import { useCssVars as _useCssVars, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "grid-container" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "min-height mt-3" }
const _hoisted_5 = { class: "back-grid" }
const _hoisted_6 = { style: {"display":"flex","justify-content":"center"} }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = {
  key: 0,
  style: {"font-style":"italic"}
}
const _hoisted_9 = ["href"]
const _hoisted_10 = {
  key: 1,
  style: {"font-style":"italic"}
}

import { useCourseStore } from "@/stores/CourseStore";
import { useAuthStore } from "@/stores/AuthStore";
import { ref } from "vue";
import PractitionerService from "@/services/practitioner.service.js";
import PractitionerSidebar from "@/components/PractitionerSidebar.vue";
import InstructorSidebar from "@/components/instructor/InstructorSidebar.vue";
import BackButton from "@/components/user/BackButton.vue";
import { websiteURL } from "@/utils";


export default /*@__PURE__*/_defineComponent({
  __name: 'InstructorProfileView',
  setup(__props) {

_useCssVars(_ctx => ({
  "73d06c66": (_unref(image))
}))

const CourseStore = useCourseStore();
const AuthStore = useAuthStore();

const currentUser = AuthStore.auth;
let course = CourseStore.course;
let instructor = ref({});
let loading = false;
let successful = false;
let message = "";
let image = ref("");

const getInstructorProfile = (instructorUserId) => {
  loading = true;
  PractitionerService.getInstructorProfile(instructorUserId).then(
    (response) => {
      instructor.value = response.data;
      image.value =
        "url('" +
        websiteURL +
        "/profile_picture/" +
        instructor.value.user.fileName +
        "')";
      loading = false;
      successful = true;
    },
    (error) => {
      loading = false;
      successful = false;
      message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(message);
    }
  );
};

getInstructorProfile(course.userId);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_unref(currentUser).roles === 'INDUSTRYPRACTITIONER')
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(PractitionerSidebar)
        ]))
      : (_unref(currentUser).roles === 'INSTRUCTOR')
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(InstructorSidebar)
          ]))
        : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createVNode(BackButton),
        _cache[0] || (_cache[0] = _createElementVNode("h2", { class: "text-align-center mb-4" }, "Instructor Details", -1))
      ]),
      _createElementVNode("div", _hoisted_6, [
        (Object.keys(_unref(instructor) || {}).length > 0)
          ? (_openBlock(), _createElementBlock("table", _hoisted_7, [
              _createElementVNode("tr", null, [
                _cache[1] || (_cache[1] = _createElementVNode("td", {
                  rowspan: "4",
                  class: "recommendation-img-card"
                }, null, -1)),
                _cache[2] || (_cache[2] = _createElementVNode("td", null, "Course-Support Request Type", -1)),
                _createElementVNode("td", null, _toDisplayString(_unref(course).courseRequestType), 1)
              ]),
              _createElementVNode("tr", null, [
                _cache[3] || (_cache[3] = _createElementVNode("td", null, "Name", -1)),
                _createElementVNode("td", null, _toDisplayString(_unref(instructor).user.firstName + " " + _unref(instructor).user.lastName), 1)
              ]),
              _createElementVNode("tr", null, [
                _cache[4] || (_cache[4] = _createElementVNode("td", null, "Title", -1)),
                _createElementVNode("td", null, _toDisplayString(_unref(instructor).user.title), 1)
              ]),
              _createElementVNode("tr", null, [
                _cache[5] || (_cache[5] = _createElementVNode("td", null, "Department/Program", -1)),
                _createElementVNode("td", null, _toDisplayString(_unref(instructor).department), 1)
              ]),
              _createElementVNode("tr", null, [
                _cache[6] || (_cache[6] = _createElementVNode("td", null, null, -1)),
                _cache[7] || (_cache[7] = _createElementVNode("td", null, "Name of Institution", -1)),
                _createElementVNode("td", null, _toDisplayString(_unref(instructor).user.nameOrg), 1)
              ]),
              _createElementVNode("tr", null, [
                _cache[8] || (_cache[8] = _createElementVNode("td", null, null, -1)),
                _cache[9] || (_cache[9] = _createElementVNode("td", null, "Location of Institution", -1)),
                _createElementVNode("td", null, _toDisplayString(_unref(instructor).city + " " + _unref(instructor).state), 1)
              ]),
              (
              _unref(course).status === '10' ||
              _unref(course).status === '20' ||
              _unref(course).status === '60'
            )
                ? (_openBlock(), _createElementBlock("tr", _hoisted_8, [
                    _cache[10] || (_cache[10] = _createElementVNode("td", null, null, -1)),
                    _cache[11] || (_cache[11] = _createElementVNode("td", null, "Email", -1)),
                    _createElementVNode("td", null, [
                      _createElementVNode("a", {
                        href: 'mailto:' + _unref(instructor)?.user?.email
                      }, _toDisplayString(_unref(instructor)?.user?.email), 9, _hoisted_9)
                    ])
                  ]))
                : _createCommentVNode("", true),
              (
              (_unref(course).status === '10' ||
                _unref(course).status === '20' ||
                _unref(course).status === '60') &&
              _unref(instructor).user.phone === 'True'
            )
                ? (_openBlock(), _createElementBlock("tr", _hoisted_10, [
                    _cache[12] || (_cache[12] = _createElementVNode("td", null, null, -1)),
                    _cache[13] || (_cache[13] = _createElementVNode("td", null, "Phone Number", -1)),
                    _createElementVNode("td", null, _toDisplayString(_unref(instructor).user.phoneNumber), 1)
                  ]))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}
}

})