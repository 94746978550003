import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import PractitionerSidebar from "@/components/PractitionerSidebar.vue";
import InstructorSidebar from "@/components/instructor/InstructorSidebar.vue";
import AdminSidebar from "@/components/admin/AdminSidebar.vue";
import { useAuthStore } from "@/stores/AuthStore";


export default /*@__PURE__*/_defineComponent({
  __name: 'AppSidebar',
  setup(__props) {

const AuthStore = useAuthStore();

const currentUser = AuthStore.auth;

return (_ctx: any,_cache: any) => {
  return (_unref(currentUser).roles === 'INDUSTRYPRACTITIONER')
    ? (_openBlock(), _createBlock(PractitionerSidebar, { key: 0 }))
    : (_unref(currentUser).roles === 'INSTRUCTOR')
      ? (_openBlock(), _createBlock(InstructorSidebar, { key: 1 }))
      : (_unref(currentUser).roles === 'ADMIN')
        ? (_openBlock(), _createBlock(AdminSidebar, { key: 2 }))
        : _createCommentVNode("", true)
}
}

})