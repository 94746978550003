<script setup lang="ts">
import { ref } from "vue";
const currYear = ref(new Date().getFullYear());
</script>

<style scoped>
footer {
  background: var(--secondary-background-color);
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1em;
  justify-content: space-between;
  height: 4em;
  overflow: hidden;
  position: relative;
}

.btn,
.btn:visited {
  display: inline-block;
  text-decoration: none;
  padding: 1em 1em;
  cursor: pointer;
}

.social-media-button {
  font-size: large;
}

a {
  color: #000;
}

a:hover {
  background: var(--primary-background-color);
}
@media (max-width: 600px) {
  .footer-text {
    display: none;
  }

  footer {
    gap: 0.5em;
  }

  .social-media-button {
    margin-left: 0.5em;
  }

  .social-media-button:hover {
    background: var(--primary-background-color);
  }
}
</style>

<template>
  <footer class="footer header-space">
    <section class="text-decoration-none flex align-items-center ml-1">
      <a
        href="https://www.instagram.com/conpecplatform/"
        target="_blank"
        class="p-1 flex-initial social-media-button rounded-2 lg:p-3"
        ><img src="@/assets/images/site/instagram.png" class="sidebar-image" />
      </a>
      <a
        href="https://www.linkedin.com/company/conpec-plaform/"
        target="_blank"
        class="p-1 flex-initial social-media-button rounded-2 lg:p-3"
        ><font-awesome-icon icon="fa-brands fa-linkedin" style="color: #0077b5"
      /></a>
      <a
        href="https://twitter.com/ConPEC_Platform"
        target="_blank"
        class="p-1 flex-initial social-media-button rounded-2 lg:p-3"
      >
        <font-awesome-icon icon="fa-brands fa-x-twitter" style="color: #000000"
      /></a>
    </section>
    <section
      class="flex flex-direction-column align-items-center text-decoration-none"
    >
      <div class="mt-1 lg:mt-2">
        <router-link
          :to="{ name: 'contact-us' }"
          class="social-media-button p-1 rounded-2 lg:p-2"
        >
          <img
            src="@/assets/images/site/contact-us.png"
            class="sidebar-image-large"
            title="Contact Us"
          />
          &nbsp;
          <span class="footer-text">Contact Us</span>
        </router-link>
        &nbsp; |
        <router-link
          :to="{ name: 'about' }"
          class="social-media-button p-1 rounded-2 lg:p-2"
          ><img
            src="@/assets/images/site/about.png"
            class="sidebar-image-large"
            title="About Us"
          />
          &nbsp;
          <span class="footer-text">About Us </span>
        </router-link>
      </div>
      <div class="flex text-align-center justify-content-end">
        <p>
          <router-link to="/" class="p-1 rounded-2 lg:p-2">
            ConPEC
            <font-awesome-icon icon="fa-regular fa-copyright" />
            {{ currYear }}
          </router-link>
        </p>
      </div>
    </section>
    <section
      class="text-decoration-none flex align-items-center justify-content-end ml-auto mr-1"
    >
      <router-link
        :to="{ name: 'faq' }"
        class="p-1 lg:p-3 text-decoration-none rounded-2"
      >
        <img
          src="@/assets/images/site/faq.png"
          class="sidebar-image-large"
          title="FAQ"
        />&nbsp;
        <span class="footer-text">FAQ</span>
      </router-link>
      <router-link
        :to="{ name: 'user-agreement' }"
        class="p-1 lg:p-3 text-decoration-none rounded-2"
      >
        <img
          src="@/assets/images/site/user_agreement.png"
          class="sidebar-image-large"
          title="User Agreement"
        />&nbsp;
        <span class="footer-text">User Agreement</span>
      </router-link>
      <router-link
        :to="{ name: 'privacy-policy' }"
        class="p-1 lg:p-3 text-decoration-none rounded-2"
      >
        <img
          src="@/assets/images/site/privacy-policy.png"
          class="sidebar-image-large"
          title="Privacy Policy"
        />&nbsp; <span class="footer-text">Privacy Policy</span></router-link
      >
    </section>
  </footer>
</template>
