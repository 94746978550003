<script setup lang="ts">
import { ref } from "vue";
import PractitionerService from "@/services/practitioner.service.js";
import PractitionerSidebar from "@/components/PractitionerSidebar.vue";
import UserList from "@/components/user/UserList.vue";
import CourseRequestItem from "@/components/practitioner/CourseRequestItem.vue";
import CourseRequestGridItem from "@/components/practitioner/CourseRequestGridItem.vue";
import DataView from "primevue/dataview";
import DataViewLayoutOptions from "primevue/dataviewlayoutoptions";

import { useAuthStore } from "@/stores/AuthStore";
import { useCourseStore } from "@/stores/CourseStore";
import { useFeedStore } from "@/stores/FeedStore";
import { useRouter, useRoute } from "vue-router";
import UserService from "@/services/user.service.js";

const AuthStore = useAuthStore();
const CourseStore = useCourseStore();
const FeedStore = useFeedStore();
const router = useRouter();
const route = useRoute();

const generalCourse = ref([]);
const myCourse = ref([]);
const currentPage = ref(1);
const totalPages = ref(1);
const generalCurrentPage = ref(1);
const generalTotalPages = ref(1);
let personalPendingRequests = ref(0);
let generalPendingRequests = ref(0);
const elementVisible = ref({});

if (route.query["redirect"]) {
  const redirectURL = route.query["redirect"] as string;
  const notificationId = redirectURL.split("/").pop();
  UserService.getNotification(notificationId).then(
    (response) => {
      const notification = response.data;
      notification.course_request.user = notification.user;
      CourseStore.addCourse(notification.course_request);
      if (AuthStore.role === "INDUSTRYPRACTITIONER")
        router.push({ name: "view-course-request-prac" });
      else if (AuthStore.role === "INSTRUCTOR") {
        CourseStore.setPractitioner(notification.user.id);
        router.push({ name: "view-industry-practitioner-ins" });
      } else router.push("/home");
    },
    (error) => {
      console.log(error);
      router.push("/home");
    }
  );
}

const viewCourseSupport = (type, pageNumber = 1) => {
  PractitionerService.getPractitionerBoard(pageNumber, type).then(
    (response) => {
      if (type === "personal") {
        myCourse.value = response.data.courses;
        totalPages.value = response.data.totalPages;
        currentPage.value = response.data.currentPage;
      } else {
        generalCourse.value = response.data.courses;
        generalTotalPages.value = response.data.totalPages;
        generalCurrentPage.value = response.data.currentPage;
      }
      response.data.courses.forEach(
        (el) => (elementVisible.value[el.id] = false)
      );
    },
    (error) => {
      let res =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(res);
    }
  );
};

const getPendingRequests = () => {
  PractitionerService.getPendingRequests().then(
    (response) => {
      personalPendingRequests.value = response.data.personalCourseCount;
      generalPendingRequests.value = response.data.generalCourseCount;
    },
    (error) => {
      let res =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(res);
    }
  );
};

viewCourseSupport("personal");
viewCourseSupport("general");
getPendingRequests();

const tabs = ref([
  {
    id: 0,
    title: "Course-Support Requests Directed to me",
    title_sm: "Requests to me",
    icon: "fa-regular fa-folder-open",
    content: myCourse,
    pendingCount: personalPendingRequests,
  },
  {
    id: 1,
    title: "General Course-Support Requests",
    title_sm: "General Requests",
    icon: "fa-regular fa-folder",
    content: generalCourse,
    pendingCount: generalPendingRequests,
  },
]);
const onClick = () => {
  viewCourseSupport("personal", currentPage.value);
};
const onClickGeneral = () => {
  viewCourseSupport("general", generalCurrentPage.value);
};
</script>

<style scoped>
.paginator {
  display: grid;
  justify-content: center;
  margin: 1rem 2rem;
}

.grid-container {
  display: grid;
  grid-template-columns: var(--sidebar-width) 1fr 12em;
  column-gap: 1rem;
  position: relative;
  top: 4em;
}

@media only screen and (max-width: 768px) {
  .grid-container {
    grid-template-columns: var(--sidebar-width) 1fr;
    column-gap: 0.5rem;
  }
}
</style>

<template>
  <div class="grid-container">
    <practitioner-sidebar></practitioner-sidebar>
    <div class="container min-height">
      <va-tabs grow v-model="FeedStore.tab">
        <template #tabs>
          <va-tab v-for="tab in tabs" :key="tab.title">
            <span
              :class="{ 'font-larger': FeedStore.tab === tab.id }"
              class="tab-font"
            >
              <font-awesome-icon :icon="tab.icon" />
              <span class="hidden ml-1 lg:inline-block">{{ tab.title }}</span>
              <span class="inline-block ml-1 lg:hidden">{{
                tab.title_sm
              }}</span>
            </span>
            &nbsp;
            <span class="tab-count">{{ tab.pendingCount }} </span>
          </va-tab>
        </template>
      </va-tabs>
      <div style="text-align: right" class="mt-2">
        <DataViewLayoutOptions v-model="FeedStore.feedLayout" />
      </div>
      <div>
        <div class="mt-3">
          <div v-if="tabs[FeedStore.tab].content.length > 0">
            <DataView
              :value="tabs[FeedStore.tab].content"
              :layout="FeedStore.feedLayout"
            >
              <template #list="slotProps">
                <div class="w-100">
                  <course-request-item :course="slotProps.data" />
                </div>
              </template>
              <template #grid="slotProps">
                <course-request-grid-item :course="slotProps.data" />
              </template>
            </DataView>
          </div>
          <div v-else style="font-style: italic; text-align: center">
            <span v-if="FeedStore.tab === 0"
              >No course-support request from instructors directed to you at
              this time</span
            >
            <span v-else
              >No general course-support request from instructors at the
              moment</span
            >
          </div>
        </div>
        <div class="paginator">
          <va-pagination
            v-model="currentPage"
            :pages="totalPages"
            :visible-pages="3"
            :hideOnSinglePage="true"
            @click="onClick"
            v-if="FeedStore.tab === 0"
          />
          <va-pagination
            v-model="generalCurrentPage"
            :pages="generalTotalPages"
            :visible-pages="3"
            :hideOnSinglePage="true"
            @click="onClickGeneral"
            v-else-if="FeedStore.tab === 1"
          />
        </div>
      </div>
    </div>
    <user-list />
  </div>
</template>
