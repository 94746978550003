import { useCssVars as _useCssVars, defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, renderList as _renderList, Fragment as _Fragment, isRef as _isRef, withCtx as _withCtx, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "grid-container" }
const _hoisted_2 = { class: "min-height mt-3 mr-5" }
const _hoisted_3 = { class: "back-grid" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = {
  class: "text-align-center mb-3",
  style: {"color":"#1a73e8"}
}
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = {
  class: "text-align-center mb-3",
  style: {"color":"#1a73e8"}
}
const _hoisted_10 = { key: 2 }
const _hoisted_11 = {
  class: "text-align-center mb-3",
  style: {"color":"#1a73e8"}
}
const _hoisted_12 = { key: 3 }
const _hoisted_13 = {
  class: "text-align-center mb-3",
  style: {"color":"#1a73e8"}
}
const _hoisted_14 = { style: {"display":"flex","justify-content":"center"} }
const _hoisted_15 = { key: 0 }
const _hoisted_16 = { key: 1 }
const _hoisted_17 = { key: 0 }
const _hoisted_18 = { key: 0 }
const _hoisted_19 = { key: 0 }
const _hoisted_20 = { key: 1 }
const _hoisted_21 = { key: 1 }
const _hoisted_22 = { key: 2 }
const _hoisted_23 = { key: 3 }
const _hoisted_24 = { key: 4 }
const _hoisted_25 = { key: 0 }
const _hoisted_26 = { key: 1 }
const _hoisted_27 = { key: 5 }
const _hoisted_28 = { key: 6 }
const _hoisted_29 = { key: 7 }
const _hoisted_30 = { key: 8 }
const _hoisted_31 = { key: 9 }
const _hoisted_32 = { key: 10 }
const _hoisted_33 = { style: {"display":"flex","justify-content":"center"} }
const _hoisted_34 = {
  key: 0,
  class: "image-table"
}
const _hoisted_35 = {
  key: 0,
  style: {"font-style":"italic"}
}
const _hoisted_36 = ["href"]
const _hoisted_37 = {
  key: 1,
  style: {"font-style":"italic"}
}
const _hoisted_38 = { class: "flex justify-space-between my-3" }
const _hoisted_39 = {
  key: 0,
  style: {"width":"100%"},
  class: "text-align-center"
}
const _hoisted_40 = { key: 1 }
const _hoisted_41 = {
  key: 2,
  style: {"width":"100%"},
  class: "text-align-center"
}
const _hoisted_42 = {
  key: 3,
  style: {"width":"100%"},
  class: "text-align-center"
}
const _hoisted_43 = {
  key: 0,
  class: "form-group"
}
const _hoisted_44 = {
  key: 0,
  style: {"color":"#1a73e8"},
  class: "bold-text"
}
const _hoisted_45 = { class: "form-group" }
const _hoisted_46 = ["value"]
const _hoisted_47 = {
  key: 1,
  class: "form-group"
}
const _hoisted_48 = { style: {"width":"100%","text-align":"center"} }
const _hoisted_49 = {
  key: 0,
  class: "bold-text font-color-banner"
}
const _hoisted_50 = ["disabled"]
const _hoisted_51 = { class: "spinner-border spinner-border-sm" }
const _hoisted_52 = { key: 0 }
const _hoisted_53 = { key: 1 }
const _hoisted_54 = { key: 2 }

import { ref } from "vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import AppSidebar from "@/components/user/AppSidebar.vue";
import BackButton from "@/components/user/BackButton.vue";
import Dialog from "primevue/dialog";
import { useAuthStore } from "@/stores/AuthStore";
import { useCourseStore } from "@/stores/CourseStore";
import PractitionerService from "@/services/practitioner.service.js";
import {
  showToast,
  showVisualRep,
  showClassroomItems,
  showPriorKnowledge,
  showAssignmentConnected,
  isCapstoneTermProject,
  websiteURL,
  showErrorToast,
  formatDate,
} from "@/utils";
import { useRouter } from "vue-router";
import * as yup from "yup";


export default /*@__PURE__*/_defineComponent({
  __name: 'CourseRequestView',
  setup(__props) {

_useCssVars(_ctx => ({
  "2b2bd964": (_unref(image))
}))

const router = useRouter();
const AuthStore = useAuthStore();
const CourseStore = useCourseStore();

const currentUser = AuthStore.auth;
let course = CourseStore.course;
let courseDate = ref(JSON.parse(course.date));
let courseTime = JSON.parse(course.time);

let loading = ref(false);
let successful = false;
let message = ref("");
let currStatus = ref("");
let visible = ref(false);
let courseDateTime = ref(-1);

const selectCourse = (course) => {
  loading.value = true;
  PractitionerService.selectCourse(course).then(
    (response) => {
      loading.value = false;
      visible.value = false;
      showToast("Message sent successfully to instructor.");
      router.push({ name: "home" });
    },
    (error) => {
      console.log("Error");
      message.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};

const schema = yup.object().shape({
  status: yup.string().optional(),
  reason: yup.string().when("status", {
    is: () => currStatus.value === "90",
    then: (schema) => schema.required("Reason is required!"),
    otherwise: (schema) => schema.optional(),
  }),
  courseDateTime: yup.string().when("status", {
    is: () => currStatus.value === "10" || currStatus.value === "60",
    then: (schema) => schema.required("Time Preference is required!"),
    otherwise: (schema) => schema.optional(),
  }),
});

const getHeader = () => {
  if (currStatus.value === "60") {
    return "Provide Course-Support Request";
  } else if (currStatus.value === "90") {
    return "Decline Course-Support Request";
  } else if (currStatus.value === "10") {
    return "Provide Course-Support Request";
  }
};

const getCustomMsg = () => {
  if (currentUser.phone === "True") {
    return `I, ${currentUser.firstName} ${currentUser.lastName} is willing to provide the course-support (${course.courseRequestType}) you requested.\nYou can reach me at ${currentUser.email} or ${currentUser.phoneNumber}.`;
  } else {
    return `I, ${currentUser.firstName} ${currentUser.lastName} is willing to provide the course-support (${course.courseRequestType}) you requested.\nYou can reach me at ${currentUser.email}.`;
  }
};
const respondCourse = (status) => {
  currStatus.value = status;
  if (courseDate.value && courseDate.value.length === 1)
    courseDateTime.value = 0;
  visible.value = true;
};

const handleSubmission = (data) => {
  course["reason"] = data.reason;
  if (currStatus.value === "60" || currStatus.value === "10") {
    if (courseDateTime.value === -1) {
      showErrorToast("Time Preference is required!");
      return;
    } else {
      course["selectedDateIndex"] = courseDateTime.value;
      course["selectedDate"] = new Date(
        courseDate.value[courseDateTime.value] +
          "T" +
          courseTime[courseDateTime.value]
      );
    }
  }
  if (currStatus.value === "10") {
    selectCourse(course);
  } else {
    loading.value = true;
    PractitionerService.respondCourseReq(course, currStatus.value).then(
      () => {
        loading.value = false;
        visible.value = false;
        showToast("Message sent successfully to instructor.");
        router.push({ name: "home" });
      },
      (error) => {
        console.log("Error");
        loading.value = false;
        message.value =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  }
};

let instructor = ref({});
let image = ref("");

const getInstructorProfile = (instructorUserId) => {
  loading.value = true;
  PractitionerService.getInstructorProfile(instructorUserId).then(
    (response) => {
      instructor.value = response.data;
      image.value =
        "url('" +
        websiteURL +
        "/profile_picture/" +
        instructor.value.user.fileName +
        "')";
      loading.value = false;
    },
    (error) => {
      loading.value = false;
      successful = false;
      message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(message);
    }
  );
};
getInstructorProfile(course.userId);

return (_ctx: any,_cache: any) => {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(AppSidebar),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(BackButton),
          _cache[5] || (_cache[5] = _createElementVNode("h2", { style: {"text-align":"center"} }, "Course-Support Request Information", -1))
        ]),
        _cache[41] || (_cache[41] = _createElementVNode("h6", { class: "text-align-center mb-3" }, " The page contains the information provided by the instructor regarding the course-support request ", -1)),
        (
          _unref(currentUser).roles === 'INDUSTRYPRACTITIONER' &&
          _unref(course)?.course_request_x_practitioners &&
          _unref(course)?.course_request_x_practitioners[0]?.status === '20'
        )
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("h5", _hoisted_5, [
                _createTextVNode(_toDisplayString(_unref(course).user.title +
            " " +
            _unref(course).user.firstName +
            " " +
            _unref(course).user.lastName +
            " (" +
            _unref(course).user.email) + " ", 1),
                (_unref(course).user.phone === 'True')
                  ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString("/ " + _unref(course).user.phoneNumber + ")"), 1))
                  : (_openBlock(), _createElementBlock("span", _hoisted_7, ")")),
                _createTextVNode(" at " + _toDisplayString(_unref(course).user.nameOrg) + " is requesting that you provide a " + _toDisplayString(_unref(course).courseRequestType) + " session ", 1)
              ])
            ]))
          : (
          _unref(course)?.course_request_x_practitioners &&
          (_unref(course)?.course_request_x_practitioners[0]?.status === '10' ||
            _unref(course)?.course_request_x_practitioners[0]?.status === '60')
        )
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _createElementVNode("h5", _hoisted_9, [
                  _createVNode(_component_font_awesome_icon, {
                    icon: "fa-solid fa-check",
                    class: "font-larger",
                    style: {"color":"#0bda51","font-size":"xx-large"}
                  }),
                  _cache[6] || (_cache[6] = _createTextVNode(" You have accepted this request "))
                ])
              ]))
            : (
          _unref(course)?.course_request_x_practitioners &&
          _unref(course)?.course_request_x_practitioners[0]?.status === '90'
        )
              ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                  _createElementVNode("h5", _hoisted_11, [
                    _createVNode(_component_font_awesome_icon, {
                      icon: "fa-solid fa-xmark",
                      class: "font-larger",
                      style: {"color":"#e71921","font-size":"xx-large"}
                    }),
                    _cache[7] || (_cache[7] = _createTextVNode("  You have declined this request "))
                  ])
                ]))
              : _createCommentVNode("", true),
        (
          _unref(currentUser).roles === 'INDUSTRYPRACTITIONER' &&
          _unref(course)?.course_request_x_practitioners[0]?.status === '40'
        )
          ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
              _createElementVNode("h5", _hoisted_13, [
                _createVNode(_component_font_awesome_icon, {
                  icon: "fa-solid fa-xmark",
                  class: "font-larger",
                  style: {"color":"#e71921","font-size":"xx-large"}
                }),
                _cache[8] || (_cache[8] = _createTextVNode("  This request has been withdrawn. "))
              ])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_14, [
          _createElementVNode("table", null, [
            _createElementVNode("tr", null, [
              _cache[9] || (_cache[9] = _createElementVNode("td", null, "Course Code", -1)),
              _createElementVNode("td", null, _toDisplayString(_unref(course).courseCode), 1)
            ]),
            _createElementVNode("tr", null, [
              _cache[10] || (_cache[10] = _createElementVNode("td", null, "Course Title", -1)),
              _createElementVNode("td", null, _toDisplayString(_unref(course).courseTitle), 1)
            ]),
            _createElementVNode("tr", null, [
              _cache[11] || (_cache[11] = _createElementVNode("td", null, "Course-Support Request Type", -1)),
              _createElementVNode("td", null, _toDisplayString(_unref(course).courseRequestType), 1)
            ]),
            _createElementVNode("tr", null, [
              _cache[12] || (_cache[12] = _createElementVNode("td", null, "Instruction Delivery Mode", -1)),
              _createElementVNode("td", null, _toDisplayString(_unref(course).insDeliveryType), 1)
            ]),
            _createElementVNode("tr", null, [
              _createElementVNode("td", null, [
                (_unref(course).courseRequestType !== 'Mentor for Project')
                  ? (_openBlock(), _createElementBlock("span", _hoisted_15, " Class Size "))
                  : (_openBlock(), _createElementBlock("span", _hoisted_16, " Size of student group to mentor "))
              ]),
              _createElementVNode("td", null, _toDisplayString(_unref(course).classSize), 1)
            ]),
            _createElementVNode("tr", null, [
              _cache[13] || (_cache[13] = _createElementVNode("td", null, "Academic Level", -1)),
              _createElementVNode("td", null, _toDisplayString(JSON.parse(_unref(course).academicLevel).join(", ")), 1)
            ]),
            _createElementVNode("tr", null, [
              _cache[14] || (_cache[14] = _createElementVNode("td", null, "Students' Department or Program of Study", -1)),
              _createElementVNode("td", null, _toDisplayString(JSON.parse(_unref(course).studentDept).join(", ")), 1)
            ]),
            _createElementVNode("tr", null, [
              _cache[15] || (_cache[15] = _createElementVNode("td", null, "When the Course-Support is Needed", -1)),
              _createElementVNode("td", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(courseDate), (item, index) => {
                  return (_openBlock(), _createElementBlock("span", { key: index }, [
                    _createTextVNode(_toDisplayString(new Date(item + "T" + _unref(courseTime)[index]).toLocaleString()) + " ", 1),
                    (index != _unref(courseDate).length - 1)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_17, " OR "))
                      : _createCommentVNode("", true)
                  ]))
                }), 128))
              ])
            ]),
            (_unref(course).courseRequestType === 'Mentor for Project')
              ? (_openBlock(), _createElementBlock("tr", _hoisted_18, [
                  _cache[16] || (_cache[16] = _createElementVNode("td", null, "When the Course-Support ends", -1)),
                  _createElementVNode("td", null, [
                    _createElementVNode("span", null, _toDisplayString(_unref(formatDate)(_unref(course).endDate)), 1)
                  ])
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("tr", null, [
              _cache[17] || (_cache[17] = _createElementVNode("td", null, "Location of Institution", -1)),
              _createElementVNode("td", null, _toDisplayString(_unref(course).location) + " " + _toDisplayString(_unref(course).city + ", " + _unref(course).state), 1)
            ]),
            _createElementVNode("tr", null, [
              _cache[18] || (_cache[18] = _createElementVNode("td", null, "Duration of Course-Support", -1)),
              _createElementVNode("td", null, _toDisplayString(_unref(course).duration) + " minutes", 1)
            ]),
            _createElementVNode("tr", null, [
              _createElementVNode("td", null, [
                (_unref(course).courseRequestType !== 'Mentor for Project')
                  ? (_openBlock(), _createElementBlock("span", _hoisted_19, "Number/Frequency of Course-Support"))
                  : (_openBlock(), _createElementBlock("span", _hoisted_20, "Frequency of Meeting with Students"))
              ]),
              _createElementVNode("td", null, _toDisplayString(_unref(course).noOfFreq), 1)
            ]),
            (_unref(course).courseRequestType === 'Mentor for Project')
              ? (_openBlock(), _createElementBlock("tr", _hoisted_21, [
                  _cache[19] || (_cache[19] = _createElementVNode("td", null, "Days of Meeting with Students", -1)),
                  _createElementVNode("td", null, _toDisplayString(_unref(course).daysOfMeeting), 1)
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("tr", null, [
              _cache[20] || (_cache[20] = _createElementVNode("td", null, "Topic Requiring Course-Support", -1)),
              _createElementVNode("td", null, _toDisplayString(_unref(course).topic), 1)
            ]),
            _createElementVNode("tr", null, [
              _cache[21] || (_cache[21] = _createElementVNode("td", null, "Learning Objective/Expected Outcome", -1)),
              _createElementVNode("td", null, _toDisplayString(JSON.parse(_unref(course).learning).join(", ")), 1)
            ]),
            (_unref(course).courseRequestType === 'Site Visit')
              ? (_openBlock(), _createElementBlock("tr", _hoisted_22, [
                  _cache[22] || (_cache[22] = _createElementVNode("td", null, "Need for Pre-Visit by the Instructor", -1)),
                  _createElementVNode("td", null, _toDisplayString(_unref(course).needForPreVisit), 1)
                ]))
              : _createCommentVNode("", true),
            (_unref(showVisualRep)(_unref(course).courseRequestType))
              ? (_openBlock(), _createElementBlock("tr", _hoisted_23, [
                  _cache[23] || (_cache[23] = _createElementVNode("td", null, "Specific Demonstrations/ Visual Representation Required", -1)),
                  _createElementVNode("td", null, _toDisplayString(_unref(course).visualRep), 1)
                ]))
              : _createCommentVNode("", true),
            (_unref(course).courseRequestType === 'Site Visit')
              ? (_openBlock(), _createElementBlock("tr", _hoisted_24, [
                  _cache[24] || (_cache[24] = _createElementVNode("td", null, "Need to Visit the Office Trailer/Site Office", -1)),
                  _createElementVNode("td", null, _toDisplayString(_unref(course).siteOffice), 1)
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("tr", null, [
              _cache[25] || (_cache[25] = _createElementVNode("td", null, "Detailed Description of Course-Support Needed", -1)),
              _createElementVNode("td", null, [
                (_unref(course).generalInfo)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_25, _toDisplayString(_unref(course).generalInfo), 1))
                  : (_openBlock(), _createElementBlock("span", _hoisted_26, "N/A"))
              ])
            ]),
            (
              _unref(showClassroomItems)(_unref(course).courseRequestType) &&
              _unref(course).classroomItems
            )
              ? (_openBlock(), _createElementBlock("tr", _hoisted_27, [
                  _cache[26] || (_cache[26] = _createElementVNode("td", null, "Available items in the classroom", -1)),
                  _createElementVNode("td", null, _toDisplayString(_unref(course).classroomItems), 1)
                ]))
              : _createCommentVNode("", true),
            (
              _unref(showAssignmentConnected)(_unref(course).courseRequestType) &&
              _unref(course).assigmentConnected
            )
              ? (_openBlock(), _createElementBlock("tr", _hoisted_28, [
                  _cache[27] || (_cache[27] = _createElementVNode("td", null, "Student Assignment Connected to Course-Support", -1)),
                  _createElementVNode("td", null, _toDisplayString(_unref(course).assigmentConnected), 1)
                ]))
              : _createCommentVNode("", true),
            (_unref(showPriorKnowledge)(_unref(course).courseRequestType))
              ? (_openBlock(), _createElementBlock("tr", _hoisted_29, [
                  _cache[28] || (_cache[28] = _createElementVNode("td", null, " Students' Prior Knowledge About Topic Requiring Course-Support ", -1)),
                  _createElementVNode("td", null, _toDisplayString(_unref(course).priorKnowledge), 1)
                ]))
              : _createCommentVNode("", true),
            (_unref(course).additionalInformation)
              ? (_openBlock(), _createElementBlock("tr", _hoisted_30, [
                  _cache[29] || (_cache[29] = _createElementVNode("td", null, "Link to Additional Course Information", -1)),
                  _createElementVNode("td", null, [
                    _createElementVNode("span", null, _toDisplayString(_unref(course).additionalInformation), 1)
                  ])
                ]))
              : _createCommentVNode("", true),
            (_unref(isCapstoneTermProject)(_unref(course).courseRequestType))
              ? (_openBlock(), _createElementBlock("tr", _hoisted_31, [
                  _cache[30] || (_cache[30] = _createElementVNode("td", null, "Type of Project", -1)),
                  _createElementVNode("td", null, _toDisplayString(_unref(course).typeProject), 1)
                ]))
              : _createCommentVNode("", true),
            (_unref(isCapstoneTermProject)(_unref(course).courseRequestType))
              ? (_openBlock(), _createElementBlock("tr", _hoisted_32, [
                  _cache[31] || (_cache[31] = _createElementVNode("td", null, "Project Description", -1)),
                  _createElementVNode("td", null, _toDisplayString(_unref(course).projectDescr), 1)
                ]))
              : _createCommentVNode("", true)
          ])
        ]),
        _cache[42] || (_cache[42] = _createElementVNode("h3", { class: "text-align-center my-4" }, "Instructor Details", -1)),
        _createElementVNode("div", _hoisted_33, [
          (Object.keys(_unref(instructor) || {}).length > 0)
            ? (_openBlock(), _createElementBlock("table", _hoisted_34, [
                _createElementVNode("tr", null, [
                  _cache[32] || (_cache[32] = _createElementVNode("td", {
                    rowspan: "4",
                    class: "recommendation-img-card"
                  }, null, -1)),
                  _cache[33] || (_cache[33] = _createElementVNode("td", { class: "bold-text" }, "Name", -1)),
                  _createElementVNode("td", null, _toDisplayString(_unref(instructor).user.firstName + " " + _unref(instructor).user.lastName), 1)
                ]),
                _createElementVNode("tr", null, [
                  _cache[34] || (_cache[34] = _createElementVNode("td", null, "Title", -1)),
                  _createElementVNode("td", null, _toDisplayString(_unref(instructor).user.title), 1)
                ]),
                _createElementVNode("tr", null, [
                  _cache[35] || (_cache[35] = _createElementVNode("td", null, "Department/Program", -1)),
                  _createElementVNode("td", null, _toDisplayString(_unref(instructor).department), 1)
                ]),
                _createElementVNode("tr", null, [
                  _cache[36] || (_cache[36] = _createElementVNode("td", null, "Name of Institution", -1)),
                  _createElementVNode("td", null, _toDisplayString(_unref(instructor).user.nameOrg), 1)
                ]),
                (
              _unref(course)?.course_request_x_practitioners &&
              (_unref(course)?.course_request_x_practitioners[0]?.status === '10' ||
                _unref(course)?.course_request_x_practitioners[0]?.status === '20' ||
                _unref(course)?.course_request_x_practitioners[0]?.status === '60')
            )
                  ? (_openBlock(), _createElementBlock("tr", _hoisted_35, [
                      _cache[37] || (_cache[37] = _createElementVNode("td", { class: "rowspan-comp" }, null, -1)),
                      _cache[38] || (_cache[38] = _createElementVNode("td", { class: "bold-text" }, "Email", -1)),
                      _createElementVNode("td", null, [
                        _createElementVNode("a", {
                          href: 'mailto:' + _unref(instructor)?.user?.email
                        }, _toDisplayString(_unref(instructor)?.user?.email), 9, _hoisted_36)
                      ])
                    ]))
                  : _createCommentVNode("", true),
                (
              _unref(course)?.course_request_x_practitioners &&
              (_unref(course)?.course_request_x_practitioners[0]?.status === '10' ||
                _unref(course)?.course_request_x_practitioners[0]?.status === '20' ||
                _unref(course)?.course_request_x_practitioners[0]?.status === '60') &&
              _unref(instructor).user.phone === 'True'
            )
                  ? (_openBlock(), _createElementBlock("tr", _hoisted_37, [
                      _cache[39] || (_cache[39] = _createElementVNode("td", { class: "rowspan-comp" }, null, -1)),
                      _cache[40] || (_cache[40] = _createElementVNode("td", { class: "bold-text" }, "Phone Number", -1)),
                      _createElementVNode("td", null, _toDisplayString(_unref(instructor).user.phoneNumber), 1)
                    ]))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_38, [
          (
            _unref(course)?.course_request_x_practitioners &&
            _unref(course)?.course_request_x_practitioners[0]?.status === '20' &&
            _unref(courseDate).some(
              (date) =>
                new Date(date) >= new Date().setDate(new Date().getDate() - 1)
            )
          )
            ? (_openBlock(), _createElementBlock("div", _hoisted_39, [
                _createElementVNode("button", {
                  class: "btn btn-danger",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (respondCourse('90')))
                }, " Decline ")
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_40)),
          (
            _unref(course)?.course_request_x_practitioners &&
            _unref(course)?.course_request_x_practitioners[0]?.status !== '10' &&
            _unref(course)?.course_request_x_practitioners[0]?.status !== '20' &&
            _unref(course)?.course_request_x_practitioners[0]?.status !== '40' &&
            _unref(course)?.course_request_x_practitioners[0]?.status !== '60' &&
            _unref(course)?.course_request_x_practitioners[0]?.status !== '90' &&
            _unref(currentUser).roles === 'INDUSTRYPRACTITIONER' &&
            _unref(courseDate).some(
              (date) =>
                new Date(date) >= new Date().setDate(new Date().getDate() - 1)
            )
          )
            ? (_openBlock(), _createElementBlock("div", _hoisted_41, [
                _createElementVNode("button", {
                  class: "btn btn-primary",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (respondCourse('10')))
                }, " Respond to Request ")
              ]))
            : _createCommentVNode("", true),
          (
            _unref(course)?.course_request_x_practitioners &&
            _unref(course)?.course_request_x_practitioners[0]?.status === '20' &&
            _unref(currentUser).roles === 'INDUSTRYPRACTITIONER' &&
            _unref(courseDate).some(
              (date) =>
                new Date(date) >= new Date().setDate(new Date().getDate() - 1)
            )
          )
            ? (_openBlock(), _createElementBlock("div", _hoisted_42, [
                _createElementVNode("button", {
                  class: "btn btn-success",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (respondCourse('60')))
                }, " Accept ")
              ]))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _createVNode(_unref(Dialog), {
      visible: _unref(visible),
      "onUpdate:visible": _cache[4] || (_cache[4] = ($event: any) => (_isRef(visible) ? (visible).value = $event : visible = $event)),
      modal: "",
      draggable: false,
      header: getHeader(),
      style: { width: '45vw' },
      breakpoints: { '768px': '90vw' }
    }, {
      default: _withCtx(() => [
        _createVNode(_unref(Form), {
          onSubmit: handleSubmission,
          "validation-schema": _unref(schema)
        }, {
          default: _withCtx(({ meta }) => [
            (_unref(currStatus) == '60' || _unref(currStatus) == '10')
              ? (_openBlock(), _createElementBlock("div", _hoisted_43, [
                  (_unref(currStatus) == '10')
                    ? (_openBlock(), _createElementBlock("span", _hoisted_44, "Only respond to this request if you are willing to provide it."))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", _hoisted_45, [
                    _cache[43] || (_cache[43] = _createElementVNode("label", { for: "courseDateTime" }, [
                      _createTextVNode("Time Preference"),
                      _createElementVNode("span", { class: "super font-color-red" }, "*")
                    ], -1)),
                    _createVNode(_unref(Field), {
                      as: "select",
                      name: "courseDateTime",
                      modelValue: _unref(courseDateTime),
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_isRef(courseDateTime) ? (courseDateTime).value = $event : courseDateTime = $event)),
                      class: "dropdown"
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(courseDate), (item, index) => {
                          return (_openBlock(), _createElementBlock("option", {
                            key: index,
                            value: index
                          }, _toDisplayString(item) + "@" + _toDisplayString(_unref(courseTime)[index]), 9, _hoisted_46))
                        }), 128))
                      ]),
                      _: 1
                    }, 8, ["modelValue"])
                  ]),
                  _cache[44] || (_cache[44] = _createElementVNode("div", { style: {"display":"flex","justify-content":"space-between","height":"100%"} }, [
                    _createElementVNode("label", { for: "reason" }, "Please provide any message you would like to send to instructor")
                  ], -1)),
                  (_unref(currStatus) == '60')
                    ? (_openBlock(), _createBlock(_unref(Field), {
                        key: 1,
                        name: "reason",
                        as: "textarea",
                        type: "text",
                        class: "form-control",
                        style: {"height":"200px"}
                      }))
                    : (_unref(currStatus) == '10')
                      ? (_openBlock(), _createBlock(_unref(Field), {
                          key: 2,
                          name: "reason",
                          type: "text",
                          as: "textarea",
                          class: "form-control",
                          style: {"height":"200px"},
                          value: getCustomMsg()
                        }, null, 8, ["value"]))
                      : _createCommentVNode("", true),
                  _createVNode(_unref(ErrorMessage), {
                    name: "reason",
                    class: "error-feedback"
                  })
                ]))
              : (_unref(currStatus) == '90')
                ? (_openBlock(), _createElementBlock("div", _hoisted_47, [
                    _cache[45] || (_cache[45] = _createElementVNode("div", { style: {"display":"flex","justify-content":"space-between","height":"100%"} }, [
                      _createElementVNode("label", { for: "reason" }, "Please provide a reason for declining")
                    ], -1)),
                    _createVNode(_unref(Field), {
                      name: "reason",
                      type: "text",
                      class: "form-control",
                      as: "textarea",
                      style: {"height":"200px"}
                    }),
                    _createVNode(_unref(ErrorMessage), {
                      name: "reason",
                      class: "error-feedback"
                    })
                  ]))
                : _createCommentVNode("", true),
            _cache[46] || (_cache[46] = _createElementVNode("br", null, null, -1)),
            _createElementVNode("div", _hoisted_48, [
              (_unref(currStatus) == '10')
                ? (_openBlock(), _createElementBlock("div", _hoisted_49, " Only respond to this request if you are willing to provide it. "))
                : _createCommentVNode("", true),
              _createElementVNode("button", {
                class: "btn btn-primary btn-block",
                disabled: _unref(loading) || !meta.valid
              }, [
                _withDirectives(_createElementVNode("span", _hoisted_51, null, 512), [
                  [_vShow, _unref(loading)]
                ]),
                (_unref(currStatus) == '60')
                  ? (_openBlock(), _createElementBlock("span", _hoisted_52, "Accept"))
                  : (_unref(currStatus) == '90')
                    ? (_openBlock(), _createElementBlock("span", _hoisted_53, "Decline"))
                    : (_unref(currStatus) == '10')
                      ? (_openBlock(), _createElementBlock("span", _hoisted_54, "Send Reply to Instructor"))
                      : _createCommentVNode("", true)
              ], 8, _hoisted_50)
            ])
          ]),
          _: 1
        }, 8, ["validation-schema"])
      ]),
      _: 1
    }, 8, ["visible", "header"])
  ], 64))
}
}

})