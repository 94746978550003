import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "content-card" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = {
  role: "button",
  class: "hint"
}
const _hoisted_5 = {
  class: "speech_bubble tool_tip_text",
  id: "tooltip",
  style: {"display":"inline"}
}
const _hoisted_6 = {
  type: "button",
  class: "close-btn-tooltip mr-4"
}
const _hoisted_7 = { key: 1 }
const _hoisted_8 = {
  role: "button",
  class: "hint"
}
const _hoisted_9 = {
  class: "speech_bubble tool_tip_text",
  id: "tooltip",
  style: {"display":"inline"}
}
const _hoisted_10 = {
  type: "button",
  class: "close-btn-tooltip mr-4"
}
const _hoisted_11 = { key: 2 }
const _hoisted_12 = {
  role: "button",
  class: "hint"
}
const _hoisted_13 = {
  class: "speech_bubble tool_tip_text",
  id: "tooltip",
  style: {"display":"inline"}
}
const _hoisted_14 = {
  type: "button",
  class: "close-btn-tooltip mr-4"
}
const _hoisted_15 = {
  key: 3,
  class: "hint"
}
const _hoisted_16 = {
  role: "button",
  class: "hint"
}
const _hoisted_17 = {
  class: "speech_bubble tool_tip_text",
  id: "tooltip",
  style: {"display":"inline"}
}
const _hoisted_18 = {
  type: "button",
  class: "close-btn-tooltip mr-4"
}
const _hoisted_19 = {
  key: 1,
  class: "highlight-selected-time"
}
const _hoisted_20 = { key: 0 }
const _hoisted_21 = { class: "button-container lg:ml-3" }
const _hoisted_22 = { class: "p-2" }

import { ref } from "vue";
import { CourseRequest } from "@/types";
import { formatTime, sameDate } from "@/utils";
import { useAuthStore } from "@/stores/AuthStore";
import { useCourseStore } from "@/stores/CourseStore";
import { useRouter } from "vue-router";


export default /*@__PURE__*/_defineComponent({
  __name: 'CourseRequestItem',
  props: {
    course: {}
  },
  setup(__props: any) {

const AuthStore = useAuthStore();
const CourseStore = useCourseStore();
const router = useRouter();

let loading = false;
let successful = false;
let message = ref("");
let editable = ref(false);
const content = ref([]);
let currCourse = ref({} as CourseRequest);
let currStatus = ref("");
const elementVisible = ref({});
let tooltipTimeout;

// eslint-disable-next-line no-undef
const props = __props;

const showTooltip = (index) => {
  elementVisible.value[index] = true;
  tooltipTimeout = setTimeout(
    () => (elementVisible.value[index] = false),
    5000
  );
};
const hideTooltip = (index) => {
  elementVisible.value[index] = false;
  clearTimeout(tooltipTimeout);
};

const viewCourseRequest = (course) => {
  CourseStore.addCourse(course);
  router.push({ name: "view-course-request-prac" });
};

return (_ctx: any,_cache: any) => {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _cache[20] || (_cache[20] = _createElementVNode("span", { class: "bold-text" }, "Course-Support Type", -1)),
      _createTextVNode(" : " + _toDisplayString(_ctx.course.courseRequestType) + " ", 1),
      (_ctx.course['course_request_x_practitioners'][0])
        ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
            (_ctx.course['course_request_x_practitioners'][0].status == '60')
              ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
                  _cache[11] || (_cache[11] = _createTextVNode("   ")),
                  _createElementVNode("span", _hoisted_4, [
                    _createElementVNode("span", {
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (showTooltip(_ctx.course.id)))
                    }, [
                      _createVNode(_component_font_awesome_icon, {
                        icon: "fa-solid fa-check",
                        style: {"color":"#0bda51","font-size":"x-large"}
                      }),
                      _cache[9] || (_cache[9] = _createElementVNode("span", { style: {"font-size":"smaller"} }, "Accepted", -1))
                    ]),
                    _withDirectives(_createElementVNode("span", _hoisted_5, [
                      _createElementVNode("button", _hoisted_6, [
                        _createVNode(_component_font_awesome_icon, {
                          icon: "fa-solid fa-xmark",
                          class: "close-btn-icon",
                          onClick: _cache[1] || (_cache[1] = ($event: any) => (hideTooltip(_ctx.course.id)))
                        })
                      ]),
                      _cache[10] || (_cache[10] = _createElementVNode("p", { style: {"margin-bottom":"0px"} }, " You have accepted to provide this course-support request. ", -1))
                    ], 512), [
                      [_vShow, elementVisible.value[_ctx.course.id]]
                    ])
                  ])
                ]))
              : (_ctx.course['course_request_x_practitioners'][0].status == '90')
                ? (_openBlock(), _createElementBlock("span", _hoisted_7, [
                    _cache[14] || (_cache[14] = _createTextVNode("   ")),
                    _createElementVNode("span", _hoisted_8, [
                      _createElementVNode("span", {
                        onClick: _cache[2] || (_cache[2] = ($event: any) => (showTooltip(_ctx.course.id)))
                      }, [
                        _createVNode(_component_font_awesome_icon, {
                          icon: "fa-solid fa-xmark",
                          style: {"color":"#e71921","font-size":"x-large"}
                        }),
                        _cache[12] || (_cache[12] = _createElementVNode("span", { style: {"font-size":"smaller"} }, " Declined", -1))
                      ]),
                      _withDirectives(_createElementVNode("span", _hoisted_9, [
                        _createElementVNode("button", _hoisted_10, [
                          _createVNode(_component_font_awesome_icon, {
                            icon: "fa-solid fa-xmark",
                            class: "close-btn-icon",
                            onClick: _cache[3] || (_cache[3] = ($event: any) => (hideTooltip(_ctx.course.id)))
                          })
                        ]),
                        _cache[13] || (_cache[13] = _createElementVNode("p", { style: {"margin-bottom":"0px"} }, " You have declined this course-support request. ", -1))
                      ], 512), [
                        [_vShow, elementVisible.value[_ctx.course.id]]
                      ])
                    ])
                  ]))
                : (_ctx.course['course_request_x_practitioners'][0].status == '40')
                  ? (_openBlock(), _createElementBlock("span", _hoisted_11, [
                      _cache[17] || (_cache[17] = _createTextVNode("   ")),
                      _createElementVNode("span", _hoisted_12, [
                        _createElementVNode("span", {
                          onClick: _cache[4] || (_cache[4] = ($event: any) => (showTooltip(_ctx.course.id)))
                        }, [
                          _createVNode(_component_font_awesome_icon, {
                            icon: "fa-solid fa-xmark",
                            style: {"color":"#e71921","font-size":"x-large"}
                          }),
                          _cache[15] || (_cache[15] = _createElementVNode("span", { style: {"font-size":"smaller"} }, " Withdrawn", -1))
                        ]),
                        _withDirectives(_createElementVNode("span", _hoisted_13, [
                          _createElementVNode("button", _hoisted_14, [
                            _createVNode(_component_font_awesome_icon, {
                              icon: "fa-solid fa-xmark",
                              class: "close-btn-icon",
                              onClick: _cache[5] || (_cache[5] = ($event: any) => (hideTooltip(_ctx.course.id)))
                            })
                          ]),
                          _cache[16] || (_cache[16] = _createElementVNode("p", { style: {"margin-bottom":"0px"} }, "This request has been withdrawn.", -1))
                        ], 512), [
                          [_vShow, elementVisible.value[_ctx.course.id]]
                        ])
                      ])
                    ]))
                  : (_openBlock(), _createElementBlock("span", _hoisted_15, [
                      _cache[19] || (_cache[19] = _createTextVNode("   ")),
                      _createElementVNode("span", _hoisted_16, [
                        _createVNode(_component_font_awesome_icon, {
                          icon: "fa-solid fa-circle-exclamation",
                          onClick: _cache[6] || (_cache[6] = ($event: any) => (showTooltip(_ctx.course.id)))
                        }),
                        _withDirectives(_createElementVNode("span", _hoisted_17, [
                          _createElementVNode("button", _hoisted_18, [
                            _createVNode(_component_font_awesome_icon, {
                              icon: "fa-solid fa-xmark",
                              class: "close-btn-icon",
                              onClick: _cache[7] || (_cache[7] = ($event: any) => (hideTooltip(_ctx.course.id)))
                            })
                          ]),
                          _cache[18] || (_cache[18] = _createElementVNode("p", { style: {"margin-bottom":"0px"} }, " You have not responded to this request. ", -1))
                        ], 512), [
                          [_vShow, elementVisible.value[_ctx.course.id]]
                        ])
                      ])
                    ]))
          ]))
        : _createCommentVNode("", true),
      _cache[21] || (_cache[21] = _createElementVNode("br", null, null, -1)),
      _cache[22] || (_cache[22] = _createElementVNode("span", { class: "bold-text" }, "Course Requested For", -1)),
      _cache[23] || (_cache[23] = _createTextVNode(" : ")),
      (
          _ctx.course['course_request_x_practitioners'][0] &&
          (_ctx.course['course_request_x_practitioners'][0].status === '60' ||
            _ctx.course['course_request_x_practitioners'][0].status === '10')
        )
        ? (_openBlock(), _createElementBlock("span", _hoisted_19, _toDisplayString(new Date(
            _ctx.course["course_request_x_practitioners"][0].selectedDate
          ).toLocaleString()), 1))
        : (_openBlock(true), _createElementBlock(_Fragment, { key: 2 }, _renderList(JSON.parse(_ctx.course.date), (item, index) => {
            return (_openBlock(), _createElementBlock("span", { key: index }, [
              _createTextVNode(_toDisplayString(new Date(item + "T" + JSON.parse(_ctx.course.time)[index]).toLocaleString()) + " ", 1),
              (index != JSON.parse(_ctx.course.date).length - 1)
                ? (_openBlock(), _createElementBlock("span", _hoisted_20, " OR "))
                : _createCommentVNode("", true)
            ]))
          }), 128)),
      _cache[24] || (_cache[24] = _createElementVNode("br", null, null, -1)),
      _cache[25] || (_cache[25] = _createElementVNode("span", { class: "bold-text" }, "Professor", -1)),
      _createTextVNode(": " + _toDisplayString(_ctx.course.user.title +
        " " +
        _ctx.course.user.firstName +
        " " +
        _ctx.course.user.lastName) + " ", 1),
      _cache[26] || (_cache[26] = _createElementVNode("br", null, null, -1)),
      _cache[27] || (_cache[27] = _createElementVNode("span", { class: "bold-text" }, "Institution", -1)),
      _createTextVNode(": " + _toDisplayString(_ctx.course.user.nameOrg), 1)
    ]),
    _createElementVNode("div", _hoisted_21, [
      _createElementVNode("div", _hoisted_22, [
        _createElementVNode("button", {
          class: "btn btn-primary",
          onClick: _cache[8] || (_cache[8] = ($event: any) => (viewCourseRequest(_ctx.course)))
        }, " View Details ")
      ])
    ])
  ]))
}
}

})