import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"
import _imports_0 from '@/assets/images/badge.png'


import { ref } from "vue";
import PractitionerService from "@/services/practitioner.service.js";


export default /*@__PURE__*/_defineComponent({
  __name: 'UserBadge',
  setup(__props) {

let badgeLevel = ref(0);

const getUserBadge = () => {
  PractitionerService.getUserBadge().then(
    (response) => {
      badgeLevel.value = response.data.badge;
    },
    (error) => {
      let res =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(res);
    }
  );
};

getUserBadge();

return (_ctx: any,_cache: any) => {
  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(badgeLevel), (index) => {
    return (_openBlock(), _createElementBlock("span", { key: index }, _cache[0] || (_cache[0] = [
      _createElementVNode("img", {
        src: _imports_0,
        class: "badge-img-card"
      }, null, -1)
    ])))
  }), 128))
}
}

})