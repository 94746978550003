import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/site/instagram.png'
import _imports_1 from '@/assets/images/site/contact-us.png'
import _imports_2 from '@/assets/images/site/about.png'
import _imports_3 from '@/assets/images/site/faq.png'
import _imports_4 from '@/assets/images/site/user_agreement.png'
import _imports_5 from '@/assets/images/site/privacy-policy.png'


const _hoisted_1 = { class: "footer header-space" }
const _hoisted_2 = { class: "text-decoration-none flex align-items-center ml-1" }
const _hoisted_3 = {
  href: "https://www.linkedin.com/company/conpec-plaform/",
  target: "_blank",
  class: "p-1 flex-initial social-media-button rounded-2 lg:p-3"
}
const _hoisted_4 = {
  href: "https://twitter.com/ConPEC_Platform",
  target: "_blank",
  class: "p-1 flex-initial social-media-button rounded-2 lg:p-3"
}
const _hoisted_5 = { class: "flex flex-direction-column align-items-center text-decoration-none" }
const _hoisted_6 = { class: "mt-1 lg:mt-2" }
const _hoisted_7 = { class: "flex text-align-center justify-content-end" }
const _hoisted_8 = { class: "text-decoration-none flex align-items-center justify-content-end ml-auto mr-1" }

import { ref } from "vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'AppFooter',
  setup(__props) {

const currYear = ref(new Date().getFullYear());

return (_ctx: any,_cache: any) => {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("footer", _hoisted_1, [
    _createElementVNode("section", _hoisted_2, [
      _cache[0] || (_cache[0] = _createElementVNode("a", {
        href: "https://www.instagram.com/conpecplatform/",
        target: "_blank",
        class: "p-1 flex-initial social-media-button rounded-2 lg:p-3"
      }, [
        _createElementVNode("img", {
          src: _imports_0,
          class: "sidebar-image"
        })
      ], -1)),
      _createElementVNode("a", _hoisted_3, [
        _createVNode(_component_font_awesome_icon, {
          icon: "fa-brands fa-linkedin",
          style: {"color":"#0077b5"}
        })
      ]),
      _createElementVNode("a", _hoisted_4, [
        _createVNode(_component_font_awesome_icon, {
          icon: "fa-brands fa-x-twitter",
          style: {"color":"#000000"}
        })
      ])
    ]),
    _createElementVNode("section", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_router_link, {
          to: { name: 'contact-us' },
          class: "social-media-button p-1 rounded-2 lg:p-2"
        }, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createElementVNode("img", {
              src: _imports_1,
              class: "sidebar-image-large",
              title: "Contact Us"
            }, null, -1),
            _createTextVNode("   "),
            _createElementVNode("span", { class: "footer-text" }, "Contact Us", -1)
          ])),
          _: 1
        }),
        _cache[3] || (_cache[3] = _createTextVNode("   | ")),
        _createVNode(_component_router_link, {
          to: { name: 'about' },
          class: "social-media-button p-1 rounded-2 lg:p-2"
        }, {
          default: _withCtx(() => _cache[2] || (_cache[2] = [
            _createElementVNode("img", {
              src: _imports_2,
              class: "sidebar-image-large",
              title: "About Us"
            }, null, -1),
            _createTextVNode("   "),
            _createElementVNode("span", { class: "footer-text" }, "About Us ", -1)
          ])),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("p", null, [
          _createVNode(_component_router_link, {
            to: "/",
            class: "p-1 rounded-2 lg:p-2"
          }, {
            default: _withCtx(() => [
              _cache[4] || (_cache[4] = _createTextVNode(" ConPEC ")),
              _createVNode(_component_font_awesome_icon, { icon: "fa-regular fa-copyright" }),
              _createTextVNode(" " + _toDisplayString(currYear.value), 1)
            ]),
            _: 1
          })
        ])
      ])
    ]),
    _createElementVNode("section", _hoisted_8, [
      _createVNode(_component_router_link, {
        to: { name: 'faq' },
        class: "p-1 lg:p-3 text-decoration-none rounded-2"
      }, {
        default: _withCtx(() => _cache[5] || (_cache[5] = [
          _createElementVNode("img", {
            src: _imports_3,
            class: "sidebar-image-large",
            title: "FAQ"
          }, null, -1),
          _createTextVNode("  "),
          _createElementVNode("span", { class: "footer-text" }, "FAQ", -1)
        ])),
        _: 1
      }),
      _createVNode(_component_router_link, {
        to: { name: 'user-agreement' },
        class: "p-1 lg:p-3 text-decoration-none rounded-2"
      }, {
        default: _withCtx(() => _cache[6] || (_cache[6] = [
          _createElementVNode("img", {
            src: _imports_4,
            class: "sidebar-image-large",
            title: "User Agreement"
          }, null, -1),
          _createTextVNode("  "),
          _createElementVNode("span", { class: "footer-text" }, "User Agreement", -1)
        ])),
        _: 1
      }),
      _createVNode(_component_router_link, {
        to: { name: 'privacy-policy' },
        class: "p-1 lg:p-3 text-decoration-none rounded-2"
      }, {
        default: _withCtx(() => _cache[7] || (_cache[7] = [
          _createElementVNode("img", {
            src: _imports_5,
            class: "sidebar-image-large",
            title: "Privacy Policy"
          }, null, -1),
          _createTextVNode("  "),
          _createElementVNode("span", { class: "footer-text" }, "Privacy Policy", -1)
        ])),
        _: 1
      })
    ])
  ]))
}
}

})