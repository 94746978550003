import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { Availability } from "@/types";
import { formatSubjectSpeak } from "@/utils";

// eslint-disable-next-line no-undef

export default /*@__PURE__*/_defineComponent({
  __name: 'PractitionerAvailability',
  props: {
    userAvailability: {}
  },
  setup(__props: any) {

const props = __props;

console.log(props.userAvailability);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("table", null, [
    _createElementVNode("tr", null, [
      _cache[0] || (_cache[0] = _createElementVNode("td", null, "Course Request Type", -1)),
      _createElementVNode("td", null, _toDisplayString(JSON.parse(_ctx.userAvailability.courseRequestType).join(", ")), 1)
    ]),
    _createElementVNode("tr", null, [
      _cache[1] || (_cache[1] = _createElementVNode("td", null, "Subject willing to speak on", -1)),
      _createElementVNode("td", null, _toDisplayString(_unref(formatSubjectSpeak)(
            _ctx.userAvailability.subjectSpeak,
            _ctx.userAvailability?.subjectSpeakOthers
          )), 1)
    ]),
    _createElementVNode("tr", null, [
      _cache[2] || (_cache[2] = _createElementVNode("td", null, "Instruction Delivery Mode", -1)),
      _createElementVNode("td", null, _toDisplayString(_ctx.userAvailability.insDeliveryType), 1)
    ]),
    _createElementVNode("tr", null, [
      _cache[3] || (_cache[3] = _createElementVNode("td", null, "Location of Institution", -1)),
      _createElementVNode("td", null, _toDisplayString(JSON.parse(_ctx.userAvailability.insState).join(", ")), 1)
    ]),
    _createElementVNode("tr", null, [
      _cache[4] || (_cache[4] = _createElementVNode("td", null, "Name of Institution", -1)),
      _createElementVNode("td", null, _toDisplayString(JSON.parse(_ctx.userAvailability.insName).join(", ")), 1)
    ]),
    _createElementVNode("tr", null, [
      _cache[5] || (_cache[5] = _createElementVNode("td", null, "Student's Academic Level", -1)),
      _createElementVNode("td", null, _toDisplayString(JSON.parse(_ctx.userAvailability.academicLevel).join(", ")), 1)
    ]),
    _createElementVNode("tr", null, [
      _cache[6] || (_cache[6] = _createElementVNode("td", null, "Students' Department or Program of Study", -1)),
      _createElementVNode("td", null, _toDisplayString(JSON.parse(_ctx.userAvailability.studentDept).join(", ")), 1)
    ])
  ]))
}
}

})