<template>
  <div class="grid-container">
    <instructor-sidebar></instructor-sidebar>
    <div class="container min-height">
      <va-tabs grow v-model="FeedStore.tab">
        <template #tabs>
          <va-tab v-for="tab in tabs" :key="tab.title">
            <span
              :class="{ 'font-larger': FeedStore.tab === tab.id }"
              class="tab-font"
            >
              <font-awesome-icon :icon="tab.icon" />
              <span class="hidden ml-1 lg:inline-block">{{ tab.title }}</span>
              <span class="inline-block ml-1 lg:hidden">{{
                tab.title_sm
              }}</span>
            </span>
            &nbsp;
            <span
              v-if="
                tab.id === 1 &&
                pastCourse.filter((el) => el.feedbackExists === false).length >
                  0
              "
              class="tab-count"
            >
              {{
                pastCourse.filter((el) => el.feedbackExists === false).length
              }}
            </span>
          </va-tab>
        </template>
      </va-tabs>

      <div v-if="tabs[FeedStore.tab].content.length > 0">
        <ul>
          <li v-for="course in tabs[FeedStore.tab].content" :key="course.id">
            <course-request-item
              :course="course"
              :type="tabs[FeedStore.tab].type"
              @fetchCourse="getCourseRequest"
            />
          </li>
        </ul>
        <div class="paginator">
          <va-pagination
            v-model="futureCurrentPage"
            :pages="futureTotalPages"
            :visible-pages="3"
            :hideOnSinglePage="true"
            @click="futureOnClick"
            v-if="FeedStore.tab === 0"
          />
          <va-pagination
            v-model="pastCurrentPage"
            :pages="pastTotalPages"
            :visible-pages="3"
            :hideOnSinglePage="true"
            @click="pastOnClick"
            v-if="FeedStore.tab === 1"
          />
        </div>
      </div>
      <div v-else class="mt-3">
        <h3
          style="text-align: center; font-style: italic"
          v-if="FeedStore.tab === 0"
        >
          No course-support request raised.
        </h3>
        <h3
          style="text-align: center; font-style: italic"
          v-if="FeedStore.tab === 1"
        >
          There is no previous course-support at the moment.
        </h3>
      </div>
    </div>
    <user-list />
  </div>
</template>
<script setup lang="ts">
import { ref } from "vue";
import InstructorSidebar from "@/components/instructor/InstructorSidebar.vue";
import CourseRequestItem from "@/components/instructor/CourseRequestItem.vue";
import UserList from "@/components/user/UserList.vue";
import InstructorService from "../../services/instructor.service.js";
import UserService from "@/services/user.service.js";

import { useRouter, useRoute } from "vue-router";
import { useAuthStore } from "@/stores/AuthStore";
import { useCourseStore } from "@/stores/CourseStore";
import { useFeedStore } from "@/stores/FeedStore";

const AuthStore = useAuthStore();
const CourseStore = useCourseStore();
const FeedStore = useFeedStore();

const router = useRouter();
const route = useRoute();

let currentUser = AuthStore.auth;
let loading = ref(false);
let successful = ref(false);
let message = ref("");
let visible = ref(false);

const content = ref([]);
const pastCourse = ref([]);
const futureCourse = ref([]);

const futureCurrentPage = ref(1);
const futureTotalPages = ref(1);
const pastCurrentPage = ref(1);
const pastTotalPages = ref(1);

if (route.query["redirect"]) {
  const redirectURL = route.query["redirect"] as string;
  const notificationId = redirectURL.split("/").pop();
  UserService.getNotification(notificationId).then(
    (response) => {
      const notification = response.data;
      notification.course_request.user = notification.user;
      CourseStore.addCourse(notification.course_request);
      if (AuthStore.role === "INDUSTRYPRACTITIONER")
        router.push({ name: "view-course-request-prac" });
      else if (AuthStore.role === "INSTRUCTOR") {
        CourseStore.setPractitioner(notification.user.id);
        router.push({ name: "view-industry-practitioner-ins" });
      } else router.push("/home");
    },
    (error) => {
      loading.value = false;
      router.push("/home");
    }
  );
}

const checkFeedbackExits = (course) => {
  UserService.checkFeedbackExists(course.id).then(
    (response) => {
      if (response.status == "200")
        course.feedbackExists = response.data.feedbackExists;
    },
    (error) => {
      loading.value = false;
      message.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};

const getCourseRequest = (type, pageNumber = 1) => {
  InstructorService.getInstructorBoard(pageNumber, type).then(
    (response) => {
      if (type === "past") {
        pastCourse.value = response.data.courses;
        pastTotalPages.value = response.data.totalPages;
        pastCurrentPage.value = response.data.currentPage;
        pastCourse.value.forEach((course) => {
          checkFeedbackExits(course);
        });
      } else {
        futureCourse.value = response.data.courses;
        futureTotalPages.value = response.data.totalPages;
        futureCurrentPage.value = response.data.currentPage;
      }
    },
    (error) => {
      content.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};

getCourseRequest("present");
getCourseRequest("past");

// let imageObjectURL = ref("");
// let imageObjectURL2 = ref("");
//
// function blobToData(blob) {
//   console.log(typeof blob);
//   return new Promise((resolve) => {
//     const reader = new FileReader();
//     reader.onloadend = () => resolve(reader.result);
//     reader.readAsDataURL(blob);
//   });
// }
//
// const getUpload = (fileName) => {
//   UserService.getUpload(fileName).then(
//     async (response) => {
//       console.log(response);
//       console.log(typeof response.data);
//       if (fileName === "CEM 3154.PNG") {
//         imageObjectURL.value = await blobToData([response.data]);
//         console.log(imageObjectURL.value);
//       } else {
//         imageObjectURL2.value = await blobToData([response.data]);
//         console.log(imageObjectURL2.value);
//       }
//     },
//     (error) => {
//       let res =
//         (error.response &&
//           error.response.data &&
//           error.response.data.message) ||
//         error.message ||
//         error.toString();
//       console.log(res);
//     }
//   );
// };
//
// getUpload("CEM 3154.PNG");
// getUpload("Action Sens.png");

const tabs = ref([
  {
    id: 0,
    title: "Active Course-Support Requests",
    title_sm: "Active Requests",
    icon: "fa-regular fa-folder-open",
    type: "Active",
    content: futureCourse,
  },
  {
    id: 1,
    title: "Previous Course-Support Requests",
    title_sm: "Previous Requests",
    icon: "fa-regular fa-folder",
    type: "Previous",
    content: pastCourse,
  },
]);
const futureOnClick = (val) => {
  getCourseRequest("present", futureCurrentPage.value);
};
const pastOnClick = (val) => {
  getCourseRequest("past", pastCurrentPage.value);
};
</script>

<style scoped>
.paginator {
  display: grid;
  justify-content: center;
  margin: 1rem 2rem;
}

.grid-container {
  display: grid;
  grid-template-columns: var(--sidebar-width) 1fr 12em;
  column-gap: 1rem;
  position: relative;
  top: 4em;
}
@media only screen and (max-width: 768px) {
  .grid-container {
    grid-template-columns: var(--sidebar-width) 1fr;
    column-gap: 0.5rem;
  }
}
</style>
