<template>
  <h5 class="text-align-center font-italic font-bold">Personal Information</h5>
  <table class="table">
    <tr>
      <td>First Name</td>
      <td>{{ userProfile.user.firstName }}</td>
    </tr>

    <tr>
      <td>Last Name</td>
      <td>{{ userProfile.user.lastName }}</td>
    </tr>

    <tr>
      <td>Email</td>
      <td>{{ userProfile.user.email }}</td>
    </tr>

    <tr>
      <td>Name of Organization</td>
      <td>{{ userProfile.user.nameOrg }}</td>
    </tr>

    <tr>
      <td>Title</td>
      <td>{{ userProfile.user.title }}</td>
    </tr>

    <tr>
      <td>Gender Identity</td>
      <td>{{ userProfile.user.gender }}</td>
    </tr>
    <tr>
      <td>Ethnicity</td>
      <td>{{ userProfile.user.ethnicity }}</td>
    </tr>
    <tr v-if="valid(userProfile.pronouns)">
      <td>Pronouns</td>
      <td>{{ userProfile.pronouns }}</td>
    </tr>

    <tr>
      <td>Age Range</td>
      <td>{{ userProfile.ageRange }}</td>
    </tr>

    <tr>
      <td>Years of Experience</td>
      <td>{{ userProfile.yearsOfExp }}</td>
    </tr>

    <tr>
      <td>Highest Level of Education</td>
      <td>{{ userProfile.levelEducation }}</td>
    </tr>

    <tr>
      <td>Physical Disabilities</td>
      <td>
        {{ JSON.parse(userProfile?.physicalDis).join(", ") }}
      </td>
    </tr>

    <tr>
      <td>Area of Specialization</td>
      <td>
        {{ JSON.parse(userProfile?.expertise || '[""]').join(", ") }}
      </td>
    </tr>

    <tr>
      <td>Professional License</td>
      <td>
        {{ JSON.parse(userProfile?.profLicense || '[""]').join(", ") }}
      </td>
    </tr>
  </table>
  <h5 class="text-align-center font-italic font-bold">Company Information</h5>
  <table class="table">
    <tr>
      <td>Location</td>
      <td>
        {{ userProfile.city + " " + userProfile.state }}
      </td>
    </tr>

    <tr>
      <td>Type of Organization</td>
      <td>{{ userProfile.type }}</td>
    </tr>

    <tr>
      <td>Size of Organization</td>
      <td>{{ userProfile.size }}</td>
    </tr>

    <tr>
      <td>Gender Diversity of Organization</td>
      <td>{{ userProfile.genderDiv }}</td>
    </tr>

    <tr>
      <td>Racial Diversity of Organization</td>
      <td>{{ userProfile.ethnicDiv }}</td>
    </tr>
    <tr>
      <td>Website of Organization</td>
      <td>
        <a
          v-if="!userProfile.website.startsWith('http')"
          :href="'https://' + userProfile.website"
          target="_blank"
          >{{ userProfile.website }}
        </a>
        <a v-else :href="userProfile.website" target="_blank">{{
          userProfile.website
        }}</a>
      </td>
    </tr>

    <tr v-if="valid(userProfile.typeProject)">
      <td>Types of Projects Executed</td>
      <td>
        {{ JSON.parse(userProfile.typeProject || '[""]').join(", ") }}
      </td>
    </tr>

    <tr v-if="valid(userProfile.typeTechnology)">
      <td>Types of Technologies Adopted by Practitioner's Organization</td>
      <td>
        {{ JSON.parse(userProfile.typeTechnology || '[""]').join(", ") }}
      </td>
    </tr>

    <tr v-if="valid(userProfile.organPolicy)">
      <td>Company's Effort to Support Student Development</td>
      <td>{{ userProfile.organPolicy }}</td>
    </tr>
  </table>
</template>

<script setup lang="ts">
import { Profile } from "@/types";
import { valid } from "@/utils";

// eslint-disable-next-line no-undef
const props = defineProps<{
  userProfile: Profile;
}>();

console.log(props.userProfile);
</script>

<style scoped>
table {
  width: fit-content;
  box-shadow: 0px 0px 10px 4px rgb(0 0 0 / 30%);
  border-radius: 6px;
  background: #fafafa;
  padding: 1rem;
  margin-top: 1rem;
  margin-left: auto;
  margin-right: auto;
}

thead td {
  text-align: center;
}

td {
  border: 2px solid #f0f0f0;
  border-collapse: collapse;
  padding: 0.2em 2em;
  font-size: larger;
  overflow-wrap: break-word;
}
</style>
