import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, isRef as _isRef, Fragment as _Fragment } from "vue"
import _imports_0 from '@/assets/images/profile.png'
import _imports_1 from '@/assets/images/availability.png'
import _imports_2 from '@/assets/images/resources.png'
import _imports_3 from '@/assets/images/upload_resources.png'
import _imports_4 from '@/assets/images/archive.png'
import _imports_5 from '@/assets/images/feedback.png'
import _imports_6 from '@/assets/images/invite_users.png'
import _imports_7 from '@/assets/images/guide.png'
import _imports_8 from '@/assets/images/settings.png'


const _hoisted_1 = { class: "sidebar sidebar-max min-height" }
const _hoisted_2 = { class: "text-align-center" }
const _hoisted_3 = { style: {"text-align":"center"} }
const _hoisted_4 = { class: "bold-text" }
const _hoisted_5 = {
  key: 0,
  style: {"font-weight":"300"}
}
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { class: "mt-2" }
const _hoisted_9 = { style: {"position":"relative"} }
const _hoisted_10 = { class: "notification-count" }
const _hoisted_11 = { style: {"position":"relative"} }
const _hoisted_12 = { class: "notification-count" }
const _hoisted_13 = {
  key: 0,
  class: "current-availability pl-3 mt-2"
}
const _hoisted_14 = { key: 0 }
const _hoisted_15 = ["checked"]
const _hoisted_16 = { key: 1 }
const _hoisted_17 = ["checked"]
const _hoisted_18 = { key: 2 }
const _hoisted_19 = { key: 3 }
const _hoisted_20 = ["checked"]
const _hoisted_21 = { key: 4 }
const _hoisted_22 = ["checked"]
const _hoisted_23 = { key: 5 }
const _hoisted_24 = ["checked"]
const _hoisted_25 = { key: 6 }
const _hoisted_26 = { key: 7 }
const _hoisted_27 = ["checked"]
const _hoisted_28 = { key: 8 }
const _hoisted_29 = ["checked"]
const _hoisted_30 = { key: 9 }
const _hoisted_31 = ["checked"]
const _hoisted_32 = { class: "sidebar sidebar-min min-height" }
const _hoisted_33 = { class: "mt-3" }
const _hoisted_34 = { style: {"position":"relative"} }
const _hoisted_35 = { class: "notification-count" }
const _hoisted_36 = { style: {"position":"relative"} }
const _hoisted_37 = { class: "notification-count" }

import { ref } from "vue";
import ProfilePicture from "@/components/user/ProfilePicture.vue";
import ProfileIcon from "@/components/user/ProfileIcon.vue";
import Dialog from "primevue/dialog";
import InviteUsersForm from "@/components/user/InviteUsersForm.vue";
import UserBadge from "@/components/user/UserBadge.vue";
import { useRouter, useRoute } from "vue-router";
import { useAvailabilityStore } from "@/stores/AvailabilityStore";
import { useNotificationStore } from "@/stores/NotificationsStore";
import { useAuthStore } from "@/stores/AuthStore";
import { showErrorToast } from "@/utils";
import PractitionerService from "@/services/practitioner.service.js";
import { useConfigStore } from "@/stores/ConfigStore";


export default /*@__PURE__*/_defineComponent({
  __name: 'PractitionerSidebar',
  setup(__props) {

const router = useRouter();
const route = useRoute();
const ConfigStore = useConfigStore();
const AvailabilityStore = useAvailabilityStore();
const AuthStore = useAuthStore();
const NotificationStore = useNotificationStore();

const settingsFields = ConfigStore.config.filter(
  (el) => el.table === "settings"
);

let currentUser = AuthStore.auth;
let isVisible = ref(false);
let pendingResourceRequests = ref(0);

const getPendingResourceRequests = () => {
  PractitionerService.getPendingResourceRequests().then(
    (response) => {
      pendingResourceRequests.value = response.data.pendingResourceCount;
    },
    (error) => {
      let res =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(res);
    }
  );
};

const getAvailability = () => {
  if (AvailabilityStore.status) {
    router.push({ name: "industry-practitioner-availability" });
  } else {
    AvailabilityStore.getAvailability().then(
      (response) => {
        router.push({ name: "industry-practitioner-availability" });
      },
      (error) => {
        let res =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        console.log(res);
      }
    );
  }
};

const showInviteOthers = () => {
  if (!AuthStore.profile)
    showErrorToast(
      "You need to complete your profile before you can access other parts of the web platform"
    );
  else if (!AvailabilityStore.status)
    showErrorToast(
      "You need to specify your availability before you can access other parts of the web platform"
    );
  else isVisible.value = true;
};

AvailabilityStore.getAvailability().catch(() => {
  router.push("/not-found");
});

NotificationStore.getNotification();
getPendingResourceRequests();

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(ProfilePicture),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(UserBadge)
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("span", _hoisted_4, _toDisplayString(_unref(currentUser).firstName + " " + _unref(currentUser).lastName), 1),
        _cache[17] || (_cache[17] = _createElementVNode("br", null, null, -1)),
        (_unref(currentUser).profile)
          ? (_openBlock(), _createElementBlock("span", _hoisted_5, [
              _createTextVNode(_toDisplayString(_unref(currentUser).profile.position), 1),
              _cache[4] || (_cache[4] = _createElementVNode("br", null, null, -1))
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("i", null, [
          (_unref(currentUser).nameOrg)
            ? (_openBlock(), _createElementBlock("span", _hoisted_6, [
                _createTextVNode(_toDisplayString(_unref(currentUser).nameOrg) + " ", 1),
                _cache[5] || (_cache[5] = _createElementVNode("br", null, null, -1))
              ]))
            : _createCommentVNode("", true),
          (_unref(currentUser).profile)
            ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_unref(currentUser).profile.city + " " + _unref(currentUser).profile.state), 1))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createVNode(_component_router_link, {
            to: {
            name: 'user-profile',
            params: { userId: _unref(currentUser).id },
          }
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", {
                class: _normalizeClass(["sidebar-button pl-3", [
              _unref(route).path.startsWith('/profile/')
                ? 'selected-sidebar-button'
                : 'unselected-sidebar-button',
            ]]),
                title: "View/edit your profile information."
              }, _cache[6] || (_cache[6] = [
                _createElementVNode("img", {
                  src: _imports_0,
                  class: "sidebar-image-large"
                }, null, -1),
                _createElementVNode("span", { class: "ml-3" }, "Profile", -1)
              ]), 2)
            ]),
            _: 1
          }, 8, ["to"]),
          _createElementVNode("div", {
            class: _normalizeClass(["sidebar-button pl-3", [
            _unref(route).path === '/practitioner/availability'
              ? 'selected-sidebar-button'
              : 'unselected-sidebar-button',
          ]]),
            onClick: _cache[0] || (_cache[0] = ($event: any) => (getAvailability())),
            title: "Indicate your availability and preferences here."
          }, _cache[7] || (_cache[7] = [
            _createElementVNode("img", {
              src: _imports_1,
              class: "sidebar-image-large"
            }, null, -1),
            _createElementVNode("span", { class: "ml-3" }, "Select Availability", -1)
          ]), 2),
          _createVNode(_component_router_link, { to: { name: 'view-resources' } }, {
            default: _withCtx(() => [
              _createElementVNode("div", {
                class: _normalizeClass(["sidebar-button pl-3", [
              _unref(route).path === '/practitioner/resources/view'
                ? 'selected-sidebar-button'
                : 'unselected-sidebar-button',
            ]]),
                title: "Respond to instructors’ requests for resources."
              }, [
                _cache[8] || (_cache[8] = _createElementVNode("img", {
                  src: _imports_2,
                  class: "sidebar-image-large"
                }, null, -1)),
                _createElementVNode("span", _hoisted_9, [
                  _createElementVNode("span", _hoisted_10, _toDisplayString(_unref(pendingResourceRequests)), 1)
                ]),
                _cache[9] || (_cache[9] = _createElementVNode("span", { class: "ml-3" }, "Request(s) for Resources", -1))
              ], 2)
            ]),
            _: 1
          }),
          _createVNode(_component_router_link, { to: { name: 'upload' } }, {
            default: _withCtx(() => [
              _createElementVNode("div", {
                class: _normalizeClass(["sidebar-button pl-3", [
              _unref(route).path === '/upload'
                ? 'selected-sidebar-button'
                : 'unselected-sidebar-button',
            ]]),
                title: "Share instructional pictures, videos, and documents with instructors here."
              }, _cache[10] || (_cache[10] = [
                _createElementVNode("img", {
                  src: _imports_3,
                  class: "sidebar-image-large",
                  alt: "Upload Resources"
                }, null, -1),
                _createElementVNode("span", { class: "ml-3" }, "Upload Project Details", -1)
              ]), 2)
            ]),
            _: 1
          }),
          _createVNode(_component_router_link, { to: { name: 'view-notifications' } }, {
            default: _withCtx(() => [
              _createElementVNode("div", {
                class: _normalizeClass(["sidebar-button pl-3", [
              _unref(route).path === '/practitioner/notifications'
                ? 'selected-sidebar-button'
                : 'unselected-sidebar-button',
            ]]),
                title: "Your notifications will appear here."
              }, [
                _createVNode(_component_font_awesome_icon, {
                  class: "sidebar-icon",
                  icon: "fa-regular fa-bell",
                  style: {"color":"#fcc62a"}
                }),
                _createElementVNode("span", _hoisted_11, [
                  _createElementVNode("span", _hoisted_12, _toDisplayString(_unref(NotificationStore).count), 1)
                ]),
                _cache[11] || (_cache[11] = _createElementVNode("span", { class: "ml-3" }, "View Notifications", -1))
              ], 2)
            ]),
            _: 1
          }),
          _createVNode(_component_router_link, { to: { name: 'view-past-course-request' } }, {
            default: _withCtx(() => [
              _createElementVNode("div", {
                class: _normalizeClass(["sidebar-button pl-3", [
              _unref(route).path === '/practitioner/course/past'
                ? 'selected-sidebar-button'
                : 'unselected-sidebar-button',
            ]]),
                title: "Here are your responses to previous course-support requests from instructors."
              }, _cache[12] || (_cache[12] = [
                _createElementVNode("img", {
                  src: _imports_4,
                  class: "sidebar-image-large"
                }, null, -1),
                _createElementVNode("span", { class: "ml-3" }, "Previous Course-Support", -1)
              ]), 2)
            ]),
            _: 1
          }),
          _createVNode(_component_router_link, {
            to: { name: 'feedback' },
            class: "nav-link"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", {
                class: _normalizeClass(["sidebar-button pl-3", [
              _unref(route).path === '/feedback'
                ? 'selected-sidebar-button'
                : 'unselected-sidebar-button',
            ]]),
                title: "Provide feedback to the Admin here."
              }, _cache[13] || (_cache[13] = [
                _createElementVNode("img", {
                  src: _imports_5,
                  class: "sidebar-image-large"
                }, null, -1),
                _createElementVNode("span", { class: "ml-3" }, "Feedback", -1)
              ]), 2)
            ]),
            _: 1
          }),
          _createElementVNode("div", {
            class: _normalizeClass(["sidebar-button pl-3", [
            _unref(isVisible) ? 'selected-sidebar-button' : 'unselected-sidebar-button',
          ]]),
            onClick: showInviteOthers,
            title: "Invite others to ConPEC here."
          }, _cache[14] || (_cache[14] = [
            _createElementVNode("img", {
              src: _imports_6,
              class: "sidebar-image-large"
            }, null, -1),
            _createElementVNode("span", { class: "ml-3" }, "Invite Potential Users", -1)
          ]), 2),
          _createVNode(_component_router_link, {
            to: { name: 'guide' },
            class: "nav-link"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", {
                class: _normalizeClass(["sidebar-button pl-3", [
              _unref(route).path === '/guide/'
                ? 'selected-sidebar-button'
                : 'unselected-sidebar-button',
            ]]),
                title: "Help/Guide"
              }, _cache[15] || (_cache[15] = [
                _createElementVNode("img", {
                  src: _imports_7,
                  class: "sidebar-image-large"
                }, null, -1),
                _createElementVNode("span", { class: "ml-3" }, "Help/Guide", -1)
              ]), 2)
            ]),
            _: 1
          }),
          (
            !_unref(settingsFields).find((el) => el.name === 'email')?.disabled ||
            !_unref(settingsFields).find((el) => el.name === 'reasonDelete')?.disabled
          )
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 0,
                to: { name: 'settings' },
                class: "nav-link"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", {
                    class: _normalizeClass(["sidebar-button pl-3", [
              _unref(route).path === '/settings/'
                ? 'selected-sidebar-button'
                : 'unselected-sidebar-button',
            ]]),
                    title: "Settings"
                  }, _cache[16] || (_cache[16] = [
                    _createElementVNode("img", {
                      src: _imports_8,
                      class: "sidebar-image-large"
                    }, null, -1),
                    _createElementVNode("span", { class: "ml-3" }, "Settings", -1)
                  ]), 2)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ])
      ]),
      (
        _unref(currentUser).profile && _unref(currentUser).profile.courseRequestType != null
      )
        ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
            _cache[32] || (_cache[32] = _createElementVNode("span", { style: {"font-size":"larger"} }, "Current Availability", -1)),
            _cache[33] || (_cache[33] = _createTextVNode()),
            _cache[34] || (_cache[34] = _createElementVNode("br", null, null, -1)),
            (
          JSON.parse(_unref(currentUser).profile.courseRequestType).includes('Seminar')
        )
              ? (_openBlock(), _createElementBlock("span", _hoisted_14, [
                  _createElementVNode("input", {
                    id: "Seminar",
                    type: "checkbox",
                    name: "Seminar",
                    disabled: "",
                    checked: 
            JSON.parse(_unref(currentUser).profile.courseRequestType).includes(
              'Seminar'
            )
          
                  }, null, 8, _hoisted_15),
                  _cache[18] || (_cache[18] = _createTextVNode("  Seminar        "))
                ]))
              : _createCommentVNode("", true),
            (
          JSON.parse(_unref(currentUser).profile.courseRequestType).includes(
            'Site Visit'
          )
        )
              ? (_openBlock(), _createElementBlock("span", _hoisted_16, [
                  _createElementVNode("input", {
                    id: "Site Visit",
                    type: "checkbox",
                    name: "Site Visit",
                    disabled: "",
                    checked: 
            JSON.parse(_unref(currentUser).profile.courseRequestType).includes(
              'Site Visit'
            )
          
                  }, null, 8, _hoisted_17),
                  _cache[19] || (_cache[19] = _createTextVNode("  Site Visit "))
                ]))
              : _createCommentVNode("", true),
            (
          JSON.parse(_unref(currentUser).profile.courseRequestType).includes(
            'Seminar'
          ) ||
          JSON.parse(_unref(currentUser).profile.courseRequestType).includes(
            'Site Visit'
          )
        )
              ? (_openBlock(), _createElementBlock("span", _hoisted_18, _cache[20] || (_cache[20] = [
                  _createElementVNode("br", null, null, -1)
                ])))
              : _createCommentVNode("", true),
            (
          JSON.parse(_unref(currentUser).profile.courseRequestType).includes('Workshop')
        )
              ? (_openBlock(), _createElementBlock("span", _hoisted_19, [
                  (
            JSON.parse(_unref(currentUser).profile.courseRequestType).includes(
              'Workshop'
            )
          )
                    ? (_openBlock(), _createElementBlock("input", {
                        key: 0,
                        id: "Workshop",
                        type: "checkbox",
                        name: "Workshop",
                        disabled: "",
                        checked: 
            JSON.parse(_unref(currentUser).profile.courseRequestType).includes(
              'Workshop'
            )
          
                      }, null, 8, _hoisted_20))
                    : _createCommentVNode("", true),
                  _cache[21] || (_cache[21] = _createTextVNode("  Workshop    "))
                ]))
              : _createCommentVNode("", true),
            (
          JSON.parse(_unref(currentUser).profile.courseRequestType).includes('Labs')
        )
              ? (_openBlock(), _createElementBlock("span", _hoisted_21, [
                  _createElementVNode("input", {
                    id: "Labs",
                    type: "checkbox",
                    name: "Labs",
                    disabled: "",
                    checked: 
            JSON.parse(_unref(currentUser).profile.courseRequestType).includes('Labs')
          
                  }, null, 8, _hoisted_22),
                  _cache[22] || (_cache[22] = _createTextVNode("   Labs "))
                ]))
              : _createCommentVNode("", true),
            (
          JSON.parse(_unref(currentUser).profile.courseRequestType).includes(
            'Guest Lecture'
          )
        )
              ? (_openBlock(), _createElementBlock("span", _hoisted_23, [
                  _cache[23] || (_cache[23] = _createElementVNode("br", null, null, -1)),
                  _createElementVNode("input", {
                    id: "Guest Lecture",
                    type: "checkbox",
                    name: "Guest Lecture",
                    disabled: "",
                    checked: 
            JSON.parse(_unref(currentUser).profile.courseRequestType).includes(
              'Guest Lecture'
            )
          
                  }, null, 8, _hoisted_24),
                  _cache[24] || (_cache[24] = _createTextVNode("  Guest Lecture "))
                ]))
              : _createCommentVNode("", true),
            (
          JSON.parse(_unref(currentUser).profile.courseRequestType).includes(
            'Guest Lecture'
          ) ||
          JSON.parse(_unref(currentUser).profile.courseRequestType).includes('Labs')
        )
              ? (_openBlock(), _createElementBlock("span", _hoisted_25, _cache[25] || (_cache[25] = [
                  _createElementVNode("br", null, null, -1)
                ])))
              : _createCommentVNode("", true),
            (
          JSON.parse(_unref(currentUser).profile.courseRequestType).includes(
            'Judge for Project'
          )
        )
              ? (_openBlock(), _createElementBlock("span", _hoisted_26, [
                  _createElementVNode("input", {
                    id: "Judge for Project",
                    type: "checkbox",
                    disabled: "",
                    checked: 
            JSON.parse(_unref(currentUser).profile.courseRequestType).includes(
              'Judge for Project'
            )
          ,
                    name: "Judge for Project"
                  }, null, 8, _hoisted_27),
                  _cache[26] || (_cache[26] = _createTextVNode("  Judge for Project ")),
                  _cache[27] || (_cache[27] = _createElementVNode("br", null, null, -1))
                ]))
              : _createCommentVNode("", true),
            (
          JSON.parse(_unref(currentUser).profile.courseRequestType).includes(
            'Mentor for Project'
          )
        )
              ? (_openBlock(), _createElementBlock("span", _hoisted_28, [
                  _createElementVNode("input", {
                    id: "Mentor for Project",
                    type: "checkbox",
                    disabled: "",
                    checked: 
            JSON.parse(_unref(currentUser).profile.courseRequestType).includes(
              'Mentor for Project'
            )
          ,
                    name: "Mentor for Project"
                  }, null, 8, _hoisted_29),
                  _cache[28] || (_cache[28] = _createTextVNode("  Mentor for Project ")),
                  _cache[29] || (_cache[29] = _createElementVNode("br", null, null, -1))
                ]))
              : _createCommentVNode("", true),
            (
          JSON.parse(_unref(currentUser).profile.courseRequestType).includes(
            'Sponsor for Project'
          )
        )
              ? (_openBlock(), _createElementBlock("span", _hoisted_30, [
                  _createElementVNode("input", {
                    id: "Sponsor for Project",
                    type: "checkbox",
                    disabled: "",
                    checked: 
            JSON.parse(_unref(currentUser).profile.courseRequestType).includes(
              'Sponsor for Project'
            )
          ,
                    name: "Sponsor for Project"
                  }, null, 8, _hoisted_31),
                  _cache[30] || (_cache[30] = _createTextVNode("  Sponsor for Project ")),
                  _cache[31] || (_cache[31] = _createElementVNode("br", null, null, -1))
                ]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_32, [
      _createVNode(ProfileIcon),
      _createElementVNode("div", null, [
        _createElementVNode("div", _hoisted_33, [
          _createVNode(_component_router_link, {
            to: {
            name: 'user-profile',
            params: { userId: _unref(currentUser).id },
          }
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", {
                class: _normalizeClass(["sidebar-button pl-3", [
              _unref(route).path === '/profile'
                ? 'selected-sidebar-button'
                : 'unselected-sidebar-button',
            ]]),
                title: "View/edit your profile information."
              }, _cache[35] || (_cache[35] = [
                _createElementVNode("img", {
                  src: _imports_0,
                  class: "sidebar-image-large"
                }, null, -1)
              ]), 2)
            ]),
            _: 1
          }, 8, ["to"]),
          _createElementVNode("div", {
            class: _normalizeClass(["sidebar-button pl-3", [
            _unref(route).path === '/practitioner/availability'
              ? 'selected-sidebar-button'
              : 'unselected-sidebar-button',
          ]]),
            onClick: _cache[1] || (_cache[1] = ($event: any) => (getAvailability())),
            title: "Indicate your availability and preferences."
          }, _cache[36] || (_cache[36] = [
            _createElementVNode("img", {
              src: _imports_1,
              class: "sidebar-image-large"
            }, null, -1),
            _createElementVNode("br", null, null, -1)
          ]), 2),
          _createVNode(_component_router_link, { to: { name: 'view-resources' } }, {
            default: _withCtx(() => [
              _createElementVNode("div", {
                class: _normalizeClass(["sidebar-button pl-3", [
              _unref(route).path === '/practitioner/resources/view'
                ? 'selected-sidebar-button'
                : 'unselected-sidebar-button',
            ]]),
                title: "Respond to instructors’ requests for resources."
              }, [
                _cache[37] || (_cache[37] = _createElementVNode("img", {
                  src: _imports_2,
                  class: "sidebar-image-large"
                }, null, -1)),
                _createElementVNode("span", _hoisted_34, [
                  _createElementVNode("span", _hoisted_35, _toDisplayString(_unref(pendingResourceRequests)), 1)
                ])
              ], 2)
            ]),
            _: 1
          }),
          _createVNode(_component_router_link, { to: { name: 'upload' } }, {
            default: _withCtx(() => [
              _createElementVNode("div", {
                class: _normalizeClass(["sidebar-button pl-3", [
              _unref(route).path.startsWith('/upload')
                ? 'selected-sidebar-button'
                : 'unselected-sidebar-button',
            ]]),
                title: "Share instructional pictures, videos, and documents with instructors here."
              }, _cache[38] || (_cache[38] = [
                _createElementVNode("img", {
                  src: _imports_3,
                  class: "sidebar-image-large",
                  alt: "Upload Project Details"
                }, null, -1)
              ]), 2)
            ]),
            _: 1
          }),
          _createVNode(_component_router_link, { to: { name: 'view-notifications' } }, {
            default: _withCtx(() => [
              _createElementVNode("div", {
                class: _normalizeClass(["sidebar-button pl-3", [
              _unref(route).path === '/practitioner/notifications'
                ? 'selected-sidebar-button'
                : 'unselected-sidebar-button',
            ]]),
                title: "Your notifications will appear here."
              }, [
                _createVNode(_component_font_awesome_icon, {
                  class: "sidebar-icon",
                  icon: "fa-regular fa-bell",
                  style: {"color":"#fcc62a"}
                }),
                _createElementVNode("span", _hoisted_36, [
                  _createElementVNode("span", _hoisted_37, _toDisplayString(_unref(NotificationStore).count), 1)
                ])
              ], 2)
            ]),
            _: 1
          }),
          _createVNode(_component_router_link, { to: { name: 'view-past-course-request' } }, {
            default: _withCtx(() => [
              _createElementVNode("div", {
                class: _normalizeClass(["sidebar-button pl-3", [
              _unref(route).path.startsWith('/practitioner/course/past')
                ? 'selected-sidebar-button'
                : 'unselected-sidebar-button',
            ]]),
                title: "Here are your responses to previous course-support requests from instructors."
              }, _cache[39] || (_cache[39] = [
                _createElementVNode("img", {
                  src: _imports_4,
                  class: "sidebar-image-large"
                }, null, -1)
              ]), 2)
            ]),
            _: 1
          }),
          _createVNode(_component_router_link, {
            to: { name: 'feedback' },
            class: "nav-link"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", {
                class: _normalizeClass(["sidebar-button pl-3", [
              _unref(route).path === '/feedback'
                ? 'selected-sidebar-button'
                : 'unselected-sidebar-button',
            ]]),
                title: "Provide feedback to the Admin here."
              }, _cache[40] || (_cache[40] = [
                _createElementVNode("img", {
                  src: _imports_5,
                  class: "sidebar-image-large"
                }, null, -1)
              ]), 2)
            ]),
            _: 1
          }),
          _createElementVNode("div", {
            class: _normalizeClass(["sidebar-button pl-3", [
            _unref(isVisible) ? 'selected-sidebar-button' : 'unselected-sidebar-button',
          ]]),
            onClick: showInviteOthers,
            title: "Invite others to ConPEC here."
          }, _cache[41] || (_cache[41] = [
            _createElementVNode("img", {
              src: _imports_6,
              class: "sidebar-image-large"
            }, null, -1)
          ]), 2),
          _createVNode(_component_router_link, {
            to: { name: 'guide' },
            class: "nav-link"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", {
                class: _normalizeClass(["sidebar-button pl-3", [
              _unref(route).path === '/guide/'
                ? 'selected-sidebar-button'
                : 'unselected-sidebar-button',
            ]]),
                title: "Help/Guide"
              }, _cache[42] || (_cache[42] = [
                _createElementVNode("img", {
                  src: _imports_7,
                  class: "sidebar-image-large"
                }, null, -1)
              ]), 2)
            ]),
            _: 1
          }),
          (
            !_unref(settingsFields).find((el) => el.name === 'email')?.disabled ||
            !_unref(settingsFields).find((el) => el.name === 'reasonDelete')?.disabled
          )
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 0,
                to: { name: 'settings' },
                class: "nav-link"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", {
                    class: _normalizeClass(["sidebar-button pl-3", [
              _unref(route).path === '/settings/'
                ? 'selected-sidebar-button'
                : 'unselected-sidebar-button',
            ]]),
                    title: "Settings"
                  }, _cache[43] || (_cache[43] = [
                    _createElementVNode("img", {
                      src: _imports_8,
                      class: "sidebar-image-large"
                    }, null, -1)
                  ]), 2)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _createVNode(_unref(Dialog), {
      visible: _unref(isVisible),
      "onUpdate:visible": _cache[3] || (_cache[3] = ($event: any) => (_isRef(isVisible) ? (isVisible).value = $event : isVisible = $event)),
      modal: "",
      header: "Invite people from your network",
      style: { width: '50vw' },
      breakpoints: { '768px': '90vw' }
    }, {
      default: _withCtx(() => [
        _createVNode(InviteUsersForm, {
          onHideDialog: _cache[2] || (_cache[2] = ($event: any) => (_isRef(isVisible) //@ts-ignore
 ? isVisible.value = false : isVisible = false))
        })
      ]),
      _: 1
    }, 8, ["visible"])
  ], 64))
}
}

})