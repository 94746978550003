<template>
  <div class="header-space min-height">
    <div class="flex home-container">
      <div class="mt-3 logo-container">
        <div class="text-align-center">
          <img
            id="profile-img"
            style="width: 50%; height: 50%"
            class="m-auto"
            src="@/assets/images/logo.png"
          />
          <p style="font-size: 1.5rem">
            ...<span class="bold-text logo-color">Con</span>necting
            <span class="bold-text logo-color">P</span>rofessional and
            <span class="bold-text logo-color">E</span>ducational
            <span class="bold-text logo-color">C</span>ommunities for Future
            Workforce Development.
          </p>
          <div class="mt-3" style="font-size: larger">
            Are you a practitioner that wants to give back?<br />
            Do you want to contribute to future workforce development?<br />
            If yes, then join us today.<router-link to="/register">
              Sign up</router-link
            >
            to connect with instructors seeking practitioners to support their
            pedagogical efforts in preparing students for the industry.<br /><br />

            Are you an instructor with practical course-support needs that
            practitioners can meet?<br />
            If yes, then <router-link to="/register">Sign up</router-link> today
            to get connected to a diverse pool of practitioners willing to meet
            your practical course-support needs.
          </div>
        </div>
      </div>
      <div class="mt-2 form-container">
        <div class="card card-container">
          <Form
            @submit="handleLogin"
            :validation-schema="schema"
            v-slot="{ values }"
          >
            <div class="form-group">
              <label for="username">Email</label>
              <Field name="username" type="text" class="form-control" />
              <ErrorMessage name="username" class="error-feedback" />
            </div>
            <div class="form-group">
              <label for="password">Password</label>
              <Field
                name="password"
                v-model="values.password"
                v-slot="{ field }"
                class="form-control"
              >
                <Password
                  v-model="values.password"
                  v-bind="field"
                  toggleMask
                  :feedback="false"
                  class="w-100"
                />
              </Field>
              <ErrorMessage name="password" class="error-feedback" />
            </div>

            <div class="form-group mt-2">
              <Field
                value="True"
                unchecked-value="False"
                name="keepSignedIn"
                type="checkbox"
              />&nbsp;Keep me Signed In <br />
            </div>

            <br />
            <div class="form-group text-align-center">
              <button class="btn btn-primary btn-block" :disabled="loading">
                <span
                  v-show="loading"
                  class="spinner-border spinner-border-sm"
                ></span>
                <span>Login</span>
              </button>
              <div class="mt-3" v-if="loginMessage">
                <div class="alert alert-danger" role="alert">
                  {{ loginMessage }}
                </div>
              </div>
              <div class="mt-3" style="color: #1a73e8">
                <router-link to="/forgotPassword" class="nav-link">
                  <span>Forgot Password?</span>
                </router-link>
              </div>
            </div>
          </Form>
          <div class="mt-5 text-align-center fs-1-5">
            Are You New Here?
            <br />
            <button
              class="btn btn-primary btn-block"
              style="margin: 10px 0px"
              :disabled="loading"
            >
              <router-link to="/register" class="nav-link">
                Sign Up
              </router-link>
            </button>
          </div>
        </div>
        <img
          style="width: 20%; height: 20%"
          class="m-auto"
          src="@/assets/images/site/web-security.png"
        />
        <div class="text-align-center">
          <h6 class="mx-auto">Secured web</h6>
        </div>
      </div>
    </div>
    <div class="moving-text-container">
      <marquee direction="right">
        {{
          Object.values(practitionerOrgList.map((el) => el.nameOrg)).join(
            "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
          )
        }}
      </marquee>
      <!--      <marquee>-->
      <!--        {{-->
      <!--          Object.values(instructorOrgList.map((el) => el.nameOrg)).join(-->
      <!--            "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"-->
      <!--          )-->
      <!--        }}-->
      <!--      </marquee>-->
    </div>
    <div class="feedback-container" v-if="content.length > 0">
      <p class="text-align-center feedback-heading">
        What users are saying about us.
      </p>
      <div class="flex justify-content-between">
        <div
          class="flex flex-grow-0 flex-shrink-1 align-items-center"
          style="margin: auto"
        >
          <button type="button" class="feedback-btn btn" @click="moveToPrev">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="48"
              height="48"
              viewBox="0 0 48 48"
              fill="none"
              stroke="#000"
              stroke-width="3"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="vertical-align-sub"
            >
              <polyline points="27,36 9,18 27,0"></polyline>
            </svg></button
          >&nbsp;
        </div>
        <div class="text-align-center feedback-text-container">
          <div class="row feedback-content">
            <p>{{ content[currentIndex].message }}</p>
          </div>
        </div>
        <div
          class="flex flex-grow-0 flex-shrink-1 align-items-center"
          style="margin: auto"
        >
          <button type="button" class="feedback-btn btn" @click="moveToNext">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="48"
              height="48"
              viewBox="0 -10 48 48"
              fill="none"
              stroke="#000"
              stroke-width="3"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="vertical-align-super"
            >
              <polyline points="9,36 27,18 9,0"></polyline>
            </svg>
          </button>
        </div>
      </div>
      <div class="text-align-center">
        <p class="customer-name mt-3 mb-1">
          {{
            content[currentIndex].user.firstName +
            " " +
            content[currentIndex].user.lastName
          }}
        </p>
        <p>
          <span> {{ content[currentIndex].user.nameOrg }}</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import Password from "primevue/password";
import UserService from "@/services/user.service.js";
import { useAuthStore } from "@/stores/AuthStore";
import { useCourseStore } from "@/stores/CourseStore";
import { useRoute, useRouter } from "vue-router";

const route = useRoute();
const router = useRouter();
const AuthStore = useAuthStore();
const CourseStore = useCourseStore();

const schema = yup.object().shape({
  username: yup
    .string()
    .email("Please Enter a valid email address")
    .required("Email is required!"),
  password: yup.string().required("Password is required!"),
});

let loading = ref(false);
let message = ref("");
let loginMessage = ref("");
let coeff = 1000 * 60 * 5;

const handleLogin = (user) => {
  loading.value = true;
  AuthStore.login(user).then(
    (res) => {
      console.log(res);
      if (route.query["redirect"]) {
        handleRedirect(route.query["redirect"]);
      } else if (AuthStore.role === "INSTRUCTOR")
        if (AuthStore.profile) router.push({ name: "instructor-feed" });
        else router.push({ name: "instructor-profile" });
      else if (AuthStore.role === "INDUSTRYPRACTITIONER")
        if (AuthStore.profile)
          router.push({ name: "industry-practitioner-feed" });
        else router.push({ name: "industry-practitioner-profile" });
      else if (AuthStore.role === "ADMIN") router.push({ name: "admin-feed" });
      else router.push("/home");
    },
    (error) => {
      console.log(error);
      let activationTime = error.response?.data?.activationTime;
      if (activationTime) {
        if (new Date(activationTime) > new Date(2029, 0, 1, 0, 0, 0)) {
          loginMessage.value =
            "Your account has been deactivated by the admin.";
        } else {
          let date = new Date(activationTime);
          let rounded = new Date(Math.ceil(date.getTime() / coeff) * coeff);
          loginMessage.value =
            "Your account has been deactivated by the admin. Your account will become active by " +
            rounded.toLocaleDateString() +
            " at " +
            new Date().toLocaleTimeString();
        }
      } else {
        loginMessage.value =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
      loading.value = false;
    }
  );
};

let currentIndex = ref(0);
let content = ref([]);
let instructorOrgList = ref([]);
let practitionerOrgList = ref([]);

const getFeedback = () => {
  UserService.getFeedback().then(
    (response) => {
      content.value = response.data;
    },
    (error) => {
      message.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};

const getOrgList = (roleType) => {
  UserService.getOrgList(roleType).then(
    (response) => {
      if (roleType == "1") instructorOrgList.value = response.data;
      else practitionerOrgList.value = response.data;
    },
    (error) => {
      message.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};

const handleRedirect = async (redirectURL) => {
  const notificationId = redirectURL.split("/").pop();
  console.log(notificationId);
  UserService.getNotification(notificationId).then(
    (response) => {
      const notification = response.data;
      notification.course_request.user = notification.user;
      CourseStore.addCourse(notification.course_request);
      if (AuthStore.role === "INDUSTRYPRACTITIONER")
        router.push({ name: "view-course-request-prac" });
      else if (AuthStore.role === "INSTRUCTOR") {
        CourseStore.setPractitioner(notification.user.id);
        router.push({ name: "view-industry-practitioner-ins" });
      } else router.push("/home");
    },
    (error) => {
      loading.value = false;
      router.push("/home");
    }
  );
};

getFeedback();
getOrgList("1");
getOrgList("2");

const moveToNext = () => {
  currentIndex.value = (currentIndex.value + 1) % content.value.length;
};

const moveToPrev = () => {
  if (currentIndex.value === 0) currentIndex.value = content.value.length - 1;
  else currentIndex.value = (currentIndex.value - 1) % content.value.length;
};

setInterval(() => {
  moveToNext();
}, 5000);
</script>

<style scoped>
.card-container.card {
  max-width: 60% !important;
  padding: 40px 40px 20px 40px;
}

.feedback-container {
  padding: 2rem 65px 1.5rem 65px;
  background: #fff;
  margin: 1em;
  color: #000;
}

.feedback-btn {
  margin: 0.25rem;
  padding: 0.25rem;
  border-radius: 10px;
}

.customer-name {
  font-family: lato;
  font-style: italic;
  font-weight: 900;
  font-size: 25px;
  line-height: 30px;
}

.feedback-heading {
  font-family: lato, sans-serif;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 42px;
  letter-spacing: 0;
  opacity: 1;
  transform: none;
}

.feedback-content {
  padding: 1rem 3rem 0rem 3rem;
}

.moving-text-container {
  overflow: hidden; /* To hide the text outside the container */
  font-size: 16px;
  font-weight: bold;
  color: #0d6efd;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  margin-top: 1rem;
}

.logo-color {
  color: #0d6efd;
}

.moving-text-lr {
  animation: moveLeftToRight 50s linear infinite; /* Adjust the duration and animation timing as needed */
  animation-delay: -5s;
}

.moving-text-rl {
  animation: moveRightToLeft 50s linear infinite; /* Adjust the duration and animation timing as needed */
  animation-delay: -5s;
}

@keyframes moveLeftToRight {
  from {
    transform: translateX(-100%); /* Move the text from left (off-screen) */
  }
  to {
    transform: translateX(100%); /* Move the text to the right (off-screen) */
  }
}

@keyframes moveRightToLeft {
  from {
    transform: translateX(100%); /* Move the text from right (off-screen) */
  }
  to {
    transform: translateX(-100%); /* Move the text to the left (off-screen) */
  }
}

.home-container {
  flex-direction: row;
}

.logo-container,
.form-container,
.feedback-text-container {
  width: 50%;
  padding-left: 1rem;
  padding-right: 1rem;
}

.header-space ~ div {
  position: relative;
  top: 4em;
}

.fs-1-5 {
  font-size: 1.5rem;
}

@media only screen and (max-width: 600px) {
  .home-container {
    flex-direction: column;
  }

  .card-container.card {
    max-width: 80% !important;
    padding: 10px 10px;
  }

  .logo-container,
  .form-container {
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .feedback-container {
    padding: 0;
  }

  .feedback-text-container {
    width: 80%;
  }

  .feedback-content {
    padding: 0.5rem 0.25rem 0rem 0.25rem;
  }

  .fs-1-5 {
    font-size: 1rem;
  }
}
</style>
