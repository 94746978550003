<template>
  <div class="min-height header-space">
    <div class="card card-container">
      <h5 class="text-align-center bold-text">Account Recovery</h5>
      <div class="text-align-center">
        Please enter the email address for which you want to reset your password
      </div>
      <Form @submit="handleForgotPassword" :validation-schema="schema">
        <div class="form-group">
          <label for="email">Email</label>
          <Field name="email" type="text" class="form-control" />
          <ErrorMessage name="email" class="error-feedback" />
        </div>

        <br />
        <div class="form-group text-align-center">
          <button class="btn btn-primary btn-block" :disabled="loading">
            <span
              v-show="loading"
              class="spinner-border spinner-border-sm"
            ></span>
            <span>Send Password Reset Link</span>
          </button>
        </div>
      </Form>
      <br />
      <div
        v-if="message"
        :class="successful ? 'alert alert-success' : 'error-feedback'"
        class="text-align-center"
      >
        {{ message }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import { ref } from "vue";
import AuthService from "@/services/auth.service";

const schema = yup.object().shape({
  email: yup.string().required("Username is required!"),
});

let loading = ref(false);
let successful = ref(false);
let message = ref("");

const handleForgotPassword = (data) => {
  loading.value = true;
  AuthService.forgotPassword(data).then(
    (res) => {
      message.value = res.data.message;
      successful.value = true;
      loading.value = false;
    },
    (error) => {
      loading.value = false;
      message.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};
</script>

<style scoped>
.min-height {
  min-height: 510px !important;
}
</style>
