<template>
  <Form
    @submit="sendInvite"
    :validation-schema="schema"
    v-slot="{ values, meta }"
  >
    <div
      class="form-group"
      v-if="!inviteUsersFields.find((el) => el.name === 'role')?.disabled"
    >
      <div class="form-check-inline">
        <label for="role"
          >User Type<span class="super font-color-red">*</span></label
        >
        <Field
          name="role"
          class="form-check-input"
          type="radio"
          value="Instructor"
        />
        <img
          src="@/assets/images/site/instructor.png"
          class="sidebar-image-xl ml-2 mr-1"
        />Instructor
      </div>
      <div class="form-check-inline lg:ml-3">
        <Field
          name="role"
          class="form-check-input"
          type="radio"
          value="IndustryPractitioner"
        />
        <img
          src="@/assets/images/site/practitioner.png"
          class="sidebar-image-xl ml-2 mr-1"
        />Industry Practitioner
      </div>
      <ErrorMessage name="role" class="error-feedback" />
    </div>

    <div
      class="form-group"
      v-if="!inviteUsersFields.find((el) => el.name === 'title')?.disabled"
    >
      <div v-if="values.role === 'IndustryPractitioner'" class="form-group">
        <label for="title"
          >Title<span class="super font-color-red">*</span></label
        >
        <Field
          v-model="values.title"
          name="title"
          type="text"
          class="dropdown"
          v-slot="{ field }"
        >
          <multiselect
            v-bind="field"
            v-model="values.title"
            :options="practitionerTitleOptions"
            mode="single"
            :searchable="true"
          />
        </Field>
      </div>
      <div v-else>
        <label for="title"
          >Title<span class="super font-color-red">*</span></label
        >
        <Field
          v-model="values.title"
          name="title"
          type="text"
          class="dropdown"
          v-slot="{ field }"
        >
          <multiselect
            v-bind="field"
            v-model="values.title"
            :options="instructorTitleOptions"
            mode="single"
            :searchable="true"
          />
        </Field>
      </div>
      <div v-if="values.title === 'Others'">
        <Field
          name="title-text"
          type="text"
          placeholder="Please Specify"
          class="form-control bg-others"
        />
      </div>
      <ErrorMessage name="title" class="error-feedback" />
    </div>

    <div
      class="form-group"
      v-if="!inviteUsersFields.find((el) => el.name === 'firstName')?.disabled"
    >
      <label for="firstName"
        >First Name<span class="super font-color-red">*</span></label
      >
      <Field name="firstName" type="text" class="form-control" />
      <ErrorMessage name="firstName" class="error-feedback" />
    </div>

    <div
      class="form-group"
      v-if="!inviteUsersFields.find((el) => el.name === 'firstName')?.disabled"
    >
      <label for="lastName"
        >Last Name<span class="super font-color-red">*</span></label
      >
      <Field name="lastName" type="text" class="form-control" />
      <ErrorMessage name="lastName" class="error-feedback" />
    </div>
    <div
      class="form-group"
      v-if="!inviteUsersFields.find((el) => el.name === 'email')?.disabled"
    >
      <label for="email"
        >Email<span class="super font-color-red">*</span>
        <span
          v-if="values.role === 'IndustryPractitioner'"
          class="font-color-blue"
        >
          (Please use your work/company email address)
        </span>
        <span v-else class="font-color-blue">
          (Please use your institutional email address)
        </span>
      </label>
      <Field name="email" type="email" class="form-control" />
      <ErrorMessage name="email" class="error-feedback" />
    </div>
    <br />
    <div class="form-group text-align-center">
      <button
        class="btn btn-primary btn-block"
        :disabled="loading || !meta.valid"
      >
        <span v-show="loading" class="spinner-border spinner-border-sm"></span>
        <span>Invite</span>
      </button>
    </div>
  </Form>
</template>

<script setup lang="ts">
import { ref } from "vue";
import * as yup from "yup";
import { Form, Field, ErrorMessage } from "vee-validate";
import Multiselect from "@vueform/multiselect";
import UserService from "@/services/user.service.js";
import {
  practitionerTitleOptions,
  instructorTitleOptions,
  showErrorToast,
  showToast,
} from "@/utils";
import { useConfigStore } from "@/stores/ConfigStore";

const ConfigStore = useConfigStore();
const inviteUsersFields = ConfigStore.config.filter(
  (el) => el.table === "invite_users"
);
let loading = ref(false);
let message = ref("");

// eslint-disable-next-line no-undef
const emits = defineEmits(["hideDialog"]);

const schema = yup.object().shape({
  fields: yup.array().default(inviteUsersFields),
  role: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "role")?.disabled,
    then: (schema) => schema.required("User Type is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  title: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "title")?.disabled,
    then: (schema) => schema.required("Title is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  title_text: yup.string().when("title", {
    is: (val) => val === "Others",
    then: (schema) => schema.required("Title is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  firstName: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "firstName")?.disabled,
    then: (schema) => schema.required("First Name is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  lastName: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "lastName")?.disabled,
    then: (schema) => schema.required("Last Name is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  email: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "email")?.disabled,
    then: (schema) =>
      schema
        .required("Email is required!")
        .email("Email is invalid!")
        .max(50, "Must be maximum 50 characters!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
});

const sendInvite = (data) => {
  loading.value = true;
  if (data.title === "Others") data.title = data["title_text"];

  UserService.sendInvite(data).then(
    (response) => {
      console.log(response.data);
      showToast("Invite sent successfully to user.");
      loading.value = false;
      emits("hideDialog");
    },
    (error) => {
      loading.value = false;
      console.log(error);
      message.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      showErrorToast(message.value);
      emits("hideDialog");
    }
  );
};
</script>

<style scoped>
.error-feedback {
  min-height: 1rem;
}
</style>
