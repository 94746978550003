<script setup lang="ts">
import CourseRequestForm from "@/components/instructor/CourseRequestForm.vue";
import InstructorSidebar from "@/components/instructor/InstructorSidebar.vue";
import BackButton from "@/components/user/BackButton.vue";
import { deptOptions, valid } from "@/utils";

import { useRouter } from "vue-router";
import { useCourseStore } from "@/stores/CourseStore";
import { useAuthStore } from "@/stores/AuthStore";

const router = useRouter();
const CourseStore = useCourseStore();
const AuthStore = useAuthStore();
const currentUser = AuthStore.auth;

let initialState = {
  academicLevel: [],
  ageRange: "",
  areaOfExpertise: "",
  classSize: "",
  courseRequestType: "",
  date: [] as string[],
  duration: "",
  ethnicity: "",
  gender: "",
  learning: [],
  levelEducation: "",
  needForPreVisit: "",
  noOfFreq: "",
  physicalDis: [],
  siteOffice: "",
  studentDept: [] as string[],
  studentDept_text: "",
  topic: "",
  time: [],
  time_text: "",
  visualRep: "",
  yearsOfExp: "",
  insDeliveryType: "",
  phone: "",
  phoneNumber: "",
  generalInfo: "",
  courseCode: "",
  courseTitle: "",
  priorKnowledge: "",
  typeProject: "",
  location: "",
  projectDescr: "",
  daysOfMeeting: "",
  startDate: "",
  endDate: "",
  classroomItems: "",
  assigmentConnected: "",
  additionalInformation: "",
  typeFirm: "",
  sizeFirm: "",
  typeFirm_text: "",
  semester: "",
  year: "",
};

if (CourseStore.status) {
  initialState.academicLevel = JSON.parse(
    CourseStore?.course?.academicLevel || '[""]'
  );
  initialState.ageRange = CourseStore.course.ageRange;
  initialState.areaOfExpertise = CourseStore.course.areaOfExpertise;
  initialState.classSize = CourseStore.course.classSize;
  initialState.courseRequestType = CourseStore.course.courseRequestType;
  initialState.date = JSON.parse(CourseStore?.course?.date);
  if (CourseStore.course.courseRequestType === "Mentor for Project") {
    const [year, month, day] = initialState.date[0].split("-");
    initialState.startDate = new Date(+year, +month - 1, +day).toLocaleString();
  }
  initialState.duration = CourseStore.course.duration;
  initialState.ethnicity = CourseStore.course.ethnicity;
  initialState.gender = CourseStore.course.gender;
  initialState.learning = JSON.parse(CourseStore?.course?.learning || '[""]');
  initialState.levelEducation = CourseStore.course.levelEducation;
  initialState.needForPreVisit = CourseStore.course.needForPreVisit;
  initialState.noOfFreq = CourseStore.course.noOfFreq;
  initialState.physicalDis = JSON.parse(CourseStore.course.physicalDis);
  initialState.siteOffice = CourseStore.course.siteOffice;
  initialState.studentDept = JSON.parse(
    CourseStore?.course?.studentDept || '[""]'
  );
  if (
    !deptOptions.some((el) => el.value === initialState.studentDept.at(-1)!)
  ) {
    initialState.studentDept_text = initialState.studentDept.at(-1)!;
    initialState.studentDept.pop();
  }
  initialState.time = JSON.parse(CourseStore?.course?.time);
  if (CourseStore.course.courseRequestType === "Mentor for Project") {
    initialState.time_text = initialState.time[0];
  }
  initialState.topic = CourseStore.course.topic;
  initialState.visualRep = CourseStore.course.visualRep;
  initialState.yearsOfExp = CourseStore.course.yearsOfExp;
  initialState.insDeliveryType = CourseStore.course.insDeliveryType;
  initialState.phone = currentUser.phone;
  initialState.phoneNumber = currentUser.phoneNumber;
  initialState.generalInfo = CourseStore.course.generalInfo;
  initialState.courseCode = CourseStore.course.courseCode;
  initialState.courseTitle = CourseStore.course.courseTitle;
  initialState.priorKnowledge = CourseStore.course.priorKnowledge;
  initialState.typeProject = CourseStore.course.typeProject;
  initialState.location = CourseStore.course.location;
  initialState.projectDescr = CourseStore.course.projectDescr;
  initialState.daysOfMeeting = CourseStore.course.daysOfMeeting;
  initialState.endDate = CourseStore.course.endDate;
  if (
    CourseStore.course.courseRequestType === "Mentor for Project" &&
    valid(initialState.endDate)
  ) {
    const [year, month, day] = initialState.endDate.split("-");
    initialState.endDate = new Date(+year, +month - 1, +day).toLocaleString();
  }
  initialState.classroomItems = CourseStore.course.classroomItems;
  initialState.assigmentConnected = CourseStore.course.assigmentConnected;
  initialState.additionalInformation = CourseStore.course.additionalInformation;
  initialState.typeFirm = CourseStore.course.typeFirm;
  initialState.sizeFirm = CourseStore.course.sizeFirm;
}

const formValues = {
  academicLevel: initialState.academicLevel,
  ageRange: initialState.ageRange,
  areaOfExpertise: initialState.areaOfExpertise,
  classSize: initialState.classSize,
  courseRequestType: initialState.courseRequestType,
  date: initialState.date,
  duration: initialState.duration,
  ethnicity: initialState.ethnicity,
  gender: initialState.gender,
  learning: initialState.learning,
  levelEducation: initialState.levelEducation,
  needForPreVisit: initialState.needForPreVisit,
  noOfFreq: initialState.noOfFreq,
  physicalDis: initialState.physicalDis,
  siteOffice: initialState.siteOffice,
  studentDept: initialState.studentDept,
  studentDept_text: initialState.studentDept_text,
  topic: initialState.topic,
  time: initialState.time,
  time_text: initialState.time_text,
  visualRep: initialState.visualRep,
  yearsOfExp: initialState.yearsOfExp,
  insDeliveryType: initialState.insDeliveryType,
  Email: "True",
  phone: initialState.phone,
  phoneNumber: initialState.phoneNumber,
  generalInfo: initialState.generalInfo,
  courseCode: initialState.courseCode,
  courseTitle: initialState.courseTitle,
  priorKnowledge: initialState.priorKnowledge,
  typeProject: initialState.typeProject,
  location: initialState.location,
  projectDescr: initialState.projectDescr,
  daysOfMeeting: initialState.daysOfMeeting,
  startDate: initialState.startDate,
  endDate: initialState.endDate,
  classroomItems: initialState.classroomItems,
  assigmentConnected: initialState.assigmentConnected,
  additionalInformation: initialState.additionalInformation,
  semester: initialState.semester,
  year: initialState.year,
  typeFirm: initialState.typeFirm,
  sizeFirm: initialState.sizeFirm,
};
</script>

<style scoped></style>

<template>
  <div class="grid-container">
    <instructor-sidebar></instructor-sidebar>
    <div class="m-3 min-height">
      <div class="back-grid">
        <back-button></back-button>
        <h2 style="text-align: center; font-weight: bold">
          Course-Support Request Information
        </h2>
      </div>
      <course-request-form
        :formValues="formValues"
        action="resubmit"
      ></course-request-form>
    </div>
  </div>
</template>
