<template>
  <practitioner-sidebar v-if="currentUser.roles === 'INDUSTRYPRACTITIONER'" />
  <instructor-sidebar v-else-if="currentUser.roles === 'INSTRUCTOR'" />
  <admin-sidebar v-else-if="currentUser.roles === 'ADMIN'" />
</template>

<script setup lang="ts">
import PractitionerSidebar from "@/components/PractitionerSidebar.vue";
import InstructorSidebar from "@/components/instructor/InstructorSidebar.vue";
import AdminSidebar from "@/components/admin/AdminSidebar.vue";
import { useAuthStore } from "@/stores/AuthStore";

const AuthStore = useAuthStore();

const currentUser = AuthStore.auth;
</script>

<style scoped></style>
