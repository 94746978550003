import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, isRef as _isRef, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, normalizeStyle as _normalizeStyle, createStaticVNode as _createStaticVNode } from "vue"
import _imports_0 from '@/assets/images/feedback.png'
import _imports_1 from '@/assets/images/bring_to_front.png'


const _hoisted_1 = { class: "flex flex-direction-row align-content-space-between justify-space-between" }
const _hoisted_2 = { class: "flex-grow-1 flex-shrink-0" }
const _hoisted_3 = { class: "flex-grow-0 flex-shrink-1" }
const _hoisted_4 = { class: "mb-2 flex flex-direction-row gap-4" }
const _hoisted_5 = {
  id: "instructor-filters",
  class: "w-50"
}
const _hoisted_6 = { class: "flex flex-direction-row" }
const _hoisted_7 = ["checked"]
const _hoisted_8 = ["checked"]
const _hoisted_9 = ["checked"]
const _hoisted_10 = { class: "flex flex-direction-row" }
const _hoisted_11 = ["checked"]
const _hoisted_12 = ["checked"]
const _hoisted_13 = ["checked"]
const _hoisted_14 = ["checked"]
const _hoisted_15 = { class: "flex flex-direction-row" }
const _hoisted_16 = ["checked"]
const _hoisted_17 = ["checked"]
const _hoisted_18 = {
  id: "practitioner-filters",
  class: "w-50"
}
const _hoisted_19 = { class: "flex flex-direction-row" }
const _hoisted_20 = ["checked"]
const _hoisted_21 = ["checked"]
const _hoisted_22 = ["checked"]
const _hoisted_23 = { class: "flex flex-direction-row" }
const _hoisted_24 = ["checked"]
const _hoisted_25 = ["checked"]
const _hoisted_26 = ["checked"]
const _hoisted_27 = { class: "flex flex-direction-row" }
const _hoisted_28 = ["checked"]
const _hoisted_29 = ["checked"]
const _hoisted_30 = { key: 0 }
const _hoisted_31 = { class: "flex flex-direction-row mt-3" }
const _hoisted_32 = {
  class: "ml-2 flex-shrink-0 flex-grow-0",
  style: {"width":"20%"}
}
const _hoisted_33 = { class: "flex flex-direction-row" }
const _hoisted_34 = { class: "mx-2 flex-grow-0" }
const _hoisted_35 = ["onClick"]
const _hoisted_36 = ["onClick"]
const _hoisted_37 = { key: 1 }
const _hoisted_38 = { class: "flex flex-direction-row gap-4" }
const _hoisted_39 = {
  class: "ml-2 flex-shrink-0 flex-grow-0",
  style: {"width":"50%"}
}
const _hoisted_40 = { class: "flex flex-direction-row" }
const _hoisted_41 = { class: "mx-2 flex-grow-0" }
const _hoisted_42 = ["onClick"]
const _hoisted_43 = ["onClick"]
const _hoisted_44 = {
  class: "ml-2 flex-shrink-0 flex-grow-0",
  style: {"width":"50%"}
}
const _hoisted_45 = { class: "flex flex-direction-row" }
const _hoisted_46 = { class: "mx-2 flex-grow-0" }
const _hoisted_47 = ["onClick"]
const _hoisted_48 = ["onClick"]

import { ref, onMounted, onUpdated } from "vue";
import xlsx from "xlsx/dist/xlsx.full.min.js";
import Multiselect from "@vueform/multiselect";
import { useAuthStore } from "@/stores/AuthStore";
import {
  courseReqTypeOptions,
  insTypeOptions,
  genderOptions,
  yearsOfExpOptions,
  ageRangeOptions,
  instructorTitleOptions,
  areaOfExpertiseOptions,
  typeOfFirmOptions,
  sizeOfFirmOptions,
  locationOptions,
  displayLabels,
  showErrorToast,
  valid,
  stateOptions,
  DashboardLabels,
  groupBy,
  mulGroupBy,
} from "@/utils";
import {
  CourseRequestFilters,
  PracAvailabilityFilters,
  Filters,
  DisplayData,
  Topic,
  DownloadData,
} from "@/types";
import AdminService from "@/services/admin.service";


export default /*@__PURE__*/_defineComponent({
  __name: 'DashboardMap',
  setup(__props) {

const AuthStore = useAuthStore();

let currentUser = AuthStore.auth;
let loading = ref(false);
let topicCategoryOptions = ref([] as string[]);
let subjectWillingCategoryOptions = ref([] as string[]);
let courseReqFilters = ref({} as CourseRequestFilters);
let pracAvailabilityFilters = ref({} as PracAvailabilityFilters);
let selectedColor = ref("");
let selectedColorPrac = ref("");
let dualMap = ref(false);
let prevDualMap = false;

let yearsOfExpFilters = {
  "1 - 5 years": ["1 - 5 years"],
  "6+ years": ["6+ years", "11+ years", "15+ years"],
  "11+ years": ["11+ years", "15+ years"],
  "15+ years": ["15+ years"],
};

let ageRangeFilters = {
  "18 – 25 years": ["18 – 25 years"],
  "26+ years": ["26+ years", "31+ years", "41+ years", "51+ years"],
  "31+ years": ["31+ years", "41+ years", "51+ years"],
  "41+ years": ["41+ years", "51+ years"],
  "51+ years": ["51+ years"],
};

let sizeOfFirmFilters = {
  "Less than 10 employees": ["Less than 10 employees"],
  "10+ employees": [
    "10+ employees",
    "20+ employees",
    "50+ employees",
    "100+ employees",
    "250+ employees",
    "500+ employees",
    "1,000+ employees",
  ],
  "20+ employees": [
    "20+ employees",
    "50+ employees",
    "100+ employees",
    "250+ employees",
    "500+ employees",
    "1,000+ employees",
  ],
  "50+ employees": [
    "50+ employees",
    "100+ employees",
    "250+ employees",
    "500+ employees",
    "1,000+ employees",
  ],
  "100+ employees": [
    "100+ employees",
    "250+ employees",
    "500+ employees",
    "1,000+ employees",
  ],
  "250+ employees": ["250+ employees", "500+ employees", "1,000+ employees"],
  "500+ employees": ["500+ employees", "1,000+ employees"],
  "1,000+ employees": ["1,000+ employees"],
};

let cities = [
  {
    city: "Blacksburg",
    lat: 37.228804,
    long: -80.427313,
  },
  {
    city: "Roanoke",
    lat: 37.270969,
    long: -79.941429,
  },
  {
    city: "Richmond",
    lat: 37.540726,
    long: -77.43605,
  },
  {
    city: "Washington DC",
    lat: 38.907192,
    long: -77.036873,
  },
  {
    city: "Auburn",
    lat: 32.609856,
    long: -85.480782,
  },
  {
    city: "Peoria",
    lat: 32.609856,
    long: -89.588989,
  },
  {
    city: "New York City",
    lat: 40.712776,
    long: -74.005974,
  },
  {
    city: "Fairfax",
    lat: 38.846226,
    long: -77.306374,
  },
  {
    city: "McLean",
    lat: 38.937592,
    long: -77.183777,
  },
  {
    city: "Chesapeake",
    lat: 36.768208,
    long: -76.287491,
  },
  {
    city: "Boulder",
    lat: 40.014984,
    long: -105.270546,
  },
  {
    city: "Reston",
    lat: 38.96019,
    long: -77.3545,
  },
  {
    city: "Laramie",
    lat: 41.3114,
    long: -105.5911,
  },
  {
    city: "Norfolk",
    lat: 36.8466,
    long: -76.2855,
  },
];
let courseRequest = [
  {
    courseRequestType: "Guest Lecture",
    insType: "Standard demographics",
    ageRange: "26+ years",
    yearsOfExp: null,
    gender: "Male",
    title: "Senior Professor of Practice",
    city: "Blacksburg",
    state: "VA",
    topic:
      "Construction Technologies/ Project Management/ Construction Engineering",
  },
  {
    courseRequestType: "Site Visit",
    insType: "Predominantly White Institution",
    ageRange: "18 – 25 years",
    yearsOfExp: "1 - 5 years",
    gender: "Male",
    title: "Professor",
    city: "Blacksburg",
    state: "VA",
    topic: "Site Logistics",
  },
  {
    courseRequestType: "Guest Lecture",
    insType: "Predominantly White Institution",
    ageRange: "18 – 25 years",
    yearsOfExp: "1 - 5 years",
    gender: "Male",
    title: "Professor",
    city: "Blacksburg",
    state: "VA",
    topic: "Site Logistics",
  },
  {
    courseRequestType: "Site Visit",
    insType: "Predominantly White Institution",
    ageRange: "18 – 25 years",
    yearsOfExp: "1 - 5 years",
    gender: "Male",
    title: "Professor",
    city: "Blacksburg",
    state: "VA",
    topic: "Site Logistics",
  },
  {
    courseRequestType: "Workshop",
    insType: "Predominantly White Institution",
    ageRange: "18 – 25 years",
    yearsOfExp: "1 - 5 years",
    gender: "Male",
    title: "Professor",
    city: "Blacksburg",
    state: "VA",
    topic: "Site Logistics",
  },
  {
    courseRequestType: "Seminar",
    insType: "Predominantly White Institution",
    ageRange: "18 – 25 years",
    yearsOfExp: "1 - 5 years",
    gender: "Male",
    title: "Professor",
    city: "Blacksburg",
    state: "VA",
    topic: "Site Logistics",
  },
  {
    courseRequestType: "Guest Lecture",
    insType: "Predominantly White Institution",
    ageRange: "18 – 25 years",
    yearsOfExp: "1 - 5 years",
    gender: "Male",
    title: "Professor",
    city: "Blacksburg",
    state: "VA",
    topic: "Site Logistics",
  },
  {
    courseRequestType: "Seminar",
    insType: "Predominantly White Institution",
    ageRange: "18 – 25 years",
    yearsOfExp: "1 - 5 years",
    gender: "Male",
    title: "Professor",
    city: "Blacksburg",
    state: "VA",
    topic: "Site Logistics",
  },
  {
    courseRequestType: "Labs",
    insType: "Predominantly White Institution",
    ageRange: "18 – 25 years",
    yearsOfExp: "1 - 5 years",
    gender: "Male",
    title: "Professor",
    city: "Blacksburg",
    state: "VA",
    topic: "Site Logistics",
  },
  {
    courseRequestType: "Site Visit",
    insType: "Predominantly White Institution",
    ageRange: "18 – 25 years",
    yearsOfExp: "1 - 5 years",
    gender: "Male",
    title: "Professor",
    city: "Blacksburg",
    state: "VA",
    topic: "Site logitics",
  },
  {
    courseRequestType: "Seminar",
    insType: "Predominantly White Institution",
    ageRange: "18 – 25 years",
    yearsOfExp: "1 - 5 years",
    gender: "Male",
    title: "Professor",
    city: "Blacksburg",
    state: "VA",
    topic: "Site Safety",
  },
  {
    courseRequestType: "Site Visit",
    insType: "Predominantly White Institution",
    ageRange: "18 – 25 years",
    yearsOfExp: "1 - 5 years",
    gender: "Male",
    title: "Professor",
    city: "Blacksburg",
    state: "VA",
    topic: "Site Logistics",
  },
  {
    courseRequestType: "Seminar",
    insType: "Predominantly White Institution",
    ageRange: "18 – 25 years",
    yearsOfExp: "1 - 5 years",
    gender: "Male",
    title: "Professor",
    city: "Blacksburg",
    state: "VA",
    topic: "Site Logistics",
  },
  {
    courseRequestType: "Guest Lecture",
    insType: "Predominantly White Institution",
    ageRange: "18 – 25 years",
    yearsOfExp: "1 - 5 years",
    gender: "Male",
    title: "Professor",
    city: "Blacksburg",
    state: "VA",
    topic: "Site Logistics",
  },
  {
    courseRequestType: "Site Visit",
    insType: "Predominantly White Institution",
    ageRange: "18 – 25 years",
    yearsOfExp: "1 - 5 years",
    gender: "Male",
    title: "Professor",
    city: "Blacksburg",
    state: "VA",
    topic: "Site Logistics",
  },
  {
    courseRequestType: "Seminar",
    insType: "Predominantly White Institution",
    ageRange: "18 – 25 years",
    yearsOfExp: "1 - 5 years",
    gender: "Male",
    title: "Professor",
    city: "Blacksburg",
    state: "VA",
    topic: "Site Logistics",
  },
  {
    courseRequestType: "Seminar",
    insType: "Predominantly White Institution",
    ageRange: "18 – 25 years",
    yearsOfExp: "1 - 5 years",
    gender: "Male",
    title: "Professor",
    city: "Blacksburg",
    state: "VA",
    topic: "Site Logistics",
  },
  {
    courseRequestType: "Site Visit",
    insType: "Predominantly White Institution",
    ageRange: "18 – 25 years",
    yearsOfExp: "1 - 5 years",
    gender: "Male",
    title: "Professor",
    city: "Blacksburg",
    state: "VA",
    topic: "Site Logistics",
  },
  {
    courseRequestType: "Seminar",
    insType: "Predominantly White Institution",
    ageRange: "18 – 25 years",
    yearsOfExp: "1 - 5 years",
    gender: "Male",
    title: "Professor",
    city: "Blacksburg",
    state: "VA",
    topic: "Site Logistics",
  },
  {
    courseRequestType: "Site Visit",
    insType: "Predominantly White Institution",
    ageRange: "18 – 25 years",
    yearsOfExp: "1 - 5 years",
    gender: "Male",
    title: "Professor",
    city: "Blacksburg",
    state: "VA",
    topic: "Site Logistics",
  },
  {
    courseRequestType: "Seminar",
    insType: "Predominantly White Institution",
    ageRange: "18 – 25 years",
    yearsOfExp: "1 - 5 years",
    gender: "Male",
    title: "Professor",
    city: "Blacksburg",
    state: "VA",
    topic: "Site Logistics",
  },
  {
    courseRequestType: "Workshop",
    insType: "Predominantly White Institution",
    ageRange: "18 – 25 years",
    yearsOfExp: "1 - 5 years",
    gender: "Male",
    title: "Professor",
    city: "Blacksburg",
    state: "VA",
    topic: "Site Logistics",
  },
  {
    courseRequestType: "Site Visit",
    insType: "Predominantly White Institution",
    ageRange: "18 – 25 years",
    yearsOfExp: "1 - 5 years",
    gender: "Male",
    title: "Professor",
    city: "Blacksburg",
    state: "VA",
    topic: "Site Logistics",
  },
  {
    courseRequestType: "Workshop",
    insType: "Predominantly White Institution",
    ageRange: "18 – 25 years",
    yearsOfExp: "1 - 5 years",
    gender: "Male",
    title: "Professor",
    city: "Blacksburg",
    state: "VA",
    topic: "Site Logistics",
  },
  {
    courseRequestType: "Seminar",
    insType: "Predominantly White Institution",
    ageRange: "18 – 25 years",
    yearsOfExp: "1 - 5 years",
    gender: "Male",
    title: "Professor",
    city: "Blacksburg",
    state: "VA",
    topic: "Site Logistics",
  },
  {
    courseRequestType: "Seminar",
    insType: "Predominantly White Institution",
    ageRange: "18 – 25 years",
    yearsOfExp: "1 - 5 years",
    gender: "Male",
    title: "Professor",
    city: "Blacksburg",
    state: "VA",
    topic: "Site Logistics",
  },
  {
    courseRequestType: "Site Visit",
    insType: "Predominantly White Institution",
    ageRange: "18 – 25 years",
    yearsOfExp: "1 - 5 years",
    gender: "Male",
    title: "Professor",
    city: "Blacksburg",
    state: "VA",
    topic: "Site Logistics",
  },
  {
    courseRequestType: "Seminar",
    insType: "Predominantly White Institution",
    ageRange: "18 – 25 years",
    yearsOfExp: "1 - 5 years",
    gender: "Male",
    title: "Professor",
    city: "Blacksburg",
    state: "VA",
    topic: "Site Logistics",
  },
  {
    courseRequestType: "Guest Lecture",
    insType: "Predominantly White Institution",
    ageRange: "18 – 25 years",
    yearsOfExp: "1 - 5 years",
    gender: "Male",
    title: "Professor",
    city: "Blacksburg",
    state: "VA",
    topic: "Site Logistics",
  },
  {
    courseRequestType: "Seminar",
    insType: "Predominantly White Institution",
    ageRange: "18 – 25 years",
    yearsOfExp: "1 - 5 years",
    gender: "Male",
    title: "Professor",
    city: "Blacksburg",
    state: "VA",
    topic: "Site Logistics",
  },
  {
    courseRequestType: "Workshop",
    insType: "Predominantly White Institution",
    ageRange: "18 – 25 years",
    yearsOfExp: "1 - 5 years",
    gender: "Male",
    title: "Professor",
    city: "Blacksburg",
    state: "VA",
    topic: "Site Logistics",
  },
  {
    courseRequestType: "Labs",
    insType: "Predominantly White Institution",
    ageRange: "18 – 25 years",
    yearsOfExp: "1 - 5 years",
    gender: "Male",
    title: "Professor",
    city: "Blacksburg",
    state: "VA",
    topic: "Structural analysis",
  },
  {
    courseRequestType: "Guest Lecture",
    insType: "Predominantly White Institution",
    ageRange: "18 – 25 years",
    yearsOfExp: "1 - 5 years",
    gender: "Male",
    title: "Professor",
    city: "Blacksburg",
    state: "VA",
    topic: "Structural analysis",
  },
  {
    courseRequestType: "Seminar",
    insType: "Predominantly White Institution",
    ageRange: "18 – 25 years",
    yearsOfExp: "1 - 5 years",
    gender: "Male",
    title: "Professor",
    city: "Blacksburg",
    state: "VA",
    topic: "Structural analysis",
  },
  {
    courseRequestType: "Seminar",
    insType: "Predominantly White Institution",
    ageRange: "18 – 25 years",
    yearsOfExp: "1 - 5 years",
    gender: "Male",
    title: "Professor",
    city: "Blacksburg",
    state: "VA",
    topic: "Site Logistics",
  },
  {
    courseRequestType: "Guest Lecture",
    insType: "Predominantly White Institution",
    ageRange: "18 – 25 years",
    yearsOfExp: "1 - 5 years",
    gender: "Male",
    title: "Professor",
    city: "Blacksburg",
    state: "VA",
    topic: "Site Logistics",
  },
  {
    courseRequestType: "Workshop",
    insType: "Predominantly White Institution",
    ageRange: "18 – 25 years",
    yearsOfExp: "1 - 5 years",
    gender: "Male",
    title: "Professor",
    city: "Blacksburg",
    state: "VA",
    topic: "Structural analysis",
  },
  {
    courseRequestType: "Guest Lecture",
    insType: "Predominantly White Institution",
    ageRange: "18 – 25 years",
    yearsOfExp: "1 - 5 years",
    gender: "Male",
    title: "Professor",
    city: "Blacksburg",
    state: "VA",
    topic: "Structural analysis",
  },
  {
    courseRequestType: "Workshop",
    insType: "Predominantly White Institution",
    ageRange: "18 – 25 years",
    yearsOfExp: "1 - 5 years",
    gender: "Male",
    title: "Professor",
    city: "Blacksburg",
    state: "VA",
    topic: "Site Logistics",
  },
  {
    courseRequestType: "Site Visit",
    insType: "Predominantly White Institution",
    ageRange: "18 – 25 years",
    yearsOfExp: "1 - 5 years",
    gender: "Male",
    title: "Professor",
    city: "Blacksburg",
    state: "VA",
    topic: "Site Logistics",
  },
  {
    courseRequestType: "Seminar",
    insType: "Predominantly White Institution",
    ageRange: "18 – 25 years",
    yearsOfExp: "1 - 5 years",
    gender: "Male",
    title: "Professor",
    city: "Blacksburg",
    state: "VA",
    topic: "Structural analysis",
  },
  {
    courseRequestType: "Guest Lecture",
    insType: "Predominantly White Institution",
    ageRange: "18 – 25 years",
    yearsOfExp: "1 - 5 years",
    gender: "Male",
    title: "Professor",
    city: "Blacksburg",
    state: "VA",
    topic: "Site Logistics",
  },
  {
    courseRequestType: "Guest Lecture",
    insType: "Predominantly White Institution",
    ageRange: "18 – 25 years",
    yearsOfExp: "1 - 5 years",
    gender: "Male",
    title: "Professor",
    city: "Blacksburg",
    state: "VA",
    topic: "Site Logistics",
  },
  {
    courseRequestType: "Seminar",
    insType: "Predominantly White Institution",
    ageRange: "18 – 25 years",
    yearsOfExp: "1 - 5 years",
    gender: "Male",
    title: "Professor",
    city: "Blacksburg",
    state: "VA",
    topic: "Drain, Waste and Vent Systems in Residential Buildings",
  },
  {
    courseRequestType: "Guest Lecture",
    insType: "Predominantly White Institution",
    ageRange: "18 – 25 years",
    yearsOfExp: "1 - 5 years",
    gender: "Male",
    title: "Professor",
    city: "Blacksburg",
    state: "VA",
    topic: "Site Logistics",
  },
  {
    courseRequestType: "Site Visit",
    insType: "Predominantly White Institution",
    ageRange: "18 – 25 years",
    yearsOfExp: "1 - 5 years",
    gender: "Male",
    title: "Professor",
    city: "Blacksburg",
    state: "VA",
    topic: "Frame Construction",
  },
  {
    courseRequestType: "Guest Lecture",
    insType: "Predominantly White Institution",
    ageRange: "18 – 25 years",
    yearsOfExp: "1 - 5 years",
    gender: "Male",
    title: "Professor",
    city: "Blacksburg",
    state: "VA",
    topic: "Drain, Waste and Vent Systems in Residential Buildings",
  },
  {
    courseRequestType: "Site Visit",
    insType: "Predominantly White Institution",
    ageRange: "18 – 25 years",
    yearsOfExp: "1 - 5 years",
    gender: "Male",
    title: "Professor",
    city: "Blacksburg",
    state: "VA",
    topic: "Frame Construction",
  },
  {
    courseRequestType: "Seminar",
    insType: "Predominantly White Institution",
    ageRange: "18 – 25 years",
    yearsOfExp: "1 - 5 years",
    gender: "Male",
    title: "Professor",
    city: "Blacksburg",
    state: "VA",
    topic: "Site Logistics",
  },
  {
    courseRequestType: "Workshop",
    insType: "Predominantly White Institution",
    ageRange: "18 – 25 years",
    yearsOfExp: "1 - 5 years",
    gender: "Male",
    title: "Professor",
    city: "Blacksburg",
    state: "VA",
    topic: "Site Logistics",
  },
  {
    courseRequestType: "Judge for Capstone Project",
    insType: "Predominantly White Institution",
    ageRange: "18 – 25 years",
    yearsOfExp: "1 - 5 years",
    gender: "Male",
    title: "Professor",
    city: "Blacksburg",
    state: "VA",
    topic: "Capstone Project",
  },
  {
    courseRequestType: "Site Visit",
    insType: "Predominantly White Institution",
    ageRange: "41+ years",
    yearsOfExp: "11+ years",
    gender: "Male",
    title: "Instructor",
    city: "Blacksburg",
    state: "VA",
    topic: "Deep foundations specifically piles",
  },
  {
    courseRequestType: "Site Visit",
    insType: "Predominantly White Institution",
    ageRange: "31+ years",
    yearsOfExp: "11+ years",
    gender: "Female",
    title: "Professor",
    city: "Blacksburg",
    state: "VA",
    topic: "hhhhh",
  },
  {
    courseRequestType: "Site Visit",
    insType: "Predominantly White Institution",
    ageRange: "26+ years",
    yearsOfExp: "6+ years",
    gender: "Male",
    title: "Adjunct Professor",
    city: "Blacksburg",
    state: "VA",
    topic: "Site Logistics",
  },
  {
    courseRequestType: "Seminar",
    insType: "Predominantly White Institution",
    ageRange: "26+ years",
    yearsOfExp: "6+ years",
    gender: "Male",
    title: "Adjunct Professor",
    city: "Blacksburg",
    state: "VA",
    topic: "rytrgh",
  },
  {
    courseRequestType: "Guest Lecture",
    insType: "Predominantly White Institution",
    ageRange: "31+ years",
    yearsOfExp: "1 - 5 years",
    gender: "Male",
    title: "Assistant Professor",
    city: "Blacksburg",
    state: "VA",
    topic: "Construction health and safety",
  },
  {
    courseRequestType: "Site Visit",
    insType: "Predominantly White Institution",
    ageRange: "26+ years",
    yearsOfExp: "1 - 5 years",
    gender: "Male",
    title: "Professor",
    city: "Blacksburg",
    state: "VA",
    topic: "Architecture",
  },
  {
    courseRequestType: "Seminar",
    insType: "Predominantly White Institution",
    ageRange: "26+ years",
    yearsOfExp: "1 - 5 years",
    gender: "Male",
    title: "Professor",
    city: "Blacksburg",
    state: "VA",
    topic: "Architecture",
  },
  {
    courseRequestType: "Seminar",
    insType: "Predominantly White Institution",
    ageRange: "26+ years",
    yearsOfExp: "1 - 5 years",
    gender: "Male",
    title: "Professor",
    city: "Blacksburg",
    state: "VA",
    topic: "Architecture",
  },
  {
    courseRequestType: "Guest Lecture",
    insType: "Predominantly White Institution",
    ageRange: "26+ years",
    yearsOfExp: "1 - 5 years",
    gender: "Female",
    title: "Assistant Professor of Practice",
    city: "Blacksburg",
    state: "VA",
    topic: "Construction Administration",
  },
  {
    courseRequestType: "Guest Lecture",
    insType: "Predominantly White Institution",
    ageRange: "31+ years",
    yearsOfExp: "6+ years",
    gender: "Female",
    title: "Assistant Professor of Practice",
    city: "Blacksburg",
    state: "VA",
    topic: "Cash Flow",
  },
  {
    courseRequestType: "Guest Lecture",
    insType: "Predominantly White Institution",
    ageRange: "61+ years",
    yearsOfExp: "15+ years",
    gender: "Female",
    title: "Associate Professor",
    city: "Blacksburg",
    state: "VA",
    topic: "Building Materials and Assemblies",
  },
  {
    courseRequestType: "Guest Lecture",
    insType: "Not Sure",
    ageRange: "41+ years",
    yearsOfExp: "6+ years",
    gender: "Female",
    title: "Assistant Professor",
    city: "Blacksburg",
    state: "VA",
    topic: "Project review",
  },
  {
    courseRequestType: "Mentor for Capstone Project",
    insType: "Predominantly White Institution",
    ageRange: "31+ years",
    yearsOfExp: "1 - 5 years",
    gender: "Female",
    title: "Professor of Practice",
    city: "Blacksburg",
    state: "VA",
    topic: "Mentorship",
  },
  {
    courseRequestType: "Seminar",
    insType: "Predominantly White Institution",
    ageRange: "31+ years",
    yearsOfExp: "6+ years",
    gender: "Male",
    title: "Associate Professor",
    city: "Blacksburg",
    state: "VA",
    topic: "Design Build",
  },
  {
    courseRequestType: "Guest Lecture",
    insType: "Predominantly White Institution",
    ageRange: "31+ years",
    yearsOfExp: "6+ years",
    gender: "Female",
    title: "Associate Professor",
    city: "Blacksburg",
    state: "VA",
    topic: "construction finance, economy",
  },
  {
    courseRequestType: "Seminar",
    insType: "Predominantly White Institution",
    ageRange: "31+ years",
    yearsOfExp: "1 - 5 years",
    gender: "Male",
    title: "Assistant Professor",
    city: "Blacksburg",
    state: "VA",
    topic: "Construction materials case studies",
  },
  {
    courseRequestType: "Guest Lecture",
    insType: "Predominantly White Institution",
    ageRange: "31+ years",
    yearsOfExp: "1 - 5 years",
    gender: "Female",
    title: "Assistant Professor",
    city: "Blacksburg",
    state: "VA",
    topic: "underground excavations",
  },
  {
    courseRequestType: "Guest Lecture",
    insType: "Predominantly White Institution",
    ageRange: "61+ years",
    yearsOfExp: "15+ years",
    gender: "Male",
    title: "Professor",
    city: "blacksburg",
    state: "VA",
    topic: "construction",
  },
  {
    courseRequestType: "Guest Lecture",
    insType: "Predominantly White Institution",
    ageRange: "41+ years",
    yearsOfExp: "6+ years",
    gender: "Male",
    title: "Associate Professor",
    city: "Blacksburg",
    state: "VA",
    topic: "Earth Retaining Structures (MSE Walls)",
  },
  {
    courseRequestType: "Guest Lecture",
    insType: "Predominantly White Institution",
    ageRange: "41+ years",
    yearsOfExp: "15+ years",
    gender: "Male",
    title: "Associate Professor",
    city: "Blacksburg",
    state: "VA",
    topic: "Conflict Management",
  },
  {
    courseRequestType: "Guest Lecture",
    insType: "Predominantly White Institution",
    ageRange: "41+ years",
    yearsOfExp: "15+ years",
    gender: "Female",
    title: "Associate Professor",
    city: "Blacksburg",
    state: "VA",
    topic: "BIM and Navisworks",
  },
  {
    courseRequestType: "Guest Lecture",
    insType: "Predominantly White Institution",
    ageRange: "41+ years",
    yearsOfExp: "1 - 5 years",
    gender: "Male",
    title: "Assistant Professor of Practice",
    city: "Blacksburg",
    state: "VA",
    topic: "Mechanical systems",
  },
  {
    courseRequestType: "Site Visit",
    insType: "Predominantly White Institution",
    ageRange: "41+ years",
    yearsOfExp: "1 - 5 years",
    gender: "Male",
    title: "Assistant Professor of Practice",
    city: "Blacksburg",
    state: "VA",
    topic: "Crane Operation",
  },
  {
    courseRequestType: "Guest Lecture",
    insType: "Predominantly White Institution",
    ageRange: "51+ years",
    yearsOfExp: "15+ years",
    gender: "Male",
    title: "Professor",
    city: "Blacksburg",
    state: "VA",
    topic: "Building Damages",
  },
  {
    courseRequestType: "Guest Lecture",
    insType: "Predominantly White Institution",
    ageRange: "51+ years",
    yearsOfExp: "11+ years",
    gender: "Female",
    title: "Associate Professor",
    city: "Blacksburg",
    state: "VA",
    topic: "CNC milling ",
  },
  {
    courseRequestType: "Seminar",
    insType: "Predominantly White Institution",
    ageRange: "31+ years",
    yearsOfExp: "11+ years",
    gender: "Male",
    title: "Research Associate",
    city: "Blacksburg",
    state: "VA",
    topic: "The use of Framed Buildings in Building Construction",
  },
  {
    courseRequestType: "Site Visit",
    insType: "Predominantly White Institution",
    ageRange: "31+ years",
    yearsOfExp: "11+ years",
    gender: "Male",
    title: "Research Associate",
    city: "Blacksburg",
    state: "VA",
    topic: "BLD 895 - Research Methods Class",
  },
  {
    courseRequestType: "Guest Lecture",
    insType: "Predominantly White Institution",
    ageRange: "31+ years",
    yearsOfExp: "6+ years",
    gender: "Female",
    title: "Assistant Professor",
    city: "Blacksburg",
    state: "VA",
    topic: "Technology and innovation on construction job sites",
  },
  {
    courseRequestType: "Guest Lecture",
    insType: "Predominantly White Institution",
    ageRange: "31+ years",
    yearsOfExp: "1 - 5 years",
    gender: "Male",
    title: "Assistant Professor",
    city: "Blacksburg",
    state: "VA",
    topic: "Temporary Structure construction",
  },
  {
    courseRequestType: "Workshop",
    insType: "Predominantly White Institution",
    ageRange: "51+ years",
    yearsOfExp: "15+ years",
    gender: "Female",
    title: "Academic Advisor",
    city: "Blacksburg",
    state: "VA",
    topic: "Negotiation Skills",
  },
];
let instructors = [
  {
    insType: "Predominantly White Institution",
    ageRange: "36 – 40 years",
    yearsOfExp: null,
    gender: "Male",
    title: "Mr",
    city: "Blacksburg",
    state: "VA",
  },
  {
    insType: "Predominantly White Institution",
    ageRange: "26 – 30 years",
    yearsOfExp: null,
    gender: "Male",
    title: "Mr",
    city: "Blacksburg",
    state: "VA",
  },
  {
    insType: "Standard demographics",
    ageRange: "26+ years",
    yearsOfExp: null,
    gender: "Male",
    title: "Senior Professor of Practice",
    city: "Blacksburg",
    state: "VA",
  },
  {
    insType: "Predominantly White Institution",
    ageRange: "18 – 25 years",
    yearsOfExp: "1 - 5 years",
    gender: "Male",
    title: "Professor",
    city: "Blacksburg",
    state: "VA",
  },
  {
    insType: "Predominantly White Institution",
    ageRange: "41+ years",
    yearsOfExp: null,
    gender: "Male",
    title: "Instructor",
    city: "Blacksburg",
    state: "VA",
  },
  {
    insType: "Predominantly White Institution",
    ageRange: "31+ years",
    yearsOfExp: "11+ years",
    gender: "Female",
    title: "Professor",
    city: "Blacksburg",
    state: "VA",
  },
  {
    insType: "Predominantly White Institution",
    ageRange: "26+ years",
    yearsOfExp: "6+ years",
    gender: "Male",
    title: "Adjunct Professor",
    city: "Blacksburg",
    state: "VA",
  },
  {
    insType: "Predominantly White Institution",
    ageRange: "31+ years",
    yearsOfExp: "1 - 5 years",
    gender: "Male",
    title: "Assistant Professor",
    city: "Blacksburg",
    state: "VA",
  },
  {
    insType: "Predominantly White Institution",
    ageRange: "26+ years",
    yearsOfExp: "1 - 5 years",
    gender: "Male",
    title: "Professor",
    city: "Blacksburg",
    state: "VA",
  },
  {
    insType: "Predominantly White Institution",
    ageRange: "26+ years",
    yearsOfExp: "1 - 5 years",
    gender: "Female",
    title: "Assistant Professor of Practice",
    city: "Blacksburg",
    state: "VA",
  },
  {
    insType: "Predominantly White Institution",
    ageRange: "31+ years",
    yearsOfExp: "6+ years",
    gender: "Female",
    title: "Assistant Professor of Practice",
    city: "Blacksburg",
    state: "VA",
  },
  {
    insType: "Predominantly White Institution",
    ageRange: "61+ years",
    yearsOfExp: "15+ years",
    gender: "Female",
    title: "Associate Professor",
    city: "Blacksburg",
    state: "VA",
  },
  {
    insType: "Not Sure",
    ageRange: "41+ years",
    yearsOfExp: "6+ years",
    gender: "Female",
    title: "Assistant Professor",
    city: "Blacksburg",
    state: "VA",
  },
  {
    insType: "Predominantly White Institution",
    ageRange: "31+ years",
    yearsOfExp: "1 - 5 years",
    gender: "Female",
    title: "Professor of Practice",
    city: "Blacksburg",
    state: "VA",
  },
  {
    insType: "Predominantly White Institution",
    ageRange: "31+ years",
    yearsOfExp: "6+ years",
    gender: "Male",
    title: "Associate Professor",
    city: "Blacksburg",
    state: "VA",
  },
  {
    insType: "Predominantly White Institution",
    ageRange: "31+ years",
    yearsOfExp: "6+ years",
    gender: "Female",
    title: "Associate Professor",
    city: "Blacksburg",
    state: "VA",
  },
  {
    insType: "Predominantly White Institution",
    ageRange: "31+ years",
    yearsOfExp: "1 - 5 years",
    gender: "Male",
    title: "Assistant Professor",
    city: "Blacksburg",
    state: "VA",
  },
  {
    insType: "Predominantly White Institution",
    ageRange: "31+ years",
    yearsOfExp: "1 - 5 years",
    gender: "Female",
    title: "Assistant Professor",
    city: "Blacksburg",
    state: "VA",
  },
  {
    insType: "Predominantly White Institution",
    ageRange: "61+ years",
    yearsOfExp: "15+ years",
    gender: "Male",
    title: "Professor",
    city: "Blacksburg",
    state: "VA",
  },
  {
    insType: "Predominantly White Institution",
    ageRange: "41+ years",
    yearsOfExp: "6+ years",
    gender: "Male",
    title: "Associate Professor",
    city: "Blacksburg",
    state: "VA",
  },
  {
    insType: "Predominantly White Institution",
    ageRange: "41+ years",
    yearsOfExp: "15+ years",
    gender: "Male",
    title: "Associate Professor",
    city: "Blacksburg",
    state: "VA",
  },
  {
    insType: "Predominantly White Institution",
    ageRange: "41+ years",
    yearsOfExp: "15+ years",
    gender: "Female",
    title: "Associate Professor",
    city: "Blacksburg",
    state: "VA",
  },
  {
    insType: "Predominantly White Institution",
    ageRange: "41+ years",
    yearsOfExp: "1 - 5 years",
    gender: "Male",
    title: "Assistant Professor of Practice",
    city: "Blacksburg",
    state: "VA",
  },
  {
    insType: "Predominantly White Institution",
    ageRange: "51+ years",
    yearsOfExp: "15+ years",
    gender: "Male",
    title: "Professor",
    city: "Blacksburg",
    state: "VA",
  },
  {
    insType: "Predominantly White Institution",
    ageRange: "51+ years",
    yearsOfExp: "11+ years",
    gender: "Female",
    title: "Associate Professor",
    city: "Blacksburg",
    state: "VA",
  },
  {
    insType: "Predominantly White Institution",
    ageRange: "31+ years",
    yearsOfExp: "11+ years",
    gender: "Male",
    title: "Research Associate",
    city: "Blacksburg",
    state: "VA",
  },
  {
    insType: "Predominantly White Institution",
    ageRange: "31+ years",
    yearsOfExp: "6+ years",
    gender: "Female",
    title: "Assistant Professor",
    city: "Blacksburg",
    state: "VA",
  },
  {
    insType: "Predominantly White Institution",
    ageRange: "31+ years",
    yearsOfExp: "1 - 5 years",
    gender: "Male",
    title: "Assistant Professor",
    city: "Blacksburg",
    state: "VA",
  },
  {
    insType: "Predominantly White Institution",
    ageRange: "51+ years",
    yearsOfExp: "15+ years",
    gender: "Female",
    title: "Academic Advisor",
    city: "Blacksburg",
    state: "VA",
  },
];
let pracAvail = [
  {
    gender: "Male",
    expertise: '["Construction Management"]',
    size: "1,000+ employees",
    type: "Design-Build firm",
    city: "Blacksburg",
    state: "VA",
    courseRequestType:
      '["Seminar","Site Visit","Workshop","Guest Lecture/Classroom Teaching","Judge for Capstone Project","Mentor for Capstone Project","Sponsor for Capstone Project","Labs","Others"]',
    subjectSpeak: "[]",
  },
  {
    gender: "Male",
    expertise: '["Health and Safety"]',
    size: "1,000+ employees",
    type: "Non-Residential Building Construction firm",
    city: "Washington DC",
    state: "DC",
    courseRequestType:
      '["Seminar","Site Visit","Workshop","Guest Lecture/Classroom Teaching","Judge for Capstone Project","Mentor for Capstone Project","Sponsor for Capstone Project","Labs"]',
    subjectSpeak: '["estimation","work schedule"]',
  },
  {
    gender: "Male",
    expertise: '["Construction Management"]',
    size: "1,000+ employees",
    type: "Federal/Heavy Construction firm",
    city: "Blacksburg",
    state: "VA",
    courseRequestType: '["Seminar"]',
    subjectSpeak: "[]",
  },
  {
    gender: "Female",
    expertise: '["Executive/Legal"]',
    size: "500+ employees",
    type: "Transportation and Highway Construction firm",
    city: "Laramie",
    state: "WY",
    courseRequestType:
      '["Seminar","Site Visit","Workshop","Guest Lecture/Classroom Teaching","Judge for Capstone Project","Mentor for Capstone Project","Sponsor for Capstone Project","Labs"]',
    subjectSpeak: '["Estimating","Lean Construction"]',
  },
  {
    gender: "Male",
    expertise:
      '["Project Management","Site Management","Others","Dispute Resolution"]',
    size: "1,000+ employees",
    type: "Custom Firm",
    city: "Blacksburg",
    state: "VA",
    courseRequestType: '["Seminar","Site Visit","Workshop"]',
    subjectSpeak: '["Construction History"]',
  },
  {
    gender: "Male",
    expertise: '["Construction Management"]',
    size: "20+ employees",
    type: "Design firms",
    city: "Auburn",
    state: "AL",
    courseRequestType: '["Site Visit"]',
    subjectSpeak: "[]",
  },
  {
    gender: "Male",
    expertise: '["Construction Management"]',
    size: "1,000+ employees",
    type: "Design firms",
    city: "Blacksburg",
    state: "VA",
    courseRequestType:
      '["Seminar","Workshop","Guest Lecture/Classroom Teaching","Judge for Capstone Project","Mentor for Capstone Project","Sponsor for Capstone Project","Labs"]',
    subjectSpeak: "[]",
  },
  {
    gender: "Female",
    expertise: '["Construction Management"]',
    size: "500+ employees",
    type: "Transportation and Highway Construction firm",
    city: "Blacksburg",
    state: "VA",
    courseRequestType:
      '["Seminar","Workshop","Guest Lecture/Classroom Teaching"]',
    subjectSpeak:
      '["Construction Management","Construction materials and methods"]',
  },
  {
    gender: "Male",
    expertise: '["Construction Management"]',
    size: "50+ employees",
    type: "Design-Build firm",
    city: "Blacksburg",
    state: "VA",
    courseRequestType:
      '["Seminar","Site Visit","Workshop","Guest Lecture/Classroom Teaching","Judge for Capstone Project","Mentor for Capstone Project","Sponsor for Capstone Project","Labs"]',
    subjectSpeak: '["","Framed Buildings"]',
  },
  {
    gender: "Female",
    expertise: '["Health and Safety"]',
    size: "20+ employees",
    type: "Federal/Heavy Construction firm",
    city: "Peoria",
    state: "AL",
    courseRequestType: '["Seminar","Site Visit","Judge for Capstone Project"]',
    subjectSpeak: '["game"]',
  },
  {
    gender: "Male",
    expertise:
      '["Construction Management","Project Management","Architecture"]',
    size: "100+ employees",
    type: "Design firms",
    city: "Blacksburg",
    state: "VA",
    courseRequestType:
      '["Seminar","Site Visit","Workshop","Guest Lecture/Classroom Teaching","Judge for Capstone Project","Mentor for Capstone Project","Sponsor for Capstone Project","Labs"]',
    subjectSpeak: '["Landscaping","Design"]',
  },
  {
    gender: "Male",
    expertise: '["Construction Management","Estimating","Project Management"]',
    size: "1,000+ employees",
    type: "Non-Residential Building Construction firm",
    city: "Roanoke",
    state: "VA",
    courseRequestType: '["Seminar","Site Visit"]',
    subjectSpeak: '["Estimating"]',
  },
  {
    gender: "Male",
    expertise:
      '["Construction Management","Building and Construction Inspection"]',
    size: "20+ employees",
    type: "Owners Representative",
    city: "Blacksburg",
    state: "VA",
    courseRequestType: '["Judge for Capstone Project"]',
    subjectSpeak: '["LEAN / Pull Planning","Construction Management"]',
  },
  {
    gender: "Male",
    expertise: '["Estimating","Construction Management","Others","BIM"]',
    size: "10+ employees",
    type: "General Contractor",
    city: "Blacksburg",
    state: "VA",
    courseRequestType:
      '["Seminar","Workshop","Guest Lecture/Classroom Teaching","Judge for Capstone Project","Mentor for Capstone Project"]',
    subjectSpeak: '["BIM","Estimating","SCheduling"]',
  },
  {
    gender: "Male",
    expertise:
      '["Construction Management","Health and Safety","Project Management","Architecture","Building and Construction Inspection"]',
    size: "20+ employees",
    type: "Government Agency/Department",
    city: "Blacksburg",
    state: "VA",
    courseRequestType:
      '["Seminar","Site Visit","Guest Lecture/Classroom Teaching","Judge for Capstone Project"]',
    subjectSpeak:
      '["Architecture","Construction","Design","project management","permitting","construction administration"]',
  },
  {
    gender: "Male",
    expertise:
      '["Construction Management","Estimating","Project Management","Building and Construction Inspection"]',
    size: "1,000+ employees",
    type: "General Contractor",
    city: "New York City",
    state: "NY",
    courseRequestType:
      '["Seminar","Site Visit","Judge for Capstone Project","Mentor for Capstone Project"]',
    subjectSpeak: '["Submittals","RFIs","Project coordination"]',
  },
  {
    gender: "Male",
    expertise:
      '["Construction Management","Health and Safety","Project Management"]',
    size: "1,000+ employees",
    type: "Federal/Heavy Construction firm",
    city: "Fairfax",
    state: "VA",
    courseRequestType:
      '["Seminar","Site Visit","Guest Lecture/Classroom Teaching","Mentor for Capstone Project"]',
    subjectSpeak: '["Earthwork","Utility Planning & Installation"]',
  },
  {
    gender: "Male",
    expertise:
      '["Construction Management","Project Management","Building and Construction Inspection","Site Management"]',
    size: "1,000+ employees",
    type: "Government Agency/Department",
    city: "Washington DC",
    state: "DC",
    courseRequestType:
      '["Seminar","Site Visit","Workshop","Guest Lecture/Classroom Teaching","Judge for Capstone Project","Mentor for Capstone Project"]',
    subjectSpeak: '["Program Management","Project Management"]',
  },
  {
    gender: "Male",
    expertise: '["Construction Management","Executive/Legal"]',
    size: "100+ employees",
    type: "General Contractor",
    city: "Richmond",
    state: "VA",
    courseRequestType: '["Guest Lecture/Classroom Teaching"]',
    subjectSpeak:
      '["Business of Construction","Business Development","Marketing","Procurement","Delivery Methods"]',
  },
  {
    gender: "Male",
    expertise:
      '["Construction Management","Project Management","Others","Construction Data Analytics"]',
    size: "10+ employees",
    type: "Construction Advisory Firm",
    city: "McLean",
    state: "VA",
    courseRequestType:
      '["Seminar","Workshop","Guest Lecture/Classroom Teaching","Judge for Capstone Project","Mentor for Capstone Project"]',
    subjectSpeak:
      '["Construction Technology","Project Management","Data Analytics"]',
  },
  {
    gender: "Female",
    expertise: '["Construction Management","Project Management"]',
    size: "1,000+ employees",
    type: "General Contractor",
    city: "McLean",
    state: "VA",
    courseRequestType:
      '["Seminar","Site Visit","Workshop","Guest Lecture/Classroom Teaching","Judge for Capstone Project","Labs"]',
    subjectSpeak:
      '["Project Management","Scheduling","Estimating","Change Order Management"]',
  },
  {
    gender: "Female",
    expertise:
      '["Executive/Legal","Others","Professional Development, Job Search"]',
    size: "250+ employees",
    type: "General Contractor",
    city: "Chesapeake",
    state: "VA",
    courseRequestType:
      '["Seminar","Site Visit","Workshop","Guest Lecture/Classroom Teaching","Judge for Capstone Project","Mentor for Capstone Project","Sponsor for Capstone Project"]',
    subjectSpeak:
      '["Resume Writing","How to acquire an internship.","Preparing for a job interview"]',
  },
  {
    gender: "Male",
    expertise:
      '["Construction Management","Project Management","Building and Construction Inspection","Health and Safety"]',
    size: "250+ employees",
    type: "Engineering Consulting ",
    city: "Roanoke",
    state: "VA",
    courseRequestType:
      '["Seminar","Site Visit","Workshop","Guest Lecture/Classroom Teaching","Judge for Capstone Project","Mentor for Capstone Project","Sponsor for Capstone Project"]',
    subjectSpeak:
      '["Geotechnical Engineering","IBC Chapter 17 Special Inspections","Lead and Asbestos Consulting","Structural Welding","Special Inspection Codes"]',
  },
  {
    gender: "Female",
    expertise:
      '["Construction Management","Project Management","Electrical Engineering","Virtual Design Construction Manager"]',
    size: "1,000+ employees",
    type: "General Contractor",
    city: "McLean",
    state: "VA",
    courseRequestType:
      '["Guest Lecture/Classroom Teaching","Mentor for Capstone Project"]',
    subjectSpeak: '["Underground risk mitigation"]',
  },
  {
    gender: "Male",
    expertise: '["Construction Management","Health and Safety"]',
    size: "50+ employees",
    type: "General Contractor",
    city: "Boulder",
    state: "CO",
    courseRequestType:
      '["Site Visit","Guest Lecture/Classroom Teaching","Judge for Capstone Project","Mentor for Capstone Project"]',
    subjectSpeak:
      '["Construction Management","Scheduling","Safety","Site Management"]',
  },
  {
    gender: "Male",
    expertise: '["Construction Management"]',
    size: "1,000+ employees",
    type: "General Contractor",
    city: "Reston",
    state: "VA",
    courseRequestType: '["Workshop"]',
    subjectSpeak: '["Healthcare Construction","Constructability"]',
  },
  {
    gender: "Male",
    expertise: '["Civil Engineering","Project Management"]',
    size: "10+ employees",
    type: "Government Agency/Department",
    city: "Blacksburg",
    state: "VA",
    courseRequestType:
      '["Site Visit","Guest Lecture/Classroom Teaching","Judge for Capstone Project"]',
    subjectSpeak:
      '["Project Management","Owner\'s Role","Project Procurement","Project Delivery Methods","Construction Budgets","Construction Schedules","OAC Relationships","Design Process"]',
  },
  {
    gender: "Male",
    expertise: '["Project Management","Architecture"]',
    size: "1,000+ employees",
    type: "General Contractor",
    city: "Mclean",
    state: "VA",
    courseRequestType:
      '["Seminar","Site Visit","Workshop","Guest Lecture/Classroom Teaching","Mentor for Capstone Project"]',
    subjectSpeak: '["Architecture","Sustainability","Construction Technology"]',
  },
  {
    gender: "Male",
    expertise: '["Construction Management"]',
    size: "20+ employees",
    type: "University",
    city: "Blacksburg",
    state: "VA",
    courseRequestType: '["Site Visit","Guest Lecture/Classroom Teaching"]',
    subjectSpeak:
      '["internships, employment opportunities, preparing for employment"]',
  },
  {
    gender: "Male",
    expertise: '["Project Management","MEP Specialist","MEP works"]',
    size: "1,000+ employees",
    type: "Higher Education",
    city: "Blacksburg",
    state: "VA",
    courseRequestType:
      '["Site Visit","Guest Lecture/Classroom Teaching","Labs"]',
    subjectSpeak: '["HVAC","Building Automation","Chilled Water management"]',
  },
  {
    gender: "Male",
    expertise:
      '["Construction Management","Project Management","Building and Construction Inspection"]',
    size: "1,000+ employees",
    type: "Government Agency/Department",
    city: "Blacksburg",
    state: "VA",
    courseRequestType: '["Site Visit","Guest Lecture/Classroom Teaching"]',
    subjectSpeak: '["Scope, Organization, Budget, Schedule"]',
  },
  {
    gender: "Male",
    expertise: '["Construction Management"]',
    size: "1,000+ employees",
    type: "Government Agency/Department",
    city: "Blacksburg",
    state: "VA",
    courseRequestType:
      '["Seminar","Site Visit","Workshop","Guest Lecture/Classroom Teaching","Judge for Capstone Project","Mentor for Capstone Project","Sponsor for Capstone Project","Labs"]',
    subjectSpeak: '["Estimating","scheduling","construction management"]',
  },
  {
    gender: "Male",
    expertise:
      '["Construction Management","Project Management","Site Superintendent"]',
    size: "1,000+ employees",
    type: "Government Agency/Department",
    city: "Blacksburg",
    state: "VA",
    courseRequestType:
      '["Seminar","Site Visit","Guest Lecture/Classroom Teaching"]',
    subjectSpeak: '["Construction Management "]',
  },
  {
    gender: "Male",
    expertise:
      '["Construction Management","Project Management","Civil Engineering"]',
    size: "1,000+ employees",
    type: "University/Institute of higher education",
    city: "Blacksburg",
    state: "VA",
    courseRequestType:
      '["Site Visit","Guest Lecture/Classroom Teaching","Sponsor for Capstone Project"]',
    subjectSpeak:
      '["Construction management from the owner\'s perspective","Leadership in construction","Organizational dynamics"]',
  },
  {
    gender: "Female",
    expertise:
      '["Project Management","Construction Management","Others","Landscape Architecture"]',
    size: "1,000+ employees",
    type: "Institutional",
    city: "Blacksburg",
    state: "VA",
    courseRequestType:
      '["Seminar","Site Visit","Workshop","Guest Lecture/Classroom Teaching","Judge for Capstone Project","Mentor for Capstone Project"]',
    subjectSpeak:
      '["Ecology","Design","Design Build","C","Construction Scheduling"]',
  },
  {
    gender: "Male",
    expertise:
      '["Construction Management","Project Management","Architecture","Architectural Engineering","Site Management","Virtual Design Construction Manager"]',
    size: "1,000+ employees",
    type: "Government Agency/Department",
    city: "Blacksburg",
    state: "VA",
    courseRequestType:
      '["Seminar","Site Visit","Workshop","Guest Lecture/Classroom Teaching","Judge for Capstone Project","Mentor for Capstone Project","Sponsor for Capstone Project","Labs"]',
    subjectSpeak: '["Architecture","Building Code"]',
  },
  {
    gender: "Male",
    expertise:
      '["Construction Management","Project Management","Building and Construction Inspection","Site Superintendent","Site Management","Health and Safety","Others","Quality Control"]',
    size: "250+ employees",
    type: "Government Agency/Department",
    city: "Blacksburg",
    state: "VA",
    courseRequestType:
      '["Site Visit","Guest Lecture/Classroom Teaching","Judge for Capstone Project"]',
    subjectSpeak:
      '["Quality Control","Inspection Processes","RFIs & Submittal Processes","Site Management","Field Supervision"]',
  },
  {
    gender: "Male",
    expertise:
      '["Estimating","Project Management","Mechanical Engineering","Others","HVAC"]',
    size: "1,000+ employees",
    type: "Mechanical, Electrical and Plumbing (MEP) firm",
    city: "Roanoke",
    state: "VA",
    courseRequestType:
      '["Seminar","Site Visit","Judge for Capstone Project","Sponsor for Capstone Project"]',
    subjectSpeak: '["HVAC","Energy Efficiency"]',
  },
  {
    gender: "Female",
    expertise: '["Construction Management"]',
    size: "20+ employees",
    type: "Government Agency/Department",
    city: "Blacksburg",
    state: "VA",
    courseRequestType: '["Site Visit"]',
    subjectSpeak: '["Safety"]',
  },
  {
    gender: "Female",
    expertise:
      '["Construction Management","Project Management","Electrical Engineering","Site Management","Electrician"]',
    size: "10+ employees",
    type: "Residential Building Construction firm",
    city: "Blacksburg",
    state: "VA",
    courseRequestType:
      '["Site Visit","Workshop","Guest Lecture/Classroom Teaching","Labs"]',
    subjectSpeak:
      '["Photovoltaics","Electrical Engineering","Renewable energy","project management"]',
  },
  {
    gender: "Male",
    expertise:
      '["Construction Management","Project Management","Carpenter","Roofer","Painter"]',
    size: "Less than 10 employees",
    type: "Government Agency/Department",
    city: "Blacksburg",
    state: "VA",
    courseRequestType:
      '["Seminar","Site Visit","Workshop","Guest Lecture/Classroom Teaching","Judge for Capstone Project"]',
    subjectSpeak:
      '["Project Management","Just in Time Delivery","Residential Construction"]',
  },
];

let colorOptions = [
  { label: "Blue", value: "#2A81CB" },
  { label: "Gold", value: "#FFD326" },
  { label: "Red", value: "#CB2B3E" },
  { label: "Green", value: "#2AAD27" },
  { label: "Orange", value: "#CB8427" },
  { label: "Black", value: "#3D3D3D" },
];

let displayData = ref([] as DisplayData[]);
let displayInsData = ref([] as DisplayData[]);
let displayPracData = ref([] as DisplayData[]);
let topics = [] as Topic[];
let subjectWillingTopic = [] as Topic[];
let map, insMap, pracMap;

const initialize = async () => {
  // const response = await fetch(
  //   "https://cdn.rawgit.com/johan/world.geo.json/34c96bba/countries/USA.geo.json"
  // );
  // const geoJSON = await response.json();

  // eslint-disable-next-line no-undef
  map = L.map("map").setView([37.395769, -99.113588], 4);
  // eslint-disable-next-line no-undef
  // insMap = L.map("insMap").setView([37.395769, -99.113588], 2);
  // eslint-disable-next-line no-undef
  // pracMap = L.map("pracMap").setView([37.395769, -99.113588], 2);

  // // eslint-disable-next-line no-undef
  // const osm = L.tileLayer.boundaryCanvas(
  //   "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
  //   {
  //     minZoom: 4,
  //     maxZoom: 10,
  //     boundary: geoJSON,
  //     attribution:
  //       '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors, USA shape <a href="https://github.com/johan/world.geo.json">johan/word.geo.json</a>',
  //   }
  // );
  // map.addLayer(osm);
  // // eslint-disable-next-line no-undef
  // const usaLayer = L.geoJSON(geoJSON);
  // map.fitBounds(usaLayer.getBounds());

  // eslint-disable-next-line no-undef
  L.tileLayer("https://tile.openstreetmap.org/{z}/{x}/{y}.png", {
    minZoom: 4,
    maxZoom: 10,
    attribution:
      '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
  }).addTo(map);
  // eslint-disable-next-line no-undef
  // L.tileLayer("https://tile.openstreetmap.org/{z}/{x}/{y}.png", {
  //   minZoom: 4,
  //   maxZoom: 10,
  //   attribution:
  //     '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
  // }).addTo(insMap);
  // eslint-disable-next-line no-undef
  // L.tileLayer("https://tile.openstreetmap.org/{z}/{x}/{y}.png", {
  //   minZoom: 4,
  //   maxZoom: 10,
  //   attribution:
  //     '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
  // }).addTo(pracMap);

  courseRequest.forEach((el) => {
    el["location"] = stateOptions.filter((e) => e.value === el.state)[0].region;
  });
  pracAvail.forEach((el) => {
    el["location"] = stateOptions.filter((e) => e.value === el.state)[0].region;
  });
};

const initializeDualMap = async () => {
  // eslint-disable-next-line no-undef
  insMap = L.map("insMap").setView([37.395769, -99.113588], 2);
  // eslint-disable-next-line no-undef
  pracMap = L.map("pracMap").setView([37.395769, -99.113588], 2);

  // eslint-disable-next-line no-undef
  L.tileLayer("https://tile.openstreetmap.org/{z}/{x}/{y}.png", {
    minZoom: 4,
    maxZoom: 10,
    attribution:
      '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
  }).addTo(insMap);
  // eslint-disable-next-line no-undef
  L.tileLayer("https://tile.openstreetmap.org/{z}/{x}/{y}.png", {
    minZoom: 4,
    maxZoom: 10,
    attribution:
      '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
  }).addTo(pracMap);
};

onUpdated(() => {
  if (prevDualMap != dualMap.value) {
    if (!dualMap.value) {
      initialize();
    } else {
      initializeDualMap();
    }
    prevDualMap = dualMap.value;
  }
});

const updateMapView = () => {
  if (!dualMap.value) {
    displayData.value.length = 0;
  } else {
    displayInsData.value.length = 0;
    displayPracData.value.length = 0;
  }
};

const downloadData = () => {
  const XLSX = xlsx;
  if (dualMap.value) {
    let exportInsData = [] as DownloadData[];
    let exportPracData = [] as DownloadData[];
    displayInsData.value.forEach((el) => {
      exportInsData.push({
        userType: el.userType,
        title: el.title,
        count: el.count,
        state: "",
        city: "",
        groupCount: null as unknown as number,
      });
      for (const [key, value] of Object.entries(el.groups)) {
        let cityState = JSON.parse(key);
        exportInsData.push({
          userType: "",
          title: "",
          count: null as unknown as number,
          state: cityState[0],
          city: cityState[1],
          groupCount: value.length,
        });
      }
    });
    displayPracData.value.forEach((el) => {
      exportPracData.push({
        userType: el.userType,
        title: el.title,
        count: el.count,
        state: "",
        city: "",
        groupCount: null as unknown as number,
      });
      for (const [key, value] of Object.entries(el.groups)) {
        let cityState = JSON.parse(key);
        exportPracData.push({
          userType: "",
          title: "",
          count: null as unknown as number,
          state: cityState[0],
          city: cityState[1],
          groupCount: value.length,
        });
      }
    });

    const exportData = [...exportInsData, ...exportPracData];
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(exportData);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "ConPEC_Dashboard_Output.xlsx");
  } else {
    console.log(displayData.value);
    let exportData = [] as DownloadData[];
    displayData.value.forEach((el) => {
      exportData.push({
        userType: el.userType,
        title: el.title,
        count: el.count,
        state: "",
        city: "",
        groupCount: null as unknown as number,
      });
      for (const [key, value] of Object.entries(el.groups)) {
        let cityState = JSON.parse(key);
        exportData.push({
          userType: "",
          title: "",
          count: null as unknown as number,
          state: cityState[0],
          city: cityState[1],
          groupCount: value.length,
        });
      }
    });
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(exportData);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "Conpec_Dashboard_Output.xlsx");
  }
};

const addData = () => {
  if (selectedColor.value.length == 0) {
    showErrorToast("Please select a color!");
    return;
  } else if (
    (!dualMap.value &&
      displayData.value.filter((el) => el.color == selectedColor.value).length >
        0) ||
    (dualMap.value &&
      displayInsData.value.filter((el) => el.color == selectedColor.value)
        .length > 0)
  ) {
    showErrorToast("Color already used");
    return;
  } else {
    let finalData: any[];
    let label = ref("" as string);
    if (valid(courseReqFilters.value["courseRequestType"])) {
      finalData = courseRequest.slice();
      finalData = filterData(finalData, label, "courseRequestType");
      finalData = filterData(finalData, label, "insType", true);
      finalData = filterData(
        finalData,
        label,
        "topicCategory",
        false,
        "Topic: "
      );
      finalData = filterData(finalData, label, "location");
      finalData = filterData(finalData, label, "state");
      finalData = filterData(finalData, label, "gender", true);
      finalData = filterData(finalData, label, "yearsOfExp", false, "YOE: ");
      finalData = filterData(finalData, label, "ageRange", false, "Age: ");
      finalData = filterData(finalData, label, "title");
    } else {
      finalData = instructors.slice();
      finalData = filterData(finalData, label, "insType", true);
      finalData = filterData(finalData, label, "location");
      finalData = filterData(
        finalData,
        label,
        "topicCategory",
        false,
        "Topic: "
      );
      finalData = filterData(finalData, label, "state");
      finalData = filterData(finalData, label, "gender", true);
      finalData = filterData(finalData, label, "yearsOfExp", false, "YOE: ");
      finalData = filterData(finalData, label, "ageRange", false, "Age: ");
      finalData = filterData(finalData, label, "title");
    }
    if (finalData.length == 0) {
      showErrorToast("Nothing to display!");
      return;
    } else {
      const title = label.value;
      label.value = label.value + ` : ${finalData.length}`;
      updateMap(
        "instructor",
        selectedColor.value,
        courseReqFilters.value,
        finalData,
        title,
        label.value
      );
    }
    // for (let prop in courseReqFilters.value) {
    //   delete courseReqFilters.value[prop];
    // }
    // multiselect.value.clear();
  }
};

const addDataPrac = () => {
  if (selectedColorPrac.value.length == 0) {
    showErrorToast("Please select a color!");
    return;
  } else if (
    (!dualMap.value &&
      displayData.value.filter((el) => el.color == selectedColorPrac.value)
        .length > 0) ||
    (dualMap.value &&
      displayPracData.value.filter((el) => el.color == selectedColorPrac.value)
        .length > 0)
  ) {
    showErrorToast("Color already used");
    return;
  } else {
    let finalData = pracAvail.slice();
    let label = ref("" as string);
    finalData = filterDataPrac(finalData, label, "courseRequestType");
    finalData = filterDataPrac(finalData, label, "gender", true);
    finalData = filterDataPrac(
      finalData,
      label,
      "topicCategory",
      false,
      "Topic: "
    );
    finalData = filterDataPrac(finalData, label, "size", false, "Size: ");
    finalData = filterDataPrac(finalData, label, "type", false, "Type: ");
    finalData = filterDataPrac(finalData, label, "expertise", false, "AOE: ");
    finalData = filterDataPrac(finalData, label, "location");
    finalData = filterDataPrac(finalData, label, "state");

    if (finalData.length == 0) {
      showErrorToast("Nothing to display!");
      return;
    } else {
      const title = label.value;
      label.value = label.value + ` : ${finalData.length}`;
      updateMap(
        "practitioner",
        selectedColorPrac.value,
        pracAvailabilityFilters.value,
        finalData,
        title,
        label.value
      );
    }
  }
};

const filterData = (
  data,
  label,
  filter,
  useLabel = false,
  filterHeading = ""
) => {
  if (!valid(courseReqFilters.value[filter])) {
    return data;
  } else {
    let finalData = [];
    for (let index in courseReqFilters.value[filter]) {
      let filteredData;
      if (filter === "yearsOfExp") {
        filteredData = data.filter((el) =>
          yearsOfExpFilters[courseReqFilters.value[filter][index]].includes(
            el[filter]
          )
        );
      } else if (filter === "ageRange") {
        filteredData = data.filter((el) =>
          ageRangeFilters[courseReqFilters.value[filter][index]].includes(
            el[filter]
          )
        );
      } else {
        filteredData = data.filter(
          (el) => el[filter] === courseReqFilters.value[filter][index]
        );
      }
      if (label.value.length > 0) label.value += " > ";
      if (useLabel)
        label.value += `${filterHeading}${
          DashboardLabels[courseReqFilters.value[filter][index]]
        }`;
      else
        label.value += `${filterHeading}${courseReqFilters.value[filter][index]}`;
      finalData.push.apply(finalData, filteredData);
    }
    return finalData;
  }
};

const filterDataPrac = (
  data,
  label,
  filter,
  useLabel = false,
  filterHeading = ""
) => {
  if (!valid(pracAvailabilityFilters.value[filter])) {
    return data;
  } else {
    let finalData = [];
    for (let index in pracAvailabilityFilters.value[filter]) {
      let filteredData;
      if (filter === "expertise" || filter === "courseRequestType") {
        filteredData = data.filter((el) =>
          JSON.parse(el[filter]).includes(
            pracAvailabilityFilters.value[filter][index]
          )
        );
      } else if (filter === "topicCategory") {
        filteredData = data.filter((el) =>
          el[filter].includes(pracAvailabilityFilters.value[filter][index])
        );
      } else if (filter === "size") {
        filteredData = data.filter((el) =>
          sizeOfFirmFilters[
            pracAvailabilityFilters.value[filter][index]
          ].includes(el[filter])
        );
      } else {
        filteredData = data.filter(
          (el) => el[filter] === pracAvailabilityFilters.value[filter][index]
        );
      }
      if (label.value.length > 0) label.value += " > ";
      if (useLabel)
        label.value += `${filterHeading}${
          DashboardLabels[pracAvailabilityFilters.value[filter][index]]
        }`;
      else
        label.value += `${filterHeading}${pracAvailabilityFilters.value[filter][index]}`;
      finalData.push.apply(finalData, filteredData);
    }
    return finalData;
  }
};

const updateMap = (userType, selectedColor, filters, data, title, label) => {
  let className = "";
  var icon;
  switch (selectedColor) {
    case "#2A81CB":
      className = "marker-cluster-blue";
      // eslint-disable-next-line no-undef
      icon = new L.Icon({
        iconUrl:
          "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-blue.png",
        shadowUrl:
          "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41],
      });
      break;
    case "#FFD326":
      className = "marker-cluster-gold";
      // eslint-disable-next-line no-undef
      icon = new L.Icon({
        iconUrl:
          "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-gold.png",
        shadowUrl:
          "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41],
      });
      break;
    case "#CB2B3E":
      className = "marker-cluster-red";
      // eslint-disable-next-line no-undef
      icon = new L.Icon({
        iconUrl:
          "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png",
        shadowUrl:
          "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41],
      });
      break;
    case "#2AAD27":
      className = "marker-cluster-green";
      // eslint-disable-next-line no-undef
      icon = new L.Icon({
        iconUrl:
          "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-green.png",
        shadowUrl:
          "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41],
      });
      break;
    case "#CB8427":
      className = "marker-cluster-orange";
      // eslint-disable-next-line no-undef
      icon = new L.Icon({
        iconUrl:
          "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-orange.png",
        shadowUrl:
          "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41],
      });
      break;
    case "#3D3D3D":
      className = "marker-cluster-black";
      // eslint-disable-next-line no-undef
      icon = new L.Icon({
        iconUrl:
          "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-black.png",
        shadowUrl:
          "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41],
      });
      break;
  }
  // eslint-disable-next-line no-undef
  var markers = L.markerClusterGroup({
    iconCreateFunction: function (cluster) {
      // eslint-disable-next-line no-undef
      return new L.DivIcon({
        html: "<div><span>" + cluster.getChildCount() + "</span></div>",
        className: "marker-cluster " + className,
        // eslint-disable-next-line no-undef
        iconSize: new L.Point(40, 40),
      });
    },
  });

  for (let pt of data) {
    let city = cities.filter(
      (el) => el.city.toLowerCase() === pt.city.toLowerCase()
    );
    // eslint-disable-next-line no-undef
    var marker = L.marker([city[0].lat, city[0].long], { icon: icon });
    marker.bindPopup(`Address: ${pt.city}`);
    markers.addLayer(marker);
  }
  data.sort(function (a, b) {
    return a.state.localeCompare(b.state) || a.city.localeCompare(b.city);
  });
  if (dualMap.value) {
    if (userType === "instructor") {
      insMap.addLayer(markers);
      displayInsData.value.push({
        userType: userType,
        color: selectedColor,
        filters: filters,
        title: title,
        label: label,
        data: data,
        count: data.length,
        markers: markers,
        groups: mulGroupBy(data, function (item) {
          return [item.state, item.city];
        }),
      });
      console.log(displayInsData.value);
    } else {
      pracMap.addLayer(markers);
      displayPracData.value.push({
        userType: userType,
        color: selectedColor,
        filters: filters,
        title: title,
        label: label,
        data: data,
        count: data.length,
        markers: markers,
        groups: mulGroupBy(data, function (item) {
          return [item.state, item.city];
        }),
      });
      console.log(displayPracData.value);
    }
  } else {
    map.addLayer(markers);
    displayData.value.push({
      userType: userType,
      color: selectedColor,
      filters: filters,
      title: title,
      label: label,
      data: data,
      count: data.length,
      markers: markers,
      groups: mulGroupBy(data, function (item) {
        return [item.state, item.city];
      }),
    });
    console.log(displayData.value);
  }
};

const removeData = (legend) => {
  let selectedDataIndex = displayData.value.findIndex(
    (el) => el.color == legend.color
  );
  if (selectedDataIndex > -1) {
    // only splice array when item is found
    map.removeLayer(displayData.value[selectedDataIndex].markers);
    displayData.value.splice(selectedDataIndex, 1);
  }
};

const bringToFront = (legend) => {
  let selectedDataIndex = displayData.value.findIndex(
    (el) => el.color == legend.color
  );
  const tempLegend = displayData.value[selectedDataIndex];
  const emptyFilter = {} as Filters;
  removeData(legend);
  updateMap(
    tempLegend.userType,
    tempLegend.color,
    emptyFilter,
    tempLegend.data,
    tempLegend.title,
    tempLegend.label
  );
};

const getTopic = (table) => {
  loading.value = true;
  AdminService.getTopic(table).then(
    (response) => {
      if (table === "CourseRequestTopic") {
        topics = response.data;
        topics = topics.filter((el) => el.categoryId != null);
        topicCategoryOptions.value = Array.from(
          new Set(topics.map((el) => el.category.name))
        );
        courseRequest.forEach((el) => {
          el["topicCategory"] = topics.filter(
            (e) => e.name === el.topic
          )[0]?.category?.name;
        });
      } else {
        subjectWillingTopic = response.data;
        subjectWillingTopic = subjectWillingTopic.filter(
          (el) => el.categoryId != null
        );
        subjectWillingCategoryOptions.value = Array.from(
          new Set(subjectWillingTopic.map((el) => el.category.name))
        );
        pracAvail.forEach((el) => {
          let temp = [] as string[];
          JSON.parse(el.subjectSpeak).forEach((e) => {
            temp.push(
              subjectWillingTopic.filter((t) => t.name === e)[0]?.category?.name
            );
          });
          el["topicCategory"] = temp;
        });
        // filteredData = data.filter((el) =>
        //     JSON.parse(el[filter]).includes(
        //         pracAvailabilityFilters.value[filter][index]
        //     )
        // );
      }
      loading.value = false;
    },
    (error) => {
      let message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      showErrorToast(message);
    }
  );
};

getTopic("CourseRequestTopic");
getTopic("SubjectWillingToSpeakOn");

onMounted(() => initialize());

return (_ctx: any,_cache: any) => {
  const _component_VaSwitch = _resolveComponent("VaSwitch")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_VaSwitch, {
          modelValue: _unref(dualMap),
          "onUpdate:modelValue": [
            _cache[0] || (_cache[0] = ($event: any) => (_isRef(dualMap) ? (dualMap).value = $event : dualMap = $event)),
            updateMapView
          ],
          "true-label": "Dual Map",
          "false-label": "Single Map",
          size: "small"
        }, null, 8, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_3, [
        (
            (!_unref(dualMap) && _unref(displayData).length > 0) ||
            (_unref(dualMap) &&
              (_unref(displayInsData).length > 0 || _unref(displayPracData).length > 0))
          )
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              class: "btn btn-secondary",
              onClick: downloadData
            }, [
              _createVNode(_component_font_awesome_icon, { icon: "fa-solid fa-download" }),
              _cache[20] || (_cache[20] = _createTextVNode(" Download Data "))
            ]))
          : _createCommentVNode("", true)
      ]),
      (_unref(currentUser).roles !== 'ADMIN')
        ? (_openBlock(), _createBlock(_component_router_link, {
            key: 0,
            class: "flex-grow-0 flex-shrink-1 ml-3",
            to: { name: 'dashboard-feedback' }
          }, {
            default: _withCtx(() => _cache[21] || (_cache[21] = [
              _createElementVNode("div", { class: "cursor-pointer" }, [
                _createElementVNode("button", { class: "btn btn-check bg-green-400 border-none" }, [
                  _createElementVNode("img", {
                    src: _imports_0,
                    class: "sidebar-image-large"
                  }),
                  _createTextVNode(" Feedback ")
                ])
              ], -1)
            ])),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _cache[34] || (_cache[34] = _createStaticVNode("<div class=\"flex flex-direction-row\" data-v-7312f8e4><div class=\"w-50\" data-v-7312f8e4><h5 class=\"text-align-center\" data-v-7312f8e4>Instructor-related Data</h5></div><div class=\"border-x-1 border-600\" data-v-7312f8e4></div><div class=\"w-50\" data-v-7312f8e4><h5 class=\"text-align-center\" data-v-7312f8e4>Practitioner-related Data</h5></div></div>", 1)),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_unref(Multiselect), {
            modelValue: _unref(courseReqFilters).courseRequestType,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(courseReqFilters).courseRequestType) = $event)),
            placeholder: "Collaboration Type",
            options: _unref(courseReqTypeOptions),
            mode: "tags",
            multipleLabel: _unref(displayLabels),
            hideSelected: false,
            closeOnSelect: false,
            closeOnDeselect: false,
            ref: "multiselect"
          }, {
            option: _withCtx(({ option }) => [
              _createElementVNode("input", {
                type: "checkbox",
                class: "input-pointer",
                checked: 
                  _unref(valid)(_unref(courseReqFilters).courseRequestType) &&
                  _unref(courseReqFilters).courseRequestType.includes(option.value)
                
              }, null, 8, _hoisted_7),
              _createTextVNode("   " + _toDisplayString(option.label), 1)
            ]),
            _: 1
          }, 8, ["modelValue", "options", "multipleLabel"]),
          _createVNode(_unref(Multiselect), {
            modelValue: _unref(courseReqFilters).insType,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(courseReqFilters).insType) = $event)),
            placeholder: "Type of Institution",
            options: _unref(insTypeOptions),
            mode: "tags",
            multipleLabel: _unref(displayLabels),
            hideSelected: false,
            closeOnSelect: false,
            closeOnDeselect: false,
            ref: "multiselect"
          }, {
            option: _withCtx(({ option }) => [
              _createElementVNode("input", {
                type: "checkbox",
                class: "input-pointer",
                checked: 
                  _unref(valid)(_unref(courseReqFilters).insType) &&
                  _unref(courseReqFilters).insType.includes(option.value)
                
              }, null, 8, _hoisted_8),
              _createTextVNode("   " + _toDisplayString(option.value), 1)
            ]),
            _: 1
          }, 8, ["modelValue", "options", "multipleLabel"]),
          _createVNode(_unref(Multiselect), {
            modelValue: _unref(courseReqFilters).yearsOfExp,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_unref(courseReqFilters).yearsOfExp) = $event)),
            placeholder: "Years of Experience",
            options: _unref(yearsOfExpOptions),
            mode: "tags",
            multipleLabel: _unref(displayLabels),
            hideSelected: false,
            closeOnSelect: false,
            closeOnDeselect: false
          }, {
            option: _withCtx(({ option }) => [
              _createElementVNode("input", {
                type: "checkbox",
                class: "input-pointer",
                checked: 
                  _unref(valid)(_unref(courseReqFilters).yearsOfExp) &&
                  _unref(courseReqFilters).yearsOfExp.includes(option.value)
                
              }, null, 8, _hoisted_9),
              _createTextVNode("   " + _toDisplayString(option.label), 1)
            ]),
            _: 1
          }, 8, ["modelValue", "options", "multipleLabel"])
        ]),
        _createElementVNode("div", _hoisted_10, [
          _createVNode(_unref(Multiselect), {
            modelValue: _unref(courseReqFilters).topicCategory,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_unref(courseReqFilters).topicCategory) = $event)),
            placeholder: "Subject Areas",
            options: _unref(topicCategoryOptions),
            mode: "tags",
            multipleLabel: _unref(displayLabels),
            hideSelected: false,
            closeOnSelect: false,
            closeOnDeselect: false
          }, {
            option: _withCtx(({ option }) => [
              _createElementVNode("input", {
                type: "checkbox",
                class: "input-pointer",
                checked: 
                  _unref(valid)(_unref(courseReqFilters).topicCategory) &&
                  _unref(courseReqFilters).topicCategory.includes(option.value)
                
              }, null, 8, _hoisted_11),
              _createTextVNode("   " + _toDisplayString(option.value), 1)
            ]),
            _: 1
          }, 8, ["modelValue", "options", "multipleLabel"]),
          _createVNode(_unref(Multiselect), {
            modelValue: _unref(courseReqFilters).location,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_unref(courseReqFilters).location) = $event)),
            placeholder: "Location(Region)",
            options: _unref(locationOptions),
            mode: "tags",
            multipleLabel: _unref(displayLabels),
            hideSelected: false,
            closeOnSelect: false,
            closeOnDeselect: false
          }, {
            option: _withCtx(({ option }) => [
              _createElementVNode("input", {
                type: "checkbox",
                class: "input-pointer",
                checked: 
                  _unref(valid)(_unref(courseReqFilters).location) &&
                  _unref(courseReqFilters).location.includes(option.value)
                
              }, null, 8, _hoisted_12),
              _createTextVNode("   " + _toDisplayString(option.value), 1)
            ]),
            _: 1
          }, 8, ["modelValue", "options", "multipleLabel"]),
          _createVNode(_unref(Multiselect), {
            modelValue: _unref(courseReqFilters).state,
            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_unref(courseReqFilters).state) = $event)),
            options: _unref(stateOptions),
            mode: "tags",
            placeholder: "Location(State)",
            multipleLabel: _unref(displayLabels),
            searchable: true,
            hideSelected: false,
            closeOnSelect: false,
            closeOnDeselect: false
          }, {
            option: _withCtx(({ option }) => [
              _createElementVNode("input", {
                type: "checkbox",
                class: "input-pointer",
                checked: _unref(courseReqFilters).state?.includes(option.value)
              }, null, 8, _hoisted_13),
              _createTextVNode("   " + _toDisplayString(option.label), 1)
            ]),
            _: 1
          }, 8, ["modelValue", "options", "multipleLabel"]),
          _createVNode(_unref(Multiselect), {
            modelValue: _unref(courseReqFilters).gender,
            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_unref(courseReqFilters).gender) = $event)),
            placeholder: "Gender",
            options: _unref(genderOptions),
            mode: "tags",
            multipleLabel: _unref(displayLabels),
            hideSelected: false,
            closeOnSelect: false,
            closeOnDeselect: false
          }, {
            option: _withCtx(({ option }) => [
              _createElementVNode("input", {
                type: "checkbox",
                class: "input-pointer",
                checked: 
                  _unref(valid)(_unref(courseReqFilters).gender) &&
                  _unref(courseReqFilters).gender.includes(option.value)
                
              }, null, 8, _hoisted_14),
              _createTextVNode("   " + _toDisplayString(option.value), 1)
            ]),
            _: 1
          }, 8, ["modelValue", "options", "multipleLabel"])
        ]),
        _createElementVNode("div", _hoisted_15, [
          _createVNode(_unref(Multiselect), {
            modelValue: _unref(courseReqFilters).ageRange,
            "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_unref(courseReqFilters).ageRange) = $event)),
            placeholder: "Age Range",
            options: _unref(ageRangeOptions),
            mode: "tags",
            multipleLabel: _unref(displayLabels),
            hideSelected: false,
            closeOnSelect: false,
            closeOnDeselect: false
          }, {
            option: _withCtx(({ option }) => [
              _createElementVNode("input", {
                type: "checkbox",
                class: "input-pointer",
                checked: 
                  _unref(valid)(_unref(courseReqFilters).ageRange) &&
                  _unref(courseReqFilters).ageRange.includes(option.value)
                
              }, null, 8, _hoisted_16),
              _createTextVNode("   " + _toDisplayString(option.label), 1)
            ]),
            _: 1
          }, 8, ["modelValue", "options", "multipleLabel"]),
          _createVNode(_unref(Multiselect), {
            modelValue: _unref(courseReqFilters).title,
            "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_unref(courseReqFilters).title) = $event)),
            placeholder: "Position",
            options: _unref(instructorTitleOptions),
            mode: "tags",
            multipleLabel: _unref(displayLabels),
            hideSelected: false,
            closeOnSelect: false,
            closeOnDeselect: false
          }, {
            option: _withCtx(({ option }) => [
              _createElementVNode("input", {
                type: "checkbox",
                class: "input-pointer",
                checked: 
                  _unref(valid)(_unref(courseReqFilters).title) &&
                  _unref(courseReqFilters).title.includes(option.value)
                
              }, null, 8, _hoisted_17),
              _createTextVNode("   " + _toDisplayString(option.value), 1)
            ]),
            _: 1
          }, 8, ["modelValue", "options", "multipleLabel"]),
          _createVNode(_unref(Multiselect), {
            modelValue: _unref(selectedColor),
            "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => (_isRef(selectedColor) ? (selectedColor).value = $event : selectedColor = $event)),
            placeholder: "Color",
            options: _unref(colorOptions),
            mode: "single",
            hideSelected: false,
            closeOnSelect: false,
            closeOnDeselect: false
          }, null, 8, ["modelValue", "options"])
        ])
      ]),
      _cache[22] || (_cache[22] = _createElementVNode("div", { class: "border-x-1 border-600" }, null, -1)),
      _createElementVNode("div", _hoisted_18, [
        _createElementVNode("div", _hoisted_19, [
          _createVNode(_unref(Multiselect), {
            modelValue: _unref(pracAvailabilityFilters).courseRequestType,
            "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_unref(pracAvailabilityFilters).courseRequestType) = $event)),
            placeholder: "Collaboration Type",
            options: _unref(courseReqTypeOptions),
            mode: "tags",
            multipleLabel: _unref(displayLabels),
            hideSelected: false,
            closeOnSelect: false,
            closeOnDeselect: false
          }, {
            option: _withCtx(({ option }) => [
              _createElementVNode("input", {
                type: "checkbox",
                class: "input-pointer",
                checked: 
                  _unref(valid)(_unref(pracAvailabilityFilters).courseRequestType) &&
                  _unref(pracAvailabilityFilters).courseRequestType.includes(
                    option.value
                  )
                
              }, null, 8, _hoisted_20),
              _createTextVNode("   " + _toDisplayString(option.label), 1)
            ]),
            _: 1
          }, 8, ["modelValue", "options", "multipleLabel"]),
          _createVNode(_unref(Multiselect), {
            modelValue: _unref(pracAvailabilityFilters).topicCategory,
            "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_unref(pracAvailabilityFilters).topicCategory) = $event)),
            placeholder: "Topics of Interest",
            options: _unref(subjectWillingCategoryOptions),
            mode: "tags",
            multipleLabel: _unref(displayLabels),
            hideSelected: false,
            closeOnSelect: false,
            closeOnDeselect: false
          }, {
            option: _withCtx(({ option }) => [
              _createElementVNode("input", {
                type: "checkbox",
                class: "input-pointer",
                checked: 
                  _unref(valid)(_unref(pracAvailabilityFilters).topicCategory) &&
                  _unref(pracAvailabilityFilters).topicCategory.includes(option.value)
                
              }, null, 8, _hoisted_21),
              _createTextVNode("   " + _toDisplayString(option.label), 1)
            ]),
            _: 1
          }, 8, ["modelValue", "options", "multipleLabel"]),
          _createVNode(_unref(Multiselect), {
            modelValue: _unref(pracAvailabilityFilters).gender,
            "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_unref(pracAvailabilityFilters).gender) = $event)),
            placeholder: "Gender",
            options: _unref(genderOptions),
            mode: "tags",
            multipleLabel: _unref(displayLabels),
            hideSelected: false,
            closeOnSelect: false,
            closeOnDeselect: false
          }, {
            option: _withCtx(({ option }) => [
              _createElementVNode("input", {
                type: "checkbox",
                class: "input-pointer",
                checked: 
                  _unref(valid)(_unref(pracAvailabilityFilters).gender) &&
                  _unref(pracAvailabilityFilters).gender.includes(option.value)
                
              }, null, 8, _hoisted_22),
              _createTextVNode("   " + _toDisplayString(option.value), 1)
            ]),
            _: 1
          }, 8, ["modelValue", "options", "multipleLabel"])
        ]),
        _createElementVNode("div", _hoisted_23, [
          _createVNode(_unref(Multiselect), {
            modelValue: _unref(pracAvailabilityFilters).size,
            "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_unref(pracAvailabilityFilters).size) = $event)),
            placeholder: "Size of Firm",
            options: _unref(sizeOfFirmOptions),
            mode: "tags",
            multipleLabel: _unref(displayLabels),
            hideSelected: false,
            closeOnSelect: false,
            closeOnDeselect: false
          }, {
            option: _withCtx(({ option }) => [
              _createElementVNode("input", {
                type: "checkbox",
                class: "input-pointer",
                checked: 
                  _unref(valid)(_unref(pracAvailabilityFilters).size) &&
                  _unref(pracAvailabilityFilters).size.includes(option.value)
                
              }, null, 8, _hoisted_24),
              _createTextVNode("   " + _toDisplayString(option.label), 1)
            ]),
            _: 1
          }, 8, ["modelValue", "options", "multipleLabel"]),
          _createVNode(_unref(Multiselect), {
            modelValue: _unref(pracAvailabilityFilters).type,
            "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_unref(pracAvailabilityFilters).type) = $event)),
            placeholder: "Type of Firm",
            options: _unref(typeOfFirmOptions),
            mode: "tags",
            multipleLabel: _unref(displayLabels),
            hideSelected: false,
            closeOnSelect: false,
            closeOnDeselect: false
          }, {
            option: _withCtx(({ option }) => [
              _createElementVNode("input", {
                type: "checkbox",
                class: "input-pointer",
                checked: 
                  _unref(valid)(_unref(pracAvailabilityFilters).type) &&
                  _unref(pracAvailabilityFilters).type.includes(option.value)
                
              }, null, 8, _hoisted_25),
              _createTextVNode("   " + _toDisplayString(option.value), 1)
            ]),
            _: 1
          }, 8, ["modelValue", "options", "multipleLabel"]),
          _createVNode(_unref(Multiselect), {
            modelValue: _unref(pracAvailabilityFilters).expertise,
            "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_unref(pracAvailabilityFilters).expertise) = $event)),
            placeholder: "Area of Expertise",
            options: _unref(areaOfExpertiseOptions),
            mode: "tags",
            multipleLabel: _unref(displayLabels),
            hideSelected: false,
            closeOnSelect: false,
            closeOnDeselect: false
          }, {
            option: _withCtx(({ option }) => [
              _createElementVNode("input", {
                type: "checkbox",
                class: "input-pointer",
                checked: 
                  _unref(valid)(_unref(pracAvailabilityFilters).expertise) &&
                  _unref(pracAvailabilityFilters).expertise.includes(option.value)
                
              }, null, 8, _hoisted_26),
              _createTextVNode("   " + _toDisplayString(option.label), 1)
            ]),
            _: 1
          }, 8, ["modelValue", "options", "multipleLabel"])
        ]),
        _createElementVNode("div", _hoisted_27, [
          _createVNode(_unref(Multiselect), {
            modelValue: _unref(pracAvailabilityFilters).location,
            "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_unref(pracAvailabilityFilters).location) = $event)),
            placeholder: "Location(Region)",
            options: _unref(locationOptions),
            mode: "tags",
            multipleLabel: _unref(displayLabels),
            hideSelected: false,
            closeOnSelect: false,
            closeOnDeselect: false
          }, {
            option: _withCtx(({ option }) => [
              _createElementVNode("input", {
                type: "checkbox",
                class: "input-pointer",
                checked: 
                  _unref(valid)(_unref(pracAvailabilityFilters).location) &&
                  _unref(pracAvailabilityFilters).location.includes(option.value)
                
              }, null, 8, _hoisted_28),
              _createTextVNode("   " + _toDisplayString(option.label), 1)
            ]),
            _: 1
          }, 8, ["modelValue", "options", "multipleLabel"]),
          _createVNode(_unref(Multiselect), {
            modelValue: _unref(pracAvailabilityFilters).state,
            "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_unref(pracAvailabilityFilters).state) = $event)),
            options: _unref(stateOptions),
            mode: "tags",
            placeholder: "Location(State)",
            multipleLabel: _unref(displayLabels),
            searchable: true,
            hideSelected: false,
            closeOnSelect: false,
            closeOnDeselect: false
          }, {
            option: _withCtx(({ option }) => [
              _createElementVNode("input", {
                type: "checkbox",
                class: "input-pointer",
                checked: _unref(pracAvailabilityFilters).state?.includes(option.value)
              }, null, 8, _hoisted_29),
              _createTextVNode("   " + _toDisplayString(option.label), 1)
            ]),
            _: 1
          }, 8, ["modelValue", "options", "multipleLabel"]),
          _createVNode(_unref(Multiselect), {
            modelValue: _unref(selectedColorPrac),
            "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => (_isRef(selectedColorPrac) ? (selectedColorPrac).value = $event : selectedColorPrac = $event)),
            placeholder: "Color",
            options: _unref(colorOptions),
            mode: "single",
            hideSelected: false,
            closeOnSelect: false,
            closeOnDeselect: false
          }, null, 8, ["modelValue", "options"])
        ])
      ])
    ]),
    _createElementVNode("div", { class: "flex flex-direction-row" }, [
      _createElementVNode("div", { class: "align-self-center text-align-center" }, [
        _createElementVNode("button", {
          onClick: addData,
          class: "btn btn-primary ml-2"
        }, " Display Data ")
      ]),
      _createElementVNode("div", { class: "align-self-center text-align-center" }, [
        _createElementVNode("button", {
          onClick: addDataPrac,
          class: "btn btn-primary"
        }, " Display Data ")
      ])
    ]),
    (!_unref(dualMap))
      ? (_openBlock(), _createElementBlock("div", _hoisted_30, [
          _createElementVNode("div", _hoisted_31, [
            _cache[26] || (_cache[26] = _createElementVNode("div", {
              class: "map-container flex-grow-0 flex-shrink-0 mb-2",
              style: {"border":"thin solid grey","border-radius":"8px","padding-left":"0.5rem"}
            }, [
              _createElementVNode("div", {
                id: "map",
                style: {"height":"100%","width":"100%"}
              })
            ], -1)),
            _createElementVNode("div", _hoisted_32, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(displayData), (legend) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: legend.color
                }, [
                  _createElementVNode("div", _hoisted_33, [
                    _createElementVNode("div", {
                      class: "flex-grow-0 flex-shrink-0 align-self-center h-1rem w-1rem",
                      style: _normalizeStyle([{ background: legend.color }, {"flex-basis":"1rem"}])
                    }, null, 4),
                    _createElementVNode("p", _hoisted_34, _toDisplayString(legend.label), 1),
                    _createElementVNode("div", {
                      class: "list-item-remove cursor-pointer category-menu-item rounded-2 flex-shrink-0 flex-grow-0",
                      style: {"flex-basis":"2rem"},
                      title: "Bring to Front",
                      onClick: ($event: any) => (bringToFront(legend))
                    }, _cache[23] || (_cache[23] = [
                      _createElementVNode("img", {
                        src: _imports_1,
                        class: "category-menu-img"
                      }, null, -1)
                    ]), 8, _hoisted_35),
                    _createElementVNode("span", {
                      class: "list-item-remove justify-content-start flex-shrink-0 flex-grow-0",
                      style: {"flex-basis":"1rem"},
                      onClick: ($event: any) => (removeData(legend)),
                      title: "Remove"
                    }, _cache[24] || (_cache[24] = [
                      _createElementVNode("span", { class: "list-item-remove-icon" }, null, -1)
                    ]), 8, _hoisted_36),
                    _cache[25] || (_cache[25] = _createElementVNode("div", { class: "flex-grow-1" }, null, -1))
                  ])
                ]))
              }), 128))
            ])
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_37, [
          _cache[33] || (_cache[33] = _createStaticVNode("<div class=\"flex flex-direction-row mt-3 gap-4\" data-v-7312f8e4><div class=\"dual-map-container flex-grow-0 flex-shrink-0\" style=\"border:thin solid grey;border-radius:8px;padding-left:0.5rem;\" data-v-7312f8e4><div id=\"insMap\" style=\"height:100%;width:100%;\" data-v-7312f8e4></div></div><div class=\"dual-map-container flex-grow-0 flex-shrink-0\" style=\"border:thin solid grey;border-radius:8px;padding-left:0.5rem;\" data-v-7312f8e4><div id=\"pracMap\" style=\"height:100%;width:100%;\" data-v-7312f8e4></div></div></div>", 1)),
          _createElementVNode("div", _hoisted_38, [
            _createElementVNode("div", _hoisted_39, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(displayInsData), (legend) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: legend.color
                }, [
                  _createElementVNode("div", _hoisted_40, [
                    _createElementVNode("div", {
                      class: "flex-grow-0 flex-shrink-0 align-self-center h-1rem w-1rem",
                      style: _normalizeStyle([{ background: legend.color }, {"flex-basis":"1rem"}])
                    }, null, 4),
                    _createElementVNode("p", _hoisted_41, _toDisplayString(legend.label), 1),
                    _createElementVNode("div", {
                      class: "list-item-remove cursor-pointer category-menu-item rounded-2 flex-shrink-0 flex-grow-0",
                      style: {"flex-basis":"2rem"},
                      title: "Bring to Front",
                      onClick: ($event: any) => (bringToFront(legend))
                    }, _cache[27] || (_cache[27] = [
                      _createElementVNode("img", {
                        src: _imports_1,
                        class: "category-menu-img"
                      }, null, -1)
                    ]), 8, _hoisted_42),
                    _createElementVNode("span", {
                      class: "list-item-remove justify-content-start flex-shrink-0 flex-grow-0",
                      style: {"flex-basis":"1rem"},
                      onClick: ($event: any) => (removeData(legend)),
                      title: "Remove"
                    }, _cache[28] || (_cache[28] = [
                      _createElementVNode("span", { class: "list-item-remove-icon" }, null, -1)
                    ]), 8, _hoisted_43),
                    _cache[29] || (_cache[29] = _createElementVNode("div", { class: "flex-grow-1" }, null, -1))
                  ])
                ]))
              }), 128))
            ]),
            _createElementVNode("div", _hoisted_44, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(displayPracData), (legend) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: legend.color
                }, [
                  _createElementVNode("div", _hoisted_45, [
                    _createElementVNode("div", {
                      class: "flex-grow-0 flex-shrink-0 align-self-center h-1rem w-1rem",
                      style: _normalizeStyle([{ background: legend.color }, {"flex-basis":"1rem"}])
                    }, null, 4),
                    _createElementVNode("p", _hoisted_46, _toDisplayString(legend.label), 1),
                    _createElementVNode("div", {
                      class: "list-item-remove cursor-pointer category-menu-item rounded-2 flex-shrink-0 flex-grow-0",
                      style: {"flex-basis":"2rem"},
                      title: "Bring to Front",
                      onClick: ($event: any) => (bringToFront(legend))
                    }, _cache[30] || (_cache[30] = [
                      _createElementVNode("img", {
                        src: _imports_1,
                        class: "category-menu-img"
                      }, null, -1)
                    ]), 8, _hoisted_47),
                    _createElementVNode("span", {
                      class: "list-item-remove justify-content-start flex-shrink-0 flex-grow-0",
                      style: {"flex-basis":"1rem"},
                      onClick: ($event: any) => (removeData(legend)),
                      title: "Remove"
                    }, _cache[31] || (_cache[31] = [
                      _createElementVNode("span", { class: "list-item-remove-icon" }, null, -1)
                    ]), 8, _hoisted_48),
                    _cache[32] || (_cache[32] = _createElementVNode("div", { class: "flex-grow-1" }, null, -1))
                  ])
                ]))
              }), 128))
            ])
          ])
        ]))
  ]))
}
}

})