<template>
  <form @keydown.enter="$event.preventDefault()" class="mt-3 lg:mx-3">
    <div class="card card-container">
      <div class="bold-text ml-3 text-align-center">
        Please provide further details based on your earlier selections
        <br /><br />
      </div>
      <div>
        <div
          class="form-group"
          v-if="
            !availabilityFields.find((el) => el.name === 'insDeliveryType')
              ?.disabled
          "
        >
          <label for="insDeliveryType"
            >Instruction Delivery Mode<span class="super font-color-red"
              >*</span
            ></label
          >
          <Field
            v-model="values.insDeliveryType"
            name="insDeliveryType"
            type="text"
            class="dropdown"
            v-slot="{ field }"
          >
            <multiselect
              v-bind="field"
              v-model="values.insDeliveryType"
              :options="insDeliveryTypeOptions"
              mode="single"
              :searchable="true"
            />
          </Field>
          <ErrorMessage name="insDeliveryType" class="error-feedback" />
        </div>

        <div
          class="form-group"
          v-if="
            !availabilityFields.find((el) => el.name === 'insDeliveryType')
              ?.disabled
          "
        >
          <label for="others">Other Comment/Information</label>
          <Field name="others" type="text" class="form-control" />
          <ErrorMessage name="others" class="error-feedback" />
        </div>

        <br />
        <div
          class="text-align-center bold-text"
          v-if="
            !availabilityFields.find((el) => el.name === 'insState')
              ?.disabled &&
            !availabilityFields.find((el) => el.name === 'insName')?.disabled
          "
        >
          Preference Definition (Institution-related)
        </div>
        <div
          class="form-group"
          v-if="
            !availabilityFields.find((el) => el.name === 'insState')?.disabled
          "
        >
          <label for="insState"
            >Location of Institution<span class="super font-color-red"
              >*</span
            ></label
          >
          <Field
            v-model="values.insState"
            name="insState"
            type="text"
            class="form-control"
            v-slot="{ field }"
          >
            <Multiselect
              v-bind="field"
              v-model="values.insState"
              :options="stateOptionsAdded"
              mode="tags"
              placeholder="Select State"
              :multipleLabel="displayLabels"
              :searchable="true"
              :hideSelected="false"
              :closeOnSelect="false"
              :closeOnDeselect="false"
              @change="validateOptAll"
            >
              <template v-slot:option="{ option }">
                <input
                  type="checkbox"
                  class="input-pointer"
                  :checked="values.insState?.includes(option.value)"
                />
                &nbsp; {{ option.label }}
              </template>
            </Multiselect>
          </Field>
          <ErrorMessage name="insState" class="error-feedback" />
        </div>
        <div
          class="form-group"
          v-if="
            !availabilityFields.find((el) => el.name === 'insName')?.disabled
          "
        >
          <label for="insName">Name of Institution</label>
          <Field
            v-model="values.insName"
            name="insName"
            type="text"
            class="form-control"
            v-slot="{ field }"
          >
            <Multiselect
              v-bind="field"
              v-model="values.insName"
              :options="univOptionsAdded"
              mode="tags"
              placeholder="Select Institute"
              :multipleLabel="displayLabels"
              :searchable="true"
              :hideSelected="false"
              :closeOnSelect="false"
              :closeOnDeselect="false"
              @change="validateOptAll"
            >
              <template v-slot:option="{ option }">
                <input
                  type="checkbox"
                  class="input-pointer"
                  :checked="values.insName?.includes(option.value)"
                />
                &nbsp; {{ option.label }}
              </template>
            </Multiselect>
          </Field>
          <ErrorMessage name="insName" class="error-feedback" />
        </div>
        <br />

        <div
          class="text-align-center bold-text"
          v-if="
            !availabilityFields.find((el) => el.name === 'academicLevel')
              ?.disabled &&
            !availabilityFields.find((el) => el.name === 'studentDept')
              ?.disabled
          "
        >
          Preference Definition (Students-related)
        </div>

        <div
          class="form-group"
          v-if="
            !availabilityFields.find((el) => el.name === 'academicLevel')
              ?.disabled
          "
        >
          <label for="academicLevel"
            >Students' Academic Level<span class="super font-color-red"
              >*</span
            ></label
          >
          <Field
            v-model="values.academicLevel"
            name="academicLevel"
            type="text"
            class="form-control"
            v-slot="{ field }"
          >
            <Multiselect
              v-bind="field"
              v-model="values.academicLevel"
              :options="academicLevelOptionsAdded"
              mode="tags"
              :multipleLabel="displayLabels"
              :searchable="true"
              :hideSelected="false"
              :closeOnSelect="false"
              :closeOnDeselect="false"
              @change="validateOptNoPref"
            >
              <template v-slot:option="{ option }">
                <input
                  type="checkbox"
                  class="input-pointer"
                  :checked="values.academicLevel?.includes(option.value)"
                />
                &nbsp; {{ option.label }}
              </template>
            </Multiselect>
          </Field>
          <ErrorMessage name="academicLevel" class="error-feedback" />
        </div>
        <div
          class="form-group"
          v-if="
            !availabilityFields.find((el) => el.name === 'studentDept')
              ?.disabled
          "
        >
          <label for="studentDept">
            Students' Department or Program of Study<span
              class="super font-color-red"
              >*</span
            ></label
          >
          <Field
            v-model="values.studentDept"
            name="studentDept"
            type="text"
            class="form-control"
            v-slot="{ field }"
          >
            <Multiselect
              v-bind="field"
              v-model="values.studentDept"
              :options="deptOptionsAdded"
              mode="tags"
              :multipleLabel="displayLabels"
              :searchable="true"
              :hideSelected="false"
              :closeOnSelect="false"
              :closeOnDeselect="false"
              @change="validateOptNoPref"
            >
              <template v-slot:option="{ option }">
                <input
                  type="checkbox"
                  class="input-pointer"
                  v-if="values.studentDept"
                  :checked="values.studentDept.includes(option.value)"
                />
                &nbsp; {{ option.label }}
              </template>
            </Multiselect>
          </Field>
          <div
            v-if="values.studentDept && values.studentDept.includes('Others')"
          >
            <Field
              name="studentDept_text"
              type="text"
              placeholder="Please Specify"
              class="form-control"
            />
          </div>
          <ErrorMessage name="studentDept" class="error-feedback" />
        </div>
      </div>
    </div>
  </form>
  <div class="text-align-center mb-3">
    <button
      class="btn btn-secondary ml-2"
      type="button"
      @click="goToPreviousStep"
    >
      Previous
    </button>
    <button
      class="btn btn-primary ml-2"
      type="button"
      @click="saveCourseDetails"
    >
      Next
    </button>
  </div>
</template>

<script setup lang="ts">
import { useForm, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import Multiselect from "@vueform/multiselect";
import { useAvailabilityStore } from "@/stores/AvailabilityStore";
import { onBeforeRouteLeave } from "vue-router";
import { useModal } from "vuestic-ui";
import {
  insDeliveryTypeOptions,
  academicLevelOptions,
  stateOptions,
  deptOptions,
  univOptions,
  displayLabels,
  validateOptAll,
  validateOptNoPref,
  showErrorToast,
} from "@/utils";
import { Availability } from "@/types";
import { useConfigStore } from "@/stores/ConfigStore";

// eslint-disable-next-line no-undef
const props = defineProps<{
  availability: Availability;
}>();
// eslint-disable-next-line no-undef
const emits = defineEmits(["previousStep", "nextStep"]);

const { confirm } = useModal();
const ConfigStore = useConfigStore();
const AvailabilityStore = useAvailabilityStore();

const academicLevelOptionsAdded = ["No preference", ...academicLevelOptions];
const deptOptionsAdded = ["No preference", ...deptOptions];
const stateOptionsAdded = ["All", ...stateOptions];
const univOptionsAdded = ["All", ...univOptions];
const availabilityFields = ConfigStore.config.filter(
  (el) => el.table === "availability"
);

const schema = yup.object().shape({
  fields: yup.array().default(availabilityFields),
  insDeliveryType: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "insDeliveryType")?.disabled,
    then: (schema) => schema.required("Instruction Delivery Mode is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  insState: yup
    .array()
    .of(yup.string())
    .when("fields", {
      is: (val) => !val.find((el) => el.name === "insState")?.disabled,
      then: (schema) =>
        schema
          .required("Location of institution is required!")
          .min(1, "Location of institution is required!"),
      otherwise: (schema) => schema.optional().nullable(),
    }),
  insName: yup.array().of(yup.string()).optional().min(0),
  academicLevel: yup
    .array()
    .of(yup.string())
    .when("fields", {
      is: (val) => !val.find((el) => el.name === "academicLevel")?.disabled,
      then: (schema) =>
        schema
          .required("Students' Academic Level is required!")
          .min(1, "Students' Academic Level is required!"),
      otherwise: (schema) => schema.optional().nullable(),
    }),
  studentDept: yup
    .array()
    .of(yup.string())
    .when("fields", {
      is: (val) => !val.find((el) => el.name === "studentDept")?.disabled,
      then: (schema) =>
        schema
          .required("Students' Department or Program of Study is required!")
          .min(1, "Students' Department or Program of Study is required!"),
      otherwise: (schema) => schema.optional().nullable(),
    }),
  others: yup.string().optional().nullable(),
});

let initialState = {
  subjectSpeak: [] as string[],
  insDeliveryType: "",
  others: "",
  insState: [] as string[],
  insName: [] as string[],
  academicLevel: [] as string[],
  studentDept: [] as string[],
  studentDept_text: "",
  phone: "",
  phoneNumber: "",
};

if (AvailabilityStore.status) {
  if (props.availability.studentDept?.includes("Others")) {
    initialState["studentDept_text"] =
      props.availability.studentDept.at(-1) || "";
    initialState.studentDept = props.availability.studentDept.slice(0, -1);
  } else {
    // eslint-disable-next-line vue/no-setup-props-destructure
    initialState.studentDept = props.availability.studentDept;
  }
}

let formValues = {
  insState: props.availability?.insState,
  insName: props.availability?.insName,
  others: props.availability?.others,
  academicLevel: props.availability?.academicLevel,
  studentDept: initialState.studentDept,
  studentDept_text: initialState.studentDept_text,
  insDeliveryType: props.availability?.insDeliveryType,
};

const { handleSubmit, errors, values, meta, validate, resetForm } = useForm({
  validationSchema: schema,
});

resetForm({
  values: formValues,
});

const checkDirty = async () => {
  if (meta.value.dirty) {
    const answer = await confirm({
      message:
        "Your changes on this page have not been saved. Kindly save your changes before leaving the page. Do you want to proceed without saving your changes?",
      okText: "Yes",
      cancelText: "No",
    });
    if (answer) return true;
    else return false;
  } else return true;
};

const saveCourseDetails = handleSubmit(
  (data) => {
    if (data.studentDept && data.studentDept.includes("Others")) {
      data.studentDept.push(data["studentDept_text"]);
      delete data["studentDept_text"];
    }
    emits("nextStep", data);
  },
  (errors) => {
    Object.values(errors.errors).forEach((error) => showErrorToast(error));
  }
);

const goToPreviousStep = async () => {
  const answer = await checkDirty();
  await console.log(answer);
  if (!answer) return false;
  emits("previousStep");
};

onBeforeRouteLeave(async (to, from) => {
  const answer = await checkDirty();
  await console.log(answer);
  if (!answer) return false;
});
</script>

<style scoped>
.card {
  margin-top: 0;
}
.card-container.card {
  max-width: 70vw !important;
  padding: 40px 40px;
}

@media only screen and (max-width: 1440px) {
  .card-container.card {
    max-width: 75vw !important;
    padding: 20px 20px;
  }
}

@media only screen and (max-width: 1366px) {
  .card-container.card {
    max-width: 85vw !important;
    padding: 10px 10px;
  }
}

@media screen and (max-width: 1024px) {
  .card-container.card {
    max-width: 85vw !important;
    padding: 10px 10px;
  }
}
</style>
