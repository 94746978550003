import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, mergeProps as _mergeProps, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "form-group" }
const _hoisted_2 = { class: "form-group" }
const _hoisted_3 = { class: "form-group" }
const _hoisted_4 = { class: "form-check-inline" }
const _hoisted_5 = { class: "form-check-inline ml-3" }
const _hoisted_6 = { class: "form-group text-align-center" }
const _hoisted_7 = ["disabled"]
const _hoisted_8 = { class: "spinner-border spinner-border-sm" }

import { ref } from "vue";
import * as yup from "yup";
import { Form, Field, ErrorMessage } from "vee-validate";
import { FAQ } from "@/types";
import AdminService from "@/services/admin.service.js";
import { showToast } from "@/utils";


export default /*@__PURE__*/_defineComponent({
  __name: 'FAQForm',
  props: {
    faq: {}
  },
  emits: ["saveFAQ"],
  setup(__props: any, { emit: __emit }) {

let loading = ref(false);
let message = ref("");

// eslint-disable-next-line no-undef
const props = __props;
// eslint-disable-next-line no-undef
const emits = __emit;

let initialState = {
  id: 0,
  userQuestion: "",
  answer: "",
  display: false,
};

const formValues = {
  id: props.faq?.id,
  userQuestion: props.faq?.userQuestion,
  answer: props.faq?.answer,
  display: props.faq?.display ? props.faq?.display.toString() : "",
};

const schema = yup.object().shape({
  userQuestion: yup
    .string()
    .trim()
    .required("User Question is required!")
    .min(1, "User Question is required!")
    .max(1000, "User Question cannot be greater than 1000 characters"),
  answer: yup
    .string()
    .trim()
    .required("Answer is required!")
    .min(1, "Answer is required!")
    .max(100000, "Answer cannot be greater than 100000 characters"),
  display: yup.string().required("Display on FAQ Page is required!"),
});

const saveFAQ = (values) => {
  AdminService.saveFAQ(values).then(
    (response) => {
      console.log(response);
      emits("saveFAQ");
      showToast("The FAQ has been saved/updated.");
    },
    (error) => {
      loading.value = false;
      message.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Form), {
    onSubmit: saveFAQ,
    "validation-schema": _unref(schema),
    "initial-values": formValues
  }, {
    default: _withCtx(() => [
      _createVNode(_unref(Field), {
        name: "id",
        hidden: ""
      }),
      _createElementVNode("div", _hoisted_1, [
        _cache[0] || (_cache[0] = _createElementVNode("label", { for: "userQuestion" }, [
          _createTextVNode("User Question "),
          _createElementVNode("span", { class: "super font-color-red" }, "*")
        ], -1)),
        _createVNode(_unref(Field), { name: "userQuestion" }, {
          default: _withCtx(({ field }) => [
            _createElementVNode("input", _mergeProps(field, {
              class: "form-control",
              type: "text"
            }), null, 16),
            _createVNode(_unref(ErrorMessage), {
              name: "userQuestion",
              class: "error-feedback"
            })
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_2, [
        _cache[1] || (_cache[1] = _createElementVNode("label", { for: "answer" }, [
          _createTextVNode("Answer"),
          _createElementVNode("span", { class: "super font-color-red" }, "*")
        ], -1)),
        _createVNode(_unref(Field), {
          name: "answer",
          type: "text",
          as: "textarea",
          class: "form-control",
          style: {"height":"200px"}
        })
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _cache[2] || (_cache[2] = _createElementVNode("label", { for: "display" }, [
            _createTextVNode("Display on FAQ Page"),
            _createElementVNode("span", { class: "super font-color-red" }, "*")
          ], -1)),
          _createVNode(_unref(Field), {
            name: "display",
            class: "form-check-input",
            type: "radio",
            value: "true"
          }),
          _cache[3] || (_cache[3] = _createTextVNode("Yes "))
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_unref(Field), {
            name: "display",
            class: "form-check-input",
            type: "radio",
            value: "false"
          }),
          _cache[4] || (_cache[4] = _createTextVNode("No "))
        ]),
        _createVNode(_unref(ErrorMessage), {
          name: "display",
          class: "error-feedback"
        })
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("button", {
          class: "btn btn-primary btn-block",
          disabled: _unref(loading)
        }, [
          _withDirectives(_createElementVNode("span", _hoisted_8, null, 512), [
            [_vShow, _unref(loading)]
          ]),
          _cache[5] || (_cache[5] = _createTextVNode(" Save "))
        ], 8, _hoisted_7)
      ])
    ]),
    _: 1
  }, 8, ["validation-schema"]))
}
}

})