<template>
  <div class="sidebar sidebar-max min-height">
    <profile-picture></profile-picture>
    <div class="text-align-center">
      <user-badge />
    </div>
    <div style="text-align: center">
      <span class="bold-text">
        {{ currentUser.firstName + " " + currentUser.lastName }}</span
      ><br />
      <span style="font-weight: 300" v-if="currentUser.profile">
        {{ currentUser.profile.position }}<br />
      </span>
      <i>
        <span v-if="currentUser.nameOrg">
          {{ currentUser.nameOrg }} <br />
        </span>
        <span v-if="currentUser.profile">
          {{ currentUser.profile.city + " " + currentUser.profile.state }}
        </span>
      </i>
      <div class="mt-2">
        <router-link
          :to="{
            name: 'user-profile',
            params: { userId: currentUser.id },
          }"
        >
          <div
            class="sidebar-button pl-3"
            :class="[
              route.path.startsWith('/profile/')
                ? 'selected-sidebar-button'
                : 'unselected-sidebar-button',
            ]"
            title="View/edit your profile information."
          >
            <img
              src="@/assets/images/profile.png"
              class="sidebar-image-large"
            />
            <span class="ml-3">Profile</span>
          </div>
        </router-link>
        <div
          class="sidebar-button pl-3"
          :class="[
            route.path === '/practitioner/availability'
              ? 'selected-sidebar-button'
              : 'unselected-sidebar-button',
          ]"
          @click="getAvailability()"
          title="Indicate your availability and preferences here."
        >
          <img
            src="@/assets/images/availability.png"
            class="sidebar-image-large"
          />
          <span class="ml-3">Select Availability</span>
        </div>
        <router-link :to="{ name: 'view-resources' }">
          <div
            class="sidebar-button pl-3"
            :class="[
              route.path === '/practitioner/resources/view'
                ? 'selected-sidebar-button'
                : 'unselected-sidebar-button',
            ]"
            title="Respond to instructors’ requests for resources."
          >
            <img
              src="@/assets/images/resources.png"
              class="sidebar-image-large"
            />
            <span style="position: relative">
              <span class="notification-count">{{
                pendingResourceRequests
              }}</span>
            </span>
            <span class="ml-3">Request(s) for Resources</span>
          </div>
        </router-link>
        <router-link :to="{ name: 'upload' }">
          <div
            class="sidebar-button pl-3"
            :class="[
              route.path === '/upload'
                ? 'selected-sidebar-button'
                : 'unselected-sidebar-button',
            ]"
            title="Share instructional pictures, videos, and documents with instructors here."
          >
            <img
              src="@/assets/images/upload_resources.png"
              class="sidebar-image-large"
              alt="Upload Resources"
            />
            <span class="ml-3">Upload Project Details</span>
          </div>
        </router-link>
        <router-link :to="{ name: 'view-notifications' }">
          <div
            class="sidebar-button pl-3"
            :class="[
              route.path === '/practitioner/notifications'
                ? 'selected-sidebar-button'
                : 'unselected-sidebar-button',
            ]"
            title="Your notifications will appear here."
          >
            <font-awesome-icon
              class="sidebar-icon"
              icon="fa-regular fa-bell"
              style="color: #fcc62a"
            />
            <span style="position: relative">
              <span class="notification-count">{{
                NotificationStore.count
              }}</span>
            </span>
            <span class="ml-3">View Notifications</span>
          </div>
        </router-link>
        <router-link :to="{ name: 'view-past-course-request' }">
          <div
            class="sidebar-button pl-3"
            :class="[
              route.path === '/practitioner/course/past'
                ? 'selected-sidebar-button'
                : 'unselected-sidebar-button',
            ]"
            title="Here are your responses to previous course-support requests from instructors."
          >
            <img
              src="@/assets/images/archive.png"
              class="sidebar-image-large"
            />
            <span class="ml-3">Previous Course-Support</span>
          </div>
        </router-link>
        <router-link :to="{ name: 'feedback' }" class="nav-link">
          <div
            class="sidebar-button pl-3"
            :class="[
              route.path === '/feedback'
                ? 'selected-sidebar-button'
                : 'unselected-sidebar-button',
            ]"
            title="Provide feedback to the Admin here."
          >
            <img
              src="@/assets/images/feedback.png"
              class="sidebar-image-large"
            />
            <span class="ml-3">Feedback</span>
          </div>
        </router-link>
        <div
          class="sidebar-button pl-3"
          :class="[
            isVisible ? 'selected-sidebar-button' : 'unselected-sidebar-button',
          ]"
          @click="showInviteOthers"
          title="Invite others to ConPEC here."
        >
          <img
            src="@/assets/images/invite_users.png"
            class="sidebar-image-large"
          />
          <span class="ml-3">Invite Potential Users</span>
        </div>
        <router-link :to="{ name: 'guide' }" class="nav-link">
          <div
            class="sidebar-button pl-3"
            :class="[
              route.path === '/guide/'
                ? 'selected-sidebar-button'
                : 'unselected-sidebar-button',
            ]"
            title="Help/Guide"
          >
            <img src="@/assets/images/guide.png" class="sidebar-image-large" />
            <span class="ml-3">Help/Guide</span>
          </div>
        </router-link>
        <router-link
          :to="{ name: 'settings' }"
          class="nav-link"
          v-if="
            !settingsFields.find((el) => el.name === 'email')?.disabled ||
            !settingsFields.find((el) => el.name === 'reasonDelete')?.disabled
          "
        >
          <div
            class="sidebar-button pl-3"
            :class="[
              route.path === '/settings/'
                ? 'selected-sidebar-button'
                : 'unselected-sidebar-button',
            ]"
            title="Settings"
          >
            <img
              src="@/assets/images/settings.png"
              class="sidebar-image-large"
            />
            <span class="ml-3">Settings</span>
          </div>
        </router-link>
      </div>
    </div>
    <div
      v-if="
        currentUser.profile && currentUser.profile.courseRequestType != null
      "
      class="current-availability pl-3 mt-2"
    >
      <span style="font-size: larger">Current Availability</span> <br />
      <span
        v-if="
          JSON.parse(currentUser.profile.courseRequestType).includes('Seminar')
        "
      >
        <input
          id="Seminar"
          type="checkbox"
          name="Seminar"
          disabled
          :checked="
            JSON.parse(currentUser.profile.courseRequestType).includes(
              'Seminar'
            )
          "
        />&nbsp; Seminar &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
      <span
        v-if="
          JSON.parse(currentUser.profile.courseRequestType).includes(
            'Site Visit'
          )
        "
      >
        <input
          id="Site Visit"
          type="checkbox"
          name="Site Visit"
          disabled
          :checked="
            JSON.parse(currentUser.profile.courseRequestType).includes(
              'Site Visit'
            )
          "
        />&nbsp; Site Visit
      </span>
      <span
        v-if="
          JSON.parse(currentUser.profile.courseRequestType).includes(
            'Seminar'
          ) ||
          JSON.parse(currentUser.profile.courseRequestType).includes(
            'Site Visit'
          )
        "
      >
        <br />
      </span>
      <span
        v-if="
          JSON.parse(currentUser.profile.courseRequestType).includes('Workshop')
        "
      >
        <input
          id="Workshop"
          type="checkbox"
          name="Workshop"
          v-if="
            JSON.parse(currentUser.profile.courseRequestType).includes(
              'Workshop'
            )
          "
          disabled
          :checked="
            JSON.parse(currentUser.profile.courseRequestType).includes(
              'Workshop'
            )
          "
        />&nbsp; Workshop &nbsp;&nbsp;
      </span>
      <span
        v-if="
          JSON.parse(currentUser.profile.courseRequestType).includes('Labs')
        "
      >
        <input
          id="Labs"
          type="checkbox"
          name="Labs"
          disabled
          :checked="
            JSON.parse(currentUser.profile.courseRequestType).includes('Labs')
          "
        />
        &nbsp; Labs
      </span>
      <span
        v-if="
          JSON.parse(currentUser.profile.courseRequestType).includes(
            'Guest Lecture'
          )
        "
      >
        <br />
        <input
          id="Guest Lecture"
          type="checkbox"
          name="Guest Lecture"
          disabled
          :checked="
            JSON.parse(currentUser.profile.courseRequestType).includes(
              'Guest Lecture'
            )
          "
        />&nbsp; Guest Lecture
      </span>
      <span
        v-if="
          JSON.parse(currentUser.profile.courseRequestType).includes(
            'Guest Lecture'
          ) ||
          JSON.parse(currentUser.profile.courseRequestType).includes('Labs')
        "
      >
        <br
      /></span>
      <span
        v-if="
          JSON.parse(currentUser.profile.courseRequestType).includes(
            'Judge for Project'
          )
        "
      >
        <input
          id="Judge for Project"
          type="checkbox"
          disabled
          :checked="
            JSON.parse(currentUser.profile.courseRequestType).includes(
              'Judge for Project'
            )
          "
          name="Judge for Project"
        />&nbsp; Judge for Project
        <br />
      </span>
      <span
        v-if="
          JSON.parse(currentUser.profile.courseRequestType).includes(
            'Mentor for Project'
          )
        "
      >
        <input
          id="Mentor for Project"
          type="checkbox"
          disabled
          :checked="
            JSON.parse(currentUser.profile.courseRequestType).includes(
              'Mentor for Project'
            )
          "
          name="Mentor for Project"
        />&nbsp; Mentor for Project
        <br />
      </span>
      <span
        v-if="
          JSON.parse(currentUser.profile.courseRequestType).includes(
            'Sponsor for Project'
          )
        "
      >
        <input
          id="Sponsor for Project"
          type="checkbox"
          disabled
          :checked="
            JSON.parse(currentUser.profile.courseRequestType).includes(
              'Sponsor for Project'
            )
          "
          name="Sponsor for Project"
        />&nbsp; Sponsor for Project
        <br />
      </span>
    </div>
  </div>
  <div class="sidebar sidebar-min min-height">
    <profile-icon></profile-icon>
    <div>
      <div class="mt-3">
        <router-link
          :to="{
            name: 'user-profile',
            params: { userId: currentUser.id },
          }"
        >
          <div
            class="sidebar-button pl-3"
            :class="[
              route.path === '/profile'
                ? 'selected-sidebar-button'
                : 'unselected-sidebar-button',
            ]"
            title="View/edit your profile information."
          >
            <img
              src="@/assets/images/profile.png"
              class="sidebar-image-large"
            />
          </div>
        </router-link>
        <div
          class="sidebar-button pl-3"
          :class="[
            route.path === '/practitioner/availability'
              ? 'selected-sidebar-button'
              : 'unselected-sidebar-button',
          ]"
          @click="getAvailability()"
          title="Indicate your availability and preferences."
        >
          <img
            src="@/assets/images/availability.png"
            class="sidebar-image-large"
          />
          <br />
        </div>
        <router-link :to="{ name: 'view-resources' }">
          <div
            class="sidebar-button pl-3"
            :class="[
              route.path === '/practitioner/resources/view'
                ? 'selected-sidebar-button'
                : 'unselected-sidebar-button',
            ]"
            title="Respond to instructors’ requests for resources."
          >
            <img
              src="@/assets/images/resources.png"
              class="sidebar-image-large"
            />
            <span style="position: relative">
              <span class="notification-count">{{
                pendingResourceRequests
              }}</span>
            </span>
          </div>
        </router-link>
        <router-link :to="{ name: 'upload' }">
          <div
            class="sidebar-button pl-3"
            :class="[
              route.path.startsWith('/upload')
                ? 'selected-sidebar-button'
                : 'unselected-sidebar-button',
            ]"
            title="Share instructional pictures, videos, and documents with instructors here."
          >
            <img
              src="@/assets/images/upload_resources.png"
              class="sidebar-image-large"
              alt="Upload Project Details"
            />
          </div>
        </router-link>
        <router-link :to="{ name: 'view-notifications' }">
          <div
            class="sidebar-button pl-3"
            :class="[
              route.path === '/practitioner/notifications'
                ? 'selected-sidebar-button'
                : 'unselected-sidebar-button',
            ]"
            title="Your notifications will appear here."
          >
            <font-awesome-icon
              class="sidebar-icon"
              icon="fa-regular fa-bell"
              style="color: #fcc62a"
            />
            <span style="position: relative">
              <span class="notification-count">{{
                NotificationStore.count
              }}</span>
            </span>
          </div>
        </router-link>
        <router-link :to="{ name: 'view-past-course-request' }">
          <div
            class="sidebar-button pl-3"
            :class="[
              route.path.startsWith('/practitioner/course/past')
                ? 'selected-sidebar-button'
                : 'unselected-sidebar-button',
            ]"
            title="Here are your responses to previous course-support requests from instructors."
          >
            <img
              src="@/assets/images/archive.png"
              class="sidebar-image-large"
            />
          </div>
        </router-link>
        <router-link :to="{ name: 'feedback' }" class="nav-link">
          <div
            class="sidebar-button pl-3"
            :class="[
              route.path === '/feedback'
                ? 'selected-sidebar-button'
                : 'unselected-sidebar-button',
            ]"
            title="Provide feedback to the Admin here."
          >
            <img
              src="@/assets/images/feedback.png"
              class="sidebar-image-large"
            />
          </div>
        </router-link>
        <div
          class="sidebar-button pl-3"
          :class="[
            isVisible ? 'selected-sidebar-button' : 'unselected-sidebar-button',
          ]"
          @click="showInviteOthers"
          title="Invite others to ConPEC here."
        >
          <img
            src="@/assets/images/invite_users.png"
            class="sidebar-image-large"
          />
        </div>
        <router-link :to="{ name: 'guide' }" class="nav-link">
          <div
            class="sidebar-button pl-3"
            :class="[
              route.path === '/guide/'
                ? 'selected-sidebar-button'
                : 'unselected-sidebar-button',
            ]"
            title="Help/Guide"
          >
            <img src="@/assets/images/guide.png" class="sidebar-image-large" />
          </div>
        </router-link>
        <router-link
          :to="{ name: 'settings' }"
          class="nav-link"
          v-if="
            !settingsFields.find((el) => el.name === 'email')?.disabled ||
            !settingsFields.find((el) => el.name === 'reasonDelete')?.disabled
          "
        >
          <div
            class="sidebar-button pl-3"
            :class="[
              route.path === '/settings/'
                ? 'selected-sidebar-button'
                : 'unselected-sidebar-button',
            ]"
            title="Settings"
          >
            <img
              src="@/assets/images/settings.png"
              class="sidebar-image-large"
            />
          </div>
        </router-link>
      </div>
    </div>
  </div>
  <Dialog
    v-model:visible="isVisible"
    modal
    header="Invite people from your network"
    :style="{ width: '50vw' }"
    :breakpoints="{ '768px': '90vw' }"
  >
    <invite-users-form @hideDialog="isVisible = false" />
  </Dialog>
</template>

<script setup lang="ts">
import { ref } from "vue";
import ProfilePicture from "@/components/user/ProfilePicture.vue";
import ProfileIcon from "@/components/user/ProfileIcon.vue";
import Dialog from "primevue/dialog";
import InviteUsersForm from "@/components/user/InviteUsersForm.vue";
import UserBadge from "@/components/user/UserBadge.vue";
import { useRouter, useRoute } from "vue-router";
import { useAvailabilityStore } from "@/stores/AvailabilityStore";
import { useNotificationStore } from "@/stores/NotificationsStore";
import { useAuthStore } from "@/stores/AuthStore";
import { showErrorToast } from "@/utils";
import PractitionerService from "@/services/practitioner.service.js";
import { useConfigStore } from "@/stores/ConfigStore";

const router = useRouter();
const route = useRoute();
const ConfigStore = useConfigStore();
const AvailabilityStore = useAvailabilityStore();
const AuthStore = useAuthStore();
const NotificationStore = useNotificationStore();

const settingsFields = ConfigStore.config.filter(
  (el) => el.table === "settings"
);

let currentUser = AuthStore.auth;
let isVisible = ref(false);
let pendingResourceRequests = ref(0);

const getPendingResourceRequests = () => {
  PractitionerService.getPendingResourceRequests().then(
    (response) => {
      pendingResourceRequests.value = response.data.pendingResourceCount;
    },
    (error) => {
      let res =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(res);
    }
  );
};

const getAvailability = () => {
  if (AvailabilityStore.status) {
    router.push({ name: "industry-practitioner-availability" });
  } else {
    AvailabilityStore.getAvailability().then(
      (response) => {
        router.push({ name: "industry-practitioner-availability" });
      },
      (error) => {
        let res =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        console.log(res);
      }
    );
  }
};

const showInviteOthers = () => {
  if (!AuthStore.profile)
    showErrorToast(
      "You need to complete your profile before you can access other parts of the web platform"
    );
  else if (!AvailabilityStore.status)
    showErrorToast(
      "You need to specify your availability before you can access other parts of the web platform"
    );
  else isVisible.value = true;
};

AvailabilityStore.getAvailability().catch(() => {
  router.push("/not-found");
});

NotificationStore.getNotification();
getPendingResourceRequests();
</script>

<style scoped>
a {
  text-decoration: none;
}
.current-availability {
  font-size: smaller;
}
</style>
