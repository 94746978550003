<script setup lang="ts">
import { ref } from "vue";
import { useRouter } from "vue-router";
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import PractitionerSidebar from "@/components/PractitionerSidebar.vue";
import BackButton from "@/components/user/BackButton.vue";
import Multiselect from "@vueform/multiselect";
import {
  displayLabels,
  physicalDisOptions,
  yearsOfExpOptions,
  ageRangeOptions,
  areaOfExpertiseOptions,
  pronounsOptions,
  profLicenseOptions,
  typeProjectOptions,
  typeOfFirmOptions,
  positionOptions,
  typeTechnologyOptions,
  genderOptions,
  ethnicityOptions,
  stateOptions,
  companySizeOptions,
  diversityOptions,
  levelEducationOptions,
  practitionerTitleOptions,
  showToast,
  showInfoToast,
  validateOptions,
  showErrorToast,
} from "@/utils";
import { useConfigStore } from "@/stores/ConfigStore";
import { useAuthStore } from "@/stores/AuthStore";
import { useAvailabilityStore } from "@/stores/AvailabilityStore";
import UserService from "@/services/user.service.js";

const ConfigStore = useConfigStore();
const AuthStore = useAuthStore();
const AvailabilityStore = useAvailabilityStore();
const router = useRouter();

const profileFields = ConfigStore.config.filter(
  (el) => el.table === "practitioner_profile"
);
const profLicenseOptionsAdded = [
  ...profLicenseOptions,
  {
    label: "Others, please specify",
    value: "Others",
  },
];
const typeProjectOptionsAdded = [
  ...typeProjectOptions,
  {
    label: "Others, please specify",
    value: "Others",
  },
];
const areaOfExpertiseOptionsAdded = [
  ...areaOfExpertiseOptions,
  {
    label: "Others, please specify",
    value: "Others",
  },
];
const typeTechnologyOptionsAdded = [
  ...typeTechnologyOptions,
  {
    label: "Others, please specify",
    value: "Others",
  },
];

let initialState = {
  ageRange: "",
  countryStudy: "",
  ethnicity: "",
  ethnicity_text: "",
  ethnicDiv: "",
  expertise: [],
  expertise_text: "",
  gender: "",
  gender_text: "",
  genderDiv: "",
  levelEducation: "",
  city: "",
  state: "",
  organPolicy: "",
  physicalDis: [],
  physicalDis_text: "",
  position: "",
  position_text: "",
  size: "",
  type: "",
  type_text: "",
  website: "",
  yearsOfExp: "",
  title: "",
  title_text: "",
  firstName: "",
  lastName: "",
  nameOrg: "",
  profLicense: [],
  profLicense_text: "",
  typeProject: [],
  typeProject_text: "",
  pronouns: "",
  pronouns_text: "",
  typeTechnology: [],
  typeTechnology_text: "",
};

let currentUser = AuthStore.auth;

const schema = yup.object().shape({
  fields: yup.array().default(profileFields),
  title: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "title")?.disabled,
    then: (schema) => schema.required("Title is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  title_text: yup.string().when("title", {
    is: (val) => val === "Others",
    then: (schema) => schema.required("Title is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  firstName: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "firstName")?.disabled,
    then: (schema) => schema.required("First Name is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  lastName: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "lastName")?.disabled,
    then: (schema) => schema.required("Last Name is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  ageRange: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "ageRange")?.disabled,
    then: (schema) => schema.required("Age Range is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  levelEducation: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "levelEducation")?.disabled,
    then: (schema) =>
      schema.required("Highest Level of Education is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  position: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "position")?.disabled,
    then: (schema) =>
      schema.required("Position/Role in organization is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  position_text: yup.string().when("position", {
    is: (val) => val === "Others",
    then: (schema) =>
      schema.required("Position/Role in organization is required!"),
    otherwise: (schema) => schema.optional(),
  }),
  yearsOfExp: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "yearsOfExp")?.disabled,
    then: (schema) => schema.required("Years of experience is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  expertise: yup
    .array()
    .of(yup.string())
    .when("fields", {
      is: (val) => !val.find((el) => el.name === "expertise")?.disabled,
      then: (schema) =>
        schema
          .required("Area of expertise is required!")
          .min(1, "Area of expertise is required!"),
      otherwise: (schema) => schema.optional().min(0).nullable(),
    }),
  expertise_text: yup.string().when("expertise", {
    is: (val) => val.includes("Others"),
    then: (schema) => schema.required("Area of expertise is required!"),
    otherwise: (schema) => schema.optional(),
  }),
  profLicense: yup
    .array()
    .of(yup.string())
    .when("fields", {
      is: (val) => !val.find((el) => el.name === "profLicense")?.disabled,
      then: (schema) =>
        schema
          .required("Professional License is required!")
          .min(1, "Professional License is required!"),
      otherwise: (schema) => schema.optional().nullable().min(0),
    }),
  profLicense_text: yup.string().when("profLicense", {
    is: (val) => val.includes("Others"),
    then: (schema) => schema.required("Professional License is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  typeProject: yup
    .array()
    .of(yup.string())
    .when("fields", {
      is: (val) => !val.find((el) => el.name === "typeProject")?.disabled,
      then: (schema) =>
        schema
          .required(
            "Types of Projects you have Executed or Executing is required!"
          )
          .min(
            1,
            "Types of Projects you have Executed or Executing is required!"
          ),
      otherwise: (schema) => schema.optional().nullable().min(0),
    }),

  typeProject_text: yup.string().when("typeProject", {
    is: (val) => val.includes("Others"),
    then: (schema) =>
      schema.required(
        "Types of Projects you have Executed or Executing is required!"
      ),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  physicalDis: yup
    .array()
    .of(yup.string())
    .when("fields", {
      is: (val) => !val.find((el) => el.name === "physicalDis")?.disabled,
      then: (schema) =>
        schema
          .required("Physical disabilities is required!")
          .min(1, "Physical disabilities is required!"),
      otherwise: (schema) => schema.optional().nullable().min(0),
    }),
  physicalDis_text: yup.string().when("physicalDis", {
    is: (val) => val.includes("Others"),
    then: (schema) => schema.required("Physical disabilities is required!"),
    otherwise: (schema) => schema.optional(),
  }),
  nameOrg: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "nameOrg")?.disabled,
    then: (schema) =>
      schema.required("Name of Organization/Institution is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),

  city: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "city")?.disabled,
    then: (schema) => schema.required("City is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  state: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "state")?.disabled,
    then: (schema) => schema.required("State is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  type: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "type")?.disabled,
    then: (schema) => schema.required("Type of Firm is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  type_text: yup.string().when("type", {
    is: (val) => val === "Others",
    then: (schema) => schema.required("Type of Firm is required!"),
    otherwise: (schema) => schema.optional(),
  }),
  size: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "size")?.disabled,
    then: (schema) => schema.required("Size of Firm is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  ethnicDiv: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "ethnicDiv")?.disabled,
    then: (schema) =>
      schema.required("Racial Diversity of organization is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  genderDiv: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "genderDiv")?.disabled,
    then: (schema) =>
      schema.required("Gender Diversity of organization is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  website: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "website")?.disabled,
    then: (schema) => schema.required("Website of organization is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  organPolicy: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "organPolicy")?.disabled,
    then: (schema) =>
      schema.required("Organization policy/support/resources is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  pronouns: yup.string().optional().nullable(),
  pronouns_text: yup.string().when("pronouns", {
    is: (val) => val === "Others",
    then: (schema) => schema.required("Pronouns is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  ethnicity: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "ethnicity")?.disabled,
    then: (schema) => schema.required("Racial Identity is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  ethnicity_text: yup.string().when("ethnicity", {
    is: (val) => val === "Others",
    then: (schema) => schema.required("Racial Identity is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  gender: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "gender")?.disabled,
    then: (schema) => schema.required("Gender Identity is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  gender_text: yup.string().when("gender", {
    is: (val) => val === "Others",
    then: (schema) => schema.required("Gender Identity is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  typeTechnology: yup
    .array()
    .of(yup.string())
    .when("fields", {
      is: (val) => !val.find((el) => el.name === "typeTechnology")?.disabled,
      then: (schema) =>
        schema
          .required(
            "Types of Technologies Adopted by Your Organization is required!"
          )
          .min(
            1,
            "Types of Technologies Adopted by Your Organization is required!"
          ),
      otherwise: (schema) => schema.optional().nullable().min(0),
    }),
  typeTechnology_text: yup.string().when("typeTechnology", {
    is: (val) => val.includes("Others"),
    then: (schema) =>
      schema.required(
        "Types of Technologies Adopted by Your Organization is required!"
      ),
    otherwise: (schema) => schema.optional(),
  }),
});
if (AuthStore.profile) {
  initialState.ageRange = currentUser.profile.ageRange;
  initialState.countryStudy = currentUser.profile.countryStudy;
  initialState.ethnicDiv = currentUser.profile.ethnicDiv;
  initialState.expertise = JSON.parse(currentUser.profile.expertise);
  if (JSON.parse(currentUser.profile.expertise).includes("Others")) {
    initialState["expertise_text"] = JSON.parse(
      currentUser.profile.expertise
    ).at(-1);
    initialState.expertise = JSON.parse(currentUser.profile.expertise).slice(
      0,
      -1
    );
  } else {
    initialState.expertise = JSON.parse(currentUser.profile.expertise);
  }
  initialState.genderDiv = currentUser.profile.genderDiv;
  initialState.levelEducation = currentUser.profile.levelEducation;
  initialState.city = currentUser.profile.city;
  initialState.state = currentUser.profile.state;
  initialState.organPolicy = currentUser.profile.organPolicy;
  if (JSON.parse(currentUser.profile.physicalDis).includes("Others")) {
    initialState["physicalDis_text"] = JSON.parse(
      currentUser.profile.physicalDis
    ).at(-1);
    initialState.physicalDis = JSON.parse(
      currentUser.profile.physicalDis
    ).slice(0, -1);
  } else {
    initialState.physicalDis = JSON.parse(currentUser.profile.physicalDis);
  }
  if (
    positionOptions.filter((e) => e.value === currentUser.profile.position)
      .length === 0
  ) {
    initialState.position = "Others";
    initialState.position_text = currentUser.profile.position;
  } else {
    initialState.position = currentUser.profile.position;
  }
  if (JSON.parse(currentUser.profile.profLicense).includes("Others")) {
    initialState["profLicense_text"] = JSON.parse(
      currentUser.profile.profLicense
    ).at(-1);
    initialState.profLicense = JSON.parse(
      currentUser.profile.profLicense
    ).slice(0, -1);
  } else {
    initialState.profLicense = JSON.parse(currentUser.profile.profLicense);
  }
  if (JSON.parse(currentUser.profile.typeProject).includes("Others")) {
    initialState["typeProject_text"] = JSON.parse(
      currentUser.profile.typeProject
    ).at(-1);
    initialState.typeProject = JSON.parse(
      currentUser.profile.typeProject
    ).slice(0, -1);
  } else {
    initialState.typeProject = JSON.parse(currentUser.profile.typeProject);
  }
  initialState.size = currentUser.profile.size;
  if (
    typeOfFirmOptions.filter((e) => e.value === currentUser.profile.type)
      .length === 0
  ) {
    initialState.type = "Others";
    initialState.type_text = currentUser.profile.type;
  } else {
    initialState.type = currentUser.profile.type;
  }
  initialState.website = currentUser.profile.website;
  initialState.yearsOfExp = currentUser.profile.yearsOfExp;
  if (JSON.parse(currentUser.profile.typeTechnology).includes("Others")) {
    initialState["typeTechnology_text"] = JSON.parse(
      currentUser.profile.typeTechnology
    ).at(-1);
    initialState.typeTechnology = JSON.parse(
      currentUser.profile.typeTechnology
    ).slice(0, -1);
  } else {
    initialState.typeTechnology = JSON.parse(
      currentUser.profile.typeTechnology
    );
  }
  if (
    !practitionerTitleOptions.some((el) => el.value === AuthStore.auth.title)
  ) {
    initialState.title_text = AuthStore.auth.title;
    initialState.title = "Others";
  } else {
    initialState.title = AuthStore.auth.title;
  }
  initialState.firstName = AuthStore.auth.firstName;
  initialState.lastName = AuthStore.auth.lastName;
  initialState.nameOrg = AuthStore.auth.nameOrg;
  if (
    !pronounsOptions.some((el) => el.value === AuthStore.auth.profile.pronouns)
  ) {
    initialState.pronouns_text = AuthStore.auth.profile.pronouns;
    initialState.pronouns = "Others";
  } else {
    initialState.pronouns = AuthStore.auth.profile.pronouns;
  }
  if (!ethnicityOptions.some((el) => el.value === AuthStore.auth.ethnicity)) {
    initialState.ethnicity_text = AuthStore.auth.ethnicity;
    initialState.ethnicity = "Others";
  } else {
    initialState.ethnicity = AuthStore.auth.ethnicity;
  }
  if (!genderOptions.some((el) => el.value === AuthStore.auth.gender)) {
    initialState.gender_text = AuthStore.auth.gender;
    initialState.gender = "Others";
  } else {
    initialState.gender = AuthStore.auth.gender;
  }
} else {
  initialState.title = AuthStore.auth.title;
  initialState.firstName = AuthStore.auth.firstName;
  initialState.lastName = AuthStore.auth.lastName;
  // showInfoToast("Please complete your profile before proceeding to Home page.");
}

const formValues = {
  ageRange: initialState.ageRange,
  countryStudy: initialState.countryStudy,
  ethnicDiv: initialState.ethnicDiv,
  expertise: initialState.expertise,
  expertise_text: initialState.expertise_text,
  genderDiv: initialState.genderDiv,
  levelEducation: initialState.levelEducation,
  city: initialState.city,
  state: initialState.state,
  organPolicy: initialState.organPolicy,
  physicalDis: initialState.physicalDis,
  physicalDis_text: initialState.physicalDis_text,
  position: initialState.position,
  position_text: initialState.position_text,
  size: initialState.size,
  type: initialState.type,
  type_text: initialState.type_text,
  website: initialState.website,
  typeTechnology: initialState.typeTechnology,
  typeTechnology_text: initialState.typeTechnology_text,
  yearsOfExp: initialState.yearsOfExp,
  title: initialState.title,
  title_text: initialState.title_text,
  firstName: initialState.firstName,
  lastName: initialState.lastName,
  nameOrg: initialState.nameOrg,
  profLicense: initialState.profLicense,
  profLicense_text: initialState.profLicense_text,
  typeProject: initialState.typeProject,
  typeProject_text: initialState.typeProject_text,
  pronouns: initialState.pronouns,
  pronouns_text: initialState.pronouns_text,
  gender: initialState.gender,
  gender_text: initialState.gender_text,
  ethnicity: initialState.ethnicity,
  ethnicity_text: initialState.ethnicity_text,
};

let loading = ref(false);
let successful = ref(false);
let message = ref("");

const saveProfile = (data) => {
  loading.value = true;
  if (data.type === "Others") data.type = data["type_text"];
  if (data.expertise.includes("Others")) {
    data.expertise.push(data["expertise_text"]);
  }
  if (data.title === "Others") data.title = data["title_text"];
  if (data.position === "Others") data.position = data["position_text"];
  if (data.gender === "Others") data.gender = data["gender_text"];
  if (data.ethnicity === "Others") data.ethnicity = data["ethnicity_text"];
  if (data.pronouns === "Others") data.pronouns = data["pronouns_text"];

  if (data.profLicense.includes("Others")) {
    data.profLicense.push(data["profLicense_text"]);
  }
  if (data.typeProject.includes("Others")) {
    data.typeProject.push(data["typeProject_text"]);
  }
  if (data.physicalDis.includes("Others")) {
    data.physicalDis.push(data["physicalDis_text"]);
  }
  if (data.typeTechnology.includes("Others")) {
    data.typeTechnology.push(data["typeTechnology_text"]);
  }
  AuthStore.saveProfile(data).then(
    () => {
      AuthStore.getProfile().then(
        () => {
          showToast("Your profile has been updated.");
          console.log(AvailabilityStore.availability);
          if (AvailabilityStore.status)
            router.push({ name: "industry-practitioner-feed" });
          else router.push({ name: "industry-practitioner-availability" });
          loading.value = false;
        },
        (err) => {
          loading.value = false;
          message.value =
            (err.response && err.response.data && err.response.data.message) ||
            err.message ||
            err.toString();
        }
      );
    },
    (error) => {
      loading.value = false;
      message.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};

const onInvalidSubmit = ({ errors }) => {
  Object.values(errors).forEach((error) => showErrorToast(error));
};
const elementVisible = ref(false);
let tooltipTimeout;
const showTooltip = () => {
  elementVisible.value = true;
  tooltipTimeout = setTimeout(() => (elementVisible.value = false), 5000);
};
const hideTooltip = () => {
  elementVisible.value = false;
  clearTimeout(tooltipTimeout);
};

const validateOpt = (e, s) => {
  validateOptions(e, s, "None");
};

const cities = ref([] as any[]);

const getCityOptions = (state) => {
  UserService.getCities(state).then(
    (response) => {
      cities.value = response.data;
    },
    (error) => {
      console.log(error);
      message.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};

if (initialState.state) getCityOptions(initialState.state);
</script>

<style scoped>
.card-container.card {
  max-width: 95% !important;
  padding: 20px 40px 40px;
  margin: 0 auto 25px;
}
.form-grid {
  display: grid;
  grid-template-columns: 1fr 1em 1fr;
  border-radius: 8px;
  column-gap: 1em;
}
.card {
  margin: 0 5px 25px 5px;
}

@media screen and (max-width: 1024px) {
  .card-container.card {
    max-width: 95% !important;
    padding: 20px 10px 40px;
    margin: 0 auto 25px;
  }
  .form-grid {
    display: block;
    border-radius: 8px;
  }
}
</style>

<template>
  <div class="grid-container">
    <practitioner-sidebar></practitioner-sidebar>
    <div class="col-md-12 mt-3">
      <div class="back-grid">
        <back-button></back-button>
        <h2 style="text-align: center; font-weight: bold">
          Practitioner Profile
        </h2>
      </div>
      <div>
        <br />
        <span class="font-color-red ml-5">* required field</span>
        <div class="card card-container">
          <Form
            @submit="saveProfile"
            @invalid-submit="onInvalidSubmit"
            v-slot="{ values }"
            :validation-schema="schema"
            :initial-values="formValues"
          >
            <div class="form-grid">
              <div>
                <h4 class="text-align-center bold-text">
                  Personal Information
                </h4>
                <br />

                <div class="form-name-column">
                  <div
                    class="form-group"
                    v-if="
                      !profileFields.find((el) => el.name === 'title')?.disabled
                    "
                  >
                    <label for="title"
                      >Title<span class="super font-color-red">*</span>
                    </label>
                    <Field
                      v-model="values.title"
                      name="title"
                      type="text"
                      class="dropdown"
                      v-slot="{ field }"
                    >
                      <multiselect
                        v-bind="field"
                        v-model="values.title"
                        :options="practitionerTitleOptions"
                        mode="single"
                        :searchable="true"
                      />
                    </Field>
                    <div v-if="values.title === 'Others'">
                      <Field
                        name="title_text"
                        type="text"
                        placeholder="Please Specify"
                        class="form-control bg-others"
                      />
                    </div>
                    <ErrorMessage name="title" class="error-feedback" />
                  </div>
                  <div
                    class="form-group"
                    v-if="
                      !profileFields.find((el) => el.name === 'firstName')
                        ?.disabled
                    "
                  >
                    <label for="firstName"
                      >First Name<span class="super font-color-red">*</span>
                    </label>
                    <Field name="firstName" type="text" class="form-control" />
                    <ErrorMessage name="firstName" class="error-feedback" />
                  </div>

                  <div
                    class="form-group"
                    v-if="
                      !profileFields.find((el) => el.name === 'lastName')
                        ?.disabled
                    "
                  >
                    <label for="lastName"
                      >Last Name<span class="super font-color-red">*</span>
                    </label>
                    <Field name="lastName" type="text" class="form-control" />
                    <ErrorMessage name="lastName" class="error-feedback" />
                  </div>
                </div>

                <div class="form-column">
                  <div
                    class="form-group"
                    v-if="
                      !profileFields.find((el) => el.name === 'gender')
                        ?.disabled
                    "
                  >
                    <label for="gender"
                      >Gender Identity<span class="super font-color-red"
                        >*</span
                      >
                      <font-awesome-icon
                        icon="fa-solid fa-lock"
                        class="ml-1"
                        title="This information is private."
                    /></label>
                    <Field
                      v-model="values.gender"
                      name="gender"
                      type="text"
                      class="dropdown"
                      v-slot="{ field }"
                    >
                      <multiselect
                        v-bind="field"
                        v-model="values.gender"
                        :options="genderOptions"
                        mode="single"
                        :searchable="true"
                      />
                    </Field>
                    <div v-if="values.gender === 'Others'">
                      <Field
                        name="gender_text"
                        type="text"
                        placeholder="Please Specify"
                        class="form-control bg-others"
                      />
                    </div>
                    <ErrorMessage name="gender" class="error-feedback" />
                  </div>

                  <div
                    class="form-group"
                    v-if="
                      !profileFields.find((el) => el.name === 'ethnicity')
                        ?.disabled
                    "
                  >
                    <label for="ethnicity"
                      >Racial Identity<span class="super font-color-red"
                        >*</span
                      >
                      <font-awesome-icon
                        icon="fa-solid fa-lock"
                        class="ml-1"
                        title="This information is private."
                    /></label>
                    <Field
                      v-model="values.ethnicity"
                      name="ethnicity"
                      type="text"
                      class="dropdown"
                      v-slot="{ field }"
                    >
                      <multiselect
                        v-bind="field"
                        v-model="values.ethnicity"
                        :options="ethnicityOptions"
                        mode="single"
                        :searchable="true"
                      />
                    </Field>
                    <div v-if="values.ethnicity === 'Others'">
                      <Field
                        name="ethnicity_text"
                        type="text"
                        placeholder="Please Specify"
                        class="form-control bg-others"
                      />
                    </div>
                    <ErrorMessage name="ethnicity" class="error-feedback" />
                  </div>
                </div>

                <div class="form-column">
                  <div
                    class="form-group"
                    v-if="
                      !profileFields.find((el) => el.name === 'pronouns')
                        ?.disabled
                    "
                  >
                    <label for="pronouns">Pronouns </label>
                    <Field
                      v-model="values.pronouns"
                      name="pronouns"
                      type="text"
                      class="dropdown"
                      v-slot="{ field }"
                    >
                      <multiselect
                        v-bind="field"
                        v-model="values.pronouns"
                        :options="pronounsOptions"
                        mode="single"
                        :searchable="true"
                      />
                    </Field>
                    <div v-if="values.pronouns === 'Others'">
                      <Field
                        name="pronouns_text"
                        type="text"
                        placeholder="Please Specify"
                        class="form-control bg-others"
                      />
                    </div>
                    <ErrorMessage name="pronouns" class="error-feedback" />
                  </div>

                  <div
                    class="form-group"
                    v-if="
                      !profileFields.find((el) => el.name === 'ageRange')
                        ?.disabled
                    "
                  >
                    <label for="ageRange"
                      >Age Range<span class="super font-color-red">*</span>
                      <font-awesome-icon
                        icon="fa-solid fa-lock"
                        class="ml-1"
                        title="This information is private."
                    /></label>
                    <Field
                      v-model="values.ageRange"
                      name="ageRange"
                      type="text"
                      class="dropdown"
                      v-slot="{ field }"
                    >
                      <multiselect
                        v-bind="field"
                        v-model="values.ageRange"
                        :options="ageRangeOptions"
                        mode="single"
                        :searchable="true"
                      />
                    </Field>
                    <ErrorMessage name="ageRange" class="error-feedback" />
                  </div>
                </div>

                <div class="form-column">
                  <div
                    class="form-group"
                    v-if="
                      !profileFields.find((el) => el.name === 'levelEducation')
                        ?.disabled
                    "
                  >
                    <label for="levelEducation"
                      >Highest Level of Education<span
                        class="super font-color-red"
                        >*</span
                      >
                    </label>
                    <Field
                      v-model="values.levelEducation"
                      name="levelEducation"
                      type="text"
                      class="dropdown"
                      v-slot="{ field }"
                    >
                      <multiselect
                        v-bind="field"
                        v-model="values.levelEducation"
                        :options="levelEducationOptions"
                        mode="single"
                        :searchable="true"
                      />
                    </Field>
                    <ErrorMessage
                      name="levelEducation"
                      class="error-feedback"
                    />
                  </div>
                  <div
                    class="form-group"
                    v-if="
                      !profileFields.find((el) => el.name === 'yearsOfExp')
                        ?.disabled
                    "
                  >
                    <label for="yearsOfExp"
                      >Years of Experience<span class="super font-color-red"
                        >*</span
                      >
                    </label>
                    <Field
                      v-model="values.yearsOfExp"
                      name="yearsOfExp"
                      type="text"
                      class="dropdown"
                      v-slot="{ field }"
                    >
                      <multiselect
                        v-bind="field"
                        v-model="values.yearsOfExp"
                        :options="yearsOfExpOptions"
                        mode="single"
                        :searchable="true"
                      />
                    </Field>
                    <ErrorMessage name="yearsOfExp" class="error-feedback" />
                  </div>
                </div>
                <div
                  class="form-group"
                  v-if="
                    !profileFields.find((el) => el.name === 'position')
                      ?.disabled
                  "
                >
                  <label for="position"
                    >Position/Role in Organization<span
                      class="super font-color-red"
                      >*</span
                    >
                  </label>
                  <Field
                    v-model="values.position"
                    name="position"
                    type="text"
                    class="dropdown"
                    v-slot="{ field }"
                  >
                    <multiselect
                      v-bind="field"
                      v-model="values.position"
                      :options="positionOptions"
                      mode="single"
                      :searchable="true"
                    />
                  </Field>
                  <div v-if="values.position === 'Others'">
                    <Field
                      name="position_text"
                      type="text"
                      placeholder="Please Specify"
                      class="form-control bg-others"
                    />
                  </div>
                  <ErrorMessage name="position" class="error-feedback" />
                </div>
                <div
                  class="form-group"
                  v-if="
                    !profileFields.find((el) => el.name === 'expertise')
                      ?.disabled
                  "
                >
                  <label for="expertise"
                    >Area of Expertise<span class="super font-color-red"
                      >*</span
                    >
                  </label>
                  <Field
                    v-model="values.expertise"
                    name="expertise"
                    type="text"
                    class="dropdown"
                    v-slot="{ field }"
                  >
                    <Multiselect
                      v-bind="field"
                      v-model="values.expertise"
                      :options="areaOfExpertiseOptionsAdded"
                      mode="tags"
                      :multipleLabel="displayLabels"
                      :searchable="true"
                      :hideSelected="false"
                      :closeOnSelect="false"
                      :closeOnDeselect="false"
                    >
                      <template v-slot:option="{ option }">
                        <input
                          type="checkbox"
                          class="input-pointer"
                          :checked="values.expertise.includes(option.value)"
                        />
                        &nbsp; {{ option.label }}
                      </template>
                    </Multiselect>
                  </Field>
                  <div v-if="values.expertise.includes('Others')">
                    <Field
                      name="expertise_text"
                      type="text"
                      placeholder="Please Specify"
                      class="form-control bg-others"
                    />
                  </div>
                  <ErrorMessage name="expertise" class="error-feedback" />
                </div>
                <div
                  class="form-group"
                  v-if="
                    !profileFields.find((el) => el.name === 'profLicense')
                      ?.disabled
                  "
                >
                  <label for="profLicense"
                    >Professional Licence<span class="super font-color-red"
                      >*</span
                    >
                  </label>
                  <Field
                    v-model="values.profLicense"
                    name="profLicense"
                    type="text"
                    class="dropdown"
                    v-slot="{ field }"
                  >
                    <Multiselect
                      v-bind="field"
                      v-model="values.profLicense"
                      :options="profLicenseOptionsAdded"
                      mode="tags"
                      @change="validateOpt"
                      :multipleLabel="displayLabels"
                      :searchable="true"
                      :hideSelected="false"
                      :closeOnSelect="false"
                      :closeOnDeselect="false"
                    >
                      <template v-slot:option="{ option }">
                        <input
                          type="checkbox"
                          class="input-pointer"
                          :checked="values.profLicense.includes(option.value)"
                        />
                        &nbsp; {{ option.label }}
                      </template>
                    </Multiselect>
                  </Field>
                  <div v-if="values.profLicense.includes('Others')">
                    <Field
                      name="profLicense_text"
                      type="text"
                      placeholder="Please Specify"
                      class="form-control bg-others"
                    />
                  </div>
                  <ErrorMessage name="profLicense" class="error-feedback" />
                </div>

                <div
                  class="form-group"
                  v-if="
                    !profileFields.find((el) => el.name === 'typeProject')
                      ?.disabled
                  "
                >
                  <label for="typeProject"
                    >Types of Projects you have Executed or Executing<span
                      class="super font-color-red"
                      >*</span
                    >
                  </label>
                  <Field
                    v-model="values.typeProject"
                    name="typeProject"
                    type="text"
                    class="dropdown"
                    v-slot="{ field }"
                  >
                    <Multiselect
                      v-bind="field"
                      v-model="values.typeProject"
                      :options="typeProjectOptionsAdded"
                      mode="tags"
                      :multipleLabel="displayLabels"
                      :searchable="true"
                      :hideSelected="false"
                      :closeOnSelect="false"
                      :closeOnDeselect="false"
                    >
                      <template v-slot:option="{ option }">
                        <input
                          type="checkbox"
                          class="input-pointer"
                          :checked="values.typeProject.includes(option.value)"
                        />
                        &nbsp; {{ option.label }}
                      </template>
                    </Multiselect>
                  </Field>
                  <div v-if="values.typeProject.includes('Others')">
                    <Field
                      name="typeProject_text"
                      type="text"
                      placeholder="Please Specify"
                      class="form-control bg-others"
                    />
                  </div>
                  <ErrorMessage name="typeProject" class="error-feedback" />
                </div>
                <div
                  class="form-group"
                  v-if="
                    !profileFields.find((el) => el.name === 'physicalDis')
                      ?.disabled
                  "
                >
                  <label for="physicalDis"
                    >Physical Disabilities (if any)
                    <span class="super font-color-red">*</span>
                    <font-awesome-icon
                      icon="fa-solid fa-lock"
                      class="ml-1"
                      title="This information is private."
                  /></label>
                  <Field
                    v-model="values.physicalDis"
                    name="physicalDis"
                    type="text"
                    class="form-control"
                    v-slot="{ field }"
                  >
                    <Multiselect
                      v-bind="field"
                      v-model="values.physicalDis"
                      :options="physicalDisOptions"
                      mode="tags"
                      @change="validateOpt"
                      :multipleLabel="displayLabels"
                      :searchable="true"
                      :hideSelected="false"
                      :closeOnSelect="false"
                      :closeOnDeselect="false"
                    >
                      <template v-slot:option="{ option }">
                        <input
                          type="checkbox"
                          class="input-pointer"
                          :checked="values.physicalDis.includes(option.value)"
                        />
                        &nbsp; {{ option.label }}
                      </template>
                    </Multiselect>
                  </Field>
                  <div v-if="values.physicalDis.includes('Others')">
                    <Field
                      name="physicalDis_text"
                      type="text"
                      placeholder="Please Specify"
                      class="form-control bg-others"
                    />
                  </div>
                  <ErrorMessage name="physicalDis" class="error-feedback" />
                </div>
              </div>
              <va-divider vertical />
              <div>
                <h4 class="text-align-center bold-text">Company Information</h4>
                <br />
                <div
                  class="form-group"
                  v-if="
                    !profileFields.find((el) => el.name === 'nameOrg')?.disabled
                  "
                >
                  <label for="nameOrg"
                    >Name of Organization<span class="super font-color-red"
                      >*</span
                    >
                  </label>
                  <Field name="nameOrg" type="text" class="form-control" />
                  <ErrorMessage name="nameOrg" class="error-feedback" />
                </div>

                <div class="form-group">
                  <label for="location"
                    >Location of {{ values.nameOrg }} (i.e. Local office)<span
                      class="super font-color-red"
                      >*
                    </span>
                  </label>
                  <div class="flex flex-direction-row gap-1">
                    <div
                      style="flex-basis: 0"
                      v-if="
                        !profileFields.find((el) => el.name === 'state')
                          ?.disabled
                      "
                    >
                      <Field
                        v-model="values.state"
                        name="state"
                        type="text"
                        class="dropdown"
                        v-slot="{ field }"
                      >
                        <multiselect
                          v-bind="field"
                          v-model="values.state"
                          :options="stateOptions"
                          mode="single"
                          placeholder="Select State"
                          :searchable="true"
                          @change="
                            getCityOptions(values.state), (values.city = '')
                          "
                        />
                      </Field>
                      <ErrorMessage name="state" class="error-feedback" />
                    </div>
                    <div
                      style="flex-basis: 0"
                      v-if="
                        !profileFields.find((el) => el.name === 'city')
                          ?.disabled
                      "
                    >
                      <Field
                        v-model="values.city"
                        name="city"
                        type="text"
                        class="dropdown"
                        v-slot="{ field }"
                      >
                        <multiselect
                          v-bind="field"
                          v-model="values.city"
                          :options="cities"
                          mode="single"
                          :searchable="true"
                        />
                      </Field>
                      <ErrorMessage name="city" class="error-feedback" />
                    </div>
                  </div>
                </div>
                <div
                  class="form-group"
                  v-if="
                    !profileFields.find((el) => el.name === 'type')?.disabled
                  "
                >
                  <label for="type"
                    >Type of Firm<span class="super font-color-red">*</span>
                  </label>
                  <Field
                    v-model="values.type"
                    name="type"
                    type="text"
                    class="dropdown"
                    v-slot="{ field }"
                  >
                    <multiselect
                      v-bind="field"
                      v-model="values.type"
                      :options="typeOfFirmOptions"
                      mode="single"
                      :searchable="true"
                    />
                  </Field>
                  <div v-if="values.type === 'Others'">
                    <Field
                      name="type_text"
                      type="text"
                      placeholder="Please Specify"
                      class="form-control bg-others"
                    />
                  </div>
                  <ErrorMessage name="type" class="error-feedback" />
                </div>
                <div
                  class="form-group"
                  v-if="
                    !profileFields.find((el) => el.name === 'size')?.disabled
                  "
                >
                  <label for="size"
                    >Size of {{ values.nameOrg
                    }}<span class="super font-color-red">*</span>
                  </label>
                  <Field
                    v-model="values.size"
                    name="size"
                    type="text"
                    class="dropdown"
                    v-slot="{ field }"
                  >
                    <multiselect
                      v-bind="field"
                      v-model="values.size"
                      :options="companySizeOptions"
                      mode="single"
                      :searchable="true"
                    />
                  </Field>
                  <ErrorMessage name="size" class="error-feedback" />
                </div>
                <div
                  class="form-group"
                  v-if="
                    !profileFields.find((el) => el.name === 'genderDiv')
                      ?.disabled
                  "
                >
                  <label for="genderDiv"
                    >Gender Diversity of {{ values.nameOrg }}
                    <span class="super font-color-red">*</span>
                  </label>
                  <Field
                    v-model="values.genderDiv"
                    name="genderDiv"
                    type="text"
                    class="dropdown"
                    v-slot="{ field }"
                  >
                    <multiselect
                      v-bind="field"
                      v-model="values.genderDiv"
                      :options="diversityOptions"
                      mode="single"
                      :searchable="true"
                    />
                  </Field>
                  <ErrorMessage name="genderDiv" class="error-feedback" />
                </div>

                <div
                  class="form-group"
                  v-if="
                    !profileFields.find((el) => el.name === 'ethnicDiv')
                      ?.disabled
                  "
                >
                  <label for="ethnicDiv"
                    >Racial Diversity of {{ values.nameOrg }}
                    <span class="super font-color-red">*</span>
                  </label>
                  <Field
                    v-model="values.ethnicDiv"
                    name="ethnicDiv"
                    type="text"
                    class="dropdown"
                    v-slot="{ field }"
                  >
                    <multiselect
                      v-bind="field"
                      v-model="values.ethnicDiv"
                      :options="diversityOptions"
                      mode="single"
                      :searchable="true"
                    />
                  </Field>
                  <ErrorMessage name="ethnicDiv" class="error-feedback" />
                </div>
                <div
                  class="form-group"
                  v-if="
                    !profileFields.find((el) => el.name === 'website')?.disabled
                  "
                >
                  <label for="website"
                    >Website of {{ values.nameOrg
                    }}<span class="super font-color-red">*</span>
                  </label>
                  <Field name="website" type="text" class="form-control" />
                  <ErrorMessage name="website" class="error-feedback" />
                </div>
                <div
                  class="form-group"
                  v-if="
                    !profileFields.find((el) => el.name === 'typeTechnology')
                      ?.disabled
                  "
                >
                  <label for="typeTechnology"
                    >Types of Technologies Adopted by Your Organization
                    <span class="super font-color-red">*</span></label
                  >
                  <Field
                    v-model="values.typeTechnology"
                    name="typeTechnology"
                    type="text"
                    class="dropdown"
                    v-slot="{ field }"
                  >
                    <Multiselect
                      v-bind="field"
                      v-model="values.typeTechnology"
                      :options="typeTechnologyOptionsAdded"
                      mode="tags"
                      :multipleLabel="displayLabels"
                      :searchable="true"
                      :hideSelected="false"
                      :closeOnSelect="false"
                      :closeOnDeselect="false"
                    >
                      <template v-slot:option="{ option }">
                        <input
                          type="checkbox"
                          class="input-pointer"
                          :checked="
                            values.typeTechnology.includes(option.value)
                          "
                        />
                        &nbsp; {{ option.label }}
                      </template>
                    </Multiselect>
                  </Field>
                  <div v-if="values.typeTechnology.includes('Others')">
                    <Field
                      name="typeTechnology_text"
                      type="text"
                      placeholder="Please Specify"
                      class="form-control bg-others"
                    />
                  </div>
                </div>
                <div
                  class="form-group"
                  v-if="
                    !profileFields.find((el) => el.name === 'organPolicy')
                      ?.disabled
                  "
                >
                  <label for="organPolicy"
                    >What Your Company do to Support Student Development
                    <span class="super font-color-red">*</span>
                    &nbsp;
                    <span role="button" class="hint">
                      <font-awesome-icon
                        icon="fa-solid fa-circle-question"
                        @click="showTooltip"
                      />
                      <span
                        class="speech_bubble tool_tip_text"
                        id="tooltip"
                        v-show="elementVisible"
                        style="display: inline"
                      >
                        <button type="button" class="close-btn-tooltip mr-4">
                          <font-awesome-icon
                            icon="fa-solid fa-xmark"
                            class="close-btn-icon"
                            @click="hideTooltip"
                          />
                        </button>
                        <span style="margin-bottom: 0">
                          Please indicate (if any) policies, practices, support
                          or resources your organization have toward supporting
                          workforce development collaboration with academia. It
                          can be as simple as for example, in the case of site
                          visit, do you offer PPE to students or they are to
                          come with it?
                        </span>
                      </span>
                    </span>
                  </label>
                  <Field name="organPolicy" type="text" class="form-control" />
                  <ErrorMessage name="organPolicy" class="error-feedback" />
                </div>
              </div>
            </div>

            <br />
            <div class="form-group text-align-center">
              <button class="btn btn-primary btn-block" :disabled="loading">
                <span
                  v-show="loading"
                  class="spinner-border spinner-border-sm"
                ></span>
                <span>Save Profile</span>
              </button>
            </div>
          </Form>
        </div>
        <div
          v-if="message"
          :class="successful ? 'alert-success' : 'alert-danger'"
        >
          {{ message }}
        </div>
      </div>
    </div>
  </div>
</template>
