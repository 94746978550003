import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "table" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "table" }
const _hoisted_4 = ["href"]
const _hoisted_5 = ["href"]
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { key: 2 }

import { Profile } from "@/types";
import { valid } from "@/utils";

// eslint-disable-next-line no-undef

export default /*@__PURE__*/_defineComponent({
  __name: 'PractitionerProfileTable',
  props: {
    userProfile: {}
  },
  setup(__props: any) {

const props = __props;

console.log(props.userProfile);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[23] || (_cache[23] = _createElementVNode("h5", { class: "text-align-center font-italic font-bold" }, "Personal Information", -1)),
    _createElementVNode("table", _hoisted_1, [
      _createElementVNode("tr", null, [
        _cache[0] || (_cache[0] = _createElementVNode("td", null, "First Name", -1)),
        _createElementVNode("td", null, _toDisplayString(_ctx.userProfile.user.firstName), 1)
      ]),
      _createElementVNode("tr", null, [
        _cache[1] || (_cache[1] = _createElementVNode("td", null, "Last Name", -1)),
        _createElementVNode("td", null, _toDisplayString(_ctx.userProfile.user.lastName), 1)
      ]),
      _createElementVNode("tr", null, [
        _cache[2] || (_cache[2] = _createElementVNode("td", null, "Email", -1)),
        _createElementVNode("td", null, _toDisplayString(_ctx.userProfile.user.email), 1)
      ]),
      _createElementVNode("tr", null, [
        _cache[3] || (_cache[3] = _createElementVNode("td", null, "Name of Organization", -1)),
        _createElementVNode("td", null, _toDisplayString(_ctx.userProfile.user.nameOrg), 1)
      ]),
      _createElementVNode("tr", null, [
        _cache[4] || (_cache[4] = _createElementVNode("td", null, "Title", -1)),
        _createElementVNode("td", null, _toDisplayString(_ctx.userProfile.user.title), 1)
      ]),
      _createElementVNode("tr", null, [
        _cache[5] || (_cache[5] = _createElementVNode("td", null, "Gender Identity", -1)),
        _createElementVNode("td", null, _toDisplayString(_ctx.userProfile.user.gender), 1)
      ]),
      _createElementVNode("tr", null, [
        _cache[6] || (_cache[6] = _createElementVNode("td", null, "Ethnicity", -1)),
        _createElementVNode("td", null, _toDisplayString(_ctx.userProfile.user.ethnicity), 1)
      ]),
      (_unref(valid)(_ctx.userProfile.pronouns))
        ? (_openBlock(), _createElementBlock("tr", _hoisted_2, [
            _cache[7] || (_cache[7] = _createElementVNode("td", null, "Pronouns", -1)),
            _createElementVNode("td", null, _toDisplayString(_ctx.userProfile.pronouns), 1)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("tr", null, [
        _cache[8] || (_cache[8] = _createElementVNode("td", null, "Age Range", -1)),
        _createElementVNode("td", null, _toDisplayString(_ctx.userProfile.ageRange), 1)
      ]),
      _createElementVNode("tr", null, [
        _cache[9] || (_cache[9] = _createElementVNode("td", null, "Years of Experience", -1)),
        _createElementVNode("td", null, _toDisplayString(_ctx.userProfile.yearsOfExp), 1)
      ]),
      _createElementVNode("tr", null, [
        _cache[10] || (_cache[10] = _createElementVNode("td", null, "Highest Level of Education", -1)),
        _createElementVNode("td", null, _toDisplayString(_ctx.userProfile.levelEducation), 1)
      ]),
      _createElementVNode("tr", null, [
        _cache[11] || (_cache[11] = _createElementVNode("td", null, "Physical Disabilities", -1)),
        _createElementVNode("td", null, _toDisplayString(JSON.parse(_ctx.userProfile?.physicalDis).join(", ")), 1)
      ]),
      _createElementVNode("tr", null, [
        _cache[12] || (_cache[12] = _createElementVNode("td", null, "Area of Specialization", -1)),
        _createElementVNode("td", null, _toDisplayString(JSON.parse(_ctx.userProfile?.expertise || '[""]').join(", ")), 1)
      ]),
      _createElementVNode("tr", null, [
        _cache[13] || (_cache[13] = _createElementVNode("td", null, "Professional License", -1)),
        _createElementVNode("td", null, _toDisplayString(JSON.parse(_ctx.userProfile?.profLicense || '[""]').join(", ")), 1)
      ])
    ]),
    _cache[24] || (_cache[24] = _createElementVNode("h5", { class: "text-align-center font-italic font-bold" }, "Company Information", -1)),
    _createElementVNode("table", _hoisted_3, [
      _createElementVNode("tr", null, [
        _cache[14] || (_cache[14] = _createElementVNode("td", null, "Location", -1)),
        _createElementVNode("td", null, _toDisplayString(_ctx.userProfile.city + " " + _ctx.userProfile.state), 1)
      ]),
      _createElementVNode("tr", null, [
        _cache[15] || (_cache[15] = _createElementVNode("td", null, "Type of Organization", -1)),
        _createElementVNode("td", null, _toDisplayString(_ctx.userProfile.type), 1)
      ]),
      _createElementVNode("tr", null, [
        _cache[16] || (_cache[16] = _createElementVNode("td", null, "Size of Organization", -1)),
        _createElementVNode("td", null, _toDisplayString(_ctx.userProfile.size), 1)
      ]),
      _createElementVNode("tr", null, [
        _cache[17] || (_cache[17] = _createElementVNode("td", null, "Gender Diversity of Organization", -1)),
        _createElementVNode("td", null, _toDisplayString(_ctx.userProfile.genderDiv), 1)
      ]),
      _createElementVNode("tr", null, [
        _cache[18] || (_cache[18] = _createElementVNode("td", null, "Racial Diversity of Organization", -1)),
        _createElementVNode("td", null, _toDisplayString(_ctx.userProfile.ethnicDiv), 1)
      ]),
      _createElementVNode("tr", null, [
        _cache[19] || (_cache[19] = _createElementVNode("td", null, "Website of Organization", -1)),
        _createElementVNode("td", null, [
          (!_ctx.userProfile.website.startsWith('http'))
            ? (_openBlock(), _createElementBlock("a", {
                key: 0,
                href: 'https://' + _ctx.userProfile.website,
                target: "_blank"
              }, _toDisplayString(_ctx.userProfile.website), 9, _hoisted_4))
            : (_openBlock(), _createElementBlock("a", {
                key: 1,
                href: _ctx.userProfile.website,
                target: "_blank"
              }, _toDisplayString(_ctx.userProfile.website), 9, _hoisted_5))
        ])
      ]),
      (_unref(valid)(_ctx.userProfile.typeProject))
        ? (_openBlock(), _createElementBlock("tr", _hoisted_6, [
            _cache[20] || (_cache[20] = _createElementVNode("td", null, "Types of Projects Executed", -1)),
            _createElementVNode("td", null, _toDisplayString(JSON.parse(_ctx.userProfile.typeProject || '[""]').join(", ")), 1)
          ]))
        : _createCommentVNode("", true),
      (_unref(valid)(_ctx.userProfile.typeTechnology))
        ? (_openBlock(), _createElementBlock("tr", _hoisted_7, [
            _cache[21] || (_cache[21] = _createElementVNode("td", null, "Types of Technologies Adopted by Practitioner's Organization", -1)),
            _createElementVNode("td", null, _toDisplayString(JSON.parse(_ctx.userProfile.typeTechnology || '[""]').join(", ")), 1)
          ]))
        : _createCommentVNode("", true),
      (_unref(valid)(_ctx.userProfile.organPolicy))
        ? (_openBlock(), _createElementBlock("tr", _hoisted_8, [
            _cache[22] || (_cache[22] = _createElementVNode("td", null, "Company's Effort to Support Student Development", -1)),
            _createElementVNode("td", null, _toDisplayString(_ctx.userProfile.organPolicy), 1)
          ]))
        : _createCommentVNode("", true)
    ])
  ], 64))
}
}

})