<template>
  <div class="content-card">
    <div>
      <span class="bold-text">Course-Support Type</span> :
      {{ course.courseRequestType }}
      <span v-if="course['course_request_x_practitioners'][0]">
        <span v-if="course['course_request_x_practitioners'][0].status == '60'">
          &nbsp;
          <span role="button" class="hint">
            <span @click="showTooltip(course.id)">
              <font-awesome-icon
                icon="fa-solid fa-check"
                style="color: #0bda51; font-size: x-large"
              /><span style="font-size: smaller">Accepted</span>
            </span>
            <span
              class="speech_bubble tool_tip_text"
              id="tooltip"
              v-show="elementVisible[course.id]"
              style="display: inline"
            >
              <button type="button" class="close-btn-tooltip mr-4">
                <font-awesome-icon
                  icon="fa-solid fa-xmark"
                  class="close-btn-icon"
                  @click="hideTooltip(course.id)"
                />
              </button>
              <p style="margin-bottom: 0px">
                You have accepted to provide this course-support request.
              </p>
            </span>
          </span>
        </span>
        <span
          v-else-if="course['course_request_x_practitioners'][0].status == '90'"
        >
          &nbsp;
          <span role="button" class="hint">
            <span @click="showTooltip(course.id)">
              <font-awesome-icon
                icon="fa-solid fa-xmark"
                style="color: #e71921; font-size: x-large"
              /><span style="font-size: smaller"> Declined</span>
            </span>
            <span
              class="speech_bubble tool_tip_text"
              id="tooltip"
              v-show="elementVisible[course.id]"
              style="display: inline"
            >
              <button type="button" class="close-btn-tooltip mr-4">
                <font-awesome-icon
                  icon="fa-solid fa-xmark"
                  class="close-btn-icon"
                  @click="hideTooltip(course.id)"
                />
              </button>
              <p style="margin-bottom: 0px">
                You have declined this course-support request.
              </p>
            </span>
          </span>
        </span>
        <span
          v-else-if="course['course_request_x_practitioners'][0].status == '40'"
        >
          &nbsp;
          <span role="button" class="hint">
            <span @click="showTooltip(course.id)">
              <font-awesome-icon
                icon="fa-solid fa-xmark"
                style="color: #e71921; font-size: x-large"
              /><span style="font-size: smaller"> Withdrawn</span>
            </span>
            <span
              class="speech_bubble tool_tip_text"
              id="tooltip"
              v-show="elementVisible[course.id]"
              style="display: inline"
            >
              <button type="button" class="close-btn-tooltip mr-4">
                <font-awesome-icon
                  icon="fa-solid fa-xmark"
                  class="close-btn-icon"
                  @click="hideTooltip(course.id)"
                />
              </button>
              <p style="margin-bottom: 0px">This request has been withdrawn.</p>
            </span>
          </span>
        </span>
        <span v-else class="hint">
          &nbsp;
          <span role="button" class="hint">
            <font-awesome-icon
              icon="fa-solid fa-circle-exclamation"
              @click="showTooltip(course.id)"
            />
            <span
              class="speech_bubble tool_tip_text"
              id="tooltip"
              v-show="elementVisible[course.id]"
              style="display: inline"
            >
              <button type="button" class="close-btn-tooltip mr-4">
                <font-awesome-icon
                  icon="fa-solid fa-xmark"
                  class="close-btn-icon"
                  @click="hideTooltip(course.id)"
                />
              </button>
              <p style="margin-bottom: 0px">
                You have not responded to this request.
              </p>
            </span>
          </span>
        </span>
      </span>
      <br />
      <span class="bold-text">Course Requested For</span> :
      <span
        v-if="
          course['course_request_x_practitioners'][0] &&
          (course['course_request_x_practitioners'][0].status === '60' ||
            course['course_request_x_practitioners'][0].status === '10')
        "
        class="highlight-selected-time"
      >
        {{
          new Date(
            course["course_request_x_practitioners"][0].selectedDate
          ).toLocaleString()
        }}
      </span>
      <span
        v-else
        v-for="(item, index) in JSON.parse(course.date)"
        :key="index"
      >
        {{
          new Date(item + "T" + JSON.parse(course.time)[index]).toLocaleString()
        }}
        <span v-if="index != JSON.parse(course.date).length - 1">
          OR
        </span></span
      >
      <br />
      <span class="bold-text">Professor</span>:
      {{
        course.user.title +
        " " +
        course.user.firstName +
        " " +
        course.user.lastName
      }}
      <br />
      <span class="bold-text">Institution</span>:
      {{ course.user.nameOrg }}
    </div>
    <div class="button-container lg:ml-3">
      <div class="p-2">
        <button class="btn btn-primary" @click="viewCourseRequest(course)">
          View Details
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { CourseRequest } from "@/types";
import { formatTime, sameDate } from "@/utils";
import { useAuthStore } from "@/stores/AuthStore";
import { useCourseStore } from "@/stores/CourseStore";
import { useRouter } from "vue-router";

const AuthStore = useAuthStore();
const CourseStore = useCourseStore();
const router = useRouter();

let loading = false;
let successful = false;
let message = ref("");
let editable = ref(false);
const content = ref([]);
let currCourse = ref({} as CourseRequest);
let currStatus = ref("");
const elementVisible = ref({});
let tooltipTimeout;

// eslint-disable-next-line no-undef
const props = defineProps<{
  course: CourseRequest;
}>();

const showTooltip = (index) => {
  elementVisible.value[index] = true;
  tooltipTimeout = setTimeout(
    () => (elementVisible.value[index] = false),
    5000
  );
};
const hideTooltip = (index) => {
  elementVisible.value[index] = false;
  clearTimeout(tooltipTimeout);
};

const viewCourseRequest = (course) => {
  CourseStore.addCourse(course);
  router.push({ name: "view-course-request-prac" });
};
</script>

<style scoped>
.button-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.content-card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #fff;
  padding: 18px;
  margin-right: 2rem;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;
  border-radius: 8px;
}

@media only screen and (max-width: 768px) {
  .content-card {
    margin-top: 1rem;
    padding: 0.5rem;
    margin-right: 0.5rem;
  }
}

@media only screen and (max-width: 600px) {
  .content-card {
    margin-top: 0.5rem;
    padding: 0.5rem;
    margin-right: 0.5rem;
    flex-direction: column;
  }
  .button-container {
    justify-content: space-evenly;
  }
}
</style>
