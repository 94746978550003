import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "container",
  class: "svg-container",
  align: "center"
}

import {
  select,
  scaleLinear,
  scaleBand,
  selectAll,
  min,
  max,
  axisBottom,
  axisTop,
  axisLeft,
} from "d3";
import { onMounted, ref, watchEffect } from "vue";

// eslint-disable-next-line no-undef

export default /*@__PURE__*/_defineComponent({
  __name: 'BarChart',
  props: {
    title: {},
    xKey: {},
    yKey: {},
    data: {}
  },
  setup(__props: any) {

const props = __props;

const svgRef = ref(null);

const dataMax = () => {
  return max(props.data, (d) => {
    return d[props.yKey];
  });
};

const dataMin = () => {
  return min(props.data, (d) => {
    return d[props.yKey];
  });
};

onMounted(() => {
  let svg = select(svgRef.value);
  const margin = { top: 10, right: 10, bottom: 140, left: 30 };
  const svgWidth = 350 - margin.left - margin.right;
  const svgHeight = (svgWidth - margin.top - margin.bottom) / 1.61803398875;
  svg = svg
    .append("g")
    .attr("transform", "translate(" + margin.left + "," + margin.top + ")");
  const x = scaleBand()
    .rangeRound([0, svgWidth])
    .paddingInner(0.1)
    .domain(props.data.map((d) => d[props.xKey]));

  svg
    .append("g")
    .attr("transform", "translate(0," + svgHeight + 50 + ")")
    .call(axisBottom(x))
    .selectAll("text")
    .attr("transform", "translate(-10,10)rotate(-90)")
    .style("text-anchor", "end");

  const y = scaleLinear()
    .range([svgHeight, 0])
    .domain([0, max(props.data, (d) => d[props.yKey])]);

  svg.append("g").call(axisLeft(y).ticks(5));

  console.log(props.data);

  let bar = svg.selectAll(".bar").data(props.data, (d) => d[props.xKey]);

  bar
    .enter()
    .append("rect")
    .attr("class", "bar")
    .merge(bar)
    .attr("y", (d) => {
      return y(d[props.yKey]);
    })
    .attr("x", (d) => x(d[props.xKey]))
    .transition()
    .delay((d, i) => {
      return i * 150;
    })
    .duration(1000)
    .attr("height", function (d) {
      return svgHeight - y(d[props.yKey]);
    })
    .attr("width", x.bandwidth())
    .attr("fill", "#69b3a2");

  bar.exit().remove();

  // let label = svg.selectAll(".text").data(props.data, (d) => d[props.xKey]);
  //
  // label
  //   .enter()
  //   .append("text")
  //   .attr("class", "text")
  //   .style("font-size", "16px")
  //   .merge(label)
  //   .attr("x", (d) => x(d[props.xKey]) + x.bandwidth() / 2)
  //   .attr("y", y(0) + 20)
  //   .transition()
  //   .duration(100)
  //   .text(function (d) {
  //     return d[props.xKey];
  //   });
  // selectAll("rect")
  //   .data(props.data)
  //   .attr("y", (d) => {
  //     return yScale(d[props.yKey]);
  //   })
  //   .attr("height", (d) => {
  //     return svgHeight - yScale(d[props.yKey]);
  //   });

  // const xAxis = axisBottom();
  // svg
  //   .select(".x-axis")
  //   .style("transform", `translateY(${svgHeight}px)`) // position on the bottom
  //   .call(xAxis);
  //
  // const yAxis = axisLeft(yScale);
  // svg.select(".y-axis").call(yAxis);
});

//   selectAll(".text")
//     .data(this.data, (d) => d.xKey)
//     .enter()
//     .append("text")
//     .attr("class", "text")
//     .style("font-size", "16px")
//     .attr("x", (d) => this.xScale(d.value))
//     .attr("y", (d) => this.yScale(d.key))
//     .transition()
//     .duration(100)
//     .text(function (d) {
//       console.log(d);
//       return d.name;
//     });
// };

// const addResizeListener = () => {
//       // redraw the chart 300ms after the window has been resized
//       window.addEventListener("resize", () => {
//         this.$data.redrawToggle = false;
//         setTimeout(() => {
//           this.$data.redrawToggle = true;
//           this.$data.svgWidth =
//             document.getElementById("container").offsetWidth * 0.75;
//           this.AnimateLoad();
//         }, 200);
//       });
//     };

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h4", null, _toDisplayString(_ctx.title), 1),
    (_openBlock(), _createElementBlock("svg", {
      width: "350",
      height: "250",
      ref_key: "svgRef",
      ref: svgRef
    }, null, 512))
  ]))
}
}

})