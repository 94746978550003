<template>
  <div class="grid-container">
    <app-sidebar />
    <div class="mt-3 min-height">
      <div class="back-grid">
        <back-button></back-button>
        <h2 style="text-align: center; font-weight: bold">Notifications</h2>
      </div>
      <div class="lg:mx-8" v-if="NotificationStore.notifications.length > 0">
        <div
          v-for="notification in NotificationStore.notifications"
          :key="notification.id"
        >
          <div
            class="notification-content"
            @click="viewCourseRequest(notification)"
            :class="{ 'unread-notification': notification.status === '01' }"
          >
            {{ getNotificationText(notification) }}
            <div style="color: darkgrey; font-size: small">
              {{
                new Date(notification.createdAt).toDateString() +
                " " +
                new Date(notification.createdAt).toLocaleTimeString()
              }}
            </div>
          </div>
        </div>
      </div>
      <div v-else>No Notifications.</div>
    </div>
  </div>
  <Dialog
    v-model:visible="visible"
    modal
    :header="currResourceRequest.type"
    :style="{ width: '70vw' }"
    :breakpoints="{ '768px': '90vw' }"
  >
    <i> {{ currResourceRequest.description }} </i>
    <div class="min-h">
      <div
        class="responsive"
        v-for="item in currResourceRequest.resource_responses"
        :key="item.id"
      >
        <resources-gallery-item :item="item" />
      </div>
    </div>
    <div class="clearfix"></div>
  </Dialog>

  <va-modal v-model="showDeleteCourseModal" hide-default-actions>
    <h3 class="va-h3"></h3>
    <div class="px-3">
      <div>
        The following course-support request was deleted by the instructor.
      </div>
      <div>
        <b>Type of course-support:</b>
        {{ currNotification.course_request.courseRequestType }}
        <br />
        <b>Date and Time:</b>
        {{
          new Date(
            currNotification.course_request.course_request_x_practitioners[0].selectedDate
          ).toLocaleString()
        }}
      </div>
      <div>Please take this schedule off your calendar.</div>
    </div>
    <div class="text-align-center mt-3">
      <va-button @click="showDeleteCourseModal = false"> Confirm </va-button>
    </div>
  </va-modal>
</template>

<script setup lang="ts">
import { ref } from "vue";
import AppSidebar from "@/components/user/AppSidebar.vue";
import BackButton from "@/components/user/BackButton.vue";
import ResourcesGalleryItem from "@/components/ResourcesGalleryItem.vue";
import Dialog from "primevue/dialog";
import { useModal } from "vuestic-ui";
import { Notification, ResourceRequest } from "@/types";
import { useRouter } from "vue-router";
import { useAuthStore } from "@/stores/AuthStore";
import { useNotificationStore } from "@/stores/NotificationsStore";
import { useCourseStore } from "@/stores/CourseStore";
import InstructorService from "@/services/instructor.service.js";

const { confirm } = useModal();
const AuthStore = useAuthStore();
const NotificationStore = useNotificationStore();
const CourseStore = useCourseStore();
const router = useRouter();

const currentUser = AuthStore.auth;
let currResourceRequest = ref({} as ResourceRequest);
let currNotification = ref({} as Notification);
let visible = ref(false);
let showDeleteCourseModal = ref(false);

const getNotificationText = (notification: Notification) => {
  let startText = "",
    endText = "";
  if (notification.action === "11") {
    startText = `Your request for ${notification.resource_request.type} (${notification.resource_request.description}) has received a response.`;
  } else {
    if (notification.user.phone === "True") {
      startText = `${notification.user.title} ${notification.user.firstName} ${notification.user.lastName} (${notification.user.email}/${notification.user.phoneNumber}) at ${notification.user.nameOrg}`;
    } else {
      startText = `${notification.user.title} ${notification.user.firstName} ${notification.user.lastName} (${notification.user.email}) at ${notification.user.nameOrg}`;
    }
    if (notification.action === "01") {
      endText = ` is requesting that you provide a ${notification.course_request.courseRequestType} session`;
    } else if (notification.action === "02") {
      endText = ` wants to provide course-support for ${notification.course_request.courseRequestType}`;
    } else if (notification.action === "03") {
      endText = ` has accepted your request to provide course-support for ${notification.course_request.courseRequestType}`;
    } else if (notification.action === "04") {
      endText = ` has declined your request provide course-support for ${notification.course_request.courseRequestType}`;
    } else if (notification.action === "09") {
      endText = ` has deleted the course-support request (${notification.course_request.courseRequestType}) which you intended to provide`;
    } else if (notification.action === "29") {
      endText = ` reported an upload.`;
    }
  }
  return startText + endText;
};

const viewCourseRequest = (notification) => {
  currNotification.value = notification;
  if (notification.status === "01") {
    NotificationStore.markAsRead(notification.id);
  }
  if (notification.action === "11") {
    InstructorService.getResourcesRequest(notification.linkedResourceId).then(
      (response) => {
        console.log(response.data);
        currResourceRequest.value = response.data;
        visible.value = true;
      },
      (error) => {
        let res =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        console.log(res);
      }
    );
  } else if (notification.action === "09") {
    showDeleteCourseModal.value = true;
  } else if (notification.action === "29") {
    router.push({
      name: "database-table",
      params: { name: "Uploads", sort: "true" },
    });
  } else {
    notification.course_request.user = notification.user;
    CourseStore.addCourse(notification.course_request);
    if (currentUser.roles === "INDUSTRYPRACTITIONER")
      router.push({ name: "view-course-request-prac" });
    else if (currentUser.roles === "INSTRUCTOR") {
      CourseStore.setPractitioner(notification.user.id);
      router.push({ name: "view-industry-practitioner-ins" });
    }
  }
};
</script>

<style scoped>
.notification-content {
  margin: 1rem;
  cursor: pointer;
  padding: 0.5rem;
  border-bottom: 2px solid rgb(240, 240, 240);
}

.notification-content:hover {
  background: #e5e5e5;
}

.unread-notification {
  background: rgb(83, 100, 113, 0.1);
  border-radius: 10px;
}

.responsive {
  padding: 0 6px;
  margin: 4px 0;
  float: left;
}

.clearfix:after {
  content: "";
  display: table;
  clear: both;
}
</style>
