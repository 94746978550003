<template>
  <div class="grid-container">
    <div v-if="currentUser.roles === 'INDUSTRYPRACTITIONER'">
      <practitioner-sidebar></practitioner-sidebar>
    </div>
    <div v-else-if="currentUser.roles === 'INSTRUCTOR'">
      <instructor-sidebar></instructor-sidebar>
    </div>
    <div class="mt-3 min-height">
      <div class="back-grid">
        <back-button></back-button>
        <h2 style="text-align: center; font-weight: bold">
          Your Account Settings
        </h2>
      </div>
      <div v-if="!settingsFields.find((el) => el.name === 'email')?.disabled">
        <h3 class="mt-5">Change email address</h3>
        <label for="username">Current Email</label>
        <div class="form-column">
          <div class="form-group">
            <Field
              name="email"
              disabled
              type="text"
              class="form-control px-4"
              :value="currentUser.email"
            />
          </div>
          <button
            class="btn btn-primary btn-block"
            style="width: 40%; min-width: max-content"
            @click="changeEmail"
          >
            Update email address
          </button>
        </div>
      </div>
      <div
        v-if="
          !settingsFields.find((el) => el.name === 'reasonDelete')?.disabled
        "
      >
        <h3 class="mt-5">Delete Account</h3>
        <button
          class="btn btn-danger btn-block mt-3"
          style="width: max-content"
          @click="deleteAccount"
        >
          Delete Account
        </button>
      </div>
    </div>
  </div>
  <Dialog
    v-model:visible="visible"
    modal
    :draggable="false"
    header="Change email"
    :style="{ width: '60vw' }"
    :breakpoints="{ '768px': '90vw' }"
  >
    <Form @submit="handleSubmit" :validation-schema="schema" v-slot="{ meta }">
      Your current email is <i>{{ currentUser.email }}</i
      >. What would you like to update it to?
      <div class="form-group">
        <Field name="email" type="email" class="form-control" />
      </div>
      <br />
      <div class="form-group" style="width: 100%; text-align: center">
        <button
          class="btn btn-primary btn-block"
          :disabled="loading || !meta.valid"
        >
          <span
            v-show="loading"
            class="spinner-border spinner-border-sm"
          ></span>
          <span>Change email address</span>
        </button>
      </div>
    </Form>
  </Dialog>
  <Dialog
    v-model:visible="deleteVisible"
    modal
    header="Delete Account"
    :style="{ width: '60vw' }"
    :breakpoints="{ '768px': '90vw' }"
  >
    Are you sure you want to delete your account? Deleting your account will
    remove all your information from this platform including your course-support
    requests. The action is irreversible. If you delete your account, to use
    this platform again you would have to create a new account.
    <br /><br /><br />
    Do you want to proceed to delete your account?&nbsp;&nbsp;

    <button class="btn btn-primary" @click="deleteVisible = false">No</button>
    &nbsp;
    <button class="btn btn-secondary" @click="deleteFormVisible = true">
      Yes
    </button>

    <Form
      v-if="deleteFormVisible"
      @submit="handleDeleteSubmit"
      :validation-schema="deleteSchema"
      v-slot="{ meta }"
    >
      <div class="form-group">
        <label for="reasonDelete"
          >Please provide a reason for deleting the account</label
        >
        <Field name="reasonDelete" type="text" class="form-control" />
        <ErrorMessage name="reasonDelete" class="error-feedback" />
      </div>
      <br />
      <div class="form-group text-align-center">
        <button
          class="btn btn-primary btn-block"
          :disabled="loading || !meta.valid"
        >
          <span
            v-show="loading"
            class="spinner-border spinner-border-sm"
          ></span>
          <span>Delete</span>
        </button>
      </div>
    </Form>
  </Dialog>
</template>

<script setup lang="ts">
import { ref } from "vue";
import AuthService from "@/services/auth.service.js";
import PractitionerSidebar from "@/components/PractitionerSidebar.vue";
import InstructorSidebar from "@/components/instructor/InstructorSidebar.vue";
import BackButton from "@/components/user/BackButton.vue";
import Dialog from "primevue/dialog";
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import { useAuthStore } from "@/stores/AuthStore";
import { useRouter } from "vue-router";
import { showErrorToast, showToast } from "@/utils";
import { useConfigStore } from "@/stores/ConfigStore";

const ConfigStore = useConfigStore();
const AuthStore = useAuthStore();
const router = useRouter();

const settingsFields = ConfigStore.config.filter(
  (el) => el.table === "settings"
);
const currentUser = AuthStore.auth;
let loading = ref(false);
let message = ref("");
let visible = ref(false);
let deleteVisible = ref(false);
let deleteFormVisible = ref(false);

const schema = yup.object().shape({
  fields: yup.array().default(settingsFields),
  email: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "email")?.disabled,
    then: (schema) =>
      schema
        .email("Please Enter a valid email address")
        .required("Email is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
});

const deleteSchema = yup.object().shape({
  fields: yup.array().default(settingsFields),
  reasonDelete: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "reasonDelete")?.disabled,
    then: (schema) => schema.required("Email is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
});

const changeEmail = () => {
  visible.value = true;
};

const deleteAccount = () => {
  deleteVisible.value = true;
};

const handleSubmit = (data) => {
  loading.value = true;
  data.roles = currentUser.roles;

  AuthService.changeEmail(data).then(
    (_) => {
      showToast(
        "Please confirm your new email by clicking on the link sent to you"
      );
      if (AuthStore.role === "INSTRUCTOR")
        router.push({ name: "instructor-feed" });
      else if (AuthStore.role === "INDUSTRYPRACTITIONER")
        router.push({ name: "industry-practitioner-feed" });
      else router.push("/home");
    },
    (error) => {
      loading.value = false;
      visible.value = false;
      message.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      showErrorToast(message.value);
    }
  );
};

const handleDeleteSubmit = (data) => {
  loading.value = true;
  AuthService.deleteAccount(data).then(
    (_) => {
      showToast("Your account has been successfully deleted");
      AuthStore.logout();
      router.push("/");
    },
    (error) => {
      loading.value = false;
      visible.value = false;
      message.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      showErrorToast(message.value);
    }
  );
};
</script>

<style scoped>
@media only screen and (max-width: 600px) {
  .form-column {
    display: grid;
    grid-template-columns: 1fr;
  }
  .form-column button {
    margin-top: 0.5rem;
  }
}
</style>
