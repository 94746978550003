import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, unref as _unref, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "grid-container" }
const _hoisted_2 = { class: "container min-height" }
const _hoisted_3 = { class: "button-container" }
const _hoisted_4 = { style: {"position":"relative"} }
const _hoisted_5 = { class: "counter" }
const _hoisted_6 = { class: "form-group" }
const _hoisted_7 = { class: "form-group text-align-center" }
const _hoisted_8 = ["disabled"]
const _hoisted_9 = { class: "spinner-border spinner-border-sm" }

import { ref } from "vue";
import * as yup from "yup";
import { Form, Field, ErrorMessage } from "vee-validate";
import AppSidebar from "@/components/user/AppSidebar.vue";
import PopupModal from "../../components/user/PopupModal.vue";
import { useToast } from "vuestic-ui";
import { useAuthStore } from "@/stores/AuthStore";
import AdminService from "@/services/admin.service.js";
import { DashboardAccessRequestFields, showToast } from "@/utils";

export default /*@__PURE__*/_defineComponent({
  __name: 'FeedView',
  setup(__props) {

const AuthStore = useAuthStore();

const currentUser = AuthStore.auth;
const { init, close, closeAll } = useToast();

const schema = yup.object().shape({
  email: yup
    .string()
    .required("Email is required!")
    .email("Email is invalid!")
    .max(50, "Must be maximum 50 characters!"),
});

let isVisible = ref(false);
let loading = ref(false);
let successful = ref(false);
let message = ref("");
let dashboardAccessCounter = ref(0);

const showPopup = () => {
  isVisible.value = true;
};

const hidePopup = () => {
  isVisible.value = false;
};

const handleSubmit = (user, { resetForm }) => {
  loading.value = true;
  AdminService.addAdmin(user).then(
    (res) => {
      console.log(res.data.message);
      message.value = res.data.message;
      showToast(
        "An Email has been sent to provided email address detailing further actions."
      );
      successful.value = true;
      loading.value = false;
      isVisible.value = false;
      resetForm();
    },
    (error) => {
      message.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      successful.value = false;
      loading.value = false;
    }
  );
};

const getDashboardAccessRequests = () => {
  loading.value = true;
  AdminService.getDashboardAccessRequests().then(
    (response) => {
      dashboardAccessCounter.value = response.data.filter(
        (el) => el.status === "01"
      ).length;
      loading.value = false;
    },
    (error) => {
      loading.value = false;
      message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};

getDashboardAccessRequests();

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(AppSidebar),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", null, [
          _cache[6] || (_cache[6] = _createElementVNode("h4", null, "General Control Panel", -1)),
          _createElementVNode("button", {
            class: "admin-button",
            onClick: showPopup
          }, "Add an Admin"),
          _cache[7] || (_cache[7] = _createElementVNode("br", null, null, -1)),
          _createVNode(_component_router_link, {
            class: "admin-button",
            to: { name: 'site-statistics' }
          }, {
            default: _withCtx(() => _cache[0] || (_cache[0] = [
              _createTextVNode("Site Statistics")
            ])),
            _: 1
          }),
          _cache[8] || (_cache[8] = _createElementVNode("br", null, null, -1)),
          _createVNode(_component_router_link, { to: { name: 'field-selection' } }, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [
              _createTextVNode("Field Selection")
            ])),
            _: 1
          }),
          _cache[9] || (_cache[9] = _createElementVNode("br", null, null, -1)),
          _createVNode(_component_router_link, { to: { name: 'database-table', params: { name: 'Users' } } }, {
            default: _withCtx(() => _cache[2] || (_cache[2] = [
              _createTextVNode("Users")
            ])),
            _: 1
          }),
          _cache[10] || (_cache[10] = _createElementVNode("br", null, null, -1)),
          _createVNode(_component_router_link, { to: { name: 'database-table', params: { name: 'Feedback' } } }, {
            default: _withCtx(() => _cache[3] || (_cache[3] = [
              _createTextVNode("Feedback")
            ])),
            _: 1
          }),
          _cache[11] || (_cache[11] = _createElementVNode("br", null, null, -1)),
          _createVNode(_component_router_link, { to: { name: 'database-table', params: { name: 'ContactUs' } } }, {
            default: _withCtx(() => _cache[4] || (_cache[4] = [
              _createTextVNode("Contact Us")
            ])),
            _: 1
          }),
          _cache[12] || (_cache[12] = _createElementVNode("br", null, null, -1)),
          _createVNode(_component_router_link, { to: { name: 'database-table', params: { name: 'FAQ' } } }, {
            default: _withCtx(() => _cache[5] || (_cache[5] = [
              _createTextVNode("FAQ")
            ])),
            _: 1
          }),
          _cache[13] || (_cache[13] = _createElementVNode("br", null, null, -1))
        ]),
        _createElementVNode("div", null, [
          _cache[24] || (_cache[24] = _createElementVNode("h4", null, "Instructor Control Panel", -1)),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_router_link, { to: { name: 'database-table', params: { name: 'Instructors' } } }, {
              default: _withCtx(() => _cache[14] || (_cache[14] = [
                _createTextVNode(" Instructors")
              ])),
              _: 1
            }),
            _cache[19] || (_cache[19] = _createElementVNode("br", null, null, -1)),
            _createVNode(_component_router_link, { to: { name: 'database-table', params: { name: 'Classes' } } }, {
              default: _withCtx(() => _cache[15] || (_cache[15] = [
                _createTextVNode(" Classes")
              ])),
              _: 1
            }),
            _cache[20] || (_cache[20] = _createElementVNode("br", null, null, -1)),
            _createVNode(_component_router_link, { to: { name: 'database-table', params: { name: 'CourseRequests' } } }, {
              default: _withCtx(() => _cache[16] || (_cache[16] = [
                _createTextVNode(" Course Requests")
              ])),
              _: 1
            }),
            _cache[21] || (_cache[21] = _createElementVNode("br", null, null, -1)),
            _createVNode(_component_router_link, { to: {
              name: 'database-table',
              params: { name: 'ResourceRequests' },
            } }, {
              default: _withCtx(() => _cache[17] || (_cache[17] = [
                _createTextVNode(" Resource Requests")
              ])),
              _: 1
            }),
            _cache[22] || (_cache[22] = _createElementVNode("br", null, null, -1)),
            _createVNode(_component_router_link, { to: {
              name: 'categorize-topics',
              params: { name: 'CourseRequestTopic' },
            } }, {
              default: _withCtx(() => _cache[18] || (_cache[18] = [
                _createTextVNode("Subject Areas")
              ])),
              _: 1
            }),
            _cache[23] || (_cache[23] = _createElementVNode("br", null, null, -1))
          ])
        ]),
        _createElementVNode("div", null, [
          _cache[30] || (_cache[30] = _createElementVNode("h4", null, "Practitioner Control Panel", -1)),
          _createVNode(_component_router_link, { to: { name: 'database-table', params: { name: 'Practitioners' } } }, {
            default: _withCtx(() => _cache[25] || (_cache[25] = [
              _createTextVNode(" Industry Practitioners")
            ])),
            _: 1
          }),
          _cache[31] || (_cache[31] = _createElementVNode("br", null, null, -1)),
          _createVNode(_component_router_link, { to: { name: 'database-table', params: { name: 'Availability' } } }, {
            default: _withCtx(() => _cache[26] || (_cache[26] = [
              _createTextVNode(" Availability")
            ])),
            _: 1
          }),
          _cache[32] || (_cache[32] = _createElementVNode("br", null, null, -1)),
          _createVNode(_component_router_link, { to: { name: 'database-table', params: { name: 'Uploads' } } }, {
            default: _withCtx(() => _cache[27] || (_cache[27] = [
              _createTextVNode(" Uploads")
            ])),
            _: 1
          }),
          _cache[33] || (_cache[33] = _createElementVNode("br", null, null, -1)),
          _createVNode(_component_router_link, { to: { name: 'database-table', params: { name: 'Organization' } } }, {
            default: _withCtx(() => _cache[28] || (_cache[28] = [
              _createTextVNode("Organization")
            ])),
            _: 1
          }),
          _cache[34] || (_cache[34] = _createElementVNode("br", null, null, -1)),
          _createVNode(_component_router_link, { to: {
            name: 'categorize-topics',
            params: { name: 'SubjectWillingToSpeakOn' },
          } }, {
            default: _withCtx(() => _cache[29] || (_cache[29] = [
              _createTextVNode("Topics of Interest")
            ])),
            _: 1
          }),
          _cache[35] || (_cache[35] = _createElementVNode("br", null, null, -1))
        ]),
        _createElementVNode("div", null, [
          _cache[39] || (_cache[39] = _createElementVNode("h4", null, "Dashboard Control Panel", -1)),
          _createVNode(_component_router_link, { to: { name: 'dashboard' } }, {
            default: _withCtx(() => _cache[36] || (_cache[36] = [
              _createTextVNode(" Dashboard ")
            ])),
            _: 1
          }),
          _cache[40] || (_cache[40] = _createElementVNode("br", null, null, -1)),
          _createVNode(_component_router_link, { to: {
            name: 'database-table',
            params: { name: 'DashboardAccessRequest' },
          } }, {
            default: _withCtx(() => _cache[37] || (_cache[37] = [
              _createTextVNode(" Access Control ")
            ])),
            _: 1
          }),
          _createElementVNode("span", _hoisted_4, [
            _createElementVNode("span", _hoisted_5, _toDisplayString(_unref(dashboardAccessCounter)), 1)
          ]),
          _cache[41] || (_cache[41] = _createElementVNode("br", null, null, -1)),
          _createVNode(_component_router_link, { to: {
            name: 'database-table',
            params: { name: 'DashboardFeedback' },
          } }, {
            default: _withCtx(() => _cache[38] || (_cache[38] = [
              _createTextVNode(" Feedback ")
            ])),
            _: 1
          }),
          _cache[42] || (_cache[42] = _createElementVNode("br", null, null, -1))
        ])
      ])
    ]),
    _createVNode(PopupModal, {
      visible: _unref(isVisible),
      onClose: hidePopup
    }, {
      title: _withCtx(() => _cache[43] || (_cache[43] = [
        _createTextVNode(" Add an Admin")
      ])),
      content: _withCtx(() => [
        _createVNode(_unref(Form), {
          onSubmit: handleSubmit,
          "validation-schema": _unref(schema)
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_6, [
              _cache[44] || (_cache[44] = _createElementVNode("label", { for: "email" }, "Email", -1)),
              _createVNode(_unref(Field), {
                name: "email",
                type: "email",
                class: "form-control"
              }),
              _createVNode(_unref(ErrorMessage), {
                name: "email",
                class: "error-feedback"
              })
            ]),
            _cache[46] || (_cache[46] = _createElementVNode("br", null, null, -1)),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("button", {
                class: "btn btn-primary btn-block",
                disabled: _unref(loading)
              }, [
                _withDirectives(_createElementVNode("span", _hoisted_9, null, 512), [
                  [_vShow, _unref(loading)]
                ]),
                _cache[45] || (_cache[45] = _createTextVNode(" Add Admin "))
              ], 8, _hoisted_8)
            ])
          ]),
          _: 1
        }, 8, ["validation-schema"])
      ]),
      _: 1
    }, 8, ["visible"])
  ], 64))
}
}

})