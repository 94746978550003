import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = {
  key: 1,
  id: "profile-img",
  src: "//ssl.gstatic.com/accounts/ui/avatar_2x.png",
  class: "profile-icon-card"
}

import { websiteURL } from "@/utils";

import { useAuthStore } from "@/stores/AuthStore";

export default /*@__PURE__*/_defineComponent({
  __name: 'ProfileIcon',
  setup(__props) {

const AuthStore = useAuthStore();

let currentUser = AuthStore.auth;

return (_ctx: any,_cache: any) => {
  return (_unref(currentUser).fileName)
    ? (_openBlock(), _createElementBlock("img", {
        key: 0,
        id: "profile-img",
        src: _unref(websiteURL) + 'profile_picture/' + _unref(currentUser).fileName,
        class: "profile-icon-card"
      }, null, 8, _hoisted_1))
    : (_openBlock(), _createElementBlock("img", _hoisted_2))
}
}

})