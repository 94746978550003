import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, withCtx as _withCtx, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, isRef as _isRef, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = { class: "grid-container" }
const _hoisted_2 = { class: "m-3 min-height" }
const _hoisted_3 = { class: "back-grid" }
const _hoisted_4 = { class: "col-md-12" }
const _hoisted_5 = { class: "card card-container" }
const _hoisted_6 = {
  key: 0,
  class: "form-group"
}
const _hoisted_7 = { class: "size-counter" }
const _hoisted_8 = { style: {"width":"100%"} }
const _hoisted_9 = {
  class: "form-group",
  style: {"text-align":"center"}
}
const _hoisted_10 = ["disabled"]
const _hoisted_11 = { class: "spinner-border spinner-border-sm" }
const _hoisted_12 = {
  key: 0,
  class: "mt-8"
}
const _hoisted_13 = { class: "course-item mt-5" }
const _hoisted_14 = { class: "flex flex-direction-column lg:flex-row xl:flex-row justify-content-between align-content-center flex-wrap" }
const _hoisted_15 = {
  key: 0,
  title: "Unhappy"
}
const _hoisted_16 = {
  key: 1,
  title: "Neutral"
}
const _hoisted_17 = {
  key: 2,
  title: "Happy"
}
const _hoisted_18 = {
  class: "flex flex-grow-0 align-content-center lg:flex-wrap mr-3 leave-comment",
  style: {"cursor":"default"}
}
const _hoisted_19 = ["onClick"]
const _hoisted_20 = { class: "flex flex-direction-row" }
const _hoisted_21 = { class: "flex-shrink-1 flex-grow-0" }
const _hoisted_22 = { class: "ml-3 mt-2 bold-text dark-text-color" }
const _hoisted_23 = {
  class: "mt-2",
  style: {"font-size":"larger"},
  autofocus: ""
}
const _hoisted_24 = { class: "flex flex-direction-row ml-2 overflow-scroll" }
const _hoisted_25 = { class: "flex-shrink-1 flex-grow-0" }
const _hoisted_26 = ["src"]
const _hoisted_27 = {
  key: 1,
  id: "profile-img",
  src: "//ssl.gstatic.com/accounts/ui/avatar_2x.png",
  class: "profile-icon-card"
}
const _hoisted_28 = { class: "mb-2" }
const _hoisted_29 = { class: "ml-3 pl-3 mt-1 card comment-card" }
const _hoisted_30 = { class: "bold-text dark-text-color" }
const _hoisted_31 = { class: "ml-3 pl-1 small" }
const _hoisted_32 = { class: "flex flex-direction-row" }
const _hoisted_33 = { class: "flex-shrink-1 flex-grow-0" }
const _hoisted_34 = {
  key: 1,
  class: "ml-3 mt-2"
}
const _hoisted_35 = { class: "flex flex-direction-row" }

import { ref } from "vue";
import { useRouter } from "vue-router";
import { Field, ErrorMessage, Form } from "vee-validate";
import * as yup from "yup";
import Dialog from "primevue/dialog";
import AppSidebar from "@/components/user/AppSidebar.vue";
import BackButton from "@/components/user/BackButton.vue";
import ProfileIcon from "@/components/user/ProfileIcon.vue";
import UserService from "../services/user.service.js";
import { useConfigStore } from "@/stores/ConfigStore";
import { useAuthStore } from "@/stores/AuthStore";
import { websiteURL, getTimeDiff, showToast } from "@/utils";
import { VaDivider } from "vuestic-ui";
import { Feedback, FeedbackComment } from "@/types";


export default /*@__PURE__*/_defineComponent({
  __name: 'DashboardFeedbackView',
  setup(__props) {

const router = useRouter();
const ConfigStore = useConfigStore();
const AuthStore = useAuthStore();

const feedbackFields = ConfigStore.config.filter(
  (el) => el.table === "feedback"
);
const currentUser = AuthStore.auth;

let loading = ref(false);
let successful = ref(false);
let msg = ref("");
let dashboardFeedback = ref([] as Feedback[]);
let currFeedback = ref({} as Feedback);
let feedbackComments = ref([] as FeedbackComment[]);
let isVisible = ref(false);
let editable = ref(false);

const schema = yup.object().shape({
  fields: yup.array().default(feedbackFields),
  message: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "message")?.disabled,
    then: (schema) =>
      schema
        .required("Message is required!")
        .min(10, "Must be at least 10 characters!")
        .max(5000, "Cannot be greater than 5000 characters!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
});

const commentSchema = yup.object().shape({
  message: yup
    .string()
    .required("Message is required!")
    .max(5000, "Cannot be greater tha 5000 characters!"),
});

const handleSubmission = (data, { resetForm }) => {
  loading.value = true;
  UserService.saveDashboardFeedback(data).then(
    (data) => {
      msg.value = data.message;
      resetForm();
      getDashboardFeedback(currentUser.id);
      successful.value = true;
      loading.value = false;
      showToast("Your Feedback has been successfully submitted.");
    },
    (error) => {
      msg.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      successful.value = false;
      loading.value = false;
    }
  );
};

const getDashboardFeedback = (userId) => {
  UserService.getDashboardFeedback(userId).then(
    (response) => {
      console.log(response);
      dashboardFeedback.value = response.data;
    },
    (error) => {
      msg.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};

const getFeedbackComments = (feedbackId) => {
  UserService.getFeedbackComments(feedbackId).then(
    (response) => {
      console.log(response);
      feedbackComments.value = response.data;
      isVisible.value = true;
    },
    (error) => {
      msg.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};

const postComment = (data) => {
  loading.value = true;
  data["linkedResourceType"] = "dashboardFeedback";
  UserService.postFeedbackComment(data, currFeedback.value.id).then(
    (data) => {
      msg.value = data.message;
      successful.value = true;
      showToast("Comment posted successfully.");
      isVisible.value = false;
      loading.value = false;
    },
    (error) => {
      msg.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      successful.value = false;
      loading.value = false;
    }
  );
};

getDashboardFeedback(currentUser.id);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(AppSidebar),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(BackButton),
          _cache[2] || (_cache[2] = _createElementVNode("h2", { style: {"text-align":"center","font-weight":"bold"} }, " Dashboard Feedback ", -1))
        ]),
        _cache[10] || (_cache[10] = _createElementVNode("br", null, null, -1)),
        _cache[11] || (_cache[11] = _createElementVNode("h5", { class: "text-align-center pl-1 pr-1 lg:pl-8 lg:pr-8" }, " Your feedback is important to us for improving the platform. Please provide feedback based on your experience. We are happy to hear from you. ", -1)),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _cache[6] || (_cache[6] = _createElementVNode("span", { class: "font-color-red" }, "* required field", -1)),
            _createVNode(_unref(Form), {
              onSubmit: handleSubmission,
              "validation-schema": _unref(schema)
            }, {
              default: _withCtx(({ values, meta }) => [
                _createElementVNode("div", null, [
                  (
                  !_unref(feedbackFields).find((el) => el.name === 'message')?.disabled
                )
                    ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                        _cache[3] || (_cache[3] = _createElementVNode("label", { for: "message" }, [
                          _createTextVNode("Message"),
                          _createElementVNode("span", { class: "super font-color-red" }, "*")
                        ], -1)),
                        _createVNode(_unref(Field), {
                          name: "message",
                          type: "text",
                          as: "textarea",
                          class: "form-control",
                          maxlength: "5000",
                          style: {"height":"100px"}
                        }),
                        _createElementVNode("div", _hoisted_7, [
                          _createElementVNode("span", null, _toDisplayString(values.message ? values.message.length : 0) + " / 5000 ", 1)
                        ])
                      ]))
                    : _createCommentVNode("", true),
                  _createVNode(_unref(ErrorMessage), {
                    name: "message",
                    class: "error-feedback"
                  })
                ]),
                _cache[5] || (_cache[5] = _createElementVNode("br", null, null, -1)),
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("button", {
                      class: "btn btn-primary",
                      style: {"text-align":"center"},
                      disabled: _unref(loading) || !meta.valid
                    }, [
                      _withDirectives(_createElementVNode("span", _hoisted_11, null, 512), [
                        [_vShow, _unref(loading)]
                      ]),
                      _cache[4] || (_cache[4] = _createTextVNode(" Submit "))
                    ], 8, _hoisted_10)
                  ])
                ])
              ]),
              _: 1
            }, 8, ["validation-schema"]),
            (_unref(msg))
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: _normalizeClass(["alert", _unref(successful) ? 'alert-success' : 'alert-danger'])
                }, _toDisplayString(_unref(msg)), 3))
              : _createCommentVNode("", true)
          ])
        ]),
        (_unref(dashboardFeedback).length > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
              _cache[9] || (_cache[9] = _createElementVNode("h3", { class: "text-align-center" }, "Your Feedback", -1)),
              _createElementVNode("div", _hoisted_13, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(dashboardFeedback), (feedback) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "lg:m-1 card",
                    key: feedback.id
                  }, [
                    _createElementVNode("div", _hoisted_14, [
                      _createElementVNode("div", null, [
                        _createElementVNode("b", null, _toDisplayString(feedback.message), 1),
                        _cache[7] || (_cache[7] = _createTextVNode("   ")),
                        (feedback.rating === '0')
                          ? (_openBlock(), _createElementBlock("span", _hoisted_15, "😞"))
                          : (feedback.rating === '1')
                            ? (_openBlock(), _createElementBlock("span", _hoisted_16, "😐"))
                            : (feedback.rating === '2')
                              ? (_openBlock(), _createElementBlock("span", _hoisted_17, "😃"))
                              : _createCommentVNode("", true),
                        _cache[8] || (_cache[8] = _createElementVNode("br", null, null, -1)),
                        _createTextVNode(" " + _toDisplayString(new Date(feedback.createdAt).toDateString()), 1)
                      ]),
                      _createElementVNode("div", _hoisted_18, [
                        _createElementVNode("button", {
                          class: "btn btn-primary",
                          onClick: ($event: any) => (
                    (_isRef(currFeedback) //@ts-ignore
 ? currFeedback.value = feedback : currFeedback = feedback), getFeedbackComments(feedback.id)
                  )
                        }, " Leave a comment ", 8, _hoisted_19)
                      ])
                    ])
                  ]))
                }), 128))
              ])
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createVNode(_unref(Dialog), {
      visible: _unref(isVisible),
      "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => (_isRef(isVisible) ? (isVisible).value = $event : isVisible = $event)),
      modal: "",
      header: "User Feedback",
      style: { width: '60vw' },
      breakpoints: { '768px': '90vw' }
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_20, [
          _createElementVNode("div", _hoisted_21, [
            _createVNode(ProfileIcon)
          ]),
          _createElementVNode("span", _hoisted_22, _toDisplayString(_unref(currentUser).firstName) + " " + _toDisplayString(_unref(currentUser).lastName), 1)
        ]),
        _createElementVNode("div", _hoisted_23, _toDisplayString(_unref(currFeedback).message), 1),
        _createVNode(_unref(VaDivider), {
          vertical: "",
          color: "red"
        }),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(feedbackComments), (feedbackComment) => {
          return (_openBlock(), _createElementBlock("div", {
            key: feedbackComment.id
          }, [
            _createElementVNode("div", _hoisted_24, [
              _createElementVNode("div", _hoisted_25, [
                (feedbackComment.user.fileName)
                  ? (_openBlock(), _createElementBlock("img", {
                      key: 0,
                      id: "profile-img",
                      src: 
              _unref(websiteURL) + 'profile_picture/' + feedbackComment.user.fileName
            ,
                      class: "profile-icon-card"
                    }, null, 8, _hoisted_26))
                  : (_openBlock(), _createElementBlock("img", _hoisted_27))
              ]),
              _createElementVNode("div", _hoisted_28, [
                _createElementVNode("div", _hoisted_29, [
                  _createElementVNode("div", _hoisted_30, _toDisplayString(feedbackComment.user.firstName) + " " + _toDisplayString(feedbackComment.user.lastName), 1),
                  _createTextVNode(" " + _toDisplayString(feedbackComment.message), 1)
                ]),
                _createElementVNode("span", _hoisted_31, _toDisplayString(_unref(getTimeDiff)(feedbackComment.createdAt)), 1)
              ])
            ])
          ]))
        }), 128)),
        _createVNode(_unref(VaDivider)),
        _createElementVNode("div", _hoisted_32, [
          _createElementVNode("div", _hoisted_33, [
            _createVNode(ProfileIcon)
          ]),
          (!_unref(editable))
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "ml-3 mt-2",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_isRef(editable) //@ts-ignore
 ? editable.value = true : editable = true))
              }, " Add Comment "))
            : (_openBlock(), _createElementBlock("div", _hoisted_34, [
                _createVNode(_unref(Form), {
                  onSubmit: postComment,
                  "validation-schema": _unref(commentSchema)
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_35, [
                      _createVNode(_unref(Field), { name: "message" }, {
                        default: _withCtx(({ field }) => [
                          _createElementVNode("textarea", _mergeProps({ type: "text" }, field, {
                            class: "form-control border-2",
                            placeholder: "Add a comment..."
                          }), null, 16),
                          _createVNode(_unref(ErrorMessage), {
                            name: "message",
                            class: "error-feedback"
                          })
                        ]),
                        _: 1
                      }),
                      _cache[12] || (_cache[12] = _createElementVNode("button", { class: "btn btn-primary mx-4 my-3" }, "Reply", -1))
                    ])
                  ]),
                  _: 1
                }, 8, ["validation-schema"])
              ]))
        ])
      ]),
      _: 1
    }, 8, ["visible"])
  ], 64))
}
}

})