<template>
  <div class="col-12 lg:col-6 xl:col-4 p-2">
    <div class="border-1 surface-border surface-card border-round p-2">
      <div class="cursor-pointer">
        <div
          class="flex flex-direction-column lg:flex-row align-center justify-space-between"
        >
          <img
            id="profile-img"
            src="@/assets/images/courseType/Judge-for-Project.jpg"
            class="recommendation-img-card"
            v-if="course.courseRequestType === 'Judge for Project'"
          />
          <img
            id="profile-img"
            src="@/assets/images/courseType/Mentor-for-Project.jpg"
            class="recommendation-img-card"
            v-else-if="course.courseRequestType === 'Mentor for Project'"
          />
          <img
            id="profile-img"
            src="@/assets/images/courseType/Guest-Lecture.jpg"
            class="recommendation-img-card"
            v-else-if="course.courseRequestType === 'Guest Lecture'"
          />
          <img
            id="profile-img"
            src="@/assets/images/courseType/Seminar.jpg"
            class="recommendation-img-card"
            v-else-if="course.courseRequestType === 'Seminar'"
          />
          <img
            id="profile-img"
            src="@/assets/images/courseType/Labs.jpg"
            class="recommendation-img-card"
            v-else-if="course.courseRequestType === 'Labs'"
          />
          <img
            id="profile-img"
            src="@/assets/images/courseType/Workshop.jpg"
            class="recommendation-img-card"
            v-else-if="course.courseRequestType === 'Workshop'"
          />
          <img
            id="profile-img"
            src="@/assets/images/courseType/Sponsor-for-Project.png"
            class="recommendation-img-card"
            v-else-if="course.courseRequestType === 'Sponsor for Project'"
          />
          <img
            id="profile-img"
            src="@/assets/images/courseType/Site-Visit.jpg"
            class="recommendation-img-card"
            v-else-if="course.courseRequestType === 'Site Visit'"
          />
          <div class="mx-2">
            <div
              v-if="
                course['course_request_x_practitioners'][0] &&
                (course['course_request_x_practitioners'][0].status === '60' ||
                  course['course_request_x_practitioners'][0].status === '10')
              "
              class="highlight-selected-time"
            >
              {{
                new Date(
                  course["course_request_x_practitioners"][0].selectedDate
                ).toLocaleString()
              }}
            </div>
            <div
              v-else
              v-for="(item, index) in JSON.parse(course.date)"
              :key="index"
            >
              {{
                new Date(
                  item + "T" + JSON.parse(course.time)[index]
                ).toLocaleString()
              }}
              <span v-if="index != JSON.parse(course.date).length - 1">
                OR
              </span>
            </div>
          </div>
        </div>

        <div class="text-align-center">
          {{
            course.user.title +
            " " +
            course.user.firstName +
            " " +
            course.user.lastName
          }}
          <br />
          <b>{{ course.user.nameOrg }}</b>
        </div>
      </div>
      <div
        class="flex flex-direction-column justify-content-between text-align-center mt-2"
      >
        <div class="button-container">
          <div class="p-2">
            <button class="view-more" @click="viewCourseRequest(course)">
              View Details
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { CourseRequest } from "@/types";
import { sameDate } from "@/utils";
import { useAuthStore } from "@/stores/AuthStore";
import { useCourseStore } from "@/stores/CourseStore";
import { useRouter } from "vue-router";

const AuthStore = useAuthStore();
const CourseStore = useCourseStore();
const router = useRouter();

let loading = false;
let successful = false;
let message = ref("");
const content = ref([]);
const visible = ref(false);

// eslint-disable-next-line no-undef
const props = defineProps<{
  course: CourseRequest;
}>();

const viewCourseRequest = (course) => {
  CourseStore.addCourse(course);
  router.push({ name: "view-course-request-prac" });
};
</script>

<style scoped>
.button-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.content-card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #fff;
  padding: 18px;
  margin-right: 2rem;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;
  border-radius: 8px;
}

.recommendation-img-card {
  width: 6rem;
  height: 6rem;
  margin: 0 auto 10px;
  display: block;
}

.view-more {
  cursor: pointer;
  margin-right: 1rem;
  color: #1a73e8;
  font-weight: bold;
  background: transparent;
  border: none;
}

@media only screen and (max-width: 768px) {
  .content-card {
    margin-top: 1rem;
    padding: 0.5rem;
    margin-right: 0.5rem;
  }
}

@media only screen and (max-width: 600px) {
  .content-card {
    flex-direction: column;
  }
}
</style>
