<template>
  <div class="m-3 lg:m-5 lg:ml-0 min-height header-space">
    <div class="text-align-center">
      <img
        src="@/assets/images/site/user_agreement.jpg"
        class="site-image mx-auto"
        autofocus
      />
    </div>
    <div class="mt-5 flex flex-row">
      <div class="logo-container flex-grow-1 flex-shrink-0">
        <img id="profile-img" class="m-auto" src="@/assets/images/logo.png" />
        <p style="font-size: 1.5rem" class="text-align-center">
          ...connecting professional and educational communities for future
          workforce development.
        </p>
      </div>
      <div>
        <h1>User Agreement</h1>
        <div>
          This page contains the User Agreement for using ConPEC.
          <ol>
            <li>
              <div class="heading">1. Introduction</div>
              <ol>
                <li>
                  <div class="sub-heading">1.1 Agreement</div>
                  <div class="ml-6">
                    When you use ConPEC you agree to all of these terms. Your
                    use of our Services is also subject to our Privacy Policy,
                    which covers how we collect, use, share, and store your
                    personal information. You agree that by clicking “Sign Up”
                    or similar, registering, accessing, or using our ConPEC, you
                    are agreeing to this User Agreement (even if you are using
                    ConPEC on behalf of an organization). If you do not agree to
                    this, do not click “Sign Up” (or similar) and do not access
                    ConPEC. If you wish to terminate this agreement, at any time
                    you can do so by closing your account and no longer
                    accessing or using the Platform.
                    <div class="sub-sub-heading">Services</div>
                    This Agreement applies to ConPEC also referred to in this
                    agreement as “the Platform”. Registered users are “Members”
                    and unregistered users are “Visitors”.
                    <div class="sub-sub-heading">ConPEC</div>
                    You are entering into this Agreement with ConPEC (also
                    referred to as “we” and “us”). This Agreement applies to
                    Members and Visitors.
                  </div>
                </li>
                <li>
                  <div class="sub-heading">1.2 Members and Visitors</div>
                  <div class="ml-6">
                    When you register and join ConPEC, you become a Member. If
                    you have chosen not to register you may access certain
                    features as a “Visitor.”
                  </div>
                </li>
                <li>
                  <div class="sub-heading">1.3 Change</div>
                  <div class="ml-6">
                    We may make changes to the Agreement. We may modify this
                    Agreement and our Privacy Policy from time to time. If we
                    make material changes to it, we will provide you notice
                    through the Platform, or by other means, to provide you the
                    opportunity to review the changes before they become
                    effective. We agree that changes cannot be retroactive. If
                    you object to any changes, you may close your account. Your
                    continued use of our Services after we publish or send a
                    notice about our changes to these terms means that you are
                    consenting to the updated terms as of their effective date.
                  </div>
                </li>
              </ol>
            </li>

            <li>
              <div class="heading">2. Obligations</div>
              <ol>
                <li>
                  <div class="sub-heading">2.1 Service Eligibility</div>
                  <div class="ml-6">
                    Here are some promises that you make to us in this
                    Agreement: You’re eligible to enter into this Agreement and
                    you are at least our “Minimum Age.” The Platform is not for
                    use by anyone under the age of 16. To use the Platform, you
                    agree that: (1) you must be the "Minimum Age"(16 years old)
                    or older; (2) you will only have one account, which must be
                    in your real name; and (3) you are not already restricted
                    from using the Platform. Creating an account with false
                    information is a violation of our terms, including accounts
                    registered on behalf of others or persons under the age of
                    16. “Minimum Age” means 16 years old. However, if the law
                    requires that you must be older for ConPEC to lawfully
                    provide the Services to you without parental consent
                    (including use of your data) then the Minimum Age is such
                    older age.
                  </div>
                </li>
                <li>
                  <div class="sub-heading">2.2 Your Account</div>
                  <div class="ml-6">
                    <div class="sub-sub-heading">You agree that:</div>
                    You will keep your password a secret.<br />
                    You will not share an account with anyone else and will
                    follow our rules and the law. Members are account holders.
                    You agree to (1) use a strong password and keep it
                    confidential; (2) not transfer any part of your account and
                    (3) follow the law and our list of Dos and Don’ts and
                    Professional Community Policies. You are responsible for
                    anything that happens through your account unless you close
                    it or report misuse.
                  </div>
                </li>
                <li>
                  <div class="sub-heading">2.3 Notices and Messages</div>
                  <div class="ml-6">
                    You’re okay with us providing notices and messages to you
                    through the Platform and your contact information. If your
                    contact information is out of date, you may miss out on
                    important notices.
                    <br />
                    You agree that we will provide notices and messages to you
                    in the following ways: (1) within the Platform, or (2) sent
                    to the contact information you provided us (e.g., email,
                    mobile number). You agree to keep your contact information
                    up to date.
                  </div>
                </li>
                <li>
                  <div class="sub-heading">2.4 Sharing</div>
                  <div class="ml-6">
                    When you share information on ConPEC, others can see, copy,
                    and use that information. <br />
                    ConPEC allows messaging and sharing of information in many
                    ways, such as your profile. Information and content that you
                    share or post may be seen by other Members. Where we have
                    made settings available, we will honor the choices you make
                    about who can see content or information. We are not
                    obligated to publish any information or content on the
                    Platform and can remove it with or without notice.
                  </div>
                </li>
              </ol>
            </li>
            <li>
              <div class="heading">3. Rights and Limits</div>
              <ol>
                <li>
                  <div class="sub-heading">3.1. Your License to ConPEC</div>
                  <div class="ml-6">
                    You own all of the content, feedback, and personal
                    information you provide to us, but you also grant us a
                    non-exclusive license to it. We’ll honor the choices you
                    make about who gets to see your information and content,
                    including how it can be used. As between you and ConPEC, you
                    own the content and information that you submit or post to
                    the Platform, and you are only granting ConPEC the following
                    non-exclusive license:<br />
                    A transferable and sublicensable right to use, copy, modify,
                    distribute, publish, and process, information and content
                    that you provide through the Platform, without any further
                    consent, notice, and/or compensation to you or others. These
                    rights are limited in the following ways:
                    <ul class="ml-5 list-style-type-square">
                      <li>
                        You can end this license for specific content by
                        deleting such content from the Platform, or generally by
                        closing your account, except (a) to the extent you
                        shared it with others as part of the Platform and they
                        copied, re-shared it, or stored it and (b) for the
                        reasonable time it takes to remove from backup and other
                        systems.
                      </li>
                      <li>
                        While we may edit and make format changes to your
                        content (such as translating or transcribing it,
                        modifying the size, layout, or file type, or removing
                        metadata), we will not modify the meaning of your
                        expression.
                      </li>
                      <li>
                        Because you own your content and information and we only
                        have non-exclusive rights to it, you may choose to make
                        it available to others.
                      </li>
                    </ul>
                    You and ConPEC agree that if content includes personal data,
                    it is subject to our Privacy Policy. <br />You and ConPEC
                    agree that we may access, store, process, and use any
                    information and personal data that you provide under the
                    terms of the Privacy Policy. By submitting suggestions or
                    other feedback regarding ConPEC, you agree that ConPEC can
                    use and share (but does not have to) such feedback for any
                    purpose without compensation to you.<br />
                    You promise to only provide information and content that you
                    have the right to share, and that your ConPEC profile will
                    be truthful.<br />
                    You agree to only provide content or information that does
                    not violate the law nor anyone’s rights (including
                    intellectual property rights). You also agree that your
                    profile information will be truthful. ConPEC may be required
                    by law to remove certain information or content as the case
                    may be.
                  </div>
                </li>

                <li>
                  <div class="sub-heading">3.2 Service Availability</div>
                  <div class="ml-6">
                    We may change, suspend, end or modify any function or
                    feature. We don’t promise to store or keep showing any
                    information and content that you’ve posted. ConPEC is not a
                    storage platform. You agree that we have no obligation to
                    store, maintain or provide you a copy of any content or
                    information that you or others provide, except to the extent
                    required by applicable law and as noted in our Privacy
                    Policy.
                  </div>
                </li>
                <li>
                  <div class="sub-heading">
                    3.3 Other Content, Sites and Apps
                  </div>
                  <div class="ml-6">
                    Your use of others’ content and information posted on our
                    ConPEC, is at your own risk. We aren’t responsible for those
                    third-party activities.<br />
                    By using ConPEC, you may encounter content or information
                    that might be inaccurate, incomplete, delayed, misleading,
                    illegal, offensive or otherwise harmful. ConPEC generally
                    does not review content provided by our Members or others.
                    You agree that we are not responsible for others’ (including
                    other Members’) content or information. We cannot always
                    prevent this misuse of ConPEC, and you agree that we are not
                    responsible for any such misuse.<br />
                    ConPEC may help connect Members offering resources and
                    course-support with Members seeking resources and
                    course-support. ConPEC does not perform nor employs
                    individuals to perform these services. You must be at least
                    18 years of age to offer, perform or receive these offers
                    and requests. You acknowledge that ConPEC does not
                    supervise, direct, control or monitor Members in the
                    performance of these course-support and agree that (1)
                    ConPEC is not responsible for the offering and performance
                    of these support and requests, (2) ConPEC does not endorse
                    any particular Member’s. If you are a Member offering
                    course-support, you represent and warrant that you have the
                    required capacity to do so.
                  </div>
                </li>
                <li>
                  <div class="sub-heading">3.4 Limits</div>
                  <div class="ml-6">
                    We have the right to limit how you connect and interact on
                    ConPEC.<br />
                    ConPEC reserves the right to limit your use of the Services,
                    including your ability to contact other Members. ConPEC
                    reserves the right to restrict, suspend, or terminate your
                    account if you breach this Agreement or the law or are
                    misusing the Services (e.g., violating any of the Dos and
                    Don’ts or Professional Community Policies).
                  </div>
                </li>
                <li>
                  <div class="sub-heading">
                    3.5 Intellectual Property Rights
                  </div>
                  <div class="ml-6">
                    ConPEC reserves all of its intellectual property rights on
                    the Platform. ConPEC, and its logos and other trademarks,
                    service marks, graphics and logos used are trademarks or
                    registered trademarks of ConPEC.
                  </div>
                </li>
                <li>
                  <div class="sub-heading">3.6 Automated Processing</div>
                  <div class="ml-6">
                    We use data and information about you to make relevant
                    suggestions to you and others. We use the information and
                    data that you provide and that we have about Members to make
                    recommendations that may be useful to you.
                  </div>
                </li>
              </ol>
            </li>
            <li>
              <div class="heading">4. Disclaimer and Limit of Liability</div>
              <ol>
                <li>
                  <div class="sub-heading">4.1 No Warranty</div>
                  <div class="ml-6">
                    This is our disclaimer of liability for the quality, safety,
                    or reliability of our Services. ConPEC makes no
                    representation or warranty about the services, including any
                    representation that the services will be uninterrupted or
                    error-free, and provides the services (including content and
                    information) on an “as is” and “as available” basis to the
                    fullest extent permitted under applicable law, ConPEC
                    disclaims any implied or statutory warranty, including any
                    implied warranty of title, accuracy of data,
                    non-infringement, merchantability, or fitness for a
                    particular purpose.
                  </div>
                </li>
                <li>
                  <div class="sub-heading">4.2 Exclusion of Liability</div>
                  <div class="ml-6">
                    These are the limits of liability we may have to you.<br />
                    To the fullest extent permitted by law (and unless ConPEC
                    has entered into a separate written agreement that overrides
                    this agreement), ConPEC will not be liable in connection
                    with this agreement for lost profits or lost business
                    opportunities, reputation (e.g., offensive or defamatory
                    statements), loss of data (e.g., downtime or loss, use of,
                    or changes to, your information or content) or any indirect,
                    incidental, consequential, special or punitive damages.
                    ConPEC will not be liable to you in connection with this
                    agreement for any amount.
                  </div>
                </li>
              </ol>
            </li>
            <li>
              <div class="heading">5. Termination</div>
              <div class="ml-4">
                We can each end this Agreement, but some rights and obligations
                survive. Both you and ConPEC may terminate this Agreement at any
                time with notice to the other. On termination, you lose the
                right to access or use the Platform. The following shall survive
                termination:
                <ul class="ml-5 list-style-type-upper-roman">
                  <li>Our rights to use and disclose your feedback</li>
                  <li>
                    Members and/or Visitors’ rights to further re-share content
                    and information you shared through the Platform;
                  </li>
                  <li>Sections 4, 6, 7, and of this Agreement;</li>
                  <li>
                    You can visit the settings page to close your account.
                  </li>
                </ul>
              </div>
            </li>
            <li>
              <div class="heading">6. Dispute Resolution</div>
              <div class="ml-4">
                In the unlikely event we end up in a dispute, you and ConPEC
                agree to resolve it amicably through mutual dialogue.
              </div>
            </li>
            <li>
              <div class="heading">7. ConPEC “Dos and Don’ts”</div>
              <div class="ml-4">
                ConPEC is a community of professionals. This list of “Dos and
                Don’ts” along with our Professional Community Policies limits
                what you can and cannot do on the Platform.
                <div class="sub-heading ml-0">7.1. Dos</div>
                <div class="ml-3">
                  You agree that you will:
                  <ul class="ml-5 list-style-type-square">
                    <li>
                      Comply with all applicable laws, including, without
                      limitation, privacy laws, intellectual property laws,
                      anti-spam laws, export control laws, tax laws, and
                      regulatory requirements;
                    </li>
                    <li>
                      Provide accurate information to us and keep it updated;
                    </li>
                    <li>Use your real name on your profile; and</li>
                    <li>Professionally use the Services.</li>
                  </ul>
                </div>
                <div class="sub-heading ml-0">7.2. Don’ts</div>
                <div class="ml-3">
                  You agree that you will not:
                  <ul class="ml-5 list-style-type-square">
                    <li>
                      Create a false identity on ConPEC, misrepresent your
                      identity, create a Member profile for anyone other than
                      yourself (a real person), or use or attempt to use
                      another’s account;
                    </li>

                    <li>
                      Develop, support, or use software, devices, scripts,
                      robots, or any other means or processes (including
                      crawlers, browser plugins, add-ons, or any other
                      technology) to scrape the Platform or otherwise copy
                      profiles and other data from the Platform;
                    </li>

                    <li>
                      Override any security feature or bypass or circumvent any
                      access controls or use limits of the Service (such as caps
                      on keyword searches or profile views);
                    </li>

                    <li>
                      Copy, use, disclose, or distribute any information
                      obtained from the ConPEC, whether directly or through
                      third parties (such as search engines), without the
                      consent of ConPEC;
                    </li>
                    <li>
                      Disclose information that you do not have consent to
                      disclose (such as confidential information of others
                      (including your employer);
                    </li>
                    <li>
                      Violate the intellectual property rights of others,
                      including copyrights, patents, trademarks, trade secrets,
                      or other proprietary rights.
                    </li>
                    <li>
                      Violate the intellectual property or other rights of
                      ConPEC.
                    </li>
                    <li>
                      Post anything that contains software viruses, worms, or
                      any other harmful code;
                    </li>
                    <li>
                      Reverse engineer, decompile, disassemble, decipher, or
                      otherwise attempt to derive the source code for the
                      Platform or any related technology that is not open
                      source;
                    </li>
                    <li>
                      Imply or state that you are affiliated with or endorsed by
                      ConPEC without our express consent (e.g., representing
                      yourself as an accredited ConPEC representative);
                    </li>
                    <li>
                      Rent, lease, loan, trade, sell/re-sell, or otherwise
                      monetize the Services or related data or access to the
                      same, without ConPEC's consent;
                    </li>
                    <li>
                      Use bots or other automated methods to access the ConPEC,
                      add or download contacts, send or redirect messages;
                    </li>
                    <li>
                      Monitor the Platform’s availability, performance, or
                      functionality for any competitive purpose;
                    </li>
                    <li>
                      Engage in “framing,” “mirroring,” or otherwise simulating
                      the appearance or function of the Platform;
                    </li>
                    <li>
                      Interfere with the operation of, or place an unreasonable
                      load on, the Platform (e.g., spam, denial of service
                      attack, viruses, gaming algorithms).
                    </li>
                  </ul>
                </div>
              </div>
            </li>
            <li>
              <div class="heading">8. Complaints Regarding Content</div>
              <div class="ml-6">
                We respect the rights of others. We require that information
                posted by Members be accurate and not in violation of the rights
                of others. We provide a policy and process for complaints
                concerning content posted by our Members.
              </div>
            </li>
            <li>
              <div class="heading">9. How to Contact Us</div>
              <div class="ml-6">
                You can contact us
                <router-link :to="{ name: 'contact-us' }">here</router-link>.
              </div>
            </li>
          </ol>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped>
.heading {
  font-size: 1.5rem;
  line-height: 1.25;
  margin-top: 2rem;
  font-weight: bold;
}

.list-style-type-square {
  list-style-type: square;
}

.list-style-type-upper-roman {
  list-style-type: decimal;
}

.sub-heading {
  font-size: 1.25rem;
  margin-top: 1rem;
  margin-left: 1rem;
  font-weight: bold;
}

.sub-sub-heading {
  font-weight: bold;
}

.heading-list > li {
  border-bottom: 1px solid #cfcfcf;
  padding-bottom: 1rem;
}
</style>
