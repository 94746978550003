import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, Transition as _Transition, withCtx as _withCtx, isRef as _isRef, vShow as _vShow, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "grid-container" }
const _hoisted_2 = { class: "content min-height m-3" }
const _hoisted_3 = { class: "back-grid" }
const _hoisted_4 = { class: "flex flex-column lg:flex-row xl:align-items-center" }
const _hoisted_5 = { class: "flex-shrink-1 flex-grow-0 mr-3 layout-button" }
const _hoisted_6 = { class: "mt-5" }
const _hoisted_7 = { class: "flex flex-direction-column lg:flex-row mr-auto ml-auto text-align-center align-center" }
const _hoisted_8 = { class: "gallery col-12 sm:col-6 lg:col-12 xl:col-3 p-0" }
const _hoisted_9 = {
  key: 0,
  class: "w-100"
}
const _hoisted_10 = {
  key: 1,
  class: "w-100"
}
const _hoisted_11 = { class: "gallery col-12 sm:col-6 lg:col-12 xl:col-3 p-0 m-1" }
const _hoisted_12 = {
  key: 0,
  class: "w-100"
}
const _hoisted_13 = {
  key: 1,
  class: "w-100"
}
const _hoisted_14 = { class: "gallery col-12 sm:col-6 lg:col-12 xl:col-3 p-0" }
const _hoisted_15 = {
  key: 0,
  class: "w-100"
}
const _hoisted_16 = {
  key: 1,
  class: "w-100"
}
const _hoisted_17 = { class: "mt-1" }
const _hoisted_18 = ["disabled"]
const _hoisted_19 = { class: "padding-10 mt-4" }
const _hoisted_20 = { key: 0 }
const _hoisted_21 = ["onClick"]
const _hoisted_22 = { class: "number" }
const _hoisted_23 = { class: "image-container" }
const _hoisted_24 = ["src"]
const _hoisted_25 = {
  key: 1,
  id: "profile-img",
  src: "//ssl.gstatic.com/accounts/ui/avatar_2x.png",
  class: "recommendation-img-card"
}
const _hoisted_26 = { class: "flex-grow-1" }
const _hoisted_27 = ["onClick"]
const _hoisted_28 = { key: 1 }
const _hoisted_29 = { class: "flex-grow-0 flex-shrink-0 mr-3 more-details" }
const _hoisted_30 = { key: 0 }
const _hoisted_31 = { key: 1 }
const _hoisted_32 = { class: "flex-grow-0 flex-shrink-0 mr-5" }
const _hoisted_33 = ["onClick"]
const _hoisted_34 = { key: 0 }
const _hoisted_35 = { class: "table" }
const _hoisted_36 = { key: 0 }
const _hoisted_37 = { key: 1 }
const _hoisted_38 = { class: "table" }
const _hoisted_39 = ["href"]
const _hoisted_40 = ["href"]
const _hoisted_41 = { key: 0 }
const _hoisted_42 = { key: 1 }
const _hoisted_43 = { key: 2 }
const _hoisted_44 = { class: "cursor-pointer" }
const _hoisted_45 = ["src"]
const _hoisted_46 = {
  key: 1,
  id: "profile-img",
  src: "//ssl.gstatic.com/accounts/ui/avatar_2x.png",
  class: "recommendation-img-card"
}
const _hoisted_47 = { class: "text-align-center" }
const _hoisted_48 = { class: "bold-text" }
const _hoisted_49 = { class: "bold-text" }
const _hoisted_50 = { class: "flex flex-direction-column justify-content-between text-align-center mt-2" }
const _hoisted_51 = ["onClick"]
const _hoisted_52 = ["onClick"]
const _hoisted_53 = { class: "form-group" }
const _hoisted_54 = { class: "form-group text-align-center" }
const _hoisted_55 = ["disabled"]
const _hoisted_56 = { class: "spinner-border spinner-border-sm" }
const _hoisted_57 = { style: {"text-align":"left"} }
const _hoisted_58 = {
  class: "bold-text",
  style: {"font-size":"x-large"}
}
const _hoisted_59 = { class: "table" }
const _hoisted_60 = { key: 0 }
const _hoisted_61 = { key: 1 }
const _hoisted_62 = { class: "table" }
const _hoisted_63 = ["href"]
const _hoisted_64 = ["href"]
const _hoisted_65 = { key: 0 }
const _hoisted_66 = { key: 1 }
const _hoisted_67 = { key: 2 }
const _hoisted_68 = { style: {"text-align":"left"} }
const _hoisted_69 = {
  class: "bold-text",
  style: {"font-size":"x-large"}
}
const _hoisted_70 = { class: "table" }

import { ref } from "vue";
import { showToast, websiteURL, valid } from "@/utils";
import * as yup from "yup";
import { Form, Field, ErrorMessage } from "vee-validate";
import Dialog from "primevue/dialog";
import InstructorSidebar from "@/components/instructor/InstructorSidebar.vue";
import BackButton from "@/components/user/BackButton.vue";
import RecommendationGalleryItem from "@/components/instructor/RecommendationGalleryItem.vue";
import DataView from "primevue/dataview";
import DataViewLayoutOptions from "primevue/dataviewlayoutoptions";
import { useCourseStore } from "@/stores/CourseStore";
import { useRecommendationsStore } from "@/stores/RecommendationsStore";
import { useAuthStore } from "@/stores/AuthStore";
import { useFeedStore } from "@/stores/FeedStore";
import { useRouter } from "vue-router";
import InstructorService from "@/services/instructor.service.js";


export default /*@__PURE__*/_defineComponent({
  __name: 'RecommendationsView',
  setup(__props) {

const CourseStore = useCourseStore();
const RecommendationsStore = useRecommendationsStore();
const AuthStore = useAuthStore();
const FeedStore = useFeedStore();
const router = useRouter();

let loading = ref(false);
let message = ref("");
let successful = ref(false);
let isVisible = ref(false);
let isPractitionerDetailsVisible = ref(false);
let isPractitionerExperienceVisible = ref(false);
let currIndustryPractitioner = ref({});
let pastExperienceData = ref([]);
const currentUser = AuthStore.auth;

const showPopup = () => {
  isVisible.value = true;
};

const schema = yup.object().shape({
  msg: yup.string().required("Message to practitioner  is required!"),
});

const getCustomMsg = () => {
  if (currentUser.phone === "True") {
    return `I, ${currentUser.title} ${currentUser.firstName} ${currentUser.lastName} wants you to provide the course-support for (${course.courseRequestType}).\nYou can reach me at ${currentUser.email} or ${currentUser.phoneNumber}.`;
  } else {
    return `I, ${currentUser.title} ${currentUser.firstName} ${currentUser.lastName} wants you to provide the course-support for (${course.courseRequestType}).\nYou can reach me at ${currentUser.email}.`;
  }
};

const course = CourseStore.course;

RecommendationsStore.setRecommendations(course);

const selectIndustryPractitioner = (practitioner) => {
  practitioner.opened = false;
  RecommendationsStore.selectPractitioner(practitioner.id);
  RecommendationsStore.$persist();
};

const saveSelectedPractitioners = (data) => {
  loading.value = true;
  InstructorService.selectIndustryPractitioner(
    course,
    RecommendationsStore.selectedPractitioner,
    data["msg"],
    RecommendationsStore.recommendations.map((el) => el.id)
  ).then(
    (response) => {
      loading.value = false;
      showToast("Message sent successfully to selected industry practitioner.");
      router.push({ name: "home" });
    },
    (error) => {
      loading.value = false;
      message.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};

const getPractitionerExperience = (e, industryPractitioner) => {
  console.log(e);
  e.stopPropagation();
  loading.value = true;
  successful.value = false;
  currIndustryPractitioner.value = industryPractitioner;
  InstructorService.getPastExperienceDetails(industryPractitioner.id).then(
    (response) => {
      console.log(response.data);
      pastExperienceData.value = response.data;
      successful.value = true;
      loading.value = false;
      isPractitionerExperienceVisible.value = true;
    },
    (error) => {
      loading.value = false;
      message.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};

const windowResize = () => {
  if (window.innerWidth < 600) {
    FeedStore.recommendationLayout = "grid";
  }
};

windowResize();
window.addEventListener("resize", windowResize);

return (_ctx: any,_cache: any) => {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(InstructorSidebar),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(BackButton),
          _createElementVNode("div", _hoisted_4, [
            _cache[4] || (_cache[4] = _createElementVNode("div", null, [
              _createElementVNode("h2", {
                style: {"text-align":"center","font-weight":"bold"},
                class: "flex-grow-1"
              }, " View Recommendations "),
              _createElementVNode("h5", { style: {"text-align":"center"} }, " You will be provided with the contact information of the practitioner(s) you selected. ")
            ], -1)),
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_unref(DataViewLayoutOptions), {
                modelValue: _unref(FeedStore).recommendationLayout,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(FeedStore).recommendationLayout) = $event))
              }, null, 8, ["modelValue"])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _cache[5] || (_cache[5] = _createElementVNode("span", { class: "bold-text" }, " A maximum of 3 selections is allowed. If the first practitioner declines, your request goes to next practitioner. ", -1)),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              (_unref(RecommendationsStore).selectedPractitioner.length > 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                    _createVNode(RecommendationGalleryItem, {
                      "selected-practitioner": 
                  _unref(RecommendationsStore).selectedPractitioner[0]
                ,
                      title: "1st Choice Practitioner"
                    }, null, 8, ["selected-practitioner"])
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_10, "1st Practitioner"))
            ]),
            _createElementVNode("div", _hoisted_11, [
              (_unref(RecommendationsStore).selectedPractitioner.length > 1)
                ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                    _createVNode(RecommendationGalleryItem, {
                      "selected-practitioner": 
                  _unref(RecommendationsStore).selectedPractitioner[1]
                ,
                      title: "2nd Choice Practitioner"
                    }, null, 8, ["selected-practitioner"])
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_13, "2nd Practitioner"))
            ]),
            _createElementVNode("div", _hoisted_14, [
              (_unref(RecommendationsStore).selectedPractitioner.length > 2)
                ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                    _createVNode(RecommendationGalleryItem, {
                      "selected-practitioner": 
                  _unref(RecommendationsStore).selectedPractitioner[2]
                ,
                      title: "3rd Choice Practitioner"
                    }, null, 8, ["selected-practitioner"])
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_16, "3rd Practitioner"))
            ]),
            _createElementVNode("div", _hoisted_17, [
              _createElementVNode("button", {
                class: "btn btn-primary",
                disabled: _unref(RecommendationsStore).selectedPractitioner.length === 0,
                onClick: showPopup
              }, " Save Choices ", 8, _hoisted_18)
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_19, [
          (_unref(RecommendationsStore).recommendations.length > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                _createVNode(_unref(DataView), {
                  value: _unref(RecommendationsStore).recommendations,
                  layout: _unref(FeedStore).recommendationLayout
                }, {
                  list: _withCtx((slotProps) => [
                    _createElementVNode("div", {
                      class: _normalizeClass(["w-100", { disabled: slotProps.data.disabled }])
                    }, [
                      _createElementVNode("div", {
                        class: _normalizeClass(["flex flex-column xl:flex-row xl:align-items-center p-4 gap-4 flex-grow-1", { 'pointer-events-none': slotProps.data.disabled }])
                      }, [
                        _createElementVNode("div", {
                          class: "flex flex-column xl:flex-row xl:align-items-center cursor-pointer",
                          onClick: ($event: any) => (slotProps.data.opened = !slotProps.data.opened)
                        }, [
                          _createElementVNode("div", _hoisted_22, _toDisplayString(slotProps.index + 1), 1),
                          _createElementVNode("div", _hoisted_23, [
                            (slotProps.data.fileName)
                              ? (_openBlock(), _createElementBlock("img", {
                                  key: 0,
                                  id: "profile-img",
                                  src: 
                          _unref(websiteURL) +
                          'profile_picture/' +
                          slotProps.data.fileName
                        ,
                                  class: "recommendation-img-card"
                                }, null, 8, _hoisted_24))
                              : (_openBlock(), _createElementBlock("img", _hoisted_25))
                          ]),
                          _createElementVNode("div", _hoisted_26, [
                            _createElementVNode("b", null, [
                              _createTextVNode(_toDisplayString(slotProps.data.title +
                          " " +
                          slotProps.data.firstName +
                          " " +
                          slotProps.data.lastName +
                          ", " +
                          slotProps.data.city +
                          " " +
                          slotProps.data.state) + " ", 1),
                              _cache[6] || (_cache[6] = _createElementVNode("br", null, null, -1)),
                              _createTextVNode(" " + _toDisplayString(slotProps.data.position +
                          " at " +
                          slotProps.data.nameOrg), 1)
                            ]),
                            _cache[9] || (_cache[9] = _createElementVNode("br", null, null, -1)),
                            _cache[10] || (_cache[10] = _createElementVNode("b", null, "Area of Specialization:", -1)),
                            _createTextVNode(" " + _toDisplayString(JSON.parse(slotProps.data.expertise).join(", ")) + " ", 1),
                            _cache[11] || (_cache[11] = _createElementVNode("br", null, null, -1)),
                            _cache[12] || (_cache[12] = _createElementVNode("b", null, "Years of Experience:", -1)),
                            _createTextVNode(" " + _toDisplayString(slotProps.data.yearsOfExp) + " ", 1),
                            _cache[13] || (_cache[13] = _createElementVNode("br", null, null, -1)),
                            (slotProps.data.pastExperience > 0)
                              ? (_openBlock(), _createElementBlock("div", {
                                  key: 0,
                                  onClick: ($event: any) => (
                          getPractitionerExperience($event, slotProps.data)
                        ),
                                  class: "past-exp-button"
                                }, [
                                  _cache[7] || (_cache[7] = _createElementVNode("b", null, "Previous course-support provided:", -1)),
                                  _createTextVNode(" " + _toDisplayString(slotProps.data.pastExperience), 1)
                                ], 8, _hoisted_27))
                              : (_openBlock(), _createElementBlock("div", _hoisted_28, [
                                  _cache[8] || (_cache[8] = _createElementVNode("b", null, "Previous course-support provided:", -1)),
                                  _createTextVNode(" " + _toDisplayString(slotProps.data.pastExperience), 1)
                                ]))
                          ]),
                          _createElementVNode("div", _hoisted_29, [
                            (slotProps.data.opened)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_30, [
                                  _createVNode(_component_font_awesome_icon, { icon: "fa-solid fa-chevron-up" })
                                ]))
                              : (_openBlock(), _createElementBlock("span", _hoisted_31, [
                                  _cache[14] || (_cache[14] = _createElementVNode("span", { class: "text-sm" }, " View Details", -1)),
                                  _createVNode(_component_font_awesome_icon, { icon: "fa-solid fa-chevron-down" })
                                ]))
                          ])
                        ], 8, _hoisted_21),
                        _createElementVNode("div", _hoisted_32, [
                          (
                        _unref(course).status !== '20' &&
                        _unref(course).status !== '10' &&
                        _unref(course).status !== '60'
                      )
                            ? (_openBlock(), _createElementBlock("button", {
                                key: 0,
                                class: "btn btn-primary",
                                onClick: ($event: any) => (selectIndustryPractitioner(slotProps.data))
                              }, " Select Practitioner ", 8, _hoisted_33))
                            : _createCommentVNode("", true)
                        ])
                      ], 2),
                      _createVNode(_Transition, null, {
                        default: _withCtx(() => [
                          (slotProps.data.opened)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_34, [
                                _cache[33] || (_cache[33] = _createElementVNode("h5", { class: "text-align-center font-italic font-bold" }, " Practitioner’s Basic Information ", -1)),
                                _createElementVNode("table", _hoisted_35, [
                                  _createElementVNode("tr", null, [
                                    _cache[15] || (_cache[15] = _createElementVNode("td", null, "Name", -1)),
                                    _createElementVNode("td", null, _toDisplayString(slotProps?.data.firstName +
                            " " +
                            slotProps?.data.lastName), 1)
                                  ]),
                                  _createElementVNode("tr", null, [
                                    _cache[16] || (_cache[16] = _createElementVNode("td", null, "Title", -1)),
                                    _createElementVNode("td", null, _toDisplayString(slotProps?.data.title), 1)
                                  ]),
                                  (_unref(valid)(slotProps?.data.pronouns))
                                    ? (_openBlock(), _createElementBlock("tr", _hoisted_36, [
                                        _cache[17] || (_cache[17] = _createElementVNode("td", null, "Pronouns", -1)),
                                        _createElementVNode("td", null, _toDisplayString(slotProps?.data.pronouns), 1)
                                      ]))
                                    : _createCommentVNode("", true),
                                  (_unref(valid)(slotProps?.data.position))
                                    ? (_openBlock(), _createElementBlock("tr", _hoisted_37, [
                                        _cache[18] || (_cache[18] = _createElementVNode("td", null, "Position", -1)),
                                        _createElementVNode("td", null, _toDisplayString(slotProps?.data.position), 1)
                                      ]))
                                    : _createCommentVNode("", true),
                                  _createElementVNode("tr", null, [
                                    _cache[19] || (_cache[19] = _createElementVNode("td", null, "Years of Experience", -1)),
                                    _createElementVNode("td", null, _toDisplayString(slotProps?.data.yearsOfExp), 1)
                                  ]),
                                  _createElementVNode("tr", null, [
                                    _cache[20] || (_cache[20] = _createElementVNode("td", null, "Level of Education", -1)),
                                    _createElementVNode("td", null, _toDisplayString(slotProps?.data.levelEducation), 1)
                                  ]),
                                  _createElementVNode("tr", null, [
                                    _cache[21] || (_cache[21] = _createElementVNode("td", null, "Area of Specialization", -1)),
                                    _createElementVNode("td", null, _toDisplayString(JSON.parse(
                              slotProps?.data?.expertise || '[""]'
                            ).join(", ")), 1)
                                  ]),
                                  _createElementVNode("tr", null, [
                                    _cache[22] || (_cache[22] = _createElementVNode("td", null, "Professional License", -1)),
                                    _createElementVNode("td", null, _toDisplayString(JSON.parse(
                              slotProps?.data?.profLicense || '[""]'
                            ).join(", ")), 1)
                                  ])
                                ]),
                                _cache[34] || (_cache[34] = _createElementVNode("h5", { class: "text-align-center font-italic font-bold" }, " Practitioner’s Organization Basic Information ", -1)),
                                _createElementVNode("table", _hoisted_38, [
                                  _createElementVNode("tr", null, [
                                    _cache[23] || (_cache[23] = _createElementVNode("td", null, "Organization", -1)),
                                    _createElementVNode("td", null, _toDisplayString(slotProps?.data.nameOrg), 1)
                                  ]),
                                  _createElementVNode("tr", null, [
                                    _cache[24] || (_cache[24] = _createElementVNode("td", null, "Location", -1)),
                                    _createElementVNode("td", null, _toDisplayString(slotProps?.data.city + " " + slotProps?.data.state), 1)
                                  ]),
                                  _createElementVNode("tr", null, [
                                    _cache[25] || (_cache[25] = _createElementVNode("td", null, "Type of Organization", -1)),
                                    _createElementVNode("td", null, _toDisplayString(slotProps?.data.type), 1)
                                  ]),
                                  _createElementVNode("tr", null, [
                                    _cache[26] || (_cache[26] = _createElementVNode("td", null, "Size of Organization", -1)),
                                    _createElementVNode("td", null, _toDisplayString(slotProps?.data.size), 1)
                                  ]),
                                  _createElementVNode("tr", null, [
                                    _cache[27] || (_cache[27] = _createElementVNode("td", null, "Gender Diversity of Organization", -1)),
                                    _createElementVNode("td", null, _toDisplayString(slotProps?.data.genderDiv), 1)
                                  ]),
                                  _createElementVNode("tr", null, [
                                    _cache[28] || (_cache[28] = _createElementVNode("td", null, "Racial Diversity of Organization", -1)),
                                    _createElementVNode("td", null, _toDisplayString(slotProps?.data.ethnicDiv), 1)
                                  ]),
                                  _createElementVNode("tr", null, [
                                    _cache[29] || (_cache[29] = _createElementVNode("td", null, "Website of Organization", -1)),
                                    _createElementVNode("td", null, [
                                      (!slotProps?.data.website.startsWith('http'))
                                        ? (_openBlock(), _createElementBlock("a", {
                                            key: 0,
                                            href: 'https://' + slotProps?.data.website,
                                            target: "_blank"
                                          }, _toDisplayString(slotProps?.data.website), 9, _hoisted_39))
                                        : (_openBlock(), _createElementBlock("a", {
                                            key: 1,
                                            href: slotProps?.data.website,
                                            target: "_blank"
                                          }, _toDisplayString(slotProps?.data.website), 9, _hoisted_40))
                                    ])
                                  ]),
                                  (_unref(valid)(slotProps?.data.typeProject))
                                    ? (_openBlock(), _createElementBlock("tr", _hoisted_41, [
                                        _cache[30] || (_cache[30] = _createElementVNode("td", null, "Types of Projects Executed", -1)),
                                        _createElementVNode("td", null, _toDisplayString(JSON.parse(
                              slotProps?.data.typeProject || '[""]'
                            ).join(", ")), 1)
                                      ]))
                                    : _createCommentVNode("", true),
                                  (_unref(valid)(slotProps?.data.typeTechnology))
                                    ? (_openBlock(), _createElementBlock("tr", _hoisted_42, [
                                        _cache[31] || (_cache[31] = _createElementVNode("td", null, " Types of Technologies Adopted by Practitioner's Organization ", -1)),
                                        _createElementVNode("td", null, _toDisplayString(JSON.parse(
                              slotProps?.data.typeTechnology || '[""]'
                            ).join(", ")), 1)
                                      ]))
                                    : _createCommentVNode("", true),
                                  (_unref(valid)(slotProps?.data.organPolicy))
                                    ? (_openBlock(), _createElementBlock("tr", _hoisted_43, [
                                        _cache[32] || (_cache[32] = _createElementVNode("td", null, "Company's Effort to Support Student Development", -1)),
                                        _createElementVNode("td", null, _toDisplayString(slotProps?.data.organPolicy), 1)
                                      ]))
                                    : _createCommentVNode("", true)
                                ])
                              ]))
                            : _createCommentVNode("", true)
                        ]),
                        _: 2
                      }, 1024)
                    ], 2)
                  ]),
                  grid: _withCtx((slotProps) => [
                    _createElementVNode("div", {
                      class: _normalizeClass(["col-12 sm:col-6 lg:col-12 xl:col-4 p-2", { disabled: slotProps.data.disabled }])
                    }, [
                      _createElementVNode("div", {
                        class: _normalizeClass(["border-1 surface-border surface-card border-round p-2", { 'pointer-events-none': slotProps.data.disabled }])
                      }, [
                        _createElementVNode("div", _hoisted_44, [
                          _createElementVNode("div", null, [
                            (slotProps.data.fileName)
                              ? (_openBlock(), _createElementBlock("img", {
                                  key: 0,
                                  id: "profile-img",
                                  src: 
                          _unref(websiteURL) +
                          'profile_picture/' +
                          slotProps.data.fileName
                        ,
                                  class: "recommendation-img-card"
                                }, null, 8, _hoisted_45))
                              : (_openBlock(), _createElementBlock("img", _hoisted_46))
                          ]),
                          _createElementVNode("div", _hoisted_47, [
                            _createElementVNode("div", _hoisted_48, _toDisplayString(slotProps.data.title +
                          " " +
                          slotProps.data.firstName +
                          " " +
                          slotProps.data.lastName), 1),
                            _createElementVNode("div", _hoisted_49, _toDisplayString(slotProps.data.position +
                          " at " +
                          slotProps.data.nameOrg +
                          ", " +
                          slotProps.data.city +
                          " " +
                          slotProps.data.state), 1),
                            _createElementVNode("div", null, [
                              _cache[35] || (_cache[35] = _createElementVNode("b", null, "Area of specialization:", -1)),
                              _createTextVNode(" " + _toDisplayString(JSON.parse(slotProps.data.expertise).join(", ")) + " ", 1),
                              _cache[36] || (_cache[36] = _createElementVNode("br", null, null, -1)),
                              _cache[37] || (_cache[37] = _createElementVNode("b", null, "Years of experience:", -1)),
                              _createTextVNode(" " + _toDisplayString(slotProps.data.yearsOfExp), 1)
                            ])
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_50, [
                          (
                        _unref(course).status !== '20' &&
                        _unref(course).status !== '10' &&
                        _unref(course).status !== '60'
                      )
                            ? (_openBlock(), _createElementBlock("div", {
                                key: 0,
                                class: "more-details",
                                onClick: ($event: any) => (
                        (_isRef(currIndustryPractitioner) //@ts-ignore
 ? currIndustryPractitioner.value = slotProps.data : currIndustryPractitioner = slotProps.data),
                          (_isRef(isPractitionerDetailsVisible) //@ts-ignore
 ? isPractitionerDetailsVisible.value = true : isPractitionerDetailsVisible = true)
                      )
                              }, " View Details ", 8, _hoisted_51))
                            : _createCommentVNode("", true),
                          (
                        _unref(course).status !== '20' &&
                        _unref(course).status !== '10' &&
                        _unref(course).status !== '60'
                      )
                            ? (_openBlock(), _createElementBlock("button", {
                                key: 1,
                                class: "btn btn-primary",
                                onClick: ($event: any) => (selectIndustryPractitioner(slotProps.data))
                              }, " Select Practitioner ", 8, _hoisted_52))
                            : _createCommentVNode("", true)
                        ])
                      ], 2)
                    ], 2)
                  ]),
                  _: 1
                }, 8, ["value", "layout"])
              ]))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _createVNode(_unref(Dialog), {
      visible: _unref(isVisible),
      "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => (_isRef(isVisible) ? (isVisible).value = $event : isVisible = $event)),
      modal: "",
      header: "Provide Course Support",
      style: { width: '40vw' },
      breakpoints: { '768px': '75vw' }
    }, {
      default: _withCtx(() => [
        _createVNode(_unref(Form), {
          onSubmit: saveSelectedPractitioners,
          "validation-schema": _unref(schema)
        }, {
          default: _withCtx(({ meta }) => [
            _createElementVNode("div", _hoisted_53, [
              _cache[38] || (_cache[38] = _createElementVNode("div", { style: {"display":"flex","justify-content":"space-between","height":"100%"} }, [
                _createElementVNode("label", { for: "msg" }, "Please provide any message you would like to send to practitioner")
              ], -1)),
              _createVNode(_unref(Field), {
                name: "msg",
                type: "text",
                class: "form-control",
                style: {"height":"200px"},
                as: "textarea",
                value: getCustomMsg()
              }, null, 8, ["value"]),
              _createVNode(_unref(ErrorMessage), {
                name: "msg",
                class: "error-feedback"
              })
            ]),
            _cache[40] || (_cache[40] = _createElementVNode("br", null, null, -1)),
            _createElementVNode("div", _hoisted_54, [
              _createElementVNode("button", {
                class: "btn btn-primary btn-block",
                disabled: _unref(loading) || !meta.valid
              }, [
                _withDirectives(_createElementVNode("span", _hoisted_56, null, 512), [
                  [_vShow, _unref(loading)]
                ]),
                _cache[39] || (_cache[39] = _createElementVNode("span", null, "Send to Industry Practitioners", -1))
              ], 8, _hoisted_55)
            ])
          ]),
          _: 1
        }, 8, ["validation-schema"])
      ]),
      _: 1
    }, 8, ["visible"]),
    _createVNode(_unref(Dialog), {
      visible: _unref(isPractitionerDetailsVisible),
      "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => (_isRef(isPractitionerDetailsVisible) ? (isPractitionerDetailsVisible).value = $event : isPractitionerDetailsVisible = $event)),
      modal: "",
      style: { width: '60vw' },
      breakpoints: { '768px': '90vw' }
    }, {
      header: _withCtx(() => [
        _createElementVNode("div", _hoisted_57, [
          _createElementVNode("span", _hoisted_58, _toDisplayString(_unref(currIndustryPractitioner)?.firstName +
          " " +
          _unref(currIndustryPractitioner)?.lastName), 1),
          _cache[41] || (_cache[41] = _createTextVNode(", ")),
          _createElementVNode("i", null, _toDisplayString(_unref(currIndustryPractitioner)?.position +
          " at " +
          _unref(currIndustryPractitioner).nameOrg), 1)
        ])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", null, [
          _cache[60] || (_cache[60] = _createElementVNode("h5", { class: "text-align-center font-italic font-bold" }, " Practitioner’s Basic Information ", -1)),
          _createElementVNode("table", _hoisted_59, [
            _createElementVNode("tr", null, [
              _cache[42] || (_cache[42] = _createElementVNode("td", null, "Name", -1)),
              _createElementVNode("td", null, _toDisplayString(_unref(currIndustryPractitioner).firstName +
              " " +
              _unref(currIndustryPractitioner).lastName), 1)
            ]),
            _createElementVNode("tr", null, [
              _cache[43] || (_cache[43] = _createElementVNode("td", null, "Title", -1)),
              _createElementVNode("td", null, _toDisplayString(_unref(currIndustryPractitioner)?.title), 1)
            ]),
            (_unref(valid)(_unref(currIndustryPractitioner).pronouns))
              ? (_openBlock(), _createElementBlock("tr", _hoisted_60, [
                  _cache[44] || (_cache[44] = _createElementVNode("td", null, "Pronouns", -1)),
                  _createElementVNode("td", null, _toDisplayString(_unref(currIndustryPractitioner).pronouns), 1)
                ]))
              : _createCommentVNode("", true),
            (_unref(valid)(_unref(currIndustryPractitioner).position))
              ? (_openBlock(), _createElementBlock("tr", _hoisted_61, [
                  _cache[45] || (_cache[45] = _createElementVNode("td", null, "Position", -1)),
                  _createElementVNode("td", null, _toDisplayString(_unref(currIndustryPractitioner).position), 1)
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("tr", null, [
              _cache[46] || (_cache[46] = _createElementVNode("td", null, "Years of Experience", -1)),
              _createElementVNode("td", null, _toDisplayString(_unref(currIndustryPractitioner).yearsOfExp), 1)
            ]),
            _createElementVNode("tr", null, [
              _cache[47] || (_cache[47] = _createElementVNode("td", null, "Level of Education", -1)),
              _createElementVNode("td", null, _toDisplayString(_unref(currIndustryPractitioner).levelEducation), 1)
            ]),
            _createElementVNode("tr", null, [
              _cache[48] || (_cache[48] = _createElementVNode("td", null, "Area of Specialization", -1)),
              _createElementVNode("td", null, _toDisplayString(JSON.parse(_unref(currIndustryPractitioner)?.expertise || '[""]').join(
                ", "
              )), 1)
            ]),
            _createElementVNode("tr", null, [
              _cache[49] || (_cache[49] = _createElementVNode("td", null, "Professional License", -1)),
              _createElementVNode("td", null, _toDisplayString(JSON.parse(_unref(currIndustryPractitioner)?.profLicense || '[""]').join(
                ", "
              )), 1)
            ])
          ]),
          _cache[61] || (_cache[61] = _createElementVNode("h5", { class: "text-align-center font-italic font-bold" }, " Practitioner’s Organization Basic Information ", -1)),
          _createElementVNode("table", _hoisted_62, [
            _createElementVNode("tr", null, [
              _cache[50] || (_cache[50] = _createElementVNode("td", null, "Organization", -1)),
              _createElementVNode("td", null, _toDisplayString(_unref(currIndustryPractitioner)?.nameOrg), 1)
            ]),
            _createElementVNode("tr", null, [
              _cache[51] || (_cache[51] = _createElementVNode("td", null, "Location", -1)),
              _createElementVNode("td", null, _toDisplayString(_unref(currIndustryPractitioner).city +
              " " +
              _unref(currIndustryPractitioner).state), 1)
            ]),
            _createElementVNode("tr", null, [
              _cache[52] || (_cache[52] = _createElementVNode("td", null, "Type of Organization", -1)),
              _createElementVNode("td", null, _toDisplayString(_unref(currIndustryPractitioner).type), 1)
            ]),
            _createElementVNode("tr", null, [
              _cache[53] || (_cache[53] = _createElementVNode("td", null, "Size of Organization", -1)),
              _createElementVNode("td", null, _toDisplayString(_unref(currIndustryPractitioner).size), 1)
            ]),
            _createElementVNode("tr", null, [
              _cache[54] || (_cache[54] = _createElementVNode("td", null, "Gender Diversity of Organization", -1)),
              _createElementVNode("td", null, _toDisplayString(_unref(currIndustryPractitioner).genderDiv), 1)
            ]),
            _createElementVNode("tr", null, [
              _cache[55] || (_cache[55] = _createElementVNode("td", null, "Racial Diversity of Organization", -1)),
              _createElementVNode("td", null, _toDisplayString(_unref(currIndustryPractitioner).ethnicDiv), 1)
            ]),
            _createElementVNode("tr", null, [
              _cache[56] || (_cache[56] = _createElementVNode("td", null, "Website of Organization", -1)),
              _createElementVNode("td", null, [
                (!_unref(currIndustryPractitioner).website.startsWith('http'))
                  ? (_openBlock(), _createElementBlock("a", {
                      key: 0,
                      href: 'https://' + _unref(currIndustryPractitioner).website,
                      target: "_blank"
                    }, _toDisplayString(_unref(currIndustryPractitioner).website), 9, _hoisted_63))
                  : (_openBlock(), _createElementBlock("a", {
                      key: 1,
                      href: _unref(currIndustryPractitioner).website,
                      target: "_blank"
                    }, _toDisplayString(_unref(currIndustryPractitioner).website), 9, _hoisted_64))
              ])
            ]),
            (_unref(valid)(_unref(currIndustryPractitioner).typeProject))
              ? (_openBlock(), _createElementBlock("tr", _hoisted_65, [
                  _cache[57] || (_cache[57] = _createElementVNode("td", null, "Types of Projects Executed", -1)),
                  _createElementVNode("td", null, _toDisplayString(JSON.parse(_unref(currIndustryPractitioner)?.typeProject || '[""]').join(
                ", "
              )), 1)
                ]))
              : _createCommentVNode("", true),
            (_unref(valid)(_unref(currIndustryPractitioner).typeTechnology))
              ? (_openBlock(), _createElementBlock("tr", _hoisted_66, [
                  _cache[58] || (_cache[58] = _createElementVNode("td", null, "Types of Technologies Adopted by Practitioner's Organization", -1)),
                  _createElementVNode("td", null, _toDisplayString(JSON.parse(
                _unref(currIndustryPractitioner)?.typeTechnology || '[""]'
              ).join(", ")), 1)
                ]))
              : _createCommentVNode("", true),
            (_unref(valid)(_unref(currIndustryPractitioner).organPolicy))
              ? (_openBlock(), _createElementBlock("tr", _hoisted_67, [
                  _cache[59] || (_cache[59] = _createElementVNode("td", null, "Company's Effort to Support Student Development", -1)),
                  _createElementVNode("td", null, _toDisplayString(_unref(currIndustryPractitioner).organPolicy), 1)
                ]))
              : _createCommentVNode("", true)
          ])
        ])
      ]),
      _: 1
    }, 8, ["visible"]),
    _createVNode(_unref(Dialog), {
      visible: _unref(isPractitionerExperienceVisible),
      "onUpdate:visible": _cache[3] || (_cache[3] = ($event: any) => (_isRef(isPractitionerExperienceVisible) ? (isPractitionerExperienceVisible).value = $event : isPractitionerExperienceVisible = $event)),
      modal: "",
      style: { width: '60vw' },
      breakpoints: { '768px': '90vw' }
    }, {
      header: _withCtx(() => [
        _createElementVNode("div", _hoisted_68, [
          _createElementVNode("span", _hoisted_69, _toDisplayString(_unref(currIndustryPractitioner)?.firstName +
          " " +
          _unref(currIndustryPractitioner)?.lastName), 1),
          _cache[62] || (_cache[62] = _createTextVNode(", ")),
          _createElementVNode("i", null, _toDisplayString(_unref(currIndustryPractitioner)?.position +
          " at " +
          _unref(currIndustryPractitioner).nameOrg), 1)
        ])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", null, [
          _createElementVNode("table", _hoisted_70, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(pastExperienceData), (currRow) => {
              return (_openBlock(), _createElementBlock("tr", {
                key: currRow.courseRequestType
              }, [
                _createElementVNode("td", null, _toDisplayString(currRow.courseRequestType), 1),
                _createElementVNode("td", null, _toDisplayString(currRow.count_exp), 1)
              ]))
            }), 128))
          ])
        ])
      ]),
      _: 1
    }, 8, ["visible"])
  ], 64))
}
}

})