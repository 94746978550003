<template>
  <div class="grid-container">
    <app-sidebar />
    <div class="m-3 min-height">
      <div class="back-grid">
        <back-button></back-button>
        <h2 class="text-align-center bold-text">
          <span v-if="tableName === 'CourseRequestTopic'"> Subject Areas </span>
          <span v-else>Topics of Interest </span>
        </h2>
      </div>
      <assign-categories :tableName="tableName" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import { useRoute } from "vue-router";
import AppSidebar from "@/components/user/AppSidebar.vue";
import BackButton from "@/components/user/BackButton.vue";
import AssignCategories from "@/components/admin/AssignCategories.vue";

const route = useRoute();
let tableName = ref("");

const fetchData = (type) => {
  console.log(type);
  tableName.value = type;
};

watch(
  () => route.params.name,
  (tableName) => {
    fetchData(tableName as string);
  },
  { immediate: true }
);
</script>

<style scoped></style>
