<template>
  <div class="grid-container">
    <app-sidebar />
    <div class="m-3 min-height">
      <div class="back-grid">
        <back-button></back-button>
        <h2 style="text-align: center; font-weight: bold">
          Dashboard Feedback
        </h2>
      </div>
      <br />
      <h5 class="text-align-center pl-1 pr-1 lg:pl-8 lg:pr-8">
        Your feedback is important to us for improving the platform. Please
        provide feedback based on your experience. We are happy to hear from
        you.
      </h5>
      <div class="col-md-12">
        <div class="card card-container">
          <span class="font-color-red">* required field</span>
          <Form
            @submit="handleSubmission"
            :validation-schema="schema"
            v-slot="{ values, meta }"
          >
            <div>
              <div
                class="form-group"
                v-if="
                  !feedbackFields.find((el) => el.name === 'message')?.disabled
                "
              >
                <label for="message"
                  >Message<span class="super font-color-red">*</span></label
                >
                <Field
                  name="message"
                  type="text"
                  as="textarea"
                  class="form-control"
                  maxlength="5000"
                  style="height: 100px"
                />
                <div class="size-counter">
                  <span
                    >{{ values.message ? values.message.length : 0 }} / 5000
                  </span>
                </div>
              </div>
              <ErrorMessage name="message" class="error-feedback" />
            </div>

            <br />
            <div style="width: 100%">
              <div class="form-group" style="text-align: center">
                <button
                  class="btn btn-primary"
                  style="text-align: center"
                  :disabled="loading || !meta.valid"
                >
                  <span
                    v-show="loading"
                    class="spinner-border spinner-border-sm"
                  ></span>
                  Submit
                </button>
              </div>
            </div>
          </Form>

          <div
            v-if="msg"
            class="alert"
            :class="successful ? 'alert-success' : 'alert-danger'"
          >
            {{ msg }}
          </div>
        </div>
      </div>
      <div class="mt-8" v-if="dashboardFeedback.length > 0">
        <h3 class="text-align-center">Your Feedback</h3>
        <div class="course-item mt-5">
          <div
            class="lg:m-1 card"
            v-for="feedback in dashboardFeedback"
            :key="feedback.id"
          >
            <div
              class="flex flex-direction-column lg:flex-row xl:flex-row justify-content-between align-content-center flex-wrap"
            >
              <div>
                <b>{{ feedback.message }} </b>
                &nbsp;
                <span v-if="feedback.rating === '0'" title="Unhappy"
                  >&#128542;</span
                >
                <span v-else-if="feedback.rating === '1'" title="Neutral"
                  >&#128528;</span
                >
                <span v-else-if="feedback.rating === '2'" title="Happy"
                  >&#128515;</span
                >
                <br />
                {{ new Date(feedback.createdAt).toDateString() }}
              </div>
              <div
                class="flex flex-grow-0 align-content-center lg:flex-wrap mr-3 leave-comment"
                style="cursor: default"
              >
                <button
                  class="btn btn-primary"
                  @click="
                    (currFeedback = feedback), getFeedbackComments(feedback.id)
                  "
                >
                  Leave a comment
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Dialog
    v-model:visible="isVisible"
    modal
    header="User Feedback"
    :style="{ width: '60vw' }"
    :breakpoints="{ '768px': '90vw' }"
  >
    <div class="flex flex-direction-row">
      <div class="flex-shrink-1 flex-grow-0">
        <profile-icon />
      </div>
      <span class="ml-3 mt-2 bold-text dark-text-color">
        {{ currentUser.firstName }} {{ currentUser.lastName }}
      </span>
    </div>
    <div class="mt-2" style="font-size: larger" autofocus>
      {{ currFeedback.message }}
    </div>
    <va-divider vertical color="red" />
    <div
      v-for="feedbackComment in feedbackComments"
      v-bind:key="feedbackComment.id"
    >
      <div class="flex flex-direction-row ml-2 overflow-scroll">
        <div class="flex-shrink-1 flex-grow-0">
          <img
            v-if="feedbackComment.user.fileName"
            id="profile-img"
            :src="
              websiteURL + 'profile_picture/' + feedbackComment.user.fileName
            "
            class="profile-icon-card"
          />
          <img
            v-else
            id="profile-img"
            src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
            class="profile-icon-card"
          />
        </div>
        <div class="mb-2">
          <div class="ml-3 pl-3 mt-1 card comment-card">
            <div class="bold-text dark-text-color">
              {{ feedbackComment.user.firstName }}
              {{ feedbackComment.user.lastName }}
            </div>
            {{ feedbackComment.message }}
          </div>
          <span class="ml-3 pl-1 small">
            {{ getTimeDiff(feedbackComment.createdAt) }}
          </span>
        </div>
      </div>
    </div>
    <va-divider />
    <div class="flex flex-direction-row">
      <div class="flex-shrink-1 flex-grow-0">
        <profile-icon />
      </div>
      <div class="ml-3 mt-2" v-if="!editable" @click="editable = true">
        Add Comment
      </div>
      <div class="ml-3 mt-2" v-else>
        <Form @submit="postComment" :validation-schema="commentSchema">
          <div class="flex flex-direction-row">
            <Field name="message" v-slot="{ field }">
              <textarea
                type="text"
                v-bind="field"
                class="form-control border-2"
                placeholder="Add a comment..."
              />
              <ErrorMessage name="message" class="error-feedback" />
            </Field>
            <button class="btn btn-primary mx-4 my-3">Reply</button>
          </div>
        </Form>
      </div>
    </div>
  </Dialog>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { useRouter } from "vue-router";
import { Field, ErrorMessage, Form } from "vee-validate";
import * as yup from "yup";
import Dialog from "primevue/dialog";
import AppSidebar from "@/components/user/AppSidebar.vue";
import BackButton from "@/components/user/BackButton.vue";
import ProfileIcon from "@/components/user/ProfileIcon.vue";
import UserService from "../services/user.service.js";
import { useConfigStore } from "@/stores/ConfigStore";
import { useAuthStore } from "@/stores/AuthStore";
import { websiteURL, getTimeDiff, showToast } from "@/utils";
import { VaDivider } from "vuestic-ui";
import { Feedback, FeedbackComment } from "@/types";

const router = useRouter();
const ConfigStore = useConfigStore();
const AuthStore = useAuthStore();

const feedbackFields = ConfigStore.config.filter(
  (el) => el.table === "feedback"
);
const currentUser = AuthStore.auth;

let loading = ref(false);
let successful = ref(false);
let msg = ref("");
let dashboardFeedback = ref([] as Feedback[]);
let currFeedback = ref({} as Feedback);
let feedbackComments = ref([] as FeedbackComment[]);
let isVisible = ref(false);
let editable = ref(false);

const schema = yup.object().shape({
  fields: yup.array().default(feedbackFields),
  message: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "message")?.disabled,
    then: (schema) =>
      schema
        .required("Message is required!")
        .min(10, "Must be at least 10 characters!")
        .max(5000, "Cannot be greater than 5000 characters!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
});

const commentSchema = yup.object().shape({
  message: yup
    .string()
    .required("Message is required!")
    .max(5000, "Cannot be greater tha 5000 characters!"),
});

const handleSubmission = (data, { resetForm }) => {
  loading.value = true;
  UserService.saveDashboardFeedback(data).then(
    (data) => {
      msg.value = data.message;
      resetForm();
      getDashboardFeedback(currentUser.id);
      successful.value = true;
      loading.value = false;
      showToast("Your Feedback has been successfully submitted.");
    },
    (error) => {
      msg.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      successful.value = false;
      loading.value = false;
    }
  );
};

const getDashboardFeedback = (userId) => {
  UserService.getDashboardFeedback(userId).then(
    (response) => {
      console.log(response);
      dashboardFeedback.value = response.data;
    },
    (error) => {
      msg.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};

const getFeedbackComments = (feedbackId) => {
  UserService.getFeedbackComments(feedbackId).then(
    (response) => {
      console.log(response);
      feedbackComments.value = response.data;
      isVisible.value = true;
    },
    (error) => {
      msg.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};

const postComment = (data) => {
  loading.value = true;
  data["linkedResourceType"] = "dashboardFeedback";
  UserService.postFeedbackComment(data, currFeedback.value.id).then(
    (data) => {
      msg.value = data.message;
      successful.value = true;
      showToast("Comment posted successfully.");
      isVisible.value = false;
      loading.value = false;
    },
    (error) => {
      msg.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      successful.value = false;
      loading.value = false;
    }
  );
};

getDashboardFeedback(currentUser.id);
</script>

<style scoped>
.emoji {
  border: none;
  margin: 0.5rem;
  font-size: 1.5rem;
  padding: 0;
  background: none;
  filter: grayscale(1);
}

.size-counter {
  position: relative;
  font-weight: lighter;
  text-align: right;
  color: #454545;
}

.selected {
  filter: grayscale(0);
}

.leave-comment {
  cursor: pointer;
  color: #1a73e8;
  font-weight: bold;
}

.dark-text-color {
  color: rgba(15, 20, 25, 1);
}

.comment-card {
  padding: 5px;
  margin-bottom: 0;
  border-radius: 8px;
}

.v-enter-active {
  transition: opacity 0.4s linear;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

@media screen and (max-width: 768px) {
  .emoji {
    margin: 0.25rem;
    font-size: 1rem;
  }
  .card {
    margin-top: 10px;
  }
  .card-container.card {
    max-width: 90% !important;
    padding: 10px 10px;
  }
}
</style>
