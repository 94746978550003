<template>
  <div class="min-height header-space mx-3">
    <div class="m-3">
      <div class="back-grid">
        <back-button></back-button>
        <h2 class="text-align-center bold-text">ConPEC Dashboard</h2>
      </div>
    </div>
    <div v-if="currentUser.dashboardAccess">
      <dashboard-map />
    </div>
    <div v-else class="my-5 text-align-center d-block">
      <h4 style="overflow-wrap: anywhere">
        Sorry, you do not have permission to access the dashboard. You can
        request access from the Admin.
      </h4>
      <button
        class="btn btn-primary mt-3"
        @click="requestDashboardAccess"
        :disabled="dashboardAccessRequestExists"
      >
        Request access from Admin
      </button>
      <div class="font-italic" v-if="dashboardAccessRequestExists">
        Request sent to Admin
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import AppSidebar from "@/components/user/AppSidebar.vue";
import BackButton from "@/components/user/BackButton.vue";
import DashboardMap from "@/components/user/DashboardMap.vue";
import { useAuthStore } from "@/stores/AuthStore";
import UserService from "@/services/user.service.js";
import { showToast, showErrorToast } from "@/utils";

const AuthStore = useAuthStore();
const currentUser = AuthStore.auth;
let loading = ref(false);
let message = ref("");
let dashboardAccessRequestExists = ref(false);

const requestDashboardAccess = () => {
  loading.value = true;
  UserService.requestDashboardAccess().then(
    (response) => {
      showToast("Request sent to admin successfully!");
      dashboardAccessRequestExists.value = true;
      loading.value = false;
    },
    (error) => {
      loading.value = false;
      message.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      showErrorToast(message.value);
    }
  );
};

const checkDashboardAccessRequestExists = () => {
  UserService.checkDashboardAccessRequestExists(currentUser.id).then(
    (response) => {
      console.log(response);
      if (response.status == "200")
        dashboardAccessRequestExists.value =
          response.data.dashboardAccessRequestExists;
    },
    (error) => {
      message.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(message.value);
    }
  );
};
if (currentUser.roles !== "ADMIN") checkDashboardAccessRequestExists();
</script>

<style scoped></style>
