<template>
  <span>
    <button
      @click="goBack"
      v-if="AuthStore.profile || AuthStore.role === 'ADMIN'"
    >
      <font-awesome-icon
        class="text-sm lg:text-4xl"
        icon="fa-solid fa-chevron-left"
        title="Go Back"
      />
      <span class="back-button-text"> Back </span>
    </button>
    <div v-else></div>
  </span>
</template>

<script setup lang="ts">
import { useRouter } from "vue-router";
import { useAuthStore } from "@/stores/AuthStore";

const router = useRouter();
const AuthStore = useAuthStore();

const goBack = () => {
  router.go(-1);
};
</script>

<style scoped>
button {
  border: none;
  background: none;
}

.back-button-text {
  vertical-align: super;
}

@media only screen and (max-width: 600px) {
  .back-button-text {
    display: none;
  }
}
</style>
