import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "grid-container" }
const _hoisted_2 = { class: "mt-3 min-height" }
const _hoisted_3 = { class: "back-grid" }
const _hoisted_4 = { class: "mt-3 lg:mt-5" }
const _hoisted_5 = {
  key: 0,
  class: "mt-3 font-large"
}
const _hoisted_6 = { class: "list-style-type-auto ml-4" }
const _hoisted_7 = { class: "sub-sub-heading" }
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = ["src"]
const _hoisted_10 = { class: "sub-sub-heading" }
const _hoisted_11 = ["innerHTML"]
const _hoisted_12 = ["src"]
const _hoisted_13 = { class: "sub-sub-heading" }
const _hoisted_14 = ["innerHTML"]
const _hoisted_15 = ["src"]
const _hoisted_16 = {
  key: 1,
  class: "mt-3 font-large"
}
const _hoisted_17 = { class: "list-style-type-auto ml-4" }
const _hoisted_18 = { class: "sub-sub-heading" }
const _hoisted_19 = ["innerHTML"]
const _hoisted_20 = ["src"]
const _hoisted_21 = { class: "sub-sub-heading" }
const _hoisted_22 = ["innerHTML"]
const _hoisted_23 = ["src"]
const _hoisted_24 = { class: "sub-sub-heading" }
const _hoisted_25 = ["innerHTML"]
const _hoisted_26 = ["src"]
const _hoisted_27 = { key: 2 }

import { ref } from "vue";
import AppSidebar from "@/components/user/AppSidebar.vue";
import BackButton from "@/components/user/BackButton.vue";
import { useAuthStore } from "@/stores/AuthStore";


export default /*@__PURE__*/_defineComponent({
  __name: 'UserGuideView',
  setup(__props) {

const AuthStore = useAuthStore();
const currentUser = AuthStore.auth;

const insReqCourseSupport = ref([
  {
    stepId: "1",
    step: "As shown below, on the home page, click on <b>“My Classes”</b> on the left side to add a class/course.",
    image: "Picture1.png",
    widthClass: "w-80",
  },
  {
    stepId: "2",
    step: "Click on <b>“Add Class +”</b> as shown in the top right-hand corner in the image below. Then a pop-up shown in Step 3 will appear.",
    image: "Picture2.png",
    widthClass: "w-80",
  },
  {
    stepId: "3",
    step: "Complete the form that pops up as shown below and click on <b>“Add Class”</b>.",
    image: "Picture3.png",
    widthClass: "w-50",
  },
  {
    stepId: "4",
    step: "Click on <b>“Request Course-Support”</b> on the left side of the screen as shown below.",
    image: "Picture4.png",
    widthClass: "w-25",
  },
  {
    stepId: "5",
    step: "The web page shown below will appear, then click on “Create New +”.",
    image: "Picture5.png",
    widthClass: "w-80",
  },
  {
    stepId: "6",
    step: "The pop-up below will appear; click on the class/course you added in Step 3.",
    image: "Picture6.png",
    widthClass: "w-80",
  },
  {
    stepId: "7",
    step: "The web page below will appear. Please complete all the fields. Some of the fields are pre-populated from the information you supplied when you added a class. Remember to click <b>“Add“</b> for every field with the icon after you have put in the required details.",
    image: "Picture7.png",
    widthClass: "w-80",
  },
  {
    stepId: "8",
    step: "Please scroll down to complete all the fields. At the bottom of the web page click on the blue icon. Respond to the pop-ups and you will be returned to the home page as shown in Step 9 below.",
    image: "Picture8.png",
    widthClass: "w-80",
  },
  {
    stepId: "9",
    step: "Click on “View Recommendations”.",
    image: "Picture9.png",
    widthClass: "w-80",
  },
  {
    stepId: "10",
    step: "The web page will show you a list of recommended practitioners (about ten) that can meet the request you submitted. You can view details of each practitioner and select a maximum of three (3) out of the list.",
    image: "Picture10.png",
    widthClass: "w-80",
  },
  {
    stepId: "11",
    step: "After you have made your three (3) choices, click on <b>“Save Choices”</b>. Then respond to the pop-up and you will be redirected to the home page.",
    image: "Picture11.png",
    widthClass: "w-80",
  },
  {
    stepId: "12",
    step: "When one of the selected practitioners responds to your request, you will see this shown on your home page in addition to the notification in your email inbox.",
    image: "Picture12.png",
    widthClass: "w-80",
  },
  {
    stepId: "13",
    step: "Click on <b>“View Selected Practitioners”</b> to see the practitioner that accepted your request among those that you earlier selected.",
    image: "Picture13.png",
    widthClass: "w-80",
  },
  {
    stepId: "14",
    step: "See the image below",
    image: "Picture14.png",
    widthClass: "w-80",
  },
]);
const insReqResources = ref([
  {
    stepId: "1",
    step: "From the home page, click on <b>“Request Resources”</b> as shown below. Complete the fields shown on the screen and click on <b>“Request Resources”</b> to submit.",
    image: "Picture15.png",
  },
  {
    stepId: "2",
    step: "After you have submitted your request, it will appear on the screen as shown below.",
    image: "Picture16.png",
  },
  {
    stepId: "3",
    step: "When there is a response to your request, the screen will be as shown below. Click on <b>“View Response(s)”</b> to download what the practitioners as provided.",
    image: "Picture17.png",
  },
]);
const insUploadResources = ref([
  {
    stepId: "1",
    step: "Click on “Upload Resources” as shown.",
    image: "Picture18.png",
  },
  {
    stepId: "2",
    step: "Select the content type, type in a description of the content you are about to upload. Under “content visibility reach”, select the institution(s) you want to give access to the material you are about to upload. You can also directly type-in the email address of instructor(s) you want to share the material with.",
    image: "Picture19.png",
  },
  {
    stepId: "3",
    step: "You can drag and drop what you want to upload, or you can click on “Choose File” and you can select the file from your device. Another option (especially for large files) is that you can provide the website link or URL where the resource can be downloaded.",
    image: "Picture20.png",
  },
  {
    stepId: "4",
    step: "After you have chosen a file from device, check the two boxes as shown and click on <b>“Upload”</b>",
    image: "Picture21.png",
  },
]);

const pracResCourseSupport = ref([
  {
    stepId: "1",
    step: "When you have course-support requests from instructors that are directed to you, the home page will appear as shown below. Other requests from instructors that are not specifically directly to you but to which you can respond will be displayed under <b>“General Course-Support Requests”</b>.",
    image: "Picture1.png",
  },
  {
    stepId: "2",
    step: "To respond to any of the course-support requests directed to you, click on <b>“View Details”</b> as shown below.",
    image: "Picture2.png",
  },
  {
    stepId: "3",
    step: "The screen below will appear showing you the details of the request as well as some information about the instructor. After you have gone through the information on the screen, scroll down to accept or decline the request as shown below. To accept, click on <b>“Accept”</b>.",
    image: "Picture3.png",
  },
  {
    stepId: "4",
    step: "A pop-up shown below will appear. If the instructors provided multiple dates and time, you would see options under <b>“Time Preference”</b> and you can select one that you prefer. You can also type a message to the instructor in the text box provided. Finally click on <b>“Accept”</b>.",
    image: "Picture4.png",
  },
]);
const pracResResourceReq = ref([
  {
    stepId: "1",
    step: "Click on <b>“Request(s) for Resources”</b>. Instructors’ requests for resources will be displayed as shown below. Click on <b>“Respond”</b> if you are willing to provide what is requested.",
    image: "Picture5.png",
  },
  {
    stepId: "2",
    step: "You can drag and drop what you want to upload, or you can click on “Choose File” and you can select the file from your device. Another option (especially for large files) is that you can provide the website link or URL where the requested resource can be downloaded.",
    image: "Picture6.png",
  },
  {
    stepId: "3",
    step: "After you have chosen a file from device, check the two boxes as shown and click on <b>“Upload”</b>",
    image: "Picture7.png",
  },
]);
const pracUploadResources = ref([
  {
    stepId: "1",
    step: "Click on “Upload Resources” as shown.",
    image: "Picture8.png",
  },
  {
    stepId: "2",
    step: "Select the content type, type in a description of the content you are about to upload. Under “content visibility reach”, select the institution(s) you want to give access to the material you are about to upload. You can also directly type-in the email address of instructor(s) you want to share the material with and press “ENTER” on the keyboard.",
    image: "Picture9.png",
  },
  {
    stepId: "3",
    step: "You can drag and drop what you want to upload, or you can click on “Choose File” and you can select the file from your device. Another option (especially for large files) is that you can provide the website link or URL where the requested resource can be downloaded.",
    image: "Picture10.png",
  },
  {
    stepId: "4",
    step: "After you have chosen a file from device, check the two boxes as shown and click on <b>“Upload”</b>",
    image: "Picture11.png",
  },
]);

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(AppSidebar),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(BackButton),
        _cache[0] || (_cache[0] = _createElementVNode("h2", { class: "text-align-center font-bold" }, "User Guide", -1))
      ]),
      _createElementVNode("div", _hoisted_4, [
        _cache[25] || (_cache[25] = _createElementVNode("div", { class: "heading" }, "Overview", -1)),
        (_unref(AuthStore).role === 'INSTRUCTOR')
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _cache[5] || (_cache[5] = _createTextVNode(" Thank you for using ")),
              _createVNode(_component_router_link, { to: { name: 'home' } }, {
                default: _withCtx(() => _cache[1] || (_cache[1] = [
                  _createTextVNode("ConPEC")
                ])),
                _: 1
              }),
              _cache[6] || (_cache[6] = _createTextVNode(" . Here is a step-by-step guide on how to use the web platform. After you have signed up, verified your email address, and completed your profile, you can ")),
              _createElementVNode("ol", _hoisted_6, [
                _createElementVNode("li", null, [
                  _createVNode(_component_router_link, { to: "#section-one" }, {
                    default: _withCtx(() => _cache[2] || (_cache[2] = [
                      _createTextVNode(" request for course-support (site visits, seminar session or guest lecture) and/or, ")
                    ])),
                    _: 1
                  })
                ]),
                _createElementVNode("li", null, [
                  _createVNode(_component_router_link, { to: "#section-two" }, {
                    default: _withCtx(() => _cache[3] || (_cache[3] = [
                      _createTextVNode(" request for resources (project videos, pictures and documents) from practitioners. ")
                    ])),
                    _: 1
                  })
                ]),
                _createElementVNode("li", null, [
                  _createVNode(_component_router_link, { to: "#section-three" }, {
                    default: _withCtx(() => _cache[4] || (_cache[4] = [
                      _createTextVNode(" upload resources (project videos, pictures and documents) to share with other instructors ")
                    ])),
                    _: 1
                  })
                ])
              ]),
              _cache[7] || (_cache[7] = _createElementVNode("div", { class: "divider" }, null, -1)),
              _cache[8] || (_cache[8] = _createElementVNode("div", {
                class: "sub-heading",
                id: "section-one"
              }, " Steps to request for course-support ", -1)),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(insReqCourseSupport.value, (currStep) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: currStep.stepId,
                  class: "mb-3 step-instruction"
                }, [
                  _createElementVNode("span", _hoisted_7, "Step " + _toDisplayString(currStep.stepId) + ": ", 1),
                  _createElementVNode("span", {
                    innerHTML: currStep.step
                  }, null, 8, _hoisted_8),
                  _createElementVNode("img", {
                    src: 
                require(`@/assets/images/guide/instructor/${currStep.image}`)
              ,
                    class: _normalizeClass('guide-image mx-auto ' + currStep.widthClass)
                  }, null, 10, _hoisted_9)
                ]))
              }), 128)),
              _cache[9] || (_cache[9] = _createElementVNode("div", { class: "divider" }, null, -1)),
              _cache[10] || (_cache[10] = _createElementVNode("div", {
                class: "sub-heading",
                id: "section-two"
              }, " Steps to request for resources ", -1)),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(insReqResources.value, (currStep) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: currStep.stepId,
                  class: "mb-3 step-instruction"
                }, [
                  _createElementVNode("span", _hoisted_10, "Step " + _toDisplayString(currStep.stepId) + ": ", 1),
                  _createElementVNode("span", {
                    innerHTML: currStep.step
                  }, null, 8, _hoisted_11),
                  _createElementVNode("img", {
                    src: 
                require(`@/assets/images/guide/instructor/${currStep.image}`)
              ,
                    class: "guide-image mx-auto"
                  }, null, 8, _hoisted_12)
                ]))
              }), 128)),
              _cache[11] || (_cache[11] = _createElementVNode("div", { class: "divider" }, null, -1)),
              _cache[12] || (_cache[12] = _createElementVNode("div", {
                class: "sub-heading",
                id: "section-three"
              }, " Steps to upload resources ", -1)),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(insUploadResources.value, (currStep) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: currStep.stepId,
                  class: "mb-3 step-instruction"
                }, [
                  _createElementVNode("span", _hoisted_13, "Step " + _toDisplayString(currStep.stepId) + ": ", 1),
                  _createElementVNode("span", {
                    innerHTML: currStep.step
                  }, null, 8, _hoisted_14),
                  _createElementVNode("img", {
                    src: 
                require(`@/assets/images/guide/instructor/${currStep.image}`)
              ,
                    class: "guide-image mx-auto"
                  }, null, 8, _hoisted_15)
                ]))
              }), 128))
            ]))
          : (_unref(AuthStore).role === 'INDUSTRYPRACTITIONER')
            ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                _cache[17] || (_cache[17] = _createTextVNode(" Thank you for using ")),
                _createVNode(_component_router_link, { to: { name: 'home' } }, {
                  default: _withCtx(() => _cache[13] || (_cache[13] = [
                    _createTextVNode("ConPEC")
                  ])),
                  _: 1
                }),
                _cache[18] || (_cache[18] = _createTextVNode(" . Here is a step-by-step guide on how to use the web platform. After you have signed up, verified your email address, and completed your profile, and selected your availabilities, this guide will show you how to: ")),
                _createElementVNode("ol", _hoisted_17, [
                  _createElementVNode("li", null, [
                    _createVNode(_component_router_link, { to: "#section-one" }, {
                      default: _withCtx(() => _cache[14] || (_cache[14] = [
                        _createTextVNode(" respond to the course-support requests (e.g., guest lectures, site visits, seminars etc.) from instructors. ")
                      ])),
                      _: 1
                    })
                  ]),
                  _createElementVNode("li", null, [
                    _createVNode(_component_router_link, { to: "#section-two" }, {
                      default: _withCtx(() => _cache[15] || (_cache[15] = [
                        _createTextVNode(" respond to requests for resources (e.g., project pictures, documents, and videos) from instructors. ")
                      ])),
                      _: 1
                    })
                  ]),
                  _createElementVNode("li", null, [
                    _createVNode(_component_router_link, { to: "#section-three" }, {
                      default: _withCtx(() => _cache[16] || (_cache[16] = [
                        _createTextVNode(" upload resources (e.g., project pictures, documents, and videos) for instructors to use as instructional materials. ")
                      ])),
                      _: 1
                    })
                  ])
                ]),
                _cache[19] || (_cache[19] = _createElementVNode("div", { class: "divider" }, null, -1)),
                _cache[20] || (_cache[20] = _createElementVNode("div", {
                  class: "sub-heading",
                  id: "section-one"
                }, " Steps to respond to course-support request(s) from instructors ", -1)),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(pracResCourseSupport.value, (currStep) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: currStep.stepId,
                    class: "mb-3 step-instruction"
                  }, [
                    _createElementVNode("span", _hoisted_18, "Step " + _toDisplayString(currStep.stepId) + ": ", 1),
                    _createElementVNode("span", {
                      innerHTML: currStep.step
                    }, null, 8, _hoisted_19),
                    _createElementVNode("img", {
                      src: 
                require(`@/assets/images/guide/practitioner/${currStep.image}`)
              ,
                      class: "guide-image mx-auto"
                    }, null, 8, _hoisted_20)
                  ]))
                }), 128)),
                _cache[21] || (_cache[21] = _createElementVNode("div", { class: "divider" }, null, -1)),
                _cache[22] || (_cache[22] = _createElementVNode("div", {
                  class: "sub-heading",
                  id: "section-two"
                }, " Steps to respond to request(s) for resources from instructors ", -1)),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(pracResResourceReq.value, (currStep) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: currStep.stepId,
                    class: "mb-3 step-instruction"
                  }, [
                    _createElementVNode("span", _hoisted_21, "Step " + _toDisplayString(currStep.stepId) + ": ", 1),
                    _createElementVNode("span", {
                      innerHTML: currStep.step
                    }, null, 8, _hoisted_22),
                    _createElementVNode("img", {
                      src: 
                require(`@/assets/images/guide/practitioner/${currStep.image}`)
              ,
                      class: "guide-image mx-auto"
                    }, null, 8, _hoisted_23)
                  ]))
                }), 128)),
                _cache[23] || (_cache[23] = _createElementVNode("div", { class: "divider" }, null, -1)),
                _cache[24] || (_cache[24] = _createElementVNode("div", {
                  class: "sub-heading",
                  id: "section-three"
                }, " Steps to upload resources ", -1)),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(pracUploadResources.value, (currStep) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: currStep.stepId,
                    class: "mb-3 step-instruction"
                  }, [
                    _createElementVNode("span", _hoisted_24, "Step " + _toDisplayString(currStep.stepId) + ": ", 1),
                    _createElementVNode("span", {
                      innerHTML: currStep.step
                    }, null, 8, _hoisted_25),
                    _createElementVNode("img", {
                      src: 
                require(`@/assets/images/guide/practitioner/${currStep.image}`)
              ,
                      class: "guide-image mx-auto"
                    }, null, 8, _hoisted_26)
                  ]))
                }), 128))
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_27))
      ])
    ])
  ]))
}
}

})