<script setup lang="ts">
import { ref } from "vue";
import * as yup from "yup";
import { Form, Field, ErrorMessage } from "vee-validate";
import Multiselect from "@vueform/multiselect";
import InstructorSidebar from "@/components/instructor/InstructorSidebar.vue";
import BackButton from "@/components/user/BackButton.vue";
import UserService from "@/services/user.service.js";
import { useConfigStore } from "@/stores/ConfigStore";
import { useAuthStore } from "@/stores/AuthStore";
import { useRouter } from "vue-router";
import {
  showInfoToast,
  showToast,
  univOptions,
  deptOptions,
  ageRangeOptions,
  yearsOfExpOptions,
  pronounsOptions,
  instructorTitleOptions,
  insTypeOptions,
  genderOptions,
  ethnicityOptions,
  stateOptions,
  showErrorToast,
} from "@/utils";

const ConfigStore = useConfigStore();
const AuthStore = useAuthStore();
const router = useRouter();

const univOptionsSorted = univOptions.sort();
const univOptionsAdded = [...univOptionsSorted, "Others"];
const profileFields = ConfigStore.config.filter(
  (el) => el.table === "instructor_profile"
);

const schema = yup.object().shape({
  fields: yup.array().default(profileFields),
  title: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "title")?.disabled,
    then: (schema) => schema.required("Title is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  title_text: yup.string().when("title", {
    is: (val) => val === "Others",
    then: (schema) => schema.required("Title is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  firstName: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "firstName")?.disabled,
    then: (schema) => schema.required("First Name is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  lastName: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "lastName")?.disabled,
    then: (schema) => schema.required("Last Name is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  nameOrg: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "nameOrg")?.disabled,
    then: (schema) => schema.required("Name of Institution is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  city: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "city")?.disabled,
    then: (schema) => schema.required("City is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  state: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "state")?.disabled,
    then: (schema) => schema.required("State is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  department: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "department")?.disabled,
    then: (schema) => schema.required("Department is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  insType: yup.string().optional().nullable(),
  ageRange: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "ageRange")?.disabled,
    then: (schema) => schema.required("Age Range is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  yearsOfExp: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "yearsOfExp")?.disabled,
    then: (schema) => schema.required("Years of Experience is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  pronouns: yup.string().optional().nullable(),
  pronouns_text: yup.string().when("pronouns", {
    is: (val) => val === "Others",
    then: (schema) => schema.required("Pronouns is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  ethnicity: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "ethnicity")?.disabled,
    then: (schema) => schema.required("Racial Identity is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  ethnicity_text: yup.string().when("ethnicity", {
    is: (val) => val === "Others",
    then: (schema) => schema.required("Racial Identity is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  gender: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "gender")?.disabled,
    then: (schema) => schema.required("Gender Identity is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  gender_text: yup.string().when("gender", {
    is: (val) => val === "Others",
    then: (schema) => schema.required("Gender Identity is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  parkingArrangement: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "parkingArrangement")?.disabled,
    then: (schema) => schema.required("Parking Information is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  parkingAddress: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "parkingAddress")?.disabled,
    then: (schema) =>
      schema
        .required("Street Address of Institution is required!")
        .max(254, "Street Address cannot be greater than 255 characters"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  officeAddress: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "officeAddress")?.disabled,
    then: (schema) =>
      schema
        .required("Office Address is required!")
        .max(254, "Office Address cannot be greater than 255 characters"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
});

const currentUser = AuthStore.auth;

let initialState = {
  city: "",
  state: "",
  department: "",
  department_text: "",
  // physicalDis: [],
  ageRange: "",
  insType: "",
  insType_text: "",
  title: "",
  title_text: "",
  firstName: "",
  lastName: "",
  nameOrg: "",
  nameOrg_text: "",
  yearsOfExp: "",
  pronouns: "",
  pronouns_text: "",
  gender: "",
  gender_text: "",
  ethnicity: "",
  ethnicity_text: "",
  parkingArrangement: "",
  parkingAddress: "",
  officeAddress: "",
};

if (AuthStore.profile) {
  initialState.city = AuthStore.auth.profile.city;
  initialState.state = AuthStore.auth.profile.state;
  if (
    !deptOptions.some((el) => el.value === AuthStore.auth.profile.department)
  ) {
    initialState.department_text = AuthStore.auth.profile.department;
    initialState.department = "Others";
  } else {
    initialState.department = AuthStore.auth.profile.department;
  }
  // initialState.physicalDis = JSON.parse(AuthStore.auth.profile.physicalDis);
  initialState.ageRange = AuthStore.auth.profile.ageRange;
  if (
    !insTypeOptions.some((el) => el.value === AuthStore.auth.profile.insType)
  ) {
    initialState.insType_text = AuthStore.auth.profile.insType;
    initialState.insType = "Others";
  } else {
    initialState.insType = AuthStore.auth.profile.insType;
  }
  initialState.parkingArrangement = AuthStore.auth.profile.parkingArrangement;
  initialState.parkingAddress = AuthStore.auth.profile.parkingAddress;
  initialState.officeAddress = AuthStore.auth.profile.officeAddress;
  if (!instructorTitleOptions.some((el) => el.value === AuthStore.auth.title)) {
    initialState.title_text = AuthStore.auth.title;
    initialState.title = "Others";
  } else {
    initialState.title = AuthStore.auth.title;
  }
  initialState.firstName = AuthStore.auth.firstName;
  initialState.lastName = AuthStore.auth.lastName;
  if (!univOptions.some((el) => el === AuthStore.auth.nameOrg)) {
    initialState.nameOrg_text = AuthStore.auth.nameOrg;
    initialState.nameOrg = "Others";
  } else {
    initialState.nameOrg = AuthStore.auth.nameOrg;
  }
  initialState.yearsOfExp = AuthStore.auth.profile.yearsOfExp;
  if (
    !pronounsOptions.some((el) => el.value === AuthStore.auth.profile.pronouns)
  ) {
    initialState.pronouns_text = AuthStore.auth.profile.pronouns;
    initialState.pronouns = "Others";
  } else {
    initialState.pronouns = AuthStore.auth.profile.pronouns;
  }
  if (!ethnicityOptions.some((el) => el.value === AuthStore.auth.ethnicity)) {
    initialState.ethnicity_text = AuthStore.auth.ethnicity;
    initialState.ethnicity = "Others";
  } else {
    initialState.ethnicity = AuthStore.auth.ethnicity;
  }
  if (!genderOptions.some((el) => el.value === AuthStore.auth.gender)) {
    initialState.gender_text = AuthStore.auth.gender;
    initialState.gender = "Others";
  } else {
    initialState.gender = AuthStore.auth.gender;
  }
} else {
  initialState.title = AuthStore.auth.title;
  initialState.firstName = AuthStore.auth.firstName;
  initialState.lastName = AuthStore.auth.lastName;
}

const formValues = {
  city: initialState.city,
  state: initialState.state,
  department: initialState.department,
  department_text: initialState.department_text,
  // physicalDis: initialState.physicalDis,
  ageRange: initialState.ageRange,
  insType: initialState.insType,
  insType_text: initialState.insType_text,
  title: initialState.title,
  title_text: initialState.title_text,
  firstName: initialState.firstName,
  lastName: initialState.lastName,
  nameOrg: initialState.nameOrg,
  nameOrg_text: initialState.nameOrg_text,
  yearsOfExp: initialState.yearsOfExp,
  pronouns: initialState.pronouns,
  pronouns_text: initialState.pronouns_text,
  gender: initialState.gender,
  gender_text: initialState.gender_text,
  ethnicity: initialState.ethnicity,
  ethnicity_text: initialState.ethnicity_text,
  parkingArrangement: initialState.parkingArrangement,
  parkingAddress: initialState.parkingAddress,
  officeAddress: initialState.officeAddress,
};

let loading = ref(false);
let successful = ref(false);
let message = ref("");

const saveProfile = (data) => {
  loading.value = true;
  if (data.title === "Others") data.title = data["title_text"];
  if (data.nameOrg === "Others") data.nameOrg = data["nameOrg_text"];
  if (data.department === "Others") data.department = data["department_text"];
  if (data.insType === "Others") data.insType = data["insType_text"];
  if (data.gender === "Others") data.gender = data["gender_text"];
  if (data.ethnicity === "Others") data.ethnicity = data["ethnicity_text"];
  if (data.pronouns === "Others") data.pronouns = data["pronouns_text"];

  AuthStore.saveProfile(data).then(
    (data) => {
      AuthStore.getProfile().then(
        () => {
          showToast("Your profile has been updated.");
          router.push({ name: "instructor-feed" });
          loading.value = false;
          successful.value = true;
        },
        (err) => {
          loading.value = false;
          successful.value = false;
          message.value =
            (err.response && err.response.data && err.response.data.message) ||
            err.message ||
            err.toString();
        }
      );
    },
    (error) => {
      loading.value = false;
      successful.value = false;
      message.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};

const onInvalidSubmit = ({ errors }) => {
  Object.values(errors).forEach((error) => showErrorToast(error));
};

const cityOptions = ref([] as any[]);

const getCityOptions = (state) => {
  UserService.getCities(state).then(
    (response) => {
      cityOptions.value = response.data;
    },
    (error) => {
      console.log(error);
      message.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};

getCityOptions(initialState.state);
</script>

<style scoped>
.card-container.card {
  max-width: 95% !important;
  padding: 20px 40px 40px;
}

.form-grid {
  border-radius: 8px;
}

.card {
  margin: 0 5px 25px 5px;
}

@media screen and (max-width: 768px) {
  .card-container.card {
    padding: 10px 10px;
  }
}
</style>

<template>
  <div class="grid-container">
    <instructor-sidebar></instructor-sidebar>
    <div class="col-md-12 mt-3 min-height">
      <div class="back-grid">
        <back-button></back-button>
        <h2 style="text-align: center; font-weight: bold">
          Instructor's Profile
        </h2>
      </div>
      <div>
        <br />
        <span class="font-color-red ml-3">* required field</span>
        <div>
          <Form
            @submit="saveProfile"
            @invalid-submit="onInvalidSubmit"
            v-slot="{ values }"
            :validation-schema="schema"
            :initial-values="formValues"
          >
            <div class="form-grid flex flex-direction-column lg:flex-row">
              <div class="card card-container flex-basis-0">
                <h4 class="text-align-center bold-text">
                  Personal Information
                </h4>
                <div
                  class="form-group"
                  v-if="
                    !profileFields.find((el) => el.name === 'title')?.disabled
                  "
                >
                  <label for="title"
                    >Title<span class="super font-color-red">*</span>
                  </label>
                  <Field
                    v-model="values.title"
                    name="title"
                    type="text"
                    class="dropdown"
                    v-slot="{ field }"
                  >
                    <multiselect
                      v-bind="field"
                      v-model="values.title"
                      :options="instructorTitleOptions"
                      mode="single"
                      :searchable="true"
                    />
                  </Field>
                  <div v-if="values.title === 'Others'">
                    <Field
                      name="title_text"
                      type="text"
                      placeholder="Please Specify"
                      class="form-control bg-others"
                    />
                  </div>
                  <ErrorMessage name="title" class="error-feedback" />
                </div>
                <div class="form-column">
                  <div
                    class="form-group"
                    v-if="
                      !profileFields.find((el) => el.name === 'firstName')
                        ?.disabled
                    "
                  >
                    <label for="firstName"
                      >First Name<span class="super font-color-red">*</span>
                    </label>
                    <Field name="firstName" type="text" class="form-control" />
                    <ErrorMessage name="firstName" class="error-feedback" />
                  </div>
                  <div
                    class="form-group"
                    v-if="
                      !profileFields.find((el) => el.name === 'lastName')
                        ?.disabled
                    "
                  >
                    <label for="lastName"
                      >Last Name<span class="super font-color-red">*</span>
                    </label>
                    <Field name="lastName" type="text" class="form-control" />
                    <ErrorMessage name="lastName" class="error-feedback" />
                  </div>
                </div>

                <div class="form-column">
                  <div
                    class="form-group"
                    v-if="
                      !profileFields.find((el) => el.name === 'gender')
                        ?.disabled
                    "
                  >
                    <label for="gender"
                      >Gender Identity<span class="super font-color-red"
                        >*</span
                      >
                      <font-awesome-icon
                        icon="fa-solid fa-lock"
                        class="ml-1"
                        title="This information is private."
                      />
                    </label>
                    <Field
                      v-model="values.gender"
                      name="gender"
                      type="text"
                      class="dropdown"
                      v-slot="{ field }"
                    >
                      <multiselect
                        v-bind="field"
                        v-model="values.gender"
                        :options="genderOptions"
                        mode="single"
                        :searchable="true"
                      />
                    </Field>
                    <div v-if="values.gender === 'Others'">
                      <Field
                        name="gender_text"
                        type="text"
                        placeholder="Please Specify"
                        class="form-control bg-others"
                      />
                    </div>
                    <ErrorMessage name="gender" class="error-feedback" />
                  </div>

                  <div
                    class="form-group"
                    v-if="
                      !profileFields.find((el) => el.name === 'ethnicity')
                        ?.disabled
                    "
                  >
                    <label for="ethnicity"
                      >Racial Identity<span class="super font-color-red"
                        >*</span
                      >
                      <font-awesome-icon
                        icon="fa-solid fa-lock"
                        class="ml-1"
                        title="This information is private."
                      />
                    </label>
                    <Field
                      v-model="values.ethnicity"
                      name="ethnicity"
                      type="text"
                      class="dropdown"
                      v-slot="{ field }"
                    >
                      <multiselect
                        v-bind="field"
                        v-model="values.ethnicity"
                        :options="ethnicityOptions"
                        mode="single"
                        :searchable="true"
                      />
                    </Field>
                    <div v-if="values.ethnicity === 'Others'">
                      <Field
                        name="ethnicity_text"
                        type="text"
                        placeholder="Please Specify"
                        class="form-control bg-others"
                      />
                    </div>
                    <ErrorMessage name="ethnicity" class="error-feedback" />
                  </div>
                </div>

                <div class="form-column">
                  <div
                    class="form-group"
                    v-if="
                      !profileFields.find((el) => el.name === 'ageRange')
                        ?.disabled
                    "
                  >
                    <label for="ageRange"
                      >Age Range<span class="super font-color-red">*</span>
                      <font-awesome-icon
                        icon="fa-solid fa-lock"
                        class="ml-1"
                        title="This information is private."
                      />
                    </label>
                    <Field
                      v-model="values.ageRange"
                      name="ageRange"
                      type="text"
                      class="dropdown"
                      v-slot="{ field }"
                    >
                      <multiselect
                        v-bind="field"
                        v-model="values.ageRange"
                        :options="ageRangeOptions"
                        mode="single"
                        :searchable="true"
                      />
                    </Field>
                    <ErrorMessage name="ageRange" class="error-feedback" />
                  </div>
                  <div
                    class="form-group"
                    v-if="
                      !profileFields.find((el) => el.name === 'yearsOfExp')
                        ?.disabled
                    "
                  >
                    <label for="yearsOfExp"
                      >Years of Faculty Experience<span
                        class="super font-color-red"
                        >*</span
                      >
                      <font-awesome-icon
                        icon="fa-solid fa-lock"
                        class="ml-1"
                        title="This information is private."
                      />
                    </label>
                    <Field
                      v-model="values.yearsOfExp"
                      name="yearsOfExp"
                      type="text"
                      class="dropdown"
                      v-slot="{ field }"
                    >
                      <multiselect
                        v-bind="field"
                        v-model="values.yearsOfExp"
                        :options="yearsOfExpOptions"
                        mode="single"
                        :searchable="true"
                      />
                    </Field>
                    <ErrorMessage name="yearsOfExp" class="error-feedback" />
                  </div>
                </div>
                <div
                  class="form-group"
                  v-if="
                    !profileFields.find((el) => el.name === 'pronouns')
                      ?.disabled
                  "
                >
                  <label for="pronouns">Pronouns </label>
                  <Field
                    v-model="values.pronouns"
                    name="pronouns"
                    type="text"
                    class="dropdown"
                    v-slot="{ field }"
                  >
                    <multiselect
                      v-bind="field"
                      v-model="values.pronouns"
                      :options="pronounsOptions"
                      mode="single"
                      :searchable="true"
                    />
                  </Field>
                  <div v-if="values.pronouns === 'Others'">
                    <Field
                      name="pronouns_text"
                      type="text"
                      placeholder="Please Specify"
                      class="form-control bg-others"
                    />
                  </div>
                  <ErrorMessage name="pronouns" class="error-feedback" />
                </div>
                <div
                  class="form-group"
                  v-if="
                    !profileFields.find((el) => el.name === 'officeAddress')
                      ?.disabled
                  "
                >
                  <label for="officeAddress"
                    >Office Address<span class="super font-color-red">*</span>
                  </label>
                  <Field
                    name="officeAddress"
                    type="text"
                    class="form-control"
                  />
                  <ErrorMessage name="officeAddress" class="error-feedback" />
                </div>
              </div>
              <div class="card card-container flex-basis-0">
                <h4 class="text-align-center bold-text">
                  Institution Information
                </h4>
                <div
                  class="form-group"
                  v-if="
                    !profileFields.find((el) => el.name === 'insType')?.disabled
                  "
                >
                  <label for="insType"
                    >Type of Institution
                    <font-awesome-icon
                      icon="fa-solid fa-lock"
                      class="ml-1"
                      title="This information is private."
                    />
                  </label>
                  <Field
                    v-model="values.insType"
                    name="insType"
                    type="text"
                    class="dropdown"
                    v-slot="{ field }"
                  >
                    <multiselect
                      v-bind="field"
                      v-model="values.insType"
                      :options="insTypeOptions"
                      mode="single"
                      :searchable="true"
                    />
                  </Field>
                  <div v-if="values.insType === 'Others'">
                    <Field
                      name="insType_text"
                      type="text"
                      placeholder="Please Specify"
                      class="form-control bg-others"
                    />
                  </div>
                </div>

                <div
                  class="form-group"
                  v-if="
                    !profileFields.find((el) => el.name === 'nameOrg')?.disabled
                  "
                >
                  <label for="nameOrg"
                    >Name of Institution<span class="super font-color-red"
                      >*</span
                    >
                  </label>
                  <Field
                    v-model="values.nameOrg"
                    name="nameOrg"
                    type="text"
                    class="dropdown"
                    v-slot="{ field }"
                  >
                    <multiselect
                      v-bind="field"
                      v-model="values.nameOrg"
                      mode="single"
                      :options="univOptionsAdded"
                      :searchable="true"
                    />
                  </Field>
                  <div v-if="values.nameOrg === 'Others'">
                    <Field
                      name="nameOrg_text"
                      type="text"
                      placeholder="Please Specify"
                      class="form-control bg-others"
                    />
                  </div>
                  <ErrorMessage name="nameOrg" class="error-feedback" />
                </div>

                <div class="form-group">
                  <label for="city"
                    >Location<span v-if="currentUser.nameOrg">
                      of {{ currentUser.nameOrg }}</span
                    ><span class="super font-color-red">*</span>
                  </label>
                  <div class="flex flex-direction-row gap-1">
                    <div
                      style="flex-basis: 0"
                      v-if="
                        !profileFields.find((el) => el.name === 'state')
                          ?.disabled
                      "
                    >
                      <Field
                        v-model="values.state"
                        name="state"
                        type="text"
                        class="dropdown"
                        v-slot="{ field }"
                      >
                        <multiselect
                          v-bind="field"
                          v-model="values.state"
                          :options="stateOptions"
                          mode="single"
                          placeholder="Select State"
                          :searchable="true"
                          @change="
                            getCityOptions(values.state), (values.city = '')
                          "
                        />
                      </Field>
                      <ErrorMessage name="state" class="error-feedback" />
                    </div>
                    <div
                      style="flex-basis: 0"
                      v-if="
                        !profileFields.find((el) => el.name === 'city')
                          ?.disabled
                      "
                    >
                      <Field
                        v-model="values.city"
                        name="city"
                        type="text"
                        class="dropdown"
                        v-slot="{ field }"
                      >
                        <multiselect
                          v-bind="field"
                          v-model="values.city"
                          :options="cityOptions"
                          mode="single"
                          :searchable="true"
                        />
                      </Field>
                      <ErrorMessage name="city" class="error-feedback" />
                    </div>
                  </div>
                  <div
                    v-if="
                      !profileFields.find((el) => el.name === 'parkingAddress')
                        ?.disabled
                    "
                  >
                    <Field
                      name="parkingAddress"
                      type="text"
                      placeholder="Street Address"
                      class="form-control"
                    />
                    <ErrorMessage
                      name="parkingAddress"
                      class="error-feedback"
                    />
                  </div>
                </div>

                <div
                  class="form-group"
                  v-if="
                    !profileFields.find(
                      (el) => el.name === 'parkingArrangement'
                    )?.disabled
                  "
                >
                  <label for="parkingArrangement"
                    >Will there be parking arrangement for practitioners
                    visiting your institution?<span class="super font-color-red"
                      >*</span
                    >
                  </label>
                  <Field
                    v-model="values.parkingArrangement"
                    name="parkingArrangement"
                    type="text"
                    class="dropdown"
                    v-slot="{ field }"
                  >
                    <multiselect
                      v-bind="field"
                      v-model="values.parkingArrangement"
                      :options="['Yes', 'No']"
                      :searchable="true"
                      mode="single"
                    />
                  </Field>
                  <ErrorMessage
                    name="parkingArrangement"
                    class="error-feedback"
                  />
                  <ErrorMessage name="parkingAddress" class="error-feedback" />
                </div>

                <div
                  class="form-group"
                  v-if="
                    !profileFields.find((el) => el.name === 'department')
                      ?.disabled
                  "
                >
                  <label for="department"
                    >Department/Program<span v-if="currentUser.nameOrg">
                      in {{ currentUser.nameOrg }}</span
                    >
                    <span class="super font-color-red">*</span>
                  </label>
                  <Field
                    v-model="values.department"
                    name="department"
                    type="text"
                    class="dropdown"
                    v-slot="{ field }"
                  >
                    <multiselect
                      v-bind="field"
                      v-model="values.department"
                      :options="deptOptions"
                      :searchable="true"
                      mode="single"
                    />
                  </Field>
                  <div v-if="values.department === 'Others'">
                    <Field
                      name="department_text"
                      type="text"
                      placeholder="Please Specify"
                      class="form-control bg-others"
                    />
                  </div>
                  <ErrorMessage name="department" class="error-feedback" />
                </div>
              </div>
            </div>

            <br />
            <div class="form-group text-align-center">
              <button class="btn btn-primary btn-block" :disabled="loading">
                <span
                  v-show="loading"
                  class="spinner-border spinner-border-sm"
                ></span>
                <span>Save Profile</span>
              </button>
            </div>
          </Form>
        </div>
        <div
          v-if="message"
          :class="successful ? 'alert-success' : 'alert-danger'"
        >
          {{ message }}
        </div>
      </div>
    </div>
  </div>
</template>
