import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives } from "vue"
import _imports_0 from '@/assets/images/site/faq.jpg'
import _imports_1 from '@/assets/images/logo.png'


const _hoisted_1 = { class: "m-3 lg:m-5 lg:ml-0 min-height header-space" }
const _hoisted_2 = { class: "mt-5 flex flex-row" }
const _hoisted_3 = { class: "mt-3" }
const _hoisted_4 = { class: "answer" }
const _hoisted_5 = { class: "answer" }
const _hoisted_6 = { class: "form-group text-align-center mt-3" }
const _hoisted_7 = ["disabled"]
const _hoisted_8 = { class: "spinner-border spinner-border-sm" }

import { ref } from "vue";
import { Form, Field, ErrorMessage, useForm } from "vee-validate";
import * as yup from "yup";
import UserService from "@/services/user.service.js";
import { showToast } from "@/utils";
import { FAQ } from "@/types";


export default /*@__PURE__*/_defineComponent({
  __name: 'FAQView',
  setup(__props) {

const values = ref([
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
]);
let loading = ref(false);
let successful = ref(false);
let message = ref("");
let content = ref([] as FAQ[]);

const schema = yup.object().shape({
  userQuestion: yup
    .string()
    .trim()
    .required("This is a required field!")
    .min(1, "This is a required field!")
    .max(1000, "Question cannot be more than 1000 characters!"),
});

const getFAQ = () => {
  UserService.getFAQ().then(
    (response) => {
      console.log(response.data);
      content.value = response.data;
      console.log(values.value.length);
      for (let i = 0; i < content.value.length; i++) values.value.push(false);
      console.log(values.value.length);
    },
    (error) => {
      message.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};

const handleSubmission = (data, { resetForm }) => {
  loading.value = true;
  UserService.saveFAQ(data).then(
    (_) => {
      successful.value = true;
      loading.value = false;
      showToast("Question submitted successfully.");
      resetForm();
    },
    (error) => {
      message.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      successful.value = false;
      loading.value = false;
    }
  );
};

getFAQ();

return (_ctx: any,_cache: any) => {
  const _component_va_collapse = _resolveComponent("va-collapse")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[27] || (_cache[27] = _createElementVNode("div", { class: "text-align-center" }, [
      _createElementVNode("img", {
        src: _imports_0,
        class: "site-image mx-auto",
        alt: "Frequently Asked Questions"
      })
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      _cache[26] || (_cache[26] = _createElementVNode("div", { class: "logo-container flex-grow-1 flex-shrink-0" }, [
        _createElementVNode("img", {
          id: "profile-img",
          class: "m-auto",
          src: _imports_1
        }),
        _createElementVNode("p", {
          style: {"font-size":"1.5rem"},
          class: "text-align-center"
        }, " ...connecting professional and educational communities for future workforce development. ")
      ], -1)),
      _createElementVNode("div", null, [
        _cache[23] || (_cache[23] = _createElementVNode("h1", null, "Frequently Asked Questions", -1)),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_va_collapse, {
            modelValue: values.value[0],
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((values.value[0]) = $event)),
            class: "w-96 my-3",
            header: "What is ConPEC?"
          }, {
            default: _withCtx(() => _cache[10] || (_cache[10] = [
              _createElementVNode("div", { class: "answer" }, " ConPEC is a collaborative networking platform for instructors and practitioners to collaborate for future workforce development. The web-based platform provides instructors with easy access to diverse pool of practitioners who are willing and able to support their pedagogical efforts. ", -1)
            ])),
            _: 1
          }, 8, ["modelValue"]),
          _createVNode(_component_va_collapse, {
            modelValue: values.value[1],
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((values.value[1]) = $event)),
            class: "w-96 my-3",
            header: "Who are the people behind or funding ConPEC?"
          }, {
            default: _withCtx(() => _cache[11] || (_cache[11] = [
              _createElementVNode("div", { class: "answer" }, " ConPEC is being funded via a National Science Foundation grant targeted at intelligently preparing the future construction engineering workforce for the workplace by connecting the professional and educational communities. The research is being conducted by Virginia Tech and North Carolina Agricultural and Technical State University. ", -1)
            ])),
            _: 1
          }, 8, ["modelValue"]),
          _createVNode(_component_va_collapse, {
            modelValue: values.value[2],
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((values.value[2]) = $event)),
            class: "w-96 my-3",
            header: "Who is ConPEC designed for?"
          }, {
            default: _withCtx(() => _cache[12] || (_cache[12] = [
              _createElementVNode("div", { class: "answer" }, " ConPEC is designed for instructors in construction related programs and construction industry practitioners. The construction-related programs include Building Construction, Civil and Environmental Engineering, Construction Management, Construction Engineering and Management and other similar academic programs. These two categories of users are the only users expected to use the platform. ", -1)
            ])),
            _: 1
          }, 8, ["modelValue"]),
          _createVNode(_component_va_collapse, {
            modelValue: values.value[3],
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((values.value[3]) = $event)),
            class: "w-96 my-3",
            header: "Who are the instructors on ConPEC?"
          }, {
            default: _withCtx(() => _cache[13] || (_cache[13] = [
              _createElementVNode("div", { class: "answer" }, " Instructors are professors and faculty members in different construction-related academic programs. ", -1)
            ])),
            _: 1
          }, 8, ["modelValue"]),
          _createVNode(_component_va_collapse, {
            modelValue: values.value[4],
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((values.value[4]) = $event)),
            class: "w-96 my-3",
            header: "Who are practitioners on ConPEC?"
          }, {
            default: _withCtx(() => _cache[14] || (_cache[14] = [
              _createElementVNode("div", { class: "answer" }, " Practitioners are construction professionals, construction workers and allied professionals. ", -1)
            ])),
            _: 1
          }, 8, ["modelValue"]),
          _createVNode(_component_va_collapse, {
            modelValue: values.value[5],
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((values.value[5]) = $event)),
            class: "w-96 my-3",
            header: "Why can’t I use my Gmail or Yahoo mail address to sign up?"
          }, {
            default: _withCtx(() => _cache[15] || (_cache[15] = [
              _createElementVNode("div", { class: "answer" }, " As a way of ensuring that the people who use the platform are the actual intended users, we require instructors to use their institutional email addresses and practitioners to use their work/company email addresses. The following email domains are not permitted: \"gmail.com\", \"yahoo.com\", \"outlook.com\", \"hotmail.com\", \"aol.com\", 'hotmail.co.uk', \"hotmail.fr\", \"msn.com\", \"comcast.net\", \"live.com\", and \"verizon.net\". ", -1)
            ])),
            _: 1
          }, 8, ["modelValue"]),
          _createVNode(_component_va_collapse, {
            modelValue: values.value[6],
            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((values.value[6]) = $event)),
            class: "w-96 my-3",
            header: "What is ConPEC designed to achieve?"
          }, {
            default: _withCtx(() => _cache[16] || (_cache[16] = [
              _createElementVNode("div", { class: "answer" }, " ConPEC is ultimately designed for future workforce development. This is achieved by providing instructors equitable access to practitioners willing to meet instructors’ course-support needs. The connection between instructors and practitioners would ensure that students interact more with their communities of practice, which could help discipline their perception and develop their professional identities. This could also help ensure an adequate blend of theory and practice and develop the necessary skills and competencies which are industry requirements. ", -1)
            ])),
            _: 1
          }, 8, ["modelValue"]),
          _createVNode(_component_va_collapse, {
            modelValue: values.value[7],
            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((values.value[7]) = $event)),
            class: "w-96 my-3",
            header: "How can I contribute to the mission of ConPEC?"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_4, [
                _cache[18] || (_cache[18] = _createTextVNode(" You can contribute to this effort in various ways. As an instructor, you can contribute by using the platform to connect with practitioners and inviting people (instructors and practitioners) to also use the platform. As a practitioner, you can contribute by using the platform to engage with instructors to contribute to student development. You can also tell other people about ConPEC and encourage them to do the same. You can also contribute by contacting us ")),
                _createVNode(_component_router_link, { to: { name: 'contact-us' } }, {
                  default: _withCtx(() => _cache[17] || (_cache[17] = [
                    _createTextVNode(" here")
                  ])),
                  _: 1
                }),
                _cache[19] || (_cache[19] = _createTextVNode(" to provide feedback, comments, and suggestions to improve the platform. "))
              ])
            ]),
            _: 1
          }, 8, ["modelValue"]),
          _createVNode(_component_va_collapse, {
            modelValue: values.value[8],
            "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((values.value[8]) = $event)),
            class: "w-96 my-3",
            header: "What does course-support mean?"
          }, {
            default: _withCtx(() => _cache[20] || (_cache[20] = [
              _createElementVNode("div", { class: "answer" }, " Course-support represents different ways by which practitioners can support instructors’ pedagogical efforts. These include facilitating site visits and laboratory sessions and serving as guest speakers for classroom teaching, workshops, and seminars. These also include serving as judge, mentor, and sponsor for term projects or capstone projects. These represent different avenues students can interact with practitioners and learn from them under the supervision of an instructor. ", -1)
            ])),
            _: 1
          }, 8, ["modelValue"]),
          _createVNode(_component_va_collapse, {
            modelValue: values.value[9],
            "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((values.value[9]) = $event)),
            class: "w-96 my-3",
            header: "How do you describe the different types of course-support?"
          }, {
            default: _withCtx(() => _cache[21] || (_cache[21] = [
              _createElementVNode("div", { class: "answer" }, [
                _createTextVNode(" Below is a brief description of different types of course-support. "),
                _createElementVNode("ul", null, [
                  _createElementVNode("li", null, [
                    _createElementVNode("b", null, "Site visit"),
                    _createTextVNode(" is a means to expose students to the workplace and work practices on construction job sites. ")
                  ]),
                  _createElementVNode("li", null, [
                    _createElementVNode("b", null, "Guest lecture"),
                    _createTextVNode(" entails bringing practitioners into the classroom to discuss technical topics from their experience. ")
                  ]),
                  _createElementVNode("li", null, [
                    _createElementVNode("b", null, "Seminar"),
                    _createTextVNode(" is for sharing knowledge and facilitating discussions on a specific topic or subject. It can also be an avenue to present their ideas and experiences to students. ")
                  ]),
                  _createElementVNode("li", null, [
                    _createElementVNode("b", null, "Workshop"),
                    _createTextVNode(" is an interactive learning and skill-building session to provide students with hands-on experience and practical training in a specific area or to develop certain skills. ")
                  ]),
                  _createElementVNode("li", null, [
                    _createElementVNode("b", null, "Laboratory session"),
                    _createTextVNode(" is a practical, hands-on learning experience that takes place in a laboratory where students get the opportunity to apply theoretical concepts to real-world construction tasks. ")
                  ]),
                  _createElementVNode("li", null, [
                    _createElementVNode("b", null, "Judge for a project:"),
                    _createTextVNode(" This involves assessing and appraising students’ projects based on established guidelines and client expectations. ")
                  ]),
                  _createElementVNode("li", null, [
                    _createElementVNode("b", null, "Mentor for project:"),
                    _createTextVNode(" This entails providing professional guidance to students throughout the delivery of their project. ")
                  ]),
                  _createElementVNode("li", null, [
                    _createElementVNode("b", null, "Sponsor for a project:"),
                    _createTextVNode(" This entails providing context for students’ projects and acting as a client. ")
                  ])
                ])
              ], -1)
            ])),
            _: 1
          }, 8, ["modelValue"]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(content), (faq, index) => {
            return (_openBlock(), _createBlock(_component_va_collapse, {
              modelValue: values.value[10 + index],
              "onUpdate:modelValue": ($event: any) => ((values.value[10 + index]) = $event),
              class: "w-96 my-3",
              key: faq.id,
              header: faq.userQuestion
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_5, _toDisplayString(faq.answer), 1)
              ]),
              _: 2
            }, 1032, ["modelValue", "onUpdate:modelValue", "header"]))
          }), 128))
        ]),
        _cache[24] || (_cache[24] = _createTextVNode(" Do you have any questions not addressed above? If yes, please submit it here.")),
        _cache[25] || (_cache[25] = _createElementVNode("span", { class: "super font-color-red" }, "*", -1)),
        _createVNode(_unref(Form), {
          onSubmit: handleSubmission,
          "validation-schema": _unref(schema)
        }, {
          default: _withCtx(() => [
            _createVNode(_unref(Field), {
              name: "userQuestion",
              class: "form-control",
              type: "text"
            }),
            _createVNode(_unref(ErrorMessage), {
              name: "userQuestion",
              class: "error-feedback"
            }),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("button", {
                class: "btn btn-primary btn-block",
                disabled: _unref(loading)
              }, [
                _withDirectives(_createElementVNode("span", _hoisted_8, null, 512), [
                  [_vShow, _unref(loading)]
                ]),
                _cache[22] || (_cache[22] = _createTextVNode(" Submit "))
              ], 8, _hoisted_7)
            ])
          ]),
          _: 1
        }, 8, ["validation-schema"])
      ])
    ])
  ]))
}
}

})