<script setup lang="ts">
import { useRouter } from "vue-router";
import { Field, useForm } from "vee-validate";
const router = useRouter();
const { handleSubmit } = useForm();

const search = handleSubmit((data) => {
  if (data.searchParameter.length > 0)
    router.push({ name: "search", params: { keyword: data.searchParameter } });
});
</script>

<style scoped>
.search-bar {
  margin: 0.5em 0 0.5em 0.5em;
  width: 20em;
  border: none;
  background-color: var(--primary-background-color);
  border-top-left-radius: 0.5em;
  border-bottom-left-radius: 0.5em;
}

.search-button {
  position: absolute;
  margin: 0.5em 0.5em 0.5em 0;
  border: none;
  color: black;
  background-color: var(--primary-background-color);
  border-top-right-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
}

.search-button:hover {
  color: black;
  background: rgba(15, 20, 25, 0.1);
}

@media (max-width: 768px) {
  .search-bar {
    padding: 0.5em;
    width: 10em;
    border: none;
  }
}
</style>

<template>
  <form @submit="search">
    <Field
      name="searchParameter"
      type="text"
      placeholder="Search ConPEC"
      class="search-bar"
    />
    <button type="submit" class="search-button button" title="Search">
      <font-awesome-icon icon="fa-solid fa-magnifying-glass" />
    </button>
  </form>
</template>
