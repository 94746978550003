<template>
  <Form
    @submit="saveOrganization"
    :validation-schema="schema"
    :initial-values="formValues"
  >
    <Field name="id" hidden />
    <div class="form-group">
      <label for="originalName"
        >Initial Organization name<span class="super font-color-red"
          >*</span
        ></label
      >
      <Field name="originalName" v-slot="{ field }">
        <input v-bind="field" class="form-control" type="text" disabled />
        <ErrorMessage name="originalName" class="error-feedback" />
      </Field>
    </div>
    <div class="form-group">
      <label for="name"
        >Organization name <span class="super font-color-red">*</span></label
      >
      <Field name="name" v-slot="{ field }">
        <input v-bind="field" class="form-control" type="text" />
        <ErrorMessage name="name" class="error-feedback" />
      </Field>
    </div>
    <div class="form-group">
      <div class="form-check-inline">
        <label for="display"
          >Display on Home Page<span class="super font-color-red"
            >*</span
          ></label
        >
        <Field
          name="display"
          class="form-check-input"
          type="radio"
          value="true"
        />Yes
      </div>
      <div class="form-check-inline ml-3">
        <Field
          name="display"
          class="form-check-input"
          type="radio"
          value="false"
        />No
      </div>
      <ErrorMessage name="display" class="error-feedback" />
    </div>
    <div class="form-group">
      <div class="form-check-inline">
        <label for="profileUpdate"
          >Update User Profile<span class="super font-color-red">*</span></label
        >
        <Field
          name="profileUpdate"
          class="form-check-input"
          type="radio"
          value="true"
        />Yes
      </div>
      <div class="form-check-inline ml-3">
        <Field
          name="profileUpdate"
          class="form-check-input"
          type="radio"
          value="false"
        />No
      </div>
      <ErrorMessage name="profileUpdate" class="error-feedback" />
    </div>
    <div class="form-group text-align-center">
      <button class="btn btn-primary btn-block" :disabled="loading">
        <span v-show="loading" class="spinner-border spinner-border-sm"></span>
        Save
      </button>
    </div>
  </Form>
</template>

<script setup lang="ts">
import { ref } from "vue";
import * as yup from "yup";
import { Form, Field, ErrorMessage } from "vee-validate";
import { FAQ, Organization } from "@/types";
import AdminService from "@/services/admin.service.js";
import { showToast } from "@/utils";

let loading = ref(false);
let message = ref("");

// eslint-disable-next-line no-undef
const props = defineProps<{
  organization: Organization;
}>();
// eslint-disable-next-line no-undef
const emits = defineEmits(["saveOrganization"]);

let initialState = {
  id: 0,
  name: "",
  originalName: "",
  status: "",
  display: false,
  profileUpdate: false,
};

const formValues = {
  id: props.organization?.id,
  name: props.organization?.name,
  originalName: props.organization?.originalName,
  display: props.organization?.display
    ? props.organization?.display.toString()
    : "",
  profileUpdate: props.organization?.profileUpdate
    ? props.organization?.profileUpdate.toString()
    : "",
};

const schema = yup.object().shape({
  name: yup
    .string()
    .required("Organization Name is required!")
    .max(255, "Organization Name cannot be greater than 255 characters"),
  display: yup.string().required("Display on Home Page is required!"),
  profileUpdate: yup.string().required("Update User Profile is required!"),
});

const saveOrganization = (values) => {
  AdminService.saveOrganization(values).then(
    (response) => {
      console.log(response);
      emits("saveOrganization");
      showToast("The Organization has been updated.");
    },
    (error) => {
      loading.value = false;
      message.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};
</script>

<style scoped></style>
