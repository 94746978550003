import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createTextVNode as _createTextVNode, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, withCtx as _withCtx, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@/assets/images/site/contact-us.jpg'
import _imports_1 from '@/assets/images/site/line.svg'


const _hoisted_1 = { class: "m-3 lg:m-5 min-height header-space" }
const _hoisted_2 = { class: "mb-1 lg:mb-3" }
const _hoisted_3 = { class: "card card-container" }
const _hoisted_4 = { class: "form-group" }
const _hoisted_5 = { class: "form-group" }
const _hoisted_6 = { class: "form-group" }
const _hoisted_7 = { class: "form-group" }
const _hoisted_8 = { class: "form-group" }
const _hoisted_9 = { class: "form-group text-align-center" }
const _hoisted_10 = ["disabled"]
const _hoisted_11 = { class: "spinner-border spinner-border-sm" }

import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import UserService from "../services/user.service.js";
import { showToast } from "@/utils";


export default /*@__PURE__*/_defineComponent({
  __name: 'ContactUsView',
  setup(__props) {

let loading = false;
let successful = false;
let message = "";

const schema = yup.object().shape({
  firstName: yup.string().required("First Name is required!"),
  lastName: yup.string().required("Last Name is required!"),
  nameOrg: yup.string().required("Institution/Company Name is required!"),
  email: yup.string().required("Email is required!").email("Email is invalid!"),
  message: yup
    .string()
    .required("Message is required!")
    .min(10, "Must be at least 10 characters!")
    .max(500, "Must be maximum 500 characters!"),
});

const handleSubmission = (data, { resetForm }) => {
  loading = true;

  UserService.saveContactUsInfo(data).then(
    (data) => {
      message = data.message;
      successful = true;
      loading = false;
      showToast(
        "Query raised successfully. Our team will contact you within 2 days"
      );

      resetForm();
    },
    (error) => {
      message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      successful = false;
      loading = false;
    }
  );
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[9] || (_cache[9] = _createElementVNode("div", { class: "text-align-center" }, [
      _createElementVNode("img", {
        src: _imports_0,
        class: "site-image mx-auto",
        alt: "About Us"
      })
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      _cache[8] || (_cache[8] = _createElementVNode("div", { class: "text-align-center" }, [
        _createElementVNode("p", { class: "page-heading" }, "Contact Us"),
        _createElementVNode("div", { class: "heading-line" }, [
          _createElementVNode("img", {
            src: _imports_1,
            alt: ""
          })
        ])
      ], -1)),
      _createElementVNode("div", _hoisted_3, [
        _cache[7] || (_cache[7] = _createElementVNode("span", { class: "font-color-red" }, "* required field", -1)),
        _createVNode(_unref(Form), {
          onSubmit: handleSubmission,
          "validation-schema": _unref(schema)
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", null, [
              _createElementVNode("div", _hoisted_4, [
                _cache[0] || (_cache[0] = _createElementVNode("label", { for: "firstName" }, [
                  _createTextVNode("First Name"),
                  _createElementVNode("span", { class: "super font-color-red" }, "*")
                ], -1)),
                _createVNode(_unref(Field), {
                  name: "firstName",
                  type: "text",
                  class: "form-control"
                }),
                _createVNode(_unref(ErrorMessage), {
                  name: "firstName",
                  class: "error-feedback"
                })
              ]),
              _createElementVNode("div", _hoisted_5, [
                _cache[1] || (_cache[1] = _createElementVNode("label", { for: "lastName" }, [
                  _createTextVNode("Last Name"),
                  _createElementVNode("span", { class: "super font-color-red" }, "*")
                ], -1)),
                _createVNode(_unref(Field), {
                  name: "lastName",
                  type: "text",
                  class: "form-control"
                }),
                _createVNode(_unref(ErrorMessage), {
                  name: "lastName",
                  class: "error-feedback"
                })
              ]),
              _createElementVNode("div", _hoisted_6, [
                _cache[2] || (_cache[2] = _createElementVNode("label", { for: "nameOrg" }, [
                  _createTextVNode("Institution/Company Name"),
                  _createElementVNode("span", { class: "super font-color-red" }, "*")
                ], -1)),
                _createVNode(_unref(Field), {
                  name: "nameOrg",
                  type: "text",
                  class: "form-control"
                }),
                _createVNode(_unref(ErrorMessage), {
                  name: "nameOrg",
                  class: "error-feedback"
                })
              ]),
              _createElementVNode("div", _hoisted_7, [
                _cache[3] || (_cache[3] = _createElementVNode("label", { for: "email" }, [
                  _createTextVNode("Email"),
                  _createElementVNode("span", { class: "super font-color-red" }, "*")
                ], -1)),
                _createVNode(_unref(Field), {
                  name: "email",
                  type: "email",
                  class: "form-control"
                }),
                _createVNode(_unref(ErrorMessage), {
                  name: "email",
                  class: "error-feedback"
                })
              ]),
              _createElementVNode("div", _hoisted_8, [
                _cache[4] || (_cache[4] = _createElementVNode("label", { for: "message" }, [
                  _createTextVNode("Message"),
                  _createElementVNode("span", { class: "super font-color-red" }, "*")
                ], -1)),
                _createVNode(_unref(Field), {
                  name: "message",
                  type: "text",
                  as: "textarea",
                  class: "form-control"
                }),
                _createVNode(_unref(ErrorMessage), {
                  name: "message",
                  class: "error-feedback"
                })
              ]),
              _cache[6] || (_cache[6] = _createElementVNode("br", null, null, -1)),
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("button", {
                  class: "btn btn-primary btn-block",
                  disabled: _unref(loading)
                }, [
                  _withDirectives(_createElementVNode("span", _hoisted_11, null, 512), [
                    [_vShow, _unref(loading)]
                  ]),
                  _cache[5] || (_cache[5] = _createTextVNode(" Contact Us "))
                ], 8, _hoisted_10)
              ])
            ])
          ]),
          _: 1
        }, 8, ["validation-schema"]),
        (_unref(message))
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(["alert", _unref(successful) ? 'alert-success' : 'alert-danger'])
            }, _toDisplayString(_unref(message)), 3))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}
}

})