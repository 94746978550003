<template>
  <div class="grid-container">
    <instructor-sidebar></instructor-sidebar>
    <div class="container min-height">
      <div class="back-grid">
        <back-button></back-button>
        <h2 class="text-align-center font-bold">Course-Support Requests</h2>
      </div>

      <h2 class="text-align-center"></h2>
      <div v-if="content.length > 0">
        <ul>
          <li v-for="course in content" :key="course.id">
            <course-request-item
              :course="course"
              @fetchCourse="getCourseRequest"
            />
          </li>
        </ul>
        <div class="paginator">
          <va-pagination
            v-model="currentPage"
            :pages="totalPages"
            :visible-pages="3"
            :hideOnSinglePage="true"
            @click="futureOnClick"
          />
        </div>
      </div>
      <div v-else class="mt-3">
        <h3 style="text-align: center; font-style: italic">
          No course-support request raised for course
          {{ route.params.courseCode }}.
        </h3>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import InstructorSidebar from "@/components/instructor/InstructorSidebar.vue";
import BackButton from "@/components/user/BackButton.vue";
import CourseRequestItem from "@/components/instructor/CourseRequestItem.vue";
import InstructorService from "@/services/instructor.service.js";
import { ref, watch } from "vue";
import { useRoute } from "vue-router";
import { CourseRequest } from "@/types";

const route = useRoute();
let message = ref("");
let loading = ref(false);
let content = ref([] as CourseRequest[]);
const currentPage = ref(1);
const totalPages = ref(1);

const getCourseRequest = (courseCode, type = "class", pageNumber = 1) => {
  console.log(courseCode);
  loading.value = true;
  InstructorService.getInstructorBoard(pageNumber, type, courseCode).then(
    (response) => {
      console.log(response);
      content.value = response.data.courses;
      totalPages.value = response.data.totalPages;
      currentPage.value = response.data.currentPage;
      loading.value = false;
    },
    (error) => {
      message.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};

const futureOnClick = () => {
  getCourseRequest(route.params.courseCode, "class", currentPage.value);
};

console.log(route.params);

watch(
  () => route.params.courseCode,
  (courseCode) => {
    getCourseRequest(courseCode as string);
  },
  { immediate: true }
);
</script>

<style scoped></style>
