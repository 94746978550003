import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "user-list" }
const _hoisted_2 = {
  key: 0,
  class: "font-larger bold-text"
}
const _hoisted_3 = {
  key: 1,
  style: {"font-weight":"600"}
}
const _hoisted_4 = { class: "name-card rounded-2" }
const _hoisted_5 = ["src"]
const _hoisted_6 = {
  key: 1,
  id: "profile-img",
  src: "//ssl.gstatic.com/accounts/ui/avatar_2x.png",
  class: "profile-icon-card"
}
const _hoisted_7 = {
  class: "ml-2 flex-grow-1",
  style: {"margin":"auto"}
}
const _hoisted_8 = {
  key: 2,
  class: "view-more"
}
const _hoisted_9 = {
  key: 3,
  style: {"font-weight":"600"}
}
const _hoisted_10 = { class: "name-card rounded-2" }
const _hoisted_11 = ["src"]
const _hoisted_12 = {
  key: 1,
  id: "profile-img",
  src: "//ssl.gstatic.com/accounts/ui/avatar_2x.png",
  class: "profile-icon-card"
}
const _hoisted_13 = {
  class: "ml-2 flex-grow-1",
  style: {"margin":"auto"}
}
const _hoisted_14 = {
  key: 4,
  class: "view-more"
}

import { ref } from "vue";
import { websiteURL } from "@/utils";
import UserService from "@/services/user.service.js";


export default /*@__PURE__*/_defineComponent({
  __name: 'UserList',
  setup(__props) {

const instructorList = ref([]);
const practitionerList = ref([]);

const getUserList = (role) => {
  UserService.getUsers(role).then(
    (response) => {
      if (role === "instructor") instructorList.value = response.data;
      else practitionerList.value = response.data;
    },
    (error) => {
      let res =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(res);
    }
  );
};
getUserList("instructor");
getUserList("industrypractitioner");

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ul", null, [
      (practitionerList.value.length > 0 || instructorList.value.length > 0)
        ? (_openBlock(), _createElementBlock("li", _hoisted_2, " Other Users "))
        : _createCommentVNode("", true),
      (practitionerList.value.length > 0)
        ? (_openBlock(), _createElementBlock("li", _hoisted_3, " Industry Practitioners "))
        : _createCommentVNode("", true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(practitionerList.value.slice(0, 5), (user) => {
        return (_openBlock(), _createElementBlock("li", {
          key: user.fileName
        }, [
          _createVNode(_component_router_link, {
            to: {
            name: 'user-profile',
            params: { userId: user.id },
          }
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_4, [
                (user.fileName)
                  ? (_openBlock(), _createElementBlock("img", {
                      key: 0,
                      id: "profile-img",
                      src: _unref(websiteURL) + 'profile_picture/' + user.fileName,
                      class: "profile-icon-card"
                    }, null, 8, _hoisted_5))
                  : (_openBlock(), _createElementBlock("img", _hoisted_6)),
                _createElementVNode("div", _hoisted_7, _toDisplayString(user.firstName + " " + user.lastName), 1)
              ])
            ]),
            _: 2
          }, 1032, ["to"])
        ]))
      }), 128)),
      (practitionerList.value.length > 5)
        ? (_openBlock(), _createElementBlock("li", _hoisted_8, [
            _createVNode(_component_router_link, { to: { name: 'users', params: { role: 'industrypractitioner' } } }, {
              default: _withCtx(() => _cache[0] || (_cache[0] = [
                _createTextVNode(" View More... ")
              ])),
              _: 1
            })
          ]))
        : _createCommentVNode("", true),
      (instructorList.value.length > 0)
        ? (_openBlock(), _createElementBlock("li", _hoisted_9, " Instructors "))
        : _createCommentVNode("", true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(instructorList.value.slice(0, 5), (user) => {
        return (_openBlock(), _createElementBlock("li", {
          key: user.fileName
        }, [
          _createVNode(_component_router_link, {
            to: {
            name: 'user-profile',
            params: { userId: user.id },
          }
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_10, [
                (user.fileName)
                  ? (_openBlock(), _createElementBlock("img", {
                      key: 0,
                      id: "profile-img",
                      src: _unref(websiteURL) + 'profile_picture/' + user.fileName,
                      class: "profile-icon-card"
                    }, null, 8, _hoisted_11))
                  : (_openBlock(), _createElementBlock("img", _hoisted_12)),
                _createElementVNode("div", _hoisted_13, _toDisplayString(user.firstName + " " + user.lastName), 1)
              ])
            ]),
            _: 2
          }, 1032, ["to"])
        ]))
      }), 128)),
      (instructorList.value.length > 5)
        ? (_openBlock(), _createElementBlock("li", _hoisted_14, [
            _createVNode(_component_router_link, { to: { name: 'users', params: { role: 'instructor' } } }, {
              default: _withCtx(() => _cache[1] || (_cache[1] = [
                _createTextVNode(" View More... ")
              ])),
              _: 1
            })
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})