import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  type: "submit",
  class: "search-button button",
  title: "Search"
}

import { useRouter } from "vue-router";
import { Field, useForm } from "vee-validate";

export default /*@__PURE__*/_defineComponent({
  __name: 'HeaderSearchBar',
  setup(__props) {

const router = useRouter();
const { handleSubmit } = useForm();

const search = handleSubmit((data) => {
  if (data.searchParameter.length > 0)
    router.push({ name: "search", params: { keyword: data.searchParameter } });
});

return (_ctx: any,_cache: any) => {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("form", {
    onSubmit: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_unref(search) && _unref(search)(...args)))
  }, [
    _createVNode(_unref(Field), {
      name: "searchParameter",
      type: "text",
      placeholder: "Search ConPEC",
      class: "search-bar"
    }),
    _createElementVNode("button", _hoisted_1, [
      _createVNode(_component_font_awesome_icon, { icon: "fa-solid fa-magnifying-glass" })
    ])
  ], 32))
}
}

})