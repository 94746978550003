import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives, withModifiers as _withModifiers, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = {
  key: 1,
  id: "profile-img",
  src: "//ssl.gstatic.com/accounts/ui/avatar_2x.png",
  class: "profile-img-card"
}
const _hoisted_3 = { id: "popup-message" }
const _hoisted_4 = { class: "form-group text-align-center" }
const _hoisted_5 = ["disabled"]
const _hoisted_6 = { class: "spinner-border spinner-border-sm" }

import { showToast, websiteURL } from "@/utils";
import PopupModal from "@/components/user/PopupModal.vue";

import { useAuthStore } from "@/stores/AuthStore";
import { ref } from "vue";
import AuthService from "@/services/auth.service.js";

export default /*@__PURE__*/_defineComponent({
  __name: 'ProfilePicture',
  setup(__props) {

const AuthStore = useAuthStore();

let currentUser = AuthStore.auth;

let isVisible = ref(false);

const showPopup = () => {
  isVisible.value = true;
};

const hidePopup = () => {
  isVisible.value = false;
};

const uploadFile = (e) => {
  files = e.target.files || e.dataTransfer.files;
  if (!files.length) return;
};

let files;
let loading = ref(false);
let message = ref("");

const uploadData = () => {
  loading.value = true;
  AuthService.saveProfilePicture(files).then(
    (response) => {
      AuthStore.getProfile().then(
        () => {
          window.location.reload();
          showToast(response.data.message);
          loading.value = false;
          isVisible.value = false;
        },
        (err) => {
          loading.value = false;
          message.value =
            (err.response && err.response.data && err.response.data.message) ||
            err.message ||
            err.toString();
        }
      );
    },
    (error) => {
      loading.value = false;
      message.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};

return (_ctx: any,_cache: any) => {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_unref(currentUser).fileName)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          id: "profile-img",
          src: _unref(websiteURL) + 'profile_picture/' + _unref(currentUser).fileName,
          class: "profile-img-card"
        }, null, 8, _hoisted_1))
      : (_openBlock(), _createElementBlock("img", _hoisted_2)),
    _createElementVNode("div", { onClick: showPopup }, [
      _createVNode(_component_font_awesome_icon, {
        icon: "fa-solid fa-camera",
        class: "update-picture-icon"
      })
    ]),
    _createVNode(PopupModal, {
      visible: _unref(isVisible),
      onClose: hidePopup
    }, {
      title: _withCtx(() => _cache[0] || (_cache[0] = [
        _createTextVNode(" Update profile picture")
      ])),
      content: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("form", {
            onSubmit: _withModifiers(uploadData, ["prevent"]),
            enctype: "multipart/form-data"
          }, [
            _createElementVNode("input", {
              type: "file",
              name: "file",
              onChange: uploadFile,
              accept: "image/*",
              class: "form-control"
            }, null, 32),
            _cache[2] || (_cache[2] = _createElementVNode("br", null, null, -1)),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("button", {
                type: "submit",
                class: "btn btn-primary btn-block",
                disabled: _unref(loading)
              }, [
                _withDirectives(_createElementVNode("span", _hoisted_6, null, 512), [
                  [_vShow, _unref(loading)]
                ]),
                _cache[1] || (_cache[1] = _createElementVNode("span", null, "Upload", -1))
              ], 8, _hoisted_5)
            ])
          ], 32)
        ])
      ]),
      _: 1
    }, 8, ["visible"])
  ], 64))
}
}

})