<template>
  <table>
    <tr>
      <td>Course Request Type</td>
      <td>
        {{ JSON.parse(userAvailability.courseRequestType).join(", ") }}
      </td>
    </tr>

    <tr>
      <td>Subject willing to speak on</td>
      <td>
        {{
          formatSubjectSpeak(
            userAvailability.subjectSpeak,
            userAvailability?.subjectSpeakOthers
          )
        }}
      </td>
    </tr>
    <tr>
      <td>Instruction Delivery Mode</td>
      <td>{{ userAvailability.insDeliveryType }}</td>
    </tr>

    <tr>
      <td>Location of Institution</td>
      <td>{{ JSON.parse(userAvailability.insState).join(", ") }}</td>
    </tr>

    <tr>
      <td>Name of Institution</td>
      <td>{{ JSON.parse(userAvailability.insName).join(", ") }}</td>
    </tr>

    <tr>
      <td>Student's Academic Level</td>
      <td>{{ JSON.parse(userAvailability.academicLevel).join(", ") }}</td>
    </tr>

    <tr>
      <td>Students' Department or Program of Study</td>
      <td>{{ JSON.parse(userAvailability.studentDept).join(", ") }}</td>
    </tr>
  </table>
</template>

<script setup lang="ts">
import { Availability } from "@/types";
import { formatSubjectSpeak } from "@/utils";

// eslint-disable-next-line no-undef
const props = defineProps<{
  userAvailability: Availability;
}>();

console.log(props.userAvailability);
</script>

<style scoped>
table {
  width: fit-content;
  box-shadow: 0px 0px 10px 4px rgb(0 0 0 / 30%);
  border-radius: 6px;
  background: #fafafa;
  padding: 1rem;
  margin-top: 1rem;
  margin-left: auto;
  margin-right: auto;
}

thead td {
  text-align: center;
}

td {
  border: 2px solid #f0f0f0;
  border-collapse: collapse;
  padding: 0.2em 2em;
  font-size: larger;
  overflow-wrap: break-word;
}
</style>
