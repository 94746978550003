<template>
  <div class="flex flex-direction-column lg:flex-row">
    <div class="mr-6">
      <input
        name="searchParameter"
        v-model="searchParameter"
        type="text"
        placeholder="Search Resources"
        class="search-bar"
      />
      <button @click="getUploads" class="search-button button" title="Search">
        <font-awesome-icon icon="fa-solid fa-magnifying-glass" />
      </button>
    </div>
    <div>
      <Field
        name="size"
        as="select"
        class="dropdown w-auto ml-auto mr-3"
        v-model="size"
        :onchange="changePageSize"
      >
        <option value="10">10</option>
        <option value="20">20</option>
        <option value="30">30</option>
        <option value="40">40</option>
        <option value="50">50</option>
      </Field>
    </div>
  </div>

  <va-tabs
    grow
    v-model="currTabIndex"
    @update:model-value="myFunc"
    class="mb-3 mt-3"
  >
    <template #tabs>
      <va-tab v-for="tab in tabs" :key="tab.title">
        <font-awesome-icon :icon="tab.icon" /> &nbsp;
        {{ tab.title }}
      </va-tab>
    </template>
  </va-tabs>

  <div v-if="currTabIndex === 0">
    <div v-if="tabs[currTabIndex].data.length === 0">
      <h5 style="margin: 1em" class="text-align-center">
        No Pictures Uploaded Yet
      </h5>
    </div>
    <div v-else>
      <div class="min-h">
        <div
          class="responsive"
          v-for="item in tabs[currTabIndex].data"
          :key="item.id"
        >
          <div class="gallery">
            <div class="image-container">
              <img
                v-if="item.filePath"
                :src="websiteURL + item.filePath.substring(9)"
                :alt="item.file"
                @click="
                  showImage(
                    websiteURL + item.filePath.substring(9),
                    item.description,
                    websiteURL + item.origFilePath.substring(9)
                  )
                "
              />
              <a v-else :href="item.linkResources" target="_blank">
                <img
                  src="@/assets/images/uploads/general_resource.jpg"
                  :alt="item.linkResources"
                />
              </a>
            </div>
            <div class="desc">
              <p>
                {{
                  item.description.slice(0, 25) +
                  (item.description.length > 25 ? "..." : "")
                }}
              </p>
              <font-awesome-icon
                icon="fa-solid fa-lock"
                class="ml-1 mr-2"
                v-if="!JSON.parse(item.visibility).includes('All')"
                title="This content is restricted."
              />
              <a
                :href="websiteURL + item.origFilePath.substring(9)"
                download
                title="Download"
                style="color: var(--bs-body-color)"
                v-if="props.type === 'public' && item.filePath"
              >
                <font-awesome-icon icon="fa-solid fa-download" />
              </a>
              <button
                @click="(visible = true), (contentId = item.id)"
                style="color: var(--bs-body-color); border: none"
                class="bg-transparent"
                v-if="props.type === 'private'"
              >
                <font-awesome-icon icon="fa-solid fa-trash" />
              </button>
              <button
                @click="(reportDialogVisible = true), (contentId = item.id)"
                style="color: var(--bs-body-color); border: none"
                class="ml-2 bg-transparent"
                v-if="props.type === 'public'"
                title="Flag as Inappropriate"
              >
                <font-awesome-icon icon="fa-solid fa-flag" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="clearfix"></div>
    </div>
  </div>
  <div v-if="currTabIndex === 1">
    <div v-if="tabs[currTabIndex].data.length === 0">
      <h5 style="margin: 1em" class="text-align-center">
        No Videos Uploaded Yet
      </h5>
    </div>
    <div v-else>
      <div
        class="responsive"
        v-for="item in tabs[currTabIndex].data"
        :key="item.id"
      >
        <div class="gallery">
          <video v-if="item.filePath" controls>
            <source
              :src="websiteURL + item.filePath.substring(9)"
              :type="item['mimetype']"
            />
          </video>
          <a v-else :href="item.linkResources" target="_blank">
            <img
              src="@/assets/images/uploads/general_resource.jpg"
              :alt="item.linkResources"
            />
          </a>
          <div class="desc">
            <p>
              {{
                item.description.slice(0, 25) +
                (item.description.length > 25 ? "..." : "")
              }}
            </p>
            <font-awesome-icon
              icon="fa-solid fa-lock"
              class="ml-1 mr-2"
              v-if="!JSON.parse(item.visibility).includes('All')"
              title="This content is restricted."
            />
            <button
              @click="(visible = true), (contentId = item.id)"
              style="color: var(--bs-body-color); border: none"
              v-if="props.type === 'private'"
            >
              <font-awesome-icon icon="fa-solid fa-trash" />
            </button>
            <button
              @click="(reportDialogVisible = true), (contentId = item.id)"
              style="color: var(--bs-body-color); border: none"
              class="bg-transparent"
              v-else
              title="Flag as Inappropriate"
            >
              <font-awesome-icon icon="fa-solid fa-flag" />
            </button>
          </div>
        </div>
      </div>
      <div class="clearfix"></div>
    </div>
  </div>
  <div v-if="currTabIndex === 2">
    <div v-if="tabs[currTabIndex].data.length === 0">
      <h5 style="margin: 1em" class="text-align-center">
        No Documents Uploaded Yet
      </h5>
    </div>
    <div v-else>
      <div
        class="responsive"
        v-for="item in tabs[currTabIndex].data"
        :key="item.id"
      >
        <div class="gallery">
          <div class="document-container">
            <div
              v-if="item.filePath"
              class="doc-image-container"
              style="cursor: default"
            >
              <img
                v-if="item.mimetype === 'application/pdf'"
                src="@/assets/images/uploads/pdf.png"
                class="doc-image"
              />
              <img
                v-else-if="
                  item.mimetype ===
                    'application/vnd.openxmlformats-officedocument.presentationml.presentation' ||
                  item.mimetype === 'application/mspowerpoint'
                "
                src="@/assets/images/uploads/ppt.png"
                class="doc-image"
              />
              <img
                v-else-if="
                  item.mimetype ===
                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
                  item.mimetype === 'application/msword'
                "
                src="@/assets/images/uploads/docx.png"
                class="doc-image"
              />
              <img
                v-else
                src="@/assets/images/uploads/txt.png"
                class="doc-image"
              />
            </div>
            <div v-else class="doc-image-container overflow-hidden">
              <a :href="item.linkResources" target="_blank">
                <img
                  src="@/assets/images/uploads/general_resource.jpg"
                  :alt="item.linkResources"
                  style="width: 100%; height: 100%; overflow: hidden"
                />
              </a>
            </div>
            <div class="desc">
              <p>
                {{
                  item.description.slice(0, 25) +
                  (item.description.length > 25 ? "..." : "")
                }}
              </p>
              <font-awesome-icon
                icon="fa-solid fa-lock"
                class="ml-1 mr-2"
                v-if="!JSON.parse(item.visibility).includes('All')"
                title="This content is restricted."
              />
              <a
                :href="websiteURL + item.filePath.substring(9)"
                download
                title="Download"
                style="color: var(--bs-body-color)"
                v-if="currentUser.roles === 'INSTRUCTOR' && item.filePath"
              >
                <font-awesome-icon icon="fa-solid fa-download" />
              </a>
              <button
                @click="(visible = true), (contentId = item.id)"
                style="color: var(--bs-body-color); border: none"
                v-if="currentUser.roles === 'INDUSTRYPRACTITIONER'"
              >
                &nbsp;
                <font-awesome-icon icon="fa-solid fa-trash" />
              </button>
              <button
                @click="(reportDialogVisible = true), (contentId = item.id)"
                style="color: var(--bs-body-color); border: none"
                class="ml-2 bg-transparent"
                v-if="props.type === 'public'"
                title="Flag as Inappropriate"
              >
                <font-awesome-icon icon="fa-solid fa-flag" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="clearfix"></div>
    </div>
  </div>
  <div class="paginator">
    <va-pagination
      v-model="tabs[currTabIndex].currentPage"
      :pages="tabs[currTabIndex].totalPages"
      :visible-pages="3"
      :hideOnSinglePage="true"
      @click="onClick"
    />
  </div>

  <Dialog
    v-model:visible="showCustomContent"
    modal
    :header="imgDesc"
    :style="{ width: '50vw' }"
  >
    <div style="display: grid; grid-template-columns: 3fr 1fr">
      <div></div>
      <div style="text-align: right">
        <a
          :href="downloadSrc"
          download
          title="Download"
          style="color: var(--bs-body-color)"
          v-if="currentUser.roles === 'INSTRUCTOR'"
        >
          <font-awesome-icon icon="fa-solid fa-download" />
        </a>
      </div>
    </div>
    <div class="flex justify-content-center" style="height: 70vh">
      <img :src="imgSrc" class="model-img" autofocus />
    </div>
  </Dialog>
  <Dialog v-model:visible="visible" modal header=" " :style="{ width: '30vw' }">
    <Form
      @submit="handleSubmission"
      :validation-schema="schema"
      v-slot="{ meta }"
    >
      <div class="form-group">
        <label for="reasonDelete"
          >Please provide a reason for deleting the uploaded content</label
        >
        <Field name="reasonDelete" type="text" class="form-control" />
        <ErrorMessage name="reasonDelete" class="error-feedback" />
      </div>
      <br />
      <div class="form-group text-align-center">
        <button
          class="btn btn-primary btn-block"
          :disabled="loading || !meta.valid"
        >
          <span
            v-show="loading"
            class="spinner-border spinner-border-sm"
          ></span>
          <span>Delete</span>
        </button>
      </div>
    </Form>
  </Dialog>
  <Dialog
    v-model:visible="reportDialogVisible"
    modal
    header=" "
    :style="{ width: '41vw' }"
  >
    <Form
      @submit="handleReportDialogSubmission"
      :validation-schema="reportDialogSchema"
      v-slot="{ meta }"
    >
      <div class="form-group">
        <label for="reasonDelete"
          >Please provide a reason for reporting the uploaded content</label
        >
        <Field name="reasonReport" type="text" class="form-control" />
        <ErrorMessage name="reasonReport" class="error-feedback" />
      </div>
      <br />
      <div class="form-group text-align-center">
        <button
          class="btn btn-primary btn-block"
          :disabled="loading || !meta.valid"
        >
          <span
            v-show="loading"
            class="spinner-border spinner-border-sm"
          ></span>
          <span>Report</span>
        </button>
      </div>
    </Form>
  </Dialog>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { Upload } from "@/types";
import heic2any from "heic2any";
import UserService from "@/services/user.service.js";
import Dialog from "primevue/dialog";
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import { showToast, websiteURL } from "@/utils";
import { useAuthStore } from "@/stores/AuthStore";

const AuthStore = useAuthStore();

const showCustomContent = ref(false);
const downloadSrc = ref("");
const imgSrc = ref("");
const imgDesc = ref("");

const currentUser = AuthStore.auth;
const contentId = ref(-1);
const visible = ref(false);
const reportDialogVisible = ref(false);
let searchParameter = ref("");
let loading = ref(false);
let message = ref("");
let size = ref(10);
let currTabIndex = ref(0);

// eslint-disable-next-line no-undef
const props = defineProps<{
  type: string;
}>();

const schema = yup.object().shape({
  reasonDelete: yup.string().required("Reason for deletion is required!"),
});

const reportDialogSchema = yup.object().shape({
  reasonReport: yup
    .string()
    .required("Reason for reporting content is required!"),
});

const tabs = ref([
  {
    title: "Pictures",
    icon: "fa-solid fa-image",
    value: "Project Pictures",
    currentPage: 1,
    totalPages: 1,
    data: [],
  },
  {
    title: "Videos",
    icon: "fa-solid fa-video",
    value: "Video",
    currentPage: 1,
    totalPages: 1,
    data: [],
  },
  {
    title: "Documents",
    icon: "fa-solid fa-file",
    value: "Document",
    currentPage: 1,
    totalPages: 1,
    data: [],
  },
]);

const handleSubmission = (data) => {
  loading.value = true;
  data.contentId = contentId.value;
  UserService.deleteContent(data).then(
    () => {
      window.location.reload();
      showToast("Content deleted successfully.");
      visible.value = false;
      loading.value = false;
    },
    (error) => {
      loading.value = false;
      message.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};

const handleReportDialogSubmission = (data) => {
  loading.value = true;
  data.contentId = contentId.value;
  UserService.reportContent(data).then(
    () => {
      showToast("Content Reported successfully.");
      getUploads();
      reportDialogVisible.value = false;
      loading.value = false;
    },
    (error) => {
      loading.value = false;
      message.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};

const getUploads = (index = currTabIndex.value) => {
  if (searchParameter.value.length > 0) {
    search(currTabIndex.value);
  } else {
    if (props.type === "private") {
      getMyUploads(index);
    } else {
      getPublicResources(index);
    }
  }
};

const getPublicResources = (index) => {
  UserService.getPublicResources(
    tabs.value[index].currentPage,
    tabs.value[index].value,
    size.value
  ).then(
    (response) => {
      tabs.value[index].data = response.data.uploads;
      tabs.value[index].currentPage = response.data.currentPage;
      tabs.value[index].totalPages = response.data.totalPages;
    },
    (error) => {
      message.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};

const getMyUploads = (index) => {
  UserService.getMyUploads(
    tabs.value[index].currentPage,
    tabs.value[index].value,
    size.value
  ).then(
    (response) => {
      tabs.value[index].data = response.data.uploads;
      tabs.value[index].currentPage = response.data.currentPage;
      tabs.value[index].totalPages = response.data.totalPages;
    },
    (error) => {
      message.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};

const search = (index) => {
  UserService.searchResources(
    searchParameter.value,
    props.type,
    tabs.value[index].currentPage,
    tabs.value[index].value,
    size.value
  ).then(
    (response) => {
      tabs.value[index].data = response.data.uploads;
      tabs.value[index].currentPage = response.data.currentPage;
      tabs.value[index].totalPages = response.data.totalPages;
    },
    (error) => {
      message.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};

const changePageSize = () => {
  tabs.value.forEach((el, index) => getUploads(index));
};

const showImage = (src, desc, downloadPath) => {
  imgSrc.value = src;
  imgDesc.value = desc;
  downloadSrc.value = downloadPath;
  showCustomContent.value = true;
};

const myFunc = (e) => {
  searchParameter.value = "";
  getUploads(e);
  console.log(e);
  console.log("Anubhav 2");
};

getUploads();
//tabs.value.forEach((el, index) => getUploads(index));

const onClick = () => {
  getUploads();
};
</script>

<style scoped>
.document span {
  font-size: xxx-large;
}

div.gallery {
  border: 1px solid #ccc;
}

div.gallery:hover {
  border: 1px solid #777;
}

div.image-container {
  width: 275px;
  height: 200px;
  overflow: hidden;
  cursor: pointer;
}

div.document-container {
  width: 200px;
  height: 200px;
  margin: auto;
  cursor: pointer;
}

div.gallery img {
  width: 100%;
  aspect-ratio: 3/2;
  object-fit: contain;
}

div.gallery video {
  width: 100%;
  height: auto;
  max-height: 200px;
}

div.desc {
  padding: 13px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /*text-align: center;*/
}

div.desc p {
  flex: 15;
}

div.desc a {
  flex: 1;
}

* {
  box-sizing: border-box;
}

.responsive {
  padding: 0 6px;
  margin: 4px 0;
  float: left;
}

.clearfix:after {
  content: "";
  display: table;
  clear: both;
}

.min-h {
  min-height: 34rem;
}

.paginator {
  display: grid;
  justify-content: center;
  margin: 0.5rem 1rem 1rem 0;
}

.doc-image-container {
  width: 100%;
  height: 60%;
  display: flex;
  align-items: center;
  background: #deeaf7;
}

.doc-image {
  width: 4rem;
  height: 4rem;
  object-fit: contain;
}

.model-img {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  object-fit: contain;
}
</style>
