import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, isRef as _isRef, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives } from "vue"
import _imports_0 from '@/assets/images/rename.png'
import _imports_1 from '@/assets/images/delete.png'


const _hoisted_1 = { class: "flex flex-column lg:flex-row gap-4 mt-3" }
const _hoisted_2 = { class: "align-center text-align-center flex-grow-0" }
const _hoisted_3 = { class: "mt-3" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "align-center text-align-center" }
const _hoisted_6 = { class: "flex flex-direction-row" }
const _hoisted_7 = { class: "flex-grow-0 w-10rem text-end" }
const _hoisted_8 = { class: "va-text-center" }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { key: 1 }
const _hoisted_11 = { class: "mt-6 flex flex-direction-row flex-wrap" }
const _hoisted_12 = {
  key: 0,
  class: "flex flex-wrap"
}
const _hoisted_13 = ["id", "name", "checked", "onClick"]
const _hoisted_14 = {
  key: 1,
  class: "flex flex-wrap"
}
const _hoisted_15 = ["id", "name", "checked", "onClick"]
const _hoisted_16 = { class: "form-group" }
const _hoisted_17 = { class: "form-group text-align-center" }
const _hoisted_18 = ["disabled"]
const _hoisted_19 = { class: "spinner-border spinner-border-sm" }
const _hoisted_20 = { class: "form-group mt-2" }
const _hoisted_21 = { class: "form-group text-align-center" }
const _hoisted_22 = ["disabled"]
const _hoisted_23 = { class: "spinner-border spinner-border-sm" }

import { ref } from "vue";
import * as yup from "yup";
import Dialog from "primevue/dialog";
import { Form, Field, ErrorMessage } from "vee-validate";
import { showToast } from "@/utils";
import AdminService from "@/services/admin.service.js";
import { Category, Topic } from "@/types";
import { useModal } from "vuestic-ui";


export default /*@__PURE__*/_defineComponent({
  __name: 'AssignCategories',
  props: {
    tableName: {}
  },
  setup(__props: any) {

const { confirm } = useModal();

let categories = ref([] as Category[]);
let topics = ref([] as Topic[]);
let topicsFiltered = ref([] as Topic[]);

let selectedCategory = ref(0);
let showAll = ref(false);
let newCategoryMenuVisible = ref(false);
let renameMenuVisible = ref(false);
let loading = ref(false);
let message = ref("");

// eslint-disable-next-line no-undef
const props = __props;

const newCategorySchema = yup.object().shape({
  name: yup
    .string()
    .required("Category Name is required!")
    .notOneOf(
      categories.value.map((el) => el.name),
      "Category already exists!"
    ),
});

const getCategories = (table) => {
  loading.value = true;
  AdminService.getCategories(table).then(
    (response) => {
      console.log(response.data);
      categories.value = response.data;
      loading.value = false;
    },
    (error) => {
      loading.value = false;
      message.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};

const createNewCategory = (data) => {
  loading.value = true;
  AdminService.createCategory(data, props.tableName).then(
    (response) => {
      showToast("New Category created successfully.");
      getCategories(props.tableName);
      newCategoryMenuVisible.value = false;
    },
    (error) => {
      loading.value = false;
      message.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};

const updateCategory = (data) => {
  loading.value = true;
  AdminService.updateCategory(
    data,
    categories.value[selectedCategory.value]?.id
  ).then(
    (response) => {
      showToast("Category updated successfully.");
      getCategories(props.tableName);
      renameMenuVisible.value = false;
    },
    (error) => {
      loading.value = false;
      message.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};

const deleteCategory = async () => {
  const cancelDeleteRequest = await confirm({
    message: "Are you sure you want to delete this category?",
    cancelText: "Yes",
    okText: "No",
  });
  if (!cancelDeleteRequest) {
    loading.value = true;
    AdminService.deleteCategory(
      categories.value[selectedCategory.value]?.id
    ).then(
      (response) => {
        showToast("Category deleted successfully.");
        getCategories(props.tableName);
        getTopic(props.tableName);
        newCategoryMenuVisible.value = false;
      },
      (error) => {
        loading.value = false;
        message.value =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  }
};

const getTopic = (table) => {
  loading.value = true;
  AdminService.getTopic(table).then(
    (response) => {
      topics.value = response.data;
      topicsFiltered.value = topics.value.filter((el) => el.categoryId == null);
      loading.value = false;
    },
    (error) => {
      loading.value = false;
      message.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};

const changeCategory = (event, topic) => {
  loading.value = true;
  AdminService.updateTopic(
    topic.id,
    event.target.checked ? categories.value[selectedCategory.value]?.id : null
  ).then(
    (response) => {
      getTopic(props.tableName);
      showToast("Topic Category updated successfully.");
      loading.value = false;
    },
    (error) => {
      loading.value = false;
      message.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};

getCategories(props.tableName);
getTopic(props.tableName);

const formValues = {
  name: categories.value[selectedCategory.value]?.name,
};

return (_ctx: any,_cache: any) => {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_va_switch = _resolveComponent("va-switch")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", null, [
          _cache[7] || (_cache[7] = _createElementVNode("h3", null, "Categories", -1)),
          _createElementVNode("div", {
            class: "d-inline-block mt-1 mx-1 p-1 px-2 cursor-pointer category-menu-item rounded-2",
            title: "Rename",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_isRef(renameMenuVisible) //@ts-ignore
 ? renameMenuVisible.value = true : renameMenuVisible = true))
          }, _cache[5] || (_cache[5] = [
            _createElementVNode("img", {
              src: _imports_0,
              class: "category-menu-img"
            }, null, -1)
          ])),
          _createElementVNode("div", {
            class: "d-inline-block mt-1 mx-1 py-1 px-2 cursor-pointer category-menu-item rounded-2",
            title: "Delete",
            onClick: deleteCategory
          }, _cache[6] || (_cache[6] = [
            _createElementVNode("img", {
              src: _imports_1,
              class: "category-menu-img cursor-pointer"
            }, null, -1)
          ]))
        ]),
        _createElementVNode("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(categories), (category, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index,
              class: _normalizeClass(["p-2 bg-bluegray-300 my-1 cursor-pointer rounded-3", { active: _unref(selectedCategory) === index }]),
              onClick: ($event: any) => (_isRef(selectedCategory) //@ts-ignore
 ? selectedCategory.value = index : selectedCategory = index)
            }, _toDisplayString(category.name), 11, _hoisted_4))
          }), 128)),
          _createElementVNode("div", {
            class: "p-2 bg-bluegray-300 my-1 cursor-pointer rounded-3",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_isRef(newCategoryMenuVisible) //@ts-ignore
 ? newCategoryMenuVisible.value = true : newCategoryMenuVisible = true))
          }, [
            _createVNode(_component_font_awesome_icon, {
              style: {"margin":"auto"},
              icon: "fa-solid fa-plus",
              title: "Add"
            }),
            _cache[8] || (_cache[8] = _createTextVNode(" Add New "))
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _cache[11] || (_cache[11] = _createElementVNode("h3", null, "Topics", -1)),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_va_switch, {
              modelValue: _unref(showAll),
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_isRef(showAll) ? (showAll).value = $event : showAll = $event)),
              color: "#5123a1",
              "off-color": "#ffd300",
              size: "small"
            }, {
              innerLabel: _withCtx(() => [
                _createElementVNode("div", _hoisted_8, [
                  (_unref(showAll))
                    ? (_openBlock(), _createElementBlock("div", _hoisted_9, _cache[9] || (_cache[9] = [
                        _createElementVNode("span", null, " All ", -1)
                      ])))
                    : (_openBlock(), _createElementBlock("div", _hoisted_10, _cache[10] || (_cache[10] = [
                        _createElementVNode("span", null, " Uncategorized ", -1)
                      ])))
                ])
              ]),
              _: 1
            }, 8, ["modelValue"])
          ])
        ]),
        _createElementVNode("div", _hoisted_11, [
          (_unref(showAll))
            ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(topics), (topic) => {
                  return (_openBlock(), _createElementBlock("span", {
                    key: topic.id,
                    class: "p-1 bg-bluegray-200 m-1 rounded-2 topic-item"
                  }, [
                    _createElementVNode("input", {
                      id: topic.id,
                      type: "checkbox",
                      name: topic.name,
                      checked: _unref(categories)[_unref(selectedCategory)]?.id === topic.categoryId,
                      onClick: ($event: any) => (changeCategory($event, topic))
                    }, null, 8, _hoisted_13),
                    _createTextVNode("  " + _toDisplayString(topic.name), 1)
                  ]))
                }), 128))
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_14, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(topicsFiltered), (topic) => {
                  return (_openBlock(), _createElementBlock("span", {
                    key: topic.id,
                    class: "p-1 bg-bluegray-200 m-1 rounded-2 topic-item"
                  }, [
                    _createElementVNode("input", {
                      id: topic.id,
                      type: "checkbox",
                      name: topic.name,
                      checked: _unref(categories)[_unref(selectedCategory)]?.id === topic.categoryId,
                      onClick: ($event: any) => (changeCategory($event, topic))
                    }, null, 8, _hoisted_15),
                    _createTextVNode("  " + _toDisplayString(topic.name), 1)
                  ]))
                }), 128))
              ]))
        ])
      ])
    ]),
    _createVNode(_unref(Dialog), {
      visible: _unref(newCategoryMenuVisible),
      "onUpdate:visible": _cache[3] || (_cache[3] = ($event: any) => (_isRef(newCategoryMenuVisible) ? (newCategoryMenuVisible).value = $event : newCategoryMenuVisible = $event)),
      modal: "",
      header: "Create New Category",
      style: { width: '30vw' },
      breakpoints: { '768px': '75vw' }
    }, {
      default: _withCtx(() => [
        _createVNode(_unref(Form), {
          onSubmit: createNewCategory,
          "validation-schema": _unref(newCategorySchema)
        }, {
          default: _withCtx(({ meta }) => [
            _createElementVNode("div", _hoisted_16, [
              _createVNode(_unref(Field), {
                name: "name",
                type: "text",
                class: "form-control",
                placeholder: "Category"
              }),
              _createVNode(_unref(ErrorMessage), {
                name: "name",
                class: "error-feedback"
              })
            ]),
            _cache[13] || (_cache[13] = _createElementVNode("br", null, null, -1)),
            _createElementVNode("div", _hoisted_17, [
              _createElementVNode("button", {
                class: "btn btn-primary btn-block",
                disabled: _unref(loading) || !meta.valid
              }, [
                _withDirectives(_createElementVNode("span", _hoisted_19, null, 512), [
                  [_vShow, _unref(loading)]
                ]),
                _cache[12] || (_cache[12] = _createElementVNode("span", null, "Create", -1))
              ], 8, _hoisted_18)
            ])
          ]),
          _: 1
        }, 8, ["validation-schema"])
      ]),
      _: 1
    }, 8, ["visible"]),
    _createVNode(_unref(Dialog), {
      visible: _unref(renameMenuVisible),
      "onUpdate:visible": _cache[4] || (_cache[4] = ($event: any) => (_isRef(renameMenuVisible) ? (renameMenuVisible).value = $event : renameMenuVisible = $event)),
      modal: "",
      header: "Rename Category",
      style: { width: '30vw' },
      breakpoints: { '768px': '75vw' }
    }, {
      default: _withCtx(() => [
        _createVNode(_unref(Form), {
          onSubmit: updateCategory,
          "validation-schema": _unref(newCategorySchema)
        }, {
          default: _withCtx(({ meta }) => [
            _cache[15] || (_cache[15] = _createElementVNode("b", null, "Current Category ", -1)),
            _cache[16] || (_cache[16] = _createTextVNode(": ")),
            _createElementVNode("i", null, _toDisplayString(_unref(categories)[_unref(selectedCategory)]?.name), 1),
            _createElementVNode("div", _hoisted_20, [
              _createVNode(_unref(Field), {
                name: "name",
                type: "text",
                class: "form-control",
                placeholder: "New Name"
              }),
              _createVNode(_unref(ErrorMessage), {
                name: "name",
                class: "error-feedback"
              })
            ]),
            _cache[17] || (_cache[17] = _createElementVNode("br", null, null, -1)),
            _createElementVNode("div", _hoisted_21, [
              _createElementVNode("button", {
                class: "btn btn-primary btn-block",
                disabled: _unref(loading) || !meta.valid
              }, [
                _withDirectives(_createElementVNode("span", _hoisted_23, null, 512), [
                  [_vShow, _unref(loading)]
                ]),
                _cache[14] || (_cache[14] = _createElementVNode("span", null, "Rename", -1))
              ], 8, _hoisted_22)
            ])
          ]),
          _: 1
        }, 8, ["validation-schema"])
      ]),
      _: 1
    }, 8, ["visible"])
  ], 64))
}
}

})