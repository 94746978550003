import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "min-height header-space" }
const _hoisted_2 = { class: "card card-container" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "form-group" }
const _hoisted_5 = { class: "form-group" }
const _hoisted_6 = { class: "form-group" }
const _hoisted_7 = ["disabled"]
const _hoisted_8 = { class: "spinner-border spinner-border-sm" }

import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import AuthService from "@/services/auth.service.js";
import Password from "primevue/password";

import { useRoute, useRouter } from "vue-router";

export default /*@__PURE__*/_defineComponent({
  __name: 'ResetPasswordView',
  setup(__props) {

const route = useRoute();
const router = useRouter();

let loading = false;
let successful = false;
let message = "";

const schema = yup.object().shape({
  password: yup
    .string()
    .required("Password is required!")
    .min(6, "Must be at least 6 characters!")
    .max(40, "Must be maximum 40 characters!")
    .matches(
      /^.*(?=.{8,})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      "Password must contain at least 8 characters, one uppercase, and one number"
    ),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password")], "Passwords must match"),
});

const handleResetPassword = (data) => {
  loading = true;
  data.token = route.params.token;
  AuthService.resetPassword(data).then(
    (data) => {
      router.push("/Home");
    },
    (error) => {
      loading = false;
      message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (!_unref(successful))
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_unref(Form), {
              onSubmit: handleResetPassword,
              "validation-schema": _unref(schema)
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_4, [
                  _cache[0] || (_cache[0] = _createElementVNode("label", { for: "password" }, "Password", -1)),
                  _createVNode(_unref(Field), {
                    name: "password",
                    type: "password",
                    class: "form-control"
                  }),
                  _createVNode(_unref(ErrorMessage), {
                    name: "password",
                    class: "error-feedback"
                  })
                ]),
                _createElementVNode("div", _hoisted_5, [
                  _cache[1] || (_cache[1] = _createElementVNode("label", { for: "confirmPassword" }, "Confirm Password", -1)),
                  _createVNode(_unref(Field), {
                    name: "confirmPassword",
                    type: "password",
                    class: "form-control"
                  }),
                  _createVNode(_unref(ErrorMessage), {
                    name: "confirmPassword",
                    class: "error-feedback"
                  })
                ]),
                _cache[3] || (_cache[3] = _createElementVNode("br", null, null, -1)),
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("button", {
                    class: "btn btn-primary btn-block",
                    style: {"margin-left":"22%"},
                    disabled: _unref(loading)
                  }, [
                    _withDirectives(_createElementVNode("span", _hoisted_8, null, 512), [
                      [_vShow, _unref(loading)]
                    ]),
                    _cache[2] || (_cache[2] = _createElementVNode("span", null, "Change Password", -1))
                  ], 8, _hoisted_7)
                ])
              ]),
              _: 1
            }, 8, ["validation-schema"])
          ]))
        : _createCommentVNode("", true),
      (_unref(message))
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: _normalizeClass(_unref(successful) ? 'alert-success' : 'error-feedback')
          }, _toDisplayString(_unref(message)), 3))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})