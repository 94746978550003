import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, isRef as _isRef, renderList as _renderList, Fragment as _Fragment, vShow as _vShow, withDirectives as _withDirectives, withModifiers as _withModifiers, withKeys as _withKeys } from "vue"
import _imports_0 from '@/assets/images/uploads/pdf.png'


const _hoisted_1 = { class: "card card-container" }
const _hoisted_2 = { class: "flex flex-direction-row justify-space-between" }
const _hoisted_3 = { class: "flex-grow-0 flex-shrink-1" }
const _hoisted_4 = {
  key: 2,
  class: "form-group"
}
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { key: 2 }
const _hoisted_8 = { key: 3 }
const _hoisted_9 = { key: 4 }
const _hoisted_10 = { key: 5 }
const _hoisted_11 = { key: 6 }
const _hoisted_12 = { key: 7 }
const _hoisted_13 = { key: 8 }
const _hoisted_14 = {
  key: 3,
  class: "form-group"
}
const _hoisted_15 = {
  key: 4,
  class: "form-group"
}
const _hoisted_16 = { for: "typeProject" }
const _hoisted_17 = { key: 0 }
const _hoisted_18 = {
  key: 5,
  class: "form-group"
}
const _hoisted_19 = ["readonly"]
const _hoisted_20 = {
  key: 6,
  class: "form-group"
}
const _hoisted_21 = {
  key: 7,
  class: "form-group"
}
const _hoisted_22 = { key: 0 }
const _hoisted_23 = {
  key: 8,
  class: "form-group"
}
const _hoisted_24 = { key: 0 }
const _hoisted_25 = { key: 1 }
const _hoisted_26 = {
  key: 9,
  class: "form-group"
}
const _hoisted_27 = {
  key: 10,
  class: "form-group"
}
const _hoisted_28 = {
  key: 11,
  class: "form-group"
}
const _hoisted_29 = {
  key: 12,
  class: "form-group"
}
const _hoisted_30 = ["href"]
const _hoisted_31 = {
  key: 0,
  src: _imports_0,
  class: "doc-image"
}
const _hoisted_32 = {
  key: 13,
  class: "form-group"
}
const _hoisted_33 = { class: "form-group" }
const _hoisted_34 = { for: "date" }
const _hoisted_35 = {
  key: 0,
  class: "font-color-yellow"
}
const _hoisted_36 = {
  class: "flex flex-direction-column lg:flex-row",
  style: {"column-gap":"4px"}
}
const _hoisted_37 = {
  class: "flex-grow-1",
  style: {"flex-basis":"0"}
}
const _hoisted_38 = { key: 0 }
const _hoisted_39 = { key: 1 }
const _hoisted_40 = { class: "list-item-remove" }
const _hoisted_41 = ["onClick"]
const _hoisted_42 = {
  class: "flex-grow-1",
  style: {"flex-basis":"0"}
}
const _hoisted_43 = { key: 0 }
const _hoisted_44 = { key: 1 }
const _hoisted_45 = {
  class: "font-italic",
  style: {"font-size":"small"}
}
const _hoisted_46 = {
  role: "button",
  class: "hint"
}
const _hoisted_47 = {
  class: "speech_bubble tool_tip_text",
  id: "tooltip",
  style: {"display":"inline"}
}
const _hoisted_48 = {
  type: "button",
  class: "close-btn-tooltip mr-4"
}
const _hoisted_49 = {
  key: 14,
  class: "form-group"
}
const _hoisted_50 = {
  key: 0,
  for: "duration"
}
const _hoisted_51 = {
  key: 1,
  for: "duration"
}
const _hoisted_52 = ["readonly"]
const _hoisted_53 = {
  key: 15,
  class: "form-group"
}
const _hoisted_54 = {
  key: 16,
  class: "form-group"
}
const _hoisted_55 = {
  key: 0,
  for: "noOfFreq"
}
const _hoisted_56 = {
  key: 1,
  for: "noOfFreq"
}
const _hoisted_57 = {
  key: 17,
  class: "form-group"
}
const _hoisted_58 = {
  key: 18,
  class: "form-group"
}
const _hoisted_59 = ["readonly"]
const _hoisted_60 = {
  key: 19,
  class: "form-group"
}
const _hoisted_61 = ["readonly"]
const _hoisted_62 = {
  key: 20,
  class: "form-group"
}
const _hoisted_63 = { style: {"display":"flex","flex-direction":"row","justify-content":"space-between"} }
const _hoisted_64 = { class: "list-item-remove" }
const _hoisted_65 = ["onClick"]
const _hoisted_66 = {
  key: 21,
  class: "form-group"
}
const _hoisted_67 = {
  key: 22,
  class: "form-group"
}
const _hoisted_68 = { for: "visualRep" }
const _hoisted_69 = { title: "Kindly give a description of any demonstration or visual representation you want the practitioner to provide to students in the delivery of this course-support" }
const _hoisted_70 = ["readonly"]
const _hoisted_71 = {
  key: 23,
  class: "form-group"
}
const _hoisted_72 = {
  key: 24,
  class: "form-group"
}
const _hoisted_73 = ["readonly"]
const _hoisted_74 = {
  key: 25,
  class: "form-group"
}
const _hoisted_75 = ["readonly"]
const _hoisted_76 = {
  key: 26,
  class: "form-group"
}
const _hoisted_77 = {
  key: 27,
  class: "form-group"
}
const _hoisted_78 = { key: 0 }
const _hoisted_79 = {
  key: 28,
  class: "form-group"
}
const _hoisted_80 = { key: 0 }
const _hoisted_81 = {
  key: 29,
  class: "form-group"
}
const _hoisted_82 = {
  key: 30,
  class: "form-group"
}
const _hoisted_83 = { key: 0 }
const _hoisted_84 = ["readonly"]
const _hoisted_85 = {
  key: 31,
  class: "form-group"
}
const _hoisted_86 = {
  key: 32,
  class: "form-group"
}
const _hoisted_87 = { key: 0 }
const _hoisted_88 = ["readonly"]
const _hoisted_89 = {
  key: 33,
  class: "form-group"
}
const _hoisted_90 = { key: 34 }
const _hoisted_91 = { class: "form-group flex flex-direction-row align-content-between" }
const _hoisted_92 = { key: 0 }
const _hoisted_93 = { key: 1 }
const _hoisted_94 = { class: "form-group" }
const _hoisted_95 = {
  key: 0,
  class: "form-group"
}
const _hoisted_96 = { class: "form-group text-align-center" }
const _hoisted_97 = { class: "form-group flex flex-direction-row align-content-between" }
const _hoisted_98 = ["disabled"]
const _hoisted_99 = ["disabled"]
const _hoisted_100 = { class: "spinner-border spinner-border-sm" }

import { ref } from "vue";
import { Field, ErrorMessage, useForm } from "vee-validate";
import * as yup from "yup";
import InstructorService from "@/services/instructor.service.js";
import Multiselect from "@vueform/multiselect";
import PopupModal from "../user/PopupModal.vue";
import {
  displayLabels,
  physicalDisOptions,
  insDeliveryTypeOptions,
  areaOfExpertiseOptions,
  yearsOfExpOptions,
  deptOptions,
  academicLevelOptions,
  showVisualRep,
  showToast,
  showPriorKnowledge,
  insDeliveryTypeSiteVisitOptions,
  isCapstoneTermProject,
  typeOfProjectOptions,
  typeOfFirmOptions,
  sizeOfFirmOptions,
  ethnicityOptions,
  showLocation,
  showClassroomItems,
  showErrorToast,
  getTimezoneName,
  showAssignmentConnected,
  websiteURL,
} from "@/utils";
import { onBeforeRouteLeave, useRouter } from "vue-router";
import { useConfigStore } from "@/stores/ConfigStore";
import { useModal } from "vuestic-ui";


export default /*@__PURE__*/_defineComponent({
  __name: 'CourseRequestForm',
  props: {
    formValues: {},
    action: {}
  },
  setup(__props: any) {

const ConfigStore = useConfigStore();
const router = useRouter();
const { confirm } = useModal();

const courseRequestFields = ConfigStore.config.filter(
  (el) => el.table === "course_request"
);

// eslint-disable-next-line no-undef
const props = __props;

const yearsOfExpOptionsAdded = ["No preference", ...yearsOfExpOptions];
const typeOfFirmOptionsAdded = [
  { label: "No preference", value: "No preference" },
  ...typeOfFirmOptions,
];
const sizeOfFirmOptionsAdded = [
  { label: "No preference", value: "No preference" },
  ...sizeOfFirmOptions,
];
const areaOfExpertiseOptionsAdded = [
  "No preference",
  ...areaOfExpertiseOptions,
];
const ethnicityOptionsAdded = ["No preference", ...ethnicityOptions];

const formatFn = (dateString) => {
  let date = new Date(dateString);
  if (date.getMonth() < 8 && date.getDate() < 9) {
    return `${date.getFullYear()}-0${date.getMonth() + 1}-0${date.getDate()}`;
  } else if (date.getMonth() < 8) {
    return `${date.getFullYear()}-0${date.getMonth() + 1}-${date.getDate()}`;
  } else if (date.getDate() < 9) {
    return `${date.getFullYear()}-${date.getMonth() + 1}-0${date.getDate()}`;
  } else {
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
  }
};
const parseFn = (text) => {
  const [year, month, day] = text.split("-");
  return new Date(year, month - 1, day + 1);
};

const schema = yup.object().shape({
  fields: yup.array().default(courseRequestFields),
  academicLevel: yup
    .array()
    .of(yup.string())
    .when("fields", {
      is: (val) => !val.find((el) => el.name === "academicLevel")?.disabled,
      then: (schema) =>
        schema
          .required("Students' academic level is required!")
          .min(1, "Students' academic level is required!"),
      otherwise: (schema) => schema.optional().nullable(),
    }),
  areaOfExpertise: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "areaOfExpertise")?.disabled,
    then: (schema) =>
      schema.required("Area of Expertise/Specialization is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  classSize: yup
    .number()
    .label("Class Size")
    .when("fields", {
      is: (val) => !val.find((el) => el.name === "classSize")?.disabled,
      then: (schema) =>
        schema
          .typeError("Class Size must be a number!")
          .positive()
          .integer()
          .required("Class Size is required!"),
      otherwise: (schema) => schema.optional().nullable(),
    }),
  courseRequestType: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "courseRequestType")?.disabled,
    then: (schema) => schema.required("Course-Support Type is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),

  duration: yup
    .number()
    .label("Duration of Course-Support")
    .when("fields", {
      is: (val) => !val.find((el) => el.name === "duration")?.disabled,
      then: (schema) =>
        schema
          .typeError("Duration of Course-Support must be a number!")
          .positive()
          .integer()
          .required("Duration of Course-Support is required!"),
      otherwise: (schema) => schema.optional().nullable(),
    }),
  ethnicity: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "ethnicity")?.disabled,
    then: (schema) => schema.required("Ethnicity is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  gender: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "gender")?.disabled,
    then: (schema) => schema.required("Gender Identity is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  insDeliveryType: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "insDeliveryType")?.disabled,
    then: (schema) => schema.required("Instruction Delivery Mode is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  learning: yup
    .array()
    .of(yup.string())
    .when("fields", {
      is: (val) => !val.find((el) => el.name === "learning")?.disabled,
      then: (schema) =>
        schema
          .required("Learning objective/Expected student Outcome is required!")
          .min(1, "Learning Objective/Expected Student Outcome is required!"),
      otherwise: (schema) => schema.optional().nullable().min(0),
    }),
  levelEducation: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "levelEducation")?.disabled,
    then: (schema) => schema.required("Level of Education is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  needForPreVisit: yup.string().when(["fields", "courseRequestType"], {
    is: (fields, courseRequestType) =>
      !fields.find((el) => el.name === "levelEducation")?.disabled &&
      courseRequestType == "Site Visit",
    then: (schema) =>
      schema.required("Need for Pre-Visit by the instructor is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  noOfFreq: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "noOfFreq")?.disabled,
    then: (schema) =>
      schema.required("Frequency of Course-Support is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  physicalDis: yup
    .array()
    .of(yup.string())
    .when(["fields", "courseRequestType", "insDeliveryType"], {
      is: (fields, courseRequestType, insDeliveryType) =>
        !fields.find((el) => el.name === "physicalDis")?.disabled &&
        courseRequestType == "Site Visit" &&
        insDeliveryType !== "Virtual site visit",
      then: (schema) =>
        schema
          .required("Physical disabilities of students is required!")
          .min(1, "Physical disabilities of students is required!"),
      otherwise: (schema) => schema.optional().min(0),
    }),
  siteOffice: yup.string().when(["fields", "courseRequestType"], {
    is: (fields, courseRequestType) =>
      !fields.find((el) => el.name === "siteOffice")?.disabled &&
      courseRequestType == "Site Visit",
    then: (schema) =>
      schema.required(
        "Need to visit the office trailer/site office is required!"
      ),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  studentDept: yup
    .array()
    .of(yup.string())
    .when("fields", {
      is: (val) => !val.find((el) => el.name === "studentDept")?.disabled,
      then: (schema) =>
        schema
          .required("Students' department or Program of study is required!")
          .min(1, "Students' department or Program of study is required!"),
      otherwise: (schema) => schema.optional().nullable().min(0),
    }),
  topic: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "topic")?.disabled,
    then: (schema) =>
      schema
        .required("Topic requiring Course-Support is required!")
        .max(
          254,
          "Topic requiring Course-Support cannot be greater than 255 characters"
        ),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  visualRep: yup.string().when(["fields", "courseRequestType"], {
    is: (fields, courseRequestType) =>
      !fields.find((el) => el.name === "visualRep")?.disabled &&
      showVisualRep(courseRequestType),
    then: (schema) =>
      schema
        .required(
          "Describe Specific demonstrations/ Visual Representation Required is required!"
        )
        .max(
          254,
          "Describe Specific demonstrations/ Visual Representation Required cannot be greater than 255 characters"
        ),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  priorKnowledge: yup.string().when(["fields", "courseRequestType"], {
    is: (fields, courseRequestType) =>
      !fields.find((el) => el.name === "priorKnowledge")?.disabled &&
      showPriorKnowledge(courseRequestType),
    then: (schema) =>
      schema
        .required(
          "Students' Prior Knowledge About Topic Requiring Course-Support is required!"
        )
        .max(
          499,
          "Students' Prior Knowledge About Topic Requiring Course-Support cannot be greater than 500 characters"
        ),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  yearsOfExp: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "yearsOfExp")?.disabled,
    then: (schema) => schema.required("Years of Experience is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  date: yup
    .array()
    .of(
      yup
        .date()
        .min(new Date(), "Date for Course-Support cannot be in the past!")
    )
    .when(["fields", "courseRequestType"], {
      is: (fields, courseRequestType) =>
        !fields.find((el) => el.name === "date")?.disabled &&
        courseRequestType !== "Mentor for Project",
      then: (schema) =>
        schema
          .required("Date for Course-Support is required!")
          .min(1, "Date for Course-Support is required!"),
      otherwise: (schema) => schema.optional().min(0).nullable(),
    }),
  time: yup
    .array()
    .of(yup.string())
    .when(["fields", "courseRequestType"], {
      is: (fields, courseRequestType) =>
        !fields.find((el) => el.name === "time")?.disabled &&
        courseRequestType !== "Mentor for Project",
      then: (schema) =>
        schema
          .required("Time for Course-Support is required!")
          .min(1, "Time for Course-Support is required!"),
      otherwise: (schema) => schema.optional().min(0).nullable(),
    }),
  generalInfo: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "generalInfo")?.disabled,
    then: (schema) =>
      schema
        .required("Detailed Description of Course-Support Needed is required!")
        .max(
          499,
          "Topic requiring Course-Support cannot be greater than 500 characters"
        ),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  courseCode: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "courseCode")?.disabled,
    then: (schema) => schema.required("Course Code is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  courseTitle: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "courseTitle")?.disabled,
    then: (schema) => schema.required("Course Title is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  typeProject: yup.string().when(["fields", "courseRequestType"], {
    is: (fields, courseRequestType) =>
      !fields.find((el) => el.name === "typeProject")?.disabled &&
      isCapstoneTermProject(courseRequestType),
    then: (schema) => schema.required("Type of Project is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  location: yup.string().when(["fields", "courseRequestType"], {
    is: (fields, courseRequestType) =>
      !fields.find((el) => el.name === "location")?.disabled &&
      showLocation(courseRequestType),
    then: (schema) =>
      schema.required("Location of Course-Support is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  projectDescr: yup.string().when(["fields", "courseRequestType"], {
    is: (fields, courseRequestType) =>
      !fields.find((el) => el.name === "projectDescr")?.disabled &&
      isCapstoneTermProject(courseRequestType),
    then: (schema) =>
      schema
        .required("Description of Project is required!")
        .max(
          499,
          "Description of Project cannot be greater than 500 characters"
        ),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  daysOfMeeting: yup.string().when(["fields", "courseRequestType"], {
    is: (fields, courseRequestType) =>
      !fields.find((el) => el.name === "daysOfMeeting")?.disabled &&
      courseRequestType === "Mentor for Project",
    then: (schema) =>
      schema.required("Days of Meeting with Students is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  startDate: yup.string().when(["fields", "courseRequestType"], {
    is: (fields, courseRequestType) =>
      !fields.find((el) => el.name === "startDate")?.disabled &&
      courseRequestType === "Mentor for Project",
    then: (schema) =>
      schema.required("Date Range for Course-Support is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  endDate: yup.string().when(["fields", "courseRequestType"], {
    is: (fields, courseRequestType) =>
      !fields.find((el) => el.name === "endDate")?.disabled &&
      courseRequestType === "Mentor for Project",
    then: (schema) =>
      schema.required("Date Range for Course-Support is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  classroomItems: yup
    .string()
    .optional()
    .nullable()
    .max(
      254,
      "Available items in the classroom cannot be greater than 255 characters"
    ),
  assigmentConnected: yup
    .string()
    .optional()
    .nullable()
    .max(
      499,
      "Is there any Student Assignment Connected to this Course-Support cannot be greater than 500 characters"
    ),
  additionalInformation: yup.string().optional().nullable(),
  typeFirm: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "typeFirm")?.disabled,
    then: (schema) => schema.required("Type of Firm is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  sizeFirm: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "sizeFirm")?.disabled,
    then: (schema) => schema.required("Size of Firm is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  semester: yup.string(),
  year: yup.string(),
});

const { handleSubmit, errors, values, meta, resetForm } = useForm({
  validationSchema: schema,
});
resetForm({
  values: props.formValues,
});

onBeforeRouteLeave(async (to, from) => {
  if (meta.value.dirty && !loading.value && !successful.value) {
    const answer = await confirm({
      message:
        "Your changes on this page have not been saved. Kindly save your changes before leaving the page. Do you want to proceed without saving your changes?",
      okText: "Yes",
      cancelText: "No",
    });
    if (!answer) return false;
  }
});

let loading = ref(false);
let successful = ref(false);
let message = ref("");
let isVisible = ref(false);
let isRecommendationsVisible = ref(false);
let showRecommendationsModal = ref(false);
let date_text = ref("");
let time_text = ref("");
let learning_text = ref("");
let elementVisible = ref(false);
let tooltipTimeout;

const today = new Date(new Date().setDate(new Date().getDate() + 1))
  .toISOString()
  .substring(0, 10);

const showTooltip = () => {
  elementVisible.value = true;
  tooltipTimeout = setTimeout(() => (elementVisible.value = false), 5000);
};
const hideTooltip = () => {
  elementVisible.value = false;
  clearTimeout(tooltipTimeout);
};
const showPopup = () => {
  isVisible.value = true;
};
const hidePopup = () => {
  isVisible.value = false;
};

const uploadFile = (e) => {
  values["files"] = e.target.files || e.dataTransfer.files;
  if (!values["files"].length) return;
};

const saveTempCourseRequest = () => {
  loading.value = true;
  if (values.courseRequestType === "Others")
    values.courseRequestType = values["courseRequestType-text"];
  if (values?.studentDept?.includes("Others"))
    values.studentDept.push(values["studentDept_text"]);
  if (values.gender === "Others") values.gender = values["gender-text"];
  if (values.ethnicity === "Others")
    values.ethnicity = values["ethnicity-text"];
  if (values.areaOfExpertise === "Others")
    values.areaOfExpertise = values["areaOfExpertise-text"];
  if (values.typeFirm === "Others") values.typeFirm = values["typeFirm_text"];
  if (values.courseRequestType === "Mentor for Project") {
    values.date.push(formatFn(values["startDate"]));
    values.time.push(values["time_text"]);
    values["endDate"] = formatFn(values["endDate"]);
  }
  InstructorService.saveTempCourseRequest(values).then(
    (response) => {
      loading.value = false;
      successful.value = true;
      showToast("Your course-support request has been saved.");
      router.push({ name: "instructor-feed" });
    },
    (error) => {
      loading.value = false;
      message.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};

const saveCourseRequest = handleSubmit(
  (data) => {
    console.log("validated while submitting");
    loading.value = true;
    if (data.courseRequestType === "Others")
      data.courseRequestType = data["courseRequestType-text"];
    if (data.studentDept.includes("Others"))
      data.studentDept.push(data["studentDept_text"]);
    if (data.gender === "Others") data.gender = data["gender-text"];
    if (data.ethnicity === "Others") data.ethnicity = data["ethnicity-text"];
    if (data.areaOfExpertise === "Others")
      data.areaOfExpertise = data["areaOfExpertise-text"];
    if (data.typeFirm === "Others") data.typeFirm = data["typeFirm_text"];
    if (data.courseRequestType === "Mentor for Project") {
      data.date.length = 0;
      data.date.push(formatFn(data["startDate"]));
      data.time.length = 0;
      data.time.push(data["time_text"]);
      data["endDate"] = formatFn(data["endDate"]);
    }
    InstructorService.saveCourseRequest(data, props.action).then(
      (response) => {
        // console.log(response.data);
        loading.value = false;
        successful.value = true;
        showToast(
          "Your course-support request has been raised/updated. You can view recommendations or check your notifications later for response(s) from practitioner(s)."
        );
        router.push({ name: "instructor-feed" });
      },
      (error) => {
        loading.value = false;
        message.value =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  },
  (error) => {
    console.log("error while submitting");
    isRecommendationsVisible.value = false;
    Object.values(error.errors).forEach((error) => showErrorToast(error));
  }
);

const recommendationsModalAccept = () => {
  showRecommendationsModal.value = false;
  showToast(
    "Your course-support request has been raised/updated. You can view recommendations or check your notifications later for response(s) from practitioner(s)."
  );
  router.push({ name: "instructor-feed" });
};

const removeTime = (val) => {
  let index = values.date.indexOf(val);
  values.date.splice(index, 1);
  values.time.splice(index, 1);
};
const addTime = () => {
  if (
    date_text.value.length > 0 &&
    time_text.value.length > 0 &&
    !(
      values.date.includes(date_text.value) &&
      values.time.includes(time_text.value)
    )
  ) {
    values.date.push(date_text.value);
    values.time.push(time_text.value);
    date_text.value = "";
    time_text.value = "";
  }
};
const removeLearningObjectives = (val) => {
  values.learning.splice(values.learning.indexOf(val), 1);
};
const addLearningObjectives = () => {
  if (
    !values.learning.includes(learning_text.value) &&
    learning_text.value.length > 0
  )
    values.learning.push(learning_text.value);
  learning_text.value = "";
};

return (_ctx: any,_cache: any) => {
  const _component_va_date_input = _resolveComponent("va-date-input")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_2, [
        _cache[49] || (_cache[49] = _createElementVNode("span", { class: "font-color-red" }, "* required field", -1)),
        _createElementVNode("span", _hoisted_3, _toDisplayString(_unref(values).semester) + " " + _toDisplayString(_unref(values).year), 1)
      ]),
      _createElementVNode("form", {
        onSubmit: _cache[47] || (_cache[47] = 
//@ts-ignore
(...args) => (_unref(saveCourseRequest) && _unref(saveCourseRequest)(...args))),
        onKeydown: _cache[48] || (_cache[48] = _withKeys(($event: any) => ($event.preventDefault()), ["enter"])),
        enctype: "multipart/form-data"
      }, [
        _cache[121] || (_cache[121] = _createElementVNode("div", { class: "align-center bold-text flex flex-direction-column" }, [
          _createTextVNode(" General Information "),
          _createElementVNode("span", { style: {"font-style":"italic","font-size":"small"} }, "This information will be shared with industry practitioner")
        ], -1)),
        (
            !_unref(courseRequestFields).find((el) => el.name === 'semester')?.disabled
          )
          ? (_openBlock(), _createBlock(_unref(Field), {
              key: 0,
              name: "semester",
              hidden: ""
            }))
          : _createCommentVNode("", true),
        (!_unref(courseRequestFields).find((el) => el.name === 'year')?.disabled)
          ? (_openBlock(), _createBlock(_unref(Field), {
              key: 1,
              name: "year",
              hidden: ""
            }))
          : _createCommentVNode("", true),
        (
            !_unref(courseRequestFields).find((el) => el.name === 'courseRequestType')
              ?.disabled
          )
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _cache[58] || (_cache[58] = _createElementVNode("label", { for: "courseRequestType" }, [
                _createTextVNode("Course-Support Type"),
                _createElementVNode("span", { class: "super font-color-red" }, "*")
              ], -1)),
              _createVNode(_unref(Field), {
                modelValue: _unref(values).courseRequestType,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(values).courseRequestType) = $event)),
                name: "courseRequestType",
                type: "text",
                class: _normalizeClass(["dropdown", { 'non-editable-select': props.action === 'view' }])
              }, {
                default: _withCtx(({ field }) => [
                  _createVNode(_unref(Multiselect), _mergeProps(field, {
                    modelValue: _unref(values).courseRequestType,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(values).courseRequestType) = $event)),
                    options: [
                { label: 'Seminar', value: 'Seminar' },
                { label: 'Site Visit', value: 'Site Visit' },
                { label: 'Workshop', value: 'Workshop' },
                { label: 'Guest Lecture', value: 'Guest Lecture' },
                { label: 'Judge for Project', value: 'Judge for Project' },
                { label: 'Mentor for Project', value: 'Mentor for Project' },
                {
                  label: 'Sponsor for Project (Context Provider)',
                  value: 'Sponsor for Project',
                },
                { label: 'Labs', value: 'Labs' },
                { label: 'Others, please specify', value: 'Others' },
              ],
                    mode: "single",
                    searchable: true,
                    disabled: props.action === 'view',
                    class: { 'field-bg-color': !_unref(meta).dirty && !_unref(meta).valid }
                  }), null, 16, ["modelValue", "disabled", "class"])
                ]),
                _: 1
              }, 8, ["modelValue", "class"]),
              (_unref(values).courseRequestType === 'Others')
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    _createVNode(_unref(Field), {
                      name: "courseRequestType-text",
                      type: "text",
                      placeholder: "Please Specify",
                      class: "form-control bg-others"
                    })
                  ]))
                : _createCommentVNode("", true),
              _createVNode(_unref(ErrorMessage), {
                name: "courseRequestType",
                class: "error-feedback"
              }),
              (_unref(values).courseRequestType === 'Site Visit')
                ? (_openBlock(), _createElementBlock("span", _hoisted_6, _cache[50] || (_cache[50] = [
                    _createElementVNode("b", null, "Site visit", -1),
                    _createTextVNode(" is a means to expose students to the workplace and work practices on construction job sites.")
                  ])))
                : _createCommentVNode("", true),
              (_unref(values).courseRequestType === 'Guest Lecture')
                ? (_openBlock(), _createElementBlock("span", _hoisted_7, _cache[51] || (_cache[51] = [
                    _createElementVNode("b", null, "Guest lecture", -1),
                    _createTextVNode(" entails bringing practitioners into the classroom to discuss technical topics from their experience.")
                  ])))
                : _createCommentVNode("", true),
              (_unref(values).courseRequestType === 'Seminar')
                ? (_openBlock(), _createElementBlock("span", _hoisted_8, _cache[52] || (_cache[52] = [
                    _createElementVNode("b", null, "Seminar", -1),
                    _createTextVNode(" is for sharing knowledge, and facilitating discussions on a specific topic or subject. It can also be an avenue to present their ideas and experiences to students.")
                  ])))
                : _createCommentVNode("", true),
              (_unref(values).courseRequestType === 'Workshop')
                ? (_openBlock(), _createElementBlock("span", _hoisted_9, _cache[53] || (_cache[53] = [
                    _createElementVNode("b", null, "Workshop", -1),
                    _createTextVNode(" is an interactive learning and skill-building session to provide students with hands-on experience and practical training in a specific area or to develop certain skills.")
                  ])))
                : _createCommentVNode("", true),
              (_unref(values).courseRequestType === 'Labs')
                ? (_openBlock(), _createElementBlock("span", _hoisted_10, _cache[54] || (_cache[54] = [
                    _createElementVNode("b", null, "Laboratory session", -1),
                    _createTextVNode(" is a practical, hands-on learning experience that takes place in a laboratory where students get the opportunity to apply theoretical concepts to real-world construction tasks.")
                  ])))
                : _createCommentVNode("", true),
              (_unref(values).courseRequestType === 'Judge for Project')
                ? (_openBlock(), _createElementBlock("span", _hoisted_11, _cache[55] || (_cache[55] = [
                    _createElementVNode("b", null, "Judge for project", -1),
                    _createTextVNode(" involves assessing and appraising students’ projects based on established guidelines, client expectations, and project outcomes.")
                  ])))
                : _createCommentVNode("", true),
              (_unref(values).courseRequestType === 'Mentor for Project')
                ? (_openBlock(), _createElementBlock("span", _hoisted_12, _cache[56] || (_cache[56] = [
                    _createElementVNode("b", null, "Mentor for project", -1),
                    _createTextVNode(": This entails providing professional guidance to students throughout the delivery of their project.")
                  ])))
                : _createCommentVNode("", true),
              (_unref(values).courseRequestType === 'Sponsor for Project')
                ? (_openBlock(), _createElementBlock("span", _hoisted_13, _cache[57] || (_cache[57] = [
                    _createElementVNode("b", null, "Sponsor for project", -1),
                    _createTextVNode(": This entails providing context for students’ project and acting as client.")
                  ])))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true),
        (
            !_unref(courseRequestFields).find((el) => el.name === 'insDeliveryType')
              ?.disabled
          )
          ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
              _cache[59] || (_cache[59] = _createElementVNode("label", { for: "insDeliveryType" }, [
                _createTextVNode("Instruction Delivery Mode"),
                _createElementVNode("span", { class: "super font-color-red" }, "*")
              ], -1)),
              _createVNode(_unref(Field), {
                modelValue: _unref(values).insDeliveryType,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_unref(values).insDeliveryType) = $event)),
                name: "insDeliveryType",
                type: "text",
                class: _normalizeClass(["dropdown", { 'non-editable-select': props.action === 'view' }])
              }, {
                default: _withCtx(({ field, meta }) => [
                  (_unref(values).courseRequestType === 'Site Visit')
                    ? (_openBlock(), _createBlock(_unref(Multiselect), _mergeProps({ key: 0 }, field, {
                        modelValue: _unref(values).insDeliveryType,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(values).insDeliveryType) = $event)),
                        options: _unref(insDeliveryTypeSiteVisitOptions),
                        searchable: true,
                        mode: "single",
                        disabled: props.action === 'view',
                        class: { 'field-bg-color': !meta.dirty && !meta.valid }
                      }), null, 16, ["modelValue", "options", "disabled", "class"]))
                    : (_openBlock(), _createBlock(_unref(Multiselect), _mergeProps({ key: 1 }, field, {
                        modelValue: _unref(values).insDeliveryType,
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_unref(values).insDeliveryType) = $event)),
                        options: _unref(insDeliveryTypeOptions),
                        mode: "single",
                        searchable: true,
                        disabled: props.action === 'view',
                        class: { 'field-bg-color': !meta.dirty && !meta.valid }
                      }), null, 16, ["modelValue", "options", "disabled", "class"]))
                ]),
                _: 1
              }, 8, ["modelValue", "class"]),
              _createVNode(_unref(ErrorMessage), {
                name: "insDeliveryType",
                class: "error-feedback"
              })
            ]))
          : _createCommentVNode("", true),
        (
            !_unref(courseRequestFields).find((el) => el.name === 'typeProject')
              ?.disabled && _unref(isCapstoneTermProject)(_unref(values).courseRequestType)
          )
          ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
              _createElementVNode("label", _hoisted_16, [
                _cache[60] || (_cache[60] = _createTextVNode(" Type of Project")),
                (_unref(values).courseRequestType === 'Sponsor for Project')
                  ? (_openBlock(), _createElementBlock("span", _hoisted_17, " Required"))
                  : _createCommentVNode("", true),
                _cache[61] || (_cache[61] = _createElementVNode("span", { class: "super font-color-red" }, "*", -1))
              ]),
              _createVNode(_unref(Field), {
                modelValue: _unref(values).typeProject,
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_unref(values).typeProject) = $event)),
                name: "typeProject",
                type: "text",
                class: _normalizeClass(["dropdown", { 'non-editable-select': props.action === 'view' }])
              }, {
                default: _withCtx(({ field }) => [
                  _createVNode(_unref(Multiselect), _mergeProps(field, {
                    modelValue: _unref(values).typeProject,
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_unref(values).typeProject) = $event)),
                    options: _unref(typeOfProjectOptions),
                    mode: "single",
                    searchable: true,
                    disabled: props.action === 'view'
                  }), null, 16, ["modelValue", "options", "disabled"])
                ]),
                _: 1
              }, 8, ["modelValue", "class"]),
              _createVNode(_unref(ErrorMessage), {
                name: "typeProject",
                class: "error-feedback"
              })
            ]))
          : _createCommentVNode("", true),
        (
            !_unref(courseRequestFields).find((el) => el.name === 'projectDescr')
              ?.disabled && _unref(isCapstoneTermProject)(_unref(values).courseRequestType)
          )
          ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
              _cache[62] || (_cache[62] = _createElementVNode("label", { for: "projectDescr" }, [
                _createTextVNode(" Description of Project"),
                _createElementVNode("span", { class: "super font-color-red" }, "*")
              ], -1)),
              _createVNode(_unref(Field), { name: "projectDescr" }, {
                default: _withCtx(({ field, meta }) => [
                  _createElementVNode("input", _mergeProps(field, {
                    class: [{
                'field-bg-color': !meta.dirty,
                'field-border-error': meta.dirty && !meta.valid,
              }, "form-control"],
                    type: "text",
                    readonly: props.action === 'view'
                  }), null, 16, _hoisted_19),
                  (meta.dirty && !meta.valid)
                    ? (_openBlock(), _createBlock(_unref(ErrorMessage), {
                        key: 0,
                        name: "projectDescr",
                        class: "error-feedback"
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ]))
          : _createCommentVNode("", true),
        (
            !_unref(courseRequestFields).find((el) => el.name === 'academicLevel')
              ?.disabled
          )
          ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
              _cache[63] || (_cache[63] = _createElementVNode("label", { for: "academicLevel" }, [
                _createTextVNode("Students' Academic Level"),
                _createElementVNode("span", { class: "super font-color-red" }, "*")
              ], -1)),
              _createVNode(_unref(Field), {
                modelValue: _unref(values).academicLevel,
                "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_unref(values).academicLevel) = $event)),
                name: "academicLevel",
                type: "text",
                class: _normalizeClass(["dropdown", { 'non-editable-select': props.action === 'view' }])
              }, {
                default: _withCtx(({ field }) => [
                  _createVNode(_unref(Multiselect), _mergeProps(field, {
                    modelValue: _unref(values).academicLevel,
                    "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_unref(values).academicLevel) = $event)),
                    options: _unref(academicLevelOptions),
                    mode: "tags",
                    multipleLabel: _unref(displayLabels),
                    hideSelected: false,
                    closeOnSelect: false,
                    closeOnDeselect: false,
                    disabled: true,
                    searchable: true
                  }), null, 16, ["modelValue", "options", "multipleLabel"])
                ]),
                _: 1
              }, 8, ["modelValue", "class"]),
              _createVNode(_unref(ErrorMessage), {
                name: "academicLevel",
                class: "error-feedback"
              })
            ]))
          : _createCommentVNode("", true),
        (
            !_unref(courseRequestFields).find((el) => el.name === 'studentDept')
              ?.disabled
          )
          ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
              _cache[64] || (_cache[64] = _createElementVNode("label", { for: "studentDept" }, [
                _createTextVNode(" Students' Department or Program of Study"),
                _createElementVNode("span", { class: "super font-color-red" }, "*")
              ], -1)),
              _createVNode(_unref(Field), {
                modelValue: _unref(values).studentDept,
                "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_unref(values).studentDept) = $event)),
                name: "studentDept",
                type: "text",
                class: _normalizeClass(["dropdown", { 'non-editable-select': props.action === 'view' }])
              }, {
                default: _withCtx(({ field }) => [
                  _createVNode(_unref(Multiselect), _mergeProps(field, {
                    modelValue: _unref(values).studentDept,
                    "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_unref(values).studentDept) = $event)),
                    options: _unref(deptOptions),
                    mode: "tags",
                    multipleLabel: _unref(displayLabels),
                    hideSelected: false,
                    closeOnSelect: false,
                    closeOnDeselect: false,
                    disabled: true,
                    searchable: true
                  }), null, 16, ["modelValue", "options", "multipleLabel"])
                ]),
                _: 1
              }, 8, ["modelValue", "class"]),
              (_unref(values).studentDept && _unref(values).studentDept.includes('Others'))
                ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
                    _createVNode(_unref(Field), {
                      name: "studentDept_text",
                      type: "text",
                      placeholder: "Please Specify",
                      class: "form-control bg-others",
                      readonly: props.action === 'view'
                    }, null, 8, ["readonly"])
                  ]))
                : _createCommentVNode("", true),
              _createVNode(_unref(ErrorMessage), {
                name: "studentDept",
                class: "error-feedback"
              })
            ]))
          : _createCommentVNode("", true),
        (
            !_unref(courseRequestFields).find((el) => el.name === 'classSize')?.disabled
          )
          ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
              (_unref(values).courseRequestType !== 'Mentor for Project')
                ? (_openBlock(), _createElementBlock("span", _hoisted_24, _cache[65] || (_cache[65] = [
                    _createElementVNode("label", { for: "classSize" }, [
                      _createTextVNode("Class Size"),
                      _createElementVNode("span", { class: "super font-color-red" }, "*")
                    ], -1)
                  ])))
                : (_openBlock(), _createElementBlock("span", _hoisted_25, _cache[66] || (_cache[66] = [
                    _createElementVNode("label", { for: "classSize" }, [
                      _createTextVNode("Size of student group to mentor"),
                      _createElementVNode("span", { class: "super font-color-red" }, "*")
                    ], -1)
                  ]))),
              _createVNode(_unref(Field), { name: "classSize" }, {
                default: _withCtx(({ field, meta }) => [
                  _createElementVNode("input", _mergeProps(field, {
                    class: [{
                'field-bg-color': !meta.dirty,
                'field-border-error': meta.dirty && !meta.valid,
              }, "form-control"],
                    type: "text",
                    readonly: "true"
                  }), null, 16),
                  (meta.dirty && !meta.valid)
                    ? (_openBlock(), _createBlock(_unref(ErrorMessage), {
                        key: 0,
                        name: "classSize",
                        class: "error-feedback"
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ]))
          : _createCommentVNode("", true),
        (
            !_unref(courseRequestFields).find((el) => el.name === 'physicalDis')
              ?.disabled &&
            _unref(values).courseRequestType === 'Site Visit' &&
            _unref(values).insDeliveryType !== 'Virtual site visit'
          )
          ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
              _cache[67] || (_cache[67] = _createElementVNode("label", { for: "physicalDis" }, [
                _createTextVNode("Physical Disabilities of Students"),
                _createElementVNode("span", { class: "super font-color-red" }, "*")
              ], -1)),
              _createVNode(_unref(Field), {
                modelValue: _unref(values).physicalDis,
                "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_unref(values).physicalDis) = $event)),
                name: "physicalDis",
                type: "text",
                class: _normalizeClass(["form-control", { 'non-editable-select': props.action === 'view' }])
              }, {
                default: _withCtx(({ field }) => [
                  _createVNode(_unref(Multiselect), _mergeProps(field, {
                    modelValue: _unref(values).physicalDis,
                    "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_unref(values).physicalDis) = $event)),
                    options: _unref(physicalDisOptions),
                    mode: "multiple",
                    searchable: true,
                    multipleLabel: _unref(displayLabels),
                    disabled: props.action === 'view'
                  }), null, 16, ["modelValue", "options", "multipleLabel", "disabled"])
                ]),
                _: 1
              }, 8, ["modelValue", "class"]),
              _createVNode(_unref(ErrorMessage), {
                name: "physicalDis",
                class: "error-feedback"
              })
            ]))
          : _createCommentVNode("", true),
        (
            !_unref(courseRequestFields).find((el) => el.name === 'courseCode')
              ?.disabled
          )
          ? (_openBlock(), _createElementBlock("div", _hoisted_27, [
              _cache[68] || (_cache[68] = _createElementVNode("label", { for: "courseCode" }, [
                _createTextVNode("Course Code"),
                _createElementVNode("span", { class: "super font-color-red" }, "*")
              ], -1)),
              _createVNode(_unref(Field), { name: "courseCode" }, {
                default: _withCtx(({ field, meta }) => [
                  _createElementVNode("input", _mergeProps(field, {
                    class: "form-control",
                    type: "text",
                    readonly: true
                  }), null, 16),
                  (meta.dirty && !meta.valid)
                    ? (_openBlock(), _createBlock(_unref(ErrorMessage), {
                        key: 0,
                        name: "courseCode",
                        class: "error-feedback"
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ]))
          : _createCommentVNode("", true),
        (
            !_unref(courseRequestFields).find((el) => el.name === 'courseTitle')
              ?.disabled
          )
          ? (_openBlock(), _createElementBlock("div", _hoisted_28, [
              _cache[69] || (_cache[69] = _createElementVNode("label", { for: "courseTitle" }, [
                _createTextVNode("Course Title"),
                _createElementVNode("span", { class: "super font-color-red" }, "*")
              ], -1)),
              _createVNode(_unref(Field), { name: "courseTitle" }, {
                default: _withCtx(({ field, meta }) => [
                  _createElementVNode("input", _mergeProps(field, {
                    class: "form-control",
                    type: "text",
                    readonly: true
                  }), null, 16),
                  (meta.dirty && !meta.valid)
                    ? (_openBlock(), _createBlock(_unref(ErrorMessage), {
                        key: 0,
                        name: "courseTitle",
                        class: "error-feedback"
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ]))
          : _createCommentVNode("", true),
        (
            !_unref(courseRequestFields).find((el) => el.name === 'file')?.disabled &&
            _unref(values).courseRequestType === 'Judge for Project'
          )
          ? (_openBlock(), _createElementBlock("div", _hoisted_29, [
              _cache[70] || (_cache[70] = _createElementVNode("label", { for: "file" }, "RFP (Request for proposal)", -1)),
              _createElementVNode("input", {
                type: "file",
                name: "file",
                onChange: uploadFile,
                accept: "application/pdf",
                class: "form-control"
              }, null, 32),
              _createElementVNode("a", {
                href: _unref(websiteURL) + '/course_support/' + _unref(values).fileName,
                download: "",
                style: {"color":"var(--bs-body-color)"},
                target: "_blank"
              }, [
                (_unref(values).fileName)
                  ? (_openBlock(), _createElementBlock("img", _hoisted_31))
                  : _createCommentVNode("", true)
              ], 8, _hoisted_30),
              (_unref(meta).dirty && !_unref(meta).valid)
                ? (_openBlock(), _createBlock(_unref(ErrorMessage), {
                    key: 0,
                    name: "file",
                    class: "error-feedback"
                  }))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true),
        (
            !_unref(courseRequestFields).find(
              (el) => el.name === 'additionalInformation'
            )?.disabled
          )
          ? (_openBlock(), _createElementBlock("div", _hoisted_32, [
              _cache[71] || (_cache[71] = _createElementVNode("label", { for: "additionalInformation" }, "Link to Additional Course Information", -1)),
              _createVNode(_unref(Field), { name: "additionalInformation" }, {
                default: _withCtx(({ field, meta }) => [
                  _createElementVNode("input", _mergeProps(field, {
                    class: "form-control",
                    type: "text",
                    placeholder: "https://",
                    readonly: true
                  }), null, 16),
                  (meta.dirty && !meta.valid)
                    ? (_openBlock(), _createBlock(_unref(ErrorMessage), {
                        key: 0,
                        name: "additionalInformation",
                        class: "error-feedback"
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_33, [
          _createElementVNode("label", _hoisted_34, [
            _cache[72] || (_cache[72] = _createTextVNode("When the Course-Support is Needed")),
            _cache[73] || (_cache[73] = _createElementVNode("span", { class: "super font-color-red" }, "*", -1)),
            (_unref(values).courseRequestType !== 'Mentor for Project')
              ? (_openBlock(), _createElementBlock("span", _hoisted_35, " (Multiple Date & Time are allowed) "))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_36, [
            _createElementVNode("div", _hoisted_37, [
              (_unref(values).courseRequestType === 'Mentor for Project')
                ? (_openBlock(), _createElementBlock("div", _hoisted_38, [
                    (
                    !_unref(courseRequestFields).find((el) => el.name === 'startDate')
                      ?.disabled
                  )
                      ? (_openBlock(), _createBlock(_unref(Field), {
                          key: 0,
                          name: "startDate",
                          modelValue: _unref(values).startDate,
                          "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_unref(values).startDate) = $event))
                        }, {
                          default: _withCtx(({ handleChange, handleBlur }) => [
                            _createVNode(_component_va_date_input, {
                              modelValue: _unref(values).startDate,
                              "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_unref(values).startDate) = $event)),
                              class: _normalizeClass({
                      'field-bg-color': !_unref(meta).dirty,
                      'field-border-error': _unref(meta).dirty && !_unref(meta).valid,
                    }),
                              readonly: props.action === 'view',
                              format: formatFn,
                              parse: parseFn,
                              background: "#fff",
                              "manual-input": "",
                              style: {"border":"none","width":"100%"},
                              onChange: handleChange,
                              onBlur: handleBlur,
                              label: "Start Date"
                            }, null, 8, ["modelValue", "class", "readonly", "onChange", "onBlur"])
                          ]),
                          _: 1
                        }, 8, ["modelValue"]))
                      : _createCommentVNode("", true),
                    (
                    !_unref(courseRequestFields).find((el) => el.name === 'endDate')
                      ?.disabled
                  )
                      ? (_openBlock(), _createBlock(_unref(Field), {
                          key: 1,
                          name: "endDate",
                          modelValue: _unref(values).endDate,
                          "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_unref(values).endDate) = $event))
                        }, {
                          default: _withCtx(({ handleChange, handleBlur }) => [
                            _createVNode(_component_va_date_input, {
                              modelValue: _unref(values).endDate,
                              "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_unref(values).endDate) = $event)),
                              class: _normalizeClass({
                      'field-bg-color': !_unref(meta).dirty,
                      'field-border-error': _unref(meta).dirty && !_unref(meta).valid,
                    }),
                              readonly: props.action === 'view',
                              background: "#fff",
                              "manual-input": "",
                              style: {"border":"none","width":"100%"},
                              format: formatFn,
                              parse: parseFn,
                              onChange: handleChange,
                              onBlur: handleBlur,
                              label: "End Date"
                            }, null, 8, ["modelValue", "class", "readonly", "onChange", "onBlur"])
                          ]),
                          _: 1
                        }, 8, ["modelValue"]))
                      : _createCommentVNode("", true),
                    _createVNode(_unref(ErrorMessage), {
                      name: "date",
                      class: "error-feedback"
                    })
                  ]))
                : (
                  !_unref(courseRequestFields).find((el) => el.name === 'date')
                    ?.disabled
                )
                  ? (_openBlock(), _createElementBlock("div", _hoisted_39, [
                      _createVNode(_unref(Field), {
                        name: "date_text",
                        type: "date",
                        class: "form-control",
                        modelValue: _unref(date_text),
                        "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => (_isRef(date_text) ? (date_text).value = $event : date_text = $event)),
                        min: _unref(today)
                      }, null, 8, ["modelValue", "min"]),
                      _createVNode(_unref(ErrorMessage), {
                        name: "date_text",
                        class: "error-feedback"
                      }),
                      (_unref(values).date != null)
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 0,
                            style: {"display":"flex","flex-wrap":"wrap"},
                            class: _normalizeClass({ 'pointer-events-none': props.action === 'view' })
                          }, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(values).date, (item, index) => {
                              return (_openBlock(), _createElementBlock("span", {
                                key: item,
                                class: "list-item"
                              }, [
                                _createTextVNode(_toDisplayString(item + " at " + _unref(values).time[index]) + " ", 1),
                                _createElementVNode("span", _hoisted_40, [
                                  _createElementVNode("span", {
                                    class: "list-item-remove-icon",
                                    onClick: ($event: any) => (removeTime(item))
                                  }, null, 8, _hoisted_41)
                                ])
                              ]))
                            }), 128))
                          ], 2))
                        : _createCommentVNode("", true)
                    ]))
                  : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_42, [
              (
                  !_unref(courseRequestFields).find((el) => el.name === 'time')
                    ?.disabled &&
                  _unref(values).courseRequestType === 'Mentor for Project'
                )
                ? (_openBlock(), _createElementBlock("div", _hoisted_43, [
                    _createVNode(_unref(Field), {
                      name: "time_text",
                      type: "time",
                      class: "form-control",
                      modelValue: _unref(time_text),
                      "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => (_isRef(time_text) ? (time_text).value = $event : time_text = $event))
                    }, null, 8, ["modelValue"]),
                    _createVNode(_unref(ErrorMessage), {
                      name: "time",
                      class: "error-feedback"
                    })
                  ]))
                : (
                  !_unref(courseRequestFields).find((el) => el.name === 'time')
                    ?.disabled
                )
                  ? (_openBlock(), _createElementBlock("div", _hoisted_44, [
                      _createVNode(_unref(Field), {
                        name: "time_text",
                        type: "time",
                        class: "form-control",
                        modelValue: _unref(time_text),
                        "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => (_isRef(time_text) ? (time_text).value = $event : time_text = $event))
                      }, null, 8, ["modelValue"]),
                      _createVNode(_unref(ErrorMessage), {
                        name: "time_text",
                        class: "error-feedback"
                      })
                    ]))
                  : _createCommentVNode("", true),
              _createElementVNode("span", _hoisted_45, [
                _createTextVNode(_toDisplayString(_unref(getTimezoneName)()) + " ", 1),
                _createElementVNode("span", _hoisted_46, [
                  _createVNode(_component_font_awesome_icon, {
                    icon: "fa-solid fa-circle-question",
                    onClick: showTooltip
                  }),
                  _withDirectives(_createElementVNode("span", _hoisted_47, [
                    _createElementVNode("button", _hoisted_48, [
                      _createVNode(_component_font_awesome_icon, {
                        icon: "fa-solid fa-xmark",
                        class: "close-btn-icon",
                        onClick: hideTooltip
                      })
                    ]),
                    _cache[74] || (_cache[74] = _createElementVNode("p", { style: {"margin-bottom":"0px"} }, " This is your local timezone. ", -1))
                  ], 512), [
                    [_vShow, _unref(elementVisible)]
                  ])
                ])
              ])
            ]),
            (_unref(values).courseRequestType !== 'Mentor for Project')
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: "ml-2 cursor-pointer d-inline flex-shrink-1 flex-grow-0 btn btn-primary w-6rem",
                  style: {"height":"2.25rem"},
                  onClick: addTime
                }, [
                  _createVNode(_component_font_awesome_icon, {
                    style: {"margin":"auto"},
                    icon: "fa-solid fa-plus",
                    title: "Add"
                  }),
                  _cache[75] || (_cache[75] = _createTextVNode("  ")),
                  _cache[76] || (_cache[76] = _createElementVNode("span", null, "Add", -1))
                ]))
              : _createCommentVNode("", true)
          ])
        ]),
        (
            !_unref(courseRequestFields).find((el) => el.name === 'duration')?.disabled
          )
          ? (_openBlock(), _createElementBlock("div", _hoisted_49, [
              (_unref(values).courseRequestType === 'Mentor for Project')
                ? (_openBlock(), _createElementBlock("label", _hoisted_50, _cache[77] || (_cache[77] = [
                    _createTextVNode("Duration of Meeting with Students"),
                    _createElementVNode("span", { class: "super font-color-red" }, "*", -1),
                    _createElementVNode("span", { class: "font-color-yellow" }, " (in minutes)", -1)
                  ])))
                : (_openBlock(), _createElementBlock("label", _hoisted_51, _cache[78] || (_cache[78] = [
                    _createTextVNode("Duration of Course-Support"),
                    _createElementVNode("span", { class: "super font-color-red" }, "*", -1),
                    _createElementVNode("span", { class: "font-color-yellow" }, " (in minutes)", -1)
                  ]))),
              _createVNode(_unref(Field), { name: "duration" }, {
                default: _withCtx(({ field, meta }) => [
                  _createElementVNode("input", _mergeProps(field, {
                    class: [{
                'field-bg-color': !meta.dirty,
                'field-border-error': meta.dirty && !meta.valid,
              }, "form-control"],
                    type: "text",
                    readonly: props.action === 'view'
                  }), null, 16, _hoisted_52),
                  (meta.dirty && !meta.valid)
                    ? (_openBlock(), _createBlock(_unref(ErrorMessage), {
                        key: 0,
                        name: "duration",
                        class: "error-feedback"
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ]))
          : _createCommentVNode("", true),
        (
            !_unref(courseRequestFields).find((el) => el.name === 'location')
              ?.disabled && _unref(showLocation)(_unref(values).courseRequestType)
          )
          ? (_openBlock(), _createElementBlock("div", _hoisted_53, [
              _cache[79] || (_cache[79] = _createElementVNode("label", { for: "location" }, [
                _createTextVNode("Location of Classroom "),
                _createElementVNode("span", { class: "super font-color-red" }, "*"),
                _createElementVNode("span", { class: "font-color-yellow" }, " (Actual Address Including Room Number)")
              ], -1)),
              _createVNode(_unref(Field), { name: "location" }, {
                default: _withCtx(({ field, meta }) => [
                  _createElementVNode("input", _mergeProps(field, {
                    class: "form-control",
                    type: "text",
                    readonly: true
                  }), null, 16),
                  (meta.dirty && !meta.valid)
                    ? (_openBlock(), _createBlock(_unref(ErrorMessage), {
                        key: 0,
                        name: "location",
                        class: "error-feedback"
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ]))
          : _createCommentVNode("", true),
        (
            !_unref(courseRequestFields).find((el) => el.name === 'noOfFreq')?.disabled
          )
          ? (_openBlock(), _createElementBlock("div", _hoisted_54, [
              (_unref(values).courseRequestType === 'Mentor for Project')
                ? (_openBlock(), _createElementBlock("label", _hoisted_55, _cache[80] || (_cache[80] = [
                    _createTextVNode("Frequency of Meeting with Students"),
                    _createElementVNode("span", { class: "super font-color-red" }, "*", -1)
                  ])))
                : (_openBlock(), _createElementBlock("label", _hoisted_56, _cache[81] || (_cache[81] = [
                    _createTextVNode("Frequency of Course-Support"),
                    _createElementVNode("span", { class: "super font-color-red" }, "*", -1),
                    _createElementVNode("span", { class: "font-color-yellow" }, " (How Often?)", -1)
                  ]))),
              _createVNode(_unref(Field), {
                modelValue: _unref(values).noOfFreq,
                "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((_unref(values).noOfFreq) = $event)),
                name: "noOfFreq",
                type: "text",
                class: _normalizeClass(["dropdown", { 'non-editable-select': props.action === 'view' }])
              }, {
                default: _withCtx(({ field, meta }) => [
                  (_unref(values).courseRequestType === 'Mentor for Project')
                    ? (_openBlock(), _createBlock(_unref(Multiselect), _mergeProps({ key: 0 }, field, {
                        modelValue: _unref(values).noOfFreq,
                        "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_unref(values).noOfFreq) = $event)),
                        options: ['Once a week', 'Once in two weeks', 'Once a month'],
                        mode: "single",
                        searchable: true,
                        disabled: props.action === 'view',
                        class: { 'field-bg-color': !meta.dirty && !meta.valid }
                      }), null, 16, ["modelValue", "disabled", "class"]))
                    : (_openBlock(), _createBlock(_unref(Multiselect), _mergeProps({ key: 1 }, field, {
                        modelValue: _unref(values).noOfFreq,
                        "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((_unref(values).noOfFreq) = $event)),
                        options: [
                'One-off',
                'Once in a month',
                'Once in two months',
                'Once in three months',
                'Throughout the semester',
              ],
                        mode: "single",
                        searchable: true,
                        disabled: props.action === 'view',
                        class: { 'field-bg-color': !meta.dirty && !meta.valid }
                      }), null, 16, ["modelValue", "disabled", "class"]))
                ]),
                _: 1
              }, 8, ["modelValue", "class"]),
              _createVNode(_unref(ErrorMessage), {
                name: "noOfFreq",
                class: "error-feedback"
              })
            ]))
          : _createCommentVNode("", true),
        (
            !_unref(courseRequestFields).find((el) => el.name === 'daysOfMeeting')
              ?.disabled && _unref(values).courseRequestType === 'Mentor for Project'
          )
          ? (_openBlock(), _createElementBlock("div", _hoisted_57, [
              _cache[82] || (_cache[82] = _createElementVNode("label", { for: "daysOfMeeting" }, [
                _createTextVNode("Days of Meeting with Students"),
                _createElementVNode("span", { class: "super font-color-red" }, "*")
              ], -1)),
              _createVNode(_unref(Field), {
                modelValue: _unref(values).daysOfMeeting,
                "onUpdate:modelValue": _cache[24] || (_cache[24] = ($event: any) => ((_unref(values).daysOfMeeting) = $event)),
                name: "daysOfMeeting",
                type: "text",
                class: _normalizeClass(["dropdown", { 'non-editable-select': props.action === 'view' }])
              }, {
                default: _withCtx(({ field, meta }) => [
                  _createVNode(_unref(Multiselect), _mergeProps(field, {
                    modelValue: _unref(values).daysOfMeeting,
                    "onUpdate:modelValue": _cache[23] || (_cache[23] = ($event: any) => ((_unref(values).daysOfMeeting) = $event)),
                    options: [
                'Monday',
                'Tuesday',
                'Wednesday',
                'Thursday',
                'Friday',
                'Saturday',
                'Sunday',
              ],
                    mode: "single",
                    searchable: true,
                    disabled: props.action === 'view',
                    class: { 'field-bg-color': !meta.dirty && !meta.valid }
                  }), null, 16, ["modelValue", "disabled", "class"]),
                  (meta.dirty && !meta.valid)
                    ? (_openBlock(), _createBlock(_unref(ErrorMessage), {
                        key: 0,
                        name: "daysOfMeeting",
                        class: "error-feedback"
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["modelValue", "class"])
            ]))
          : _createCommentVNode("", true),
        (
            !_unref(courseRequestFields).find((el) => el.name === 'topic')?.disabled
          )
          ? (_openBlock(), _createElementBlock("div", _hoisted_58, [
              _cache[83] || (_cache[83] = _createElementVNode("label", { for: "topic" }, [
                _createTextVNode("Topic Requiring Course-Support"),
                _createElementVNode("span", { class: "super font-color-red" }, "*")
              ], -1)),
              _createVNode(_unref(Field), { name: "topic" }, {
                default: _withCtx(({ field, meta }) => [
                  _createElementVNode("input", _mergeProps(field, {
                    class: [{
                'field-bg-color': !meta.dirty,
                'field-border-error': meta.dirty && !meta.valid,
              }, "form-control"],
                    type: "text",
                    readonly: props.action === 'view'
                  }), null, 16, _hoisted_59),
                  (meta.dirty && !meta.valid)
                    ? (_openBlock(), _createBlock(_unref(ErrorMessage), {
                        key: 0,
                        name: "topic",
                        class: "error-feedback"
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ]))
          : _createCommentVNode("", true),
        (
            !_unref(courseRequestFields).find((el) => el.name === 'priorKnowledge')
              ?.disabled && _unref(showPriorKnowledge)(_unref(values).courseRequestType)
          )
          ? (_openBlock(), _createElementBlock("div", _hoisted_60, [
              _cache[84] || (_cache[84] = _createElementVNode("label", { for: "priorKnowledge" }, [
                _createTextVNode("Students' Prior Knowledge About Topic Requiring Course-Support "),
                _createElementVNode("span", { class: "super font-color-red" }, "*")
              ], -1)),
              _createVNode(_unref(Field), { name: "priorKnowledge" }, {
                default: _withCtx(({ field, meta }) => [
                  _createElementVNode("input", _mergeProps(field, {
                    class: [{
                'field-bg-color': !meta.dirty,
                'field-border-error': meta.dirty && !meta.valid,
              }, "form-control"],
                    type: "text",
                    readonly: props.action === 'view'
                  }), null, 16, _hoisted_61),
                  (meta.dirty && !meta.valid)
                    ? (_openBlock(), _createBlock(_unref(ErrorMessage), {
                        key: 0,
                        name: "priorKnowledge",
                        class: "error-feedback"
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ]))
          : _createCommentVNode("", true),
        (
            !_unref(courseRequestFields).find((el) => el.name === 'learning')?.disabled
          )
          ? (_openBlock(), _createElementBlock("div", _hoisted_62, [
              _cache[87] || (_cache[87] = _createElementVNode("label", { for: "learning" }, [
                _createTextVNode("Learning Objective/Expected Student Outcomes"),
                _createElementVNode("span", { class: "super font-color-red" }, "*"),
                _createElementVNode("span", { class: "font-color-yellow" }, " (You can add multiple)")
              ], -1)),
              _createElementVNode("div", _hoisted_63, [
                _createVNode(_unref(Field), {
                  name: "learning_text",
                  type: "text",
                  class: "form-control",
                  modelValue: _unref(learning_text),
                  "onUpdate:modelValue": _cache[25] || (_cache[25] = ($event: any) => (_isRef(learning_text) ? (learning_text).value = $event : learning_text = $event)),
                  readonly: props.action === 'view',
                  onKeyup: _withKeys(_withModifiers(addLearningObjectives, ["prevent"]), ["enter"])
                }, null, 8, ["modelValue", "readonly", "onKeyup"]),
                _createElementVNode("div", {
                  class: "ml-2 cursor-pointer btn btn-primary w-6rem",
                  style: {"display":"inline","justify-content":"center"},
                  onClick: addLearningObjectives
                }, [
                  _createVNode(_component_font_awesome_icon, {
                    style: {"margin":"auto"},
                    icon: "fa-solid fa-plus",
                    title: "Add Learning Objective/Expected Student Outcomes"
                  }),
                  _cache[85] || (_cache[85] = _createTextVNode("   ")),
                  _cache[86] || (_cache[86] = _createElementVNode("span", null, "Add", -1))
                ])
              ]),
              _createVNode(_unref(ErrorMessage), {
                name: "learning",
                class: "error-feedback"
              }),
              (_unref(values).learning != null)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    style: {"display":"flex","flex-wrap":"wrap"},
                    class: _normalizeClass({ 'pointer-events-none': props.action === 'view' })
                  }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(values).learning, (item) => {
                      return (_openBlock(), _createElementBlock("span", {
                        key: item,
                        class: "list-item"
                      }, [
                        _createTextVNode(_toDisplayString(item) + " ", 1),
                        _createElementVNode("span", _hoisted_64, [
                          _createElementVNode("span", {
                            class: "list-item-remove-icon",
                            onClick: ($event: any) => (removeLearningObjectives(item))
                          }, null, 8, _hoisted_65)
                        ])
                      ]))
                    }), 128))
                  ], 2))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true),
        (
            !_unref(courseRequestFields).find((el) => el.name === 'needForPreVisit')
              ?.disabled && _unref(values).courseRequestType === 'Site Visit'
          )
          ? (_openBlock(), _createElementBlock("div", _hoisted_66, [
              _cache[88] || (_cache[88] = _createElementVNode("label", { for: "needForPreVisit" }, [
                _createTextVNode(" Need for Pre-Visit by the Instructor"),
                _createElementVNode("span", { class: "super font-color-red" }, "*")
              ], -1)),
              _createVNode(_unref(Field), {
                modelValue: _unref(values).needForPreVisit,
                "onUpdate:modelValue": _cache[27] || (_cache[27] = ($event: any) => ((_unref(values).needForPreVisit) = $event)),
                name: "needForPreVisit",
                type: "text",
                class: _normalizeClass(["dropdown", { 'non-editable-select': props.action === 'view' }])
              }, {
                default: _withCtx(({ field }) => [
                  _createVNode(_unref(Multiselect), _mergeProps(field, {
                    modelValue: _unref(values).needForPreVisit,
                    "onUpdate:modelValue": _cache[26] || (_cache[26] = ($event: any) => ((_unref(values).needForPreVisit) = $event)),
                    options: ['Yes', 'No'],
                    mode: "single",
                    searchable: true,
                    disabled: props.action === 'view',
                    class: { 'field-bg-color': !_unref(meta).dirty && !_unref(meta).valid }
                  }), null, 16, ["modelValue", "disabled", "class"])
                ]),
                _: 1
              }, 8, ["modelValue", "class"]),
              _createVNode(_unref(ErrorMessage), {
                name: "needForPreVisit",
                class: "error-feedback"
              })
            ]))
          : _createCommentVNode("", true),
        (
            !_unref(courseRequestFields).find((el) => el.name === 'visualRep')
              ?.disabled && _unref(showVisualRep)(_unref(values).courseRequestType)
          )
          ? (_openBlock(), _createElementBlock("div", _hoisted_67, [
              _createElementVNode("label", _hoisted_68, [
                _cache[89] || (_cache[89] = _createTextVNode("Describe Specific Demonstrations/Visual Representation Required ")),
                _cache[90] || (_cache[90] = _createElementVNode("span", { class: "super font-color-red" }, "*", -1)),
                _cache[91] || (_cache[91] = _createTextVNode("   ")),
                _createElementVNode("span", _hoisted_69, [
                  _createVNode(_component_font_awesome_icon, { icon: "fa-solid fa-circle-question" })
                ])
              ]),
              _createVNode(_unref(Field), { name: "visualRep" }, {
                default: _withCtx(({ field, meta }) => [
                  _createElementVNode("input", _mergeProps(field, {
                    class: [{
                'field-bg-color': !meta.dirty,
                'field-border-error': meta.dirty && !meta.valid,
              }, "form-control"],
                    type: "text",
                    readonly: props.action === 'view'
                  }), null, 16, _hoisted_70)
                ]),
                _: 1
              }),
              _createVNode(_unref(ErrorMessage), {
                name: "visualRep",
                class: "error-feedback"
              })
            ]))
          : _createCommentVNode("", true),
        (
            !_unref(courseRequestFields).find((el) => el.name === 'siteOffice')
              ?.disabled && _unref(values).courseRequestType === 'Site Visit'
          )
          ? (_openBlock(), _createElementBlock("div", _hoisted_71, [
              _cache[92] || (_cache[92] = _createElementVNode("label", { for: "siteOffice" }, [
                _createTextVNode("Need to Visit the Office Trailer/Site Office for any Demonstration "),
                _createElementVNode("span", { class: "super font-color-red" }, "*")
              ], -1)),
              _createVNode(_unref(Field), {
                modelValue: _unref(values).siteOffice,
                "onUpdate:modelValue": _cache[29] || (_cache[29] = ($event: any) => ((_unref(values).siteOffice) = $event)),
                name: "siteOffice",
                type: "text",
                class: _normalizeClass(["dropdown", { 'non-editable-select': props.action === 'view' }])
              }, {
                default: _withCtx(({ field }) => [
                  _createVNode(_unref(Multiselect), _mergeProps(field, {
                    modelValue: _unref(values).siteOffice,
                    "onUpdate:modelValue": _cache[28] || (_cache[28] = ($event: any) => ((_unref(values).siteOffice) = $event)),
                    options: ['Yes', 'No'],
                    mode: "single",
                    searchable: true,
                    disabled: props.action === 'view',
                    class: { 'field-bg-color': !_unref(meta).dirty && !_unref(meta).valid }
                  }), null, 16, ["modelValue", "disabled", "class"])
                ]),
                _: 1
              }, 8, ["modelValue", "class"]),
              _createVNode(_unref(ErrorMessage), {
                name: "siteOffice",
                class: "error-feedback"
              })
            ]))
          : _createCommentVNode("", true),
        (
            !_unref(courseRequestFields).find((el) => el.name === 'classroomItems')
              ?.disabled && _unref(showClassroomItems)(_unref(values).courseRequestType)
          )
          ? (_openBlock(), _createElementBlock("div", _hoisted_72, [
              _cache[93] || (_cache[93] = _createElementVNode("label", { for: "classroomItems" }, " Available items in the classroom e.g., projector, HDMI, whiteboard ", -1)),
              _createVNode(_unref(Field), { name: "classroomItems" }, {
                default: _withCtx(({ field, meta }) => [
                  _createElementVNode("input", _mergeProps(field, {
                    class: [{
                'field-bg-color': !meta.dirty,
                'field-border-error': meta.dirty && !meta.valid,
              }, "form-control"],
                    type: "text",
                    readonly: props.action === 'view'
                  }), null, 16, _hoisted_73)
                ]),
                _: 1
              }),
              _createVNode(_unref(ErrorMessage), {
                name: "classroomItems",
                class: "error-feedback"
              })
            ]))
          : _createCommentVNode("", true),
        (
            !_unref(courseRequestFields).find((el) => el.name === 'assigmentConnected')
              ?.disabled && _unref(showAssignmentConnected)(_unref(values).courseRequestType)
          )
          ? (_openBlock(), _createElementBlock("div", _hoisted_74, [
              _cache[94] || (_cache[94] = _createElementVNode("label", { for: "assigmentConnected" }, " Is there any Student Assignment Connected to this Course-Support? If yes, Please Provide Details. ", -1)),
              _createVNode(_unref(Field), { name: "assigmentConnected" }, {
                default: _withCtx(({ field, meta }) => [
                  _createElementVNode("input", _mergeProps(field, {
                    class: [{
                'field-bg-color': !meta.dirty,
                'field-border-error': meta.dirty && !meta.valid,
              }, "form-control"],
                    type: "text",
                    readonly: props.action === 'view'
                  }), null, 16, _hoisted_75)
                ]),
                _: 1
              }),
              _createVNode(_unref(ErrorMessage), {
                name: "assigmentConnected",
                class: "error-feedback"
              })
            ]))
          : _createCommentVNode("", true),
        (
            !_unref(courseRequestFields).find((el) => el.name === 'generalInfo')
              ?.disabled
          )
          ? (_openBlock(), _createElementBlock("div", _hoisted_76, [
              _cache[95] || (_cache[95] = _createElementVNode("label", { for: "generalInfo" }, [
                _createTextVNode("Detailed Description of Course-Support Needed"),
                _createElementVNode("span", { class: "super font-color-red" }, "* "),
                _createElementVNode("span", { class: "font-color-yellow" }, "(Please describe the format of the class and/or the form of engagement you desire from the practitioner)")
              ], -1)),
              _createVNode(_unref(Field), {
                name: "generalInfo",
                type: "text",
                class: "form-control",
                readonly: props.action === 'view'
              }, null, 8, ["readonly"]),
              _createVNode(_unref(ErrorMessage), {
                name: "generalInfo",
                class: "error-feedback"
              })
            ]))
          : _createCommentVNode("", true),
        _cache[122] || (_cache[122] = _createElementVNode("br", null, null, -1)),
        _cache[123] || (_cache[123] = _createElementVNode("div", { class: "align-center bold-text flex flex-direction-column" }, [
          _createTextVNode(" Preference Definition (Industry Practitioner Related) "),
          _createElementVNode("span", { style: {"font-style":"italic","font-size":"small"} }, "This information will not be shared with industry practitioners")
        ], -1)),
        (
            !_unref(courseRequestFields).find((el) => el.name === 'gender')?.disabled
          )
          ? (_openBlock(), _createElementBlock("div", _hoisted_77, [
              _cache[96] || (_cache[96] = _createElementVNode("label", { for: "gender" }, [
                _createTextVNode("Gender Identity"),
                _createElementVNode("span", { class: "super font-color-red" }, "*")
              ], -1)),
              _createVNode(_unref(Field), {
                modelValue: _unref(values).gender,
                "onUpdate:modelValue": _cache[31] || (_cache[31] = ($event: any) => ((_unref(values).gender) = $event)),
                name: "gender",
                type: "text",
                class: _normalizeClass(["dropdown", { 'non-editable-select': props.action === 'view' }])
              }, {
                default: _withCtx(({ field }) => [
                  _createVNode(_unref(Multiselect), _mergeProps(field, {
                    modelValue: _unref(values).gender,
                    "onUpdate:modelValue": _cache[30] || (_cache[30] = ($event: any) => ((_unref(values).gender) = $event)),
                    options: [
                { label: 'No preference', value: 'No preference' },
                { label: 'Male', value: 'Male' },
                { label: 'Female', value: 'Female' },
                { label: 'Non-binary', value: 'Non-binary' },
                { label: 'Others, please specify', value: 'Others' },
              ],
                    mode: "single",
                    searchable: true,
                    disabled: props.action === 'view',
                    class: { 'field-bg-color': !_unref(meta).dirty && !_unref(meta).valid }
                  }), null, 16, ["modelValue", "disabled", "class"])
                ]),
                _: 1
              }, 8, ["modelValue", "class"]),
              (_unref(values).gender === 'Others')
                ? (_openBlock(), _createElementBlock("div", _hoisted_78, [
                    _createVNode(_unref(Field), {
                      name: "gender-text",
                      type: "text",
                      placeholder: "Please Specify",
                      class: "form-control bg-others",
                      readonly: props.action === 'view'
                    }, null, 8, ["readonly"])
                  ]))
                : _createCommentVNode("", true),
              _createVNode(_unref(ErrorMessage), {
                name: "gender",
                class: "error-feedback"
              })
            ]))
          : _createCommentVNode("", true),
        (
            !_unref(courseRequestFields).find((el) => el.name === 'ethnicity')?.disabled
          )
          ? (_openBlock(), _createElementBlock("div", _hoisted_79, [
              _cache[97] || (_cache[97] = _createElementVNode("label", { for: "ethnicity" }, [
                _createTextVNode("Ethnicity"),
                _createElementVNode("span", { class: "super font-color-red" }, "*")
              ], -1)),
              _createVNode(_unref(Field), {
                modelValue: _unref(values).ethnicity,
                "onUpdate:modelValue": _cache[33] || (_cache[33] = ($event: any) => ((_unref(values).ethnicity) = $event)),
                name: "ethnicity",
                type: "text",
                class: _normalizeClass(["dropdown", { 'non-editable-select': props.action === 'view' }])
              }, {
                default: _withCtx(({ field }) => [
                  _createVNode(_unref(Multiselect), _mergeProps(field, {
                    modelValue: _unref(values).ethnicity,
                    "onUpdate:modelValue": _cache[32] || (_cache[32] = ($event: any) => ((_unref(values).ethnicity) = $event)),
                    options: ethnicityOptionsAdded,
                    mode: "single",
                    searchable: true,
                    disabled: props.action === 'view',
                    class: { 'field-bg-color': !_unref(meta).dirty && !_unref(meta).valid }
                  }), null, 16, ["modelValue", "disabled", "class"])
                ]),
                _: 1
              }, 8, ["modelValue", "class"]),
              (_unref(values).ethnicity === 'Others')
                ? (_openBlock(), _createElementBlock("div", _hoisted_80, [
                    _createVNode(_unref(Field), {
                      name: "ethnicity-text",
                      type: "text",
                      placeholder: "Please Specify",
                      class: "form-control bg-others",
                      readonly: props.action === 'view'
                    }, null, 8, ["readonly"])
                  ]))
                : _createCommentVNode("", true),
              _createVNode(_unref(ErrorMessage), {
                name: "ethnicity",
                class: "error-feedback"
              })
            ]))
          : _createCommentVNode("", true),
        (
            !_unref(courseRequestFields).find((el) => el.name === 'yearsOfExp')
              ?.disabled
          )
          ? (_openBlock(), _createElementBlock("div", _hoisted_81, [
              _cache[98] || (_cache[98] = _createElementVNode("label", { for: "yearsOfExp" }, [
                _createTextVNode("Years of Experience"),
                _createElementVNode("span", { class: "super font-color-red" }, "*")
              ], -1)),
              _createVNode(_unref(Field), {
                modelValue: _unref(values).yearsOfExp,
                "onUpdate:modelValue": _cache[35] || (_cache[35] = ($event: any) => ((_unref(values).yearsOfExp) = $event)),
                name: "yearsOfExp",
                type: "text",
                class: _normalizeClass(["dropdown", { 'non-editable-select': props.action === 'view' }])
              }, {
                default: _withCtx(({ field }) => [
                  _createVNode(_unref(Multiselect), _mergeProps(field, {
                    modelValue: _unref(values).yearsOfExp,
                    "onUpdate:modelValue": _cache[34] || (_cache[34] = ($event: any) => ((_unref(values).yearsOfExp) = $event)),
                    options: yearsOfExpOptionsAdded,
                    mode: "single",
                    searchable: true,
                    disabled: props.action === 'view',
                    class: { 'field-bg-color': !_unref(meta).dirty && !_unref(meta).valid }
                  }), null, 16, ["modelValue", "disabled", "class"])
                ]),
                _: 1
              }, 8, ["modelValue", "class"]),
              _createVNode(_unref(ErrorMessage), {
                name: "yearsOfExp",
                class: "error-feedback"
              })
            ]))
          : _createCommentVNode("", true),
        (
            !_unref(courseRequestFields).find((el) => el.name === 'areaOfExpertise')
              ?.disabled
          )
          ? (_openBlock(), _createElementBlock("div", _hoisted_82, [
              _cache[99] || (_cache[99] = _createElementVNode("label", { for: "areaOfExpertise" }, [
                _createTextVNode("Area of Expertise/Specialization"),
                _createElementVNode("span", { class: "super font-color-red" }, "*")
              ], -1)),
              _createVNode(_unref(Field), {
                modelValue: _unref(values).areaOfExpertise,
                "onUpdate:modelValue": _cache[37] || (_cache[37] = ($event: any) => ((_unref(values).areaOfExpertise) = $event)),
                name: "areaOfExpertise",
                type: "text",
                class: _normalizeClass(["dropdown", { 'non-editable-select': props.action === 'view' }])
              }, {
                default: _withCtx(({ field }) => [
                  _createVNode(_unref(Multiselect), _mergeProps(field, {
                    modelValue: _unref(values).areaOfExpertise,
                    "onUpdate:modelValue": _cache[36] || (_cache[36] = ($event: any) => ((_unref(values).areaOfExpertise) = $event)),
                    options: areaOfExpertiseOptionsAdded,
                    mode: "single",
                    searchable: true,
                    disabled: props.action === 'view',
                    class: { 'field-bg-color': !_unref(meta).dirty && !_unref(meta).valid }
                  }), null, 16, ["modelValue", "disabled", "class"])
                ]),
                _: 1
              }, 8, ["modelValue", "class"]),
              (_unref(values).areaOfExpertise === 'Others')
                ? (_openBlock(), _createElementBlock("div", _hoisted_83, [
                    _createVNode(_unref(Field), { name: "areaOfExpertise-text" }, {
                      default: _withCtx(({ field }) => [
                        _createElementVNode("input", _mergeProps(field, {
                          placeholder: "Please Specify",
                          class: "form-control bg-others",
                          type: "text",
                          readonly: props.action === 'view'
                        }), null, 16, _hoisted_84)
                      ]),
                      _: 1
                    })
                  ]))
                : _createCommentVNode("", true),
              _createVNode(_unref(ErrorMessage), {
                name: "areaOfExpertise",
                class: "error-feedback"
              })
            ]))
          : _createCommentVNode("", true),
        (
            !_unref(courseRequestFields).find((el) => el.name === 'levelEducation')
              ?.disabled
          )
          ? (_openBlock(), _createElementBlock("div", _hoisted_85, [
              _cache[100] || (_cache[100] = _createElementVNode("label", { for: "levelEducation" }, [
                _createTextVNode("Minimum Level of Education"),
                _createElementVNode("span", { class: "super font-color-red" }, "*")
              ], -1)),
              _createVNode(_unref(Field), {
                modelValue: _unref(values).levelEducation,
                "onUpdate:modelValue": _cache[39] || (_cache[39] = ($event: any) => ((_unref(values).levelEducation) = $event)),
                name: "levelEducation",
                type: "text",
                class: _normalizeClass(["dropdown", { 'non-editable-select': props.action === 'view' }])
              }, {
                default: _withCtx(({ field }) => [
                  _createVNode(_unref(Multiselect), _mergeProps(field, {
                    modelValue: _unref(values).levelEducation,
                    "onUpdate:modelValue": _cache[38] || (_cache[38] = ($event: any) => ((_unref(values).levelEducation) = $event)),
                    options: [
                { label: 'No preference', value: 'No preference' },
                { label: 'Diploma', value: 'Diploma' },
                { label: 'Associate Degree', value: 'Associate Degree' },
                { label: 'Bachelor’s degree', value: 'Bachelor’s degree' },
                { label: 'Master’s degree', value: 'Master’s degree' },
                { label: 'Doctoral Degree', value: 'Doctoral Degree' },
              ],
                    mode: "single",
                    searchable: true,
                    disabled: props.action === 'view',
                    class: { 'field-bg-color': !_unref(meta).dirty && !_unref(meta).valid }
                  }), null, 16, ["modelValue", "disabled", "class"])
                ]),
                _: 1
              }, 8, ["modelValue", "class"]),
              _createVNode(_unref(ErrorMessage), {
                name: "levelEducation",
                class: "error-feedback"
              })
            ]))
          : _createCommentVNode("", true),
        (
            !_unref(courseRequestFields).find((el) => el.name === 'typeFirm')?.disabled
          )
          ? (_openBlock(), _createElementBlock("div", _hoisted_86, [
              _cache[101] || (_cache[101] = _createElementVNode("label", { for: "typeFirm" }, [
                _createTextVNode("Type of Firm"),
                _createElementVNode("span", { class: "super font-color-red" }, "*")
              ], -1)),
              _createVNode(_unref(Field), {
                modelValue: _unref(values).typeFirm,
                "onUpdate:modelValue": _cache[41] || (_cache[41] = ($event: any) => ((_unref(values).typeFirm) = $event)),
                name: "typeFirm",
                type: "text",
                class: _normalizeClass(["dropdown", { 'non-editable-select': props.action === 'view' }])
              }, {
                default: _withCtx(({ field }) => [
                  _createVNode(_unref(Multiselect), _mergeProps(field, {
                    modelValue: _unref(values).typeFirm,
                    "onUpdate:modelValue": _cache[40] || (_cache[40] = ($event: any) => ((_unref(values).typeFirm) = $event)),
                    options: typeOfFirmOptionsAdded,
                    mode: "single",
                    searchable: true,
                    disabled: props.action === 'view',
                    class: { 'field-bg-color': !_unref(meta).dirty && !_unref(meta).valid }
                  }), null, 16, ["modelValue", "disabled", "class"])
                ]),
                _: 1
              }, 8, ["modelValue", "class"]),
              (_unref(values).typeFirm === 'Others')
                ? (_openBlock(), _createElementBlock("div", _hoisted_87, [
                    _createVNode(_unref(Field), { name: "typeFirm_text" }, {
                      default: _withCtx(({ field, meta }) => [
                        _createElementVNode("input", _mergeProps(field, {
                          class: [{
                  'field-bg-color': !meta.dirty,
                  'field-border-error': meta.dirty && !meta.valid,
                }, "form-control bg-others"],
                          placeholder: "Please Specify",
                          type: "text",
                          readonly: props.action === 'view'
                        }), null, 16, _hoisted_88)
                      ]),
                      _: 1
                    })
                  ]))
                : _createCommentVNode("", true),
              _createVNode(_unref(ErrorMessage), {
                name: "typeFirm",
                class: "error-feedback"
              })
            ]))
          : _createCommentVNode("", true),
        (
            !_unref(courseRequestFields).find((el) => el.name === 'sizeFirm')?.disabled
          )
          ? (_openBlock(), _createElementBlock("div", _hoisted_89, [
              _cache[102] || (_cache[102] = _createElementVNode("label", { for: "sizeFirm" }, [
                _createTextVNode("Size of Firm"),
                _createElementVNode("span", { class: "super font-color-red" }, "*")
              ], -1)),
              _createVNode(_unref(Field), {
                modelValue: _unref(values).sizeFirm,
                "onUpdate:modelValue": _cache[43] || (_cache[43] = ($event: any) => ((_unref(values).sizeFirm) = $event)),
                name: "sizeFirm",
                type: "text",
                class: _normalizeClass(["dropdown", { 'non-editable-select': props.action === 'view' }])
              }, {
                default: _withCtx(({ field }) => [
                  _createVNode(_unref(Multiselect), _mergeProps(field, {
                    modelValue: _unref(values).sizeFirm,
                    "onUpdate:modelValue": _cache[42] || (_cache[42] = ($event: any) => ((_unref(values).sizeFirm) = $event)),
                    options: sizeOfFirmOptionsAdded,
                    mode: "single",
                    searchable: true,
                    disabled: props.action === 'view',
                    class: { 'field-bg-color': !_unref(meta).dirty && !_unref(meta).valid }
                  }), null, 16, ["modelValue", "disabled", "class"])
                ]),
                _: 1
              }, 8, ["modelValue", "class"]),
              _createVNode(_unref(ErrorMessage), {
                name: "sizeFirm",
                class: "error-feedback"
              })
            ]))
          : _createCommentVNode("", true),
        _cache[124] || (_cache[124] = _createElementVNode("br", null, null, -1)),
        (props.action !== 'view')
          ? (_openBlock(), _createElementBlock("div", _hoisted_90, [
              _createElementVNode("div", _hoisted_91, [
                _createElementVNode("button", {
                  type: "button",
                  onClick: saveTempCourseRequest,
                  class: "btn btn-secondary btn-block"
                }, _cache[103] || (_cache[103] = [
                  _createElementVNode("span", null, "Save & Continue Later", -1)
                ])),
                _cache[104] || (_cache[104] = _createElementVNode("div", null, null, -1)),
                _createElementVNode("button", {
                  type: "button",
                  class: "btn btn-primary btn-block",
                  onClick: showPopup
                }, [
                  (_ctx.action === 'update')
                    ? (_openBlock(), _createElementBlock("span", _hoisted_92, "Update Course Request"))
                    : _createCommentVNode("", true),
                  (_ctx.action === 'create' || _ctx.action === 'resubmit')
                    ? (_openBlock(), _createElementBlock("span", _hoisted_93, "Request Course-Support"))
                    : _createCommentVNode("", true)
                ])
              ]),
              _createVNode(PopupModal, {
                visible: _unref(isVisible),
                onClose: hidePopup
              }, {
                title: _withCtx(() => _cache[105] || (_cache[105] = [
                  _createTextVNode(" Contact Preference")
                ])),
                content: _withCtx(() => [
                  _createElementVNode("div", _hoisted_94, [
                    _cache[106] || (_cache[106] = _createElementVNode("label", { for: "contactPref" }, "Please select how you would like to be contacted?", -1)),
                    _createVNode(_unref(Field), {
                      name: "Email",
                      value: "True",
                      type: "checkbox",
                      disabled: ""
                    }),
                    _cache[107] || (_cache[107] = _createTextVNode("Email ")),
                    _cache[108] || (_cache[108] = _createElementVNode("br", null, null, -1)),
                    _createVNode(_unref(Field), {
                      value: "True",
                      "unchecked-value": "False",
                      name: "phone",
                      type: "checkbox"
                    }),
                    _cache[109] || (_cache[109] = _createTextVNode(" Phone ")),
                    _cache[110] || (_cache[110] = _createElementVNode("br", null, null, -1)),
                    _createVNode(_unref(ErrorMessage), {
                      name: "contactPref",
                      class: "error-feedback"
                    })
                  ]),
                  (_unref(values).phone === 'True')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_95, [
                        _cache[111] || (_cache[111] = _createElementVNode("label", { for: "phoneNumber" }, "Phone Number", -1)),
                        _createVNode(_unref(Field), {
                          name: "phoneNumber",
                          type: "text",
                          class: "form-control"
                        }),
                        _createVNode(_unref(ErrorMessage), {
                          name: "phoneNumber",
                          class: "error-feedback"
                        })
                      ]))
                    : _createCommentVNode("", true),
                  _cache[113] || (_cache[113] = _createElementVNode("br", null, null, -1)),
                  _createElementVNode("div", _hoisted_96, [
                    _createElementVNode("button", {
                      type: "button",
                      class: "btn btn-primary btn-block",
                      onClick: _cache[44] || (_cache[44] = ($event: any) => (
                    (_isRef(isVisible) //@ts-ignore
 ? isVisible.value = false : isVisible = false), (_isRef(isRecommendationsVisible) //@ts-ignore
 ? isRecommendationsVisible.value = true : isRecommendationsVisible = true)
                  ))
                    }, _cache[112] || (_cache[112] = [
                      _createElementVNode("span", null, "Proceed", -1)
                    ]))
                  ])
                ]),
                _: 1
              }, 8, ["visible"]),
              _createVNode(PopupModal, {
                visible: _unref(isRecommendationsVisible),
                onClose: _cache[46] || (_cache[46] = ($event: any) => (_isRef(isRecommendationsVisible) //@ts-ignore
 ? isRecommendationsVisible.value = false : isRecommendationsVisible = false))
              }, {
                title: _withCtx(() => _cache[114] || (_cache[114] = [])),
                content: _withCtx(() => [
                  _cache[118] || (_cache[118] = _createElementVNode("div", null, " Your course-support request will be posted to all practitioners on this platform. However, you can make a maximum of three selections from the recommended practitioners. Once you make the selection(s), your course-support request will now only be visible to the practitioners that you selected. ", -1)),
                  _cache[119] || (_cache[119] = _createElementVNode("br", null, null, -1)),
                  _cache[120] || (_cache[120] = _createElementVNode("br", null, null, -1)),
                  _createElementVNode("div", _hoisted_97, [
                    _createElementVNode("button", {
                      type: "button",
                      class: "btn btn-secondary btn-block",
                      onClick: _cache[45] || (_cache[45] = ($event: any) => (_isRef(isRecommendationsVisible) //@ts-ignore
 ? isRecommendationsVisible.value = false : isRecommendationsVisible = false)),
                      disabled: _unref(loading)
                    }, _cache[115] || (_cache[115] = [
                      _createElementVNode("span", null, "Back", -1)
                    ]), 8, _hoisted_98),
                    _cache[117] || (_cache[117] = _createElementVNode("div", null, null, -1)),
                    _createElementVNode("button", {
                      class: "btn btn-primary btn-block",
                      disabled: _unref(loading)
                    }, [
                      _withDirectives(_createElementVNode("span", _hoisted_100, null, 512), [
                        [_vShow, _unref(loading)]
                      ]),
                      _cache[116] || (_cache[116] = _createElementVNode("span", null, "Accept", -1))
                    ], 8, _hoisted_99)
                  ])
                ]),
                _: 1
              }, 8, ["visible"])
            ]))
          : _createCommentVNode("", true)
      ], 32)
    ]),
    (_unref(message))
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(_unref(successful) ? 'alert-success' : 'alert-danger')
        }, _toDisplayString(_unref(message)), 3))
      : _createCommentVNode("", true)
  ]))
}
}

})