<template>
  <div class="grid-container">
    <div v-if="currentUser.roles === 'INDUSTRYPRACTITIONER'">
      <practitioner-sidebar></practitioner-sidebar>
    </div>
    <div v-else-if="currentUser.roles === 'INSTRUCTOR'">
      <instructor-sidebar></instructor-sidebar>
    </div>
    <div class="min-height mt-3">
      <div class="back-grid">
        <back-button></back-button>
        <h2 class="text-align-center mb-4">Instructor Details</h2>
      </div>
      <div style="display: flex; justify-content: center">
        <table v-if="Object.keys(instructor || {}).length > 0">
          <tr>
            <td rowspan="4" class="recommendation-img-card">
              <!--              <img-->
              <!--                v-if="instructor.user.fileName"-->
              <!--                id="profile-img"-->
              <!--                :src="-->
              <!--                  websiteURL + 'profile_picture/' + instructor.user.fileName-->
              <!--                "-->
              <!--              />-->
            </td>
            <td>Course-Support Request Type</td>
            <td>{{ course.courseRequestType }}</td>
          </tr>

          <tr>
            <td>Name</td>
            <td>
              {{ instructor.user.firstName + " " + instructor.user.lastName }}
            </td>
          </tr>

          <tr>
            <td>Title</td>
            <td>{{ instructor.user.title }}</td>
          </tr>

          <tr>
            <td>Department/Program</td>
            <td>{{ instructor.department }}</td>
          </tr>
          <tr>
            <td></td>
            <td>Name of Institution</td>
            <td>{{ instructor.user.nameOrg }}</td>
          </tr>

          <tr>
            <td></td>
            <td>Location of Institution</td>
            <td>{{ instructor.city + " " + instructor.state }}</td>
          </tr>

          <tr
            v-if="
              course.status === '10' ||
              course.status === '20' ||
              course.status === '60'
            "
            style="font-style: italic"
          >
            <td></td>
            <td>Email</td>
            <td>
              <a :href="'mailto:' + instructor?.user?.email">
                {{ instructor?.user?.email }}
              </a>
            </td>
          </tr>

          <tr
            v-if="
              (course.status === '10' ||
                course.status === '20' ||
                course.status === '60') &&
              instructor.user.phone === 'True'
            "
            style="font-style: italic"
          >
            <td></td>
            <td>Phone Number</td>
            <td>{{ instructor.user.phoneNumber }}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useCourseStore } from "@/stores/CourseStore";
import { useAuthStore } from "@/stores/AuthStore";
import { ref } from "vue";
import PractitionerService from "@/services/practitioner.service.js";
import PractitionerSidebar from "@/components/PractitionerSidebar.vue";
import InstructorSidebar from "@/components/instructor/InstructorSidebar.vue";
import BackButton from "@/components/user/BackButton.vue";
import { websiteURL } from "@/utils";

const CourseStore = useCourseStore();
const AuthStore = useAuthStore();

const currentUser = AuthStore.auth;
let course = CourseStore.course;
let instructor = ref({});
let loading = false;
let successful = false;
let message = "";
let image = ref("");

const getInstructorProfile = (instructorUserId) => {
  loading = true;
  PractitionerService.getInstructorProfile(instructorUserId).then(
    (response) => {
      instructor.value = response.data;
      image.value =
        "url('" +
        websiteURL +
        "/profile_picture/" +
        instructor.value.user.fileName +
        "')";
      loading = false;
      successful = true;
    },
    (error) => {
      loading = false;
      successful = false;
      message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(message);
    }
  );
};

getInstructorProfile(course.userId);
</script>

<style scoped>
table {
  width: fit-content;
  box-shadow: 0px 0px 10px 4px rgb(0 0 0 / 30%);
  border-radius: 6px;
  background: #fafafa;
}

thead td {
  text-align: center;
}

td {
  border: 2px solid #f0f0f0;
  border-collapse: collapse;
  padding: 0.2em 4em;
  font-size: larger;
}

.recommendation-img-card {
  background-image: v-bind(image);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
</style>
