import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "grid-container" }
const _hoisted_2 = { class: "m-3 min-height" }
const _hoisted_3 = { class: "back-grid" }
const _hoisted_4 = { class: "text-align-center" }

import { ref } from "vue";
import InstructorSidebar from "@/components/instructor/InstructorSidebar.vue";
import CourseRequestForm from "@/components/instructor/CourseRequestForm.vue";
import { useAuthStore } from "@/stores/AuthStore";
import { useCourseStore } from "@/stores/CourseStore";
import { useRouter } from "vue-router";
import BackButton from "@/components/user/BackButton.vue";
import { CourseRequest } from "@/utils/CourseRequest";
import { valid } from "@/utils";

export default /*@__PURE__*/_defineComponent({
  __name: 'DisplayView',
  setup(__props) {

const router = useRouter();
const AuthStore = useAuthStore();
const CourseStore = useCourseStore();

const currentUser = AuthStore.auth;
let course = CourseStore.course;
let message = ref("");

let initialState = {
  academicLevel: [],
  areaOfExpertise: "",
  classSize: "",
  courseRequestType: "",
  date: [] as string[],
  duration: "",
  ethnicity: "",
  gender: "",
  learning: [],
  levelEducation: "",
  needForPreVisit: "",
  noOfFreq: "",
  physicalDis: [],
  siteOffice: "",
  studentDept: [],
  topic: "",
  time: "",
  time_text: "",
  visualRep: "",
  yearsOfExp: "",
  insDeliveryType: "",
  phone: "",
  phoneNumber: "",
  generalInfo: "",
  courseCode: "",
  courseTitle: "",
  priorKnowledge: "",
  typeProject: "",
  location: "",
  projectDescr: "",
  daysOfMeeting: "",
  startDate: "",
  endDate: "",
  classroomItems: "",
  assigmentConnected: "",
  additionalInformation: "",
  typeFirm: "",
  sizeFirm: "",
  typeFirm_text: "",
  semester: "",
  year: "",
};

if (CourseStore.status) {
  initialState.academicLevel = JSON.parse(CourseStore.course.academicLevel);
  initialState.areaOfExpertise = CourseStore.course.areaOfExpertise;
  initialState.classSize = CourseStore.course.classSize;
  initialState.courseRequestType = CourseStore.course.courseRequestType;
  initialState.date = JSON.parse(CourseStore.course.date);
  if (CourseStore.course.courseRequestType === "Mentor for Project") {
    const [year, month, day] = initialState.date[0].split("-");
    initialState.startDate = new Date(+year, +month - 1, +day).toLocaleString();
  }
  initialState.duration = CourseStore.course.duration;
  initialState.ethnicity = CourseStore.course.ethnicity;
  initialState.gender = CourseStore.course.gender;
  initialState.learning = JSON.parse(CourseStore.course.learning);
  initialState.levelEducation = CourseStore.course.levelEducation;
  initialState.needForPreVisit = CourseStore.course.needForPreVisit;
  initialState.noOfFreq = CourseStore.course.noOfFreq;
  initialState.physicalDis = JSON.parse(CourseStore.course.physicalDis);
  initialState.siteOffice = CourseStore.course.siteOffice;
  initialState.studentDept = JSON.parse(CourseStore.course.studentDept);
  initialState.time = JSON.parse(CourseStore?.course?.time);
  if (CourseStore.course.courseRequestType === "Mentor for Project") {
    initialState.time_text = initialState.time[0];
  }

  initialState.topic = CourseStore.course.topic;
  initialState.visualRep = CourseStore.course.visualRep;
  initialState.yearsOfExp = CourseStore.course.yearsOfExp;
  initialState.insDeliveryType = CourseStore.course.insDeliveryType;
  initialState.generalInfo = CourseStore.course.generalInfo;
  initialState.phone = currentUser.phone;
  initialState.phoneNumber = currentUser.phoneNumber;
  initialState.courseCode = CourseStore.course.courseCode;
  initialState.courseTitle = CourseStore.course.courseTitle;
  initialState.priorKnowledge = CourseStore.course.priorKnowledge;
  initialState.typeProject = CourseStore.course.typeProject;
  initialState.location = CourseStore.course.location;
  initialState.projectDescr = CourseStore.course.projectDescr;
  initialState.daysOfMeeting = CourseStore.course.daysOfMeeting;
  initialState.endDate = CourseStore.course.endDate;
  if (
    CourseStore.course.courseRequestType === "Mentor for Project" &&
    valid(initialState.endDate)
  ) {
    const [year, month, day] = initialState.endDate.split("-");
    initialState.endDate = new Date(+year, +month - 1, +day).toLocaleString();
  }
  initialState.classroomItems = CourseStore.course.classroomItems;
  initialState.assigmentConnected = CourseStore.course.assigmentConnected;
  initialState.additionalInformation = CourseStore.course.additionalInformation;
  initialState.typeFirm = CourseStore.course.typeFirm;
  initialState.sizeFirm = CourseStore.course.sizeFirm;
  initialState.semester = CourseStore.course.semester;
  initialState.year = CourseStore.course.year;
}

const formValues = {
  academicLevel: initialState.academicLevel,
  areaOfExpertise: initialState.areaOfExpertise,
  classSize: initialState.classSize,
  courseRequestType: initialState.courseRequestType,
  date: initialState.date,
  duration: initialState.duration,
  ethnicity: initialState.ethnicity,
  gender: initialState.gender,
  learning: initialState.learning,
  levelEducation: initialState.levelEducation,
  needForPreVisit: initialState.needForPreVisit,
  noOfFreq: initialState.noOfFreq,
  physicalDis: initialState.physicalDis,
  siteOffice: initialState.siteOffice,
  studentDept: initialState.studentDept,
  topic: initialState.topic,
  time: initialState.time,
  time_text: initialState.time_text,
  visualRep: initialState.visualRep,
  yearsOfExp: initialState.yearsOfExp,
  insDeliveryType: initialState.insDeliveryType,
  Email: "True",
  phone: initialState.phone,
  phoneNumber: initialState.phoneNumber,
  generalInfo: initialState.generalInfo,
  courseCode: initialState.courseCode,
  courseTitle: initialState.courseTitle,
  priorKnowledge: initialState.priorKnowledge,
  typeProject: initialState.typeProject,
  location: initialState.location,
  projectDescr: initialState.projectDescr,
  daysOfMeeting: initialState.daysOfMeeting,
  startDate: initialState.startDate,
  endDate: initialState.endDate,
  classroomItems: initialState.classroomItems,
  assigmentConnected: initialState.assigmentConnected,
  additionalInformation: initialState.additionalInformation,
  semester: initialState.semester,
  year: initialState.year,
  typeFirm: initialState.typeFirm,
  sizeFirm: initialState.sizeFirm,
};

const viewSelectedIndustryPractitioner = (course) => {
  CourseStore.addCourse(course);
  CourseStore.setPractitioner(course.industryPractitionerUserId);
  router.push({ name: "view-industry-practitioner-ins" });
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(InstructorSidebar),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(BackButton),
        _cache[1] || (_cache[1] = _createElementVNode("h2", { style: {"text-align":"center","font-weight":"bold"} }, " Course-Support Request Information ", -1))
      ]),
      _createVNode(CourseRequestForm, {
        formValues: formValues,
        action: "view"
      }),
      _cache[2] || (_cache[2] = _createElementVNode("br", null, null, -1)),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("button", {
          class: "btn btn-primary",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (viewSelectedIndustryPractitioner(_unref(course))))
        }, " View Industry Practitioner ")
      ])
    ])
  ]))
}
}

})