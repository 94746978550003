import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, unref as _unref, isRef as _isRef, vShow as _vShow, withDirectives as _withDirectives, withCtx as _withCtx, Fragment as _Fragment } from "vue"
import _imports_0 from '@/assets/images/feedback.png'
import _imports_1 from '@/assets/images/info.png'


const _hoisted_1 = { class: "content-card" }
const _hoisted_2 = { title: "A notification has been sent to the selected industry practitioner" }
const _hoisted_3 = {
  key: 0,
  class: "font-bold font-larger text-align-center"
}
const _hoisted_4 = {
  key: 1,
  class: "font-bold font-larger text-align-center"
}
const _hoisted_5 = { class: "button-container lg:ml-3" }
const _hoisted_6 = {
  key: 0,
  class: "p-2"
}
const _hoisted_7 = {
  key: 1,
  class: "p-2"
}
const _hoisted_8 = { class: "p-2" }
const _hoisted_9 = {
  key: 2,
  class: "p-2"
}
const _hoisted_10 = {
  key: 3,
  class: "p-2"
}
const _hoisted_11 = {
  key: 4,
  class: "p-2 text-align-center flex flex-direction-column"
}
const _hoisted_12 = {
  key: 5,
  class: "p-2"
}
const _hoisted_13 = { class: "form-group" }
const _hoisted_14 = { class: "form-group text-align-center" }
const _hoisted_15 = ["disabled"]
const _hoisted_16 = { class: "spinner-border spinner-border-sm" }

import { ref } from "vue";
import { showToast, showErrorToast } from "@/utils";
import * as yup from "yup";
import { Form, Field, ErrorMessage } from "vee-validate";
import Dialog from "primevue/dialog";
import FeedbackFrom from "@/components/user/FeedbackForm.vue";
import InstructorService from "@/services/instructor.service.js";

import { useRouter } from "vue-router";
import { useAuthStore } from "@/stores/AuthStore";
import { useCourseStore } from "@/stores/CourseStore";
import { useFeedStore } from "@/stores/FeedStore";
import { useRecommendationsStore } from "@/stores/RecommendationsStore";
import { CourseRequest } from "@/types";


export default /*@__PURE__*/_defineComponent({
  __name: 'CourseRequestItem',
  props: {
    course: {},
    type: {}
  },
  emits: ["fetchCourse"],
  setup(__props: any, { emit: __emit }) {

const AuthStore = useAuthStore();
const CourseStore = useCourseStore();
const FeedStore = useFeedStore();
const RecommendationsStore = useRecommendationsStore();
const router = useRouter();

let currentUser = AuthStore.auth;
let loading = ref(false);
let successful = ref(false);
let message = ref("");
let visible = ref(false);
let currCourse = ref({} as CourseRequest);
let feedbackVisible = ref(false);

// eslint-disable-next-line no-undef
const props = __props;
// eslint-disable-next-line no-undef
const emits = __emit;

const schema = yup.object().shape({
  reasonDelete: yup.string().required("Reason for deletion is required!"),
});

const deleteCourseRequest = (data) => {
  loading.value = true;
  data.courseId = currCourse.value.id;
  let courseStatus = currCourse.value.status;
  console.log(currCourse);
  InstructorService.deleteCourseRequest(data).then(
    (response) => {
      if (response.status == "200") emits("fetchCourse", "present");
      if (
        courseStatus == "10" ||
        courseStatus == "20" ||
        courseStatus == "60"
      ) {
        showToast(
          "Your course-support request has been deleted and a notification has been sent to the practitioner you earlier selected to inform them that you have canceled the request."
        );
      } else
        showToast("The course support request has been successfully deleted.");
      loading.value = false;
      visible.value = false;
    },
    (error) => {
      loading.value = false;
      visible.value = false;
      message.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};

const emitFetchCourse = () => {
  feedbackVisible.value = false;
  showToast("Your feedback has been successfully saved.");
  emits("fetchCourse", "past");
};

const viewCourseRequest = (course) => {
  CourseStore.addCourse(course);
  router.push({ name: "view-course-request-ins" });
};
const updateCourseRequest = (course) => {
  CourseStore.addCourse(course);
  router.push({ name: "update-course-request" });
};

const editCourseRequest = (course) => {
  CourseStore.addCourse(course);
  router.push({ name: "edit-course-request" });
};

const viewRecommendations = (course) => {
  CourseStore.addCourse(course);
  RecommendationsStore.resetSelectedPractitioner();
  router.push({ name: "view-recommendations" });
};
const viewSelectedRecommendations = (course) => {
  CourseStore.addCourse(course);
  RecommendationsStore.resetSelectedPractitioner();
  router.push({ name: "view-recommendations-selected" });
};

return (_ctx: any,_cache: any) => {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("p", null, [
        _cache[9] || (_cache[9] = _createElementVNode("b", null, "Course-Support Type", -1)),
        _createTextVNode(": " + _toDisplayString(_ctx.course.courseRequestType) + " ", 1),
        _createElementVNode("span", _hoisted_2, [
          (
            _ctx.course.status === '10' ||
            _ctx.course.status === '20' ||
            _ctx.course.status === '60'
          )
            ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                key: 0,
                icon: "fa-solid fa-circle-check",
                style: {"color":"#0bda51","font-size":"large"}
              }))
            : _createCommentVNode("", true)
        ]),
        _cache[10] || (_cache[10] = _createElementVNode("br", null, null, -1)),
        _cache[11] || (_cache[11] = _createElementVNode("b", null, "Course Requested On", -1)),
        _createTextVNode(": " + _toDisplayString(new Date(_ctx.course.createdAt).toDateString()), 1)
      ]),
      _createElementVNode("div", null, [
        (
          _ctx.course.course_request_x_practitioners.filter(
            (el) => el.status === '10' || el.status === '60'
          ).length > 0
        )
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_font_awesome_icon, {
                icon: "fa-solid fa-check",
                class: "font-larger",
                style: {"color":"#0bda51","font-size":"xx-large"}
              }),
              _cache[12] || (_cache[12] = _createTextVNode(" Accepted ")),
              _cache[13] || (_cache[13] = _createElementVNode("br", null, null, -1)),
              _createTextVNode(" " + _toDisplayString(new Date(
            _ctx.course.course_request_x_practitioners.filter(
              (el) => el.status === "10" || el.status === "60"
            )[0].selectedDate
          ).toLocaleString()), 1)
            ]))
          : (
          _ctx.course.course_request_x_practitioners.filter(
            (el) => el.status === '20'
          ).length > 0
        )
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode(_component_font_awesome_icon, {
                  icon: "fa-solid fa-circle-exclamation",
                  class: "font-larger",
                  style: {"color":"#336","font-size":"xx-large"}
                }),
                _cache[14] || (_cache[14] = _createTextVNode(" Response Pending "))
              ]))
            : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_5, [
        (_unref(FeedStore).tab === 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createElementVNode("button", {
                class: "btn btn-danger bg-red-600 border-none",
                onClick: _cache[0] || (_cache[0] = ($event: any) => ((_isRef(currCourse) //@ts-ignore
 ? currCourse.value = _ctx.course : currCourse = _ctx.course), (_isRef(visible) //@ts-ignore
 ? visible.value = true : visible = true))),
                title: "Delete"
              }, [
                _createVNode(_component_font_awesome_icon, { icon: "fa-solid fa-trash" })
              ])
            ]))
          : _createCommentVNode("", true),
        (
          _unref(FeedStore).tab === 1 && !_ctx.course.feedbackExists && _ctx.course.status == '60'
        )
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createElementVNode("button", {
                class: "btn btn-danger bg-red-500 border-none",
                onClick: _cache[1] || (_cache[1] = ($event: any) => ((_isRef(currCourse) //@ts-ignore
 ? currCourse.value = _ctx.course : currCourse = _ctx.course), (_isRef(feedbackVisible) //@ts-ignore
 ? feedbackVisible.value = true : feedbackVisible = true))),
                title: "Give Feedback"
              }, _cache[15] || (_cache[15] = [
                _createElementVNode("img", {
                  src: _imports_0,
                  class: "sidebar-image"
                }, null, -1)
              ]))
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("button", {
            class: "btn btn-primary bg-bluegray-400 border-none",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (editCourseRequest(_ctx.course))),
            title: "Edit & Submit"
          }, [
            _createVNode(_component_font_awesome_icon, { icon: "fa-solid fa-pen-to-square" })
          ])
        ]),
        (
          _ctx.course.status !== '10' &&
          _ctx.course.status !== '20' &&
          _ctx.course.status !== '60' &&
          _ctx.type === 'Active'
        )
          ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
              _createElementVNode("button", {
                class: "btn btn-primary bg-cyan-600 border-none",
                onClick: _cache[3] || (_cache[3] = ($event: any) => (updateCourseRequest(_ctx.course))),
                title: "Update"
              }, [
                _createVNode(_component_font_awesome_icon, { icon: "fa-solid fa-rotate" })
              ])
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_10, [
              _createElementVNode("button", {
                class: "btn btn-primary bg-green-400 border-none",
                onClick: _cache[4] || (_cache[4] = ($event: any) => (viewCourseRequest(_ctx.course))),
                title: "View Details"
              }, _cache[16] || (_cache[16] = [
                _createElementVNode("img", {
                  src: _imports_1,
                  class: "sidebar-image"
                }, null, -1)
              ]))
            ])),
        (
          _ctx.course.status == '10' ||
          _ctx.course.status == '20' ||
          _ctx.course.status == '60'
        )
          ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
              _createElementVNode("button", {
                class: "btn btn-primary",
                onClick: _cache[5] || (_cache[5] = ($event: any) => (viewSelectedRecommendations(_ctx.course)))
              }, " View Selected Practitioners "),
              _cache[17] || (_cache[17] = _createElementVNode("span", { style: {"font-style":"italic","font-size":"smaller"} }, " Practitioners' contact details available ", -1))
            ]))
          : (_ctx.type === 'Active')
            ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                _createElementVNode("button", {
                  class: "btn btn-primary collapsible",
                  onClick: _cache[6] || (_cache[6] = ($event: any) => (viewRecommendations(_ctx.course)))
                }, " View Recommendations ")
              ]))
            : _createCommentVNode("", true)
      ])
    ]),
    _createVNode(_unref(Dialog), {
      visible: _unref(visible),
      "onUpdate:visible": _cache[7] || (_cache[7] = ($event: any) => (_isRef(visible) ? (visible).value = $event : visible = $event)),
      modal: "",
      header: "Delete Course-Support Request",
      style: { width: '40vw' }
    }, {
      default: _withCtx(() => [
        _createVNode(_unref(Form), {
          onSubmit: deleteCourseRequest,
          "validation-schema": _unref(schema)
        }, {
          default: _withCtx(({ meta }) => [
            _createElementVNode("div", _hoisted_13, [
              _cache[18] || (_cache[18] = _createElementVNode("label", { for: "reasonDelete" }, "Please provide a reason for deleting the course-support request", -1)),
              _createVNode(_unref(Field), {
                name: "reasonDelete",
                type: "text",
                class: "form-control"
              }),
              _createVNode(_unref(ErrorMessage), {
                name: "reasonDelete",
                class: "error-feedback"
              })
            ]),
            _cache[20] || (_cache[20] = _createElementVNode("br", null, null, -1)),
            _createElementVNode("div", _hoisted_14, [
              _createElementVNode("button", {
                class: "btn btn-primary btn-block",
                disabled: _unref(loading) || !meta.valid
              }, [
                _withDirectives(_createElementVNode("span", _hoisted_16, null, 512), [
                  [_vShow, _unref(loading)]
                ]),
                _cache[19] || (_cache[19] = _createElementVNode("span", null, "Delete", -1))
              ], 8, _hoisted_15)
            ])
          ]),
          _: 1
        }, 8, ["validation-schema"])
      ]),
      _: 1
    }, 8, ["visible"]),
    _createVNode(_unref(Dialog), {
      visible: _unref(feedbackVisible),
      "onUpdate:visible": _cache[8] || (_cache[8] = ($event: any) => (_isRef(feedbackVisible) ? (feedbackVisible).value = $event : feedbackVisible = $event)),
      modal: "",
      header: "Course-Support Feedback",
      style: { width: '50vw' },
      breakpoints: { '768px': '90vw' }
    }, {
      default: _withCtx(() => [
        _createVNode(FeedbackFrom, {
          "curr-course-id": _unref(currCourse).id,
          onFetchCourse: emitFetchCourse
        }, null, 8, ["curr-course-id"])
      ]),
      _: 1
    }, 8, ["visible"])
  ], 64))
}
}

})