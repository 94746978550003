<template>
  <div class="grid-container">
    <app-sidebar />
    <div class="min-height">
      <BarChart
        class="mt-3"
        title="User Type"
        xKey="name"
        yKey="amount"
        :data="userType"
        v-if="successful"
      />
      <BarChart
        class="mt-3"
        title="User Gender Distribution"
        xKey="name"
        yKey="amount"
        :data="userGender"
        v-if="successful"
      />
      <BarChart
        class="mt-3"
        title="User Ethnicity Distribution"
        xKey="name"
        yKey="amount"
        :data="userEthnicity"
        v-if="successful"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import AdminService from "@/services/admin.service.js";
import AppSidebar from "@/components/user/AppSidebar.vue";
import BarChart from "@/components/admin/BarChart.vue";
import { groupBy } from "@/utils";

let loading = ref(false);
let successful = ref(false);
let message = ref("");
let items = ref([]);
let userGender = [],
  userEthnicity = [],
  userType = [];
const formatData = (data, outputObj) => {
  for (const key in data) {
    let label = "";
    if (key == "null") label = "Not Set";
    else label = key;
    outputObj.push({ name: label, amount: data[key].length });
  }
  console.log(outputObj);
};
const formatRoleData = (data, outputObj) => {
  for (const key in data) {
    let label = "";
    if (key == "1") label = "Instructor";
    else if (key == "2") label = "Practitioner";
    outputObj.push({ name: label, amount: data[key].length });
  }
  console.log(outputObj);
};

const getUsers = () => {
  loading.value = true;
  AdminService.getUsers().then(
    (response) => {
      items.value = response.data;
      console.log(response.data);
      formatRoleData(groupBy(items.value, "roleId"), userType);
      formatData(groupBy(items.value, "gender"), userGender);
      formatData(groupBy(items.value, "ethnicity"), userEthnicity);
      loading.value = false;
      successful.value = true;
    },
    (error) => {
      loading.value = false;
      message.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};

getUsers();
</script>

<style scoped></style>
