<template>
  <div class="header-space min-height">
    <div class="text-align-center m-3 min-height">
      <div class="mt-3" style="font-size: 5rem">
        <img
          src="@/assets/images/site/mail-tick.png"
          class="site-mail-verification-image"
        />
      </div>
      <h2 class="mt-3" style="font-weight: 500">
        Verify your email to continue
      </h2>
      <div class="mt-5">
        Email : <i>{{ userEmail }}</i> <br /><br />
        Verification Link expired? <br />
        <button class="btn btn-outline-dark" @click="resendVerification">
          Resend Verification Email
        </button>
        <br /><br />
        Need Help?
        <router-link :to="{ name: 'contact-us' }">Contact Support </router-link>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import AuthService from "@/services/auth.service.js";
import { useRoute, useRouter } from "vue-router";
import { showErrorToast, showToast } from "@/utils";

const route = useRoute();
const router = useRouter();

let loading = ref(false);
let successful = ref(false);
let message = ref("");
let userEmail = ref("");
userEmail.value = "nandaa@vt.edu";

const resendVerification = () => {
  loading.value = true;
  console.log(userEmail.value);
  AuthService.resendVerification(userEmail.value).then(
    (res) => {
      showToast(
        "Verification Mail Sent successfully. Please check your inbox."
      );
      loading.value = false;
    },
    (error) => {
      message.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      showErrorToast(message.value);
      loading.value = false;
    }
  );
};

watch(
  () => route.query.email,
  (email) => {
    userEmail.value = email as string;
  },
  { immediate: true }
);
</script>

<style scoped>
.site-mail-verification-image {
  height: 5rem;
  width: 5rem;
  display: inline-block;
}
</style>
