<template>
  <div class="grid-container">
    <app-sidebar />
    <div class="mt-3 min-height">
      <div class="back-grid">
        <back-button></back-button>
        <h2 class="text-align-center font-bold">User Guide</h2>
      </div>
      <div class="mt-3 lg:mt-5">
        <div class="heading">Overview</div>
        <div v-if="AuthStore.role === 'INSTRUCTOR'" class="mt-3 font-large">
          Thank you for using
          <router-link :to="{ name: 'home' }">ConPEC</router-link>
          . Here is a step-by-step guide on how to use the web platform. After
          you have signed up, verified your email address, and completed your
          profile, you can
          <ol class="list-style-type-auto ml-4">
            <li>
              <router-link to="#section-one">
                request for course-support (site visits, seminar session or
                guest lecture) and/or,
              </router-link>
            </li>
            <li>
              <router-link to="#section-two">
                request for resources (project videos, pictures and documents)
                from practitioners.
              </router-link>
            </li>
            <li>
              <router-link to="#section-three">
                upload resources (project videos, pictures and documents) to
                share with other instructors
              </router-link>
            </li>
          </ol>
          <div class="divider" />
          <div class="sub-heading" id="section-one">
            Steps to request for course-support
          </div>
          <div
            v-for="currStep in insReqCourseSupport"
            v-bind:key="currStep.stepId"
            class="mb-3 step-instruction"
          >
            <span class="sub-sub-heading">Step {{ currStep.stepId }}: </span>
            <span v-html="currStep.step"></span>
            <img
              :src="
                require(`@/assets/images/guide/instructor/${currStep.image}`)
              "
              :class="'guide-image mx-auto ' + currStep.widthClass"
            />
          </div>
          <div class="divider" />
          <div class="sub-heading" id="section-two">
            Steps to request for resources
          </div>
          <div
            v-for="currStep in insReqResources"
            v-bind:key="currStep.stepId"
            class="mb-3 step-instruction"
          >
            <span class="sub-sub-heading">Step {{ currStep.stepId }}: </span>
            <span v-html="currStep.step"></span>
            <img
              :src="
                require(`@/assets/images/guide/instructor/${currStep.image}`)
              "
              class="guide-image mx-auto"
            />
          </div>
          <div class="divider" />
          <div class="sub-heading" id="section-three">
            Steps to upload resources
          </div>
          <div
            v-for="currStep in insUploadResources"
            v-bind:key="currStep.stepId"
            class="mb-3 step-instruction"
          >
            <span class="sub-sub-heading">Step {{ currStep.stepId }}: </span>
            <span v-html="currStep.step"></span>
            <img
              :src="
                require(`@/assets/images/guide/instructor/${currStep.image}`)
              "
              class="guide-image mx-auto"
            />
          </div>
        </div>
        <div
          v-else-if="AuthStore.role === 'INDUSTRYPRACTITIONER'"
          class="mt-3 font-large"
        >
          Thank you for using
          <router-link :to="{ name: 'home' }">ConPEC</router-link>
          . Here is a step-by-step guide on how to use the web platform. After
          you have signed up, verified your email address, and completed your
          profile, and selected your availabilities, this guide will show you
          how to:
          <ol class="list-style-type-auto ml-4">
            <li>
              <router-link to="#section-one">
                respond to the course-support requests (e.g., guest lectures,
                site visits, seminars etc.) from instructors.
              </router-link>
            </li>
            <li>
              <router-link to="#section-two">
                respond to requests for resources (e.g., project pictures,
                documents, and videos) from instructors.
              </router-link>
            </li>
            <li>
              <router-link to="#section-three">
                upload resources (e.g., project pictures, documents, and videos)
                for instructors to use as instructional materials.
              </router-link>
            </li>
          </ol>
          <div class="divider" />
          <div class="sub-heading" id="section-one">
            Steps to respond to course-support request(s) from instructors
          </div>
          <div
            v-for="currStep in pracResCourseSupport"
            v-bind:key="currStep.stepId"
            class="mb-3 step-instruction"
          >
            <span class="sub-sub-heading">Step {{ currStep.stepId }}: </span>
            <span v-html="currStep.step"></span>
            <img
              :src="
                require(`@/assets/images/guide/practitioner/${currStep.image}`)
              "
              class="guide-image mx-auto"
            />
          </div>
          <div class="divider" />
          <div class="sub-heading" id="section-two">
            Steps to respond to request(s) for resources from instructors
          </div>
          <div
            v-for="currStep in pracResResourceReq"
            v-bind:key="currStep.stepId"
            class="mb-3 step-instruction"
          >
            <span class="sub-sub-heading">Step {{ currStep.stepId }}: </span>
            <span v-html="currStep.step"></span>
            <img
              :src="
                require(`@/assets/images/guide/practitioner/${currStep.image}`)
              "
              class="guide-image mx-auto"
            />
          </div>
          <div class="divider" />
          <div class="sub-heading" id="section-three">
            Steps to upload resources
          </div>
          <div
            v-for="currStep in pracUploadResources"
            v-bind:key="currStep.stepId"
            class="mb-3 step-instruction"
          >
            <span class="sub-sub-heading">Step {{ currStep.stepId }}: </span>
            <span v-html="currStep.step"></span>
            <img
              :src="
                require(`@/assets/images/guide/practitioner/${currStep.image}`)
              "
              class="guide-image mx-auto"
            />
          </div>
        </div>
        <div v-else></div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import AppSidebar from "@/components/user/AppSidebar.vue";
import BackButton from "@/components/user/BackButton.vue";
import { useAuthStore } from "@/stores/AuthStore";

const AuthStore = useAuthStore();
const currentUser = AuthStore.auth;

const insReqCourseSupport = ref([
  {
    stepId: "1",
    step: "As shown below, on the home page, click on <b>“My Classes”</b> on the left side to add a class/course.",
    image: "Picture1.png",
    widthClass: "w-80",
  },
  {
    stepId: "2",
    step: "Click on <b>“Add Class +”</b> as shown in the top right-hand corner in the image below. Then a pop-up shown in Step 3 will appear.",
    image: "Picture2.png",
    widthClass: "w-80",
  },
  {
    stepId: "3",
    step: "Complete the form that pops up as shown below and click on <b>“Add Class”</b>.",
    image: "Picture3.png",
    widthClass: "w-50",
  },
  {
    stepId: "4",
    step: "Click on <b>“Request Course-Support”</b> on the left side of the screen as shown below.",
    image: "Picture4.png",
    widthClass: "w-25",
  },
  {
    stepId: "5",
    step: "The web page shown below will appear, then click on “Create New +”.",
    image: "Picture5.png",
    widthClass: "w-80",
  },
  {
    stepId: "6",
    step: "The pop-up below will appear; click on the class/course you added in Step 3.",
    image: "Picture6.png",
    widthClass: "w-80",
  },
  {
    stepId: "7",
    step: "The web page below will appear. Please complete all the fields. Some of the fields are pre-populated from the information you supplied when you added a class. Remember to click <b>“Add“</b> for every field with the icon after you have put in the required details.",
    image: "Picture7.png",
    widthClass: "w-80",
  },
  {
    stepId: "8",
    step: "Please scroll down to complete all the fields. At the bottom of the web page click on the blue icon. Respond to the pop-ups and you will be returned to the home page as shown in Step 9 below.",
    image: "Picture8.png",
    widthClass: "w-80",
  },
  {
    stepId: "9",
    step: "Click on “View Recommendations”.",
    image: "Picture9.png",
    widthClass: "w-80",
  },
  {
    stepId: "10",
    step: "The web page will show you a list of recommended practitioners (about ten) that can meet the request you submitted. You can view details of each practitioner and select a maximum of three (3) out of the list.",
    image: "Picture10.png",
    widthClass: "w-80",
  },
  {
    stepId: "11",
    step: "After you have made your three (3) choices, click on <b>“Save Choices”</b>. Then respond to the pop-up and you will be redirected to the home page.",
    image: "Picture11.png",
    widthClass: "w-80",
  },
  {
    stepId: "12",
    step: "When one of the selected practitioners responds to your request, you will see this shown on your home page in addition to the notification in your email inbox.",
    image: "Picture12.png",
    widthClass: "w-80",
  },
  {
    stepId: "13",
    step: "Click on <b>“View Selected Practitioners”</b> to see the practitioner that accepted your request among those that you earlier selected.",
    image: "Picture13.png",
    widthClass: "w-80",
  },
  {
    stepId: "14",
    step: "See the image below",
    image: "Picture14.png",
    widthClass: "w-80",
  },
]);
const insReqResources = ref([
  {
    stepId: "1",
    step: "From the home page, click on <b>“Request Resources”</b> as shown below. Complete the fields shown on the screen and click on <b>“Request Resources”</b> to submit.",
    image: "Picture15.png",
  },
  {
    stepId: "2",
    step: "After you have submitted your request, it will appear on the screen as shown below.",
    image: "Picture16.png",
  },
  {
    stepId: "3",
    step: "When there is a response to your request, the screen will be as shown below. Click on <b>“View Response(s)”</b> to download what the practitioners as provided.",
    image: "Picture17.png",
  },
]);
const insUploadResources = ref([
  {
    stepId: "1",
    step: "Click on “Upload Resources” as shown.",
    image: "Picture18.png",
  },
  {
    stepId: "2",
    step: "Select the content type, type in a description of the content you are about to upload. Under “content visibility reach”, select the institution(s) you want to give access to the material you are about to upload. You can also directly type-in the email address of instructor(s) you want to share the material with.",
    image: "Picture19.png",
  },
  {
    stepId: "3",
    step: "You can drag and drop what you want to upload, or you can click on “Choose File” and you can select the file from your device. Another option (especially for large files) is that you can provide the website link or URL where the resource can be downloaded.",
    image: "Picture20.png",
  },
  {
    stepId: "4",
    step: "After you have chosen a file from device, check the two boxes as shown and click on <b>“Upload”</b>",
    image: "Picture21.png",
  },
]);

const pracResCourseSupport = ref([
  {
    stepId: "1",
    step: "When you have course-support requests from instructors that are directed to you, the home page will appear as shown below. Other requests from instructors that are not specifically directly to you but to which you can respond will be displayed under <b>“General Course-Support Requests”</b>.",
    image: "Picture1.png",
  },
  {
    stepId: "2",
    step: "To respond to any of the course-support requests directed to you, click on <b>“View Details”</b> as shown below.",
    image: "Picture2.png",
  },
  {
    stepId: "3",
    step: "The screen below will appear showing you the details of the request as well as some information about the instructor. After you have gone through the information on the screen, scroll down to accept or decline the request as shown below. To accept, click on <b>“Accept”</b>.",
    image: "Picture3.png",
  },
  {
    stepId: "4",
    step: "A pop-up shown below will appear. If the instructors provided multiple dates and time, you would see options under <b>“Time Preference”</b> and you can select one that you prefer. You can also type a message to the instructor in the text box provided. Finally click on <b>“Accept”</b>.",
    image: "Picture4.png",
  },
]);
const pracResResourceReq = ref([
  {
    stepId: "1",
    step: "Click on <b>“Request(s) for Resources”</b>. Instructors’ requests for resources will be displayed as shown below. Click on <b>“Respond”</b> if you are willing to provide what is requested.",
    image: "Picture5.png",
  },
  {
    stepId: "2",
    step: "You can drag and drop what you want to upload, or you can click on “Choose File” and you can select the file from your device. Another option (especially for large files) is that you can provide the website link or URL where the requested resource can be downloaded.",
    image: "Picture6.png",
  },
  {
    stepId: "3",
    step: "After you have chosen a file from device, check the two boxes as shown and click on <b>“Upload”</b>",
    image: "Picture7.png",
  },
]);
const pracUploadResources = ref([
  {
    stepId: "1",
    step: "Click on “Upload Resources” as shown.",
    image: "Picture8.png",
  },
  {
    stepId: "2",
    step: "Select the content type, type in a description of the content you are about to upload. Under “content visibility reach”, select the institution(s) you want to give access to the material you are about to upload. You can also directly type-in the email address of instructor(s) you want to share the material with and press “ENTER” on the keyboard.",
    image: "Picture9.png",
  },
  {
    stepId: "3",
    step: "You can drag and drop what you want to upload, or you can click on “Choose File” and you can select the file from your device. Another option (especially for large files) is that you can provide the website link or URL where the requested resource can be downloaded.",
    image: "Picture10.png",
  },
  {
    stepId: "4",
    step: "After you have chosen a file from device, check the two boxes as shown and click on <b>“Upload”</b>",
    image: "Picture11.png",
  },
]);
</script>

<style scoped>
.heading {
  font-size: 2.5rem;
  line-height: 2.75rem;
  margin-top: 1rem;
  font-weight: bold;
}

.sub-heading {
  font-size: 2rem;
  font-weight: bold;
  font-style: italic;
  text-decoration: underline;
  text-align: center;
  margin: 1rem 0 1rem 0;
}

.sub-sub-heading {
  margin-top: 0.5rem;
  font-weight: bold;
}

.step-instruction > span,
.font-large {
  font-size: 1.2rem;
  line-height: 1.5rem;
}

.guide-image {
  display: block;
  width: 80%;
  border: 2px solid black;
}

.divider {
  border-top: 2px solid #a4a6a9;
  margin: 2rem 2rem 1rem 0;
}

.w-80 {
  width: 80% !important;
}

@media only screen and (max-width: 768px) {
  .heading {
    font-size: 1.25rem;
    line-height: 1.5rem;
    margin-top: 0.5rem;
  }

  .sub-heading {
    font-size: 1rem;
    margin: 0.5rem 0 0.5rem 0;
  }

  .sub-sub-heading {
    margin-top: 0.25rem;
  }

  .step-instruction > span,
  .font-large {
    font-size: 0.8rem;
    line-height: 1rem;
  }
}
</style>
