<template>
  <div class="grid-container">
    <instructor-sidebar></instructor-sidebar>
    <div class="content min-height m-3">
      <div class="back-grid">
        <back-button></back-button>
        <h2 style="text-align: center; font-weight: bold">
          Course-Support Request Information
        </h2>
      </div>
      <div class="m-3 lg:m-5">
        <div
          class="justify-content-center lg:justify-content-between course-item"
        >
          <h3>Saved Course-Support Request</h3>
          <div
            class="mt-3 m-0 lg:mt-0 py-1 px-2 lg:p-3 card cursor-pointer inline-block"
            style="width: max-content"
            @click="isDialogVisible = true"
          >
            <b> Create New </b>
            <font-awesome-icon
              class="ml-2"
              title="Create New Course-Support Request"
              icon="fa-solid fa-plus"
            />
          </div>
        </div>
        <div
          v-if="pendingCourse.length > 0"
          class="course-item justify-content-center lg:justify-content-start mt-3 lg:mt-0"
        >
          <div
            class="m-0 ml-1 mt-2 py-1 px-2 lg:p-3 card cursor-pointer"
            v-for="course in pendingCourse"
            :key="course"
          >
            <span @click="editCourseRequest(course)">
              <b>{{ course.courseRequestType }} </b> <br />
              {{ new Date(course.updatedAt).toDateString() }}
            </span>
            <div class="align-self-end">
              <font-awesome-icon
                icon="fa-solid fa-trash"
                @click="deleteCourseRequest(course)"
              />
            </div>
          </div>
        </div>
        <div v-else class="mt-3 text-align-center lg:font-bold">
          You don't have any saved course-support requests.
        </div>
      </div>
    </div>
  </div>
  <Dialog
    v-model:visible="isDialogVisible"
    modal
    header="Select Class"
    :style="{ width: '50vw' }"
    :breakpoints="{ '768px': '90vw' }"
  >
    <div v-if="myClasses.length > 0" class="course-item">
      <div
        class="m-0 ml-1 mt-2 py-1 px-2 lg:p-3 card cursor-pointer"
        style="width: max-content"
        v-for="currClass in myClasses"
        :key="currClass.id"
      >
        <span @click="createCourseRequest(currClass)">
          <b>{{ currClass.courseCode }} </b> <br />
          {{ currClass.courseTitle }}
        </span>
      </div>
    </div>
    <div
      class="m-0 mt-2 py-1 px-2 lg:p-3 card inline-block cursor-pointer course-item-margin"
      @click="(isDialogVisible = false), (isAddClassVisible = true)"
    >
      <b> Add Class </b>
      <font-awesome-icon
        class="ml-2"
        title="Add Class"
        icon="fa-solid fa-plus"
      />
    </div>
  </Dialog>
  <Dialog
    v-model:visible="isAddClassVisible"
    modal
    header="Add Class"
    :style="{ width: '50vw' }"
    :breakpoints="{ '768px': '75vw' }"
  >
    <class-form
      :successAction="saveCourseSuccess"
      action="create"
      :course="course"
    />
  </Dialog>
</template>

<script setup lang="ts">
import { ref } from "vue";
import InstructorSidebar from "@/components/instructor/InstructorSidebar.vue";
import BackButton from "@/components/user/BackButton.vue";
import ClassForm from "@/components/instructor/ClassForm.vue";
import Dialog from "primevue/dialog";
import { useModal } from "vuestic-ui";
import InstructorService from "@/services/instructor.service.js";
import { useCourseStore } from "@/stores/CourseStore";
import { useRouter } from "vue-router";
import { convertCourseToCourseRequest, showToast } from "@/utils";
import { Course } from "@/types";

const CourseStore = useCourseStore();
const router = useRouter();
const { confirm } = useModal();
let pendingCourse = ref([]);
let message = ref("");
let loading = ref(false);
let myClasses = ref([] as Course[]);
let isDialogVisible = ref(false);
let isAddClassVisible = ref(false);

const getTempCourseRequest = (pageNumber = 1) => {
  InstructorService.getTempCourseRequest(pageNumber).then(
    (response) => {
      console.log(response);
      pendingCourse.value = response.data.courses;
      console.log(pendingCourse);
    },
    (error) => {
      message.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};

const deleteCourseRequest = async (data) => {
  const cancelDeleteRequest = await confirm({
    message: "Are you sure you want to delete this course support request?",
    cancelText: "Yes",
    okText: "No",
  });
  if (!cancelDeleteRequest) {
    loading.value = true;
    InstructorService.deleteTempCourseRequest(data).then(
      (_) => {
        getTempCourseRequest();
        showToast("The course support request has been successfully deleted.");
        loading.value = false;
      },
      (error) => {
        loading.value = false;
        message.value =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  }
};

const getCourse = (pageNumber = 1) => {
  InstructorService.getCourse(pageNumber).then(
    (response) => {
      myClasses.value = response.data;
    },
    (error) => {
      message.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};

const editCourseRequest = (course) => {
  CourseStore.addCourse(course);
  router.push({ name: "edit-course-request" });
};

const createCourseRequest = (course: Course | Course) => {
  CourseStore.removeCourse();
  let courseRequest = convertCourseToCourseRequest(course);
  CourseStore.addCourse(courseRequest);
  router.push({ name: "create-class-course-request" });
};

const saveCourseSuccess = (values) => {
  showToast("Class has been saved.");
  getCourse();
  isAddClassVisible.value = false;
};

getTempCourseRequest();
getCourse();
</script>

<style scoped>
.course-item {
  align-items: center;
  border-radius: 4px;
  display: flex;
  justify-content: flex-start;
  margin: 0 0.125rem;
  padding: 0.125rem 0.125rem;
  flex-direction: row;
  flex-wrap: wrap;
}

.course-item-margin {
  margin-left: 0.375rem !important;
}

.card {
  padding: 0 10px 10px 0;
}
</style>
