import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "grid-container" }
const _hoisted_2 = { class: "container min-height" }
const _hoisted_3 = { class: "back-grid" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "paginator" }
const _hoisted_6 = {
  key: 1,
  class: "mt-3"
}
const _hoisted_7 = { style: {"text-align":"center","font-style":"italic"} }

import InstructorSidebar from "@/components/instructor/InstructorSidebar.vue";
import BackButton from "@/components/user/BackButton.vue";
import CourseRequestItem from "@/components/instructor/CourseRequestItem.vue";
import InstructorService from "@/services/instructor.service.js";
import { ref, watch } from "vue";
import { useRoute } from "vue-router";
import { CourseRequest } from "@/types";


export default /*@__PURE__*/_defineComponent({
  __name: 'ClassCourseRequestView',
  setup(__props) {

const route = useRoute();
let message = ref("");
let loading = ref(false);
let content = ref([] as CourseRequest[]);
const currentPage = ref(1);
const totalPages = ref(1);

const getCourseRequest = (courseCode, type = "class", pageNumber = 1) => {
  console.log(courseCode);
  loading.value = true;
  InstructorService.getInstructorBoard(pageNumber, type, courseCode).then(
    (response) => {
      console.log(response);
      content.value = response.data.courses;
      totalPages.value = response.data.totalPages;
      currentPage.value = response.data.currentPage;
      loading.value = false;
    },
    (error) => {
      message.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};

const futureOnClick = () => {
  getCourseRequest(route.params.courseCode, "class", currentPage.value);
};

console.log(route.params);

watch(
  () => route.params.courseCode,
  (courseCode) => {
    getCourseRequest(courseCode as string);
  },
  { immediate: true }
);

return (_ctx: any,_cache: any) => {
  const _component_va_pagination = _resolveComponent("va-pagination")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(InstructorSidebar),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(BackButton),
        _cache[1] || (_cache[1] = _createElementVNode("h2", { class: "text-align-center font-bold" }, "Course-Support Requests", -1))
      ]),
      _cache[2] || (_cache[2] = _createElementVNode("h2", { class: "text-align-center" }, null, -1)),
      (_unref(content).length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("ul", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(content), (course) => {
                return (_openBlock(), _createElementBlock("li", {
                  key: course.id
                }, [
                  _createVNode(CourseRequestItem, {
                    course: course,
                    onFetchCourse: getCourseRequest
                  }, null, 8, ["course"])
                ]))
              }), 128))
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_va_pagination, {
                modelValue: currentPage.value,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((currentPage).value = $event)),
                pages: totalPages.value,
                "visible-pages": 3,
                hideOnSinglePage: true,
                onClick: futureOnClick
              }, null, 8, ["modelValue", "pages"])
            ])
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createElementVNode("h3", _hoisted_7, " No course-support request raised for course " + _toDisplayString(_unref(route).params.courseCode) + ". ", 1)
          ]))
    ])
  ]))
}
}

})