<template>
  <div style="padding: 5px">
    <div class="font-larger font-bold">
      Please indicate your date and time preferences based on your schedule
    </div>
    <span>Unavailable</span>
    <svg width="40" height="36">
      <rect x="5" y="8" width="32" height="16" fill="#ffdede" stroke="black" />
    </svg>
    <span class="ml-6">Available</span>
    <svg width="40" height="36">
      <rect x="5" y="8" width="32" height="16" fill="#339900" stroke="black" />
    </svg>
    <span class="ml-6">Public Holiday</span>
    <svg width="40" height="36">
      <rect x="5" y="8" width="32" height="16" fill="#EBEBE4" stroke="black" />
    </svg>
    <div>
      Your time zone is
      <b
        ><i>{{ getTimezoneName() }}</i></b
      >
    </div>
  </div>
  <div
    v-for="(month, index) in monthDays"
    v-bind:key="index"
    class="mt-3 avail-calender"
    :onmouseup="selectStop"
  >
    <span style="font-size: x-large">{{ month.longName }}</span>
    <div class="flex flex-direction-row text-align-center ml-5">
      <div
        v-for="j in month.noOfDays"
        v-bind:key="j"
        style="font-size: 0.6rem"
        class="flex-grow-0 flex-shrink-1"
      >
        <div
          v-if="
            index === 0
              ? getDay(month.year, month.index, currDate + j) != 6 &&
                getDay(month.year, month.index, currDate + j) != 0
              : getDay(month.year, month.index, j) != 6 &&
                getDay(month.year, month.index, j) != 0
          "
          class="w-2rem mx-1"
        >
          <span v-if="index === 0"
            >{{ month.name + " " + (currDate + j) }}
            {{ days[getDay(month.year, month.index, currDate + j)] }}
          </span>
          <span v-else
            >{{ month.name + " " + j }}
            {{ days[getDay(month.year, month.index, j)] }}</span
          >
        </div>
      </div>
    </div>
    <div v-for="i in 14" v-bind:key="i" class="line-height-1">
      <div class="flex flex-direction-row align-end">
        <div
          class="w-2rem flex-shrink-1 flex-grow-0 pr-1"
          style="text-align: right; font-size: 0.6rem"
        >
          <span v-if="i == 1" class="position-relative top-1">
            7 am <br />
          </span>
          <span>{{ i + 7 > 12 ? i + 7 - 12 + " pm" : i + 7 + " am" }}</span>
        </div>
        <div>
          <div v-for="j in month.noOfDays" v-bind:key="j" class="inline-block">
            <div
              v-if="
                index === 0
                  ? getDay(month.year, month.index, currDate + j) != 6 &&
                    getDay(month.year, month.index, currDate + j) != 0
                  : getDay(month.year, month.index, j) != 6 &&
                    getDay(month.year, month.index, j) != 0
              "
              :data-row="j"
              :data-col="i + 7"
              :data-month="month.index"
              :data-time="
                (index === 0
                  ? Date.UTC(month.year, month.index, currDate + j, i + 7 - 1)
                  : Date.UTC(month.year, month.index, j, i + 7 - 1)) / 1000
              "
              :id="
                'YouTime' +
                (index === 0
                  ? Date.UTC(month.year, month.index, currDate + j, i + 7 - 1)
                  : Date.UTC(month.year, month.index, j, i + 7 - 1)) /
                  1000
              "
              :class="{
                disabled:
                  index === 0
                    ? isPublicHoliday(month.year, month.index, currDate + j)
                    : isPublicHoliday(month.year, month.index, j),
              }"
              class="time-grid"
              :onmousedown="selectFromHere"
              :onmouseover="selectToHere"
              :ontouchstart="selectFromHereByTouch"
              :ontouchmove="selectToHereByTouch"
              :ontouchend="selectStopByTouch"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    v-for="(month, index) in monthDays"
    v-bind:key="index"
    class="mt-3 avail-calender-mob"
    :onmouseup="selectStop"
  >
    <span style="font-size: x-large">{{ month.longName }}</span>
    <div
      class="flex flex-direction-row text-align-center"
      style="margin-left: 3.5rem"
    >
      <div
        class="flex-grow-0 flex-shrink-1 w-1rem"
        style="font-size: 0.6rem; display: inline-block"
      >
        7 am
      </div>
      <div
        v-for="i in 14"
        v-bind:key="i"
        class="flex-grow-0 flex-shrink-1 w-1rem"
      >
        <div style="font-size: 0.6rem">
          <span>{{ i + 7 > 12 ? i + 7 - 12 + " pm" : i + 7 + " am" }}</span>
        </div>
      </div>
    </div>
    <div
      v-for="j in month.noOfDays"
      v-bind:key="j"
      style="font-size: 0.6rem"
      class="line-height-1"
    >
      <div class="flex flex-direction-row align-end">
        <div
          v-if="
            index === 0
              ? getDay(month.year, month.index, currDate + j) != 6 &&
                getDay(month.year, month.index, currDate + j) != 0
              : getDay(month.year, month.index, j) != 6 &&
                getDay(month.year, month.index, j) != 0
          "
          class="w-4rem pr-1 flex-shrink-1 flex-grow-0"
          style="text-align: right"
        >
          <span v-if="index === 0"
            >{{ days[getDay(month.year, month.index, currDate + j)] }}
            {{ month.name + " " + (currDate + j) }}
          </span>
          <span v-else
            >{{ days[getDay(month.year, month.index, j)] }}
            {{ month.name + " " + j }}
          </span>
        </div>
        <div v-for="i in 14" v-bind:key="i" class="inline-block flex-grow-0">
          <div
            v-if="
              index === 0
                ? getDay(month.year, month.index, currDate + j) != 6 &&
                  getDay(month.year, month.index, currDate + j) != 0
                : getDay(month.year, month.index, j) != 6 &&
                  getDay(month.year, month.index, j) != 0
            "
            :data-row="j"
            :data-col="i + 7"
            :data-month="month.index"
            :data-time="
              (index === 0
                ? Date.UTC(month.year, month.index, currDate + j, i + 7 - 1)
                : Date.UTC(month.year, month.index, j, i + 7 - 1)) / 1000
            "
            :id="
              'YouTime' +
              (index === 0
                ? Date.UTC(month.year, month.index, currDate + j, i + 7 - 1)
                : Date.UTC(month.year, month.index, j, i + 7 - 1)) /
                1000
            "
            :class="{
              disabled:
                index === 0
                  ? isPublicHoliday(month.year, month.index, currDate + j)
                  : isPublicHoliday(month.year, month.index, j),
            }"
            class="time-grid"
            :onmousedown="selectFromHere"
            :onmouseover="selectToHere"
            :ontouchstart="selectFromHereByTouch"
            :ontouchmove="selectToHereByTouch"
            :ontouchend="selectStopByTouch"
          />
        </div>
      </div>
    </div>
  </div>
  <div class="text-align-center my-3">
    <button
      class="btn btn-secondary ml-2"
      type="button"
      @click="goToPreviousStep"
    >
      Previous
    </button>

    <button
      class="btn btn-primary ml-2"
      type="button"
      @click="saveAvailabilityTime"
    >
      <span v-if="props.lastStep">Save Availability</span>
      <span v-else>Next</span>
    </button>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue";
import {
  monthNames,
  longMonthNames,
  showErrorToast,
  getTimezoneName,
} from "@/utils";
import { useAuthStore } from "@/stores/AuthStore";
import { Availability } from "@/types";

// eslint-disable-next-line no-undef
const props = defineProps<{
  availability: Availability;
  lastStep: boolean;
}>();
// eslint-disable-next-line no-undef
const emits = defineEmits(["previousStep", "nextStep"]);
const AuthStore = useAuthStore();

const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
const getDay = (year, month, date) => {
  return new Date(year, month, date).getDay();
};

const isPublicHoliday = (year, month, date) => {
  let fullDate = new Date(year, month, date);
  const publicHolidays = [
    new Date(2023, 10, 23).getTime(),
    new Date(2023, 11, 25).getTime(),
    new Date(2024, 0, 1).getTime(),
    new Date(2024, 0, 15).getTime(),
    new Date(2024, 4, 27).getTime(),
    new Date(2024, 5, 19).getTime(),
    new Date(2024, 6, 4).getTime(),
  ];
  return publicHolidays.includes(fullDate.getTime()) ? true : false;
};

// let baseDate = Date.UTC(new Date().getFullYear(), 0, 1);
let currMonth = new Date().getMonth();
let currDate = new Date().getDate();
let monthDays = [] as any[];
for (let i = 1; i <= 3; i++) {
  let monthObject = {
    year: new Date().getFullYear() + (currMonth + (i - 1) > 11 ? 1 : 0),
    index: (currMonth + i - 1) % 12,
    name: monthNames[(currMonth + (i - 1)) % 12],
    longName: longMonthNames[(currMonth + (i - 1)) % 12],
    noOfDays:
      i === 1
        ? new Date(
            new Date().getFullYear(),
            (currMonth + i) % 12,
            0
          ).getDate() - currDate
        : new Date(new Date().getFullYear(), (currMonth + i) % 12, 0).getDate(),
  };
  monthDays.push(monthObject);
}

let selectionMode = false,
  isMouseDown = false;
var ToCol = -1,
  ToRow = -1,
  FromCol = -1,
  FromRow = -1,
  Month = -1,
  TimeOfSlot = ref([] as number[]);

let TimeGrid = document.getElementsByClassName("time-grid");
const ReColorIndividual = () => {
  let ColA, ColB, RowA, RowB, i, NewColor;
  if (FromCol < ToCol) {
    ColA = FromCol;
    ColB = ToCol;
  } else {
    ColA = ToCol;
    ColB = FromCol;
  }
  if (FromRow < ToRow) {
    RowA = FromRow;
    RowB = ToRow;
  } else {
    RowA = ToRow;
    RowB = FromRow;
  }
  // TimeGrid = document.getElementsByClassName("month-" + Month);
  for (i = 0; i < TimeGrid.length; i++) {
    let currentElement = TimeGrid[i] as HTMLElement;
    if (currentElement) {
      let dataCol = Number(currentElement.getAttribute("data-col"));
      let dataRow = Number(currentElement.getAttribute("data-row"));
      let dataMonth = Number(currentElement.getAttribute("data-month"));
      let dataTime = Number(currentElement.getAttribute("data-time"));

      let WithinX = ColA <= dataCol && dataCol <= ColB;
      let WithinY = RowA <= dataRow && dataRow <= RowB;
      let sameMonth = dataMonth === Month;

      if (selectionMode) NewColor = "#339900";
      else NewColor = "#ffdede";

      if (sameMonth && WithinX && WithinY && isMouseDown) {
        currentElement.style.background = NewColor;
      } else {
        if (TimeOfSlot.value.includes(dataTime)) {
          // console.log("Changing Color bcz of TimeOfSlot");
          currentElement.style.background = "#339900";
        } else {
          currentElement.style.background = "#ffdede";
        }
      }
    }
  }
};

const selectFromHere = (e) => {
  let timeOfSlot = Number(e.target.getAttribute("data-time"));
  // slotID = TimeOfSlot.indexOf(timeOfSlot);
  selectionMode = !TimeOfSlot.value.includes(timeOfSlot);
  isMouseDown = true;

  ToCol = Number(e.target.getAttribute("data-col"));
  ToRow = Number(e.target.getAttribute("data-row"));
  Month = Number(e.target.getAttribute("data-month"));
  FromCol = ToCol;
  FromRow = ToRow;
  ReColorIndividual();
};
const selectToHere = (e) => {
  if (isMouseDown) {
    ToCol = Number(e.target.getAttribute("data-col"));
    ToRow = Number(e.target.getAttribute("data-row"));
    ReColorIndividual();
  }
};
const selectStop = () => {
  if (!isMouseDown) return;
  let ColA, ColB, RowA, RowB, i;
  if (FromCol < ToCol) {
    ColA = FromCol;
    ColB = ToCol;
  } else {
    ColA = ToCol;
    ColB = FromCol;
  }
  if (FromRow < ToRow) {
    RowA = FromRow;
    RowB = ToRow;
  } else {
    RowA = ToRow;
    RowB = FromRow;
  }

  // TimeGrid = document.getElementsByClassName("month-" + Month);
  for (i = 0; i < TimeGrid.length; i++) {
    let currentElement = TimeGrid[i] as HTMLElement;
    if (currentElement) {
      let dataCol = Number(currentElement.getAttribute("data-col"));
      let dataRow = Number(currentElement.getAttribute("data-row"));
      let dataMonth = Number(currentElement.getAttribute("data-month"));
      let dataTime = Number(currentElement.getAttribute("data-time"));

      let WithinX = ColA <= dataCol && dataCol <= ColB;
      let WithinY = RowA <= dataRow && dataRow <= RowB;
      let sameMonth = dataMonth === Month;

      if (sameMonth && WithinX && WithinY) {
        if (selectionMode) TimeOfSlot.value.push(dataTime);
        else {
          // console.log(dataTime);
          const beforeSplit = TimeOfSlot.value;
          let SplitSpot = TimeOfSlot.value.indexOf(dataTime);
          // console.log(SplitSpot);
          TimeOfSlot.value.splice(SplitSpot, 1);
          // console.log(TimeOfSlot.value.filter((x) => !beforeSplit.includes(x)));
          // console.log(TimeOfSlot.value.length);
        }
      }
    }
  }

  isMouseDown = false;
  FromCol = -1;
  ToCol = -1;
  FromRow = -1;
  ToRow = -1;
  Month = -1;
  ReColorIndividual();
};

// const checkDirty = async () => {
//   if (props.availability !== ) {
//     const answer = await confirm({
//       message:
//         "Your changes on this page have not been saved. Kindly save your changes before leaving the page. Do you want to proceed without saving your changes?",
//       okText: "Yes",
//       cancelText: "No",
//     });
//     if (answer) return true;
//     else return false;
//   } else return true;
// };

const goToPreviousStep = async () => {
  // const answer = await checkDirty();
  // await console.log(answer);
  // if (!answer) return false;
  emits("previousStep");
};

const saveAvailabilityTime = () => {
  if (TimeOfSlot.value.length > 0) {
    // console.log(TimeOfSlot.value);
    let availabilityTimes = [] as string[];
    TimeOfSlot.value.forEach((el) => {
      availabilityTimes.push(new Date(el * 1000).toISOString());
    });
    const availabilityTime = Array.from(new Set(availabilityTimes)).map(
      (el) => ({
        availabilityTime: el,
        userId: AuthStore.auth.id,
      })
    );
    // console.log(availabilityTimes);
    emits("nextStep", availabilityTime);
    // console.log(availabilityTime);
  } else {
    emits("nextStep", []);
  }
};

const transformInitialData = (availability) => {
  // console.log(availability);
  // console.log(baseDate);
  availability.forEach((el) => {
    let date = new Date(el.availabilityTime);
    let dateDiff =
      Date.UTC(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate(),
        date.getUTCHours()
      ) / 1000;
    if (!TimeOfSlot.value.includes(dateDiff)) TimeOfSlot.value.push(dateDiff);
  });
  // console.log(TimeOfSlot.value);
  ReColorIndividual();
};

const selectFromHereByTouch = (e) => {
  if (e.touches.length == 1) {
    selectFromHere(e);
    e.preventDefault();
  } else {
    return true;
  }
};
const selectToHereByTouch = (e) => {
  if (e.touches.length == 1) {
    e.preventDefault();
    let touch = e.touches[0];
    let elem = document.elementFromPoint(touch.clientX, touch.clientY);
    if (elem?.hasAttribute("data-col")) {
      ToCol = Number(elem.getAttribute("data-col"));
      ToRow = Number(elem.getAttribute("data-row"));
      ReColorIndividual();
    }
  } else {
    return true;
  }
};

function selectStopByTouch() {
  selectStop();
}

onMounted(() => {
  transformInitialData(props.availability.availabilityTime);
  // transformInitialData(userAvailability);
});
</script>

<style scoped>
.time-grid {
  vertical-align: top;
  display: inline-block;
  zoom: 1;
  width: 2.5rem;
  height: 1rem;
  font-size: 0px;
  outline: 1px solid black;
  background: rgb(255, 222, 222);
}

.disabled-background {
  background: rgb(235, 235, 228);
}

.top-1 {
  top: -0.25rem;
}

left-1 {
  left: -0.25rem;
}

.disabled {
  pointer-events: none;
  background: rgb(235, 235, 228) !important;
}

.avail-calender {
  display: block;
}

.avail-calender-mob {
  display: none;
}

@media screen and (max-width: 768px) {
  .avail-calender {
    display: none;
  }

  .avail-calender-mob {
    display: block;
  }

  .time-grid {
    width: 1rem;
    height: 0.75rem;
  }
}
</style>
