<template>
  <div class="grid-container">
    <practitioner-sidebar />
    <div class="m-3 min-height">
      <div class="back-grid">
        <back-button></back-button>
        <h2 style="text-align: center; font-weight: bold">
          Request(s) For Resources
        </h2>
      </div>
      <div class="mt-8">
        <div v-if="resourceRequests.length > 0" class="course-item mt-5">
          <div
            class="m-1 card"
            v-for="resourceRequest in resourceRequests"
            :key="resourceRequest.id"
          >
            <div
              class="flex flex-direction-column text-center lg:text-left lg:flex-row justify-content-between align-content-center flex-wrap"
            >
              <div>
                <b>{{ resourceRequest.type }} </b>
                <span v-if="resourceRequest.resource_responses.length > 0"
                  ><font-awesome-icon
                    icon="fa-solid fa-check"
                    style="color: #0bda51; font-size: x-large"
                  /><span style="font-size: smaller"
                    >You have successfully responded to this request!</span
                  >
                </span>
                <br />
                {{ resourceRequest.description }}
              </div>
              <div
                class="flex flex-grow-0 text-center align-content-center sm:mt-2 lg:mr-3 lg:flex-wrap"
              >
                <span
                  v-if="resourceRequest.status === '90'"
                  class="mr-3 align-self-center font-bold"
                >
                  Closed
                </span>

                <span v-else>
                  <button
                    @click="fileType(resourceRequest)"
                    class="btn btn-primary mr-3"
                  >
                    <span v-if="resourceRequest.resource_responses.length > 0"
                      >Respond Again</span
                    >
                    <span v-else>Respond</span>
                  </button>
                </span>
              </div>
            </div>
          </div>
          <div class="paginator">
            <va-pagination
              v-model="currentPage"
              :pages="totalPages"
              :visible-pages="3"
              :hideOnSinglePage="true"
              @click="onClick"
            />
          </div>
        </div>
        <div v-else class="text-align-center">
          No request for resources from instructors at the moment
        </div>
      </div>
    </div>
  </div>
  <Dialog
    v-model:visible="visible"
    modal
    :header="currResourceRequest.type"
    :style="{ width: '75vw' }"
  >
    <i> {{ currResourceRequest.description }} </i>
    <Form
      @submit="onValidSubmit"
      @invalid-submit="onInvalidSubmit"
      :validation-schema="schema"
      :initial-values="initialState"
      enctype="multipart/form-data"
    >
      <div
        class="form-group flex justify-space-between flex-direction-column mt-3 lg:flex-row"
        v-if="
          !resourceResponseFields.find((el) => el.name === 'files')?.disabled
        "
      >
        <div class="flex-grow-0 flex-shrink-1">
          <label style="margin-top: 0">Upload File(s)</label>
        </div>
        <div class="ml-3">
          <Field name="file" v-slot="{ handleChange, handleBlur }">
            <va-file-upload
              v-model="files"
              dropzone
              @change="handleChange"
              @blur="handleBlur"
              uploadButtonText="Choose File"
              :file-types="allowedFileType"
            />
          </Field>
          <div class="font-color-banner mt-0">
            Maximum allowable file size is 25MB
          </div>
          <div
            v-if="currResourceRequest.type === 'Project Pictures'"
            class="font-color-banner"
          >
            Allowed File Type: .xbm, .tif, .pjp, .apng, .jpg, .jpeg, .ico,
            .tiff, .gif, .svg, .jfif, .webp, .png, .bmp, .avif, .heic
          </div>
          <div
            v-else-if="currResourceRequest.type === 'Video'"
            class="font-color-banner"
          >
            Allowed File Type: .ogm, .wmv, .mpg, .webm, .ogv, .mov, .asx, .mpeg,
            .mp4, .m4v, .avi
          </div>
          <div
            v-else-if="currResourceRequest.type === 'Document'"
            class="font-color-banner"
          >
            Allowed File Type: .pdf, .doc, .docx, .txt, .ppt, .pptx
          </div>
          <div v-else>
            <br />
          </div>
        </div>
      </div>
      <div
        class="form-group bold-text ml-5"
        v-if="
          !resourceResponseFields.find((el) => el.name === 'files')?.disabled &&
          !resourceResponseFields.find((el) => el.name === 'linkResources')
            ?.disabled
        "
      >
        OR
      </div>
      <div
        class="form-group flex justify-space-between flex-direction-column lg:flex-row"
        v-if="
          !resourceResponseFields.find((el) => el.name === 'linkResources')
            ?.disabled
        "
      >
        <div class="flex-grow-0 flex-shrink-1">
          <label for="linkResources">Provide Link</label>
        </div>
        <div class="ml-3">
          <Field name="linkResources" v-slot="{ field }">
            <input
              v-bind="field"
              class="form-control"
              type="text"
              placeholder="https://"
            />
            <ErrorMessage name="linkResources" class="error-feedback" />
          </Field>
        </div>
      </div>
      <div
        class="form-group"
        v-if="
          !resourceResponseFields.find((el) => el.name === 'rightToUpload')
            ?.disabled
        "
      >
        <Field name="rightToUpload" value="True" type="checkbox" /><span
          class="super font-color-red"
          >*</span
        >
        I confirm that I have the right to upload this content and share it with
        instructors to enhance their instructional materials. <br />
        <ErrorMessage name="rightToUpload" class="error-feedback" />
      </div>
      <div
        class="form-group"
        v-if="
          !resourceResponseFields.find((el) => el.name === 'personalInfo')
            ?.disabled
        "
      >
        <Field name="personalInfo" value="True" type="checkbox" /><span
          class="super font-color-red"
          >*</span
        >
        I confirm that this material is void of any personal identifiable
        information or privacy data. <br />
        <ErrorMessage name="personalInfo" class="error-feedback" />
      </div>
      <div class="form-group text-align-center mt-3">
        <button
          type="submit"
          class="btn btn-primary btn-block"
          :disabled="loading"
        >
          <span
            v-show="loading"
            class="spinner-border spinner-border-sm"
          ></span>
          <span>Upload</span>
        </button>
      </div>
    </Form>
  </Dialog>
</template>

<script setup lang="ts">
import { ref } from "vue";
import * as yup from "yup";
import { Form, Field, ErrorMessage } from "vee-validate";
import PractitionerSidebar from "@/components/PractitionerSidebar.vue";
import BackButton from "@/components/user/BackButton.vue";
import Dialog from "primevue/dialog";
import { checkIfFilesAreTooBig, showErrorToast, showToast } from "@/utils";
import UserService from "@/services/user.service.js";
import PractitionerService from "@/services/practitioner.service.js";
import { ResourceRequest } from "@/types";
import heic2any from "heic2any";
import { useConfigStore } from "@/stores/ConfigStore";

const ConfigStore = useConfigStore();
const resourceResponseFields = ConfigStore.config.filter(
  (el) => el.table === "resource_response"
);

let resourceRequests = ref([] as ResourceRequest[]);
let currResourceRequest = ref({} as ResourceRequest);
let files = ref([] as File[]);
let convertedFiles = ref([] as File[]);
let allowedFileType = ref("");
let message = ref("");
let visible = ref(false);
let loading = ref(false);
const currentPage = ref(1);
const totalPages = ref(1);

const viewResourceRequest = (pageNumber = 1) => {
  UserService.getResourcesRequest(pageNumber).then(
    (response) => {
      console.log(response.data);
      resourceRequests.value = response.data.data;
      totalPages.value = response.data.totalPages;
      currentPage.value = response.data.currentPage;
    },
    (error) => {
      let res =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(res);
    }
  );
};

let initialState = {
  linkResources: "",
  rightToUpload: "",
  personalInfo: "",
};

const schema = yup.object().shape({
  fields: yup.array().default(resourceResponseFields),
  linkResources: yup.string().when("personalInfo", {
    is: () => files && files.value.length > 0,
    then: (schema) => schema.optional().nullable(),
    otherwise: (schema) =>
      schema
        .required("Link to Resources or uploaded file is required!")
        .url("Link to Resources should be a valid hyperlink!"),
  }),
  file: yup.mixed().when("linkResources", {
    is: (val) => val && val.length > 0,
    then: (schema) => schema.optional(),
    otherwise: (schema) =>
      schema.required().test(
        "is-file-too-large",
        () => `File size exceeds the maximum allowable of 25MB!`,
        (value, testContext) =>
          checkIfFilesAreTooBig(value, testContext, files.value)
      ),
  }),
  rightToUpload: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "rightToUpload")?.disabled,
    then: (schema) =>
      schema
        .required("This is required to proceed!")
        .oneOf(["True"], "This is required to proceed!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  personalInfo: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "personalInfo")?.disabled,
    then: (schema) =>
      schema
        .required("This is required to proceed!")
        .oneOf(["True"], "This is required to proceed!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
});

const fileType = (resourceRequest) => {
  currResourceRequest.value = resourceRequest;
  if (currResourceRequest.value.type === "Video")
    allowedFileType.value =
      ".ogm, .wmv, .mpg, .webm, .ogv, .mov, .asx, .mpeg, .mp4, .m4v, .avi";
  else if (currResourceRequest.value.type === "Project Pictures")
    allowedFileType.value =
      ".xbm, .tif, .pjp, .apng, .jpg, .jpeg, .ico, .tiff, .gif, .svg, .jfif, .webp, .png, .bmp, .avif, .heic";
  else if (currResourceRequest.value.type === "Document")
    allowedFileType.value =
      ".pdf,.doc,.docx,application/msword,.txt,.ppt,.pptx,application/vnd.ms-powerpoint";
  files.value.length = 0;
  visible.value = true;
};

const convertImg = async (blob) => {
  return (async () => {
    const conversionResult = await heic2any({ blob });
    convertedFiles.value.push(
      new File(
        [conversionResult],
        blob.name.split(".").slice(0, -1).join(".") + "-conv.png"
      )
    );
  })();
};

const convertImages = async (files) => {
  for (let index = 0; index < files.length; index++) {
    if (!files[index].type.startsWith("image")) await convertImg(files[index]);
  }
  await console.log("done convertImages");
};

const uploadData = async (data, { resetForm }) => {
  data["files"] = files.value;
  data["convertedFiles"] = convertedFiles.value;
  data["resourceRequestId"] = currResourceRequest.value.id;
  PractitionerService.uploadResourceRequestResponse(data).then(
    () => {
      showToast("Content Uploaded Successfully!");
      loading.value = false;
      resetForm();
      viewResourceRequest();
      visible.value = false;
    },
    (error) => {
      console.log(error);
      message.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      loading.value = false;
      visible.value = false;
      showErrorToast("File size limit has been reached");
    }
  );
};

const onValidSubmit = async (data, { resetForm }) => {
  if (files && files.value.length > 0 && data["linkResources"].length > 0) {
    showErrorToast(
      "Please provide only one of the following : File or Hyperlink to resource."
    );
  } else {
    loading.value = true;
    if (currResourceRequest.value.type === "Project Pictures") {
      await convertImages(files.value);
    }
    await uploadData(data, { resetForm });
  }
};

const onInvalidSubmit = ({ errors }) => {
  Object.values(errors).forEach((error) => showErrorToast(error));
};

const onClick = (val) => {
  viewResourceRequest(currentPage.value);
};

viewResourceRequest();

console.log("Anubhav");
</script>

<style scoped>
.paginator {
  display: grid;
  justify-content: center;
  margin: 1rem 2rem;
}
@media screen and (max-width: 768px) {
  .card {
    padding: 0.5rem;
  }
}
</style>
