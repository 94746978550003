<template>
  <div class="header-space min-height">
    <div v-if="!successful" class="card card-container">
      <h4 class="text-align-center bold-text">User Registration</h4>
      <br />
      <span class="font-color-red">* required field</span>
      <Form
        @submit="handleRegister"
        v-slot="{ values }"
        :validation-schema="schema"
      >
        <div>
          <div class="form-group">
            <div class="form-check-inline">
              <label for="roles"
                >User Type<span class="super font-color-red">*</span></label
              >
              <Field
                name="roles"
                class="form-check-input"
                type="radio"
                value="Instructor"
              />
              <img
                src="@/assets/images/site/instructor.png"
                class="sidebar-image-xl ml-2 mr-1"
              />Instructor
            </div>
            <div class="form-check-inline lg:ml-3">
              <Field
                name="roles"
                class="form-check-input"
                type="radio"
                value="IndustryPractitioner"
              />
              <img
                src="@/assets/images/site/practitioner.png"
                class="sidebar-image-xl ml-2 mr-1"
              />Industry Practitioner
            </div>
            <ErrorMessage name="roles" class="error-feedback" />
          </div>

          <div class="form-group">
            <div
              v-if="values.roles === 'IndustryPractitioner'"
              class="form-group"
            >
              <label for="title"
                >Title<span class="super font-color-red">*</span></label
              >
              <Field
                v-model="values.title"
                name="title"
                type="text"
                class="dropdown"
                v-slot="{ field }"
              >
                <multiselect
                  v-bind="field"
                  v-model="values.title"
                  :options="practitionerTitleOptions"
                  mode="single"
                  :searchable="true"
                />
              </Field>
            </div>
            <div v-else>
              <label for="title"
                >Title<span class="super font-color-red">*</span></label
              >
              <Field
                v-model="values.title"
                name="title"
                type="text"
                class="dropdown"
                v-slot="{ field }"
              >
                <multiselect
                  v-bind="field"
                  v-model="values.title"
                  :options="instructorTitleOptions"
                  mode="single"
                  :searchable="true"
                />
              </Field>
            </div>
            <div v-if="values.title === 'Others'">
              <Field
                name="title_text"
                type="text"
                placeholder="Please Specify"
                class="form-control bg-others"
              />
            </div>
            <ErrorMessage name="title" class="error-feedback" />
          </div>

          <div class="form-group">
            <label for="firstName"
              >First Name<span class="super font-color-red">*</span></label
            >
            <Field name="firstName" type="text" class="form-control" />
            <ErrorMessage name="firstName" class="error-feedback" />
          </div>

          <div class="form-group">
            <label for="lastName"
              >Last Name<span class="super font-color-red">*</span></label
            >
            <Field name="lastName" type="text" class="form-control" />
            <ErrorMessage name="lastName" class="error-feedback" />
          </div>
          <div class="form-group">
            <label for="email"
              >Email<span class="super font-color-red">*</span>
              <span
                v-if="values.roles === 'IndustryPractitioner'"
                class="font-color-blue"
              >
                (Please use your work/company email address)
              </span>
              <span
                v-else-if="values.roles === 'Instructor'"
                class="font-color-blue"
              >
                (Please use your institutional email address)
              </span>
            </label>
            <Field
              name="email"
              type="email"
              class="form-control"
              @focusin.prevent="inFocus"
              @focusout.prevent="outFocus"
            />
            <ErrorMessage name="email" class="error-feedback" />
          </div>

          <div class="position-relative">
            <div class="registration-hint" v-show="emailToolTipVisible">
              <i></i>
              <span
                class="speech_bubble tool_tip_text"
                id="tooltip"
                style="display: inline"
              >
                <p style="margin-bottom: 0px">
                  You will be required to verify your email.
                </p>
              </span>
            </div>
          </div>

          <!--          <div class="form-group">-->
          <!--            <label for="password"-->
          <!--              >Password<span class="super font-color-red">*</span></label-->
          <!--            >-->
          <!--            <Field-->
          <!--              name="password"-->
          <!--              v-model="values.password"-->
          <!--              v-slot="{ field }"-->
          <!--              class="form-control"-->
          <!--            >-->
          <!--              <Password-->
          <!--                v-model="values.password"-->
          <!--                v-bind="field"-->
          <!--                toggleMask-->
          <!--                :feedback="false"-->
          <!--                class="w-100"-->
          <!--              />-->
          <!--            </Field>-->
          <!--            <ErrorMessage name="password" class="error-feedback" />-->
          <!--          </div>-->

          <!--          <div class="form-group">-->
          <!--            <label for="confirmPassword"-->
          <!--              >Confirm Password<span class="super font-color-red"-->
          <!--                >*</span-->
          <!--              ></label-->
          <!--            >-->
          <!--            <Field-->
          <!--              name="confirmPassword"-->
          <!--              v-model="values.confirmPassword"-->
          <!--              v-slot="{ field }"-->
          <!--              class="form-control"-->
          <!--            >-->
          <!--              <Password-->
          <!--                v-model="values.confirmPassword"-->
          <!--                v-bind="field"-->
          <!--                toggleMask-->
          <!--                :feedback="false"-->
          <!--                class="w-100"-->
          <!--              />-->
          <!--            </Field>-->
          <!--            <ErrorMessage name="confirmPassword" class="error-feedback" />-->
          <!--          </div>-->

          <div class="form-group">
            <label for="password"
              >Password<span class="super font-color-red">*</span></label
            >
            <Field
              name="password"
              v-model="values.password"
              v-slot="{ field }"
              class="form-control"
            >
              <Password
                v-model="values.password"
                v-bind="field"
                toggleMask
                :feedback="false"
                @focusin.prevent="inFocusPassword"
                @focusout.prevent="outFocusPassword"
                class="w-100"
              />
            </Field>
            <ErrorMessage name="password" class="error-feedback" />
          </div>

          <div class="position-relative">
            <div class="registration-hint" v-show="passwordToolTipVisible">
              <i></i>
              <span
                class="speech_bubble tool_tip_text"
                id="tooltip"
                style="display: inline"
              >
                <div style="margin-bottom: 0px">
                  Your password should be ATLEAST 8 characters long with a
                  minimum of:
                  <ul>
                    <li>1 number</li>
                    <li>1 uppercase letter</li>
                    <li>1 lowercase letter</li>
                  </ul>
                </div>
              </span>
            </div>
          </div>

          <div class="form-group">
            <label for="confirmPassword"
              >Confirm Password<span class="super font-color-red"
                >*</span
              ></label
            >
            <Field
              name="confirmPassword"
              v-model="values.confirmPassword"
              v-slot="{ field }"
              class="form-control"
            >
              <Password
                v-model="values.confirmPassword"
                v-bind="field"
                toggleMask
                :feedback="false"
                class="w-100"
              />
            </Field>
            <ErrorMessage name="confirmPassword" class="error-feedback" />
          </div>
          <br />
          <div style="font-size: small; text-align: center">
            By clicking Agree & Join, you agree to the ConPEC
            <router-link :to="{ name: 'user-agreement' }" target="_blank"
              >User Agreement</router-link
            >
            and
            <router-link :to="{ name: 'privacy-policy' }" target="_blank"
              >Privacy Policy</router-link
            >.
          </div>
          <br />

          <div class="form-group text-align-center">
            <button class="btn btn-primary btn-block" :disabled="loading">
              <span
                v-show="loading"
                class="spinner-border spinner-border-sm"
              ></span>
              Agree & Join
            </button>
          </div>
        </div>
      </Form>
      <div v-if="message" class="w-100 text-align-center">
        <br />
        <span
          class="alert"
          :class="successful ? 'alert-success' : 'alert-danger'"
        >
          {{ message }}
        </span>
      </div>
    </div>
    <div v-else class="text-align-center m-3 min-height">
      <div class="mt-3" style="font-size: 5rem">
        <img
          src="@/assets/images/site/mail-tick.png"
          class="site-mail-verification-image"
        />
      </div>
      <h2 class="mt-3" style="font-weight: 500">
        Verify your email to continue
      </h2>
      <div class="mt-5">
        Your security is our priority. <br />
        <div v-if="msgCode === 'REACTIVATE'" class="px-2 lg:px-8">
          You had a previous account associated with this email ID.<br />
          To reactivate your previous account, an email has been sent to your
          email address.<br />
          Kindly click on the verification link in the email message to verify
          your email ID and reactivate your account.
        </div>
        <div v-else>
          Please check your inbox at {{ message }} and verify your email to
          enjoy all the benefits of ConPec.
        </div>
        <span class="bold-text">
          Please if you cannot find the verification email in your inbox, please
          check your junk or spam folder</span
        ><br /><br />
        Didn't receive a verification email? <br />
        <button class="btn btn-outline-dark" @click="resendVerification">
          Resend Verification Email
        </button>
        <br /><br />
        Need Help?
        <router-link :to="{ name: 'contact-us' }">Contact Support </router-link>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import Multiselect from "@vueform/multiselect";
import Password from "primevue/password";
import * as yup from "yup";
import { useAuthStore } from "@/stores/AuthStore";
import { useRouter } from "vue-router";
import AuthService from "@/services/auth.service.js";
import {
  practitionerTitleOptions,
  instructorTitleOptions,
  showErrorToast,
  showToast,
} from "@/utils";
import { useConfigStore } from "@/stores/ConfigStore";

const ConfigStore = useConfigStore();
const AuthStore = useAuthStore();
const router = useRouter();

const registrationFields = ConfigStore.config.filter(
  (el) => el.table === "registration"
);

let loading = ref(false);
let successful = ref(false);
let message = ref("");
let msgCode = ref("");
let passwordToolTipVisible = ref(false);
let emailToolTipVisible = ref(false);

const schema = yup.object().shape({
  fields: yup.array().default(registrationFields),
  email: yup
    .string()
    .required("Email is required!")
    .email("Email is invalid!")
    .max(50, "Must be maximum 50 characters!"),
  password: yup
    .string()
    .required("Password is required!")
    .min(8, "Must be at least 8 characters!")
    .max(40, "Must be maximum 40 characters!")
    .matches(
      /^.*(?=.{8,})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      "Password must contain at least 8 characters, one uppercase, and one number"
    ),
  // ethnicity: yup.string().required("Ethnicity is required!"),
  roles: yup.string().required("User Type is required!"),
  title: yup.string().required("Title is required!"),
  title_text: yup.string().when("title", {
    is: (val) => val === "Others",
    then: (schema) => schema.required("Title is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  firstName: yup.string().required("First Name is required!"),
  lastName: yup.string().required("Last Name is required!"),
  confirmPassword: yup
    .string()
    .required("Confirm your password!")
    .oneOf([yup.ref("password")], "Those passwords didn’t match. Try again."),
});

const resendVerification = () => {
  loading.value = true;
  AuthService.resendVerification(message.value).then(
    (res) => {
      showToast(
        "Verification Mail Sent successfully. Please check your inbox."
      );
      loading.value = false;
    },
    (error) => {
      message.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      showErrorToast(message.value);
      loading.value = false;
    }
  );
};

const handleRegister = (user) => {
  loading.value = true;
  if (user.title === "Others") user.title = user["title_text"];

  AuthService.register(user).then(
    (res) => {
      console.log(res);
      message.value = res.data.email;
      msgCode.value = res.data.code;
      successful.value = true;
      loading.value = false;
    },
    (error) => {
      message.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      successful.value = false;
      loading.value = false;
    }
  );
};

const inFocus = () => {
  emailToolTipVisible.value = true;
};

const outFocus = () => {
  emailToolTipVisible.value = false;
};

const inFocusPassword = () => {
  passwordToolTipVisible.value = true;
};

const outFocusPassword = () => {
  passwordToolTipVisible.value = false;
};
</script>

<style scoped>
.card-container.card {
  width: 50vw !important;
  padding: 40px 20px;
}

.error-feedback {
  color: red;
}

.registration-hint {
  position: absolute;
  left: -60px;
  top: 0.8rem;
  z-index: 400;
  opacity: 1;
  display: inline-block;
  font-size: 16px;
  font-family: "HelveticaNeueW01", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-style: normal;
  color: #336;
}

.registration-hint .speech_bubble {
  padding: 10px;
  width: 20vw;
  background-color: #be4b49;
  border: 0;
  border-radius: 2px;
  box-shadow: 0 0 0 1px rgba(139, 3, 0, 0.75), 0 1px 10px rgba(0, 0, 0, 0.35);
  color: #fff;
  text-transform: none;
  box-sizing: border-box;
  display: none;
  position: absolute;
  z-index: 999;
  bottom: calc(100% + 10px);
  right: -40px;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  line-height: 1.2;
  white-space: normal;
  word-break: break-word;
}

.registration-hint .speech_bubble span {
  display: block;
  font-size: 14px;
}

.registration-hint i {
  background-image: url("@/assets/images/site/icons.png");
  background-repeat: no-repeat;
  background-size: auto;
  background-position: -12px -88px;
  height: 22px;
  width: 11px;
  position: absolute;
  right: -50px;
  top: -45px;
}

.site-mail-verification-image {
  height: 5rem;
  width: 5rem;
  display: inline-block;
}

@media only screen and (max-width: 768px) {
  .card-container.card {
    width: 90% !important;
    padding: 10px 10px;
  }
}
</style>
