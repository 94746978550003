<template>
  <form @keydown.enter="$event.preventDefault()" class="mt-3 lg:mx-3">
    <div class="card card-container">
      <div class="bold-text ml-3 text-align-center">
        Please indicate the types of course-supports you are willing to provide
      </div>
      <div class="align-center mt-3">
        <div
          class="form-group"
          v-if="
            !availabilityFields.find((el) => el.name === 'courseRequestType')
              ?.disabled
          "
        >
          <label for="courseRequestType"
            >Course-Support Type<span class="super font-color-red"
              >*</span
            ></label
          >
          <div
            title="Seminar is for sharing knowledge, and facilitating discussions on a specific topic or subject. It can also be an avenue to present their ideas and experiences to students."
          >
            <Field
              name="Seminar"
              value="True"
              unchecked-value="False"
              type="checkbox"
              @change="checkBoxClicked"
            />&nbsp;Seminar
          </div>
          <div
            title="Site visit is a means to expose students to the workplace and work practices on construction job sites."
          >
            <Field
              value="True"
              unchecked-value="False"
              name="Site Visit"
              type="checkbox"
              @change="checkBoxClicked"
            />&nbsp;Site Visit
          </div>
          <div
            title="Workshop is an interactive learning and skill-building session to provide students with hands-on experience and practical training in a specific area or to develop certain skills."
          >
            <Field
              value="True"
              unchecked-value="False"
              name="Workshop"
              type="checkbox"
              @change="checkBoxClicked"
            />&nbsp;Workshop
          </div>
          <div
            title="Guest lecture entails bringing practitioners into the classroom to discuss technical topics from their experience."
          >
            <Field
              value="True"
              unchecked-value="False"
              name="Guest Lecture"
              type="checkbox"
              @change="checkBoxClicked"
            /><span>&nbsp;Guest Lecture</span>
          </div>
          <div
            title="This involve assessing and appraising students’ projects based on established guidelines, client expectations, and"
          >
            <Field
              value="True"
              unchecked-value="False"
              name="Judge for Project"
              type="checkbox"
              @change="checkBoxClicked"
            />&nbsp;Judge for Project
          </div>
          <div
            title="This entails providing professional guidance to students throughout the delivery of their project."
          >
            <Field
              value="True"
              unchecked-value="False"
              name="Mentor for Project"
              type="checkbox"
              @change="checkBoxClicked"
            />&nbsp;Mentor for Project
          </div>
          <div
            title="This entails providing context for students’ project and acting as client."
          >
            <Field
              value="True"
              unchecked-value="False"
              name="Sponsor for Project"
              type="checkbox"
              @change="checkBoxClicked"
            />&nbsp;Sponsor for Project
          </div>
          <div
            title="Laboratory session is a practical, hands-on learning experience that takes place in a laboratory where students get the opportunity to apply theoretical concepts to real-world construction tasks."
          >
            <Field
              value="True"
              unchecked-value="False"
              name="Labs"
              type="checkbox"
              @change="checkBoxClicked"
            />&nbsp;Labs
          </div>
          <ErrorMessage
            name="courseRequestType"
            v-if="meta.dirty"
            class="error-feedback"
          />
        </div>
      </div>
      <va-divider horizontal />
      <div
        class="form-group"
        v-if="
          !availabilityFields.find((el) => el.name === 'subjectSpeak')?.disabled
        "
      >
        <label for="subjectSpeak"
          >Subject Areas You Are Willing To Speak On<span
            class="super font-color-red"
            >*</span
          >
        </label>

        <Field
          v-model="values.subjectSpeak"
          name="subjectSpeak"
          type="text"
          class="form-control"
          v-slot="{ field }"
        >
          <Multiselect
            v-bind="field"
            v-model="values.subjectSpeak"
            :options="subjectWillingToSpeakOnOptionsAdded"
            mode="tags"
            :searchable="true"
            :multipleLabel="displayLabels"
            :hideSelected="false"
            :closeOnSelect="false"
            :closeOnDeselect="false"
          >
            <template v-slot:option="{ option }">
              <input
                type="checkbox"
                class="input-pointer"
                :checked="values.subjectSpeak?.includes(option.value)"
              />
              &nbsp; {{ option.label }}
            </template>
          </Multiselect>
        </Field>
        <ErrorMessage
          name="subjectSpeak"
          v-if="meta.dirty"
          class="error-feedback"
        />
        <div v-if="values.subjectSpeak?.includes('Others')" class="mt-2">
          <div class="flex flex-direction-row justify-space-between">
            <Field
              name="subjectSpeak-text"
              type="text"
              placeholder="One entry per input, but multiple input is allowed"
              aria-placeholder="One entry per input, but multiple input is allowed"
              class="form-control"
              v-model="subjectSpeak_text"
              v-on:keyup.enter.prevent="addSubjectSpeak"
            />
            <div
              class="ml-2 cursor-pointer d-inline flex-shrink-1 flex-grow-0 btn btn-primary w-6rem"
              style="height: 2.25rem"
              @click="addSubjectSpeak"
            >
              <font-awesome-icon
                style="margin: auto"
                icon="fa-solid fa-plus"
                title="Add Learning Objective/Expected Student Outcomes"
              />&nbsp;
              <span>Add</span>
            </div>
          </div>
          <div
            style="display: flex; flex-wrap: wrap"
            v-if="values.subjectSpeakOthers != null"
          >
            <span
              v-for="item in values.subjectSpeakOthers"
              :key="item"
              class="list-item"
            >
              {{ item.trim() }}
              <span class="list-item-remove"
                ><span
                  class="list-item-remove-icon"
                  @click="removeVal(item)"
                ></span
              ></span>
            </span>
          </div>
        </div>
        <ErrorMessage
          name="subjectSpeakOthers"
          v-if="meta.dirty"
          class="error-feedback"
        />
      </div>
    </div>
  </form>
  <div class="text-align-center mb-3">
    <button
      class="btn btn-primary ml-2"
      type="button"
      @click="saveCourseSelection"
    >
      Next
    </button>
  </div>
  <va-modal v-model="showSiteVisitModal" hide-default-actions>
    <h3 class="va-h3"></h3>
    <p>
      Please do not share confidential information about your construction
      project(s)
    </p>
    <br />
    <div style="text-align: center">
      <va-button @click="showSiteVisitModal = false"> Accept </va-button>
    </div>
  </va-modal>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { useForm, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import { useConfigStore } from "@/stores/ConfigStore";
import { useAvailabilityStore } from "@/stores/AvailabilityStore";
import { onBeforeRouteLeave } from "vue-router";
import Multiselect from "@vueform/multiselect";
import { useModal } from "vuestic-ui";
import {
  subjectWillingToSpeakOnOptions,
  showErrorToast,
  displayLabels,
} from "@/utils";
import { Availability } from "@/types";

const AvailabilityStore = useAvailabilityStore();
let subjectSpeak_text = ref("");
let showSiteVisitModal = ref(false);
// eslint-disable-next-line no-undef
const props = defineProps<{
  availability: Availability;
}>();

// eslint-disable-next-line no-undef
const emits = defineEmits(["siteVisitSelected", "nextStep"]);
const { confirm } = useModal();
const ConfigStore = useConfigStore();

const subjectWillingToSpeakOnOptionsAdded = [
  ...subjectWillingToSpeakOnOptions,
  { label: "Others, please specify", value: "Others" },
];
const availabilityFields = ConfigStore.config.filter(
  (el) => el.table === "availability"
);

const schema = yup.object().shape({
  fields: yup.array().default(availabilityFields),
  courseRequestType: yup
    .array()
    .of(yup.string())
    .when("fields", {
      is: (val) => !val.find((el) => el.name === "courseRequestType")?.disabled,
      then: (schema) =>
        schema
          .required("Please select one of available Course Support Type!")
          .min(1, "Please select one of available Course Support Type!"),
      otherwise: (schema) => schema.optional().nullable(),
    }),
  subjectSpeak: yup
    .array()
    .of(yup.string())
    .when("fields", {
      is: (val) => !val.find((el) => el.name === "subjectSpeak")?.disabled,
      then: (schema) =>
        schema
          .required("Subject areas you are willing to speak on is required!")
          .min(1, "Subject areas you are willing to speak on is required!"),
      otherwise: (schema) => schema.optional().nullable(),
    }),
  subjectSpeakOthers: yup
    .array()
    .of(yup.string())
    .when("subjectSpeak", {
      is: (val) => val.includes("Others"),
      then: (schema) =>
        schema
          .required("Subject areas you are willing to speak on is required!")
          .min(1, "Subject areas you are willing to speak on is required!"),
      otherwise: (schema) => schema.optional().min(0),
    }),
});

const removeVal = async (val) => {
  values.subjectSpeakOthers?.splice(values.subjectSpeak.indexOf(val), 1);
  const { valid, errors } = await validate();
};
const addSubjectSpeak = () => {
  if (
    !values.subjectSpeakOthers?.includes(subjectSpeak_text.value) &&
    subjectSpeak_text.value.length > 0
  )
    values.subjectSpeakOthers?.push(subjectSpeak_text.value);
  subjectSpeak_text.value = "";
};
const checkBoxClicked = (e) => {
  let clickedCourseType = e.target.name;
  if (values[clickedCourseType] === "True") {
    values.courseRequestType.push(clickedCourseType);
    if (clickedCourseType === "Site Visit") {
      emits("siteVisitSelected", true);
      showSiteVisitModal.value = true;
    }
  } else if (values[clickedCourseType] === "False") {
    values.courseRequestType.splice(
      values.courseRequestType.indexOf(clickedCourseType),
      1
    );
    if (clickedCourseType === "Site Visit") {
      emits("siteVisitSelected", false);
    }
  }
};

let initialState = {
  "Guest Lecture": "False",
  Seminar: "False",
  "Mentor for Project": "False",
  "Sponsor for Project": "False",
  "Judge for Project": "False",
  "Site Visit": "False",
  Workshop: "False",
  Labs: "False",
  courseRequestType: [] as string[],
  subjectSpeak: [] as string[],
  subjectSpeakOthers: [] as string[],
};

if (AvailabilityStore.status) {
  initialState["Guest Lecture"] = props.availability.courseRequestType.includes(
    "Guest Lecture"
  )
    ? "True"
    : "False";
  initialState["Seminar"] = props.availability.courseRequestType.includes(
    "Seminar"
  )
    ? "True"
    : "False";
  initialState["Judge for Project"] =
    props.availability.courseRequestType.includes("Judge for Project")
      ? "True"
      : "False";
  initialState["Mentor for Project"] =
    props.availability.courseRequestType.includes("Mentor for Project")
      ? "True"
      : "False";
  initialState["Sponsor for Project"] =
    props.availability.courseRequestType.includes("Sponsor for Project")
      ? "True"
      : "False";
  initialState["Site Visit"] = props.availability.courseRequestType.includes(
    "Site Visit"
  )
    ? "True"
    : "False";
  initialState["Workshop"] = props.availability.courseRequestType.includes(
    "Workshop"
  )
    ? "True"
    : "False";
  initialState["Labs"] = props.availability.courseRequestType.includes("Labs")
    ? "True"
    : "False";
}

let formValues = {
  "Guest Lecture": initialState["Guest Lecture"],
  Seminar: initialState.Seminar,
  "Mentor for Project": initialState["Mentor for Project"],
  "Sponsor for Project": initialState["Sponsor for Project"],
  "Judge for Project": initialState["Judge for Project"],
  "Site Visit": initialState["Site Visit"],
  Workshop: initialState.Workshop,
  Labs: initialState.Labs,
  courseRequestType: props.availability?.courseRequestType || [],
  subjectSpeak: props.availability?.subjectSpeak || [],
  subjectSpeakOthers: props.availability?.subjectSpeakOthers || [],
};

const { handleSubmit, errors, values, meta, validate, resetForm } = useForm({
  validationSchema: schema,
});

resetForm({
  values: formValues,
});

const saveCourseSelection = handleSubmit(
  (data) => {
    console.log(data);
    emits("nextStep", data);
  },
  (errors) => {
    Object.values(errors.errors).forEach((error) => showErrorToast(error));
  }
);

onBeforeRouteLeave(async (to, from) => {
  if (meta.value.dirty) {
    const answer = await confirm({
      message:
        "Your changes on this page have not been saved. Kindly save your changes before leaving the page. Do you want to proceed without saving your changes?",
      okText: "Yes",
      cancelText: "No",
    });
    if (!answer) return false;
  }
});
</script>

<style scoped>
.card {
  margin-top: 0;
}
.card-container.card {
  max-width: 70vw !important;
  padding: 40px 40px;
}

@media only screen and (max-width: 1440px) {
  .card-container.card {
    max-width: 75vw !important;
    padding: 20px 20px;
  }
}

@media only screen and (max-width: 1366px) {
  .card-container.card {
    max-width: 85vw !important;
    padding: 10px 10px;
  }
}

@media screen and (max-width: 1024px) {
  .card-container.card {
    max-width: 85vw !important;
    padding: 10px 10px;
  }
}
</style>
