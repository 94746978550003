import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "table" }

import { User } from "@/types";

// eslint-disable-next-line no-undef

export default /*@__PURE__*/_defineComponent({
  __name: 'UserTable',
  props: {
    user: {}
  },
  setup(__props: any) {

const props = __props;

console.log(props.user);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("table", _hoisted_1, [
    _createElementVNode("tr", null, [
      _cache[0] || (_cache[0] = _createElementVNode("td", null, "First Name", -1)),
      _createElementVNode("td", null, _toDisplayString(_ctx.user.firstName), 1)
    ]),
    _createElementVNode("tr", null, [
      _cache[1] || (_cache[1] = _createElementVNode("td", null, "Last Name", -1)),
      _createElementVNode("td", null, _toDisplayString(_ctx.user.lastName), 1)
    ]),
    _createElementVNode("tr", null, [
      _cache[2] || (_cache[2] = _createElementVNode("td", null, "Email", -1)),
      _createElementVNode("td", null, _toDisplayString(_ctx.user.email), 1)
    ]),
    _createElementVNode("tr", null, [
      _cache[3] || (_cache[3] = _createElementVNode("td", null, "Name of Organization", -1)),
      _createElementVNode("td", null, _toDisplayString(_ctx.user.nameOrg), 1)
    ]),
    _createElementVNode("tr", null, [
      _cache[4] || (_cache[4] = _createElementVNode("td", null, "Title", -1)),
      _createElementVNode("td", null, _toDisplayString(_ctx.user.title), 1)
    ]),
    _createElementVNode("tr", null, [
      _cache[5] || (_cache[5] = _createElementVNode("td", null, "Gender Identity", -1)),
      _createElementVNode("td", null, _toDisplayString(_ctx.user.gender), 1)
    ]),
    _createElementVNode("tr", null, [
      _cache[6] || (_cache[6] = _createElementVNode("td", null, "Ethnicity", -1)),
      _createElementVNode("td", null, _toDisplayString(_ctx.user.ethnicity), 1)
    ]),
    _createElementVNode("tr", null, [
      _cache[7] || (_cache[7] = _createElementVNode("td", null, "Phone", -1)),
      _createElementVNode("td", null, _toDisplayString(_ctx.user.phone), 1)
    ]),
    _createElementVNode("tr", null, [
      _cache[8] || (_cache[8] = _createElementVNode("td", null, "Phone Number", -1)),
      _createElementVNode("td", null, _toDisplayString(_ctx.user.phoneNumber), 1)
    ]),
    _createElementVNode("tr", null, [
      _cache[9] || (_cache[9] = _createElementVNode("td", null, "Account Creation Date", -1)),
      _createElementVNode("td", null, _toDisplayString(new Date(_ctx.user.createdAt).toLocaleString()), 1)
    ]),
    _createElementVNode("tr", null, [
      _cache[10] || (_cache[10] = _createElementVNode("td", null, "Account Active", -1)),
      _createElementVNode("td", null, _toDisplayString(_ctx.user.status), 1)
    ]),
    _createElementVNode("tr", null, [
      _cache[11] || (_cache[11] = _createElementVNode("td", null, "Reason Delete", -1)),
      _createElementVNode("td", null, _toDisplayString(_ctx.user.reasonDelete), 1)
    ])
  ]))
}
}

})