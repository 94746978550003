<template>
  <div class="grid-container">
    <instructor-sidebar></instructor-sidebar>
    <div class="m-3 min-height">
      <div class="back-grid">
        <back-button></back-button>
        <h2 style="text-align: center; font-weight: bold">View Resources</h2>
      </div>
      <h5 class="text-align-center pl-3 pr-3 sm:pl-5 sm:pr-5 lg:pl-8 lg:pr-8">
        You can download images and pictures on this page to support your
        instructional materials (for academic purposes only).
      </h5>
      <div>
        <resources-list type="public"></resources-list>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import ResourcesList from "@/components/user/ResourcesListTabs.vue";
import InstructorSidebar from "@/components/instructor/InstructorSidebar.vue";
import BackButton from "@/components/user/BackButton.vue";

let message = ref([]);
</script>

<style scoped>
ul > li {
  display: inline-block;
  margin: 1em;
  /* You can also add some margins here to make it look prettier */
}
</style>
