<template>
  <div></div>
</template>

<!--<script setup lang="ts">-->
<!--import { ref, watch } from "vue";-->
<!--import { Form, Field, ErrorMessage } from "vee-validate";-->
<!--import AppSidebar from "@/components/user/AppSidebar.vue";-->
<!--import BackButton from "@/components/user/BackButton.vue";-->
<!--import Dialog from "primevue/dialog";-->

<!--import { useAuthStore } from "@/stores/AuthStore";-->
<!--import { useCourseStore } from "@/stores/CourseStore";-->
<!--import PractitionerService from "@/services/practitioner.service.js";-->
<!--import {-->
<!--  showToast,-->
<!--  showVisualRep,-->
<!--  showClassroomItems,-->
<!--  showPriorKnowledge,-->
<!--  showAssignmentConnected,-->
<!--  isCapstoneTermProject,-->
<!--} from "@/utils";-->
<!--import { useRoute, useRouter } from "vue-router";-->
<!--import * as yup from "yup";-->
<!--const route = useRoute();-->
<!--const router = useRouter();-->

<!--const AuthStore = useAuthStore();-->
<!--const CourseStore = useCourseStore();-->

<!--const currentUser = AuthStore.auth;-->
<!--let course = CourseStore.course;-->

<!--let loading = ref(false);-->
<!--let successful = false;-->
<!--let message = ref("");-->
<!--let currStatus = ref("");-->
<!--let visible = ref(false);-->

<!--const selectCourse = (course) => {-->
<!--  PractitionerService.selectCourse(course).then(-->
<!--    (response) => {-->
<!--      loading.value = false;-->
<!--      visible.value = true;-->
<!--      showToast("Message sent successfully to instructor.");-->
<!--      router.push({ name: "home" });-->
<!--    },-->
<!--    (error) => {-->
<!--      console.log("Error");-->
<!--      message.value =-->
<!--        (error.response &&-->
<!--          error.response.data &&-->
<!--          error.response.data.message) ||-->
<!--        error.message ||-->
<!--        error.toString();-->
<!--    }-->
<!--  );-->
<!--};-->

<!--const schema = yup.object().shape({-->
<!--  status: yup.string().optional(),-->
<!--  reason: yup.string().when("status", {-->
<!--    is: () => currStatus.value === "90",-->
<!--    then: (schema) => schema.required("Reason is required!"),-->
<!--    otherwise: (schema) => schema.optional(),-->
<!--  }),-->
<!--});-->

<!--const getHeader = () => {-->
<!--  if (currStatus.value === "60") {-->
<!--    return "Provide Course-Support Request";-->
<!--  } else if (currStatus.value === "90") {-->
<!--    return "Decline Course-Support Request";-->
<!--  } else if (currStatus.value === "10") {-->
<!--    return "Provide Course-Support Request";-->
<!--  }-->
<!--};-->

<!--const getCustomMsg = () => {-->
<!--  if (currentUser.phone === "True") {-->
<!--    return `I, ${currentUser.firstName} ${currentUser.lastName} is willing to provide the course-support (${course.courseRequestType}) you requested.\nYou can reach me at ${currentUser.email} or ${currentUser.phoneNumber}.`;-->
<!--  } else {-->
<!--    return `I, ${currentUser.firstName} ${currentUser.lastName} is willing to provide the course-support (${course.courseRequestType}) you requested.\nYou can reach me at ${currentUser.email}.`;-->
<!--  }-->
<!--};-->
<!--const respondCourse = (status) => {-->
<!--  currStatus.value = status;-->
<!--  visible.value = true;-->
<!--};-->

<!--const handleSubmission = (data) => {-->
<!--  loading.value = true;-->
<!--  course["reason"] = data.reason;-->
<!--  if (currStatus.value === "10") {-->
<!--    selectCourse(course);-->
<!--  } else {-->
<!--    if (currStatus.value === "60") {-->
<!--      course["selectedDateIndex"] = data.courseDateTime;-->
<!--      course["selectedDate"] = new Date(-->
<!--        JSON.parse(course.date)[data.courseDateTime] +-->
<!--          "T" +-->
<!--          JSON.parse(course.time)[data.courseDateTime]-->
<!--      );-->
<!--    }-->
<!--    PractitionerService.respondCourseReq(course, currStatus.value).then(-->
<!--      (response) => {-->
<!--        loading.value = false;-->
<!--        visible.value = true;-->
<!--        showToast("Message sent successfully to instructor.");-->
<!--        router.push({ name: "home" });-->
<!--      },-->
<!--      (error) => {-->
<!--        console.log("Error");-->
<!--        loading.value = false;-->
<!--        message.value =-->
<!--          (error.response &&-->
<!--            error.response.data &&-->
<!--            error.response.data.message) ||-->
<!--          error.message ||-->
<!--          error.toString();-->
<!--      }-->
<!--    );-->
<!--  }-->
<!--};-->

<!--watch(-->
<!--  () => route.params.id,-->
<!--  (courseId) => {-->
<!--    console.log(courseId);-->
<!--  },-->
<!--  { immediate: true }-->
<!--);-->
<!--</script>-->

<!--<style scoped>-->
<!--table {-->
<!--  width: fit-content;-->
<!--  box-shadow: 0px 0px 10px 4px rgb(0 0 0 / 30%);-->
<!--  border-radius: 6px;-->
<!--  background: #fafafa;-->
<!--}-->

<!--thead td {-->
<!--  text-align: center;-->
<!--}-->

<!--td {-->
<!--  border: 2px solid #f0f0f0;-->
<!--  border-collapse: collapse;-->
<!--  padding: 0.2em 4em;-->
<!--  font-size: larger;-->
<!--}-->
<!--</style>-->
