import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, createTextVNode as _createTextVNode, unref as _unref, isRef as _isRef, withCtx as _withCtx } from "vue"
import _imports_0 from '@/assets/images/feedback.png'
import _imports_1 from '@/assets/images/info.png'


const _hoisted_1 = { class: "grid-container" }
const _hoisted_2 = { style: {"min-height":"calc(100vh - 10em)"} }
const _hoisted_3 = { class: "mt-3" }
const _hoisted_4 = { class: "flex flex-column lg:flex-row" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { class: "content-card" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = {
  role: "button",
  class: "hint"
}
const _hoisted_9 = ["onClick"]
const _hoisted_10 = {
  class: "speech_bubble tool_tip_text",
  id: "tooltip",
  style: {"display":"inline"}
}
const _hoisted_11 = {
  type: "button",
  class: "close-btn-tooltip mr-4"
}
const _hoisted_12 = {
  key: 0,
  style: {"margin-bottom":"0px"}
}
const _hoisted_13 = {
  key: 1,
  style: {"margin-bottom":"0px"}
}
const _hoisted_14 = { key: 0 }
const _hoisted_15 = { class: "button-container flex-shrink-0" }
const _hoisted_16 = {
  key: 0,
  class: "p-2"
}
const _hoisted_17 = ["onClick"]
const _hoisted_18 = { key: 1 }
const _hoisted_19 = { class: "p-2" }
const _hoisted_20 = ["onClick"]
const _hoisted_21 = { class: "paginator" }
const _hoisted_22 = {
  key: 1,
  style: {"font-style":"italic"}
}
const _hoisted_23 = { class: "mt-3 lg:mt-0" }
const _hoisted_24 = { key: 0 }
const _hoisted_25 = { class: "content-card" }
const _hoisted_26 = { key: 0 }
const _hoisted_27 = {
  role: "button",
  class: "hint"
}
const _hoisted_28 = ["onClick"]
const _hoisted_29 = {
  class: "speech_bubble tool_tip_text",
  id: "tooltip",
  style: {"display":"inline"}
}
const _hoisted_30 = {
  type: "button",
  class: "close-btn-tooltip mr-4"
}
const _hoisted_31 = {
  key: 1,
  class: "hint"
}
const _hoisted_32 = {
  role: "button",
  class: "hint"
}
const _hoisted_33 = {
  class: "speech_bubble tool_tip_text",
  id: "tooltip",
  style: {"display":"inline"}
}
const _hoisted_34 = {
  type: "button",
  class: "close-btn-tooltip mr-4"
}
const _hoisted_35 = { key: 0 }
const _hoisted_36 = { class: "button-container flex-shrink-0" }
const _hoisted_37 = { class: "p-2" }
const _hoisted_38 = ["onClick"]
const _hoisted_39 = { class: "paginator" }
const _hoisted_40 = {
  key: 1,
  style: {"font-style":"italic"}
}

import { ref } from "vue";
import PractitionerService from "@/services/practitioner.service.js";
import Dialog from "primevue/dialog";
import FeedbackFrom from "@/components/user/FeedbackForm.vue";
import PractitionerSidebar from "@/components/PractitionerSidebar.vue";
import { formatTime, showToast } from "@/utils";
import { useRouter } from "vue-router";
import { useCourseStore } from "@/stores/CourseStore";
import { CourseRequest } from "@/types";


export default /*@__PURE__*/_defineComponent({
  __name: 'PastCourseRequestView',
  setup(__props) {

const router = useRouter();
const CourseStore = useCourseStore();

const acceptedCourse = ref([]);
const acceptedCurrentPage = ref(1);
const acceptedTotalPages = ref(1);
const declinedCourse = ref([]);
const declinedCurrentPage = ref(1);
const declinedTotalPages = ref(1);
const elementVisible = ref({});
let currCourse = ref({} as CourseRequest);
let feedbackVisible = ref(false);
let tooltipTimeout;

const viewCourseSupport = (type, pageNumber = 1) => {
  PractitionerService.getPractitionerBoard(pageNumber, type).then(
    (response) => {
      if (type === "accepted") {
        console.log(response.data);
        acceptedCourse.value = response.data.courses;
        acceptedTotalPages.value = response.data.totalPages;
        acceptedCurrentPage.value = response.data.currentPage;
      } else {
        declinedCourse.value = response.data.courses;
        declinedTotalPages.value = response.data.totalPages;
        declinedCurrentPage.value = response.data.currentPage;
      }
      response.data.courses.forEach(
        (el) => (elementVisible.value[el.id] = false)
      );
    },
    (error) => {
      let res =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(res);
    }
  );
};

const viewCourseRequest = (course) => {
  CourseStore.addCourse(course);
  router.push({ name: "view-course-request-prac" });
};

const fetchCourse = () => {
  feedbackVisible.value = false;
  showToast("Your feedback has been successfully saved.");
  viewCourseSupport("accepted", acceptedCurrentPage.value);
};

const acceptedOnClick = () => {
  viewCourseSupport("accepted", acceptedCurrentPage.value);
};
const declinedOnClick = () => {
  viewCourseSupport("declined", declinedCurrentPage.value);
};

viewCourseSupport("accepted");
viewCourseSupport("declined");

const showTooltip = (index) => {
  elementVisible.value[index] = true;
  tooltipTimeout = setTimeout(
    () => (elementVisible.value[index] = false),
    5000
  );
};
const hideTooltip = (index) => {
  elementVisible.value[index] = false;
  clearTimeout(tooltipTimeout);
};

return (_ctx: any,_cache: any) => {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_va_pagination = _resolveComponent("va-pagination")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(PractitionerSidebar),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", null, [
              _cache[12] || (_cache[12] = _createElementVNode("span", { class: "bold-text" }, " Course-Support Requests Accepted ", -1)),
              (acceptedCourse.value.length > 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    _createElementVNode("ul", null, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(acceptedCourse.value, (course) => {
                        return (_openBlock(), _createElementBlock("li", {
                          key: course.id
                        }, [
                          _createElementVNode("div", _hoisted_6, [
                            _createElementVNode("div", null, [
                              _createTextVNode(" Course-Support Type: " + _toDisplayString(course.courseRequestType) + " ", 1),
                              (course.status == '60' || course.status == '10')
                                ? (_openBlock(), _createElementBlock("span", _hoisted_7, [
                                    _cache[4] || (_cache[4] = _createTextVNode("   ")),
                                    _createElementVNode("span", _hoisted_8, [
                                      _createElementVNode("span", {
                                        onClick: ($event: any) => (showTooltip(course.id))
                                      }, [
                                        _createVNode(_component_font_awesome_icon, {
                                          icon: "fa-solid fa-check",
                                          style: {"color":"#0bda51","font-size":"x-large"}
                                        }),
                                        _cache[3] || (_cache[3] = _createElementVNode("span", { style: {"font-size":"smaller"} }, "Accepted", -1))
                                      ], 8, _hoisted_9),
                                      _withDirectives(_createElementVNode("span", _hoisted_10, [
                                        _createElementVNode("button", _hoisted_11, [
                                          _createVNode(_component_font_awesome_icon, {
                                            icon: "fa-solid fa-xmark",
                                            class: "close-btn-icon",
                                            onClick: ($event: any) => (hideTooltip(course.id))
                                          }, null, 8, ["onClick"])
                                        ]),
                                        (course.status === '60')
                                          ? (_openBlock(), _createElementBlock("p", _hoisted_12, " You accepted to provide this request. "))
                                          : _createCommentVNode("", true),
                                        (course.status === '10')
                                          ? (_openBlock(), _createElementBlock("p", _hoisted_13, " You responded to this request. "))
                                          : _createCommentVNode("", true)
                                      ], 512), [
                                        [_vShow, elementVisible.value[course.id]]
                                      ])
                                    ])
                                  ]))
                                : _createCommentVNode("", true),
                              _cache[5] || (_cache[5] = _createElementVNode("br", null, null, -1)),
                              _cache[6] || (_cache[6] = _createTextVNode(" Course Requested For : ")),
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(JSON.parse(course.date), (item, index) => {
                                return (_openBlock(), _createElementBlock("span", { key: index }, [
                                  _createTextVNode(_toDisplayString(item) + "@" + _toDisplayString(_unref(formatTime)(JSON.parse(course.time)[index])) + " ", 1),
                                  (index != JSON.parse(course.date).length - 1)
                                    ? (_openBlock(), _createElementBlock("span", _hoisted_14, " OR "))
                                    : _createCommentVNode("", true)
                                ]))
                              }), 128)),
                              _cache[7] || (_cache[7] = _createElementVNode("br", null, null, -1)),
                              _createTextVNode(" Professor : " + _toDisplayString(course.user.title +
                        " " +
                        course.user.firstName +
                        " " +
                        course.user.lastName) + " ", 1),
                              _cache[8] || (_cache[8] = _createElementVNode("br", null, null, -1)),
                              _createTextVNode(" Institute: " + _toDisplayString(course.user.nameOrg), 1)
                            ]),
                            _createElementVNode("div", _hoisted_15, [
                              (course['course_request_feedbacks'].length == 0)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                                    _createElementVNode("button", {
                                      class: "btn btn-danger bg-red-500 border-none",
                                      onClick: ($event: any) => (
                            (_isRef(currCourse) //@ts-ignore
 ? currCourse.value = course : currCourse = course), (_isRef(feedbackVisible) //@ts-ignore
 ? feedbackVisible.value = true : feedbackVisible = true)
                          ),
                                      title: "Give Feedback"
                                    }, _cache[9] || (_cache[9] = [
                                      _createElementVNode("img", {
                                        src: _imports_0,
                                        class: "sidebar-image"
                                      }, null, -1)
                                    ]), 8, _hoisted_17)
                                  ]))
                                : (_openBlock(), _createElementBlock("div", _hoisted_18)),
                              _createElementVNode("div", _hoisted_19, [
                                _createElementVNode("button", {
                                  class: "btn btn-primary bg-green-400 border-none",
                                  onClick: ($event: any) => (viewCourseRequest(course)),
                                  title: "View Details"
                                }, _cache[10] || (_cache[10] = [
                                  _createElementVNode("img", {
                                    src: _imports_1,
                                    class: "sidebar-image"
                                  }, null, -1)
                                ]), 8, _hoisted_20)
                              ])
                            ])
                          ])
                        ]))
                      }), 128))
                    ]),
                    _createElementVNode("div", _hoisted_21, [
                      _createVNode(_component_va_pagination, {
                        modelValue: acceptedCurrentPage.value,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((acceptedCurrentPage).value = $event)),
                        pages: acceptedTotalPages.value,
                        "visible-pages": 3,
                        hideOnSinglePage: true,
                        onClick: acceptedOnClick
                      }, null, 8, ["modelValue", "pages"])
                    ])
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_22, _cache[11] || (_cache[11] = [
                    _createElementVNode("br", null, null, -1),
                    _createTextVNode(" No entries under this category at the moment ")
                  ])))
            ]),
            _createElementVNode("div", _hoisted_23, [
              _cache[24] || (_cache[24] = _createElementVNode("span", { class: "bold-text" }, " Course-Support Requests Declined/Not Responded ", -1)),
              (declinedCourse.value.length > 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
                    _createElementVNode("ul", null, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(declinedCourse.value, (course) => {
                        return (_openBlock(), _createElementBlock("li", {
                          key: course.id
                        }, [
                          _createElementVNode("div", _hoisted_25, [
                            _createElementVNode("div", null, [
                              _createTextVNode(" Course-Support Type: " + _toDisplayString(course.courseRequestType) + " ", 1),
                              (course.status == '90')
                                ? (_openBlock(), _createElementBlock("span", _hoisted_26, [
                                    _cache[15] || (_cache[15] = _createTextVNode("   ")),
                                    _createElementVNode("span", _hoisted_27, [
                                      _createElementVNode("span", {
                                        onClick: ($event: any) => (showTooltip(course.id))
                                      }, [
                                        _createVNode(_component_font_awesome_icon, {
                                          icon: "fa-solid fa-xmark",
                                          style: {"color":"#e71921","font-size":"x-large"}
                                        }),
                                        _cache[13] || (_cache[13] = _createElementVNode("span", { style: {"font-size":"smaller"} }, " Declined", -1))
                                      ], 8, _hoisted_28),
                                      _withDirectives(_createElementVNode("span", _hoisted_29, [
                                        _createElementVNode("button", _hoisted_30, [
                                          _createVNode(_component_font_awesome_icon, {
                                            icon: "fa-solid fa-xmark",
                                            class: "close-btn-icon",
                                            onClick: ($event: any) => (hideTooltip(course.id))
                                          }, null, 8, ["onClick"])
                                        ]),
                                        _cache[14] || (_cache[14] = _createElementVNode("p", { style: {"margin-bottom":"0px"} }, " You declined this request. ", -1))
                                      ], 512), [
                                        [_vShow, elementVisible.value[course.id]]
                                      ])
                                    ])
                                  ]))
                                : (_openBlock(), _createElementBlock("span", _hoisted_31, [
                                    _cache[17] || (_cache[17] = _createTextVNode("   ")),
                                    _createElementVNode("span", _hoisted_32, [
                                      _createVNode(_component_font_awesome_icon, {
                                        icon: "fa-solid fa-circle-exclamation",
                                        onClick: ($event: any) => (showTooltip(course.id))
                                      }, null, 8, ["onClick"]),
                                      _withDirectives(_createElementVNode("span", _hoisted_33, [
                                        _createElementVNode("button", _hoisted_34, [
                                          _createVNode(_component_font_awesome_icon, {
                                            icon: "fa-solid fa-xmark",
                                            class: "close-btn-icon",
                                            onClick: ($event: any) => (hideTooltip(course.id))
                                          }, null, 8, ["onClick"])
                                        ]),
                                        _cache[16] || (_cache[16] = _createElementVNode("p", { style: {"margin-bottom":"0px"} }, " You did not respond to this request. ", -1))
                                      ], 512), [
                                        [_vShow, elementVisible.value[course.id]]
                                      ])
                                    ])
                                  ])),
                              _cache[18] || (_cache[18] = _createElementVNode("br", null, null, -1)),
                              _cache[19] || (_cache[19] = _createTextVNode(" Course Requested For : ")),
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(JSON.parse(course.date), (item, index) => {
                                return (_openBlock(), _createElementBlock("span", { key: index }, [
                                  _createTextVNode(_toDisplayString(item) + "@" + _toDisplayString(_unref(formatTime)(JSON.parse(course.time)[index])) + " ", 1),
                                  (index != JSON.parse(course.date).length - 1)
                                    ? (_openBlock(), _createElementBlock("span", _hoisted_35, " OR "))
                                    : _createCommentVNode("", true)
                                ]))
                              }), 128)),
                              _cache[20] || (_cache[20] = _createElementVNode("br", null, null, -1)),
                              _createTextVNode(" Professor : " + _toDisplayString(course.user.title +
                        " " +
                        course.user.firstName +
                        " " +
                        course.user.lastName) + " ", 1),
                              _cache[21] || (_cache[21] = _createElementVNode("br", null, null, -1)),
                              _createTextVNode(" Institute: " + _toDisplayString(course.user.nameOrg), 1)
                            ]),
                            _createElementVNode("div", _hoisted_36, [
                              _createElementVNode("div", _hoisted_37, [
                                _createElementVNode("button", {
                                  class: "btn btn-primary bg-green-400 border-none",
                                  onClick: ($event: any) => (viewCourseRequest(course)),
                                  title: "View Details"
                                }, _cache[22] || (_cache[22] = [
                                  _createElementVNode("img", {
                                    src: _imports_1,
                                    class: "sidebar-image"
                                  }, null, -1)
                                ]), 8, _hoisted_38)
                              ])
                            ])
                          ])
                        ]))
                      }), 128))
                    ]),
                    _createElementVNode("div", _hoisted_39, [
                      _createVNode(_component_va_pagination, {
                        modelValue: declinedCurrentPage.value,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((declinedCurrentPage).value = $event)),
                        pages: declinedTotalPages.value,
                        "visible-pages": 3,
                        hideOnSinglePage: true,
                        onClick: declinedOnClick
                      }, null, 8, ["modelValue", "pages"])
                    ])
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_40, _cache[23] || (_cache[23] = [
                    _createElementVNode("br", null, null, -1),
                    _createTextVNode(" No entries under this category at the moment ")
                  ])))
            ])
          ])
        ])
      ])
    ]),
    _createVNode(_unref(Dialog), {
      visible: _unref(feedbackVisible),
      "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => (_isRef(feedbackVisible) ? (feedbackVisible).value = $event : feedbackVisible = $event)),
      modal: "",
      header: "Course-Support Feedback",
      style: { width: '50vw' },
      breakpoints: { '768px': '90vw' }
    }, {
      default: _withCtx(() => [
        _createVNode(FeedbackFrom, {
          "curr-course-id": _unref(currCourse).id,
          onFetchCourse: fetchCourse
        }, null, 8, ["curr-course-id"])
      ]),
      _: 1
    }, 8, ["visible"])
  ], 64))
}
}

})