import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createTextVNode as _createTextVNode, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, toDisplayString as _toDisplayString, mergeProps as _mergeProps, withCtx as _withCtx, isRef as _isRef, withModifiers as _withModifiers, withKeys as _withKeys, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "card card-container" }
const _hoisted_2 = { class: "align-center mt-3" }
const _hoisted_3 = {
  key: 0,
  class: "form-group"
}
const _hoisted_4 = { title: "Seminar is for sharing knowledge, and facilitating discussions on a specific topic or subject. It can also be an avenue to present their ideas and experiences to students." }
const _hoisted_5 = { title: "Site visit is a means to expose students to the workplace and work practices on construction job sites." }
const _hoisted_6 = { title: "Workshop is an interactive learning and skill-building session to provide students with hands-on experience and practical training in a specific area or to develop certain skills." }
const _hoisted_7 = { title: "Guest lecture entails bringing practitioners into the classroom to discuss technical topics from their experience." }
const _hoisted_8 = { title: "This involve assessing and appraising students’ projects based on established guidelines, client expectations, and" }
const _hoisted_9 = { title: "This entails providing professional guidance to students throughout the delivery of their project." }
const _hoisted_10 = { title: "This entails providing context for students’ project and acting as client." }
const _hoisted_11 = { title: "Laboratory session is a practical, hands-on learning experience that takes place in a laboratory where students get the opportunity to apply theoretical concepts to real-world construction tasks." }
const _hoisted_12 = {
  key: 0,
  class: "form-group"
}
const _hoisted_13 = ["checked"]
const _hoisted_14 = {
  key: 1,
  class: "mt-2"
}
const _hoisted_15 = { class: "flex flex-direction-row justify-space-between" }
const _hoisted_16 = {
  key: 0,
  style: {"display":"flex","flex-wrap":"wrap"}
}
const _hoisted_17 = { class: "list-item-remove" }
const _hoisted_18 = ["onClick"]
const _hoisted_19 = { class: "text-align-center mb-3" }
const _hoisted_20 = { style: {"text-align":"center"} }

import { ref } from "vue";
import { useForm, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import { useConfigStore } from "@/stores/ConfigStore";
import { useAvailabilityStore } from "@/stores/AvailabilityStore";
import { onBeforeRouteLeave } from "vue-router";
import Multiselect from "@vueform/multiselect";
import { useModal } from "vuestic-ui";
import {
  subjectWillingToSpeakOnOptions,
  showErrorToast,
  displayLabels,
} from "@/utils";
import { Availability } from "@/types";


export default /*@__PURE__*/_defineComponent({
  __name: 'CourseSelectionForm',
  props: {
    availability: {}
  },
  emits: ["siteVisitSelected", "nextStep"],
  setup(__props: any, { emit: __emit }) {

const AvailabilityStore = useAvailabilityStore();
let subjectSpeak_text = ref("");
let showSiteVisitModal = ref(false);
// eslint-disable-next-line no-undef
const props = __props;

// eslint-disable-next-line no-undef
const emits = __emit;
const { confirm } = useModal();
const ConfigStore = useConfigStore();

const subjectWillingToSpeakOnOptionsAdded = [
  ...subjectWillingToSpeakOnOptions,
  { label: "Others, please specify", value: "Others" },
];
const availabilityFields = ConfigStore.config.filter(
  (el) => el.table === "availability"
);

const schema = yup.object().shape({
  fields: yup.array().default(availabilityFields),
  courseRequestType: yup
    .array()
    .of(yup.string())
    .when("fields", {
      is: (val) => !val.find((el) => el.name === "courseRequestType")?.disabled,
      then: (schema) =>
        schema
          .required("Please select one of available Course Support Type!")
          .min(1, "Please select one of available Course Support Type!"),
      otherwise: (schema) => schema.optional().nullable(),
    }),
  subjectSpeak: yup
    .array()
    .of(yup.string())
    .when("fields", {
      is: (val) => !val.find((el) => el.name === "subjectSpeak")?.disabled,
      then: (schema) =>
        schema
          .required("Subject areas you are willing to speak on is required!")
          .min(1, "Subject areas you are willing to speak on is required!"),
      otherwise: (schema) => schema.optional().nullable(),
    }),
  subjectSpeakOthers: yup
    .array()
    .of(yup.string())
    .when("subjectSpeak", {
      is: (val) => val.includes("Others"),
      then: (schema) =>
        schema
          .required("Subject areas you are willing to speak on is required!")
          .min(1, "Subject areas you are willing to speak on is required!"),
      otherwise: (schema) => schema.optional().min(0),
    }),
});

const removeVal = async (val) => {
  values.subjectSpeakOthers?.splice(values.subjectSpeak.indexOf(val), 1);
  const { valid, errors } = await validate();
};
const addSubjectSpeak = () => {
  if (
    !values.subjectSpeakOthers?.includes(subjectSpeak_text.value) &&
    subjectSpeak_text.value.length > 0
  )
    values.subjectSpeakOthers?.push(subjectSpeak_text.value);
  subjectSpeak_text.value = "";
};
const checkBoxClicked = (e) => {
  let clickedCourseType = e.target.name;
  if (values[clickedCourseType] === "True") {
    values.courseRequestType.push(clickedCourseType);
    if (clickedCourseType === "Site Visit") {
      emits("siteVisitSelected", true);
      showSiteVisitModal.value = true;
    }
  } else if (values[clickedCourseType] === "False") {
    values.courseRequestType.splice(
      values.courseRequestType.indexOf(clickedCourseType),
      1
    );
    if (clickedCourseType === "Site Visit") {
      emits("siteVisitSelected", false);
    }
  }
};

let initialState = {
  "Guest Lecture": "False",
  Seminar: "False",
  "Mentor for Project": "False",
  "Sponsor for Project": "False",
  "Judge for Project": "False",
  "Site Visit": "False",
  Workshop: "False",
  Labs: "False",
  courseRequestType: [] as string[],
  subjectSpeak: [] as string[],
  subjectSpeakOthers: [] as string[],
};

if (AvailabilityStore.status) {
  initialState["Guest Lecture"] = props.availability.courseRequestType.includes(
    "Guest Lecture"
  )
    ? "True"
    : "False";
  initialState["Seminar"] = props.availability.courseRequestType.includes(
    "Seminar"
  )
    ? "True"
    : "False";
  initialState["Judge for Project"] =
    props.availability.courseRequestType.includes("Judge for Project")
      ? "True"
      : "False";
  initialState["Mentor for Project"] =
    props.availability.courseRequestType.includes("Mentor for Project")
      ? "True"
      : "False";
  initialState["Sponsor for Project"] =
    props.availability.courseRequestType.includes("Sponsor for Project")
      ? "True"
      : "False";
  initialState["Site Visit"] = props.availability.courseRequestType.includes(
    "Site Visit"
  )
    ? "True"
    : "False";
  initialState["Workshop"] = props.availability.courseRequestType.includes(
    "Workshop"
  )
    ? "True"
    : "False";
  initialState["Labs"] = props.availability.courseRequestType.includes("Labs")
    ? "True"
    : "False";
}

let formValues = {
  "Guest Lecture": initialState["Guest Lecture"],
  Seminar: initialState.Seminar,
  "Mentor for Project": initialState["Mentor for Project"],
  "Sponsor for Project": initialState["Sponsor for Project"],
  "Judge for Project": initialState["Judge for Project"],
  "Site Visit": initialState["Site Visit"],
  Workshop: initialState.Workshop,
  Labs: initialState.Labs,
  courseRequestType: props.availability?.courseRequestType || [],
  subjectSpeak: props.availability?.subjectSpeak || [],
  subjectSpeakOthers: props.availability?.subjectSpeakOthers || [],
};

const { handleSubmit, errors, values, meta, validate, resetForm } = useForm({
  validationSchema: schema,
});

resetForm({
  values: formValues,
});

const saveCourseSelection = handleSubmit(
  (data) => {
    console.log(data);
    emits("nextStep", data);
  },
  (errors) => {
    Object.values(errors.errors).forEach((error) => showErrorToast(error));
  }
);

onBeforeRouteLeave(async (to, from) => {
  if (meta.value.dirty) {
    const answer = await confirm({
      message:
        "Your changes on this page have not been saved. Kindly save your changes before leaving the page. Do you want to proceed without saving your changes?",
      okText: "Yes",
      cancelText: "No",
    });
    if (!answer) return false;
  }
});

return (_ctx: any,_cache: any) => {
  const _component_va_divider = _resolveComponent("va-divider")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_va_button = _resolveComponent("va-button")!
  const _component_va_modal = _resolveComponent("va-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("form", {
      onKeydown: _cache[3] || (_cache[3] = _withKeys(($event: any) => ($event.preventDefault()), ["enter"])),
      class: "mt-3 lg:mx-3"
    }, [
      _createElementVNode("div", _hoisted_1, [
        _cache[19] || (_cache[19] = _createElementVNode("div", { class: "bold-text ml-3 text-align-center" }, " Please indicate the types of course-supports you are willing to provide ", -1)),
        _createElementVNode("div", _hoisted_2, [
          (
            !_unref(availabilityFields).find((el) => el.name === 'courseRequestType')
              ?.disabled
          )
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _cache[15] || (_cache[15] = _createElementVNode("label", { for: "courseRequestType" }, [
                  _createTextVNode("Course-Support Type"),
                  _createElementVNode("span", { class: "super font-color-red" }, "*")
                ], -1)),
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_unref(Field), {
                    name: "Seminar",
                    value: "True",
                    "unchecked-value": "False",
                    type: "checkbox",
                    onChange: checkBoxClicked
                  }),
                  _cache[7] || (_cache[7] = _createTextVNode(" Seminar "))
                ]),
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(_unref(Field), {
                    value: "True",
                    "unchecked-value": "False",
                    name: "Site Visit",
                    type: "checkbox",
                    onChange: checkBoxClicked
                  }),
                  _cache[8] || (_cache[8] = _createTextVNode(" Site Visit "))
                ]),
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_unref(Field), {
                    value: "True",
                    "unchecked-value": "False",
                    name: "Workshop",
                    type: "checkbox",
                    onChange: checkBoxClicked
                  }),
                  _cache[9] || (_cache[9] = _createTextVNode(" Workshop "))
                ]),
                _createElementVNode("div", _hoisted_7, [
                  _createVNode(_unref(Field), {
                    value: "True",
                    "unchecked-value": "False",
                    name: "Guest Lecture",
                    type: "checkbox",
                    onChange: checkBoxClicked
                  }),
                  _cache[10] || (_cache[10] = _createElementVNode("span", null, " Guest Lecture", -1))
                ]),
                _createElementVNode("div", _hoisted_8, [
                  _createVNode(_unref(Field), {
                    value: "True",
                    "unchecked-value": "False",
                    name: "Judge for Project",
                    type: "checkbox",
                    onChange: checkBoxClicked
                  }),
                  _cache[11] || (_cache[11] = _createTextVNode(" Judge for Project "))
                ]),
                _createElementVNode("div", _hoisted_9, [
                  _createVNode(_unref(Field), {
                    value: "True",
                    "unchecked-value": "False",
                    name: "Mentor for Project",
                    type: "checkbox",
                    onChange: checkBoxClicked
                  }),
                  _cache[12] || (_cache[12] = _createTextVNode(" Mentor for Project "))
                ]),
                _createElementVNode("div", _hoisted_10, [
                  _createVNode(_unref(Field), {
                    value: "True",
                    "unchecked-value": "False",
                    name: "Sponsor for Project",
                    type: "checkbox",
                    onChange: checkBoxClicked
                  }),
                  _cache[13] || (_cache[13] = _createTextVNode(" Sponsor for Project "))
                ]),
                _createElementVNode("div", _hoisted_11, [
                  _createVNode(_unref(Field), {
                    value: "True",
                    "unchecked-value": "False",
                    name: "Labs",
                    type: "checkbox",
                    onChange: checkBoxClicked
                  }),
                  _cache[14] || (_cache[14] = _createTextVNode(" Labs "))
                ]),
                (_unref(meta).dirty)
                  ? (_openBlock(), _createBlock(_unref(ErrorMessage), {
                      key: 0,
                      name: "courseRequestType",
                      class: "error-feedback"
                    }))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true)
        ]),
        _createVNode(_component_va_divider, { horizontal: "" }),
        (
          !_unref(availabilityFields).find((el) => el.name === 'subjectSpeak')?.disabled
        )
          ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
              _cache[18] || (_cache[18] = _createElementVNode("label", { for: "subjectSpeak" }, [
                _createTextVNode("Subject Areas You Are Willing To Speak On"),
                _createElementVNode("span", { class: "super font-color-red" }, "*")
              ], -1)),
              _createVNode(_unref(Field), {
                modelValue: _unref(values).subjectSpeak,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(values).subjectSpeak) = $event)),
                name: "subjectSpeak",
                type: "text",
                class: "form-control"
              }, {
                default: _withCtx(({ field }) => [
                  _createVNode(_unref(Multiselect), _mergeProps(field, {
                    modelValue: _unref(values).subjectSpeak,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(values).subjectSpeak) = $event)),
                    options: subjectWillingToSpeakOnOptionsAdded,
                    mode: "tags",
                    searchable: true,
                    multipleLabel: _unref(displayLabels),
                    hideSelected: false,
                    closeOnSelect: false,
                    closeOnDeselect: false
                  }), {
                    option: _withCtx(({ option }) => [
                      _createElementVNode("input", {
                        type: "checkbox",
                        class: "input-pointer",
                        checked: _unref(values).subjectSpeak?.includes(option.value)
                      }, null, 8, _hoisted_13),
                      _createTextVNode("   " + _toDisplayString(option.label), 1)
                    ]),
                    _: 2
                  }, 1040, ["modelValue", "multipleLabel"])
                ]),
                _: 1
              }, 8, ["modelValue"]),
              (_unref(meta).dirty)
                ? (_openBlock(), _createBlock(_unref(ErrorMessage), {
                    key: 0,
                    name: "subjectSpeak",
                    class: "error-feedback"
                  }))
                : _createCommentVNode("", true),
              (_unref(values).subjectSpeak?.includes('Others'))
                ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                    _createElementVNode("div", _hoisted_15, [
                      _createVNode(_unref(Field), {
                        name: "subjectSpeak-text",
                        type: "text",
                        placeholder: "One entry per input, but multiple input is allowed",
                        "aria-placeholder": "One entry per input, but multiple input is allowed",
                        class: "form-control",
                        modelValue: _unref(subjectSpeak_text),
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_isRef(subjectSpeak_text) ? (subjectSpeak_text).value = $event : subjectSpeak_text = $event)),
                        onKeyup: _withKeys(_withModifiers(addSubjectSpeak, ["prevent"]), ["enter"])
                      }, null, 8, ["modelValue", "onKeyup"]),
                      _createElementVNode("div", {
                        class: "ml-2 cursor-pointer d-inline flex-shrink-1 flex-grow-0 btn btn-primary w-6rem",
                        style: {"height":"2.25rem"},
                        onClick: addSubjectSpeak
                      }, [
                        _createVNode(_component_font_awesome_icon, {
                          style: {"margin":"auto"},
                          icon: "fa-solid fa-plus",
                          title: "Add Learning Objective/Expected Student Outcomes"
                        }),
                        _cache[16] || (_cache[16] = _createTextVNode("  ")),
                        _cache[17] || (_cache[17] = _createElementVNode("span", null, "Add", -1))
                      ])
                    ]),
                    (_unref(values).subjectSpeakOthers != null)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(values).subjectSpeakOthers, (item) => {
                            return (_openBlock(), _createElementBlock("span", {
                              key: item,
                              class: "list-item"
                            }, [
                              _createTextVNode(_toDisplayString(item.trim()) + " ", 1),
                              _createElementVNode("span", _hoisted_17, [
                                _createElementVNode("span", {
                                  class: "list-item-remove-icon",
                                  onClick: ($event: any) => (removeVal(item))
                                }, null, 8, _hoisted_18)
                              ])
                            ]))
                          }), 128))
                        ]))
                      : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true),
              (_unref(meta).dirty)
                ? (_openBlock(), _createBlock(_unref(ErrorMessage), {
                    key: 2,
                    name: "subjectSpeakOthers",
                    class: "error-feedback"
                  }))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true)
      ])
    ], 32),
    _createElementVNode("div", _hoisted_19, [
      _createElementVNode("button", {
        class: "btn btn-primary ml-2",
        type: "button",
        onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_unref(saveCourseSelection) && _unref(saveCourseSelection)(...args)))
      }, " Next ")
    ]),
    _createVNode(_component_va_modal, {
      modelValue: _unref(showSiteVisitModal),
      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (_isRef(showSiteVisitModal) ? (showSiteVisitModal).value = $event : showSiteVisitModal = $event)),
      "hide-default-actions": ""
    }, {
      default: _withCtx(() => [
        _cache[21] || (_cache[21] = _createElementVNode("h3", { class: "va-h3" }, null, -1)),
        _cache[22] || (_cache[22] = _createElementVNode("p", null, " Please do not share confidential information about your construction project(s) ", -1)),
        _cache[23] || (_cache[23] = _createElementVNode("br", null, null, -1)),
        _createElementVNode("div", _hoisted_20, [
          _createVNode(_component_va_button, {
            onClick: _cache[5] || (_cache[5] = ($event: any) => (_isRef(showSiteVisitModal) //@ts-ignore
 ? showSiteVisitModal.value = false : showSiteVisitModal = false))
          }, {
            default: _withCtx(() => _cache[20] || (_cache[20] = [
              _createTextVNode(" Accept ")
            ])),
            _: 1
          })
        ])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}
}

})