import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "grid-container" }
const _hoisted_2 = { class: "container min-height" }
const _hoisted_3 = { class: "hidden ml-1 lg:inline-block" }
const _hoisted_4 = { class: "inline-block ml-1 lg:hidden" }
const _hoisted_5 = { class: "tab-count" }
const _hoisted_6 = {
  style: {"text-align":"right"},
  class: "mt-2"
}
const _hoisted_7 = { class: "mt-3" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { class: "w-100" }
const _hoisted_10 = {
  key: 1,
  style: {"font-style":"italic","text-align":"center"}
}
const _hoisted_11 = { key: 0 }
const _hoisted_12 = { key: 1 }
const _hoisted_13 = { class: "paginator" }

import { ref } from "vue";
import PractitionerService from "@/services/practitioner.service.js";
import PractitionerSidebar from "@/components/PractitionerSidebar.vue";
import UserList from "@/components/user/UserList.vue";
import CourseRequestItem from "@/components/practitioner/CourseRequestItem.vue";
import CourseRequestGridItem from "@/components/practitioner/CourseRequestGridItem.vue";
import DataView from "primevue/dataview";
import DataViewLayoutOptions from "primevue/dataviewlayoutoptions";

import { useAuthStore } from "@/stores/AuthStore";
import { useCourseStore } from "@/stores/CourseStore";
import { useFeedStore } from "@/stores/FeedStore";
import { useRouter, useRoute } from "vue-router";
import UserService from "@/services/user.service.js";


export default /*@__PURE__*/_defineComponent({
  __name: 'FeedView',
  setup(__props) {

const AuthStore = useAuthStore();
const CourseStore = useCourseStore();
const FeedStore = useFeedStore();
const router = useRouter();
const route = useRoute();

const generalCourse = ref([]);
const myCourse = ref([]);
const currentPage = ref(1);
const totalPages = ref(1);
const generalCurrentPage = ref(1);
const generalTotalPages = ref(1);
let personalPendingRequests = ref(0);
let generalPendingRequests = ref(0);
const elementVisible = ref({});

if (route.query["redirect"]) {
  const redirectURL = route.query["redirect"] as string;
  const notificationId = redirectURL.split("/").pop();
  UserService.getNotification(notificationId).then(
    (response) => {
      const notification = response.data;
      notification.course_request.user = notification.user;
      CourseStore.addCourse(notification.course_request);
      if (AuthStore.role === "INDUSTRYPRACTITIONER")
        router.push({ name: "view-course-request-prac" });
      else if (AuthStore.role === "INSTRUCTOR") {
        CourseStore.setPractitioner(notification.user.id);
        router.push({ name: "view-industry-practitioner-ins" });
      } else router.push("/home");
    },
    (error) => {
      console.log(error);
      router.push("/home");
    }
  );
}

const viewCourseSupport = (type, pageNumber = 1) => {
  PractitionerService.getPractitionerBoard(pageNumber, type).then(
    (response) => {
      if (type === "personal") {
        myCourse.value = response.data.courses;
        totalPages.value = response.data.totalPages;
        currentPage.value = response.data.currentPage;
      } else {
        generalCourse.value = response.data.courses;
        generalTotalPages.value = response.data.totalPages;
        generalCurrentPage.value = response.data.currentPage;
      }
      response.data.courses.forEach(
        (el) => (elementVisible.value[el.id] = false)
      );
    },
    (error) => {
      let res =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(res);
    }
  );
};

const getPendingRequests = () => {
  PractitionerService.getPendingRequests().then(
    (response) => {
      personalPendingRequests.value = response.data.personalCourseCount;
      generalPendingRequests.value = response.data.generalCourseCount;
    },
    (error) => {
      let res =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(res);
    }
  );
};

viewCourseSupport("personal");
viewCourseSupport("general");
getPendingRequests();

const tabs = ref([
  {
    id: 0,
    title: "Course-Support Requests Directed to me",
    title_sm: "Requests to me",
    icon: "fa-regular fa-folder-open",
    content: myCourse,
    pendingCount: personalPendingRequests,
  },
  {
    id: 1,
    title: "General Course-Support Requests",
    title_sm: "General Requests",
    icon: "fa-regular fa-folder",
    content: generalCourse,
    pendingCount: generalPendingRequests,
  },
]);
const onClick = () => {
  viewCourseSupport("personal", currentPage.value);
};
const onClickGeneral = () => {
  viewCourseSupport("general", generalCurrentPage.value);
};

return (_ctx: any,_cache: any) => {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_va_tab = _resolveComponent("va-tab")!
  const _component_va_tabs = _resolveComponent("va-tabs")!
  const _component_va_pagination = _resolveComponent("va-pagination")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(PractitionerSidebar),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_va_tabs, {
        grow: "",
        modelValue: _unref(FeedStore).tab,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(FeedStore).tab) = $event))
      }, {
        tabs: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(tabs.value, (tab) => {
            return (_openBlock(), _createBlock(_component_va_tab, {
              key: tab.title
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", {
                  class: _normalizeClass([{ 'font-larger': _unref(FeedStore).tab === tab.id }, "tab-font"])
                }, [
                  _createVNode(_component_font_awesome_icon, {
                    icon: tab.icon
                  }, null, 8, ["icon"]),
                  _createElementVNode("span", _hoisted_3, _toDisplayString(tab.title), 1),
                  _createElementVNode("span", _hoisted_4, _toDisplayString(tab.title_sm), 1)
                ], 2),
                _cache[4] || (_cache[4] = _createTextVNode("   ")),
                _createElementVNode("span", _hoisted_5, _toDisplayString(tab.pendingCount), 1)
              ]),
              _: 2
            }, 1024))
          }), 128))
        ]),
        _: 1
      }, 8, ["modelValue"]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_unref(DataViewLayoutOptions), {
          modelValue: _unref(FeedStore).feedLayout,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(FeedStore).feedLayout) = $event))
        }, null, 8, ["modelValue"])
      ]),
      _createElementVNode("div", null, [
        _createElementVNode("div", _hoisted_7, [
          (tabs.value[_unref(FeedStore).tab].content.length > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _createVNode(_unref(DataView), {
                  value: tabs.value[_unref(FeedStore).tab].content,
                  layout: _unref(FeedStore).feedLayout
                }, {
                  list: _withCtx((slotProps) => [
                    _createElementVNode("div", _hoisted_9, [
                      _createVNode(CourseRequestItem, {
                        course: slotProps.data
                      }, null, 8, ["course"])
                    ])
                  ]),
                  grid: _withCtx((slotProps) => [
                    _createVNode(CourseRequestGridItem, {
                      course: slotProps.data
                    }, null, 8, ["course"])
                  ]),
                  _: 1
                }, 8, ["value", "layout"])
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_10, [
                (_unref(FeedStore).tab === 0)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_11, "No course-support request from instructors directed to you at this time"))
                  : (_openBlock(), _createElementBlock("span", _hoisted_12, "No general course-support request from instructors at the moment"))
              ]))
        ]),
        _createElementVNode("div", _hoisted_13, [
          (_unref(FeedStore).tab === 0)
            ? (_openBlock(), _createBlock(_component_va_pagination, {
                key: 0,
                modelValue: currentPage.value,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((currentPage).value = $event)),
                pages: totalPages.value,
                "visible-pages": 3,
                hideOnSinglePage: true,
                onClick: onClick
              }, null, 8, ["modelValue", "pages"]))
            : (_unref(FeedStore).tab === 1)
              ? (_openBlock(), _createBlock(_component_va_pagination, {
                  key: 1,
                  modelValue: generalCurrentPage.value,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((generalCurrentPage).value = $event)),
                  pages: generalTotalPages.value,
                  "visible-pages": 3,
                  hideOnSinglePage: true,
                  onClick: onClickGeneral
                }, null, 8, ["modelValue", "pages"]))
              : _createCommentVNode("", true)
        ])
      ])
    ]),
    _createVNode(UserList)
  ]))
}
}

})