import axios from "axios";
import authHeader from "./auth-header";
import { websiteURL } from "@/utils";

import { useAuthStore } from "@/stores/AuthStore";
import { useCourseStore } from "@/stores/CourseStore";

const API_URL = websiteURL + "api/";

class InstructorService {
  getInstructorBoard(page, type, courseCode = "") {
    // console.log(API_URL + "user/course");
    return axios.get(API_URL + "user/course", {
      params: { page: page, size: 10, type: type, courseCode: courseCode },
      headers: authHeader(),
    });
  }

  getResourcesRequest(resourceRequestId) {
    console.log(API_URL + "user/resource/" + resourceRequestId);
    return axios.get(API_URL + "user/resource/" + resourceRequestId, {
      headers: authHeader(),
    });
  }

  saveCourseRequest(courseData, action) {
    const AuthStore = useAuthStore();
    const CourseStore = useCourseStore();

    // console.log("Trying To Save Profile " + API_URL + "user/course");
    // console.log("UserId " + AuthStore.auth.id);

    if (!CourseStore.status || action === "resubmit") {
      return axios.post(
        API_URL + "user/course",
        {
          academicLevel: JSON.stringify(courseData.academicLevel),
          ageRange: courseData.ageRange,
          areaOfExpertise: courseData.areaOfExpertise,
          classSize: courseData.classSize,
          countryStudy: courseData.countryStudy,
          courseRequestType: courseData.courseRequestType,
          courseCode: courseData.courseCode,
          courseTitle: courseData.courseTitle,
          date: JSON.stringify(courseData.date),
          duration: courseData.duration,
          ethnicDiv: courseData.ethnicDiv,
          ethnicity: courseData.ethnicity,
          gender: courseData.gender,
          genderProp: courseData.genderProp,
          generalInfo: courseData.generalInfo,
          learning: JSON.stringify(courseData.learning),
          levelEducation: courseData.levelEducation,
          needForPreVisit: courseData.needForPreVisit,
          noOfFreq: courseData.noOfFreq,
          physicalDis: JSON.stringify(courseData.physicalDis),
          physicalDisConcern: JSON.stringify(courseData.physicalDisConcern),
          priorKnowledge: courseData.priorKnowledge,
          siteOffice: courseData.siteOffice,
          studentDept: JSON.stringify(courseData.studentDept),
          time: JSON.stringify(courseData.time),
          topic: courseData.topic,
          visualRep: courseData.visualRep,
          yearsOfExp: courseData.yearsOfExp,
          insDeliveryType: courseData.insDeliveryType,
          phone: courseData.phone,
          phoneNumber: courseData.phoneNumber,
          typeProject: courseData.typeProject,
          location: courseData.location,
          projectDescr: courseData.projectDescr,
          daysOfMeeting: courseData.daysOfMeeting,
          endDate: courseData.endDate,
          classroomItems: courseData.classroomItems,
          assigmentConnected: courseData.assigmentConnected,
          additionalInformation: courseData.additionalInformation,
          typeFirm: courseData.typeFirm,
          sizeFirm: courseData.sizeFirm,
          files: courseData.files,
          semester: courseData.semester,
          year: courseData.year,
          userId: AuthStore.auth.id,
        },
        { headers: authHeader() }
      );
    } else {
      return axios.put(
        API_URL + "user/course",
        {
          academicLevel: JSON.stringify(courseData.academicLevel),
          ageRange: courseData.ageRange,
          areaOfExpertise: courseData.areaOfExpertise,
          classSize: courseData.classSize,
          countryStudy: courseData.countryStudy,
          courseRequestType: courseData.courseRequestType,
          courseCode: courseData.courseCode,
          courseTitle: courseData.courseTitle,
          date: JSON.stringify(courseData.date),
          duration: courseData.duration,
          ethnicDiv: courseData.ethnicDiv,
          ethnicity: courseData.ethnicity,
          gender: courseData.gender,
          genderProp: courseData.genderProp,
          generalInfo: courseData.generalInfo,
          learning: JSON.stringify(courseData.learning),
          levelEducation: courseData.levelEducation,
          needForPreVisit: courseData.needForPreVisit,
          noOfFreq: courseData.noOfFreq,
          physicalDis: JSON.stringify(courseData.physicalDis),
          physicalDisConcern: JSON.stringify(courseData.physicalDisConcern),
          priorKnowledge: courseData.priorKnowledge,
          siteOffice: courseData.siteOffice,
          studentDept: JSON.stringify(courseData.studentDept),
          time: JSON.stringify(courseData.time),
          topic: courseData.topic,
          visualRep: courseData.visualRep,
          yearsOfExp: courseData.yearsOfExp,
          userId: AuthStore.auth.id,
          insDeliveryType: courseData.insDeliveryType,
          phone: courseData.phone,
          phoneNumber: courseData.phoneNumber,
          typeProject: courseData.typeProject,
          location: courseData.location,
          projectDescr: courseData.projectDescr,
          daysOfMeeting: courseData.daysOfMeeting,
          endDate: courseData.endDate,
          classroomItems: courseData.classroomItems,
          assigmentConnected: courseData.assigmentConnected,
          additionalInformation: courseData.additionalInformation,
          typeFirm: courseData.typeFirm,
          sizeFirm: courseData.sizeFirm,
          files: courseData.files,
          semester: courseData.semester,
          year: courseData.year,
          id: CourseStore.course.id,
        },
        { headers: authHeader() }
      );
    }
  }

  deleteCourseRequest(courseData) {
    // console.log("Trying To Delete Course " + API_URL + "user/course/delete");
    return axios.post(
      API_URL + "user/course/delete",
      {
        id: courseData.courseId,
        reasonDelete: courseData.reasonDelete,
      },
      { headers: authHeader() }
    );
  }

  saveTempCourseRequest(courseData) {
    console.log("Trying To Save Profile " + API_URL + "user/course");
    // console.log("UserId " + AuthStore.auth.id);

    return axios.post(
      API_URL + "user/temp/course",
      {
        academicLevel: JSON.stringify(courseData.academicLevel),
        ageRange: courseData.ageRange,
        areaOfExpertise: courseData.areaOfExpertise,
        classSize: courseData.classSize,
        courseRequestType: courseData.courseRequestType,
        courseCode: courseData.courseCode,
        courseTitle: courseData.courseTitle,
        date: JSON.stringify(courseData.date),
        duration: courseData.duration,
        ethnicity: courseData.ethnicity,
        gender: courseData.gender,
        generalInfo: courseData.generalInfo,
        learning: JSON.stringify(courseData.learning),
        levelEducation: courseData.levelEducation,
        needForPreVisit: courseData.needForPreVisit,
        noOfFreq: courseData.noOfFreq,
        physicalDis: JSON.stringify(courseData.physicalDis),
        priorKnowledge: courseData.priorKnowledge,
        siteOffice: courseData.siteOffice,
        studentDept: JSON.stringify(courseData.studentDept),
        time: JSON.stringify(courseData.time),
        topic: courseData.topic,
        visualRep: courseData.visualRep,
        yearsOfExp: courseData.yearsOfExp,
        insDeliveryType: courseData.insDeliveryType,
        typeProject: courseData.typeProject,
        location: courseData.location,
        projectDescr: courseData.projectDescr,
        daysOfMeeting: courseData.daysOfMeeting,
        endDate: courseData.endDate,
        classroomItems: courseData.classroomItems,
        assigmentConnected: courseData.assigmentConnected,
        additionalInformation: courseData.additionalInformation,
        typeFirm: courseData.typeFirm,
        sizeFirm: courseData.sizeFirm,
        semester: courseData.semester,
        year: courseData.year,
      },
      { headers: authHeader() }
    );
  }

  getTempCourseRequest(page) {
    return axios.get(API_URL + "user/temp/course", {
      params: { page: page, size: 50 },
      headers: authHeader(),
    });
  }

  deleteTempCourseRequest(courseData) {
    console.log(
      "Trying To Delete Temp Course " +
        API_URL +
        "user/temp/course/delete " +
        courseData.id
    );
    return axios.post(
      API_URL + "user/temp/course/delete",
      {
        id: courseData.id,
      },
      { headers: authHeader() }
    );
  }

  saveCourse(course, action) {
    // console.log("Trying To Save Course " + API_URL + "user/class");
    // console.log("UserId " + AuthStore.auth.id);

    if (action === "create") {
      return axios.post(
        API_URL + "user/class",
        {
          academicLevel: JSON.stringify(course.academicLevel),
          classSize: course.classSize,
          courseCode: course.courseCode,
          courseTitle: course.courseTitle,
          studentDept: JSON.stringify(course.studentDept),
          semester: course.semester,
          year: course.year,
          additionalInformation: course.additionalInformation,
          location: course.location,
        },
        { headers: authHeader() }
      );
    } else {
      console.log(
        "Trying To Update Course " + API_URL + "user/class" + course.id
      );
      return axios.put(
        API_URL + "user/class",
        {
          academicLevel: JSON.stringify(course.academicLevel),
          classSize: course.classSize,
          courseCode: course.courseCode,
          courseTitle: course.courseTitle,
          studentDept: JSON.stringify(course.studentDept),
          semester: course.semester,
          year: course.year,
          additionalInformation: course.additionalInformation,
          location: course.location,
          id: course.id,
        },
        { headers: authHeader() }
      );
    }
  }

  getCourse(page) {
    console.log("Trying To Get Course " + API_URL + "user/class");
    return axios.get(API_URL + "user/class", {
      headers: authHeader(),
    });
  }

  getClassBoard(page, type, courseCode, semester, year) {
    // console.log(API_URL + "user/course");
    return axios.get(API_URL + "user/course", {
      params: {
        page: page,
        size: 5,
        type: type,
        courseCode: courseCode,
        semester: semester,
        year: year,
      },
      headers: authHeader(),
    });
  }

  saveResourcesRequest(data, action) {
    // console.log("Trying To Save Profile " + API_URL + "user/resource");
    // console.log("UserId " + AuthStore.auth.id);

    if (action === "create") {
      return axios.post(
        API_URL + "user/resource",
        {
          type: data.resourceType,
          description: data.description,
        },
        { headers: authHeader() }
      );
    } else {
      return axios.put(
        API_URL + "user/resource",
        {
          type: data.resourceType,
          description: data.description,
        },
        { headers: authHeader() }
      );
    }
  }

  updateResourcesRequest(resourceId, status) {
    // console.log("Trying To Close Resources " + API_URL + "user/resource/close");
    return axios.patch(
      API_URL + "user/resource",
      {
        id: resourceId,
        status: status,
      },
      { headers: authHeader() }
    );
  }

  getRecommendations(courseData) {
    // console.log(
    //   "Trying To View Recommendations for Course " +
    //     API_URL +
    //     "user/course/" +
    //     courseData.id +
    //     "/recommendations"
    // );
    return axios.get(
      API_URL + "user/course/" + courseData.id + "/recommendations",
      {
        headers: authHeader(),
      }
    );
  }

  getIndustryPractitionerProfile(industryPractitionerUserId) {
    // console.log(
    //   "Trying To View Profile for Course " +
    //     API_URL +
    //     "user/profile/" +
    //     industryPractitionerUserId
    // );
    return axios.get(API_URL + "user/profile/" + industryPractitionerUserId, {
      headers: authHeader(),
    });
  }

  getPastExperienceDetails(industryPractitionerUserId) {
    console.log(
      "Trying To View Experience for " +
        API_URL +
        "user/experience/" +
        industryPractitionerUserId
    );
    return axios.get(
      API_URL + "user/experience/" + industryPractitionerUserId,
      {
        headers: authHeader(),
      }
    );
  }

  selectIndustryPractitioner(
    course,
    industryPractitioner,
    msg,
    recommendationList
  ) {
    // console.log(API_URL + "user/practitioner");
    const industryPractitionerUserId = industryPractitioner.map((el) => el.id);
    return axios.post(
      API_URL + "user/practitioner",
      {
        id: course.id,
        industryPractitionerUserId: JSON.stringify(industryPractitionerUserId),
        message: msg,
        recommendationList: JSON.stringify(recommendationList),
      },
      {
        headers: authHeader(),
      }
    );
  }
}

export default new InstructorService();
