<template>
  <div class="p-2 cursor-pointer">
    <span style="display: grid; grid-template-columns: 14fr 1fr">
      <i>{{ props.title }}</i>
      <span
        class="list-item-remove list-item-remove-icon"
        @click="
          RecommendationsStore.removeSelectedPractitioner(
            selectedPractitioner.id
          )
        "
      ></span
    ></span>
    <div class="mt-2">
      <img
        v-if="selectedPractitioner.fileName"
        id="profile-img"
        :src="websiteURL + 'profile_picture/' + selectedPractitioner.fileName"
        class="recommendation-img-card"
      />
      <img
        v-else
        id="profile-img"
        src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
        class="recommendation-img-card"
      />
    </div>
    <div class="text-align-center">
      <div class="bold-text">
        {{
          selectedPractitioner.title +
          " " +
          selectedPractitioner.firstName +
          " " +
          selectedPractitioner.lastName
        }}
      </div>
      <div class="bold-text">
        {{
          selectedPractitioner.position + " at " + selectedPractitioner.nameOrg
        }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { websiteURL } from "@/utils";
import { useRecommendationsStore } from "@/stores/RecommendationsStore";
import { User } from "@/types";

const RecommendationsStore = useRecommendationsStore();
// eslint-disable-next-line no-undef
const props = defineProps<{
  selectedPractitioner: User;
  title: string;
}>();

console.log(props.selectedPractitioner);
</script>

<style scoped>
.recommendation-img-card {
  width: 6rem;
  height: 6rem;
  margin: 0 auto 10px;
  display: block;
}

.list-item-remove {
  justify-content: end;
}
.list-item-remove-icon {
  height: 1.25rem;
  width: 1.25rem;
}

.list-item-remove:hover {
  background: rgba(0, 0, 0, 0.6);
}
</style>
