import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "grid-container" }
const _hoisted_2 = { class: "m-3 min-height" }
const _hoisted_3 = { class: "back-grid" }

import { ref } from "vue";
import ResourcesList from "@/components/user/ResourcesListTabs.vue";
import InstructorSidebar from "@/components/instructor/InstructorSidebar.vue";
import BackButton from "@/components/user/BackButton.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'ResourcesView',
  setup(__props) {

let message = ref([]);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(InstructorSidebar),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(BackButton),
        _cache[0] || (_cache[0] = _createElementVNode("h2", { style: {"text-align":"center","font-weight":"bold"} }, "View Resources", -1))
      ]),
      _cache[1] || (_cache[1] = _createElementVNode("h5", { class: "text-align-center pl-3 pr-3 sm:pl-5 sm:pr-5 lg:pl-8 lg:pr-8" }, " You can download images and pictures on this page to support your instructional materials (for academic purposes only). ", -1)),
      _createElementVNode("div", null, [
        _createVNode(ResourcesList, { type: "public" })
      ])
    ])
  ]))
}
}

})