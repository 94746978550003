import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, mergeProps as _mergeProps, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "form-group" }
const _hoisted_2 = { class: "form-group" }
const _hoisted_3 = { class: "form-group" }
const _hoisted_4 = { class: "form-check-inline" }
const _hoisted_5 = { class: "form-check-inline ml-3" }
const _hoisted_6 = { class: "form-group" }
const _hoisted_7 = { class: "form-check-inline" }
const _hoisted_8 = { class: "form-check-inline ml-3" }
const _hoisted_9 = { class: "form-group text-align-center" }
const _hoisted_10 = ["disabled"]
const _hoisted_11 = { class: "spinner-border spinner-border-sm" }

import { ref } from "vue";
import * as yup from "yup";
import { Form, Field, ErrorMessage } from "vee-validate";
import { FAQ, Organization } from "@/types";
import AdminService from "@/services/admin.service.js";
import { showToast } from "@/utils";


export default /*@__PURE__*/_defineComponent({
  __name: 'OrganizationForm',
  props: {
    organization: {}
  },
  emits: ["saveOrganization"],
  setup(__props: any, { emit: __emit }) {

let loading = ref(false);
let message = ref("");

// eslint-disable-next-line no-undef
const props = __props;
// eslint-disable-next-line no-undef
const emits = __emit;

let initialState = {
  id: 0,
  name: "",
  originalName: "",
  status: "",
  display: false,
  profileUpdate: false,
};

const formValues = {
  id: props.organization?.id,
  name: props.organization?.name,
  originalName: props.organization?.originalName,
  display: props.organization?.display
    ? props.organization?.display.toString()
    : "",
  profileUpdate: props.organization?.profileUpdate
    ? props.organization?.profileUpdate.toString()
    : "",
};

const schema = yup.object().shape({
  name: yup
    .string()
    .required("Organization Name is required!")
    .max(255, "Organization Name cannot be greater than 255 characters"),
  display: yup.string().required("Display on Home Page is required!"),
  profileUpdate: yup.string().required("Update User Profile is required!"),
});

const saveOrganization = (values) => {
  AdminService.saveOrganization(values).then(
    (response) => {
      console.log(response);
      emits("saveOrganization");
      showToast("The Organization has been updated.");
    },
    (error) => {
      loading.value = false;
      message.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Form), {
    onSubmit: saveOrganization,
    "validation-schema": _unref(schema),
    "initial-values": formValues
  }, {
    default: _withCtx(() => [
      _createVNode(_unref(Field), {
        name: "id",
        hidden: ""
      }),
      _createElementVNode("div", _hoisted_1, [
        _cache[0] || (_cache[0] = _createElementVNode("label", { for: "originalName" }, [
          _createTextVNode("Initial Organization name"),
          _createElementVNode("span", { class: "super font-color-red" }, "*")
        ], -1)),
        _createVNode(_unref(Field), { name: "originalName" }, {
          default: _withCtx(({ field }) => [
            _createElementVNode("input", _mergeProps(field, {
              class: "form-control",
              type: "text",
              disabled: ""
            }), null, 16),
            _createVNode(_unref(ErrorMessage), {
              name: "originalName",
              class: "error-feedback"
            })
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_2, [
        _cache[1] || (_cache[1] = _createElementVNode("label", { for: "name" }, [
          _createTextVNode("Organization name "),
          _createElementVNode("span", { class: "super font-color-red" }, "*")
        ], -1)),
        _createVNode(_unref(Field), { name: "name" }, {
          default: _withCtx(({ field }) => [
            _createElementVNode("input", _mergeProps(field, {
              class: "form-control",
              type: "text"
            }), null, 16),
            _createVNode(_unref(ErrorMessage), {
              name: "name",
              class: "error-feedback"
            })
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _cache[2] || (_cache[2] = _createElementVNode("label", { for: "display" }, [
            _createTextVNode("Display on Home Page"),
            _createElementVNode("span", { class: "super font-color-red" }, "*")
          ], -1)),
          _createVNode(_unref(Field), {
            name: "display",
            class: "form-check-input",
            type: "radio",
            value: "true"
          }),
          _cache[3] || (_cache[3] = _createTextVNode("Yes "))
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_unref(Field), {
            name: "display",
            class: "form-check-input",
            type: "radio",
            value: "false"
          }),
          _cache[4] || (_cache[4] = _createTextVNode("No "))
        ]),
        _createVNode(_unref(ErrorMessage), {
          name: "display",
          class: "error-feedback"
        })
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _cache[5] || (_cache[5] = _createElementVNode("label", { for: "profileUpdate" }, [
            _createTextVNode("Update User Profile"),
            _createElementVNode("span", { class: "super font-color-red" }, "*")
          ], -1)),
          _createVNode(_unref(Field), {
            name: "profileUpdate",
            class: "form-check-input",
            type: "radio",
            value: "true"
          }),
          _cache[6] || (_cache[6] = _createTextVNode("Yes "))
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createVNode(_unref(Field), {
            name: "profileUpdate",
            class: "form-check-input",
            type: "radio",
            value: "false"
          }),
          _cache[7] || (_cache[7] = _createTextVNode("No "))
        ]),
        _createVNode(_unref(ErrorMessage), {
          name: "profileUpdate",
          class: "error-feedback"
        })
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("button", {
          class: "btn btn-primary btn-block",
          disabled: _unref(loading)
        }, [
          _withDirectives(_createElementVNode("span", _hoisted_11, null, 512), [
            [_vShow, _unref(loading)]
          ]),
          _cache[8] || (_cache[8] = _createTextVNode(" Save "))
        ], 8, _hoisted_10)
      ])
    ]),
    _: 1
  }, 8, ["validation-schema"]))
}
}

})