import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "table" }

import { Profile } from "@/types";

// eslint-disable-next-line no-undef

export default /*@__PURE__*/_defineComponent({
  __name: 'InstructorProfileTable',
  props: {
    userProfile: {}
  },
  setup(__props: any) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("table", _hoisted_1, [
    _createElementVNode("tr", null, [
      _cache[0] || (_cache[0] = _createElementVNode("td", null, "First Name", -1)),
      _createElementVNode("td", null, _toDisplayString(_ctx.userProfile.user.firstName), 1)
    ]),
    _createElementVNode("tr", null, [
      _cache[1] || (_cache[1] = _createElementVNode("td", null, "Last Name", -1)),
      _createElementVNode("td", null, _toDisplayString(_ctx.userProfile.user.lastName), 1)
    ]),
    _createElementVNode("tr", null, [
      _cache[2] || (_cache[2] = _createElementVNode("td", null, "Email", -1)),
      _createElementVNode("td", null, _toDisplayString(_ctx.userProfile.user.email), 1)
    ]),
    _createElementVNode("tr", null, [
      _cache[3] || (_cache[3] = _createElementVNode("td", null, "Name of Institution", -1)),
      _createElementVNode("td", null, _toDisplayString(_ctx.userProfile.user.nameOrg), 1)
    ]),
    _createElementVNode("tr", null, [
      _cache[4] || (_cache[4] = _createElementVNode("td", null, "Title", -1)),
      _createElementVNode("td", null, _toDisplayString(_ctx.userProfile.user.title), 1)
    ]),
    _createElementVNode("tr", null, [
      _cache[5] || (_cache[5] = _createElementVNode("td", null, "Gender Identity", -1)),
      _createElementVNode("td", null, _toDisplayString(_ctx.userProfile.user.gender), 1)
    ]),
    _createElementVNode("tr", null, [
      _cache[6] || (_cache[6] = _createElementVNode("td", null, "Ethnicity", -1)),
      _createElementVNode("td", null, _toDisplayString(_ctx.userProfile.user.ethnicity), 1)
    ]),
    _createElementVNode("tr", null, [
      _cache[7] || (_cache[7] = _createElementVNode("td", null, "Age Range", -1)),
      _createElementVNode("td", null, _toDisplayString(_ctx.userProfile.ageRange), 1)
    ]),
    _createElementVNode("tr", null, [
      _cache[8] || (_cache[8] = _createElementVNode("td", null, "Years of Faculty Experience", -1)),
      _createElementVNode("td", null, _toDisplayString(_ctx.userProfile.yearsOfExp), 1)
    ]),
    _createElementVNode("tr", null, [
      _cache[9] || (_cache[9] = _createElementVNode("td", null, "Pronouns", -1)),
      _createElementVNode("td", null, _toDisplayString(_ctx.userProfile.pronouns), 1)
    ]),
    _createElementVNode("tr", null, [
      _cache[10] || (_cache[10] = _createElementVNode("td", null, "Office Address", -1)),
      _createElementVNode("td", null, _toDisplayString(_ctx.userProfile.officeAddress), 1)
    ]),
    _createElementVNode("tr", null, [
      _cache[11] || (_cache[11] = _createElementVNode("td", null, "Department/Program", -1)),
      _createElementVNode("td", null, _toDisplayString(_ctx.userProfile.department), 1)
    ]),
    _createElementVNode("tr", null, [
      _cache[12] || (_cache[12] = _createElementVNode("td", null, "Type of Institution", -1)),
      _createElementVNode("td", null, _toDisplayString(_ctx.userProfile.insType), 1)
    ]),
    _createElementVNode("tr", null, [
      _cache[14] || (_cache[14] = _createElementVNode("td", null, "Location of Institution", -1)),
      _createElementVNode("td", null, [
        _createTextVNode(_toDisplayString(_ctx.userProfile.parkingAddress) + " ", 1),
        _cache[13] || (_cache[13] = _createElementVNode("br", null, null, -1)),
        _createTextVNode(" " + _toDisplayString(_ctx.userProfile.city + " " + _ctx.userProfile.state), 1)
      ])
    ]),
    _createElementVNode("tr", null, [
      _cache[15] || (_cache[15] = _createElementVNode("td", null, "Parking Arrangement for Practitioners", -1)),
      _createElementVNode("td", null, _toDisplayString(_ctx.userProfile.parkingArrangement), 1)
    ])
  ]))
}
}

})