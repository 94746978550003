<template>
  <table>
    <tr>
      <td>Course Code</td>
      <td>
        {{ props.course.courseCode }}
      </td>
    </tr>
    <tr>
      <td>Course Title</td>
      <td>
        {{ course.courseTitle }}
      </td>
    </tr>
    <tr>
      <td>Course-Support Request Type</td>
      <td>
        {{ course.courseRequestType }}
      </td>
    </tr>
    <tr>
      <td>Instruction Delivery Mode</td>
      <td>
        {{ course.insDeliveryType }}
      </td>
    </tr>
    <tr>
      <td>Class Size</td>
      <td>{{ course.classSize }}</td>
    </tr>
    <tr>
      <td>Academic Level</td>
      <td>{{ JSON.parse(course.academicLevel).join(", ") }}</td>
    </tr>
    <tr>
      <td>Students' Department or Program of Study</td>
      <td>{{ JSON.parse(course.studentDept).join(", ") }}</td>
    </tr>

    <tr>
      <td>When the Course-Support is Needed</td>
      <td>
        <span v-for="(item, index) in courseDate" :key="index">
          {{ new Date(item + "T" + courseTime[index]).toLocaleString() }}
          <span v-if="index != courseDate.length - 1"> OR </span>
        </span>
      </td>
    </tr>

    <tr>
      <td>Location of Institution</td>
      <td>
        {{ course.location }}
        {{ course.city + ", " + course.state }}
      </td>
    </tr>

    <tr>
      <td>Duration of Course-Support</td>
      <td>{{ course.duration }} minutes</td>
    </tr>

    <tr>
      <td>Number/Frequency of Course-Support</td>
      <td>{{ course.noOfFreq }}</td>
    </tr>

    <tr>
      <td>Topic Requiring Course-Support</td>
      <td>{{ course.topic }}</td>
    </tr>

    <tr>
      <td>Learning Objective/Expected Outcome</td>
      <td>{{ JSON.parse(course.learning).join(", ") }}</td>
    </tr>
    <tr v-if="course.courseRequestType === 'Site Visit'">
      <td>Need for Pre-Visit by the Instructor</td>
      <td>{{ course.needForPreVisit }}</td>
    </tr>

    <tr v-if="showVisualRep(course.courseRequestType)">
      <td>Specific Demonstrations/ Visual Representation Required</td>
      <td>{{ course.visualRep }}</td>
    </tr>

    <tr v-if="course.courseRequestType === 'Site Visit'">
      <td>Need to Visit the Office Trailer/Site Office</td>
      <td>{{ course.siteOffice }}</td>
    </tr>

    <tr>
      <td>Detailed Description of Course-Support Needed</td>
      <td>
        <span v-if="course.generalInfo">{{ course.generalInfo }}</span>
        <span v-else>N/A</span>
      </td>
    </tr>
    <tr
      v-if="
        showClassroomItems(course.courseRequestType) && course.classroomItems
      "
    >
      <td>Available items in the classroom</td>
      <td>{{ course.classroomItems }}</td>
    </tr>
    <tr
      v-if="
        showAssignmentConnected(course.courseRequestType) &&
        course.assigmentConnected
      "
    >
      <td>Student Assignment Connected to Course-Support</td>
      <td>{{ course.assigmentConnected }}</td>
    </tr>
    <tr v-if="showPriorKnowledge(course.courseRequestType)">
      <td>Students' Prior Knowledge About Topic Requiring Course-Support</td>
      <td>{{ course.priorKnowledge }}</td>
    </tr>
    <tr v-if="course.additionalInformation">
      <td>Link to Additional Course Information</td>
      <td>
        <span>{{ course.additionalInformation }}</span>
      </td>
    </tr>
    <tr v-if="isCapstoneTermProject(course.courseRequestType)">
      <td>Type of Project</td>
      <td>{{ course.typeProject }}</td>
    </tr>
    <tr v-if="isCapstoneTermProject(course.courseRequestType)">
      <td>Project Description</td>
      <td>{{ course.projectDescr }}</td>
    </tr>
    <tr>
      <td>Status</td>
      <td>{{ course.status }}</td>
    </tr>
    <tr>
      <td>Reason Delete</td>
      <td>{{ course.reasonDelete }}</td>
    </tr>
  </table>
</template>

<script setup lang="ts">
import { CourseRequest } from "@/types";
import {
  isCapstoneTermProject,
  showPriorKnowledge,
  showAssignmentConnected,
  showClassroomItems,
  showVisualRep,
} from "@/utils";

// eslint-disable-next-line no-undef
const props = defineProps<{
  course: CourseRequest;
}>();

console.log(props.course);

let courseDate = JSON.parse(props.course.date);
let courseTime = JSON.parse(props.course.time);
</script>

<style scoped>
table {
  width: fit-content;
  box-shadow: 0px 0px 10px 4px rgb(0 0 0 / 30%);
  border-radius: 6px;
  background: #fafafa;
  padding: 1rem;
  margin-top: 1rem;
  margin-left: auto;
  margin-right: auto;
}

thead td {
  text-align: center;
}

td {
  border: 2px solid #f0f0f0;
  border-collapse: collapse;
  padding: 0.2em 2em;
  font-size: larger;
  overflow-wrap: break-word;
}
</style>
