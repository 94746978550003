import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, unref as _unref, mergeProps as _mergeProps, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withKeys as _withKeys, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "card card-container" }
const _hoisted_2 = {
  key: 0,
  class: "form-group"
}
const _hoisted_3 = {
  key: 1,
  class: "form-group"
}
const _hoisted_4 = {
  key: 2,
  class: "text-align-center bold-text"
}
const _hoisted_5 = {
  key: 3,
  class: "form-group"
}
const _hoisted_6 = ["checked"]
const _hoisted_7 = {
  key: 4,
  class: "form-group"
}
const _hoisted_8 = ["checked"]
const _hoisted_9 = {
  key: 5,
  class: "text-align-center bold-text"
}
const _hoisted_10 = {
  key: 6,
  class: "form-group"
}
const _hoisted_11 = ["checked"]
const _hoisted_12 = {
  key: 7,
  class: "form-group"
}
const _hoisted_13 = ["checked"]
const _hoisted_14 = { key: 0 }
const _hoisted_15 = { class: "text-align-center mb-3" }

import { useForm, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import Multiselect from "@vueform/multiselect";
import { useAvailabilityStore } from "@/stores/AvailabilityStore";
import { onBeforeRouteLeave } from "vue-router";
import { useModal } from "vuestic-ui";
import {
  insDeliveryTypeOptions,
  academicLevelOptions,
  stateOptions,
  deptOptions,
  univOptions,
  displayLabels,
  validateOptAll,
  validateOptNoPref,
  showErrorToast,
} from "@/utils";
import { Availability } from "@/types";
import { useConfigStore } from "@/stores/ConfigStore";

// eslint-disable-next-line no-undef

export default /*@__PURE__*/_defineComponent({
  __name: 'CourseDetailsForm',
  props: {
    availability: {}
  },
  emits: ["previousStep", "nextStep"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
// eslint-disable-next-line no-undef
const emits = __emit;

const { confirm } = useModal();
const ConfigStore = useConfigStore();
const AvailabilityStore = useAvailabilityStore();

const academicLevelOptionsAdded = ["No preference", ...academicLevelOptions];
const deptOptionsAdded = ["No preference", ...deptOptions];
const stateOptionsAdded = ["All", ...stateOptions];
const univOptionsAdded = ["All", ...univOptions];
const availabilityFields = ConfigStore.config.filter(
  (el) => el.table === "availability"
);

const schema = yup.object().shape({
  fields: yup.array().default(availabilityFields),
  insDeliveryType: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "insDeliveryType")?.disabled,
    then: (schema) => schema.required("Instruction Delivery Mode is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  insState: yup
    .array()
    .of(yup.string())
    .when("fields", {
      is: (val) => !val.find((el) => el.name === "insState")?.disabled,
      then: (schema) =>
        schema
          .required("Location of institution is required!")
          .min(1, "Location of institution is required!"),
      otherwise: (schema) => schema.optional().nullable(),
    }),
  insName: yup.array().of(yup.string()).optional().min(0),
  academicLevel: yup
    .array()
    .of(yup.string())
    .when("fields", {
      is: (val) => !val.find((el) => el.name === "academicLevel")?.disabled,
      then: (schema) =>
        schema
          .required("Students' Academic Level is required!")
          .min(1, "Students' Academic Level is required!"),
      otherwise: (schema) => schema.optional().nullable(),
    }),
  studentDept: yup
    .array()
    .of(yup.string())
    .when("fields", {
      is: (val) => !val.find((el) => el.name === "studentDept")?.disabled,
      then: (schema) =>
        schema
          .required("Students' Department or Program of Study is required!")
          .min(1, "Students' Department or Program of Study is required!"),
      otherwise: (schema) => schema.optional().nullable(),
    }),
  others: yup.string().optional().nullable(),
});

let initialState = {
  subjectSpeak: [] as string[],
  insDeliveryType: "",
  others: "",
  insState: [] as string[],
  insName: [] as string[],
  academicLevel: [] as string[],
  studentDept: [] as string[],
  studentDept_text: "",
  phone: "",
  phoneNumber: "",
};

if (AvailabilityStore.status) {
  if (props.availability.studentDept?.includes("Others")) {
    initialState["studentDept_text"] =
      props.availability.studentDept.at(-1) || "";
    initialState.studentDept = props.availability.studentDept.slice(0, -1);
  } else {
    // eslint-disable-next-line vue/no-setup-props-destructure
    initialState.studentDept = props.availability.studentDept;
  }
}

let formValues = {
  insState: props.availability?.insState,
  insName: props.availability?.insName,
  others: props.availability?.others,
  academicLevel: props.availability?.academicLevel,
  studentDept: initialState.studentDept,
  studentDept_text: initialState.studentDept_text,
  insDeliveryType: props.availability?.insDeliveryType,
};

const { handleSubmit, errors, values, meta, validate, resetForm } = useForm({
  validationSchema: schema,
});

resetForm({
  values: formValues,
});

const checkDirty = async () => {
  if (meta.value.dirty) {
    const answer = await confirm({
      message:
        "Your changes on this page have not been saved. Kindly save your changes before leaving the page. Do you want to proceed without saving your changes?",
      okText: "Yes",
      cancelText: "No",
    });
    if (answer) return true;
    else return false;
  } else return true;
};

const saveCourseDetails = handleSubmit(
  (data) => {
    if (data.studentDept && data.studentDept.includes("Others")) {
      data.studentDept.push(data["studentDept_text"]);
      delete data["studentDept_text"];
    }
    emits("nextStep", data);
  },
  (errors) => {
    Object.values(errors.errors).forEach((error) => showErrorToast(error));
  }
);

const goToPreviousStep = async () => {
  const answer = await checkDirty();
  await console.log(answer);
  if (!answer) return false;
  emits("previousStep");
};

onBeforeRouteLeave(async (to, from) => {
  const answer = await checkDirty();
  await console.log(answer);
  if (!answer) return false;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("form", {
      onKeydown: _cache[10] || (_cache[10] = _withKeys(($event: any) => ($event.preventDefault()), ["enter"])),
      class: "mt-3 lg:mx-3"
    }, [
      _createElementVNode("div", _hoisted_1, [
        _cache[20] || (_cache[20] = _createElementVNode("div", { class: "bold-text ml-3 text-align-center" }, [
          _createTextVNode(" Please provide further details based on your earlier selections "),
          _createElementVNode("br"),
          _createElementVNode("br")
        ], -1)),
        _createElementVNode("div", null, [
          (
            !_unref(availabilityFields).find((el) => el.name === 'insDeliveryType')
              ?.disabled
          )
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _cache[12] || (_cache[12] = _createElementVNode("label", { for: "insDeliveryType" }, [
                  _createTextVNode("Instruction Delivery Mode"),
                  _createElementVNode("span", { class: "super font-color-red" }, "*")
                ], -1)),
                _createVNode(_unref(Field), {
                  modelValue: _unref(values).insDeliveryType,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(values).insDeliveryType) = $event)),
                  name: "insDeliveryType",
                  type: "text",
                  class: "dropdown"
                }, {
                  default: _withCtx(({ field }) => [
                    _createVNode(_unref(Multiselect), _mergeProps(field, {
                      modelValue: _unref(values).insDeliveryType,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(values).insDeliveryType) = $event)),
                      options: _unref(insDeliveryTypeOptions),
                      mode: "single",
                      searchable: true
                    }), null, 16, ["modelValue", "options"])
                  ]),
                  _: 1
                }, 8, ["modelValue"]),
                _createVNode(_unref(ErrorMessage), {
                  name: "insDeliveryType",
                  class: "error-feedback"
                })
              ]))
            : _createCommentVNode("", true),
          (
            !_unref(availabilityFields).find((el) => el.name === 'insDeliveryType')
              ?.disabled
          )
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _cache[13] || (_cache[13] = _createElementVNode("label", { for: "others" }, "Other Comment/Information", -1)),
                _createVNode(_unref(Field), {
                  name: "others",
                  type: "text",
                  class: "form-control"
                }),
                _createVNode(_unref(ErrorMessage), {
                  name: "others",
                  class: "error-feedback"
                })
              ]))
            : _createCommentVNode("", true),
          _cache[18] || (_cache[18] = _createElementVNode("br", null, null, -1)),
          (
            !_unref(availabilityFields).find((el) => el.name === 'insState')
              ?.disabled &&
            !_unref(availabilityFields).find((el) => el.name === 'insName')?.disabled
          )
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, " Preference Definition (Institution-related) "))
            : _createCommentVNode("", true),
          (
            !_unref(availabilityFields).find((el) => el.name === 'insState')?.disabled
          )
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _cache[14] || (_cache[14] = _createElementVNode("label", { for: "insState" }, [
                  _createTextVNode("Location of Institution"),
                  _createElementVNode("span", { class: "super font-color-red" }, "*")
                ], -1)),
                _createVNode(_unref(Field), {
                  modelValue: _unref(values).insState,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_unref(values).insState) = $event)),
                  name: "insState",
                  type: "text",
                  class: "form-control"
                }, {
                  default: _withCtx(({ field }) => [
                    _createVNode(_unref(Multiselect), _mergeProps(field, {
                      modelValue: _unref(values).insState,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(values).insState) = $event)),
                      options: stateOptionsAdded,
                      mode: "tags",
                      placeholder: "Select State",
                      multipleLabel: _unref(displayLabels),
                      searchable: true,
                      hideSelected: false,
                      closeOnSelect: false,
                      closeOnDeselect: false,
                      onChange: _unref(validateOptAll)
                    }), {
                      option: _withCtx(({ option }) => [
                        _createElementVNode("input", {
                          type: "checkbox",
                          class: "input-pointer",
                          checked: _unref(values).insState?.includes(option.value)
                        }, null, 8, _hoisted_6),
                        _createTextVNode("   " + _toDisplayString(option.label), 1)
                      ]),
                      _: 2
                    }, 1040, ["modelValue", "multipleLabel", "onChange"])
                  ]),
                  _: 1
                }, 8, ["modelValue"]),
                _createVNode(_unref(ErrorMessage), {
                  name: "insState",
                  class: "error-feedback"
                })
              ]))
            : _createCommentVNode("", true),
          (
            !_unref(availabilityFields).find((el) => el.name === 'insName')?.disabled
          )
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _cache[15] || (_cache[15] = _createElementVNode("label", { for: "insName" }, "Name of Institution", -1)),
                _createVNode(_unref(Field), {
                  modelValue: _unref(values).insName,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_unref(values).insName) = $event)),
                  name: "insName",
                  type: "text",
                  class: "form-control"
                }, {
                  default: _withCtx(({ field }) => [
                    _createVNode(_unref(Multiselect), _mergeProps(field, {
                      modelValue: _unref(values).insName,
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_unref(values).insName) = $event)),
                      options: univOptionsAdded,
                      mode: "tags",
                      placeholder: "Select Institute",
                      multipleLabel: _unref(displayLabels),
                      searchable: true,
                      hideSelected: false,
                      closeOnSelect: false,
                      closeOnDeselect: false,
                      onChange: _unref(validateOptAll)
                    }), {
                      option: _withCtx(({ option }) => [
                        _createElementVNode("input", {
                          type: "checkbox",
                          class: "input-pointer",
                          checked: _unref(values).insName?.includes(option.value)
                        }, null, 8, _hoisted_8),
                        _createTextVNode("   " + _toDisplayString(option.label), 1)
                      ]),
                      _: 2
                    }, 1040, ["modelValue", "multipleLabel", "onChange"])
                  ]),
                  _: 1
                }, 8, ["modelValue"]),
                _createVNode(_unref(ErrorMessage), {
                  name: "insName",
                  class: "error-feedback"
                })
              ]))
            : _createCommentVNode("", true),
          _cache[19] || (_cache[19] = _createElementVNode("br", null, null, -1)),
          (
            !_unref(availabilityFields).find((el) => el.name === 'academicLevel')
              ?.disabled &&
            !_unref(availabilityFields).find((el) => el.name === 'studentDept')
              ?.disabled
          )
            ? (_openBlock(), _createElementBlock("div", _hoisted_9, " Preference Definition (Students-related) "))
            : _createCommentVNode("", true),
          (
            !_unref(availabilityFields).find((el) => el.name === 'academicLevel')
              ?.disabled
          )
            ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                _cache[16] || (_cache[16] = _createElementVNode("label", { for: "academicLevel" }, [
                  _createTextVNode("Students' Academic Level"),
                  _createElementVNode("span", { class: "super font-color-red" }, "*")
                ], -1)),
                _createVNode(_unref(Field), {
                  modelValue: _unref(values).academicLevel,
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_unref(values).academicLevel) = $event)),
                  name: "academicLevel",
                  type: "text",
                  class: "form-control"
                }, {
                  default: _withCtx(({ field }) => [
                    _createVNode(_unref(Multiselect), _mergeProps(field, {
                      modelValue: _unref(values).academicLevel,
                      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_unref(values).academicLevel) = $event)),
                      options: academicLevelOptionsAdded,
                      mode: "tags",
                      multipleLabel: _unref(displayLabels),
                      searchable: true,
                      hideSelected: false,
                      closeOnSelect: false,
                      closeOnDeselect: false,
                      onChange: _unref(validateOptNoPref)
                    }), {
                      option: _withCtx(({ option }) => [
                        _createElementVNode("input", {
                          type: "checkbox",
                          class: "input-pointer",
                          checked: _unref(values).academicLevel?.includes(option.value)
                        }, null, 8, _hoisted_11),
                        _createTextVNode("   " + _toDisplayString(option.label), 1)
                      ]),
                      _: 2
                    }, 1040, ["modelValue", "multipleLabel", "onChange"])
                  ]),
                  _: 1
                }, 8, ["modelValue"]),
                _createVNode(_unref(ErrorMessage), {
                  name: "academicLevel",
                  class: "error-feedback"
                })
              ]))
            : _createCommentVNode("", true),
          (
            !_unref(availabilityFields).find((el) => el.name === 'studentDept')
              ?.disabled
          )
            ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                _cache[17] || (_cache[17] = _createElementVNode("label", { for: "studentDept" }, [
                  _createTextVNode(" Students' Department or Program of Study"),
                  _createElementVNode("span", { class: "super font-color-red" }, "*")
                ], -1)),
                _createVNode(_unref(Field), {
                  modelValue: _unref(values).studentDept,
                  "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_unref(values).studentDept) = $event)),
                  name: "studentDept",
                  type: "text",
                  class: "form-control"
                }, {
                  default: _withCtx(({ field }) => [
                    _createVNode(_unref(Multiselect), _mergeProps(field, {
                      modelValue: _unref(values).studentDept,
                      "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_unref(values).studentDept) = $event)),
                      options: deptOptionsAdded,
                      mode: "tags",
                      multipleLabel: _unref(displayLabels),
                      searchable: true,
                      hideSelected: false,
                      closeOnSelect: false,
                      closeOnDeselect: false,
                      onChange: _unref(validateOptNoPref)
                    }), {
                      option: _withCtx(({ option }) => [
                        (_unref(values).studentDept)
                          ? (_openBlock(), _createElementBlock("input", {
                              key: 0,
                              type: "checkbox",
                              class: "input-pointer",
                              checked: _unref(values).studentDept.includes(option.value)
                            }, null, 8, _hoisted_13))
                          : _createCommentVNode("", true),
                        _createTextVNode("   " + _toDisplayString(option.label), 1)
                      ]),
                      _: 2
                    }, 1040, ["modelValue", "multipleLabel", "onChange"])
                  ]),
                  _: 1
                }, 8, ["modelValue"]),
                (_unref(values).studentDept && _unref(values).studentDept.includes('Others'))
                  ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                      _createVNode(_unref(Field), {
                        name: "studentDept_text",
                        type: "text",
                        placeholder: "Please Specify",
                        class: "form-control"
                      })
                    ]))
                  : _createCommentVNode("", true),
                _createVNode(_unref(ErrorMessage), {
                  name: "studentDept",
                  class: "error-feedback"
                })
              ]))
            : _createCommentVNode("", true)
        ])
      ])
    ], 32),
    _createElementVNode("div", _hoisted_15, [
      _createElementVNode("button", {
        class: "btn btn-secondary ml-2",
        type: "button",
        onClick: goToPreviousStep
      }, " Previous "),
      _createElementVNode("button", {
        class: "btn btn-primary ml-2",
        type: "button",
        onClick: _cache[11] || (_cache[11] = 
//@ts-ignore
(...args) => (_unref(saveCourseDetails) && _unref(saveCourseDetails)(...args)))
      }, " Next ")
    ])
  ], 64))
}
}

})