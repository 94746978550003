<template>
  <div class="grid-container">
    <app-sidebar />
    <div class="min-height mt-3 mr-5">
      <div class="back-grid">
        <back-button></back-button>
        <h2 style="text-align: center">Course-Support Request Information</h2>
      </div>
      <h6 class="text-align-center mb-3">
        The page contains the information provided by the instructor regarding
        the course-support request
      </h6>
      <div
        v-if="
          currentUser.roles === 'INDUSTRYPRACTITIONER' &&
          course?.course_request_x_practitioners &&
          course?.course_request_x_practitioners[0]?.status === '20'
        "
      >
        <h5 class="text-align-center mb-3" style="color: #1a73e8">
          {{
            course.user.title +
            " " +
            course.user.firstName +
            " " +
            course.user.lastName +
            " (" +
            course.user.email
          }}
          <span v-if="course.user.phone === 'True'">
            {{ "/ " + course.user.phoneNumber + ")" }}
          </span>
          <span v-else>)</span>
          at {{ course.user.nameOrg }} is requesting that you provide a
          {{ course.courseRequestType }} session
        </h5>
      </div>
      <div
        v-else-if="
          course?.course_request_x_practitioners &&
          (course?.course_request_x_practitioners[0]?.status === '10' ||
            course?.course_request_x_practitioners[0]?.status === '60')
        "
      >
        <h5 class="text-align-center mb-3" style="color: #1a73e8">
          <font-awesome-icon
            icon="fa-solid fa-check"
            class="font-larger"
            style="color: #0bda51; font-size: xx-large"
          />
          You have accepted this request
        </h5>
      </div>
      <div
        v-else-if="
          course?.course_request_x_practitioners &&
          course?.course_request_x_practitioners[0]?.status === '90'
        "
      >
        <h5 class="text-align-center mb-3" style="color: #1a73e8">
          <font-awesome-icon
            icon="fa-solid fa-xmark"
            class="font-larger"
            style="color: #e71921; font-size: xx-large"
          />&nbsp; You have declined this request
        </h5>
      </div>
      <div
        v-if="
          currentUser.roles === 'INDUSTRYPRACTITIONER' &&
          course?.course_request_x_practitioners[0]?.status === '40'
        "
      >
        <h5 class="text-align-center mb-3" style="color: #1a73e8">
          <font-awesome-icon
            icon="fa-solid fa-xmark"
            class="font-larger"
            style="color: #e71921; font-size: xx-large"
          />&nbsp; This request has been withdrawn.
        </h5>
      </div>
      <div style="display: flex; justify-content: center">
        <table>
          <tr>
            <td>Course Code</td>
            <td>
              {{ course.courseCode }}
            </td>
          </tr>
          <tr>
            <td>Course Title</td>
            <td>
              {{ course.courseTitle }}
            </td>
          </tr>
          <tr>
            <td>Course-Support Request Type</td>
            <td>
              {{ course.courseRequestType }}
            </td>
          </tr>
          <tr>
            <td>Instruction Delivery Mode</td>
            <td>
              {{ course.insDeliveryType }}
            </td>
          </tr>
          <tr>
            <td>
              <span v-if="course.courseRequestType !== 'Mentor for Project'">
                Class Size
              </span>
              <span v-else> Size of student group to mentor </span>
            </td>
            <td>{{ course.classSize }}</td>
          </tr>
          <tr>
            <td>Academic Level</td>
            <td>{{ JSON.parse(course.academicLevel).join(", ") }}</td>
          </tr>
          <tr>
            <td>Students' Department or Program of Study</td>
            <td>{{ JSON.parse(course.studentDept).join(", ") }}</td>
          </tr>

          <tr>
            <td>When the Course-Support is Needed</td>
            <td>
              <span v-for="(item, index) in courseDate" :key="index">
                {{ new Date(item + "T" + courseTime[index]).toLocaleString() }}
                <span v-if="index != courseDate.length - 1"> OR </span>
              </span>
            </td>
          </tr>

          <tr v-if="course.courseRequestType === 'Mentor for Project'">
            <td>When the Course-Support ends</td>
            <td>
              <span>
                {{ formatDate(course.endDate) }}
              </span>
            </td>
          </tr>

          <tr>
            <td>Location of Institution</td>
            <td>
              {{ course.location }}
              {{ course.city + ", " + course.state }}
            </td>
          </tr>

          <tr>
            <td>Duration of Course-Support</td>
            <td>{{ course.duration }} minutes</td>
          </tr>

          <tr>
            <td>
              <span v-if="course.courseRequestType !== 'Mentor for Project'"
                >Number/Frequency of Course-Support</span
              >
              <span v-else>Frequency of Meeting with Students</span>
            </td>
            <td>{{ course.noOfFreq }}</td>
          </tr>

          <tr v-if="course.courseRequestType === 'Mentor for Project'">
            <td>Days of Meeting with Students</td>
            <td>{{ course.daysOfMeeting }}</td>
          </tr>

          <tr>
            <td>Topic Requiring Course-Support</td>
            <td>{{ course.topic }}</td>
          </tr>

          <tr>
            <td>Learning Objective/Expected Outcome</td>
            <td>{{ JSON.parse(course.learning).join(", ") }}</td>
          </tr>
          <tr v-if="course.courseRequestType === 'Site Visit'">
            <td>Need for Pre-Visit by the Instructor</td>
            <td>{{ course.needForPreVisit }}</td>
          </tr>

          <tr v-if="showVisualRep(course.courseRequestType)">
            <td>Specific Demonstrations/ Visual Representation Required</td>
            <td>{{ course.visualRep }}</td>
          </tr>

          <tr v-if="course.courseRequestType === 'Site Visit'">
            <td>Need to Visit the Office Trailer/Site Office</td>
            <td>{{ course.siteOffice }}</td>
          </tr>

          <tr>
            <td>Detailed Description of Course-Support Needed</td>
            <td>
              <span v-if="course.generalInfo">{{ course.generalInfo }}</span>
              <span v-else>N/A</span>
            </td>
          </tr>
          <tr
            v-if="
              showClassroomItems(course.courseRequestType) &&
              course.classroomItems
            "
          >
            <td>Available items in the classroom</td>
            <td>{{ course.classroomItems }}</td>
          </tr>
          <tr
            v-if="
              showAssignmentConnected(course.courseRequestType) &&
              course.assigmentConnected
            "
          >
            <td>Student Assignment Connected to Course-Support</td>
            <td>{{ course.assigmentConnected }}</td>
          </tr>
          <tr v-if="showPriorKnowledge(course.courseRequestType)">
            <td>
              Students' Prior Knowledge About Topic Requiring Course-Support
            </td>
            <td>{{ course.priorKnowledge }}</td>
          </tr>
          <tr v-if="course.additionalInformation">
            <td>Link to Additional Course Information</td>
            <td>
              <span>{{ course.additionalInformation }}</span>
            </td>
          </tr>
          <tr v-if="isCapstoneTermProject(course.courseRequestType)">
            <td>Type of Project</td>
            <td>{{ course.typeProject }}</td>
          </tr>
          <tr v-if="isCapstoneTermProject(course.courseRequestType)">
            <td>Project Description</td>
            <td>{{ course.projectDescr }}</td>
          </tr>
        </table>
      </div>
      <h3 class="text-align-center my-4">Instructor Details</h3>
      <div style="display: flex; justify-content: center">
        <table
          v-if="Object.keys(instructor || {}).length > 0"
          class="image-table"
        >
          <tr>
            <td rowspan="4" class="recommendation-img-card"></td>
            <td class="bold-text">Name</td>
            <td>
              {{ instructor.user.firstName + " " + instructor.user.lastName }}
            </td>
          </tr>

          <tr>
            <td>Title</td>
            <td>{{ instructor.user.title }}</td>
          </tr>

          <tr>
            <td>Department/Program</td>
            <td>{{ instructor.department }}</td>
          </tr>

          <tr>
            <td>Name of Institution</td>
            <td>{{ instructor.user.nameOrg }}</td>
          </tr>
          <tr
            v-if="
              course?.course_request_x_practitioners &&
              (course?.course_request_x_practitioners[0]?.status === '10' ||
                course?.course_request_x_practitioners[0]?.status === '20' ||
                course?.course_request_x_practitioners[0]?.status === '60')
            "
            style="font-style: italic"
          >
            <td class="rowspan-comp"></td>
            <td class="bold-text">Email</td>
            <td>
              <a :href="'mailto:' + instructor?.user?.email">
                {{ instructor?.user?.email }}
              </a>
            </td>
          </tr>
          <tr
            v-if="
              course?.course_request_x_practitioners &&
              (course?.course_request_x_practitioners[0]?.status === '10' ||
                course?.course_request_x_practitioners[0]?.status === '20' ||
                course?.course_request_x_practitioners[0]?.status === '60') &&
              instructor.user.phone === 'True'
            "
            style="font-style: italic"
          >
            <td class="rowspan-comp"></td>
            <td class="bold-text">Phone Number</td>
            <td>{{ instructor.user.phoneNumber }}</td>
          </tr>
        </table>
      </div>
      <div class="flex justify-space-between my-3">
        <div
          style="width: 100%"
          class="text-align-center"
          v-if="
            course?.course_request_x_practitioners &&
            course?.course_request_x_practitioners[0]?.status === '20' &&
            courseDate.some(
              (date) =>
                new Date(date) >= new Date().setDate(new Date().getDate() - 1)
            )
          "
        >
          <button class="btn btn-danger" @click="respondCourse('90')">
            Decline
          </button>
        </div>
        <div v-else></div>
        <div
          style="width: 100%"
          class="text-align-center"
          v-if="
            course?.course_request_x_practitioners &&
            course?.course_request_x_practitioners[0]?.status !== '10' &&
            course?.course_request_x_practitioners[0]?.status !== '20' &&
            course?.course_request_x_practitioners[0]?.status !== '40' &&
            course?.course_request_x_practitioners[0]?.status !== '60' &&
            course?.course_request_x_practitioners[0]?.status !== '90' &&
            currentUser.roles === 'INDUSTRYPRACTITIONER' &&
            courseDate.some(
              (date) =>
                new Date(date) >= new Date().setDate(new Date().getDate() - 1)
            )
          "
        >
          <button class="btn btn-primary" @click="respondCourse('10')">
            Respond to Request
          </button>
        </div>
        <div
          style="width: 100%"
          class="text-align-center"
          v-if="
            course?.course_request_x_practitioners &&
            course?.course_request_x_practitioners[0]?.status === '20' &&
            currentUser.roles === 'INDUSTRYPRACTITIONER' &&
            courseDate.some(
              (date) =>
                new Date(date) >= new Date().setDate(new Date().getDate() - 1)
            )
          "
        >
          <button class="btn btn-success" @click="respondCourse('60')">
            Accept
          </button>
        </div>
      </div>
    </div>
  </div>
  <Dialog
    v-model:visible="visible"
    modal
    :draggable="false"
    :header="getHeader()"
    :style="{ width: '45vw' }"
    :breakpoints="{ '768px': '90vw' }"
  >
    <Form
      @submit="handleSubmission"
      :validation-schema="schema"
      v-slot="{ meta }"
    >
      <div class="form-group" v-if="currStatus == '60' || currStatus == '10'">
        <span v-if="currStatus == '10'" style="color: #1a73e8" class="bold-text"
          >Only respond to this request if you are willing to provide it.</span
        >
        <div class="form-group">
          <label for="courseDateTime"
            >Time Preference<span class="super font-color-red">*</span></label
          >
          <Field
            as="select"
            name="courseDateTime"
            v-model="courseDateTime"
            class="dropdown"
          >
            <option
              v-for="(item, index) in courseDate"
              :key="index"
              :value="index"
            >
              {{ item }}@{{ courseTime[index] }}
            </option>
          </Field>
        </div>
        <div
          style="display: flex; justify-content: space-between; height: 100%"
        >
          <label for="reason"
            >Please provide any message you would like to send to
            instructor</label
          >
        </div>
        <Field
          name="reason"
          as="textarea"
          type="text"
          class="form-control"
          style="height: 200px"
          v-if="currStatus == '60'"
        />
        <Field
          name="reason"
          type="text"
          as="textarea"
          class="form-control"
          style="height: 200px"
          v-else-if="currStatus == '10'"
          :value="getCustomMsg()"
        />
        <ErrorMessage name="reason" class="error-feedback" />
      </div>
      <div class="form-group" v-else-if="currStatus == '90'">
        <div
          style="display: flex; justify-content: space-between; height: 100%"
        >
          <label for="reason">Please provide a reason for declining</label>
        </div>
        <Field
          name="reason"
          type="text"
          class="form-control"
          as="textarea"
          style="height: 200px"
        />
        <ErrorMessage name="reason" class="error-feedback" />
      </div>
      <br />
      <div style="width: 100%; text-align: center">
        <div v-if="currStatus == '10'" class="bold-text font-color-banner">
          Only respond to this request if you are willing to provide it.
        </div>
        <button
          class="btn btn-primary btn-block"
          :disabled="loading || !meta.valid"
        >
          <span
            v-show="loading"
            class="spinner-border spinner-border-sm"
          ></span>
          <span v-if="currStatus == '60'">Accept</span>
          <span v-else-if="currStatus == '90'">Decline</span>
          <span v-else-if="currStatus == '10'">Send Reply to Instructor</span>
        </button>
      </div>
    </Form>
  </Dialog>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import AppSidebar from "@/components/user/AppSidebar.vue";
import BackButton from "@/components/user/BackButton.vue";
import Dialog from "primevue/dialog";
import { useAuthStore } from "@/stores/AuthStore";
import { useCourseStore } from "@/stores/CourseStore";
import PractitionerService from "@/services/practitioner.service.js";
import {
  showToast,
  showVisualRep,
  showClassroomItems,
  showPriorKnowledge,
  showAssignmentConnected,
  isCapstoneTermProject,
  websiteURL,
  showErrorToast,
  formatDate,
} from "@/utils";
import { useRouter } from "vue-router";
import * as yup from "yup";

const router = useRouter();
const AuthStore = useAuthStore();
const CourseStore = useCourseStore();

const currentUser = AuthStore.auth;
let course = CourseStore.course;
let courseDate = ref(JSON.parse(course.date));
let courseTime = JSON.parse(course.time);

let loading = ref(false);
let successful = false;
let message = ref("");
let currStatus = ref("");
let visible = ref(false);
let courseDateTime = ref(-1);

const selectCourse = (course) => {
  loading.value = true;
  PractitionerService.selectCourse(course).then(
    (response) => {
      loading.value = false;
      visible.value = false;
      showToast("Message sent successfully to instructor.");
      router.push({ name: "home" });
    },
    (error) => {
      console.log("Error");
      message.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};

const schema = yup.object().shape({
  status: yup.string().optional(),
  reason: yup.string().when("status", {
    is: () => currStatus.value === "90",
    then: (schema) => schema.required("Reason is required!"),
    otherwise: (schema) => schema.optional(),
  }),
  courseDateTime: yup.string().when("status", {
    is: () => currStatus.value === "10" || currStatus.value === "60",
    then: (schema) => schema.required("Time Preference is required!"),
    otherwise: (schema) => schema.optional(),
  }),
});

const getHeader = () => {
  if (currStatus.value === "60") {
    return "Provide Course-Support Request";
  } else if (currStatus.value === "90") {
    return "Decline Course-Support Request";
  } else if (currStatus.value === "10") {
    return "Provide Course-Support Request";
  }
};

const getCustomMsg = () => {
  if (currentUser.phone === "True") {
    return `I, ${currentUser.firstName} ${currentUser.lastName} is willing to provide the course-support (${course.courseRequestType}) you requested.\nYou can reach me at ${currentUser.email} or ${currentUser.phoneNumber}.`;
  } else {
    return `I, ${currentUser.firstName} ${currentUser.lastName} is willing to provide the course-support (${course.courseRequestType}) you requested.\nYou can reach me at ${currentUser.email}.`;
  }
};
const respondCourse = (status) => {
  currStatus.value = status;
  if (courseDate.value && courseDate.value.length === 1)
    courseDateTime.value = 0;
  visible.value = true;
};

const handleSubmission = (data) => {
  course["reason"] = data.reason;
  if (currStatus.value === "60" || currStatus.value === "10") {
    if (courseDateTime.value === -1) {
      showErrorToast("Time Preference is required!");
      return;
    } else {
      course["selectedDateIndex"] = courseDateTime.value;
      course["selectedDate"] = new Date(
        courseDate.value[courseDateTime.value] +
          "T" +
          courseTime[courseDateTime.value]
      );
    }
  }
  if (currStatus.value === "10") {
    selectCourse(course);
  } else {
    loading.value = true;
    PractitionerService.respondCourseReq(course, currStatus.value).then(
      () => {
        loading.value = false;
        visible.value = false;
        showToast("Message sent successfully to instructor.");
        router.push({ name: "home" });
      },
      (error) => {
        console.log("Error");
        loading.value = false;
        message.value =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  }
};

let instructor = ref({});
let image = ref("");

const getInstructorProfile = (instructorUserId) => {
  loading.value = true;
  PractitionerService.getInstructorProfile(instructorUserId).then(
    (response) => {
      instructor.value = response.data;
      image.value =
        "url('" +
        websiteURL +
        "/profile_picture/" +
        instructor.value.user.fileName +
        "')";
      loading.value = false;
    },
    (error) => {
      loading.value = false;
      successful = false;
      message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(message);
    }
  );
};
getInstructorProfile(course.userId);
</script>

<style scoped>
table {
  width: fit-content;
  box-shadow: 0px 0px 10px 4px rgb(0 0 0 / 30%);
  border-radius: 6px;
  background: #fafafa;
}

thead td {
  text-align: center;
}

td {
  border: 2px solid #f0f0f0;
  border-collapse: collapse;
  padding: 0.2em 2em;
  font-size: larger;
  overflow-wrap: break-word;
}
.recommendation-img-card {
  background-image: v-bind(image);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

@media screen and (max-width: 768px) {
  .recommendation-img-card {
    display: none;
  }
  .image-table .rowspan-comp {
    display: none;
  }
  td {
    padding: 0.2em 0.25em;
    font-size: small;
    overflow-wrap: anywhere;
  }
}
</style>
