import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, unref as _unref, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, isRef as _isRef, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createSlots as _createSlots, renderList as _renderList, Fragment as _Fragment, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = { class: "m-3 min-height header-space" }
const _hoisted_2 = { class: "back-grid" }
const _hoisted_3 = { class: "text-align-center bold-text m-2 mb-5" }
const _hoisted_4 = { class: "flex flex-direction-row justify-space-between my-3" }
const _hoisted_5 = ["disabled"]
const _hoisted_6 = { class: "spinner-border spinner-border-sm" }
const _hoisted_7 = { class: "spinner-border spinner-border-sm" }
const _hoisted_8 = { class: "spinner-border spinner-border-sm" }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { key: 1 }
const _hoisted_11 = { key: 0 }
const _hoisted_12 = { key: 1 }
const _hoisted_13 = { key: 0 }
const _hoisted_14 = { key: 1 }
const _hoisted_15 = { key: 0 }
const _hoisted_16 = { key: 1 }
const _hoisted_17 = { key: 0 }
const _hoisted_18 = { key: 1 }
const _hoisted_19 = { key: 0 }
const _hoisted_20 = { key: 1 }
const _hoisted_21 = { key: 0 }
const _hoisted_22 = { key: 1 }
const _hoisted_23 = { key: 0 }
const _hoisted_24 = { key: 0 }
const _hoisted_25 = { key: 1 }
const _hoisted_26 = { key: 2 }
const _hoisted_27 = { key: 3 }
const _hoisted_28 = { key: 1 }
const _hoisted_29 = { key: 0 }
const _hoisted_30 = { key: 1 }
const _hoisted_31 = { key: 2 }
const _hoisted_32 = { key: 0 }
const _hoisted_33 = { key: 1 }
const _hoisted_34 = { key: 2 }
const _hoisted_35 = { key: 3 }
const _hoisted_36 = { key: 0 }
const _hoisted_37 = { key: 3 }
const _hoisted_38 = { key: 4 }
const _hoisted_39 = { key: 5 }
const _hoisted_40 = { key: 6 }
const _hoisted_41 = { key: 7 }
const _hoisted_42 = ["colspan"]
const _hoisted_43 = { class: "table--pagination" }
const _hoisted_44 = { class: "flex flex-direction-row" }
const _hoisted_45 = { class: "flex-shrink-1 flex-grow-0" }
const _hoisted_46 = { class: "ml-3 mt-2 bold-text dark-text-color" }
const _hoisted_47 = {
  class: "mt-2",
  style: {"font-size":"larger"},
  autofocus: ""
}
const _hoisted_48 = { class: "flex flex-direction-row ml-2" }
const _hoisted_49 = { class: "flex-shrink-1 flex-grow-0" }
const _hoisted_50 = ["src"]
const _hoisted_51 = {
  key: 1,
  id: "profile-img",
  src: "//ssl.gstatic.com/accounts/ui/avatar_2x.png",
  class: "profile-icon-card"
}
const _hoisted_52 = { class: "mb-2" }
const _hoisted_53 = { class: "ml-3 pl-3 mt-1 card comment-card" }
const _hoisted_54 = { class: "bold-text dark-text-color" }
const _hoisted_55 = { class: "ml-3 pl-1 small" }
const _hoisted_56 = { class: "flex flex-direction-row" }
const _hoisted_57 = { class: "flex-shrink-1 flex-grow-0" }
const _hoisted_58 = {
  key: 1,
  class: "ml-3 mt-2"
}
const _hoisted_59 = { class: "flex flex-direction-row" }
const _hoisted_60 = { key: 0 }
const _hoisted_61 = { key: 1 }
const _hoisted_62 = { key: 2 }
const _hoisted_63 = { class: "table" }
const _hoisted_64 = { key: 0 }
const _hoisted_65 = { key: 3 }
const _hoisted_66 = { key: 4 }
const _hoisted_67 = { key: 5 }
const _hoisted_68 = { key: 6 }
const _hoisted_69 = { key: 7 }
const _hoisted_70 = { class: "min-h" }
const _hoisted_71 = {
  key: 0,
  class: "text-align-center"
}
const _hoisted_72 = { key: 8 }
const _hoisted_73 = { class: "mx-3 mt-2" }
const _hoisted_74 = { class: "mx-3 mt-2" }
const _hoisted_75 = { class: "form-column" }
const _hoisted_76 = { class: "flex-grow-1 flex-shrink-1" }
const _hoisted_77 = { class: "flex-grow-1 flex-shrink-1" }
const _hoisted_78 = { class: "form-group" }
const _hoisted_79 = { class: "form-group text-align-center" }
const _hoisted_80 = ["disabled"]
const _hoisted_81 = { class: "spinner-border spinner-border-sm" }
const _hoisted_82 = { key: 0 }
const _hoisted_83 = { key: 1 }
const _hoisted_84 = { class: "mx-3 mt-2" }
const _hoisted_85 = { class: "mx-3 mt-2" }
const _hoisted_86 = { class: "form-group" }
const _hoisted_87 = { class: "form-group text-align-center" }
const _hoisted_88 = ["disabled"]
const _hoisted_89 = { class: "spinner-border spinner-border-sm" }

import { ref, watch, computed } from "vue";
import BackButton from "@/components/user/BackButton.vue";
import ProfileIcon from "@/components/user/ProfileIcon.vue";
import UserTable from "@/components/admin/UserTable.vue";
import CourseRequestTable from "@/components/admin/CourseRequestTable.vue";
import InstructorProfileTable from "@/components/admin/InstructorProfileTable.vue";
import PractitionerProfileTable from "@/components/admin/PractitionerProfileTable.vue";
import PractitionerAvailabilityTable from "@/components/admin/PractitionerAvailability.vue";
import ResourcesGalleryItem from "@/components/ResourcesGalleryItem.vue";
import FAQForm from "@/components/admin/FAQForm.vue";
import OrganizationForm from "@/components/admin/OrganizationForm.vue";
import { Field, ErrorMessage, Form } from "vee-validate";
import * as yup from "yup";
import Dialog from "primevue/dialog";
import { useModal } from "vuestic-ui";
import AdminService from "@/services/admin.service.js";
import { useRoute, useRouter } from "vue-router";
import {
  UserFields,
  InstructorFields,
  IndustryPractitionerFields,
  CourseRequestFields,
  AvailabilityFields,
  UploadFields,
  FeedbackFields,
  ClassFields,
  ResourceRequestFields,
  ContactUsFields,
  FAQFields,
  websiteURL,
  getTimeDiff,
  isJSONObject,
  showToast,
  valid,
  OrganizationFields,
  DashboardAccessRequestFields,
  DashboardFeedbackFields,
} from "@/utils";
import UserService from "@/services/user.service.js";
import {
  User,
  Feedback,
  FeedbackComment,
  Course,
  CourseRequest,
  Profile,
  Upload,
  Availability,
  FAQ,
  ResourceRequest,
  Organization,
  ContactUs,
} from "@/types";
import ContactUsTable from "@/components/admin/ContactUsTable.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'DatabaseTableView',
  setup(__props) {

const route = useRoute();
const router = useRouter();
const { confirm } = useModal();

let loading = ref(false);
let successful = ref(false);
let message = "";
let items = ref([]);
let fields = ref([{}]);
let currentPage = ref(1);
let currFeedback = ref({} as Feedback);
let feedbackComments = ref([] as FeedbackComment[]);
let isVisible = ref(false);
let user = ref({} as User);
let course = ref({} as Course);
let courseRequest = ref({} as CourseRequest);
let userProfile = ref({} as Profile);
let userAvailability = ref({} as Availability);
let resourceRequest = ref({} as ResourceRequest);
let upload = ref({} as Upload);
let faq = ref({} as FAQ);
let contactUs = ref({} as ContactUs);
let organization = ref({} as Organization);
let emptyFaq = ref({} as FAQ);
let isViewClassVisible = ref(false);
let editable = ref(false);
let isUserAccountDisableVisible = ref(false);
let isFAQFormVisible = ref(false);
let isOrganizationFormVisible = ref(false);
let isDashboardAccessFormVisible = ref(false);
let isAssignFormVisible = ref(false);
let userAction = ref("");
let assignFormHeader = ref("");

const perPage = ref(10);
const tableHeading = ref("");
let dialogHeading = ref("");
const selectable = ref(false);
const selectedItems = ref([]);
const filtered = ref([]);

let formValues = {
  email: "",
};

const schema = yup.object().shape({
  email: yup
    .string()
    .required("Email is required!")
    .email("Email is invalid!")
    .max(50, "Must be maximum 50 characters!"),
});

const fetchTable = (tableName) => {
  switch (tableName) {
    case "Users":
      getUsers();
      selectable.value = false;
      tableHeading.value = tableName.toString();
      break;
    case "Instructors":
      getInstructors();
      selectable.value = false;
      tableHeading.value = tableName.toString();
      break;
    case "Classes":
      getClasses();
      selectable.value = false;
      tableHeading.value = tableName.toString();
      break;
    case "CourseRequests":
      getCourseRequests();
      selectable.value = false;
      tableHeading.value = tableName.toString();
      break;
    case "ResourceRequests":
      getResourceRequests();
      selectable.value = false;
      tableHeading.value = tableName.toString();
      break;
    case "Practitioners":
      getIndustryPractitioners();
      selectable.value = false;
      tableHeading.value = tableName.toString();
      break;
    case "Availability":
      getAvailability();
      selectable.value = false;
      tableHeading.value = tableName.toString();
      break;
    case "Uploads":
      getUploads();
      selectable.value = false;
      tableHeading.value = tableName.toString();
      break;
    case "Feedback":
      getFeedback();
      selectable.value = true;
      tableHeading.value = tableName.toString();
      break;
    case "ContactUs":
      getContactUs();
      selectable.value = false;
      tableHeading.value = tableName.toString();
      break;
    case "FAQ":
      getFAQs();
      selectable.value = false;
      tableHeading.value = tableName.toString();
      break;
    case "Organization":
      getOrganization();
      selectable.value = false;
      tableHeading.value = tableName.toString();
      break;
    case "DashboardAccessRequest":
      getDashboardAccessRequests();
      selectable.value = false;
      tableHeading.value = tableName.toString();
      break;
    case "DashboardFeedback":
      getDashboardFeedback();
      selectable.value = false;
      tableHeading.value = tableName.toString();
      break;
    default:
      router.push({ name: "admin-feed" });
  }
};

const getUsers = () => {
  loading.value = true;
  AdminService.getUsers().then(
    (response) => {
      items.value = response.data;
      fields.value = UserFields;
      loading.value = false;
    },
    (error) => {
      loading.value = false;
      message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};

const getInstructors = () => {
  loading.value = true;
  AdminService.getInstructors().then(
    (response) => {
      items.value = response.data;
      fields.value = InstructorFields;
      loading.value = false;
    },
    (error) => {
      loading.value = false;
      message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};

const getClasses = () => {
  loading.value = true;
  AdminService.getClasses().then(
    (response) => {
      items.value = response.data;
      fields.value = ClassFields;
      loading.value = false;
    },
    (error) => {
      loading.value = false;
      message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};

const getCourseRequests = () => {
  loading.value = true;
  AdminService.getCourseRequests().then(
    (response) => {
      console.log(response.data);
      items.value = response.data;
      fields.value = CourseRequestFields;
      loading.value = false;
    },
    (error) => {
      loading.value = false;
      message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};

const getResourceRequests = () => {
  loading.value = true;
  AdminService.getResourceRequests().then(
    (response) => {
      items.value = response.data;
      fields.value = ResourceRequestFields;
      loading.value = false;
    },
    (error) => {
      loading.value = false;
      message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};

const getIndustryPractitioners = () => {
  loading.value = true;
  AdminService.getIndustryPractitioners().then(
    (response) => {
      items.value = response.data;
      fields.value = IndustryPractitionerFields;
      loading.value = false;
    },
    (error) => {
      loading.value = false;
      message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};

const getAvailability = () => {
  loading.value = true;
  AdminService.getAvailability().then(
    (response) => {
      items.value = response.data;
      fields.value = AvailabilityFields;
      loading.value = false;
    },
    (error) => {
      loading.value = false;
      message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};

const getUploads = () => {
  loading.value = true;
  AdminService.getUploads().then(
    (response) => {
      items.value = response.data;
      fields.value = UploadFields;
      loading.value = false;
    },
    (error) => {
      loading.value = false;
      message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};

const getFeedback = () => {
  loading.value = true;
  AdminService.getFeedback().then(
    (response) => {
      items.value = response.data;
      fields.value = FeedbackFields;
      selectedItems.value = items.value.filter((it) => it["display"] === true);
      loading.value = false;
    },
    (error) => {
      loading.value = false;
      message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};

const getContactUs = () => {
  loading.value = true;
  AdminService.getContactUs().then(
    (response) => {
      items.value = response.data;
      fields.value = ContactUsFields;
      loading.value = false;
    },
    (error) => {
      loading.value = false;
      message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};

const getFAQs = () => {
  loading.value = true;
  AdminService.getFAQs().then(
    (response) => {
      items.value = response.data;
      fields.value = FAQFields;
      loading.value = false;
    },
    (error) => {
      loading.value = false;
      message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};

const getOrganization = () => {
  loading.value = true;
  AdminService.getOrganization().then(
    (response) => {
      items.value = response.data;
      fields.value = OrganizationFields;
      loading.value = false;
    },
    (error) => {
      loading.value = false;
      message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};

const getDashboardAccessRequests = () => {
  loading.value = true;
  AdminService.getDashboardAccessRequests().then(
    (response) => {
      items.value = response.data;
      console.log(response);
      fields.value = DashboardAccessRequestFields;
      loading.value = false;
    },
    (error) => {
      loading.value = false;
      message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};

const getDashboardFeedback = () => {
  loading.value = true;
  AdminService.getDashboardFeedback().then(
    (response) => {
      items.value = response.data;
      fields.value = DashboardFeedbackFields;
      loading.value = false;
    },
    (error) => {
      loading.value = false;
      message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};

const saveFAQ = () => {
  getFAQs();
  isFAQFormVisible.value = false;
};

const saveOrganization = () => {
  getOrganization();
  isOrganizationFormVisible.value = false;
};

const pages = computed({
  get() {
    return perPage.value && perPage.value !== 0
      ? Math.ceil(filtered.value.length / perPage.value)
      : filtered.value.length;
  },
  set(items) {
    console.log(items);
  },
});

const selectedItemsEmitted = ref([]);

let updating = ref(false);
let msg = ref("");

const updateInDatabase = () => {
  updating.value = true;

  console.log(selectedItemsEmitted.value.map((el) => el["id"]));
  let data = JSON.stringify(selectedItemsEmitted.value.map((el) => el["id"]));
  AdminService.updateFeedback(data).then(
    (response) => {
      updating.value = false;
      console.log(response.data);
      getFeedback();
    },
    (error) => {
      updating.value = false;
      msg.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};

const commentSchema = yup.object().shape({
  message: yup
    .string()
    .required("Message is required!")
    .max(5000, "Cannot be greater tha 5000 characters!"),
});

const disableUserAccountSchema = yup.object().shape({
  unit: yup.string().required("Unit is required!"),
  duration: yup.string().when("unit", {
    is: (val) => val !== "Indefinite",
    then: (schema) => schema.required("Duration is required!"),
    otherwise: (schema) => schema.optional().min(0),
  }),
});

const getFeedbackComments = (rowIndex) => {
  console.log(rowIndex);
  currFeedback.value = items.value[rowIndex];
  UserService.getFeedbackComments(currFeedback.value.id).then(
    (response) => {
      console.log(response);
      feedbackComments.value = response.data;
      isVisible.value = true;
    },
    (error) => {
      msg.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};

const rowAction = (rowIndex) => {
  switch (tableHeading.value) {
    case "Users":
      user.value = items.value[rowIndex];
      dialogHeading.value = "View User Details";
      isViewClassVisible.value = true;
      break;
    case "Instructors":
      userProfile.value = items.value[rowIndex];
      dialogHeading.value = "View Instructor Details";
      isViewClassVisible.value = true;
      break;
    case "Classes":
      course.value = items.value[rowIndex];
      dialogHeading.value = "View Class Details";
      isViewClassVisible.value = true;
      break;
    case "CourseRequests":
      courseRequest.value = items.value[rowIndex];
      dialogHeading.value = "View Course Request Details";
      isViewClassVisible.value = true;
      break;
    case "ResourceRequests":
      console.log("resource request table");
      resourceRequest.value = items.value[rowIndex];
      dialogHeading.value = "View Resource Request Details";
      isViewClassVisible.value = true;
      break;
    case "Practitioners":
      userProfile.value = items.value[rowIndex];
      dialogHeading.value = "View Practitioner Details";
      isViewClassVisible.value = true;
      break;
    case "Availability":
      console.log("availability table");
      userAvailability.value = items.value[rowIndex];
      dialogHeading.value = "View Practitioner Availability Details";
      isViewClassVisible.value = true;
      break;
    case "Uploads":
      upload.value = items.value[rowIndex];
      dialogHeading.value = "View Upload Details";
      isViewClassVisible.value = true;
      break;
    case "Feedback":
      console.log("feedback table");
      getFeedbackComments(rowIndex);
      break;
    case "FAQ":
      console.log("faq table");
      faq.value = items.value[rowIndex];
      isFAQFormVisible.value = true;
      break;
    case "ContactUs":
      console.log("ContactUs table");
      contactUs.value = items.value[rowIndex];
      dialogHeading.value = "View Contact Message Details";
      isViewClassVisible.value = true;
      break;
    case "Organization":
      console.log("organization table");
      organization.value = items.value[rowIndex];
      isOrganizationFormVisible.value = true;
      break;
    case "DashboardFeedback":
      console.log("dashboard feedback table");
      getFeedbackComments(rowIndex);
      break;
  }
};

const deleteData = async (rowIndex) => {
  const cancelDeleteContent = await confirm({
    message: "Are you sure you want to delete this uploaded content?",
    cancelText: "Yes",
    okText: "No",
  });
  if (!cancelDeleteContent) {
    loading.value = true;
    AdminService.deleteProjectDetail(items.value[rowIndex].id).then(
      (res) => {
        msg.value = res.data.message;
        showToast("Content deleted successfully.");
        getUploads();
        loading.value = false;
      },
      (error) => {
        msg.value =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        loading.value = false;
      }
    );
  }
};

const disableUserStatus = (rowIndex) => {
  user.value = items.value[rowIndex];
  isUserAccountDisableVisible.value = true;
};

const changeUserStatus = (rowIndex) => {
  console.log(rowIndex);
  loading.value = true;
  AdminService.changeUserStatus(items.value[rowIndex].id).then(
    (res) => {
      msg.value = res.data.message;
      showToast("User updated successfully.");
      getUsers();
      loading.value = false;
    },
    (error) => {
      msg.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      loading.value = false;
    }
  );
};

const assignPractitioner = (rowIndex) => {
  console.log(rowIndex);
  userAction.value = "assignPractitioner";
  assignFormHeader.value = "Assign Practitioner";
  courseRequest.value = items.value[rowIndex];
  isAssignFormVisible.value = true;
};

const recommendPractitioner = (rowIndex) => {
  console.log(rowIndex);
  userAction.value = "recommendPractitioner";
  assignFormHeader.value = "Recommend Practitioner";
  courseRequest.value = items.value[rowIndex];
  isAssignFormVisible.value = true;
};

const removePractitioner = async (rowIndex) => {
  console.log(rowIndex);
  const removePractitioner = await confirm({
    message:
      "Are you sure you want to remove this practitioner from course support request?",
    cancelText: "Yes",
    okText: "No",
  });
  if (!removePractitioner) {
    loading.value = true;
    AdminService.removePractitioner(items.value[rowIndex].id).then(
      (res) => {
        msg.value = res.data.message;
        showToast("Course Request updated successfully.");
        getCourseRequests();
        loading.value = false;
      },
      (error) => {
        msg.value =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        loading.value = false;
      }
    );
  }
};

const disablePractitionerRecommendation = async (rowIndex) => {
  console.log(rowIndex);
  const cancelDisablePractitioner = await confirm({
    message:
      "Are you sure you want to disable this practitioner from instructor recommendations?",
    cancelText: "Yes",
    okText: "No",
  });
  if (!cancelDisablePractitioner) {
    loading.value = true;
    AdminService.updatePractitionerRecommendationAvail(
      items.value[rowIndex].id,
      true
    ).then(
      (res) => {
        msg.value = res.data.message;
        showToast("User updated successfully.");
        getUsers();
        loading.value = false;
      },
      (error) => {
        msg.value =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        loading.value = false;
      }
    );
  }
};

const enablePractitionerRecommendation = async (rowIndex) => {
  console.log(rowIndex);
  loading.value = true;
  AdminService.updatePractitionerRecommendationAvail(
    items.value[rowIndex].id,
    false
  ).then(
    (res) => {
      msg.value = res.data.message;
      showToast("User updated successfully.");
      getUsers();
      loading.value = false;
    },
    (error) => {
      msg.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      loading.value = false;
    }
  );
};

const handleSubmit = (data) => {
  if (userAction.value === "assignPractitioner") {
    loading.value = true;
    AdminService.assignPractitioner(courseRequest.value.id, data).then(
      (res) => {
        msg.value = res.data.message;
        showToast("Course Request updated successfully.");
        getCourseRequests();
        isAssignFormVisible.value = false;
        loading.value = false;
      },
      (error) => {
        msg.value =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        loading.value = false;
      }
    );
  } else {
    loading.value = true;
    AdminService.assignRecommendation(courseRequest.value.id, data).then(
      (res) => {
        msg.value = res.data.message;
        showToast("Recommendations updated successfully.");
        getCourseRequests();
        isAssignFormVisible.value = false;
        loading.value = false;
      },
      (error) => {
        msg.value =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        loading.value = false;
      }
    );
  }
};

const postComment = (data) => {
  loading.value = true;
  console.log(tableHeading.value);
  if (tableHeading.value === "Feedback")
    data["linkedResourceType"] = "feedback";
  else data["linkedResourceType"] = "dashboardFeedback";
  UserService.postFeedbackComment(data, currFeedback.value.id).then(
    (res) => {
      msg.value = res.data.message;
      showToast("Comment posted successfully.");
      isVisible.value = false;
      loading.value = false;
    },
    (error) => {
      msg.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      successful.value = false;
      loading.value = false;
    }
  );
};

const disableUserAccount = (data) => {
  loading.value = true;
  AdminService.changeUserStatus(user.value.id, data).then(
    (res) => {
      msg.value = res.data.message;
      isUserAccountDisableVisible.value = false;
      showToast("User updated successfully.");
      getUsers();
      loading.value = false;
    },
    (error) => {
      msg.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      loading.value = false;
    }
  );
};

const updateDashboardAccessRequest = (item, status) => {
  loading.value = true;
  let data = { status: status };
  AdminService.updateDashboardAccessRequest(item.id, data).then(
    (res) => {
      msg.value = res.data.message;
      showToast(msg.value);
      getDashboardAccessRequests();
      loading.value = false;
    },
    (error) => {
      msg.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      loading.value = false;
    }
  );
};

const addDashboardAccessRequest = (data) => {
  loading.value = true;
  AdminService.addDashboardAccessRequest(data).then(
    (res) => {
      msg.value = res.data.message;
      showToast(msg.value);
      getDashboardAccessRequests();
      isDashboardAccessFormVisible.value = false;
      loading.value = false;
    },
    (error) => {
      msg.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      loading.value = false;
    }
  );
};

const filterInput = ref("");

watch(
  () => route.params.name,
  (newName) => {
    fetchTable(newName as string);
  },
  { immediate: true }
);

return (_ctx: any,_cache: any) => {
  const _component_va_input = _resolveComponent("va-input")!
  const _component_va_chip = _resolveComponent("va-chip")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_va_button = _resolveComponent("va-button")!
  const _component_va_pagination = _resolveComponent("va-pagination")!
  const _component_va_data_table = _resolveComponent("va-data-table")!
  const _component_va_divider = _resolveComponent("va-divider")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(BackButton),
        _createElementVNode("h3", _hoisted_3, _toDisplayString(tableHeading.value.replace(/([A-Z]+)/g, " $1").trim()), 1)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_va_input, {
          modelValue: filterInput.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((filterInput).value = $event)),
          class: "flex flex-col mb-2 md6 xs12",
          placeholder: "Filter..."
        }, null, 8, ["modelValue"]),
        _cache[18] || (_cache[18] = _createTextVNode("    ")),
        (tableHeading.value === 'Feedback')
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              class: "btn btn-primary btn-block flex-shrink-1 flex-grow-0",
              style: {"height":"2.3rem"},
              disabled: _unref(updating),
              onClick: updateInDatabase
            }, [
              _withDirectives(_createElementVNode("span", _hoisted_6, null, 512), [
                [_vShow, _unref(updating)]
              ]),
              _cache[15] || (_cache[15] = _createElementVNode("span", null, "Update", -1))
            ], 8, _hoisted_5))
          : _createCommentVNode("", true),
        (tableHeading.value === 'FAQ')
          ? (_openBlock(), _createElementBlock("button", {
              key: 1,
              class: "btn btn-primary btn-block flex-shrink-1 flex-grow-0",
              style: {"height":"2.3rem"},
              onClick: _cache[1] || (_cache[1] = ($event: any) => ((_isRef(faq) //@ts-ignore
 ? faq.value = _unref(emptyFaq) : faq = _unref(emptyFaq)), (_isRef(isFAQFormVisible) //@ts-ignore
 ? isFAQFormVisible.value = true : isFAQFormVisible = true)))
            }, [
              _withDirectives(_createElementVNode("span", _hoisted_7, null, 512), [
                [_vShow, _unref(updating)]
              ]),
              _cache[16] || (_cache[16] = _createElementVNode("span", null, "Add FAQ", -1))
            ]))
          : _createCommentVNode("", true),
        (tableHeading.value === 'DashboardAccessRequest')
          ? (_openBlock(), _createElementBlock("button", {
              key: 2,
              class: "btn btn-primary btn-block flex-shrink-1 flex-grow-0",
              style: {"height":"2.3rem"},
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_isRef(isDashboardAccessFormVisible) //@ts-ignore
 ? isDashboardAccessFormVisible.value = true : isDashboardAccessFormVisible = true))
            }, [
              _withDirectives(_createElementVNode("span", _hoisted_8, null, 512), [
                [_vShow, _unref(updating)]
              ]),
              _cache[17] || (_cache[17] = _createElementVNode("span", null, "Add Access to User", -1))
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", null, [
        _createVNode(_component_va_data_table, {
          class: "table-crud",
          items: _unref(items),
          columns: _unref(fields),
          filter: filterInput.value,
          "per-page": perPage.value,
          "current-page": _unref(currentPage),
          "sticky-header": "",
          loading: _unref(loading),
          modelValue: selectedItems.value,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((selectedItems).value = $event)),
          selectable: selectable.value,
          "select-mode": "multiple",
          "selected-color": "#888888",
          onFiltered: _cache[5] || (_cache[5] = ($event: any) => ((filtered.value = $event.items), (_isRef(currentPage) //@ts-ignore
 ? currentPage.value = 1 : currentPage = 1))),
          onSelectionChange: _cache[6] || (_cache[6] = ($event: any) => (selectedItemsEmitted.value = $event.currentSelectedItems))
        }, _createSlots({
          "cell(rating)": _withCtx(({ value }) => [
            _createTextVNode(_toDisplayString(+value + 1), 1)
          ]),
          "cell(roleId)": _withCtx(({ value }) => [
            (value == 1)
              ? (_openBlock(), _createBlock(_component_va_chip, {
                  key: 0,
                  size: "small"
                }, {
                  default: _withCtx(() => _cache[20] || (_cache[20] = [
                    _createTextVNode(" Instructor ")
                  ])),
                  _: 1
                }))
              : (value == 2)
                ? (_openBlock(), _createBlock(_component_va_chip, {
                    key: 1,
                    size: "small"
                  }, {
                    default: _withCtx(() => _cache[21] || (_cache[21] = [
                      _createTextVNode(" Practitioner ")
                    ])),
                    _: 1
                  }))
                : (_openBlock(), _createBlock(_component_va_chip, {
                    key: 2,
                    size: "small"
                  }, {
                    default: _withCtx(() => _cache[22] || (_cache[22] = [
                      _createTextVNode(" Admin ")
                    ])),
                    _: 1
                  }))
          ]),
          "cell(academicLevel)": _withCtx(({ value }) => [
            (_unref(isJSONObject)(value))
              ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(JSON.parse(value).join(", ")), 1))
              : (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(value), 1))
          ]),
          "cell(studentDept)": _withCtx(({ value }) => [
            (_unref(isJSONObject)(value))
              ? (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(JSON.parse(value).join(", ")), 1))
              : (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(value), 1))
          ]),
          "cell(expertise)": _withCtx(({ value }) => [
            (_unref(isJSONObject)(value))
              ? (_openBlock(), _createElementBlock("span", _hoisted_13, _toDisplayString(JSON.parse(value).join(", ")), 1))
              : (_openBlock(), _createElementBlock("span", _hoisted_14, _toDisplayString(value), 1))
          ]),
          "cell(courseRequestType)": _withCtx(({ value }) => [
            (_unref(isJSONObject)(value))
              ? (_openBlock(), _createElementBlock("span", _hoisted_15, _toDisplayString(JSON.parse(value).join(", ")), 1))
              : (_openBlock(), _createElementBlock("span", _hoisted_16, _toDisplayString(value), 1))
          ]),
          "cell(insState)": _withCtx(({ value }) => [
            (_unref(isJSONObject)(value))
              ? (_openBlock(), _createElementBlock("span", _hoisted_17, _toDisplayString(JSON.parse(value).join(", ")), 1))
              : (_openBlock(), _createElementBlock("span", _hoisted_18, _toDisplayString(value), 1))
          ]),
          "cell(insName)": _withCtx(({ value }) => [
            (_unref(isJSONObject)(value))
              ? (_openBlock(), _createElementBlock("span", _hoisted_19, _toDisplayString(JSON.parse(value).join(", ")), 1))
              : (_openBlock(), _createElementBlock("span", _hoisted_20, _toDisplayString(value), 1))
          ]),
          "cell(date)": _withCtx(({ value }) => [
            _createTextVNode(_toDisplayString(JSON.parse(value).join(", ")), 1)
          ]),
          "cell(time)": _withCtx(({ value }) => [
            _createTextVNode(_toDisplayString(JSON.parse(value).join(", ")), 1)
          ]),
          "cell(message)": _withCtx(({ value }) => [
            (value.length > 100)
              ? (_openBlock(), _createElementBlock("span", _hoisted_21, _toDisplayString(value.substring(0, 100)) + "... ", 1))
              : (_openBlock(), _createElementBlock("span", _hoisted_22, _toDisplayString(value), 1))
          ]),
          "cell(status)": _withCtx(({ value }) => [
            (tableHeading.value == 'CourseRequests')
              ? (_openBlock(), _createElementBlock("span", _hoisted_23, [
                  (value === '10' || value === '60')
                    ? (_openBlock(), _createElementBlock("span", _hoisted_24, "Accepted"))
                    : (value === '20')
                      ? (_openBlock(), _createElementBlock("span", _hoisted_25, "Practitioner's Response Awaited"))
                      : (value === '40')
                        ? (_openBlock(), _createElementBlock("span", _hoisted_26, "Deleted"))
                        : (_openBlock(), _createElementBlock("span", _hoisted_27, "Pending"))
                ]))
              : (tableHeading.value === 'Users')
                ? (_openBlock(), _createElementBlock("span", _hoisted_28, [
                    (value == 'true')
                      ? (_openBlock(), _createElementBlock("span", _hoisted_29, " Active "))
                      : (_openBlock(), _createElementBlock("span", _hoisted_30, "Inactive"))
                  ]))
                : (tableHeading.value == 'Uploads')
                  ? (_openBlock(), _createElementBlock("span", _hoisted_31, [
                      (value === '60')
                        ? (_openBlock(), _createElementBlock("span", _hoisted_32, "Uploaded"))
                        : (value === '90')
                          ? (_openBlock(), _createElementBlock("span", _hoisted_33, [
                              _cache[23] || (_cache[23] = _createTextVNode("Reported ")),
                              _createVNode(_component_font_awesome_icon, { icon: "fa-solid fa-flag" })
                            ]))
                          : (value === '40')
                            ? (_openBlock(), _createElementBlock("span", _hoisted_34, "Deleted"))
                            : _createCommentVNode("", true)
                    ]))
                  : (_openBlock(), _createElementBlock("span", _hoisted_35, _toDisplayString(value), 1))
          ]),
          "cell(actions)": _withCtx(({ rowIndex }) => [
            (tableHeading.value === 'DashboardAccessRequest')
              ? (_openBlock(), _createElementBlock("div", _hoisted_36, [
                  (_unref(items)[rowIndex].status === '01')
                    ? (_openBlock(), _createBlock(_component_va_button, {
                        key: 0,
                        preset: "plain",
                        title: "Accept Access Request",
                        icon: "check",
                        onClick: ($event: any) => (updateDashboardAccessRequest(_unref(items)[rowIndex], '60'))
                      }, null, 8, ["onClick"]))
                    : (_unref(items)[rowIndex].status === '60')
                      ? (_openBlock(), _createBlock(_component_va_button, {
                          key: 1,
                          preset: "plain",
                          color: "danger",
                          title: "Remove Access",
                          icon: "block",
                          onClick: ($event: any) => (updateDashboardAccessRequest(_unref(items)[rowIndex], '40'))
                        }, null, 8, ["onClick"]))
                      : _createCommentVNode("", true),
                  (_unref(items)[rowIndex].status === '01')
                    ? (_openBlock(), _createBlock(_component_va_button, {
                        key: 2,
                        preset: "plain",
                        color: "danger",
                        title: "Reject Access Request",
                        icon: "close",
                        onClick: ($event: any) => (updateDashboardAccessRequest(_unref(items)[rowIndex], '90')),
                        class: "ml-2"
                      }, null, 8, ["onClick"]))
                    : _createCommentVNode("", true)
                ]))
              : (
                tableHeading.value === 'FAQ' && tableHeading.value === 'Organization'
              )
                ? (_openBlock(), _createBlock(_component_va_button, {
                    key: 1,
                    preset: "plain",
                    title: "Edit",
                    icon: "edit",
                    onClick: ($event: any) => (rowAction(rowIndex))
                  }, null, 8, ["onClick"]))
                : (_openBlock(), _createBlock(_component_va_button, {
                    key: 2,
                    preset: "plain",
                    title: "View Details",
                    icon: "visibility",
                    onClick: ($event: any) => (rowAction(rowIndex))
                  }, null, 8, ["onClick"])),
            (
                tableHeading.value == 'CourseRequests' &&
                (_unref(items)[rowIndex].status == null ||
                  _unref(items)[rowIndex].status == '20')
              )
              ? (_openBlock(), _createElementBlock("span", _hoisted_37, [
                  _cache[24] || (_cache[24] = _createTextVNode("   ")),
                  _createVNode(_component_va_button, {
                    preset: "plain",
                    icon: "assignment_ind",
                    title: "Assign Practitioner",
                    onClick: ($event: any) => (assignPractitioner(rowIndex))
                  }, null, 8, ["onClick"])
                ]))
              : _createCommentVNode("", true),
            (
                tableHeading.value == 'CourseRequests' &&
                _unref(items)[rowIndex].status == null
              )
              ? (_openBlock(), _createElementBlock("span", _hoisted_38, [
                  _cache[25] || (_cache[25] = _createTextVNode("   ")),
                  _createVNode(_component_va_button, {
                    preset: "plain",
                    icon: "recommend",
                    title: "Recommend Practitioner",
                    onClick: ($event: any) => (recommendPractitioner(rowIndex))
                  }, null, 8, ["onClick"])
                ]))
              : _createCommentVNode("", true),
            (
                tableHeading.value == 'CourseRequests' &&
                (_unref(items)[rowIndex].status == '60' ||
                  _unref(items)[rowIndex].status == '10')
              )
              ? (_openBlock(), _createElementBlock("span", _hoisted_39, [
                  _cache[26] || (_cache[26] = _createTextVNode("   ")),
                  _createVNode(_component_va_button, {
                    preset: "plain",
                    icon: "person_remove",
                    title: "Remove Practitioner",
                    onClick: ($event: any) => (removePractitioner(rowIndex))
                  }, null, 8, ["onClick"])
                ]))
              : (tableHeading.value == 'Users')
                ? (_openBlock(), _createElementBlock("span", _hoisted_40, [
                    _cache[27] || (_cache[27] = _createTextVNode("   ")),
                    (_unref(items)[rowIndex].status === true)
                      ? (_openBlock(), _createBlock(_component_va_button, {
                          key: 0,
                          preset: "plain",
                          icon: "person_remove",
                          title: "Deactivate User Account",
                          onClick: ($event: any) => (disableUserStatus(rowIndex))
                        }, null, 8, ["onClick"]))
                      : (_openBlock(), _createBlock(_component_va_button, {
                          key: 1,
                          preset: "plain",
                          icon: "person_add",
                          title: "Activate User Account",
                          onClick: ($event: any) => (changeUserStatus(rowIndex))
                        }, null, 8, ["onClick"])),
                    _cache[28] || (_cache[28] = _createTextVNode("   ")),
                    (
                  _unref(items)[rowIndex].roleId == 2 &&
                  !_unref(items)[rowIndex].recommendationDisable
                )
                      ? (_openBlock(), _createBlock(_component_va_button, {
                          key: 2,
                          preset: "plain",
                          icon: "thumb_down",
                          title: "Disable as Recommendation",
                          onClick: ($event: any) => (disablePractitionerRecommendation(rowIndex))
                        }, null, 8, ["onClick"]))
                      : (
                  _unref(items)[rowIndex].roleId == 2 &&
                  _unref(items)[rowIndex].recommendationDisable
                )
                        ? (_openBlock(), _createBlock(_component_va_button, {
                            key: 3,
                            preset: "plain",
                            icon: "thumb_up",
                            title: "Enable as Recommendation",
                            onClick: ($event: any) => (enablePractitionerRecommendation(rowIndex))
                          }, null, 8, ["onClick"]))
                        : _createCommentVNode("", true)
                  ]))
                : (
                tableHeading.value === 'Uploads' && _unref(items)[rowIndex].status !== '40'
              )
                  ? (_openBlock(), _createElementBlock("span", _hoisted_41, [
                      _cache[29] || (_cache[29] = _createTextVNode("   ")),
                      _createVNode(_component_va_button, {
                        preset: "plain",
                        title: "Delete Uploaded Content",
                        icon: "delete",
                        onClick: ($event: any) => (deleteData(rowIndex))
                      }, null, 8, ["onClick"])
                    ]))
                  : _createCommentVNode("", true)
          ]),
          bodyAppend: _withCtx(() => [
            _createElementVNode("tr", null, [
              _createElementVNode("td", {
                colspan: _unref(fields).length
              }, [
                _createElementVNode("div", _hoisted_43, [
                  _createVNode(_component_va_pagination, {
                    modelValue: _unref(currentPage),
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_isRef(currentPage) ? (currentPage).value = $event : currentPage = $event)),
                    input: "",
                    pages: pages.value,
                    hideOnSinglePage: true,
                    class: "mx-auto my-2"
                  }, null, 8, ["modelValue", "pages"])
                ])
              ], 8, _hoisted_42)
            ])
          ]),
          _: 2
        }, [
          (selectable.value)
            ? {
                name: "headerAppend",
                fn: _withCtx(() => [
                  _cache[19] || (_cache[19] = _createElementVNode("tr", { class: "va-data-table__table-tr" }, [
                    _createElementVNode("th", { class: "va-data-table__table-th" }, "Show on Home Screen")
                  ], -1))
                ]),
                key: "0"
              }
            : undefined
        ]), 1032, ["items", "columns", "filter", "per-page", "current-page", "loading", "modelValue", "selectable"])
      ])
    ]),
    _createVNode(_unref(Dialog), {
      visible: _unref(isVisible),
      "onUpdate:visible": _cache[8] || (_cache[8] = ($event: any) => (_isRef(isVisible) ? (isVisible).value = $event : isVisible = $event)),
      modal: "",
      header: "User Feedback",
      style: { width: '60vw' },
      breakpoints: { '768px': '90vw' }
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_44, [
          _createElementVNode("div", _hoisted_45, [
            _createVNode(ProfileIcon)
          ]),
          _createElementVNode("span", _hoisted_46, _toDisplayString(_unref(currFeedback).user.firstName) + " " + _toDisplayString(_unref(currFeedback).user.lastName), 1)
        ]),
        _createElementVNode("div", _hoisted_47, _toDisplayString(_unref(currFeedback).message), 1),
        _createVNode(_component_va_divider),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(feedbackComments), (feedbackComment) => {
          return (_openBlock(), _createElementBlock("div", {
            key: feedbackComment.id
          }, [
            _createElementVNode("div", _hoisted_48, [
              _createElementVNode("div", _hoisted_49, [
                (feedbackComment.user.fileName)
                  ? (_openBlock(), _createElementBlock("img", {
                      key: 0,
                      id: "profile-img",
                      src: 
                _unref(websiteURL) + 'profile_picture/' + feedbackComment.user.fileName
              ,
                      class: "profile-icon-card"
                    }, null, 8, _hoisted_50))
                  : (_openBlock(), _createElementBlock("img", _hoisted_51))
              ]),
              _createElementVNode("div", _hoisted_52, [
                _createElementVNode("div", _hoisted_53, [
                  _createElementVNode("div", _hoisted_54, _toDisplayString(feedbackComment.user.firstName) + " " + _toDisplayString(feedbackComment.user.lastName), 1),
                  _createTextVNode(" " + _toDisplayString(feedbackComment.message), 1)
                ]),
                _createElementVNode("span", _hoisted_55, _toDisplayString(_unref(getTimeDiff)(feedbackComment.createdAt)), 1)
              ])
            ])
          ]))
        }), 128)),
        _createVNode(_component_va_divider),
        _createElementVNode("div", _hoisted_56, [
          _createElementVNode("div", _hoisted_57, [
            _createVNode(ProfileIcon)
          ]),
          (!_unref(editable))
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "ml-3 mt-2",
                onClick: _cache[7] || (_cache[7] = ($event: any) => (_isRef(editable) //@ts-ignore
 ? editable.value = true : editable = true))
              }, " Add Comment "))
            : (_openBlock(), _createElementBlock("div", _hoisted_58, [
                _createVNode(_unref(Form), {
                  onSubmit: postComment,
                  "validation-schema": _unref(commentSchema)
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_59, [
                      _createVNode(_unref(Field), { name: "message" }, {
                        default: _withCtx(({ field }) => [
                          _createElementVNode("textarea", _mergeProps({ type: "text" }, field, {
                            class: "form-control border-2",
                            placeholder: "Add a comment..."
                          }), null, 16),
                          _createVNode(_unref(ErrorMessage), {
                            name: "message",
                            class: "error-feedback"
                          })
                        ]),
                        _: 1
                      }),
                      _cache[30] || (_cache[30] = _createElementVNode("button", { class: "btn btn-primary mx-4 my-3" }, "Reply", -1))
                    ])
                  ]),
                  _: 1
                }, 8, ["validation-schema"])
              ]))
        ])
      ]),
      _: 1
    }, 8, ["visible"]),
    _createVNode(_unref(Dialog), {
      visible: _unref(isViewClassVisible),
      "onUpdate:visible": _cache[9] || (_cache[9] = ($event: any) => (_isRef(isViewClassVisible) ? (isViewClassVisible).value = $event : isViewClassVisible = $event)),
      modal: "",
      header: _unref(dialogHeading),
      style: { width: '60vw' },
      breakpoints: { '768px': '95vw' }
    }, {
      default: _withCtx(() => [
        (tableHeading.value === 'Users')
          ? (_openBlock(), _createElementBlock("div", _hoisted_60, [
              _createVNode(UserTable, { user: _unref(user) }, null, 8, ["user"])
            ]))
          : (tableHeading.value === 'Instructors')
            ? (_openBlock(), _createElementBlock("div", _hoisted_61, [
                _createVNode(InstructorProfileTable, { "user-profile": _unref(userProfile) }, null, 8, ["user-profile"])
              ]))
            : (tableHeading.value === 'Classes')
              ? (_openBlock(), _createElementBlock("div", _hoisted_62, [
                  _createElementVNode("table", _hoisted_63, [
                    _createElementVNode("tr", null, [
                      _cache[31] || (_cache[31] = _createElementVNode("td", null, "Semester & Year", -1)),
                      _createElementVNode("td", null, _toDisplayString(_unref(course).semester + " " + _unref(course).year), 1)
                    ]),
                    _createElementVNode("tr", null, [
                      _cache[32] || (_cache[32] = _createElementVNode("td", null, "Course Code", -1)),
                      _createElementVNode("td", null, _toDisplayString(_unref(course).courseCode), 1)
                    ]),
                    _createElementVNode("tr", null, [
                      _cache[33] || (_cache[33] = _createElementVNode("td", null, "Course Title", -1)),
                      _createElementVNode("td", null, _toDisplayString(_unref(course).courseTitle), 1)
                    ]),
                    (_unref(course).additionalInformation?.length > 0)
                      ? (_openBlock(), _createElementBlock("tr", _hoisted_64, [
                          _cache[34] || (_cache[34] = _createElementVNode("td", null, "Link to Additional Course Information", -1)),
                          _createElementVNode("td", null, _toDisplayString(_unref(course).additionalInformation), 1)
                        ]))
                      : _createCommentVNode("", true),
                    _createElementVNode("tr", null, [
                      _cache[35] || (_cache[35] = _createElementVNode("td", null, "Location of Classroom", -1)),
                      _createElementVNode("td", null, _toDisplayString(_unref(course).location), 1)
                    ]),
                    _createElementVNode("tr", null, [
                      _cache[36] || (_cache[36] = _createElementVNode("td", null, "Class Size", -1)),
                      _createElementVNode("td", null, _toDisplayString(_unref(course).classSize), 1)
                    ]),
                    _createElementVNode("tr", null, [
                      _cache[37] || (_cache[37] = _createElementVNode("td", null, "Students' Academic Level", -1)),
                      _createElementVNode("td", null, _toDisplayString(JSON.parse(_unref(course).academicLevel).join(", ")), 1)
                    ]),
                    _createElementVNode("tr", null, [
                      _cache[38] || (_cache[38] = _createElementVNode("td", null, "Students' Department or Program of Study", -1)),
                      _createElementVNode("td", null, _toDisplayString(JSON.parse(_unref(course).studentDept).join(", ")), 1)
                    ])
                  ])
                ]))
              : (tableHeading.value === 'CourseRequests')
                ? (_openBlock(), _createElementBlock("div", _hoisted_65, [
                    _createVNode(CourseRequestTable, { course: _unref(courseRequest) }, null, 8, ["course"])
                  ]))
                : (tableHeading.value === 'Practitioners')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_66, [
                      _createVNode(PractitionerProfileTable, { "user-profile": _unref(userProfile) }, null, 8, ["user-profile"])
                    ]))
                  : (tableHeading.value === 'Availability')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_67, [
                        _createVNode(PractitionerAvailabilityTable, { userAvailability: _unref(userAvailability) }, null, 8, ["userAvailability"])
                      ]))
                    : (tableHeading.value === 'Uploads')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_68, [
                          _createVNode(ResourcesGalleryItem, { item: _unref(upload) }, null, 8, ["item"])
                        ]))
                      : (tableHeading.value === 'ResourceRequests')
                        ? (_openBlock(), _createElementBlock("div", _hoisted_69, [
                            _createElementVNode("div", _hoisted_70, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(resourceRequest).resource_responses, (item) => {
                                return (_openBlock(), _createElementBlock("div", {
                                  class: "responsive",
                                  key: item.id
                                }, [
                                  _createVNode(ResourcesGalleryItem, { item: item }, null, 8, ["item"])
                                ]))
                              }), 128)),
                              (_unref(resourceRequest).resource_responses?.length == 0)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_71, " No Responses! "))
                                : _createCommentVNode("", true)
                            ]),
                            _cache[39] || (_cache[39] = _createElementVNode("div", { class: "clearfix" }, null, -1))
                          ]))
                        : (tableHeading.value === 'ContactUs')
                          ? (_openBlock(), _createElementBlock("div", _hoisted_72, [
                              _createVNode(ContactUsTable, { contactUs: _unref(contactUs) }, null, 8, ["contactUs"])
                            ]))
                          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["visible", "header"]),
    _createVNode(_unref(Dialog), {
      visible: _unref(isFAQFormVisible),
      "onUpdate:visible": _cache[10] || (_cache[10] = ($event: any) => (_isRef(isFAQFormVisible) ? (isFAQFormVisible).value = $event : isFAQFormVisible = $event)),
      modal: "",
      header: "Edit FAQ",
      style: { width: '60vw' },
      breakpoints: { '768px': '90vw' }
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_73, [
          _createVNode(FAQForm, {
            faq: _unref(faq),
            onSaveFAQ: saveFAQ
          }, null, 8, ["faq"])
        ])
      ]),
      _: 1
    }, 8, ["visible"]),
    _createVNode(_unref(Dialog), {
      visible: _unref(isUserAccountDisableVisible),
      "onUpdate:visible": _cache[11] || (_cache[11] = ($event: any) => (_isRef(isUserAccountDisableVisible) ? (isUserAccountDisableVisible).value = $event : isUserAccountDisableVisible = $event)),
      modal: "",
      header: "Disable User Account",
      style: { width: '50vw' },
      breakpoints: { '768px': '90vw' }
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_74, [
          _createVNode(_unref(Form), {
            onSubmit: disableUserAccount,
            "validation-schema": _unref(disableUserAccountSchema)
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", null, [
                _createElementVNode("div", _hoisted_75, [
                  _createElementVNode("div", _hoisted_76, [
                    _createVNode(_unref(Field), {
                      name: "duration",
                      class: "form-control",
                      type: "text",
                      placeholder: "Duration"
                    }),
                    _createVNode(_unref(ErrorMessage), {
                      name: "duration",
                      class: "error-feedback"
                    })
                  ]),
                  _createElementVNode("div", _hoisted_77, [
                    _createVNode(_unref(Field), {
                      name: "unit",
                      as: "select",
                      class: "dropdown"
                    }, {
                      default: _withCtx(() => [
                        _cache[40] || (_cache[40] = _createElementVNode("option", {
                          value: "",
                          selected: "",
                          hidden: "",
                          disabled: ""
                        }, "Unit", -1)),
                        _cache[41] || (_cache[41] = _createElementVNode("option", { value: "hour(s)" }, "Hour(s)", -1)),
                        _cache[42] || (_cache[42] = _createElementVNode("option", { value: "day(s)" }, "Day(s)", -1)),
                        _cache[43] || (_cache[43] = _createElementVNode("option", { value: "month(s)" }, "Month(s)", -1)),
                        _cache[44] || (_cache[44] = _createElementVNode("option", { value: "Indefinite" }, "Indefinite", -1)),
                        _createVNode(_unref(ErrorMessage), {
                          name: "message",
                          class: "error-feedback"
                        })
                      ]),
                      _: 1
                    })
                  ])
                ]),
                _cache[45] || (_cache[45] = _createElementVNode("div", { class: "text-align-center" }, [
                  _createElementVNode("button", { class: "btn btn-primary flex-grow-0 flex-shrink-1 w-fit mt-2" }, " Disable ")
                ], -1))
              ])
            ]),
            _: 1
          }, 8, ["validation-schema"])
        ])
      ]),
      _: 1
    }, 8, ["visible"]),
    _createVNode(_unref(Dialog), {
      visible: _unref(isAssignFormVisible),
      "onUpdate:visible": _cache[12] || (_cache[12] = ($event: any) => (_isRef(isAssignFormVisible) ? (isAssignFormVisible).value = $event : isAssignFormVisible = $event)),
      modal: "",
      header: _unref(assignFormHeader),
      style: { width: '40vw' },
      breakpoints: { '768px': '75vw' }
    }, {
      default: _withCtx(() => [
        _createVNode(_unref(Form), {
          onSubmit: handleSubmit,
          "validation-schema": _unref(schema),
          "initial-values": _unref(formValues)
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_78, [
              _cache[46] || (_cache[46] = _createElementVNode("label", { for: "email" }, "Email", -1)),
              _createVNode(_unref(Field), {
                name: "email",
                type: "email",
                class: "form-control"
              }),
              _createVNode(_unref(ErrorMessage), {
                name: "email",
                class: "error-feedback"
              })
            ]),
            _cache[47] || (_cache[47] = _createElementVNode("br", null, null, -1)),
            _createElementVNode("div", _hoisted_79, [
              _createElementVNode("button", {
                class: "btn btn-primary btn-block",
                disabled: _unref(loading)
              }, [
                _withDirectives(_createElementVNode("span", _hoisted_81, null, 512), [
                  [_vShow, _unref(loading)]
                ]),
                (_unref(userAction) === 'assignPractitioner')
                  ? (_openBlock(), _createElementBlock("span", _hoisted_82, "Assign Practitioner"))
                  : (_openBlock(), _createElementBlock("span", _hoisted_83, "Recommend Practitioner"))
              ], 8, _hoisted_80)
            ])
          ]),
          _: 1
        }, 8, ["validation-schema", "initial-values"])
      ]),
      _: 1
    }, 8, ["visible", "header"]),
    _createVNode(_unref(Dialog), {
      visible: _unref(isOrganizationFormVisible),
      "onUpdate:visible": _cache[13] || (_cache[13] = ($event: any) => (_isRef(isOrganizationFormVisible) ? (isOrganizationFormVisible).value = $event : isOrganizationFormVisible = $event)),
      modal: "",
      header: "Edit Organization Name",
      style: { width: '60vw' },
      breakpoints: { '768px': '90vw' }
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_84, [
          _createVNode(OrganizationForm, {
            organization: _unref(organization),
            onSaveOrganization: saveOrganization
          }, null, 8, ["organization"])
        ])
      ]),
      _: 1
    }, 8, ["visible"]),
    _createVNode(_unref(Dialog), {
      visible: _unref(isDashboardAccessFormVisible),
      "onUpdate:visible": _cache[14] || (_cache[14] = ($event: any) => (_isRef(isDashboardAccessFormVisible) ? (isDashboardAccessFormVisible).value = $event : isDashboardAccessFormVisible = $event)),
      modal: "",
      header: "Grant Dashboard Access to User",
      style: { width: '60vw' },
      breakpoints: { '768px': '90vw' }
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_85, [
          _createVNode(_unref(Form), {
            onSubmit: addDashboardAccessRequest,
            "validation-schema": _unref(schema),
            "initial-values": _unref(formValues)
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_86, [
                _cache[48] || (_cache[48] = _createElementVNode("label", { for: "email" }, "Email", -1)),
                _createVNode(_unref(Field), {
                  name: "email",
                  type: "email",
                  class: "form-control"
                }),
                _createVNode(_unref(ErrorMessage), {
                  name: "email",
                  class: "error-feedback"
                })
              ]),
              _cache[50] || (_cache[50] = _createElementVNode("br", null, null, -1)),
              _createElementVNode("div", _hoisted_87, [
                _createElementVNode("button", {
                  class: "btn btn-primary btn-block",
                  disabled: _unref(loading)
                }, [
                  _withDirectives(_createElementVNode("span", _hoisted_89, null, 512), [
                    [_vShow, _unref(loading)]
                  ]),
                  _cache[49] || (_cache[49] = _createElementVNode("span", null, "Add Access to User", -1))
                ], 8, _hoisted_88)
              ])
            ]),
            _: 1
          }, 8, ["validation-schema", "initial-values"])
        ])
      ]),
      _: 1
    }, 8, ["visible"])
  ]))
}
}

})