<script setup lang="ts">
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import AdminService from "@/services/admin.service.js";
import { useRoute, useRouter } from "vue-router";

const route = useRoute();
const router = useRouter();

let loading = false;
let successful = false;
let message = "";

const schema = yup.object().shape({
  ethnicity: yup.string().required("Racial Identity is required!"),
  gender: yup.string().required("Gender Identity is required!"),
  firstName: yup.string().required("First Name is required!"),
  lastName: yup.string().required("Last Name is required!"),
  password: yup
    .string()
    .required("Password is required!")
    .min(6, "Must be at least 6 characters!")
    .max(40, "Must be maximum 40 characters!")
    .matches(
      /^.*(?=.{8,})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      "Password must contain at least 8 characters, one uppercase, and one number"
    ),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password")], "Passwords must match"),
});

const handleSetAdmin = (data) => {
  loading = true;
  data.token = route.params.token;
  if (data.gender === "Others") data.gender = data["gender-text"];
  if (data.ethnicity === "Others") data.ethnicity = data["ethnicity-text"];
  AdminService.setAdminDetails(data).then(
    () => {
      router.push("/Home");
    },
    (error) => {
      loading = false;
      message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};
</script>

<style scoped></style>

<template>
  <div class="min-height header-space">
    <div class="card card-container">
      <div v-if="!successful">
        <Form
          @submit="handleSetAdmin"
          :validation-schema="schema"
          v-slot="{ values }"
        >
          <div class="form-group">
            <label for="firstName">First Name</label>
            <Field name="firstName" type="text" class="form-control" />
            <ErrorMessage name="firstName" class="error-feedback" />
          </div>

          <div class="form-group">
            <label for="lastName">Last Name</label>
            <Field name="lastName" type="text" class="form-control" />
            <ErrorMessage name="lastName" class="error-feedback" />
          </div>

          <div class="form-group">
            <label for="gender">Gender Identity</label>
            <Field name="gender" type="select" class="dropdown" as="select">
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="Non-binary">Non-binary</option>
              <option value="Others">Others, please specify</option>
            </Field>
            <div v-if="values.gender === 'Others'">
              <Field
                name="gender-text"
                type="text"
                placeholder=""
                class="form-control"
              />
            </div>

            <ErrorMessage name="gender" class="error-feedback" />
          </div>

          <div class="form-group">
            <label for="ethnicity">Racial Identity</label>
            <Field name="ethnicity" class="dropdown" as="select">
              <option value="American Indian or Alaska Native">
                American Indian or Alaska Native
              </option>
              <option value="Asian">Asian</option>
              <option value="Black or African American">
                Black or African American
              </option>
              <option value="Hispanic or Latinx">Hispanic or Latinx</option>
              <option value="Native Hawaiian or other Pacific Islander">
                Native Hawaiian or other Pacific Islander
              </option>
              <option value="White/Caucasian">White/Caucasian</option>
              <option value="Others">Others, please specify</option>
            </Field>
            <div v-if="values.ethnicity === 'Others'">
              <Field
                name="ethnicity-text"
                type="text"
                placeholder=""
                class="form-control"
              />
            </div>
            <ErrorMessage name="ethnicity" class="error-feedback" />
          </div>

          <div class="form-group">
            <label for="password">Password</label>
            <Field name="password" type="password" class="form-control" />
            <ErrorMessage name="password" class="error-feedback" />
          </div>
          <div class="form-group">
            <label for="confirmPassword">Confirm Password</label>
            <Field
              name="confirmPassword"
              type="password"
              class="form-control"
            />
            <ErrorMessage name="confirmPassword" class="error-feedback" />
          </div>

          <br />
          <div class="form-group text-align-center" style="width: 100%">
            <button class="btn btn-primary btn-block" :disabled="loading">
              <span
                v-show="loading"
                class="spinner-border spinner-border-sm"
              ></span>
              <span>Set Password</span>
            </button>
          </div>
        </Form>
      </div>
      <div
        v-if="message"
        :class="successful ? 'alert-success' : 'error-feedback'"
      >
        {{ message }}
      </div>
    </div>
  </div>
</template>
