import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 2 }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { key: 3 }
const _hoisted_8 = { key: 4 }
const _hoisted_9 = { key: 5 }
const _hoisted_10 = { key: 6 }
const _hoisted_11 = { key: 7 }
const _hoisted_12 = { key: 8 }

import { CourseRequest } from "@/types";
import {
  isCapstoneTermProject,
  showPriorKnowledge,
  showAssignmentConnected,
  showClassroomItems,
  showVisualRep,
} from "@/utils";

// eslint-disable-next-line no-undef

export default /*@__PURE__*/_defineComponent({
  __name: 'CourseRequestTable',
  props: {
    course: {}
  },
  setup(__props: any) {

const props = __props;

console.log(props.course);

let courseDate = JSON.parse(props.course.date);
let courseTime = JSON.parse(props.course.time);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("table", null, [
    _createElementVNode("tr", null, [
      _cache[0] || (_cache[0] = _createElementVNode("td", null, "Course Code", -1)),
      _createElementVNode("td", null, _toDisplayString(props.course.courseCode), 1)
    ]),
    _createElementVNode("tr", null, [
      _cache[1] || (_cache[1] = _createElementVNode("td", null, "Course Title", -1)),
      _createElementVNode("td", null, _toDisplayString(_ctx.course.courseTitle), 1)
    ]),
    _createElementVNode("tr", null, [
      _cache[2] || (_cache[2] = _createElementVNode("td", null, "Course-Support Request Type", -1)),
      _createElementVNode("td", null, _toDisplayString(_ctx.course.courseRequestType), 1)
    ]),
    _createElementVNode("tr", null, [
      _cache[3] || (_cache[3] = _createElementVNode("td", null, "Instruction Delivery Mode", -1)),
      _createElementVNode("td", null, _toDisplayString(_ctx.course.insDeliveryType), 1)
    ]),
    _createElementVNode("tr", null, [
      _cache[4] || (_cache[4] = _createElementVNode("td", null, "Class Size", -1)),
      _createElementVNode("td", null, _toDisplayString(_ctx.course.classSize), 1)
    ]),
    _createElementVNode("tr", null, [
      _cache[5] || (_cache[5] = _createElementVNode("td", null, "Academic Level", -1)),
      _createElementVNode("td", null, _toDisplayString(JSON.parse(_ctx.course.academicLevel).join(", ")), 1)
    ]),
    _createElementVNode("tr", null, [
      _cache[6] || (_cache[6] = _createElementVNode("td", null, "Students' Department or Program of Study", -1)),
      _createElementVNode("td", null, _toDisplayString(JSON.parse(_ctx.course.studentDept).join(", ")), 1)
    ]),
    _createElementVNode("tr", null, [
      _cache[7] || (_cache[7] = _createElementVNode("td", null, "When the Course-Support is Needed", -1)),
      _createElementVNode("td", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(courseDate), (item, index) => {
          return (_openBlock(), _createElementBlock("span", { key: index }, [
            _createTextVNode(_toDisplayString(new Date(item + "T" + _unref(courseTime)[index]).toLocaleString()) + " ", 1),
            (index != _unref(courseDate).length - 1)
              ? (_openBlock(), _createElementBlock("span", _hoisted_1, " OR "))
              : _createCommentVNode("", true)
          ]))
        }), 128))
      ])
    ]),
    _createElementVNode("tr", null, [
      _cache[8] || (_cache[8] = _createElementVNode("td", null, "Location of Institution", -1)),
      _createElementVNode("td", null, _toDisplayString(_ctx.course.location) + " " + _toDisplayString(_ctx.course.city + ", " + _ctx.course.state), 1)
    ]),
    _createElementVNode("tr", null, [
      _cache[9] || (_cache[9] = _createElementVNode("td", null, "Duration of Course-Support", -1)),
      _createElementVNode("td", null, _toDisplayString(_ctx.course.duration) + " minutes", 1)
    ]),
    _createElementVNode("tr", null, [
      _cache[10] || (_cache[10] = _createElementVNode("td", null, "Number/Frequency of Course-Support", -1)),
      _createElementVNode("td", null, _toDisplayString(_ctx.course.noOfFreq), 1)
    ]),
    _createElementVNode("tr", null, [
      _cache[11] || (_cache[11] = _createElementVNode("td", null, "Topic Requiring Course-Support", -1)),
      _createElementVNode("td", null, _toDisplayString(_ctx.course.topic), 1)
    ]),
    _createElementVNode("tr", null, [
      _cache[12] || (_cache[12] = _createElementVNode("td", null, "Learning Objective/Expected Outcome", -1)),
      _createElementVNode("td", null, _toDisplayString(JSON.parse(_ctx.course.learning).join(", ")), 1)
    ]),
    (_ctx.course.courseRequestType === 'Site Visit')
      ? (_openBlock(), _createElementBlock("tr", _hoisted_2, [
          _cache[13] || (_cache[13] = _createElementVNode("td", null, "Need for Pre-Visit by the Instructor", -1)),
          _createElementVNode("td", null, _toDisplayString(_ctx.course.needForPreVisit), 1)
        ]))
      : _createCommentVNode("", true),
    (_unref(showVisualRep)(_ctx.course.courseRequestType))
      ? (_openBlock(), _createElementBlock("tr", _hoisted_3, [
          _cache[14] || (_cache[14] = _createElementVNode("td", null, "Specific Demonstrations/ Visual Representation Required", -1)),
          _createElementVNode("td", null, _toDisplayString(_ctx.course.visualRep), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.course.courseRequestType === 'Site Visit')
      ? (_openBlock(), _createElementBlock("tr", _hoisted_4, [
          _cache[15] || (_cache[15] = _createElementVNode("td", null, "Need to Visit the Office Trailer/Site Office", -1)),
          _createElementVNode("td", null, _toDisplayString(_ctx.course.siteOffice), 1)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("tr", null, [
      _cache[16] || (_cache[16] = _createElementVNode("td", null, "Detailed Description of Course-Support Needed", -1)),
      _createElementVNode("td", null, [
        (_ctx.course.generalInfo)
          ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.course.generalInfo), 1))
          : (_openBlock(), _createElementBlock("span", _hoisted_6, "N/A"))
      ])
    ]),
    (
        _unref(showClassroomItems)(_ctx.course.courseRequestType) && _ctx.course.classroomItems
      )
      ? (_openBlock(), _createElementBlock("tr", _hoisted_7, [
          _cache[17] || (_cache[17] = _createElementVNode("td", null, "Available items in the classroom", -1)),
          _createElementVNode("td", null, _toDisplayString(_ctx.course.classroomItems), 1)
        ]))
      : _createCommentVNode("", true),
    (
        _unref(showAssignmentConnected)(_ctx.course.courseRequestType) &&
        _ctx.course.assigmentConnected
      )
      ? (_openBlock(), _createElementBlock("tr", _hoisted_8, [
          _cache[18] || (_cache[18] = _createElementVNode("td", null, "Student Assignment Connected to Course-Support", -1)),
          _createElementVNode("td", null, _toDisplayString(_ctx.course.assigmentConnected), 1)
        ]))
      : _createCommentVNode("", true),
    (_unref(showPriorKnowledge)(_ctx.course.courseRequestType))
      ? (_openBlock(), _createElementBlock("tr", _hoisted_9, [
          _cache[19] || (_cache[19] = _createElementVNode("td", null, "Students' Prior Knowledge About Topic Requiring Course-Support", -1)),
          _createElementVNode("td", null, _toDisplayString(_ctx.course.priorKnowledge), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.course.additionalInformation)
      ? (_openBlock(), _createElementBlock("tr", _hoisted_10, [
          _cache[20] || (_cache[20] = _createElementVNode("td", null, "Link to Additional Course Information", -1)),
          _createElementVNode("td", null, [
            _createElementVNode("span", null, _toDisplayString(_ctx.course.additionalInformation), 1)
          ])
        ]))
      : _createCommentVNode("", true),
    (_unref(isCapstoneTermProject)(_ctx.course.courseRequestType))
      ? (_openBlock(), _createElementBlock("tr", _hoisted_11, [
          _cache[21] || (_cache[21] = _createElementVNode("td", null, "Type of Project", -1)),
          _createElementVNode("td", null, _toDisplayString(_ctx.course.typeProject), 1)
        ]))
      : _createCommentVNode("", true),
    (_unref(isCapstoneTermProject)(_ctx.course.courseRequestType))
      ? (_openBlock(), _createElementBlock("tr", _hoisted_12, [
          _cache[22] || (_cache[22] = _createElementVNode("td", null, "Project Description", -1)),
          _createElementVNode("td", null, _toDisplayString(_ctx.course.projectDescr), 1)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("tr", null, [
      _cache[23] || (_cache[23] = _createElementVNode("td", null, "Status", -1)),
      _createElementVNode("td", null, _toDisplayString(_ctx.course.status), 1)
    ]),
    _createElementVNode("tr", null, [
      _cache[24] || (_cache[24] = _createElementVNode("td", null, "Reason Delete", -1)),
      _createElementVNode("td", null, _toDisplayString(_ctx.course.reasonDelete), 1)
    ])
  ]))
}
}

})