<template>
  <img
    v-if="currentUser.fileName"
    id="profile-img"
    :src="websiteURL + 'profile_picture/' + currentUser.fileName"
    class="profile-img-card"
  />
  <img
    v-else
    id="profile-img"
    src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
    class="profile-img-card"
  />
  <div @click="showPopup">
    <font-awesome-icon icon="fa-solid fa-camera" class="update-picture-icon" />
  </div>
  <PopupModal :visible="isVisible" @close="hidePopup">
    <template v-slot:title> Update profile picture</template>
    <template v-slot:content>
      <div id="popup-message">
        <form @submit.prevent="uploadData" enctype="multipart/form-data">
          <input
            type="file"
            name="file"
            v-on:change="uploadFile"
            accept="image/*"
            class="form-control"
          />
          <br />
          <div class="form-group text-align-center">
            <button
              type="submit"
              class="btn btn-primary btn-block"
              :disabled="loading"
            >
              <span
                v-show="loading"
                class="spinner-border spinner-border-sm"
              ></span>
              <span>Upload</span>
            </button>
          </div>
        </form>
      </div>
    </template>
  </PopupModal>
</template>

<script setup lang="ts">
import { showToast, websiteURL } from "@/utils";
import PopupModal from "@/components/user/PopupModal.vue";

import { useAuthStore } from "@/stores/AuthStore";
import { ref } from "vue";
import AuthService from "@/services/auth.service.js";
const AuthStore = useAuthStore();

let currentUser = AuthStore.auth;

let isVisible = ref(false);

const showPopup = () => {
  isVisible.value = true;
};

const hidePopup = () => {
  isVisible.value = false;
};

const uploadFile = (e) => {
  files = e.target.files || e.dataTransfer.files;
  if (!files.length) return;
};

let files;
let loading = ref(false);
let message = ref("");

const uploadData = () => {
  loading.value = true;
  AuthService.saveProfilePicture(files).then(
    (response) => {
      AuthStore.getProfile().then(
        () => {
          window.location.reload();
          showToast(response.data.message);
          loading.value = false;
          isVisible.value = false;
        },
        (err) => {
          loading.value = false;
          message.value =
            (err.response && err.response.data && err.response.data.message) ||
            err.message ||
            err.toString();
        }
      );
    },
    (error) => {
      loading.value = false;
      message.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};
</script>

<style scoped>
.update-picture-icon {
  position: relative;
  top: -35px;
  left: 155px;
  font-size: larger;
  padding: 0.4rem;
  background: #f0f2f5;
  color: #000;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  cursor: pointer;
}
</style>
