<template>
  <Form
    @submit="saveSite"
    :validation-schema="schema"
    v-slot="{ values }"
    :initial-values="formValues"
  >
    <div>
      <label for="city"
        >Location of Job Site<span class="super font-color-red">*</span></label
      >
      <div class="form-column">
        <div
          class="form-group"
          v-if="!siteVisitFields.find((el) => el.name === 'state')?.disabled"
        >
          <Field
            v-model="values.state"
            name="state"
            type="text"
            class="form-control"
            v-slot="{ field }"
          >
            <Multiselect
              v-bind="field"
              v-model="values.state"
              :options="stateOptions"
              mode="single"
              :searchable="true"
              placeholder="State"
              @change="getCityOptions(values.state), (values.city = '')"
            />
          </Field>
          <ErrorMessage name="state" class="error-feedback" />
        </div>
        <div
          class="form-group"
          v-if="!siteVisitFields.find((el) => el.name === 'city')?.disabled"
        >
          <Field
            v-model="values.city"
            name="city"
            type="text"
            class="dropdown"
            v-slot="{ field }"
          >
            <Multiselect
              v-bind="field"
              v-model="values.city"
              :options="cityOptions"
              mode="single"
              :searchable="true"
              placeholder="City"
            />
          </Field>
          <ErrorMessage name="city" class="error-feedback" />
        </div>
      </div>
    </div>
    <div
      class="form-group"
      v-if="!siteVisitFields.find((el) => el.name === 'address')?.disabled"
    >
      <label for="address"
        >Specific Address of Job Site<span class="super font-color-red"
          >*</span
        ></label
      >
      <Field name="address" type="text" class="form-control" />
      <ErrorMessage name="address" class="error-feedback" />
    </div>
    <div
      class="form-group"
      v-if="!siteVisitFields.find((el) => el.name === 'noOfStudent')?.disabled"
    >
      <label for="noOfStudent"
        >Number of Students that can be Accommodated<span
          class="super font-color-red"
          >*</span
        ></label
      >
      <Field name="noOfStudent" type="text" class="form-control" />
      <ErrorMessage name="noOfStudent" class="error-feedback" />
    </div>
    <div
      class="form-group"
      v-if="!siteVisitFields.find((el) => el.name === 'physicalDis')?.disabled"
    >
      <label for="physicalDis"
        >Type of Physical Disabilities that can be Accommodated<span
          class="super font-color-red"
          >*</span
        ></label
      >
      <Field
        v-model="values.physicalDis"
        name="physicalDis"
        type="text"
        class="form-control"
        v-slot="{ field }"
      >
        <Multiselect
          v-bind="field"
          v-model="values.physicalDis"
          :options="physicalDisOptions"
          mode="tags"
          :searchable="true"
          :multipleLabel="displayLabels"
          :hideSelected="false"
          :closeOnSelect="false"
          :closeOnDeselect="false"
          @change="validateOptNone"
        >
          <template v-slot:option="{ option }">
            <input
              type="checkbox"
              class="input-pointer"
              :checked="values.physicalDis.includes(option.value)"
            />
            &nbsp; {{ option.label }}
          </template>
        </Multiselect>
      </Field>
      <div v-if="values.physicalDis && values.physicalDis.includes('Others')">
        <Field
          name="physicalDis_text"
          type="text"
          placeholder="Please Specify"
          class="form-control"
        />
      </div>
      <ErrorMessage name="physicalDis" class="error-feedback" />
    </div>
    <div class="form-group" v-if="values.physicalDis.includes('None')">
      <label for="physicalDisReason"
        >Why can't you accommodate students with disabilities?</label
      >
      <Field name="physicalDisReason" type="text" class="form-control" />
      <ErrorMessage name="physicalDisReason" class="error-feedback" />
    </div>
    <div
      class="form-column"
      v-if="!siteVisitFields.find((el) => el.name === 'limitAccess')?.disabled"
    >
      <div class="form-group">
        <label for="limitAccess"
          >Limit/Extent of Access to Site Allow<span
            class="super font-color-red"
            >*</span
          ></label
        >
        <Field
          v-model="values.limitAccess"
          name="limitAccess"
          type="text"
          class="dropdown"
          v-slot="{ field }"
        >
          <multiselect
            v-bind="field"
            v-model="values.limitAccess"
            :options="['Full Access', 'Limited Access']"
            :searchable="true"
            mode="single"
          />
        </Field>
        <ErrorMessage name="limitAccess" class="error-feedback" />
      </div>
      <div
        class="form-group"
        v-if="
          !siteVisitFields.find((el) => el.name === 'durationSiteVisit')
            ?.disabled
        "
      >
        <label for="durationSiteVisit"
          >Duration of Site Visit that can be Allowed<span
            class="super font-color-red"
            >*</span
          ></label
        >
        <Field name="durationSiteVisit" class="form-control" type="text" />
        <ErrorMessage name="durationSiteVisit" class="error-feedback" />
      </div>
    </div>
    <div
      class="form-group"
      v-if="!siteVisitFields.find((el) => el.name === 'projectDescr')?.disabled"
    >
      <label for="projectDescr"
        >Title and Description of Project<span class="super font-color-red"
          >*</span
        ></label
      >
      <Field name="projectDescr" type="text" class="form-control" />
    </div>
    <div
      class="form-group"
      v-if="!siteVisitFields.find((el) => el.name === 'projectStage')?.disabled"
    >
      <label for="projectStage"
        >Project Stage<span class="super font-color-red">*</span></label
      >
      <Field
        v-model="values.projectStage"
        name="projectStage"
        type="text"
        class="form-control"
        v-slot="{ field }"
      >
        <Multiselect
          v-bind="field"
          v-model="values.projectStage"
          :options="projectStageOptions"
          mode="tags"
          :searchable="true"
          :multipleLabel="displayLabels"
          :hideSelected="false"
          :closeOnSelect="false"
          :closeOnDeselect="false"
        >
          <template v-slot:option="{ option }">
            <input
              type="checkbox"
              class="input-pointer"
              :checked="values.projectStage.includes(option.value)"
            />
            &nbsp; {{ option.label }}
          </template>
        </Multiselect>
      </Field>
      <div v-if="values.projectStage && values.projectStage.includes('Others')">
        <Field
          name="projectStage_text"
          type="text"
          placeholder="Please Specify"
          class="form-control"
        />
      </div>
      <ErrorMessage name="projectStage" class="error-feedback" />
    </div>
    <div class="form-group">
      <label for="projectOverviewDuration">General Project Overview</label>
      <div class="form-project-overview-column">
        <Field
          v-if="
            !siteVisitFields.find((el) => el.name === 'projectOverviewDuration')
              ?.disabled
          "
          name="projectOverviewDuration"
          type="text"
          class="form-control"
          placeholder="Duration (in months)"
        />
        <Field
          v-if="
            !siteVisitFields.find((el) => el.name === 'projectOverviewCost')
              ?.disabled
          "
          name="projectOverviewCost"
          type="text"
          class="form-control"
          placeholder="Cost (in dollars)"
        />
        <Field
          v-if="
            !siteVisitFields.find((el) => el.name === 'projectOverviewClient')
              ?.disabled
          "
          name="projectOverviewClient"
          type="text"
          class="form-control"
          placeholder="Owner"
        />
      </div>
      <ErrorMessage name="projectOverviewDuration" class="error-feedback" />
      <ErrorMessage name="projectOverviewCost" class="error-feedback" />
      <ErrorMessage name="projectOverviewClient" class="error-feedback" />
    </div>
    <div
      class="form-group"
      v-if="
        !siteVisitFields.find((el) => el.name === 'projectCompletionDate')
          ?.disabled
      "
    >
      <label for="projectCompletionDate"
        >Probable Project Completion Date<span class="super font-color-red"
          >*</span
        ></label
      >
      <Field
        name="projectCompletionDate"
        v-model="values.projectCompletionDate"
        v-slot="{ handleChange, handleBlur }"
      >
        <va-date-input
          v-model="values.projectCompletionDate"
          background="#fff"
          manual-input
          style="border: none; width: 100%"
          @change="handleChange"
          @blur="handleBlur"
        />
      </Field>
    </div>
    <div
      class="form-group"
      v-if="
        !siteVisitFields.find((el) => el.name === 'safetyRequirement')?.disabled
      "
    >
      <label for="safetyRequirement"
        >Safety Requirement<span class="super font-color-red">*</span></label
      >
      <Field
        v-model="values.safetyRequirement"
        name="safetyRequirement"
        type="text"
        class="form-control"
        v-slot="{ field }"
      >
        <Multiselect
          v-bind="field"
          v-model="values.safetyRequirement"
          :options="safetyReqOptions"
          mode="tags"
          :searchable="true"
          :multipleLabel="displayLabels"
          :hideSelected="false"
          :closeOnSelect="false"
          :closeOnDeselect="false"
          @change="validateOptSafetyReq"
        >
          <template v-slot:option="{ option }">
            <input
              type="checkbox"
              class="input-pointer"
              :checked="values.safetyRequirement.includes(option.value)"
            />
            &nbsp; {{ option.label }}
          </template>
        </Multiselect>
      </Field>
      <div
        v-if="
          values.safetyRequirement &&
          values.safetyRequirement.includes('Others')
        "
      >
        <Field
          name="safetyRequirement_text"
          type="text"
          placeholder="Please Specify"
          class="form-control"
        />
      </div>
      <ErrorMessage name="safetyRequirement" class="error-feedback" />
    </div>
    <div
      class="form-group"
      v-if="
        !siteVisitFields.find((el) => el.name === 'safetyReqAvailability')
          ?.disabled
      "
    >
      <label for="safetyReqAvailability"
        >Will you provide the required PPE?<span class="super font-color-red"
          >*</span
        ></label
      >
      <Field
        v-model="values.safetyReqAvailability"
        name="safetyReqAvailability"
        type="text"
        class="dropdown"
        v-slot="{ field }"
      >
        <multiselect
          v-bind="field"
          v-model="values.safetyReqAvailability"
          :options="[
            { label: 'Yes, we would', value: 'Yes' },
            { label: 'No, students would have to come with them', value: 'No' },
            {
              label: 'We would provide only the ones listed below',
              value: 'some',
            },
          ]"
          :searchable="true"
          mode="single"
        />
      </Field>
      <div
        v-if="
          !siteVisitFields.find(
            (el) => el.name === 'safetyReqAvailability_text'
          )?.disabled &&
          values.safetyReqAvailability &&
          values.safetyReqAvailability === 'some'
        "
      >
        <Field
          name="safetyReqAvailability_text"
          type="text"
          placeholder="Please Specify"
          class="form-control"
        />
      </div>
      <ErrorMessage name="safetyReqAvailability_text" class="error-feedback" />
    </div>

    <div
      class="form-group"
      v-if="
        !siteVisitFields.find((el) => el.name === 'availSeniorPersonnel')
          ?.disabled
      "
    >
      <label for="availSeniorPersonnel"
        >Availability of Senior Personnel During Site Visit<span
          class="super font-color-red"
          >*</span
        ></label
      >
      <Field
        v-model="values.availSeniorPersonnel"
        name="availSeniorPersonnel"
        type="text"
        class="dropdown"
        v-slot="{ field }"
      >
        <multiselect
          v-bind="field"
          v-model="values.availSeniorPersonnel"
          :options="['Yes', 'No', 'Not Sure']"
          :searchable="true"
          mode="single"
        />
      </Field>
      <ErrorMessage name="availSeniorPersonnel" class="error-feedback" />
    </div>
    <div
      class="form-group"
      v-if="
        !siteVisitFields.find((el) => el.name === 'siteSpecificRequirements')
          ?.disabled
      "
    >
      <label for="siteSpecificRequirements">Site Specific Requirements</label>
      <Field name="siteSpecificRequirements" type="text" class="form-control" />
      <ErrorMessage name="siteSpecificRequirements" class="error-feedback" />
    </div>

    <div class="form-group text-align-center mt-3">
      <button class="btn btn-primary btn-block" :disabled="loading">
        <span v-show="loading" class="spinner-border spinner-border-sm"></span>
        <span v-if="props.action === 'update'">Update Site</span>
        <span v-else>Add Site</span>
      </button>
    </div>
  </Form>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { Site } from "@/types";
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import {
  physicalDisOptions,
  safetyReqOptions,
  stateOptions,
  projectStageOptions,
  displayLabels,
  validateOptNone,
  validateOptions,
} from "@/utils";
import Multiselect from "@vueform/multiselect";
import { useConfigStore } from "@/stores/ConfigStore";
import UserService from "@/services/user.service.js";

// eslint-disable-next-line no-undef
const props = defineProps<{
  action: string;
  site: Site;
}>();
// eslint-disable-next-line no-undef
const emits = defineEmits(["saveSite"]);
const ConfigStore = useConfigStore();
const siteVisitFields = ConfigStore.config.filter(
  (el) => el.table === "site_visit"
);

let loading = ref(false);
let message = ref("");

const schema = yup.object().shape({
  fields: yup.array().default(siteVisitFields),
  city: yup
    .string()
    .label("Location of institution")
    .when("fields", {
      is: (val) => !val.find((el) => el.name === "city")?.disabled,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.optional().nullable(),
    }),
  state: yup
    .string()
    .label("Location of institution")
    .when("fields", {
      is: (val) => !val.find((el) => el.name === "state")?.disabled,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.optional().nullable(),
    }),
  noOfStudent: yup
    .string()
    .label("Number of students that can be accommodated")
    .when("fields", {
      is: (val) => !val.find((el) => el.name === "noOfStudent")?.disabled,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.optional().nullable(),
    }),
  limitAccess: yup
    .string()
    .label("Limit/Extent of access to site allow")
    .when("fields", {
      is: (val) => !val.find((el) => el.name === "limitAccess")?.disabled,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.optional().nullable(),
    }),
  projectDescr: yup
    .string()
    .label("Title and Description of Project")
    .when("fields", {
      is: (val) => !val.find((el) => el.name === "projectDescr")?.disabled,
      then: (schema) =>
        schema.required("Title and Description of Project is required!"),
      otherwise: (schema) => schema.optional().nullable(),
    }),
  projectStage: yup
    .array()
    .of(yup.string())
    .label("Project Stage")
    .when("fields", {
      is: (val) => !val.find((el) => el.name === "projectStage")?.disabled,
      then: (schema) => schema.required().min(1, "Project Stage is required!"),
      otherwise: (schema) => schema.optional().nullable(),
    }),
  projectOverviewDuration: yup.string().optional().nullable(),
  projectOverviewCost: yup.string().optional().nullable(),
  projectOverviewClient: yup.string().optional().nullable(),
  safetyRequirement: yup
    .array()
    .of(yup.string())
    .when("fields", {
      is: (val) => !val.find((el) => el.name === "safetyRequirement")?.disabled,
      then: (schema) =>
        schema.required().min(1, "Safety Requirement is required!"),
      otherwise: (schema) => schema.optional().nullable(),
    }),
  availSeniorPersonnel: yup
    .string()
    .label("Availability of senior personnel during site visit")
    .when("fields", {
      is: (val) =>
        !val.find((el) => el.name === "availSeniorPersonnel")?.disabled,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.optional().nullable(),
    }),
  address: yup
    .string()
    .label("Specific Address of Job Site")
    .when("fields", {
      is: (val) => !val.find((el) => el.name === "address")?.disabled,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.optional().nullable(),
    }),
  physicalDisReason: yup
    .string()
    .optional()
    .nullable()
    .label("Why can't you accommodate students with disabilities"),
  durationSiteVisit: yup
    .string()
    .label("Duration of Site Visit that can be Allowed")
    .when("fields", {
      is: (val) => !val.find((el) => el.name === "durationSiteVisit")?.disabled,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.optional().nullable(),
    }),
  projectCompletionDate: yup
    .string()
    .label("Probable Project Completion Date")
    .when("fields", {
      is: (val) =>
        !val.find((el) => el.name === "projectCompletionDate")?.disabled,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.optional().nullable(),
    }),
  safetyReqAvailability: yup
    .string()
    .label("Will you provide the required PPE")
    .when("fields", {
      is: (val) =>
        !val.find((el) => el.name === "safetyReqAvailability")?.disabled,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.optional().nullable(),
    }),
  safetyReqAvailability_text: yup.string().when("safetyReqAvailability", {
    is: (val) => val === "some",
    then: (schema) =>
      schema
        .required("Specifying the  safety items you will provide is required!")
        .label("Specifying the  safety items you will provide"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  siteSpecificRequirements: yup
    .string()
    .optional()
    .nullable()
    .label("Site Specific Requirements"),
});

const validateOptSafetyReq = (e, s) => {
  validateOptions(e, s, "All");
  validateOptions(e, s, "Not Required");
};

let initialState = {
  availSeniorPersonnel: "",
  city: "",
  state: "",
  limitAccess: "",
  noOfStudent: "",
  physicalDis: [] as string[],
  physicalDis_text: "",
  physicalDisReason: "",
  projectStage: [] as string[],
  projectStage_text: "",
  safetyRequirement: [] as string[],
  safetyRequirement_text: "",
  safetyReqAvailability: "",
  safetyReqAvailability_text: "",
  projectOverviewDuration: "",
  projectOverviewCost: "",
  projectOverviewClient: "",
  durationSiteVisit: "",
  projectCompletionDate: "",
  siteSpecificRequirements: "",
  address: "",
  projectDescr: "",
};

if (props.action === "update") {
  if (props.site.physicalDis.includes("Others")) {
    initialState["physicalDis_text"] = props.site.physicalDis.at(-1) || "";
    initialState.physicalDis = props.site.physicalDis.slice(0, -1);
  } else {
    // eslint-disable-next-line vue/no-setup-props-destructure
    initialState.physicalDis = props.site.physicalDis;
  }
  if (props.site.projectStage.includes("Others")) {
    initialState["projectStage_text"] = props.site.projectStage.at(-1) || "";
    initialState.projectStage = props.site.projectStage.slice(0, -1);
  } else {
    // eslint-disable-next-line vue/no-setup-props-destructure
    initialState.projectStage = props.site.projectStage;
  }
  if (props.site.safetyRequirement.includes("Others")) {
    initialState["safetyRequirement_text"] =
      props.site.safetyRequirement.at(-1) || "";
    initialState.safetyRequirement = props.site.safetyRequirement.slice(0, -1);
  } else {
    // eslint-disable-next-line vue/no-setup-props-destructure
    initialState.safetyRequirement = props.site.safetyRequirement;
  }
}

const formValues = {
  availSeniorPersonnel: props.site?.availSeniorPersonnel,
  city: props.site?.city,
  state: props.site?.state,
  limitAccess: props.site?.limitAccess,
  noOfStudent: props.site?.noOfStudent,
  physicalDis: initialState.physicalDis,
  physicalDis_text: initialState.physicalDis_text,
  projectStage: initialState.projectStage,
  projectStage_text: initialState.projectStage_text,
  safetyRequirement: initialState.safetyRequirement,
  safetyRequirement_text: initialState.safetyRequirement_text,
  projectOverviewDuration: props.site?.projectOverviewDuration,
  projectOverviewCost: props.site?.projectOverviewCost,
  projectOverviewClient: props.site?.projectOverviewClient,
  address: props.site?.address,
  projectDescr: props.site?.projectDescr,
  physicalDisReason: props.site?.physicalDisReason,
  durationSiteVisit: props.site?.durationSiteVisit,
  projectCompletionDate: props.site?.projectCompletionDate,
  safetyReqAvailability: props.site?.safetyReqAvailability,
  safetyReqAvailability_text: props.site?.safetyReqAvailability_text,
  siteSpecificRequirements: props.site?.siteSpecificRequirements,
};

const saveSite = (data) => {
  // console.log(data);
  if (data.projectStage && data.projectStage === "Others")
    data.projectStage = data["projectStage_text"];
  if (data.safetyRequirement && data.safetyRequirement.includes("Others"))
    data.safetyRequirement.push(data["safetyRequirement_text"]);
  if (data.physicalDis && data.physicalDis.includes("Others"))
    data.physicalDis.push(data["physicalDis_text"]);
  emits("saveSite", props.action, data);
};

const cityOptions = ref([] as any[]);

const getCityOptions = (state) => {
  console.log(state);
  UserService.getCities(state).then(
    (response) => {
      console.log(response.data);
      cityOptions.value = response.data;
    },
    (error) => {
      console.log(error);
      message.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};

getCityOptions(props.site?.state);
</script>

<style scoped></style>
