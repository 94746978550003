import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, withCtx as _withCtx, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "min-height header-space" }
const _hoisted_2 = { class: "card card-container" }
const _hoisted_3 = { class: "form-group" }
const _hoisted_4 = { class: "form-group text-align-center" }
const _hoisted_5 = ["disabled"]
const _hoisted_6 = { class: "spinner-border spinner-border-sm" }

import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import { ref } from "vue";
import AuthService from "@/services/auth.service";


export default /*@__PURE__*/_defineComponent({
  __name: 'ForgotPasswordView',
  setup(__props) {

const schema = yup.object().shape({
  email: yup.string().required("Username is required!"),
});

let loading = ref(false);
let successful = ref(false);
let message = ref("");

const handleForgotPassword = (data) => {
  loading.value = true;
  AuthService.forgotPassword(data).then(
    (res) => {
      message.value = res.data.message;
      successful.value = true;
      loading.value = false;
    },
    (error) => {
      loading.value = false;
      message.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[3] || (_cache[3] = _createElementVNode("h5", { class: "text-align-center bold-text" }, "Account Recovery", -1)),
      _cache[4] || (_cache[4] = _createElementVNode("div", { class: "text-align-center" }, " Please enter the email address for which you want to reset your password ", -1)),
      _createVNode(_unref(Form), {
        onSubmit: handleForgotPassword,
        "validation-schema": _unref(schema)
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            _cache[0] || (_cache[0] = _createElementVNode("label", { for: "email" }, "Email", -1)),
            _createVNode(_unref(Field), {
              name: "email",
              type: "text",
              class: "form-control"
            }),
            _createVNode(_unref(ErrorMessage), {
              name: "email",
              class: "error-feedback"
            })
          ]),
          _cache[2] || (_cache[2] = _createElementVNode("br", null, null, -1)),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("button", {
              class: "btn btn-primary btn-block",
              disabled: _unref(loading)
            }, [
              _withDirectives(_createElementVNode("span", _hoisted_6, null, 512), [
                [_vShow, _unref(loading)]
              ]),
              _cache[1] || (_cache[1] = _createElementVNode("span", null, "Send Password Reset Link", -1))
            ], 8, _hoisted_5)
          ])
        ]),
        _: 1
      }, 8, ["validation-schema"]),
      _cache[5] || (_cache[5] = _createElementVNode("br", null, null, -1)),
      (_unref(message))
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass([_unref(successful) ? 'alert alert-success' : 'error-feedback', "text-align-center"])
          }, _toDisplayString(_unref(message)), 3))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})