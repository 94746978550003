<template>
  <Form
    @submit="saveFAQ"
    :validation-schema="schema"
    :initial-values="formValues"
  >
    <Field name="id" hidden />
    <div class="form-group">
      <label for="userQuestion"
        >User Question <span class="super font-color-red">*</span></label
      >
      <Field name="userQuestion" v-slot="{ field }">
        <input v-bind="field" class="form-control" type="text" />
        <ErrorMessage name="userQuestion" class="error-feedback" />
      </Field>
    </div>
    <div class="form-group">
      <label for="answer"
        >Answer<span class="super font-color-red">*</span></label
      >
      <Field
        name="answer"
        type="text"
        as="textarea"
        class="form-control"
        style="height: 200px"
      />
    </div>
    <div class="form-group">
      <div class="form-check-inline">
        <label for="display"
          >Display on FAQ Page<span class="super font-color-red">*</span></label
        >
        <Field
          name="display"
          class="form-check-input"
          type="radio"
          value="true"
        />Yes
      </div>
      <div class="form-check-inline ml-3">
        <Field
          name="display"
          class="form-check-input"
          type="radio"
          value="false"
        />No
      </div>
      <ErrorMessage name="display" class="error-feedback" />
    </div>
    <div class="form-group text-align-center">
      <button class="btn btn-primary btn-block" :disabled="loading">
        <span v-show="loading" class="spinner-border spinner-border-sm"></span>
        Save
      </button>
    </div>
  </Form>
</template>

<script setup lang="ts">
import { ref } from "vue";
import * as yup from "yup";
import { Form, Field, ErrorMessage } from "vee-validate";
import { FAQ } from "@/types";
import AdminService from "@/services/admin.service.js";
import { showToast } from "@/utils";

let loading = ref(false);
let message = ref("");

// eslint-disable-next-line no-undef
const props = defineProps<{
  faq: FAQ;
}>();
// eslint-disable-next-line no-undef
const emits = defineEmits(["saveFAQ"]);

let initialState = {
  id: 0,
  userQuestion: "",
  answer: "",
  display: false,
};

const formValues = {
  id: props.faq?.id,
  userQuestion: props.faq?.userQuestion,
  answer: props.faq?.answer,
  display: props.faq?.display ? props.faq?.display.toString() : "",
};

const schema = yup.object().shape({
  userQuestion: yup
    .string()
    .trim()
    .required("User Question is required!")
    .min(1, "User Question is required!")
    .max(1000, "User Question cannot be greater than 1000 characters"),
  answer: yup
    .string()
    .trim()
    .required("Answer is required!")
    .min(1, "Answer is required!")
    .max(100000, "Answer cannot be greater than 100000 characters"),
  display: yup.string().required("Display on FAQ Page is required!"),
});

const saveFAQ = (values) => {
  AdminService.saveFAQ(values).then(
    (response) => {
      console.log(response);
      emits("saveFAQ");
      showToast("The FAQ has been saved/updated.");
    },
    (error) => {
      loading.value = false;
      message.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};
</script>

<style scoped></style>
