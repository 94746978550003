<template>
  <div class="grid-container">
    <app-sidebar />
    <div class="min-height mt-3">
      <div class="back-grid">
        <back-button></back-button>
        <h2 style="text-align: center; font-weight: bold">Search Results</h2>
      </div>
      <div v-if="searchResults.length > 0" class="card-container card mt-3">
        <ul>
          <li v-for="user in searchResults" :key="user.id">
            <div v-if="user.nameOrg" class="search-results-list">
              <div>
                <img
                  v-if="user.fileName"
                  id="profile-img"
                  :src="websiteURL + 'profile_picture/' + user.fileName"
                  class="search-img-card"
                />
                <img
                  v-else
                  id="profile-img"
                  src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
                  class="search-img-card"
                />
              </div>
              <div class="name-position-card">
                <div>
                  <span class="name">
                    {{ user.firstName + " " + user.lastName }}
                  </span>
                  <br />
                  <span class="sub-heading">
                    {{ user.position + " at " + user.nameOrg }}
                  </span>
                </div>
                <div>
                  <router-link
                    :to="{
                      name: 'search-profile',
                      params: { userId: user.id },
                    }"
                  >
                    <button class="btn btn-primary">View Profile</button>
                  </router-link>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="m-1 mt-2 text-align-center" v-else>
        No Search Results for {{ route.params.keyword }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import { websiteURL } from "@/utils.ts";
import AppSidebar from "@/components/user/AppSidebar.vue";
import BackButton from "@/components/user/BackButton.vue";
import UserService from "@/services/user.service.js";
import { useRoute, useRouter } from "vue-router";

const route = useRoute();
const router = useRouter();

let searchResults = ref([]);

const getSearchResults = (data) => {
  UserService.getSearchResults(data).then(
    (response) => {
      searchResults.value = response.data;
    },
    (error) => {
      const res =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(res);
    }
  );
};

watch(
  () => route.params.keyword,
  (newKeyword) => {
    getSearchResults(newKeyword as string);
  },
  { immediate: true }
);
</script>

<style scoped>
h3 {
  font-size: 1.25rem;
  word-break: break-word;
  word-wrap: break-word;
  font-weight: 700;
  text-align: left;
  line-height: 1.2;
}

.card-container.card {
  max-width: 950px !important;
  width: 800px;
  padding: 1rem 1rem 1rem 1rem;
  margin: 1rem auto;
  box-shadow: 0px 0px 10px 4px rgb(0 0 0 / 30%);
  border-radius: 6px;
  background: #fafafa;
}

.search-results-list {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 0.5rem;
}

.search-results-list:hover {
  background: rgba(15, 20, 25, 0.1);
}

.name-position-card {
  padding: 0.25rem 0.5rem 0.25rem 0.25rem;
  text-decoration: none;
  color: black;
  flex-grow: 1;
  margin-left: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.name {
  font-weight: 600;
  font-size: 1.0625rem;
}

.sub-heading {
  font-weight: 400;
  text-align: left;
  word-wrap: break-word;
  word-break: break-word;
  color: #65676b;
  font-size: 0.9375rem;
  display: block;
}

@media only screen and (max-width: 768px) {
  .card-container.card {
    width: 85%;
    padding: 0.5rem;
  }

  .search-results-list,
  .name-card {
    flex-direction: column;
  }

  .sub-heading {
    display: none;
  }

  .btn {
    padding: 0.25rem;
  }
}
</style>
