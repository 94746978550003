import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "grid-container" }
const _hoisted_2 = { class: "container min-height" }
const _hoisted_3 = { class: "hidden ml-1 lg:inline-block" }
const _hoisted_4 = { class: "inline-block ml-1 lg:hidden" }
const _hoisted_5 = {
  key: 0,
  class: "tab-count"
}
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { class: "paginator" }
const _hoisted_8 = {
  key: 1,
  class: "mt-3"
}
const _hoisted_9 = {
  key: 0,
  style: {"text-align":"center","font-style":"italic"}
}
const _hoisted_10 = {
  key: 1,
  style: {"text-align":"center","font-style":"italic"}
}

import { ref } from "vue";
import InstructorSidebar from "@/components/instructor/InstructorSidebar.vue";
import CourseRequestItem from "@/components/instructor/CourseRequestItem.vue";
import UserList from "@/components/user/UserList.vue";
import InstructorService from "../../services/instructor.service.js";
import UserService from "@/services/user.service.js";

import { useRouter, useRoute } from "vue-router";
import { useAuthStore } from "@/stores/AuthStore";
import { useCourseStore } from "@/stores/CourseStore";
import { useFeedStore } from "@/stores/FeedStore";


export default /*@__PURE__*/_defineComponent({
  __name: 'FeedView',
  setup(__props) {

const AuthStore = useAuthStore();
const CourseStore = useCourseStore();
const FeedStore = useFeedStore();

const router = useRouter();
const route = useRoute();

let currentUser = AuthStore.auth;
let loading = ref(false);
let successful = ref(false);
let message = ref("");
let visible = ref(false);

const content = ref([]);
const pastCourse = ref([]);
const futureCourse = ref([]);

const futureCurrentPage = ref(1);
const futureTotalPages = ref(1);
const pastCurrentPage = ref(1);
const pastTotalPages = ref(1);

if (route.query["redirect"]) {
  const redirectURL = route.query["redirect"] as string;
  const notificationId = redirectURL.split("/").pop();
  UserService.getNotification(notificationId).then(
    (response) => {
      const notification = response.data;
      notification.course_request.user = notification.user;
      CourseStore.addCourse(notification.course_request);
      if (AuthStore.role === "INDUSTRYPRACTITIONER")
        router.push({ name: "view-course-request-prac" });
      else if (AuthStore.role === "INSTRUCTOR") {
        CourseStore.setPractitioner(notification.user.id);
        router.push({ name: "view-industry-practitioner-ins" });
      } else router.push("/home");
    },
    (error) => {
      loading.value = false;
      router.push("/home");
    }
  );
}

const checkFeedbackExits = (course) => {
  UserService.checkFeedbackExists(course.id).then(
    (response) => {
      if (response.status == "200")
        course.feedbackExists = response.data.feedbackExists;
    },
    (error) => {
      loading.value = false;
      message.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};

const getCourseRequest = (type, pageNumber = 1) => {
  InstructorService.getInstructorBoard(pageNumber, type).then(
    (response) => {
      if (type === "past") {
        pastCourse.value = response.data.courses;
        pastTotalPages.value = response.data.totalPages;
        pastCurrentPage.value = response.data.currentPage;
        pastCourse.value.forEach((course) => {
          checkFeedbackExits(course);
        });
      } else {
        futureCourse.value = response.data.courses;
        futureTotalPages.value = response.data.totalPages;
        futureCurrentPage.value = response.data.currentPage;
      }
    },
    (error) => {
      content.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};

getCourseRequest("present");
getCourseRequest("past");

// let imageObjectURL = ref("");
// let imageObjectURL2 = ref("");
//
// function blobToData(blob) {
//   console.log(typeof blob);
//   return new Promise((resolve) => {
//     const reader = new FileReader();
//     reader.onloadend = () => resolve(reader.result);
//     reader.readAsDataURL(blob);
//   });
// }
//
// const getUpload = (fileName) => {
//   UserService.getUpload(fileName).then(
//     async (response) => {
//       console.log(response);
//       console.log(typeof response.data);
//       if (fileName === "CEM 3154.PNG") {
//         imageObjectURL.value = await blobToData([response.data]);
//         console.log(imageObjectURL.value);
//       } else {
//         imageObjectURL2.value = await blobToData([response.data]);
//         console.log(imageObjectURL2.value);
//       }
//     },
//     (error) => {
//       let res =
//         (error.response &&
//           error.response.data &&
//           error.response.data.message) ||
//         error.message ||
//         error.toString();
//       console.log(res);
//     }
//   );
// };
//
// getUpload("CEM 3154.PNG");
// getUpload("Action Sens.png");

const tabs = ref([
  {
    id: 0,
    title: "Active Course-Support Requests",
    title_sm: "Active Requests",
    icon: "fa-regular fa-folder-open",
    type: "Active",
    content: futureCourse,
  },
  {
    id: 1,
    title: "Previous Course-Support Requests",
    title_sm: "Previous Requests",
    icon: "fa-regular fa-folder",
    type: "Previous",
    content: pastCourse,
  },
]);
const futureOnClick = (val) => {
  getCourseRequest("present", futureCurrentPage.value);
};
const pastOnClick = (val) => {
  getCourseRequest("past", pastCurrentPage.value);
};

return (_ctx: any,_cache: any) => {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_va_tab = _resolveComponent("va-tab")!
  const _component_va_tabs = _resolveComponent("va-tabs")!
  const _component_va_pagination = _resolveComponent("va-pagination")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(InstructorSidebar),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_va_tabs, {
        grow: "",
        modelValue: _unref(FeedStore).tab,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(FeedStore).tab) = $event))
      }, {
        tabs: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(tabs.value, (tab) => {
            return (_openBlock(), _createBlock(_component_va_tab, {
              key: tab.title
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", {
                  class: _normalizeClass([{ 'font-larger': _unref(FeedStore).tab === tab.id }, "tab-font"])
                }, [
                  _createVNode(_component_font_awesome_icon, {
                    icon: tab.icon
                  }, null, 8, ["icon"]),
                  _createElementVNode("span", _hoisted_3, _toDisplayString(tab.title), 1),
                  _createElementVNode("span", _hoisted_4, _toDisplayString(tab.title_sm), 1)
                ], 2),
                _cache[3] || (_cache[3] = _createTextVNode("   ")),
                (
                tab.id === 1 &&
                pastCourse.value.filter((el) => el.feedbackExists === false).length >
                  0
              )
                  ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(pastCourse.value.filter((el) => el.feedbackExists === false).length), 1))
                  : _createCommentVNode("", true)
              ]),
              _: 2
            }, 1024))
          }), 128))
        ]),
        _: 1
      }, 8, ["modelValue"]),
      (tabs.value[_unref(FeedStore).tab].content.length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createElementVNode("ul", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(tabs.value[_unref(FeedStore).tab].content, (course) => {
                return (_openBlock(), _createElementBlock("li", {
                  key: course.id
                }, [
                  _createVNode(CourseRequestItem, {
                    course: course,
                    type: tabs.value[_unref(FeedStore).tab].type,
                    onFetchCourse: getCourseRequest
                  }, null, 8, ["course", "type"])
                ]))
              }), 128))
            ]),
            _createElementVNode("div", _hoisted_7, [
              (_unref(FeedStore).tab === 0)
                ? (_openBlock(), _createBlock(_component_va_pagination, {
                    key: 0,
                    modelValue: futureCurrentPage.value,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((futureCurrentPage).value = $event)),
                    pages: futureTotalPages.value,
                    "visible-pages": 3,
                    hideOnSinglePage: true,
                    onClick: futureOnClick
                  }, null, 8, ["modelValue", "pages"]))
                : _createCommentVNode("", true),
              (_unref(FeedStore).tab === 1)
                ? (_openBlock(), _createBlock(_component_va_pagination, {
                    key: 1,
                    modelValue: pastCurrentPage.value,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((pastCurrentPage).value = $event)),
                    pages: pastTotalPages.value,
                    "visible-pages": 3,
                    hideOnSinglePage: true,
                    onClick: pastOnClick
                  }, null, 8, ["modelValue", "pages"]))
                : _createCommentVNode("", true)
            ])
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_8, [
            (_unref(FeedStore).tab === 0)
              ? (_openBlock(), _createElementBlock("h3", _hoisted_9, " No course-support request raised. "))
              : _createCommentVNode("", true),
            (_unref(FeedStore).tab === 1)
              ? (_openBlock(), _createElementBlock("h3", _hoisted_10, " There is no previous course-support at the moment. "))
              : _createCommentVNode("", true)
          ]))
    ]),
    _createVNode(UserList)
  ]))
}
}

})