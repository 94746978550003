<template>
  <div class="grid-container">
    <div v-if="currentUser.roles === 'INDUSTRYPRACTITIONER'">
      <practitioner-sidebar></practitioner-sidebar>
    </div>
    <div v-else-if="currentUser.roles === 'INSTRUCTOR'">
      <instructor-sidebar></instructor-sidebar>
    </div>
    <div class="container min-height">
      <div class="back-grid">
        <back-button></back-button>
        <h2 style="text-align: center; font-weight: bold">
          <span v-if="route.params.role === 'industrypractitioner'">
            Industry Practitioners
          </span>
          <span v-else>Instructors</span>
        </h2>
      </div>
      <br />
      <DataView :value="userList" layout="grid">
        <template #grid="slotProps">
          <div class="col-12 sm:col-6 lg:col-3 xl:col-2 p-1">
            <router-link
              :to="{
                name: 'user-profile',
                params: { userId: slotProps.data.id },
              }"
            >
              <div
                class="border-1 surface-border surface-card border-round cursor-pointer"
              >
                <img
                  v-if="slotProps.data.fileName"
                  id="profile-img"
                  :src="
                    websiteURL + 'profile_picture/' + slotProps.data.fileName
                  "
                  class="recommendation-img-card"
                />
                <img
                  v-else
                  id="profile-img"
                  src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
                  class="recommendation-img-card"
                />
                <div
                  class="flex flex-column sm:flex-row justify-content-between align-items-center flex-1"
                >
                  <div
                    class="flex flex-column align-items-center gap-3 text-decoration-none"
                    style="color: black"
                  >
                    {{ slotProps.data.firstName }} {{ slotProps.data.lastName }}
                  </div>
                </div>
              </div>
            </router-link>
          </div>
        </template>
      </DataView>
    </div>
  </div>
</template>

<script setup lang="ts">
import UserService from "@/services/user.service.js";
import { ref, watch } from "vue";
import { websiteURL } from "@/utils";
import { useRoute } from "vue-router";
import { useAuthStore } from "@/stores/AuthStore";
import PractitionerSidebar from "@/components/PractitionerSidebar.vue";
import InstructorSidebar from "@/components/instructor/InstructorSidebar.vue";
import BackButton from "@/components/user/BackButton.vue";
import DataView from "primevue/dataview";

const AuthStore = useAuthStore();

const currentUser = AuthStore.auth;
const route = useRoute();
const userList = ref([]);

const getUserList = (role) => {
  UserService.getUsers(role).then(
    (response) => {
      userList.value = response.data;
    },
    (error) => {
      let res =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(res);
    }
  );
};

watch(
  () => route.params.role,
  (role) => {
    getUserList(role as string);
  },
  { immediate: true }
);
</script>

<style scoped>
.recommendation-img-card {
  width: 7rem;
  height: 7rem;
  margin: 0 auto 10px;
  display: block;
}
</style>
