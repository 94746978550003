import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/site/mail-tick.png'


const _hoisted_1 = { class: "header-space min-height" }
const _hoisted_2 = { class: "text-align-center m-3 min-height" }
const _hoisted_3 = { class: "mt-5" }

import { ref, watch } from "vue";
import AuthService from "@/services/auth.service.js";
import { useRoute, useRouter } from "vue-router";
import { showErrorToast, showToast } from "@/utils";


export default /*@__PURE__*/_defineComponent({
  __name: 'GenVerLinkView',
  setup(__props) {

const route = useRoute();
const router = useRouter();

let loading = ref(false);
let successful = ref(false);
let message = ref("");
let userEmail = ref("");
userEmail.value = "nandaa@vt.edu";

const resendVerification = () => {
  loading.value = true;
  console.log(userEmail.value);
  AuthService.resendVerification(userEmail.value).then(
    (res) => {
      showToast(
        "Verification Mail Sent successfully. Please check your inbox."
      );
      loading.value = false;
    },
    (error) => {
      message.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      showErrorToast(message.value);
      loading.value = false;
    }
  );
};

watch(
  () => route.query.email,
  (email) => {
    userEmail.value = email as string;
  },
  { immediate: true }
);

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[10] || (_cache[10] = _createElementVNode("div", {
        class: "mt-3",
        style: {"font-size":"5rem"}
      }, [
        _createElementVNode("img", {
          src: _imports_0,
          class: "site-mail-verification-image"
        })
      ], -1)),
      _cache[11] || (_cache[11] = _createElementVNode("h2", {
        class: "mt-3",
        style: {"font-weight":"500"}
      }, " Verify your email to continue ", -1)),
      _createElementVNode("div", _hoisted_3, [
        _cache[1] || (_cache[1] = _createTextVNode(" Email : ")),
        _createElementVNode("i", null, _toDisplayString(_unref(userEmail)), 1),
        _cache[2] || (_cache[2] = _createTextVNode()),
        _cache[3] || (_cache[3] = _createElementVNode("br", null, null, -1)),
        _cache[4] || (_cache[4] = _createElementVNode("br", null, null, -1)),
        _cache[5] || (_cache[5] = _createTextVNode(" Verification Link expired? ")),
        _cache[6] || (_cache[6] = _createElementVNode("br", null, null, -1)),
        _createElementVNode("button", {
          class: "btn btn-outline-dark",
          onClick: resendVerification
        }, " Resend Verification Email "),
        _cache[7] || (_cache[7] = _createElementVNode("br", null, null, -1)),
        _cache[8] || (_cache[8] = _createElementVNode("br", null, null, -1)),
        _cache[9] || (_cache[9] = _createTextVNode(" Need Help? ")),
        _createVNode(_component_router_link, { to: { name: 'contact-us' } }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createTextVNode("Contact Support ")
          ])),
          _: 1
        })
      ])
    ])
  ]))
}
}

})