import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, createTextVNode as _createTextVNode, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "grid-container" }
const _hoisted_2 = { class: "col-md-12 mt-3 min-height" }
const _hoisted_3 = { class: "back-grid" }
const _hoisted_4 = { class: "form-grid flex flex-direction-column lg:flex-row" }
const _hoisted_5 = { class: "card card-container flex-basis-0" }
const _hoisted_6 = {
  key: 0,
  class: "form-group"
}
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { class: "form-column" }
const _hoisted_9 = {
  key: 0,
  class: "form-group"
}
const _hoisted_10 = {
  key: 1,
  class: "form-group"
}
const _hoisted_11 = { class: "form-column" }
const _hoisted_12 = {
  key: 0,
  class: "form-group"
}
const _hoisted_13 = { for: "gender" }
const _hoisted_14 = { key: 0 }
const _hoisted_15 = {
  key: 1,
  class: "form-group"
}
const _hoisted_16 = { for: "ethnicity" }
const _hoisted_17 = { key: 0 }
const _hoisted_18 = { class: "form-column" }
const _hoisted_19 = {
  key: 0,
  class: "form-group"
}
const _hoisted_20 = { for: "ageRange" }
const _hoisted_21 = {
  key: 1,
  class: "form-group"
}
const _hoisted_22 = { for: "yearsOfExp" }
const _hoisted_23 = {
  key: 1,
  class: "form-group"
}
const _hoisted_24 = { key: 0 }
const _hoisted_25 = {
  key: 2,
  class: "form-group"
}
const _hoisted_26 = { class: "card card-container flex-basis-0" }
const _hoisted_27 = {
  key: 0,
  class: "form-group"
}
const _hoisted_28 = { for: "insType" }
const _hoisted_29 = { key: 0 }
const _hoisted_30 = {
  key: 1,
  class: "form-group"
}
const _hoisted_31 = { key: 0 }
const _hoisted_32 = { class: "form-group" }
const _hoisted_33 = { for: "city" }
const _hoisted_34 = { key: 0 }
const _hoisted_35 = { class: "flex flex-direction-row gap-1" }
const _hoisted_36 = {
  key: 0,
  style: {"flex-basis":"0"}
}
const _hoisted_37 = {
  key: 1,
  style: {"flex-basis":"0"}
}
const _hoisted_38 = { key: 0 }
const _hoisted_39 = {
  key: 2,
  class: "form-group"
}
const _hoisted_40 = {
  key: 3,
  class: "form-group"
}
const _hoisted_41 = { for: "department" }
const _hoisted_42 = { key: 0 }
const _hoisted_43 = { key: 0 }
const _hoisted_44 = { class: "form-group text-align-center" }
const _hoisted_45 = ["disabled"]
const _hoisted_46 = { class: "spinner-border spinner-border-sm" }

import { ref } from "vue";
import * as yup from "yup";
import { Form, Field, ErrorMessage } from "vee-validate";
import Multiselect from "@vueform/multiselect";
import InstructorSidebar from "@/components/instructor/InstructorSidebar.vue";
import BackButton from "@/components/user/BackButton.vue";
import UserService from "@/services/user.service.js";
import { useConfigStore } from "@/stores/ConfigStore";
import { useAuthStore } from "@/stores/AuthStore";
import { useRouter } from "vue-router";
import {
  showInfoToast,
  showToast,
  univOptions,
  deptOptions,
  ageRangeOptions,
  yearsOfExpOptions,
  pronounsOptions,
  instructorTitleOptions,
  insTypeOptions,
  genderOptions,
  ethnicityOptions,
  stateOptions,
  showErrorToast,
} from "@/utils";


export default /*@__PURE__*/_defineComponent({
  __name: 'UpdateProfileView',
  setup(__props) {

const ConfigStore = useConfigStore();
const AuthStore = useAuthStore();
const router = useRouter();

const univOptionsSorted = univOptions.sort();
const univOptionsAdded = [...univOptionsSorted, "Others"];
const profileFields = ConfigStore.config.filter(
  (el) => el.table === "instructor_profile"
);

const schema = yup.object().shape({
  fields: yup.array().default(profileFields),
  title: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "title")?.disabled,
    then: (schema) => schema.required("Title is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  title_text: yup.string().when("title", {
    is: (val) => val === "Others",
    then: (schema) => schema.required("Title is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  firstName: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "firstName")?.disabled,
    then: (schema) => schema.required("First Name is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  lastName: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "lastName")?.disabled,
    then: (schema) => schema.required("Last Name is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  nameOrg: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "nameOrg")?.disabled,
    then: (schema) => schema.required("Name of Institution is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  city: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "city")?.disabled,
    then: (schema) => schema.required("City is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  state: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "state")?.disabled,
    then: (schema) => schema.required("State is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  department: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "department")?.disabled,
    then: (schema) => schema.required("Department is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  insType: yup.string().optional().nullable(),
  ageRange: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "ageRange")?.disabled,
    then: (schema) => schema.required("Age Range is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  yearsOfExp: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "yearsOfExp")?.disabled,
    then: (schema) => schema.required("Years of Experience is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  pronouns: yup.string().optional().nullable(),
  pronouns_text: yup.string().when("pronouns", {
    is: (val) => val === "Others",
    then: (schema) => schema.required("Pronouns is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  ethnicity: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "ethnicity")?.disabled,
    then: (schema) => schema.required("Racial Identity is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  ethnicity_text: yup.string().when("ethnicity", {
    is: (val) => val === "Others",
    then: (schema) => schema.required("Racial Identity is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  gender: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "gender")?.disabled,
    then: (schema) => schema.required("Gender Identity is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  gender_text: yup.string().when("gender", {
    is: (val) => val === "Others",
    then: (schema) => schema.required("Gender Identity is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  parkingArrangement: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "parkingArrangement")?.disabled,
    then: (schema) => schema.required("Parking Information is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  parkingAddress: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "parkingAddress")?.disabled,
    then: (schema) =>
      schema
        .required("Street Address of Institution is required!")
        .max(254, "Street Address cannot be greater than 255 characters"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  officeAddress: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "officeAddress")?.disabled,
    then: (schema) =>
      schema
        .required("Office Address is required!")
        .max(254, "Office Address cannot be greater than 255 characters"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
});

const currentUser = AuthStore.auth;

let initialState = {
  city: "",
  state: "",
  department: "",
  department_text: "",
  // physicalDis: [],
  ageRange: "",
  insType: "",
  insType_text: "",
  title: "",
  title_text: "",
  firstName: "",
  lastName: "",
  nameOrg: "",
  nameOrg_text: "",
  yearsOfExp: "",
  pronouns: "",
  pronouns_text: "",
  gender: "",
  gender_text: "",
  ethnicity: "",
  ethnicity_text: "",
  parkingArrangement: "",
  parkingAddress: "",
  officeAddress: "",
};

if (AuthStore.profile) {
  initialState.city = AuthStore.auth.profile.city;
  initialState.state = AuthStore.auth.profile.state;
  if (
    !deptOptions.some((el) => el.value === AuthStore.auth.profile.department)
  ) {
    initialState.department_text = AuthStore.auth.profile.department;
    initialState.department = "Others";
  } else {
    initialState.department = AuthStore.auth.profile.department;
  }
  // initialState.physicalDis = JSON.parse(AuthStore.auth.profile.physicalDis);
  initialState.ageRange = AuthStore.auth.profile.ageRange;
  if (
    !insTypeOptions.some((el) => el.value === AuthStore.auth.profile.insType)
  ) {
    initialState.insType_text = AuthStore.auth.profile.insType;
    initialState.insType = "Others";
  } else {
    initialState.insType = AuthStore.auth.profile.insType;
  }
  initialState.parkingArrangement = AuthStore.auth.profile.parkingArrangement;
  initialState.parkingAddress = AuthStore.auth.profile.parkingAddress;
  initialState.officeAddress = AuthStore.auth.profile.officeAddress;
  if (!instructorTitleOptions.some((el) => el.value === AuthStore.auth.title)) {
    initialState.title_text = AuthStore.auth.title;
    initialState.title = "Others";
  } else {
    initialState.title = AuthStore.auth.title;
  }
  initialState.firstName = AuthStore.auth.firstName;
  initialState.lastName = AuthStore.auth.lastName;
  if (!univOptions.some((el) => el === AuthStore.auth.nameOrg)) {
    initialState.nameOrg_text = AuthStore.auth.nameOrg;
    initialState.nameOrg = "Others";
  } else {
    initialState.nameOrg = AuthStore.auth.nameOrg;
  }
  initialState.yearsOfExp = AuthStore.auth.profile.yearsOfExp;
  if (
    !pronounsOptions.some((el) => el.value === AuthStore.auth.profile.pronouns)
  ) {
    initialState.pronouns_text = AuthStore.auth.profile.pronouns;
    initialState.pronouns = "Others";
  } else {
    initialState.pronouns = AuthStore.auth.profile.pronouns;
  }
  if (!ethnicityOptions.some((el) => el.value === AuthStore.auth.ethnicity)) {
    initialState.ethnicity_text = AuthStore.auth.ethnicity;
    initialState.ethnicity = "Others";
  } else {
    initialState.ethnicity = AuthStore.auth.ethnicity;
  }
  if (!genderOptions.some((el) => el.value === AuthStore.auth.gender)) {
    initialState.gender_text = AuthStore.auth.gender;
    initialState.gender = "Others";
  } else {
    initialState.gender = AuthStore.auth.gender;
  }
} else {
  initialState.title = AuthStore.auth.title;
  initialState.firstName = AuthStore.auth.firstName;
  initialState.lastName = AuthStore.auth.lastName;
}

const formValues = {
  city: initialState.city,
  state: initialState.state,
  department: initialState.department,
  department_text: initialState.department_text,
  // physicalDis: initialState.physicalDis,
  ageRange: initialState.ageRange,
  insType: initialState.insType,
  insType_text: initialState.insType_text,
  title: initialState.title,
  title_text: initialState.title_text,
  firstName: initialState.firstName,
  lastName: initialState.lastName,
  nameOrg: initialState.nameOrg,
  nameOrg_text: initialState.nameOrg_text,
  yearsOfExp: initialState.yearsOfExp,
  pronouns: initialState.pronouns,
  pronouns_text: initialState.pronouns_text,
  gender: initialState.gender,
  gender_text: initialState.gender_text,
  ethnicity: initialState.ethnicity,
  ethnicity_text: initialState.ethnicity_text,
  parkingArrangement: initialState.parkingArrangement,
  parkingAddress: initialState.parkingAddress,
  officeAddress: initialState.officeAddress,
};

let loading = ref(false);
let successful = ref(false);
let message = ref("");

const saveProfile = (data) => {
  loading.value = true;
  if (data.title === "Others") data.title = data["title_text"];
  if (data.nameOrg === "Others") data.nameOrg = data["nameOrg_text"];
  if (data.department === "Others") data.department = data["department_text"];
  if (data.insType === "Others") data.insType = data["insType_text"];
  if (data.gender === "Others") data.gender = data["gender_text"];
  if (data.ethnicity === "Others") data.ethnicity = data["ethnicity_text"];
  if (data.pronouns === "Others") data.pronouns = data["pronouns_text"];

  AuthStore.saveProfile(data).then(
    (data) => {
      AuthStore.getProfile().then(
        () => {
          showToast("Your profile has been updated.");
          router.push({ name: "instructor-feed" });
          loading.value = false;
          successful.value = true;
        },
        (err) => {
          loading.value = false;
          successful.value = false;
          message.value =
            (err.response && err.response.data && err.response.data.message) ||
            err.message ||
            err.toString();
        }
      );
    },
    (error) => {
      loading.value = false;
      successful.value = false;
      message.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};

const onInvalidSubmit = ({ errors }) => {
  Object.values(errors).forEach((error) => showErrorToast(error));
};

const cityOptions = ref([] as any[]);

const getCityOptions = (state) => {
  UserService.getCities(state).then(
    (response) => {
      cityOptions.value = response.data;
    },
    (error) => {
      console.log(error);
      message.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};

getCityOptions(initialState.state);

return (_ctx: any,_cache: any) => {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(InstructorSidebar),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(BackButton),
        _cache[0] || (_cache[0] = _createElementVNode("h2", { style: {"text-align":"center","font-weight":"bold"} }, " Instructor's Profile ", -1))
      ]),
      _createElementVNode("div", null, [
        _cache[25] || (_cache[25] = _createElementVNode("br", null, null, -1)),
        _cache[26] || (_cache[26] = _createElementVNode("span", { class: "font-color-red ml-3" }, "* required field", -1)),
        _createElementVNode("div", null, [
          _createVNode(_unref(Form), {
            onSubmit: saveProfile,
            onInvalidSubmit: onInvalidSubmit,
            "validation-schema": _unref(schema),
            "initial-values": formValues
          }, {
            default: _withCtx(({ values }) => [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _cache[14] || (_cache[14] = _createElementVNode("h4", { class: "text-align-center bold-text" }, " Personal Information ", -1)),
                  (
                    !_unref(profileFields).find((el) => el.name === 'title')?.disabled
                  )
                    ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                        _cache[1] || (_cache[1] = _createElementVNode("label", { for: "title" }, [
                          _createTextVNode("Title"),
                          _createElementVNode("span", { class: "super font-color-red" }, "*")
                        ], -1)),
                        _createVNode(_unref(Field), {
                          modelValue: values.title,
                          "onUpdate:modelValue": ($event: any) => ((values.title) = $event),
                          name: "title",
                          type: "text",
                          class: "dropdown"
                        }, {
                          default: _withCtx(({ field }) => [
                            _createVNode(_unref(Multiselect), _mergeProps(field, {
                              modelValue: values.title,
                              "onUpdate:modelValue": ($event: any) => ((values.title) = $event),
                              options: _unref(instructorTitleOptions),
                              mode: "single",
                              searchable: true
                            }), null, 16, ["modelValue", "onUpdate:modelValue", "options"])
                          ]),
                          _: 2
                        }, 1032, ["modelValue", "onUpdate:modelValue"]),
                        (values.title === 'Others')
                          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                              _createVNode(_unref(Field), {
                                name: "title_text",
                                type: "text",
                                placeholder: "Please Specify",
                                class: "form-control bg-others"
                              })
                            ]))
                          : _createCommentVNode("", true),
                        _createVNode(_unref(ErrorMessage), {
                          name: "title",
                          class: "error-feedback"
                        })
                      ]))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", _hoisted_8, [
                    (
                      !_unref(profileFields).find((el) => el.name === 'firstName')
                        ?.disabled
                    )
                      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                          _cache[2] || (_cache[2] = _createElementVNode("label", { for: "firstName" }, [
                            _createTextVNode("First Name"),
                            _createElementVNode("span", { class: "super font-color-red" }, "*")
                          ], -1)),
                          _createVNode(_unref(Field), {
                            name: "firstName",
                            type: "text",
                            class: "form-control"
                          }),
                          _createVNode(_unref(ErrorMessage), {
                            name: "firstName",
                            class: "error-feedback"
                          })
                        ]))
                      : _createCommentVNode("", true),
                    (
                      !_unref(profileFields).find((el) => el.name === 'lastName')
                        ?.disabled
                    )
                      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                          _cache[3] || (_cache[3] = _createElementVNode("label", { for: "lastName" }, [
                            _createTextVNode("Last Name"),
                            _createElementVNode("span", { class: "super font-color-red" }, "*")
                          ], -1)),
                          _createVNode(_unref(Field), {
                            name: "lastName",
                            type: "text",
                            class: "form-control"
                          }),
                          _createVNode(_unref(ErrorMessage), {
                            name: "lastName",
                            class: "error-feedback"
                          })
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_11, [
                    (
                      !_unref(profileFields).find((el) => el.name === 'gender')
                        ?.disabled
                    )
                      ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                          _createElementVNode("label", _hoisted_13, [
                            _cache[4] || (_cache[4] = _createTextVNode("Gender Identity")),
                            _cache[5] || (_cache[5] = _createElementVNode("span", { class: "super font-color-red" }, "*", -1)),
                            _createVNode(_component_font_awesome_icon, {
                              icon: "fa-solid fa-lock",
                              class: "ml-1",
                              title: "This information is private."
                            })
                          ]),
                          _createVNode(_unref(Field), {
                            modelValue: values.gender,
                            "onUpdate:modelValue": ($event: any) => ((values.gender) = $event),
                            name: "gender",
                            type: "text",
                            class: "dropdown"
                          }, {
                            default: _withCtx(({ field }) => [
                              _createVNode(_unref(Multiselect), _mergeProps(field, {
                                modelValue: values.gender,
                                "onUpdate:modelValue": ($event: any) => ((values.gender) = $event),
                                options: _unref(genderOptions),
                                mode: "single",
                                searchable: true
                              }), null, 16, ["modelValue", "onUpdate:modelValue", "options"])
                            ]),
                            _: 2
                          }, 1032, ["modelValue", "onUpdate:modelValue"]),
                          (values.gender === 'Others')
                            ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                                _createVNode(_unref(Field), {
                                  name: "gender_text",
                                  type: "text",
                                  placeholder: "Please Specify",
                                  class: "form-control bg-others"
                                })
                              ]))
                            : _createCommentVNode("", true),
                          _createVNode(_unref(ErrorMessage), {
                            name: "gender",
                            class: "error-feedback"
                          })
                        ]))
                      : _createCommentVNode("", true),
                    (
                      !_unref(profileFields).find((el) => el.name === 'ethnicity')
                        ?.disabled
                    )
                      ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                          _createElementVNode("label", _hoisted_16, [
                            _cache[6] || (_cache[6] = _createTextVNode("Racial Identity")),
                            _cache[7] || (_cache[7] = _createElementVNode("span", { class: "super font-color-red" }, "*", -1)),
                            _createVNode(_component_font_awesome_icon, {
                              icon: "fa-solid fa-lock",
                              class: "ml-1",
                              title: "This information is private."
                            })
                          ]),
                          _createVNode(_unref(Field), {
                            modelValue: values.ethnicity,
                            "onUpdate:modelValue": ($event: any) => ((values.ethnicity) = $event),
                            name: "ethnicity",
                            type: "text",
                            class: "dropdown"
                          }, {
                            default: _withCtx(({ field }) => [
                              _createVNode(_unref(Multiselect), _mergeProps(field, {
                                modelValue: values.ethnicity,
                                "onUpdate:modelValue": ($event: any) => ((values.ethnicity) = $event),
                                options: _unref(ethnicityOptions),
                                mode: "single",
                                searchable: true
                              }), null, 16, ["modelValue", "onUpdate:modelValue", "options"])
                            ]),
                            _: 2
                          }, 1032, ["modelValue", "onUpdate:modelValue"]),
                          (values.ethnicity === 'Others')
                            ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                                _createVNode(_unref(Field), {
                                  name: "ethnicity_text",
                                  type: "text",
                                  placeholder: "Please Specify",
                                  class: "form-control bg-others"
                                })
                              ]))
                            : _createCommentVNode("", true),
                          _createVNode(_unref(ErrorMessage), {
                            name: "ethnicity",
                            class: "error-feedback"
                          })
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_18, [
                    (
                      !_unref(profileFields).find((el) => el.name === 'ageRange')
                        ?.disabled
                    )
                      ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                          _createElementVNode("label", _hoisted_20, [
                            _cache[8] || (_cache[8] = _createTextVNode("Age Range")),
                            _cache[9] || (_cache[9] = _createElementVNode("span", { class: "super font-color-red" }, "*", -1)),
                            _createVNode(_component_font_awesome_icon, {
                              icon: "fa-solid fa-lock",
                              class: "ml-1",
                              title: "This information is private."
                            })
                          ]),
                          _createVNode(_unref(Field), {
                            modelValue: values.ageRange,
                            "onUpdate:modelValue": ($event: any) => ((values.ageRange) = $event),
                            name: "ageRange",
                            type: "text",
                            class: "dropdown"
                          }, {
                            default: _withCtx(({ field }) => [
                              _createVNode(_unref(Multiselect), _mergeProps(field, {
                                modelValue: values.ageRange,
                                "onUpdate:modelValue": ($event: any) => ((values.ageRange) = $event),
                                options: _unref(ageRangeOptions),
                                mode: "single",
                                searchable: true
                              }), null, 16, ["modelValue", "onUpdate:modelValue", "options"])
                            ]),
                            _: 2
                          }, 1032, ["modelValue", "onUpdate:modelValue"]),
                          _createVNode(_unref(ErrorMessage), {
                            name: "ageRange",
                            class: "error-feedback"
                          })
                        ]))
                      : _createCommentVNode("", true),
                    (
                      !_unref(profileFields).find((el) => el.name === 'yearsOfExp')
                        ?.disabled
                    )
                      ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                          _createElementVNode("label", _hoisted_22, [
                            _cache[10] || (_cache[10] = _createTextVNode("Years of Faculty Experience")),
                            _cache[11] || (_cache[11] = _createElementVNode("span", { class: "super font-color-red" }, "*", -1)),
                            _createVNode(_component_font_awesome_icon, {
                              icon: "fa-solid fa-lock",
                              class: "ml-1",
                              title: "This information is private."
                            })
                          ]),
                          _createVNode(_unref(Field), {
                            modelValue: values.yearsOfExp,
                            "onUpdate:modelValue": ($event: any) => ((values.yearsOfExp) = $event),
                            name: "yearsOfExp",
                            type: "text",
                            class: "dropdown"
                          }, {
                            default: _withCtx(({ field }) => [
                              _createVNode(_unref(Multiselect), _mergeProps(field, {
                                modelValue: values.yearsOfExp,
                                "onUpdate:modelValue": ($event: any) => ((values.yearsOfExp) = $event),
                                options: _unref(yearsOfExpOptions),
                                mode: "single",
                                searchable: true
                              }), null, 16, ["modelValue", "onUpdate:modelValue", "options"])
                            ]),
                            _: 2
                          }, 1032, ["modelValue", "onUpdate:modelValue"]),
                          _createVNode(_unref(ErrorMessage), {
                            name: "yearsOfExp",
                            class: "error-feedback"
                          })
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  (
                    !_unref(profileFields).find((el) => el.name === 'pronouns')
                      ?.disabled
                  )
                    ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                        _cache[12] || (_cache[12] = _createElementVNode("label", { for: "pronouns" }, "Pronouns ", -1)),
                        _createVNode(_unref(Field), {
                          modelValue: values.pronouns,
                          "onUpdate:modelValue": ($event: any) => ((values.pronouns) = $event),
                          name: "pronouns",
                          type: "text",
                          class: "dropdown"
                        }, {
                          default: _withCtx(({ field }) => [
                            _createVNode(_unref(Multiselect), _mergeProps(field, {
                              modelValue: values.pronouns,
                              "onUpdate:modelValue": ($event: any) => ((values.pronouns) = $event),
                              options: _unref(pronounsOptions),
                              mode: "single",
                              searchable: true
                            }), null, 16, ["modelValue", "onUpdate:modelValue", "options"])
                          ]),
                          _: 2
                        }, 1032, ["modelValue", "onUpdate:modelValue"]),
                        (values.pronouns === 'Others')
                          ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
                              _createVNode(_unref(Field), {
                                name: "pronouns_text",
                                type: "text",
                                placeholder: "Please Specify",
                                class: "form-control bg-others"
                              })
                            ]))
                          : _createCommentVNode("", true),
                        _createVNode(_unref(ErrorMessage), {
                          name: "pronouns",
                          class: "error-feedback"
                        })
                      ]))
                    : _createCommentVNode("", true),
                  (
                    !_unref(profileFields).find((el) => el.name === 'officeAddress')
                      ?.disabled
                  )
                    ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
                        _cache[13] || (_cache[13] = _createElementVNode("label", { for: "officeAddress" }, [
                          _createTextVNode("Office Address"),
                          _createElementVNode("span", { class: "super font-color-red" }, "*")
                        ], -1)),
                        _createVNode(_unref(Field), {
                          name: "officeAddress",
                          type: "text",
                          class: "form-control"
                        }),
                        _createVNode(_unref(ErrorMessage), {
                          name: "officeAddress",
                          class: "error-feedback"
                        })
                      ]))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_26, [
                  _cache[22] || (_cache[22] = _createElementVNode("h4", { class: "text-align-center bold-text" }, " Institution Information ", -1)),
                  (
                    !_unref(profileFields).find((el) => el.name === 'insType')?.disabled
                  )
                    ? (_openBlock(), _createElementBlock("div", _hoisted_27, [
                        _createElementVNode("label", _hoisted_28, [
                          _cache[15] || (_cache[15] = _createTextVNode("Type of Institution ")),
                          _createVNode(_component_font_awesome_icon, {
                            icon: "fa-solid fa-lock",
                            class: "ml-1",
                            title: "This information is private."
                          })
                        ]),
                        _createVNode(_unref(Field), {
                          modelValue: values.insType,
                          "onUpdate:modelValue": ($event: any) => ((values.insType) = $event),
                          name: "insType",
                          type: "text",
                          class: "dropdown"
                        }, {
                          default: _withCtx(({ field }) => [
                            _createVNode(_unref(Multiselect), _mergeProps(field, {
                              modelValue: values.insType,
                              "onUpdate:modelValue": ($event: any) => ((values.insType) = $event),
                              options: _unref(insTypeOptions),
                              mode: "single",
                              searchable: true
                            }), null, 16, ["modelValue", "onUpdate:modelValue", "options"])
                          ]),
                          _: 2
                        }, 1032, ["modelValue", "onUpdate:modelValue"]),
                        (values.insType === 'Others')
                          ? (_openBlock(), _createElementBlock("div", _hoisted_29, [
                              _createVNode(_unref(Field), {
                                name: "insType_text",
                                type: "text",
                                placeholder: "Please Specify",
                                class: "form-control bg-others"
                              })
                            ]))
                          : _createCommentVNode("", true)
                      ]))
                    : _createCommentVNode("", true),
                  (
                    !_unref(profileFields).find((el) => el.name === 'nameOrg')?.disabled
                  )
                    ? (_openBlock(), _createElementBlock("div", _hoisted_30, [
                        _cache[16] || (_cache[16] = _createElementVNode("label", { for: "nameOrg" }, [
                          _createTextVNode("Name of Institution"),
                          _createElementVNode("span", { class: "super font-color-red" }, "*")
                        ], -1)),
                        _createVNode(_unref(Field), {
                          modelValue: values.nameOrg,
                          "onUpdate:modelValue": ($event: any) => ((values.nameOrg) = $event),
                          name: "nameOrg",
                          type: "text",
                          class: "dropdown"
                        }, {
                          default: _withCtx(({ field }) => [
                            _createVNode(_unref(Multiselect), _mergeProps(field, {
                              modelValue: values.nameOrg,
                              "onUpdate:modelValue": ($event: any) => ((values.nameOrg) = $event),
                              mode: "single",
                              options: univOptionsAdded,
                              searchable: true
                            }), null, 16, ["modelValue", "onUpdate:modelValue"])
                          ]),
                          _: 2
                        }, 1032, ["modelValue", "onUpdate:modelValue"]),
                        (values.nameOrg === 'Others')
                          ? (_openBlock(), _createElementBlock("div", _hoisted_31, [
                              _createVNode(_unref(Field), {
                                name: "nameOrg_text",
                                type: "text",
                                placeholder: "Please Specify",
                                class: "form-control bg-others"
                              })
                            ]))
                          : _createCommentVNode("", true),
                        _createVNode(_unref(ErrorMessage), {
                          name: "nameOrg",
                          class: "error-feedback"
                        })
                      ]))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", _hoisted_32, [
                    _createElementVNode("label", _hoisted_33, [
                      _cache[17] || (_cache[17] = _createTextVNode("Location")),
                      (_unref(currentUser).nameOrg)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_34, " of " + _toDisplayString(_unref(currentUser).nameOrg), 1))
                        : _createCommentVNode("", true),
                      _cache[18] || (_cache[18] = _createElementVNode("span", { class: "super font-color-red" }, "*", -1))
                    ]),
                    _createElementVNode("div", _hoisted_35, [
                      (
                        !_unref(profileFields).find((el) => el.name === 'state')
                          ?.disabled
                      )
                        ? (_openBlock(), _createElementBlock("div", _hoisted_36, [
                            _createVNode(_unref(Field), {
                              modelValue: values.state,
                              "onUpdate:modelValue": ($event: any) => ((values.state) = $event),
                              name: "state",
                              type: "text",
                              class: "dropdown"
                            }, {
                              default: _withCtx(({ field }) => [
                                _createVNode(_unref(Multiselect), _mergeProps(field, {
                                  modelValue: values.state,
                                  "onUpdate:modelValue": ($event: any) => ((values.state) = $event),
                                  options: _unref(stateOptions),
                                  mode: "single",
                                  placeholder: "Select State",
                                  searchable: true,
                                  onChange: ($event: any) => (
                            getCityOptions(values.state), (values.city = '')
                          )
                                }), null, 16, ["modelValue", "onUpdate:modelValue", "options", "onChange"])
                              ]),
                              _: 2
                            }, 1032, ["modelValue", "onUpdate:modelValue"]),
                            _createVNode(_unref(ErrorMessage), {
                              name: "state",
                              class: "error-feedback"
                            })
                          ]))
                        : _createCommentVNode("", true),
                      (
                        !_unref(profileFields).find((el) => el.name === 'city')
                          ?.disabled
                      )
                        ? (_openBlock(), _createElementBlock("div", _hoisted_37, [
                            _createVNode(_unref(Field), {
                              modelValue: values.city,
                              "onUpdate:modelValue": ($event: any) => ((values.city) = $event),
                              name: "city",
                              type: "text",
                              class: "dropdown"
                            }, {
                              default: _withCtx(({ field }) => [
                                _createVNode(_unref(Multiselect), _mergeProps(field, {
                                  modelValue: values.city,
                                  "onUpdate:modelValue": ($event: any) => ((values.city) = $event),
                                  options: cityOptions.value,
                                  mode: "single",
                                  searchable: true
                                }), null, 16, ["modelValue", "onUpdate:modelValue", "options"])
                              ]),
                              _: 2
                            }, 1032, ["modelValue", "onUpdate:modelValue"]),
                            _createVNode(_unref(ErrorMessage), {
                              name: "city",
                              class: "error-feedback"
                            })
                          ]))
                        : _createCommentVNode("", true)
                    ]),
                    (
                      !_unref(profileFields).find((el) => el.name === 'parkingAddress')
                        ?.disabled
                    )
                      ? (_openBlock(), _createElementBlock("div", _hoisted_38, [
                          _createVNode(_unref(Field), {
                            name: "parkingAddress",
                            type: "text",
                            placeholder: "Street Address",
                            class: "form-control"
                          }),
                          _createVNode(_unref(ErrorMessage), {
                            name: "parkingAddress",
                            class: "error-feedback"
                          })
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  (
                    !_unref(profileFields).find(
                      (el) => el.name === 'parkingArrangement'
                    )?.disabled
                  )
                    ? (_openBlock(), _createElementBlock("div", _hoisted_39, [
                        _cache[19] || (_cache[19] = _createElementVNode("label", { for: "parkingArrangement" }, [
                          _createTextVNode("Will there be parking arrangement for practitioners visiting your institution?"),
                          _createElementVNode("span", { class: "super font-color-red" }, "*")
                        ], -1)),
                        _createVNode(_unref(Field), {
                          modelValue: values.parkingArrangement,
                          "onUpdate:modelValue": ($event: any) => ((values.parkingArrangement) = $event),
                          name: "parkingArrangement",
                          type: "text",
                          class: "dropdown"
                        }, {
                          default: _withCtx(({ field }) => [
                            _createVNode(_unref(Multiselect), _mergeProps(field, {
                              modelValue: values.parkingArrangement,
                              "onUpdate:modelValue": ($event: any) => ((values.parkingArrangement) = $event),
                              options: ['Yes', 'No'],
                              searchable: true,
                              mode: "single"
                            }), null, 16, ["modelValue", "onUpdate:modelValue"])
                          ]),
                          _: 2
                        }, 1032, ["modelValue", "onUpdate:modelValue"]),
                        _createVNode(_unref(ErrorMessage), {
                          name: "parkingArrangement",
                          class: "error-feedback"
                        }),
                        _createVNode(_unref(ErrorMessage), {
                          name: "parkingAddress",
                          class: "error-feedback"
                        })
                      ]))
                    : _createCommentVNode("", true),
                  (
                    !_unref(profileFields).find((el) => el.name === 'department')
                      ?.disabled
                  )
                    ? (_openBlock(), _createElementBlock("div", _hoisted_40, [
                        _createElementVNode("label", _hoisted_41, [
                          _cache[20] || (_cache[20] = _createTextVNode("Department/Program")),
                          (_unref(currentUser).nameOrg)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_42, " in " + _toDisplayString(_unref(currentUser).nameOrg), 1))
                            : _createCommentVNode("", true),
                          _cache[21] || (_cache[21] = _createElementVNode("span", { class: "super font-color-red" }, "*", -1))
                        ]),
                        _createVNode(_unref(Field), {
                          modelValue: values.department,
                          "onUpdate:modelValue": ($event: any) => ((values.department) = $event),
                          name: "department",
                          type: "text",
                          class: "dropdown"
                        }, {
                          default: _withCtx(({ field }) => [
                            _createVNode(_unref(Multiselect), _mergeProps(field, {
                              modelValue: values.department,
                              "onUpdate:modelValue": ($event: any) => ((values.department) = $event),
                              options: _unref(deptOptions),
                              searchable: true,
                              mode: "single"
                            }), null, 16, ["modelValue", "onUpdate:modelValue", "options"])
                          ]),
                          _: 2
                        }, 1032, ["modelValue", "onUpdate:modelValue"]),
                        (values.department === 'Others')
                          ? (_openBlock(), _createElementBlock("div", _hoisted_43, [
                              _createVNode(_unref(Field), {
                                name: "department_text",
                                type: "text",
                                placeholder: "Please Specify",
                                class: "form-control bg-others"
                              })
                            ]))
                          : _createCommentVNode("", true),
                        _createVNode(_unref(ErrorMessage), {
                          name: "department",
                          class: "error-feedback"
                        })
                      ]))
                    : _createCommentVNode("", true)
                ])
              ]),
              _cache[24] || (_cache[24] = _createElementVNode("br", null, null, -1)),
              _createElementVNode("div", _hoisted_44, [
                _createElementVNode("button", {
                  class: "btn btn-primary btn-block",
                  disabled: _unref(loading)
                }, [
                  _withDirectives(_createElementVNode("span", _hoisted_46, null, 512), [
                    [_vShow, _unref(loading)]
                  ]),
                  _cache[23] || (_cache[23] = _createElementVNode("span", null, "Save Profile", -1))
                ], 8, _hoisted_45)
              ])
            ]),
            _: 1
          }, 8, ["validation-schema"])
        ]),
        (_unref(message))
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(_unref(successful) ? 'alert-success' : 'alert-danger')
            }, _toDisplayString(_unref(message)), 3))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}
}

})