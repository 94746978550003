import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { style: {"padding":"5px"} }
const _hoisted_2 = { style: {"font-size":"x-large"} }
const _hoisted_3 = { class: "flex flex-direction-row text-align-center ml-5" }
const _hoisted_4 = {
  key: 0,
  class: "w-2rem mx-1"
}
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { class: "flex flex-direction-row align-end" }
const _hoisted_8 = {
  class: "w-2rem flex-shrink-1 flex-grow-0 pr-1",
  style: {"text-align":"right","font-size":"0.6rem"}
}
const _hoisted_9 = {
  key: 0,
  class: "position-relative top-1"
}
const _hoisted_10 = ["data-row", "data-col", "data-month", "data-time", "id"]
const _hoisted_11 = { style: {"font-size":"x-large"} }
const _hoisted_12 = {
  class: "flex flex-direction-row text-align-center",
  style: {"margin-left":"3.5rem"}
}
const _hoisted_13 = { style: {"font-size":"0.6rem"} }
const _hoisted_14 = { class: "flex flex-direction-row align-end" }
const _hoisted_15 = {
  key: 0,
  class: "w-4rem pr-1 flex-shrink-1 flex-grow-0",
  style: {"text-align":"right"}
}
const _hoisted_16 = { key: 0 }
const _hoisted_17 = { key: 1 }
const _hoisted_18 = ["data-row", "data-col", "data-month", "data-time", "id"]
const _hoisted_19 = { class: "text-align-center my-3" }
const _hoisted_20 = { key: 0 }
const _hoisted_21 = { key: 1 }

import { ref, onMounted } from "vue";
import {
  monthNames,
  longMonthNames,
  showErrorToast,
  getTimezoneName,
} from "@/utils";
import { useAuthStore } from "@/stores/AuthStore";
import { Availability } from "@/types";

// eslint-disable-next-line no-undef

export default /*@__PURE__*/_defineComponent({
  __name: 'AvailabilitySelector',
  props: {
    availability: {},
    lastStep: { type: Boolean }
  },
  emits: ["previousStep", "nextStep"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
// eslint-disable-next-line no-undef
const emits = __emit;
const AuthStore = useAuthStore();

const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
const getDay = (year, month, date) => {
  return new Date(year, month, date).getDay();
};

const isPublicHoliday = (year, month, date) => {
  let fullDate = new Date(year, month, date);
  const publicHolidays = [
    new Date(2023, 10, 23).getTime(),
    new Date(2023, 11, 25).getTime(),
    new Date(2024, 0, 1).getTime(),
    new Date(2024, 0, 15).getTime(),
    new Date(2024, 4, 27).getTime(),
    new Date(2024, 5, 19).getTime(),
    new Date(2024, 6, 4).getTime(),
  ];
  return publicHolidays.includes(fullDate.getTime()) ? true : false;
};

// let baseDate = Date.UTC(new Date().getFullYear(), 0, 1);
let currMonth = new Date().getMonth();
let currDate = new Date().getDate();
let monthDays = [] as any[];
for (let i = 1; i <= 3; i++) {
  let monthObject = {
    year: new Date().getFullYear() + (currMonth + (i - 1) > 11 ? 1 : 0),
    index: (currMonth + i - 1) % 12,
    name: monthNames[(currMonth + (i - 1)) % 12],
    longName: longMonthNames[(currMonth + (i - 1)) % 12],
    noOfDays:
      i === 1
        ? new Date(
            new Date().getFullYear(),
            (currMonth + i) % 12,
            0
          ).getDate() - currDate
        : new Date(new Date().getFullYear(), (currMonth + i) % 12, 0).getDate(),
  };
  monthDays.push(monthObject);
}

let selectionMode = false,
  isMouseDown = false;
var ToCol = -1,
  ToRow = -1,
  FromCol = -1,
  FromRow = -1,
  Month = -1,
  TimeOfSlot = ref([] as number[]);

let TimeGrid = document.getElementsByClassName("time-grid");
const ReColorIndividual = () => {
  let ColA, ColB, RowA, RowB, i, NewColor;
  if (FromCol < ToCol) {
    ColA = FromCol;
    ColB = ToCol;
  } else {
    ColA = ToCol;
    ColB = FromCol;
  }
  if (FromRow < ToRow) {
    RowA = FromRow;
    RowB = ToRow;
  } else {
    RowA = ToRow;
    RowB = FromRow;
  }
  // TimeGrid = document.getElementsByClassName("month-" + Month);
  for (i = 0; i < TimeGrid.length; i++) {
    let currentElement = TimeGrid[i] as HTMLElement;
    if (currentElement) {
      let dataCol = Number(currentElement.getAttribute("data-col"));
      let dataRow = Number(currentElement.getAttribute("data-row"));
      let dataMonth = Number(currentElement.getAttribute("data-month"));
      let dataTime = Number(currentElement.getAttribute("data-time"));

      let WithinX = ColA <= dataCol && dataCol <= ColB;
      let WithinY = RowA <= dataRow && dataRow <= RowB;
      let sameMonth = dataMonth === Month;

      if (selectionMode) NewColor = "#339900";
      else NewColor = "#ffdede";

      if (sameMonth && WithinX && WithinY && isMouseDown) {
        currentElement.style.background = NewColor;
      } else {
        if (TimeOfSlot.value.includes(dataTime)) {
          // console.log("Changing Color bcz of TimeOfSlot");
          currentElement.style.background = "#339900";
        } else {
          currentElement.style.background = "#ffdede";
        }
      }
    }
  }
};

const selectFromHere = (e) => {
  let timeOfSlot = Number(e.target.getAttribute("data-time"));
  // slotID = TimeOfSlot.indexOf(timeOfSlot);
  selectionMode = !TimeOfSlot.value.includes(timeOfSlot);
  isMouseDown = true;

  ToCol = Number(e.target.getAttribute("data-col"));
  ToRow = Number(e.target.getAttribute("data-row"));
  Month = Number(e.target.getAttribute("data-month"));
  FromCol = ToCol;
  FromRow = ToRow;
  ReColorIndividual();
};
const selectToHere = (e) => {
  if (isMouseDown) {
    ToCol = Number(e.target.getAttribute("data-col"));
    ToRow = Number(e.target.getAttribute("data-row"));
    ReColorIndividual();
  }
};
const selectStop = () => {
  if (!isMouseDown) return;
  let ColA, ColB, RowA, RowB, i;
  if (FromCol < ToCol) {
    ColA = FromCol;
    ColB = ToCol;
  } else {
    ColA = ToCol;
    ColB = FromCol;
  }
  if (FromRow < ToRow) {
    RowA = FromRow;
    RowB = ToRow;
  } else {
    RowA = ToRow;
    RowB = FromRow;
  }

  // TimeGrid = document.getElementsByClassName("month-" + Month);
  for (i = 0; i < TimeGrid.length; i++) {
    let currentElement = TimeGrid[i] as HTMLElement;
    if (currentElement) {
      let dataCol = Number(currentElement.getAttribute("data-col"));
      let dataRow = Number(currentElement.getAttribute("data-row"));
      let dataMonth = Number(currentElement.getAttribute("data-month"));
      let dataTime = Number(currentElement.getAttribute("data-time"));

      let WithinX = ColA <= dataCol && dataCol <= ColB;
      let WithinY = RowA <= dataRow && dataRow <= RowB;
      let sameMonth = dataMonth === Month;

      if (sameMonth && WithinX && WithinY) {
        if (selectionMode) TimeOfSlot.value.push(dataTime);
        else {
          // console.log(dataTime);
          const beforeSplit = TimeOfSlot.value;
          let SplitSpot = TimeOfSlot.value.indexOf(dataTime);
          // console.log(SplitSpot);
          TimeOfSlot.value.splice(SplitSpot, 1);
          // console.log(TimeOfSlot.value.filter((x) => !beforeSplit.includes(x)));
          // console.log(TimeOfSlot.value.length);
        }
      }
    }
  }

  isMouseDown = false;
  FromCol = -1;
  ToCol = -1;
  FromRow = -1;
  ToRow = -1;
  Month = -1;
  ReColorIndividual();
};

// const checkDirty = async () => {
//   if (props.availability !== ) {
//     const answer = await confirm({
//       message:
//         "Your changes on this page have not been saved. Kindly save your changes before leaving the page. Do you want to proceed without saving your changes?",
//       okText: "Yes",
//       cancelText: "No",
//     });
//     if (answer) return true;
//     else return false;
//   } else return true;
// };

const goToPreviousStep = async () => {
  // const answer = await checkDirty();
  // await console.log(answer);
  // if (!answer) return false;
  emits("previousStep");
};

const saveAvailabilityTime = () => {
  if (TimeOfSlot.value.length > 0) {
    // console.log(TimeOfSlot.value);
    let availabilityTimes = [] as string[];
    TimeOfSlot.value.forEach((el) => {
      availabilityTimes.push(new Date(el * 1000).toISOString());
    });
    const availabilityTime = Array.from(new Set(availabilityTimes)).map(
      (el) => ({
        availabilityTime: el,
        userId: AuthStore.auth.id,
      })
    );
    // console.log(availabilityTimes);
    emits("nextStep", availabilityTime);
    // console.log(availabilityTime);
  } else {
    emits("nextStep", []);
  }
};

const transformInitialData = (availability) => {
  // console.log(availability);
  // console.log(baseDate);
  availability.forEach((el) => {
    let date = new Date(el.availabilityTime);
    let dateDiff =
      Date.UTC(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate(),
        date.getUTCHours()
      ) / 1000;
    if (!TimeOfSlot.value.includes(dateDiff)) TimeOfSlot.value.push(dateDiff);
  });
  // console.log(TimeOfSlot.value);
  ReColorIndividual();
};

const selectFromHereByTouch = (e) => {
  if (e.touches.length == 1) {
    selectFromHere(e);
    e.preventDefault();
  } else {
    return true;
  }
};
const selectToHereByTouch = (e) => {
  if (e.touches.length == 1) {
    e.preventDefault();
    let touch = e.touches[0];
    let elem = document.elementFromPoint(touch.clientX, touch.clientY);
    if (elem?.hasAttribute("data-col")) {
      ToCol = Number(elem.getAttribute("data-col"));
      ToRow = Number(elem.getAttribute("data-row"));
      ReColorIndividual();
    }
  } else {
    return true;
  }
};

function selectStopByTouch() {
  selectStop();
}

onMounted(() => {
  transformInitialData(props.availability.availabilityTime);
  // transformInitialData(userAvailability);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[1] || (_cache[1] = _createStaticVNode("<div class=\"font-larger font-bold\" data-v-01b44ac0> Please indicate your date and time preferences based on your schedule </div><span data-v-01b44ac0>Unavailable</span><svg width=\"40\" height=\"36\" data-v-01b44ac0><rect x=\"5\" y=\"8\" width=\"32\" height=\"16\" fill=\"#ffdede\" stroke=\"black\" data-v-01b44ac0></rect></svg><span class=\"ml-6\" data-v-01b44ac0>Available</span><svg width=\"40\" height=\"36\" data-v-01b44ac0><rect x=\"5\" y=\"8\" width=\"32\" height=\"16\" fill=\"#339900\" stroke=\"black\" data-v-01b44ac0></rect></svg><span class=\"ml-6\" data-v-01b44ac0>Public Holiday</span><svg width=\"40\" height=\"36\" data-v-01b44ac0><rect x=\"5\" y=\"8\" width=\"32\" height=\"16\" fill=\"#EBEBE4\" stroke=\"black\" data-v-01b44ac0></rect></svg>", 7)),
      _createElementVNode("div", null, [
        _cache[0] || (_cache[0] = _createTextVNode(" Your time zone is ")),
        _createElementVNode("b", null, [
          _createElementVNode("i", null, _toDisplayString(_unref(getTimezoneName)()), 1)
        ])
      ])
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(monthDays), (month, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        class: "mt-3 avail-calender",
        onmouseup: selectStop
      }, [
        _createElementVNode("span", _hoisted_2, _toDisplayString(month.longName), 1),
        _createElementVNode("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(month.noOfDays, (j) => {
            return (_openBlock(), _createElementBlock("div", {
              key: j,
              style: {"font-size":"0.6rem"},
              class: "flex-grow-0 flex-shrink-1"
            }, [
              (
            index === 0
              ? getDay(month.year, month.index, _unref(currDate) + j) != 6 &&
                getDay(month.year, month.index, _unref(currDate) + j) != 0
              : getDay(month.year, month.index, j) != 6 &&
                getDay(month.year, month.index, j) != 0
          )
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    (index === 0)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(month.name + " " + (_unref(currDate) + j)) + " " + _toDisplayString(days[getDay(month.year, month.index, _unref(currDate) + j)]), 1))
                      : (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(month.name + " " + j) + " " + _toDisplayString(days[getDay(month.year, month.index, j)]), 1))
                  ]))
                : _createCommentVNode("", true)
            ]))
          }), 128))
        ]),
        (_openBlock(), _createElementBlock(_Fragment, null, _renderList(14, (i) => {
          return _createElementVNode("div", {
            key: i,
            class: "line-height-1"
          }, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                (i == 1)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_9, _cache[2] || (_cache[2] = [
                      _createTextVNode(" 7 am "),
                      _createElementVNode("br", null, null, -1)
                    ])))
                  : _createCommentVNode("", true),
                _createElementVNode("span", null, _toDisplayString(i + 7 > 12 ? i + 7 - 12 + " pm" : i + 7 + " am"), 1)
              ]),
              _createElementVNode("div", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(month.noOfDays, (j) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: j,
                    class: "inline-block"
                  }, [
                    (
                index === 0
                  ? getDay(month.year, month.index, _unref(currDate) + j) != 6 &&
                    getDay(month.year, month.index, _unref(currDate) + j) != 0
                  : getDay(month.year, month.index, j) != 6 &&
                    getDay(month.year, month.index, j) != 0
              )
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 0,
                          "data-row": j,
                          "data-col": i + 7,
                          "data-month": month.index,
                          "data-time": 
                (index === 0
                  ? Date.UTC(month.year, month.index, _unref(currDate) + j, i + 7 - 1)
                  : Date.UTC(month.year, month.index, j, i + 7 - 1)) / 1000
              ,
                          id: 
                'YouTime' +
                (index === 0
                  ? Date.UTC(month.year, month.index, _unref(currDate) + j, i + 7 - 1)
                  : Date.UTC(month.year, month.index, j, i + 7 - 1)) /
                  1000
              ,
                          class: _normalizeClass([{
                disabled:
                  index === 0
                    ? isPublicHoliday(month.year, month.index, _unref(currDate) + j)
                    : isPublicHoliday(month.year, month.index, j),
              }, "time-grid"]),
                          onmousedown: selectFromHere,
                          onmouseover: selectToHere,
                          ontouchstart: selectFromHereByTouch,
                          ontouchmove: selectToHereByTouch,
                          ontouchend: selectStopByTouch
                        }, null, 10, _hoisted_10))
                      : _createCommentVNode("", true)
                  ]))
                }), 128))
              ])
            ])
          ])
        }), 64))
      ]))
    }), 128)),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(monthDays), (month, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        class: "mt-3 avail-calender-mob",
        onmouseup: selectStop
      }, [
        _createElementVNode("span", _hoisted_11, _toDisplayString(month.longName), 1),
        _createElementVNode("div", _hoisted_12, [
          _cache[3] || (_cache[3] = _createElementVNode("div", {
            class: "flex-grow-0 flex-shrink-1 w-1rem",
            style: {"font-size":"0.6rem","display":"inline-block"}
          }, " 7 am ", -1)),
          (_openBlock(), _createElementBlock(_Fragment, null, _renderList(14, (i) => {
            return _createElementVNode("div", {
              key: i,
              class: "flex-grow-0 flex-shrink-1 w-1rem"
            }, [
              _createElementVNode("div", _hoisted_13, [
                _createElementVNode("span", null, _toDisplayString(i + 7 > 12 ? i + 7 - 12 + " pm" : i + 7 + " am"), 1)
              ])
            ])
          }), 64))
        ]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(month.noOfDays, (j) => {
          return (_openBlock(), _createElementBlock("div", {
            key: j,
            style: {"font-size":"0.6rem"},
            class: "line-height-1"
          }, [
            _createElementVNode("div", _hoisted_14, [
              (
            index === 0
              ? getDay(month.year, month.index, _unref(currDate) + j) != 6 &&
                getDay(month.year, month.index, _unref(currDate) + j) != 0
              : getDay(month.year, month.index, j) != 6 &&
                getDay(month.year, month.index, j) != 0
          )
                ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                    (index === 0)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_16, _toDisplayString(days[getDay(month.year, month.index, _unref(currDate) + j)]) + " " + _toDisplayString(month.name + " " + (_unref(currDate) + j)), 1))
                      : (_openBlock(), _createElementBlock("span", _hoisted_17, _toDisplayString(days[getDay(month.year, month.index, j)]) + " " + _toDisplayString(month.name + " " + j), 1))
                  ]))
                : _createCommentVNode("", true),
              (_openBlock(), _createElementBlock(_Fragment, null, _renderList(14, (i) => {
                return _createElementVNode("div", {
                  key: i,
                  class: "inline-block flex-grow-0"
                }, [
                  (
              index === 0
                ? getDay(month.year, month.index, _unref(currDate) + j) != 6 &&
                  getDay(month.year, month.index, _unref(currDate) + j) != 0
                : getDay(month.year, month.index, j) != 6 &&
                  getDay(month.year, month.index, j) != 0
            )
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        "data-row": j,
                        "data-col": i + 7,
                        "data-month": month.index,
                        "data-time": 
              (index === 0
                ? Date.UTC(month.year, month.index, _unref(currDate) + j, i + 7 - 1)
                : Date.UTC(month.year, month.index, j, i + 7 - 1)) / 1000
            ,
                        id: 
              'YouTime' +
              (index === 0
                ? Date.UTC(month.year, month.index, _unref(currDate) + j, i + 7 - 1)
                : Date.UTC(month.year, month.index, j, i + 7 - 1)) /
                1000
            ,
                        class: _normalizeClass([{
              disabled:
                index === 0
                  ? isPublicHoliday(month.year, month.index, _unref(currDate) + j)
                  : isPublicHoliday(month.year, month.index, j),
            }, "time-grid"]),
                        onmousedown: selectFromHere,
                        onmouseover: selectToHere,
                        ontouchstart: selectFromHereByTouch,
                        ontouchmove: selectToHereByTouch,
                        ontouchend: selectStopByTouch
                      }, null, 10, _hoisted_18))
                    : _createCommentVNode("", true)
                ])
              }), 64))
            ])
          ]))
        }), 128))
      ]))
    }), 128)),
    _createElementVNode("div", _hoisted_19, [
      _createElementVNode("button", {
        class: "btn btn-secondary ml-2",
        type: "button",
        onClick: goToPreviousStep
      }, " Previous "),
      _createElementVNode("button", {
        class: "btn btn-primary ml-2",
        type: "button",
        onClick: saveAvailabilityTime
      }, [
        (props.lastStep)
          ? (_openBlock(), _createElementBlock("span", _hoisted_20, "Save Availability"))
          : (_openBlock(), _createElementBlock("span", _hoisted_21, "Next"))
      ])
    ])
  ], 64))
}
}

})