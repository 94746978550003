import { defineStore } from "pinia";

const FEED_STORAGE_KEY = "TAB_VALUE";

export const useFeedStore = defineStore("FeedStore", {
  state: () => ({
    tab: 0,
    feedLayout: "list",
    recommendationLayout: "list",
  }),
  getters: {},
  actions: {
    setTabValue(currTab) {
      this.tab = currTab;
    },
  },
  persist: {
    storage: sessionStorage,
    key: FEED_STORAGE_KEY,
  },
});
