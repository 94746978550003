import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, isRef as _isRef, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "grid-container" }
const _hoisted_2 = { class: "m-3 min-height" }
const _hoisted_3 = { class: "back-grid" }
const _hoisted_4 = { class: "card card-container" }
const _hoisted_5 = { class: "mt-8" }
const _hoisted_6 = {
  key: 0,
  class: "course-item mt-5"
}
const _hoisted_7 = { class: "flex flex-direction-column lg:flex-row xl:flex-row justify-content-between flex-wrap mx-2 lg:mx-0" }
const _hoisted_8 = { class: "flex flex-row" }
const _hoisted_9 = {
  key: 0,
  class: "text-align-center align-self-center font-bold d-inline d-lg-none flex-shrink-1 flex-grow-0 mr-3"
}
const _hoisted_10 = { class: "flex flex-grow-0 align-content-center mr-3 lg:flex-wrap" }
const _hoisted_11 = {
  key: 0,
  class: "mr-3"
}
const _hoisted_12 = { class: "d-inline" }
const _hoisted_13 = ["onClick"]
const _hoisted_14 = { key: 1 }
const _hoisted_15 = ["onClick"]
const _hoisted_16 = { key: 2 }
const _hoisted_17 = ["onClick"]
const _hoisted_18 = { key: 3 }
const _hoisted_19 = { class: "paginator" }
const _hoisted_20 = {
  key: 1,
  class: "mt-3 text-align-center"
}
const _hoisted_21 = { class: "min-h" }

import { ref } from "vue";
import { showToast } from "@/utils";
import InstructorSidebar from "@/components/instructor/InstructorSidebar.vue";
import ResourcesRequestForm from "@/components/ResourcesRequestForm.vue";
import BackButton from "@/components/user/BackButton.vue";
import ResourcesGalleryItem from "@/components/ResourcesGalleryItem.vue";
import Dialog from "primevue/dialog";
import UserService from "@/services/user.service.js";
import InstructorService from "@/services/instructor.service.js";
import { useRouter } from "vue-router";
import { useCourseStore } from "@/stores/CourseStore";
import { useAuthStore } from "@/stores/AuthStore";


export default /*@__PURE__*/_defineComponent({
  __name: 'CreateView',
  setup(__props) {

const router = useRouter();
const CourseStore = useCourseStore();
const AuthStore = useAuthStore();
const currentUser = AuthStore.auth;

let resourceRequests = ref([]);
let visible = ref(false);
let currResourceRequest = ref({});
const currentPage = ref(1);
const totalPages = ref(1);

const viewResourceRequest = (pageNumber = 1) => {
  UserService.getResourcesRequest(pageNumber).then(
    (response) => {
      resourceRequests.value = response.data.data;
      totalPages.value = response.data.totalPages;
      currentPage.value = response.data.currentPage;
    },
    (error) => {
      let res =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(res);
    }
  );
};

const updateResourceRequest = (id, status) => {
  InstructorService.updateResourcesRequest(id, status).then(
    (response) => {
      viewResourceRequest();
      showToast("Resource Request updated successfully");
    },
    (error) => {
      let res =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(res);
    }
  );
};

const onClick = (val) => {
  viewResourceRequest(currentPage.value);
};

viewResourceRequest();

return (_ctx: any,_cache: any) => {
  const _component_va_pagination = _resolveComponent("va-pagination")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(InstructorSidebar),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(BackButton),
          _cache[2] || (_cache[2] = _createElementVNode("h2", { style: {"text-align":"center","font-weight":"bold"} }, " Resources Request Information ", -1))
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(ResourcesRequestForm, {
            action: "create",
            onReloadResourceRequest: viewResourceRequest
          })
        ]),
        _createElementVNode("div", _hoisted_5, [
          _cache[5] || (_cache[5] = _createElementVNode("h3", { class: "text-align-center font-bold" }, "Your Resources Request", -1)),
          (_unref(resourceRequests).length > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(resourceRequests), (resourceRequest) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "m-1 card",
                    key: resourceRequest.id
                  }, [
                    _createElementVNode("div", _hoisted_7, [
                      _createElementVNode("div", _hoisted_8, [
                        _createElementVNode("div", null, [
                          _createElementVNode("b", null, _toDisplayString(resourceRequest.type), 1),
                          _cache[3] || (_cache[3] = _createElementVNode("br", null, null, -1)),
                          _createTextVNode(" " + _toDisplayString(resourceRequest.description), 1)
                        ]),
                        (resourceRequest.status === '90')
                          ? (_openBlock(), _createElementBlock("div", _hoisted_9, " Closed "))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("div", _hoisted_10, [
                        (resourceRequest.status === '90')
                          ? (_openBlock(), _createElementBlock("span", _hoisted_11, [
                              _createElementVNode("div", _hoisted_12, [
                                _cache[4] || (_cache[4] = _createElementVNode("span", { class: "text-align-center font-bold d-none d-lg-inline" }, "Closed", -1)),
                                _createElementVNode("span", null, [
                                  _createElementVNode("button", {
                                    onClick: ($event: any) => (updateResourceRequest(resourceRequest.id, '01')),
                                    class: "btn btn-secondary mx-1 lg:mx-3"
                                  }, " Re-Open Request ", 8, _hoisted_13)
                                ])
                              ])
                            ]))
                          : (_openBlock(), _createElementBlock("span", _hoisted_14, [
                              _createElementVNode("button", {
                                onClick: ($event: any) => (updateResourceRequest(resourceRequest.id, '90')),
                                class: "btn btn-danger mr-3"
                              }, " Close Request ", 8, _hoisted_15)
                            ])),
                        (resourceRequest?.resource_responses?.length > 0)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_16, [
                              _createElementVNode("button", {
                                onClick: ($event: any) => (
                      (_isRef(visible) //@ts-ignore
 ? visible.value = true : visible = true), (_isRef(currResourceRequest) //@ts-ignore
 ? currResourceRequest.value = resourceRequest : currResourceRequest = resourceRequest)
                    ),
                                class: "btn btn-primary ml-3"
                              }, " View " + _toDisplayString(resourceRequest.resource_responses.length) + " Response(s) ", 9, _hoisted_17)
                            ]))
                          : (_openBlock(), _createElementBlock("span", _hoisted_18, " 0 Response "))
                      ])
                    ])
                  ]))
                }), 128)),
                _createElementVNode("div", _hoisted_19, [
                  _createVNode(_component_va_pagination, {
                    modelValue: currentPage.value,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((currentPage).value = $event)),
                    pages: totalPages.value,
                    "visible-pages": 3,
                    hideOnSinglePage: true,
                    onClick: onClick
                  }, null, 8, ["modelValue", "pages"])
                ])
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_20, " You don't have any saved resource requests. "))
        ])
      ])
    ]),
    _createVNode(_unref(Dialog), {
      visible: _unref(visible),
      "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => (_isRef(visible) ? (visible).value = $event : visible = $event)),
      modal: "",
      header: _unref(currResourceRequest).type,
      style: { width: '75vw' },
      breakpoints: { '768px': '80vw' }
    }, {
      default: _withCtx(() => [
        _createElementVNode("i", null, _toDisplayString(_unref(currResourceRequest).description), 1),
        _createElementVNode("div", _hoisted_21, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(currResourceRequest).resource_responses, (item) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "responsive",
              key: item.id
            }, [
              _createVNode(ResourcesGalleryItem, { item: item }, null, 8, ["item"])
            ]))
          }), 128))
        ]),
        _cache[6] || (_cache[6] = _createElementVNode("div", { class: "clearfix" }, null, -1))
      ]),
      _: 1
    }, 8, ["visible", "header"])
  ], 64))
}
}

})