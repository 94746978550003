<template>
  <div class="gallery">
    <div class="image-container">
      <a v-if="item.linkResources" :href="item.linkResources" target="_blank">
        <img
          src="@/assets/images/uploads/general_resource.jpg"
          :alt="item.linkResources"
        />
      </a>
      <div v-else class="h-inherit">
        <img
          v-if="item.type === 'Project Pictures' && item.filePath"
          :src="websiteURL + item.filePath.substring(9)"
          :alt="item.description"
          @click="
            showImage(
              websiteURL + item.filePath.substring(9),
              item.description,
              websiteURL + item.origFilePath.substring(9)
            )
          "
        />
        <video v-if="item.type === 'Video' && item.filePath" controls>
          <source
            :src="websiteURL + item.filePath.substring(9)"
            :type="item['mimetype']"
          />
        </video>
        <div v-if="item.type === 'Document'">
          <a :href="websiteURL + item.filePath.substring(9)" target="_blank">
            <img
              src="@/assets/images/uploads/general_resource.jpg"
              :alt="item.description"
              style="width: 100%; height: 100%; overflow: hidden"
            />
          </a>
        </div>
      </div>
    </div>
    <div class="desc">
      <p>
        {{
          item.description.slice(0, 25) +
          (item.description.length > 25 ? "..." : "")
        }}
      </p>
      <a
        v-if="item.filePath"
        :href="websiteURL + item.origFilePath.substring(9)"
        download
        target="_blank"
        title="Download"
        style="color: var(--bs-body-color)"
      >
        <font-awesome-icon icon="fa-solid fa-download" />
      </a>
      <!--      <button-->
      <!--        @click="(reportDialogVisible = true), (contentId = item.id)"-->
      <!--        style="color: var(&#45;&#45;bs-body-color); border: none"-->
      <!--        class="ml-2"-->
      <!--        v-if="props.type === 'public'"-->
      <!--        title="Flag as Inappropriate"-->
      <!--      >-->
      <!--        <font-awesome-icon icon="fa-solid fa-flag" />-->
      <!--      </button>-->
    </div>
  </div>
  <Dialog
    v-model:visible="showCustomContent"
    modal
    :header="imgDesc"
    :style="{ width: '75vw' }"
  >
    <div class="flex justify-content-center" style="height: 70vh">
      <br />
      <img :src="imgSrc" class="model-img" />
    </div>
  </Dialog>
</template>

<script setup lang="ts">
import { Upload } from "@/types";
import { websiteURL } from "@/utils";
import { ref } from "vue";
import { useAuthStore } from "@/stores/AuthStore";
import Dialog from "primevue/dialog";
import heic2any from "heic2any";

const AuthStore = useAuthStore();
const currentUser = AuthStore.auth;
const showCustomContent = ref(false);
const imgSrc = ref("");
const imgDesc = ref("");
const downloadSrc = ref("");

// eslint-disable-next-line no-undef
const props = defineProps<{
  item: Upload;
}>();

const showImage = (src, desc, downloadPath) => {
  imgSrc.value = src;
  imgDesc.value = desc;
  downloadSrc.value = downloadPath;
  showCustomContent.value = true;
};
</script>

<style scoped>
.document span {
  font-size: xxx-large;
}

div.gallery {
  border: 1px solid #ccc;
}

div.gallery:hover {
  border: 1px solid #777;
}

div.image-container {
  width: 280px;
  overflow: hidden;
  cursor: pointer;
}

div.gallery img {
  width: 100%;
  aspect-ratio: 3/2;
  object-fit: contain;
}

div.gallery video {
  width: 100%;
  height: auto;
  max-height: 200px;
}

div.desc {
  padding: 13px;
  display: flex;
  /*text-align: center;*/
}

div.desc p {
  flex: 15;
}

div.desc a {
  flex: 1;
}

.model-img {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  object-fit: contain;
}

* {
  box-sizing: border-box;
}
</style>
