import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "grid-container" }
const _hoisted_2 = { class: "min-height mt-3" }
const _hoisted_3 = { class: "back-grid" }
const _hoisted_4 = {
  key: 0,
  class: "card-container card mt-3"
}
const _hoisted_5 = {
  key: 0,
  class: "search-results-list"
}
const _hoisted_6 = ["src"]
const _hoisted_7 = {
  key: 1,
  id: "profile-img",
  src: "//ssl.gstatic.com/accounts/ui/avatar_2x.png",
  class: "search-img-card"
}
const _hoisted_8 = { class: "name-position-card" }
const _hoisted_9 = { class: "name" }
const _hoisted_10 = { class: "sub-heading" }
const _hoisted_11 = {
  key: 1,
  class: "m-1 mt-2 text-align-center"
}

import { ref, watch } from "vue";
import { websiteURL } from "@/utils.ts";
import AppSidebar from "@/components/user/AppSidebar.vue";
import BackButton from "@/components/user/BackButton.vue";
import UserService from "@/services/user.service.js";
import { useRoute, useRouter } from "vue-router";


export default /*@__PURE__*/_defineComponent({
  __name: 'SearchResultsView',
  setup(__props) {

const route = useRoute();
const router = useRouter();

let searchResults = ref([]);

const getSearchResults = (data) => {
  UserService.getSearchResults(data).then(
    (response) => {
      searchResults.value = response.data;
    },
    (error) => {
      const res =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(res);
    }
  );
};

watch(
  () => route.params.keyword,
  (newKeyword) => {
    getSearchResults(newKeyword as string);
  },
  { immediate: true }
);

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(AppSidebar),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(BackButton),
        _cache[0] || (_cache[0] = _createElementVNode("h2", { style: {"text-align":"center","font-weight":"bold"} }, "Search Results", -1))
      ]),
      (_unref(searchResults).length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("ul", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(searchResults), (user) => {
                return (_openBlock(), _createElementBlock("li", {
                  key: user.id
                }, [
                  (user.nameOrg)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                        _createElementVNode("div", null, [
                          (user.fileName)
                            ? (_openBlock(), _createElementBlock("img", {
                                key: 0,
                                id: "profile-img",
                                src: _unref(websiteURL) + 'profile_picture/' + user.fileName,
                                class: "search-img-card"
                              }, null, 8, _hoisted_6))
                            : (_openBlock(), _createElementBlock("img", _hoisted_7))
                        ]),
                        _createElementVNode("div", _hoisted_8, [
                          _createElementVNode("div", null, [
                            _createElementVNode("span", _hoisted_9, _toDisplayString(user.firstName + " " + user.lastName), 1),
                            _cache[1] || (_cache[1] = _createElementVNode("br", null, null, -1)),
                            _createElementVNode("span", _hoisted_10, _toDisplayString(user.position + " at " + user.nameOrg), 1)
                          ]),
                          _createElementVNode("div", null, [
                            _createVNode(_component_router_link, {
                              to: {
                      name: 'search-profile',
                      params: { userId: user.id },
                    }
                            }, {
                              default: _withCtx(() => _cache[2] || (_cache[2] = [
                                _createElementVNode("button", { class: "btn btn-primary" }, "View Profile", -1)
                              ])),
                              _: 2
                            }, 1032, ["to"])
                          ])
                        ])
                      ]))
                    : _createCommentVNode("", true)
                ]))
              }), 128))
            ])
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_11, " No Search Results for " + _toDisplayString(_unref(route).params.keyword), 1))
    ])
  ]))
}
}

})