<template>
  <div class="m-3 lg:m-5 min-height header-space">
    <div class="text-align-center">
      <img
        src="@/assets/images/site/contact-us.jpg"
        class="site-image mx-auto"
        alt="About Us"
      />
    </div>
    <div class="mb-1 lg:mb-3">
      <div class="text-align-center">
        <p class="page-heading">Contact Us</p>
        <div class="heading-line">
          <img src="@/assets/images/site/line.svg" alt="" />
        </div>
      </div>
      <div class="card card-container">
        <span class="font-color-red">* required field</span>
        <Form @submit="handleSubmission" :validation-schema="schema">
          <div>
            <div class="form-group">
              <label for="firstName"
                >First Name<span class="super font-color-red">*</span></label
              >
              <Field name="firstName" type="text" class="form-control" />
              <ErrorMessage name="firstName" class="error-feedback" />
            </div>

            <div class="form-group">
              <label for="lastName"
                >Last Name<span class="super font-color-red">*</span></label
              >
              <Field name="lastName" type="text" class="form-control" />
              <ErrorMessage name="lastName" class="error-feedback" />
            </div>

            <div class="form-group">
              <label for="nameOrg"
                >Institution/Company Name<span class="super font-color-red"
                  >*</span
                ></label
              >
              <Field name="nameOrg" type="text" class="form-control" />
              <ErrorMessage name="nameOrg" class="error-feedback" />
            </div>

            <div class="form-group">
              <label for="email"
                >Email<span class="super font-color-red">*</span></label
              >
              <Field name="email" type="email" class="form-control" />
              <ErrorMessage name="email" class="error-feedback" />
            </div>

            <div class="form-group">
              <label for="message"
                >Message<span class="super font-color-red">*</span></label
              >
              <Field
                name="message"
                type="text"
                as="textarea"
                class="form-control"
              />
              <ErrorMessage name="message" class="error-feedback" />
            </div>

            <br />
            <div class="form-group text-align-center">
              <button class="btn btn-primary btn-block" :disabled="loading">
                <span
                  v-show="loading"
                  class="spinner-border spinner-border-sm"
                ></span>
                Contact Us
              </button>
            </div>
          </div>
        </Form>

        <div
          v-if="message"
          class="alert"
          :class="successful ? 'alert-success' : 'alert-danger'"
        >
          {{ message }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import UserService from "../services/user.service.js";
import { showToast } from "@/utils";

let loading = false;
let successful = false;
let message = "";

const schema = yup.object().shape({
  firstName: yup.string().required("First Name is required!"),
  lastName: yup.string().required("Last Name is required!"),
  nameOrg: yup.string().required("Institution/Company Name is required!"),
  email: yup.string().required("Email is required!").email("Email is invalid!"),
  message: yup
    .string()
    .required("Message is required!")
    .min(10, "Must be at least 10 characters!")
    .max(500, "Must be maximum 500 characters!"),
});

const handleSubmission = (data, { resetForm }) => {
  loading = true;

  UserService.saveContactUsInfo(data).then(
    (data) => {
      message = data.message;
      successful = true;
      loading = false;
      showToast(
        "Query raised successfully. Our team will contact you within 2 days"
      );

      resetForm();
    },
    (error) => {
      message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      successful = false;
      loading = false;
    }
  );
};
</script>

<style scoped>
@media screen and (max-width: 768px) {
  .card-container.card {
    max-width: 90% !important;
    padding: 10px 10px;
  }

  .card {
    margin-top: 20px;
  }
}
</style>
