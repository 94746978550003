import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, createTextVNode as _createTextVNode, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "grid-container" }
const _hoisted_2 = { class: "col-md-12 mt-3" }
const _hoisted_3 = { class: "back-grid" }
const _hoisted_4 = { class: "card card-container" }
const _hoisted_5 = { class: "form-grid" }
const _hoisted_6 = { class: "form-name-column" }
const _hoisted_7 = {
  key: 0,
  class: "form-group"
}
const _hoisted_8 = { key: 0 }
const _hoisted_9 = {
  key: 1,
  class: "form-group"
}
const _hoisted_10 = {
  key: 2,
  class: "form-group"
}
const _hoisted_11 = { class: "form-column" }
const _hoisted_12 = {
  key: 0,
  class: "form-group"
}
const _hoisted_13 = { for: "gender" }
const _hoisted_14 = { key: 0 }
const _hoisted_15 = {
  key: 1,
  class: "form-group"
}
const _hoisted_16 = { for: "ethnicity" }
const _hoisted_17 = { key: 0 }
const _hoisted_18 = { class: "form-column" }
const _hoisted_19 = {
  key: 0,
  class: "form-group"
}
const _hoisted_20 = { key: 0 }
const _hoisted_21 = {
  key: 1,
  class: "form-group"
}
const _hoisted_22 = { for: "ageRange" }
const _hoisted_23 = { class: "form-column" }
const _hoisted_24 = {
  key: 0,
  class: "form-group"
}
const _hoisted_25 = {
  key: 1,
  class: "form-group"
}
const _hoisted_26 = {
  key: 0,
  class: "form-group"
}
const _hoisted_27 = { key: 0 }
const _hoisted_28 = {
  key: 1,
  class: "form-group"
}
const _hoisted_29 = ["checked"]
const _hoisted_30 = { key: 0 }
const _hoisted_31 = {
  key: 2,
  class: "form-group"
}
const _hoisted_32 = ["checked"]
const _hoisted_33 = { key: 0 }
const _hoisted_34 = {
  key: 3,
  class: "form-group"
}
const _hoisted_35 = ["checked"]
const _hoisted_36 = { key: 0 }
const _hoisted_37 = {
  key: 4,
  class: "form-group"
}
const _hoisted_38 = { for: "physicalDis" }
const _hoisted_39 = ["checked"]
const _hoisted_40 = { key: 0 }
const _hoisted_41 = {
  key: 0,
  class: "form-group"
}
const _hoisted_42 = { class: "form-group" }
const _hoisted_43 = { for: "location" }
const _hoisted_44 = { class: "flex flex-direction-row gap-1" }
const _hoisted_45 = {
  key: 0,
  style: {"flex-basis":"0"}
}
const _hoisted_46 = {
  key: 1,
  style: {"flex-basis":"0"}
}
const _hoisted_47 = {
  key: 1,
  class: "form-group"
}
const _hoisted_48 = { key: 0 }
const _hoisted_49 = {
  key: 2,
  class: "form-group"
}
const _hoisted_50 = { for: "size" }
const _hoisted_51 = {
  key: 3,
  class: "form-group"
}
const _hoisted_52 = { for: "genderDiv" }
const _hoisted_53 = {
  key: 4,
  class: "form-group"
}
const _hoisted_54 = { for: "ethnicDiv" }
const _hoisted_55 = {
  key: 5,
  class: "form-group"
}
const _hoisted_56 = { for: "website" }
const _hoisted_57 = {
  key: 6,
  class: "form-group"
}
const _hoisted_58 = ["checked"]
const _hoisted_59 = { key: 0 }
const _hoisted_60 = {
  key: 7,
  class: "form-group"
}
const _hoisted_61 = { for: "organPolicy" }
const _hoisted_62 = {
  role: "button",
  class: "hint"
}
const _hoisted_63 = {
  class: "speech_bubble tool_tip_text",
  id: "tooltip",
  style: {"display":"inline"}
}
const _hoisted_64 = {
  type: "button",
  class: "close-btn-tooltip mr-4"
}
const _hoisted_65 = { class: "form-group text-align-center" }
const _hoisted_66 = ["disabled"]
const _hoisted_67 = { class: "spinner-border spinner-border-sm" }

import { ref } from "vue";
import { useRouter } from "vue-router";
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import PractitionerSidebar from "@/components/PractitionerSidebar.vue";
import BackButton from "@/components/user/BackButton.vue";
import Multiselect from "@vueform/multiselect";
import {
  displayLabels,
  physicalDisOptions,
  yearsOfExpOptions,
  ageRangeOptions,
  areaOfExpertiseOptions,
  pronounsOptions,
  profLicenseOptions,
  typeProjectOptions,
  typeOfFirmOptions,
  positionOptions,
  typeTechnologyOptions,
  genderOptions,
  ethnicityOptions,
  stateOptions,
  companySizeOptions,
  diversityOptions,
  levelEducationOptions,
  practitionerTitleOptions,
  showToast,
  showInfoToast,
  validateOptions,
  showErrorToast,
} from "@/utils";
import { useConfigStore } from "@/stores/ConfigStore";
import { useAuthStore } from "@/stores/AuthStore";
import { useAvailabilityStore } from "@/stores/AvailabilityStore";
import UserService from "@/services/user.service.js";


export default /*@__PURE__*/_defineComponent({
  __name: 'UpdateProfileView',
  setup(__props) {

const ConfigStore = useConfigStore();
const AuthStore = useAuthStore();
const AvailabilityStore = useAvailabilityStore();
const router = useRouter();

const profileFields = ConfigStore.config.filter(
  (el) => el.table === "practitioner_profile"
);
const profLicenseOptionsAdded = [
  ...profLicenseOptions,
  {
    label: "Others, please specify",
    value: "Others",
  },
];
const typeProjectOptionsAdded = [
  ...typeProjectOptions,
  {
    label: "Others, please specify",
    value: "Others",
  },
];
const areaOfExpertiseOptionsAdded = [
  ...areaOfExpertiseOptions,
  {
    label: "Others, please specify",
    value: "Others",
  },
];
const typeTechnologyOptionsAdded = [
  ...typeTechnologyOptions,
  {
    label: "Others, please specify",
    value: "Others",
  },
];

let initialState = {
  ageRange: "",
  countryStudy: "",
  ethnicity: "",
  ethnicity_text: "",
  ethnicDiv: "",
  expertise: [],
  expertise_text: "",
  gender: "",
  gender_text: "",
  genderDiv: "",
  levelEducation: "",
  city: "",
  state: "",
  organPolicy: "",
  physicalDis: [],
  physicalDis_text: "",
  position: "",
  position_text: "",
  size: "",
  type: "",
  type_text: "",
  website: "",
  yearsOfExp: "",
  title: "",
  title_text: "",
  firstName: "",
  lastName: "",
  nameOrg: "",
  profLicense: [],
  profLicense_text: "",
  typeProject: [],
  typeProject_text: "",
  pronouns: "",
  pronouns_text: "",
  typeTechnology: [],
  typeTechnology_text: "",
};

let currentUser = AuthStore.auth;

const schema = yup.object().shape({
  fields: yup.array().default(profileFields),
  title: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "title")?.disabled,
    then: (schema) => schema.required("Title is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  title_text: yup.string().when("title", {
    is: (val) => val === "Others",
    then: (schema) => schema.required("Title is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  firstName: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "firstName")?.disabled,
    then: (schema) => schema.required("First Name is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  lastName: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "lastName")?.disabled,
    then: (schema) => schema.required("Last Name is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  ageRange: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "ageRange")?.disabled,
    then: (schema) => schema.required("Age Range is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  levelEducation: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "levelEducation")?.disabled,
    then: (schema) =>
      schema.required("Highest Level of Education is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  position: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "position")?.disabled,
    then: (schema) =>
      schema.required("Position/Role in organization is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  position_text: yup.string().when("position", {
    is: (val) => val === "Others",
    then: (schema) =>
      schema.required("Position/Role in organization is required!"),
    otherwise: (schema) => schema.optional(),
  }),
  yearsOfExp: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "yearsOfExp")?.disabled,
    then: (schema) => schema.required("Years of experience is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  expertise: yup
    .array()
    .of(yup.string())
    .when("fields", {
      is: (val) => !val.find((el) => el.name === "expertise")?.disabled,
      then: (schema) =>
        schema
          .required("Area of expertise is required!")
          .min(1, "Area of expertise is required!"),
      otherwise: (schema) => schema.optional().min(0).nullable(),
    }),
  expertise_text: yup.string().when("expertise", {
    is: (val) => val.includes("Others"),
    then: (schema) => schema.required("Area of expertise is required!"),
    otherwise: (schema) => schema.optional(),
  }),
  profLicense: yup
    .array()
    .of(yup.string())
    .when("fields", {
      is: (val) => !val.find((el) => el.name === "profLicense")?.disabled,
      then: (schema) =>
        schema
          .required("Professional License is required!")
          .min(1, "Professional License is required!"),
      otherwise: (schema) => schema.optional().nullable().min(0),
    }),
  profLicense_text: yup.string().when("profLicense", {
    is: (val) => val.includes("Others"),
    then: (schema) => schema.required("Professional License is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  typeProject: yup
    .array()
    .of(yup.string())
    .when("fields", {
      is: (val) => !val.find((el) => el.name === "typeProject")?.disabled,
      then: (schema) =>
        schema
          .required(
            "Types of Projects you have Executed or Executing is required!"
          )
          .min(
            1,
            "Types of Projects you have Executed or Executing is required!"
          ),
      otherwise: (schema) => schema.optional().nullable().min(0),
    }),

  typeProject_text: yup.string().when("typeProject", {
    is: (val) => val.includes("Others"),
    then: (schema) =>
      schema.required(
        "Types of Projects you have Executed or Executing is required!"
      ),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  physicalDis: yup
    .array()
    .of(yup.string())
    .when("fields", {
      is: (val) => !val.find((el) => el.name === "physicalDis")?.disabled,
      then: (schema) =>
        schema
          .required("Physical disabilities is required!")
          .min(1, "Physical disabilities is required!"),
      otherwise: (schema) => schema.optional().nullable().min(0),
    }),
  physicalDis_text: yup.string().when("physicalDis", {
    is: (val) => val.includes("Others"),
    then: (schema) => schema.required("Physical disabilities is required!"),
    otherwise: (schema) => schema.optional(),
  }),
  nameOrg: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "nameOrg")?.disabled,
    then: (schema) =>
      schema.required("Name of Organization/Institution is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),

  city: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "city")?.disabled,
    then: (schema) => schema.required("City is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  state: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "state")?.disabled,
    then: (schema) => schema.required("State is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  type: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "type")?.disabled,
    then: (schema) => schema.required("Type of Firm is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  type_text: yup.string().when("type", {
    is: (val) => val === "Others",
    then: (schema) => schema.required("Type of Firm is required!"),
    otherwise: (schema) => schema.optional(),
  }),
  size: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "size")?.disabled,
    then: (schema) => schema.required("Size of Firm is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  ethnicDiv: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "ethnicDiv")?.disabled,
    then: (schema) =>
      schema.required("Racial Diversity of organization is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  genderDiv: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "genderDiv")?.disabled,
    then: (schema) =>
      schema.required("Gender Diversity of organization is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  website: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "website")?.disabled,
    then: (schema) => schema.required("Website of organization is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  organPolicy: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "organPolicy")?.disabled,
    then: (schema) =>
      schema.required("Organization policy/support/resources is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  pronouns: yup.string().optional().nullable(),
  pronouns_text: yup.string().when("pronouns", {
    is: (val) => val === "Others",
    then: (schema) => schema.required("Pronouns is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  ethnicity: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "ethnicity")?.disabled,
    then: (schema) => schema.required("Racial Identity is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  ethnicity_text: yup.string().when("ethnicity", {
    is: (val) => val === "Others",
    then: (schema) => schema.required("Racial Identity is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  gender: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "gender")?.disabled,
    then: (schema) => schema.required("Gender Identity is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  gender_text: yup.string().when("gender", {
    is: (val) => val === "Others",
    then: (schema) => schema.required("Gender Identity is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  typeTechnology: yup
    .array()
    .of(yup.string())
    .when("fields", {
      is: (val) => !val.find((el) => el.name === "typeTechnology")?.disabled,
      then: (schema) =>
        schema
          .required(
            "Types of Technologies Adopted by Your Organization is required!"
          )
          .min(
            1,
            "Types of Technologies Adopted by Your Organization is required!"
          ),
      otherwise: (schema) => schema.optional().nullable().min(0),
    }),
  typeTechnology_text: yup.string().when("typeTechnology", {
    is: (val) => val.includes("Others"),
    then: (schema) =>
      schema.required(
        "Types of Technologies Adopted by Your Organization is required!"
      ),
    otherwise: (schema) => schema.optional(),
  }),
});
if (AuthStore.profile) {
  initialState.ageRange = currentUser.profile.ageRange;
  initialState.countryStudy = currentUser.profile.countryStudy;
  initialState.ethnicDiv = currentUser.profile.ethnicDiv;
  initialState.expertise = JSON.parse(currentUser.profile.expertise);
  if (JSON.parse(currentUser.profile.expertise).includes("Others")) {
    initialState["expertise_text"] = JSON.parse(
      currentUser.profile.expertise
    ).at(-1);
    initialState.expertise = JSON.parse(currentUser.profile.expertise).slice(
      0,
      -1
    );
  } else {
    initialState.expertise = JSON.parse(currentUser.profile.expertise);
  }
  initialState.genderDiv = currentUser.profile.genderDiv;
  initialState.levelEducation = currentUser.profile.levelEducation;
  initialState.city = currentUser.profile.city;
  initialState.state = currentUser.profile.state;
  initialState.organPolicy = currentUser.profile.organPolicy;
  if (JSON.parse(currentUser.profile.physicalDis).includes("Others")) {
    initialState["physicalDis_text"] = JSON.parse(
      currentUser.profile.physicalDis
    ).at(-1);
    initialState.physicalDis = JSON.parse(
      currentUser.profile.physicalDis
    ).slice(0, -1);
  } else {
    initialState.physicalDis = JSON.parse(currentUser.profile.physicalDis);
  }
  if (
    positionOptions.filter((e) => e.value === currentUser.profile.position)
      .length === 0
  ) {
    initialState.position = "Others";
    initialState.position_text = currentUser.profile.position;
  } else {
    initialState.position = currentUser.profile.position;
  }
  if (JSON.parse(currentUser.profile.profLicense).includes("Others")) {
    initialState["profLicense_text"] = JSON.parse(
      currentUser.profile.profLicense
    ).at(-1);
    initialState.profLicense = JSON.parse(
      currentUser.profile.profLicense
    ).slice(0, -1);
  } else {
    initialState.profLicense = JSON.parse(currentUser.profile.profLicense);
  }
  if (JSON.parse(currentUser.profile.typeProject).includes("Others")) {
    initialState["typeProject_text"] = JSON.parse(
      currentUser.profile.typeProject
    ).at(-1);
    initialState.typeProject = JSON.parse(
      currentUser.profile.typeProject
    ).slice(0, -1);
  } else {
    initialState.typeProject = JSON.parse(currentUser.profile.typeProject);
  }
  initialState.size = currentUser.profile.size;
  if (
    typeOfFirmOptions.filter((e) => e.value === currentUser.profile.type)
      .length === 0
  ) {
    initialState.type = "Others";
    initialState.type_text = currentUser.profile.type;
  } else {
    initialState.type = currentUser.profile.type;
  }
  initialState.website = currentUser.profile.website;
  initialState.yearsOfExp = currentUser.profile.yearsOfExp;
  if (JSON.parse(currentUser.profile.typeTechnology).includes("Others")) {
    initialState["typeTechnology_text"] = JSON.parse(
      currentUser.profile.typeTechnology
    ).at(-1);
    initialState.typeTechnology = JSON.parse(
      currentUser.profile.typeTechnology
    ).slice(0, -1);
  } else {
    initialState.typeTechnology = JSON.parse(
      currentUser.profile.typeTechnology
    );
  }
  if (
    !practitionerTitleOptions.some((el) => el.value === AuthStore.auth.title)
  ) {
    initialState.title_text = AuthStore.auth.title;
    initialState.title = "Others";
  } else {
    initialState.title = AuthStore.auth.title;
  }
  initialState.firstName = AuthStore.auth.firstName;
  initialState.lastName = AuthStore.auth.lastName;
  initialState.nameOrg = AuthStore.auth.nameOrg;
  if (
    !pronounsOptions.some((el) => el.value === AuthStore.auth.profile.pronouns)
  ) {
    initialState.pronouns_text = AuthStore.auth.profile.pronouns;
    initialState.pronouns = "Others";
  } else {
    initialState.pronouns = AuthStore.auth.profile.pronouns;
  }
  if (!ethnicityOptions.some((el) => el.value === AuthStore.auth.ethnicity)) {
    initialState.ethnicity_text = AuthStore.auth.ethnicity;
    initialState.ethnicity = "Others";
  } else {
    initialState.ethnicity = AuthStore.auth.ethnicity;
  }
  if (!genderOptions.some((el) => el.value === AuthStore.auth.gender)) {
    initialState.gender_text = AuthStore.auth.gender;
    initialState.gender = "Others";
  } else {
    initialState.gender = AuthStore.auth.gender;
  }
} else {
  initialState.title = AuthStore.auth.title;
  initialState.firstName = AuthStore.auth.firstName;
  initialState.lastName = AuthStore.auth.lastName;
  // showInfoToast("Please complete your profile before proceeding to Home page.");
}

const formValues = {
  ageRange: initialState.ageRange,
  countryStudy: initialState.countryStudy,
  ethnicDiv: initialState.ethnicDiv,
  expertise: initialState.expertise,
  expertise_text: initialState.expertise_text,
  genderDiv: initialState.genderDiv,
  levelEducation: initialState.levelEducation,
  city: initialState.city,
  state: initialState.state,
  organPolicy: initialState.organPolicy,
  physicalDis: initialState.physicalDis,
  physicalDis_text: initialState.physicalDis_text,
  position: initialState.position,
  position_text: initialState.position_text,
  size: initialState.size,
  type: initialState.type,
  type_text: initialState.type_text,
  website: initialState.website,
  typeTechnology: initialState.typeTechnology,
  typeTechnology_text: initialState.typeTechnology_text,
  yearsOfExp: initialState.yearsOfExp,
  title: initialState.title,
  title_text: initialState.title_text,
  firstName: initialState.firstName,
  lastName: initialState.lastName,
  nameOrg: initialState.nameOrg,
  profLicense: initialState.profLicense,
  profLicense_text: initialState.profLicense_text,
  typeProject: initialState.typeProject,
  typeProject_text: initialState.typeProject_text,
  pronouns: initialState.pronouns,
  pronouns_text: initialState.pronouns_text,
  gender: initialState.gender,
  gender_text: initialState.gender_text,
  ethnicity: initialState.ethnicity,
  ethnicity_text: initialState.ethnicity_text,
};

let loading = ref(false);
let successful = ref(false);
let message = ref("");

const saveProfile = (data) => {
  loading.value = true;
  if (data.type === "Others") data.type = data["type_text"];
  if (data.expertise.includes("Others")) {
    data.expertise.push(data["expertise_text"]);
  }
  if (data.title === "Others") data.title = data["title_text"];
  if (data.position === "Others") data.position = data["position_text"];
  if (data.gender === "Others") data.gender = data["gender_text"];
  if (data.ethnicity === "Others") data.ethnicity = data["ethnicity_text"];
  if (data.pronouns === "Others") data.pronouns = data["pronouns_text"];

  if (data.profLicense.includes("Others")) {
    data.profLicense.push(data["profLicense_text"]);
  }
  if (data.typeProject.includes("Others")) {
    data.typeProject.push(data["typeProject_text"]);
  }
  if (data.physicalDis.includes("Others")) {
    data.physicalDis.push(data["physicalDis_text"]);
  }
  if (data.typeTechnology.includes("Others")) {
    data.typeTechnology.push(data["typeTechnology_text"]);
  }
  AuthStore.saveProfile(data).then(
    () => {
      AuthStore.getProfile().then(
        () => {
          showToast("Your profile has been updated.");
          console.log(AvailabilityStore.availability);
          if (AvailabilityStore.status)
            router.push({ name: "industry-practitioner-feed" });
          else router.push({ name: "industry-practitioner-availability" });
          loading.value = false;
        },
        (err) => {
          loading.value = false;
          message.value =
            (err.response && err.response.data && err.response.data.message) ||
            err.message ||
            err.toString();
        }
      );
    },
    (error) => {
      loading.value = false;
      message.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};

const onInvalidSubmit = ({ errors }) => {
  Object.values(errors).forEach((error) => showErrorToast(error));
};
const elementVisible = ref(false);
let tooltipTimeout;
const showTooltip = () => {
  elementVisible.value = true;
  tooltipTimeout = setTimeout(() => (elementVisible.value = false), 5000);
};
const hideTooltip = () => {
  elementVisible.value = false;
  clearTimeout(tooltipTimeout);
};

const validateOpt = (e, s) => {
  validateOptions(e, s, "None");
};

const cities = ref([] as any[]);

const getCityOptions = (state) => {
  UserService.getCities(state).then(
    (response) => {
      cities.value = response.data;
    },
    (error) => {
      console.log(error);
      message.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};

if (initialState.state) getCityOptions(initialState.state);

return (_ctx: any,_cache: any) => {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_va_divider = _resolveComponent("va-divider")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(PractitionerSidebar),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(BackButton),
        _cache[0] || (_cache[0] = _createElementVNode("h2", { style: {"text-align":"center","font-weight":"bold"} }, " Practitioner Profile ", -1))
      ]),
      _createElementVNode("div", null, [
        _cache[37] || (_cache[37] = _createElementVNode("br", null, null, -1)),
        _cache[38] || (_cache[38] = _createElementVNode("span", { class: "font-color-red ml-5" }, "* required field", -1)),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_unref(Form), {
            onSubmit: saveProfile,
            onInvalidSubmit: onInvalidSubmit,
            "validation-schema": _unref(schema),
            "initial-values": formValues
          }, {
            default: _withCtx(({ values }) => [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", null, [
                  _cache[19] || (_cache[19] = _createElementVNode("h4", { class: "text-align-center bold-text" }, " Personal Information ", -1)),
                  _cache[20] || (_cache[20] = _createElementVNode("br", null, null, -1)),
                  _createElementVNode("div", _hoisted_6, [
                    (
                      !_unref(profileFields).find((el) => el.name === 'title')?.disabled
                    )
                      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                          _cache[1] || (_cache[1] = _createElementVNode("label", { for: "title" }, [
                            _createTextVNode("Title"),
                            _createElementVNode("span", { class: "super font-color-red" }, "*")
                          ], -1)),
                          _createVNode(_unref(Field), {
                            modelValue: values.title,
                            "onUpdate:modelValue": ($event: any) => ((values.title) = $event),
                            name: "title",
                            type: "text",
                            class: "dropdown"
                          }, {
                            default: _withCtx(({ field }) => [
                              _createVNode(_unref(Multiselect), _mergeProps(field, {
                                modelValue: values.title,
                                "onUpdate:modelValue": ($event: any) => ((values.title) = $event),
                                options: _unref(practitionerTitleOptions),
                                mode: "single",
                                searchable: true
                              }), null, 16, ["modelValue", "onUpdate:modelValue", "options"])
                            ]),
                            _: 2
                          }, 1032, ["modelValue", "onUpdate:modelValue"]),
                          (values.title === 'Others')
                            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                                _createVNode(_unref(Field), {
                                  name: "title_text",
                                  type: "text",
                                  placeholder: "Please Specify",
                                  class: "form-control bg-others"
                                })
                              ]))
                            : _createCommentVNode("", true),
                          _createVNode(_unref(ErrorMessage), {
                            name: "title",
                            class: "error-feedback"
                          })
                        ]))
                      : _createCommentVNode("", true),
                    (
                      !_unref(profileFields).find((el) => el.name === 'firstName')
                        ?.disabled
                    )
                      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                          _cache[2] || (_cache[2] = _createElementVNode("label", { for: "firstName" }, [
                            _createTextVNode("First Name"),
                            _createElementVNode("span", { class: "super font-color-red" }, "*")
                          ], -1)),
                          _createVNode(_unref(Field), {
                            name: "firstName",
                            type: "text",
                            class: "form-control"
                          }),
                          _createVNode(_unref(ErrorMessage), {
                            name: "firstName",
                            class: "error-feedback"
                          })
                        ]))
                      : _createCommentVNode("", true),
                    (
                      !_unref(profileFields).find((el) => el.name === 'lastName')
                        ?.disabled
                    )
                      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                          _cache[3] || (_cache[3] = _createElementVNode("label", { for: "lastName" }, [
                            _createTextVNode("Last Name"),
                            _createElementVNode("span", { class: "super font-color-red" }, "*")
                          ], -1)),
                          _createVNode(_unref(Field), {
                            name: "lastName",
                            type: "text",
                            class: "form-control"
                          }),
                          _createVNode(_unref(ErrorMessage), {
                            name: "lastName",
                            class: "error-feedback"
                          })
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_11, [
                    (
                      !_unref(profileFields).find((el) => el.name === 'gender')
                        ?.disabled
                    )
                      ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                          _createElementVNode("label", _hoisted_13, [
                            _cache[4] || (_cache[4] = _createTextVNode("Gender Identity")),
                            _cache[5] || (_cache[5] = _createElementVNode("span", { class: "super font-color-red" }, "*", -1)),
                            _createVNode(_component_font_awesome_icon, {
                              icon: "fa-solid fa-lock",
                              class: "ml-1",
                              title: "This information is private."
                            })
                          ]),
                          _createVNode(_unref(Field), {
                            modelValue: values.gender,
                            "onUpdate:modelValue": ($event: any) => ((values.gender) = $event),
                            name: "gender",
                            type: "text",
                            class: "dropdown"
                          }, {
                            default: _withCtx(({ field }) => [
                              _createVNode(_unref(Multiselect), _mergeProps(field, {
                                modelValue: values.gender,
                                "onUpdate:modelValue": ($event: any) => ((values.gender) = $event),
                                options: _unref(genderOptions),
                                mode: "single",
                                searchable: true
                              }), null, 16, ["modelValue", "onUpdate:modelValue", "options"])
                            ]),
                            _: 2
                          }, 1032, ["modelValue", "onUpdate:modelValue"]),
                          (values.gender === 'Others')
                            ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                                _createVNode(_unref(Field), {
                                  name: "gender_text",
                                  type: "text",
                                  placeholder: "Please Specify",
                                  class: "form-control bg-others"
                                })
                              ]))
                            : _createCommentVNode("", true),
                          _createVNode(_unref(ErrorMessage), {
                            name: "gender",
                            class: "error-feedback"
                          })
                        ]))
                      : _createCommentVNode("", true),
                    (
                      !_unref(profileFields).find((el) => el.name === 'ethnicity')
                        ?.disabled
                    )
                      ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                          _createElementVNode("label", _hoisted_16, [
                            _cache[6] || (_cache[6] = _createTextVNode("Racial Identity")),
                            _cache[7] || (_cache[7] = _createElementVNode("span", { class: "super font-color-red" }, "*", -1)),
                            _createVNode(_component_font_awesome_icon, {
                              icon: "fa-solid fa-lock",
                              class: "ml-1",
                              title: "This information is private."
                            })
                          ]),
                          _createVNode(_unref(Field), {
                            modelValue: values.ethnicity,
                            "onUpdate:modelValue": ($event: any) => ((values.ethnicity) = $event),
                            name: "ethnicity",
                            type: "text",
                            class: "dropdown"
                          }, {
                            default: _withCtx(({ field }) => [
                              _createVNode(_unref(Multiselect), _mergeProps(field, {
                                modelValue: values.ethnicity,
                                "onUpdate:modelValue": ($event: any) => ((values.ethnicity) = $event),
                                options: _unref(ethnicityOptions),
                                mode: "single",
                                searchable: true
                              }), null, 16, ["modelValue", "onUpdate:modelValue", "options"])
                            ]),
                            _: 2
                          }, 1032, ["modelValue", "onUpdate:modelValue"]),
                          (values.ethnicity === 'Others')
                            ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                                _createVNode(_unref(Field), {
                                  name: "ethnicity_text",
                                  type: "text",
                                  placeholder: "Please Specify",
                                  class: "form-control bg-others"
                                })
                              ]))
                            : _createCommentVNode("", true),
                          _createVNode(_unref(ErrorMessage), {
                            name: "ethnicity",
                            class: "error-feedback"
                          })
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_18, [
                    (
                      !_unref(profileFields).find((el) => el.name === 'pronouns')
                        ?.disabled
                    )
                      ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                          _cache[8] || (_cache[8] = _createElementVNode("label", { for: "pronouns" }, "Pronouns ", -1)),
                          _createVNode(_unref(Field), {
                            modelValue: values.pronouns,
                            "onUpdate:modelValue": ($event: any) => ((values.pronouns) = $event),
                            name: "pronouns",
                            type: "text",
                            class: "dropdown"
                          }, {
                            default: _withCtx(({ field }) => [
                              _createVNode(_unref(Multiselect), _mergeProps(field, {
                                modelValue: values.pronouns,
                                "onUpdate:modelValue": ($event: any) => ((values.pronouns) = $event),
                                options: _unref(pronounsOptions),
                                mode: "single",
                                searchable: true
                              }), null, 16, ["modelValue", "onUpdate:modelValue", "options"])
                            ]),
                            _: 2
                          }, 1032, ["modelValue", "onUpdate:modelValue"]),
                          (values.pronouns === 'Others')
                            ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                                _createVNode(_unref(Field), {
                                  name: "pronouns_text",
                                  type: "text",
                                  placeholder: "Please Specify",
                                  class: "form-control bg-others"
                                })
                              ]))
                            : _createCommentVNode("", true),
                          _createVNode(_unref(ErrorMessage), {
                            name: "pronouns",
                            class: "error-feedback"
                          })
                        ]))
                      : _createCommentVNode("", true),
                    (
                      !_unref(profileFields).find((el) => el.name === 'ageRange')
                        ?.disabled
                    )
                      ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                          _createElementVNode("label", _hoisted_22, [
                            _cache[9] || (_cache[9] = _createTextVNode("Age Range")),
                            _cache[10] || (_cache[10] = _createElementVNode("span", { class: "super font-color-red" }, "*", -1)),
                            _createVNode(_component_font_awesome_icon, {
                              icon: "fa-solid fa-lock",
                              class: "ml-1",
                              title: "This information is private."
                            })
                          ]),
                          _createVNode(_unref(Field), {
                            modelValue: values.ageRange,
                            "onUpdate:modelValue": ($event: any) => ((values.ageRange) = $event),
                            name: "ageRange",
                            type: "text",
                            class: "dropdown"
                          }, {
                            default: _withCtx(({ field }) => [
                              _createVNode(_unref(Multiselect), _mergeProps(field, {
                                modelValue: values.ageRange,
                                "onUpdate:modelValue": ($event: any) => ((values.ageRange) = $event),
                                options: _unref(ageRangeOptions),
                                mode: "single",
                                searchable: true
                              }), null, 16, ["modelValue", "onUpdate:modelValue", "options"])
                            ]),
                            _: 2
                          }, 1032, ["modelValue", "onUpdate:modelValue"]),
                          _createVNode(_unref(ErrorMessage), {
                            name: "ageRange",
                            class: "error-feedback"
                          })
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_23, [
                    (
                      !_unref(profileFields).find((el) => el.name === 'levelEducation')
                        ?.disabled
                    )
                      ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
                          _cache[11] || (_cache[11] = _createElementVNode("label", { for: "levelEducation" }, [
                            _createTextVNode("Highest Level of Education"),
                            _createElementVNode("span", { class: "super font-color-red" }, "*")
                          ], -1)),
                          _createVNode(_unref(Field), {
                            modelValue: values.levelEducation,
                            "onUpdate:modelValue": ($event: any) => ((values.levelEducation) = $event),
                            name: "levelEducation",
                            type: "text",
                            class: "dropdown"
                          }, {
                            default: _withCtx(({ field }) => [
                              _createVNode(_unref(Multiselect), _mergeProps(field, {
                                modelValue: values.levelEducation,
                                "onUpdate:modelValue": ($event: any) => ((values.levelEducation) = $event),
                                options: _unref(levelEducationOptions),
                                mode: "single",
                                searchable: true
                              }), null, 16, ["modelValue", "onUpdate:modelValue", "options"])
                            ]),
                            _: 2
                          }, 1032, ["modelValue", "onUpdate:modelValue"]),
                          _createVNode(_unref(ErrorMessage), {
                            name: "levelEducation",
                            class: "error-feedback"
                          })
                        ]))
                      : _createCommentVNode("", true),
                    (
                      !_unref(profileFields).find((el) => el.name === 'yearsOfExp')
                        ?.disabled
                    )
                      ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
                          _cache[12] || (_cache[12] = _createElementVNode("label", { for: "yearsOfExp" }, [
                            _createTextVNode("Years of Experience"),
                            _createElementVNode("span", { class: "super font-color-red" }, "*")
                          ], -1)),
                          _createVNode(_unref(Field), {
                            modelValue: values.yearsOfExp,
                            "onUpdate:modelValue": ($event: any) => ((values.yearsOfExp) = $event),
                            name: "yearsOfExp",
                            type: "text",
                            class: "dropdown"
                          }, {
                            default: _withCtx(({ field }) => [
                              _createVNode(_unref(Multiselect), _mergeProps(field, {
                                modelValue: values.yearsOfExp,
                                "onUpdate:modelValue": ($event: any) => ((values.yearsOfExp) = $event),
                                options: _unref(yearsOfExpOptions),
                                mode: "single",
                                searchable: true
                              }), null, 16, ["modelValue", "onUpdate:modelValue", "options"])
                            ]),
                            _: 2
                          }, 1032, ["modelValue", "onUpdate:modelValue"]),
                          _createVNode(_unref(ErrorMessage), {
                            name: "yearsOfExp",
                            class: "error-feedback"
                          })
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  (
                    !_unref(profileFields).find((el) => el.name === 'position')
                      ?.disabled
                  )
                    ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
                        _cache[13] || (_cache[13] = _createElementVNode("label", { for: "position" }, [
                          _createTextVNode("Position/Role in Organization"),
                          _createElementVNode("span", { class: "super font-color-red" }, "*")
                        ], -1)),
                        _createVNode(_unref(Field), {
                          modelValue: values.position,
                          "onUpdate:modelValue": ($event: any) => ((values.position) = $event),
                          name: "position",
                          type: "text",
                          class: "dropdown"
                        }, {
                          default: _withCtx(({ field }) => [
                            _createVNode(_unref(Multiselect), _mergeProps(field, {
                              modelValue: values.position,
                              "onUpdate:modelValue": ($event: any) => ((values.position) = $event),
                              options: _unref(positionOptions),
                              mode: "single",
                              searchable: true
                            }), null, 16, ["modelValue", "onUpdate:modelValue", "options"])
                          ]),
                          _: 2
                        }, 1032, ["modelValue", "onUpdate:modelValue"]),
                        (values.position === 'Others')
                          ? (_openBlock(), _createElementBlock("div", _hoisted_27, [
                              _createVNode(_unref(Field), {
                                name: "position_text",
                                type: "text",
                                placeholder: "Please Specify",
                                class: "form-control bg-others"
                              })
                            ]))
                          : _createCommentVNode("", true),
                        _createVNode(_unref(ErrorMessage), {
                          name: "position",
                          class: "error-feedback"
                        })
                      ]))
                    : _createCommentVNode("", true),
                  (
                    !_unref(profileFields).find((el) => el.name === 'expertise')
                      ?.disabled
                  )
                    ? (_openBlock(), _createElementBlock("div", _hoisted_28, [
                        _cache[14] || (_cache[14] = _createElementVNode("label", { for: "expertise" }, [
                          _createTextVNode("Area of Expertise"),
                          _createElementVNode("span", { class: "super font-color-red" }, "*")
                        ], -1)),
                        _createVNode(_unref(Field), {
                          modelValue: values.expertise,
                          "onUpdate:modelValue": ($event: any) => ((values.expertise) = $event),
                          name: "expertise",
                          type: "text",
                          class: "dropdown"
                        }, {
                          default: _withCtx(({ field }) => [
                            _createVNode(_unref(Multiselect), _mergeProps(field, {
                              modelValue: values.expertise,
                              "onUpdate:modelValue": ($event: any) => ((values.expertise) = $event),
                              options: areaOfExpertiseOptionsAdded,
                              mode: "tags",
                              multipleLabel: _unref(displayLabels),
                              searchable: true,
                              hideSelected: false,
                              closeOnSelect: false,
                              closeOnDeselect: false
                            }), {
                              option: _withCtx(({ option }) => [
                                _createElementVNode("input", {
                                  type: "checkbox",
                                  class: "input-pointer",
                                  checked: values.expertise.includes(option.value)
                                }, null, 8, _hoisted_29),
                                _createTextVNode("   " + _toDisplayString(option.label), 1)
                              ]),
                              _: 2
                            }, 1040, ["modelValue", "onUpdate:modelValue", "multipleLabel"])
                          ]),
                          _: 2
                        }, 1032, ["modelValue", "onUpdate:modelValue"]),
                        (values.expertise.includes('Others'))
                          ? (_openBlock(), _createElementBlock("div", _hoisted_30, [
                              _createVNode(_unref(Field), {
                                name: "expertise_text",
                                type: "text",
                                placeholder: "Please Specify",
                                class: "form-control bg-others"
                              })
                            ]))
                          : _createCommentVNode("", true),
                        _createVNode(_unref(ErrorMessage), {
                          name: "expertise",
                          class: "error-feedback"
                        })
                      ]))
                    : _createCommentVNode("", true),
                  (
                    !_unref(profileFields).find((el) => el.name === 'profLicense')
                      ?.disabled
                  )
                    ? (_openBlock(), _createElementBlock("div", _hoisted_31, [
                        _cache[15] || (_cache[15] = _createElementVNode("label", { for: "profLicense" }, [
                          _createTextVNode("Professional Licence"),
                          _createElementVNode("span", { class: "super font-color-red" }, "*")
                        ], -1)),
                        _createVNode(_unref(Field), {
                          modelValue: values.profLicense,
                          "onUpdate:modelValue": ($event: any) => ((values.profLicense) = $event),
                          name: "profLicense",
                          type: "text",
                          class: "dropdown"
                        }, {
                          default: _withCtx(({ field }) => [
                            _createVNode(_unref(Multiselect), _mergeProps(field, {
                              modelValue: values.profLicense,
                              "onUpdate:modelValue": ($event: any) => ((values.profLicense) = $event),
                              options: profLicenseOptionsAdded,
                              mode: "tags",
                              onChange: validateOpt,
                              multipleLabel: _unref(displayLabels),
                              searchable: true,
                              hideSelected: false,
                              closeOnSelect: false,
                              closeOnDeselect: false
                            }), {
                              option: _withCtx(({ option }) => [
                                _createElementVNode("input", {
                                  type: "checkbox",
                                  class: "input-pointer",
                                  checked: values.profLicense.includes(option.value)
                                }, null, 8, _hoisted_32),
                                _createTextVNode("   " + _toDisplayString(option.label), 1)
                              ]),
                              _: 2
                            }, 1040, ["modelValue", "onUpdate:modelValue", "multipleLabel"])
                          ]),
                          _: 2
                        }, 1032, ["modelValue", "onUpdate:modelValue"]),
                        (values.profLicense.includes('Others'))
                          ? (_openBlock(), _createElementBlock("div", _hoisted_33, [
                              _createVNode(_unref(Field), {
                                name: "profLicense_text",
                                type: "text",
                                placeholder: "Please Specify",
                                class: "form-control bg-others"
                              })
                            ]))
                          : _createCommentVNode("", true),
                        _createVNode(_unref(ErrorMessage), {
                          name: "profLicense",
                          class: "error-feedback"
                        })
                      ]))
                    : _createCommentVNode("", true),
                  (
                    !_unref(profileFields).find((el) => el.name === 'typeProject')
                      ?.disabled
                  )
                    ? (_openBlock(), _createElementBlock("div", _hoisted_34, [
                        _cache[16] || (_cache[16] = _createElementVNode("label", { for: "typeProject" }, [
                          _createTextVNode("Types of Projects you have Executed or Executing"),
                          _createElementVNode("span", { class: "super font-color-red" }, "*")
                        ], -1)),
                        _createVNode(_unref(Field), {
                          modelValue: values.typeProject,
                          "onUpdate:modelValue": ($event: any) => ((values.typeProject) = $event),
                          name: "typeProject",
                          type: "text",
                          class: "dropdown"
                        }, {
                          default: _withCtx(({ field }) => [
                            _createVNode(_unref(Multiselect), _mergeProps(field, {
                              modelValue: values.typeProject,
                              "onUpdate:modelValue": ($event: any) => ((values.typeProject) = $event),
                              options: typeProjectOptionsAdded,
                              mode: "tags",
                              multipleLabel: _unref(displayLabels),
                              searchable: true,
                              hideSelected: false,
                              closeOnSelect: false,
                              closeOnDeselect: false
                            }), {
                              option: _withCtx(({ option }) => [
                                _createElementVNode("input", {
                                  type: "checkbox",
                                  class: "input-pointer",
                                  checked: values.typeProject.includes(option.value)
                                }, null, 8, _hoisted_35),
                                _createTextVNode("   " + _toDisplayString(option.label), 1)
                              ]),
                              _: 2
                            }, 1040, ["modelValue", "onUpdate:modelValue", "multipleLabel"])
                          ]),
                          _: 2
                        }, 1032, ["modelValue", "onUpdate:modelValue"]),
                        (values.typeProject.includes('Others'))
                          ? (_openBlock(), _createElementBlock("div", _hoisted_36, [
                              _createVNode(_unref(Field), {
                                name: "typeProject_text",
                                type: "text",
                                placeholder: "Please Specify",
                                class: "form-control bg-others"
                              })
                            ]))
                          : _createCommentVNode("", true),
                        _createVNode(_unref(ErrorMessage), {
                          name: "typeProject",
                          class: "error-feedback"
                        })
                      ]))
                    : _createCommentVNode("", true),
                  (
                    !_unref(profileFields).find((el) => el.name === 'physicalDis')
                      ?.disabled
                  )
                    ? (_openBlock(), _createElementBlock("div", _hoisted_37, [
                        _createElementVNode("label", _hoisted_38, [
                          _cache[17] || (_cache[17] = _createTextVNode("Physical Disabilities (if any) ")),
                          _cache[18] || (_cache[18] = _createElementVNode("span", { class: "super font-color-red" }, "*", -1)),
                          _createVNode(_component_font_awesome_icon, {
                            icon: "fa-solid fa-lock",
                            class: "ml-1",
                            title: "This information is private."
                          })
                        ]),
                        _createVNode(_unref(Field), {
                          modelValue: values.physicalDis,
                          "onUpdate:modelValue": ($event: any) => ((values.physicalDis) = $event),
                          name: "physicalDis",
                          type: "text",
                          class: "form-control"
                        }, {
                          default: _withCtx(({ field }) => [
                            _createVNode(_unref(Multiselect), _mergeProps(field, {
                              modelValue: values.physicalDis,
                              "onUpdate:modelValue": ($event: any) => ((values.physicalDis) = $event),
                              options: _unref(physicalDisOptions),
                              mode: "tags",
                              onChange: validateOpt,
                              multipleLabel: _unref(displayLabels),
                              searchable: true,
                              hideSelected: false,
                              closeOnSelect: false,
                              closeOnDeselect: false
                            }), {
                              option: _withCtx(({ option }) => [
                                _createElementVNode("input", {
                                  type: "checkbox",
                                  class: "input-pointer",
                                  checked: values.physicalDis.includes(option.value)
                                }, null, 8, _hoisted_39),
                                _createTextVNode("   " + _toDisplayString(option.label), 1)
                              ]),
                              _: 2
                            }, 1040, ["modelValue", "onUpdate:modelValue", "options", "multipleLabel"])
                          ]),
                          _: 2
                        }, 1032, ["modelValue", "onUpdate:modelValue"]),
                        (values.physicalDis.includes('Others'))
                          ? (_openBlock(), _createElementBlock("div", _hoisted_40, [
                              _createVNode(_unref(Field), {
                                name: "physicalDis_text",
                                type: "text",
                                placeholder: "Please Specify",
                                class: "form-control bg-others"
                              })
                            ]))
                          : _createCommentVNode("", true),
                        _createVNode(_unref(ErrorMessage), {
                          name: "physicalDis",
                          class: "error-feedback"
                        })
                      ]))
                    : _createCommentVNode("", true)
                ]),
                _createVNode(_component_va_divider, { vertical: "" }),
                _createElementVNode("div", null, [
                  _cache[33] || (_cache[33] = _createElementVNode("h4", { class: "text-align-center bold-text" }, "Company Information", -1)),
                  _cache[34] || (_cache[34] = _createElementVNode("br", null, null, -1)),
                  (
                    !_unref(profileFields).find((el) => el.name === 'nameOrg')?.disabled
                  )
                    ? (_openBlock(), _createElementBlock("div", _hoisted_41, [
                        _cache[21] || (_cache[21] = _createElementVNode("label", { for: "nameOrg" }, [
                          _createTextVNode("Name of Organization"),
                          _createElementVNode("span", { class: "super font-color-red" }, "*")
                        ], -1)),
                        _createVNode(_unref(Field), {
                          name: "nameOrg",
                          type: "text",
                          class: "form-control"
                        }),
                        _createVNode(_unref(ErrorMessage), {
                          name: "nameOrg",
                          class: "error-feedback"
                        })
                      ]))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", _hoisted_42, [
                    _createElementVNode("label", _hoisted_43, [
                      _createTextVNode("Location of " + _toDisplayString(values.nameOrg) + " (i.e. Local office)", 1),
                      _cache[22] || (_cache[22] = _createElementVNode("span", { class: "super font-color-red" }, "* ", -1))
                    ]),
                    _createElementVNode("div", _hoisted_44, [
                      (
                        !_unref(profileFields).find((el) => el.name === 'state')
                          ?.disabled
                      )
                        ? (_openBlock(), _createElementBlock("div", _hoisted_45, [
                            _createVNode(_unref(Field), {
                              modelValue: values.state,
                              "onUpdate:modelValue": ($event: any) => ((values.state) = $event),
                              name: "state",
                              type: "text",
                              class: "dropdown"
                            }, {
                              default: _withCtx(({ field }) => [
                                _createVNode(_unref(Multiselect), _mergeProps(field, {
                                  modelValue: values.state,
                                  "onUpdate:modelValue": ($event: any) => ((values.state) = $event),
                                  options: _unref(stateOptions),
                                  mode: "single",
                                  placeholder: "Select State",
                                  searchable: true,
                                  onChange: ($event: any) => (
                            getCityOptions(values.state), (values.city = '')
                          )
                                }), null, 16, ["modelValue", "onUpdate:modelValue", "options", "onChange"])
                              ]),
                              _: 2
                            }, 1032, ["modelValue", "onUpdate:modelValue"]),
                            _createVNode(_unref(ErrorMessage), {
                              name: "state",
                              class: "error-feedback"
                            })
                          ]))
                        : _createCommentVNode("", true),
                      (
                        !_unref(profileFields).find((el) => el.name === 'city')
                          ?.disabled
                      )
                        ? (_openBlock(), _createElementBlock("div", _hoisted_46, [
                            _createVNode(_unref(Field), {
                              modelValue: values.city,
                              "onUpdate:modelValue": ($event: any) => ((values.city) = $event),
                              name: "city",
                              type: "text",
                              class: "dropdown"
                            }, {
                              default: _withCtx(({ field }) => [
                                _createVNode(_unref(Multiselect), _mergeProps(field, {
                                  modelValue: values.city,
                                  "onUpdate:modelValue": ($event: any) => ((values.city) = $event),
                                  options: cities.value,
                                  mode: "single",
                                  searchable: true
                                }), null, 16, ["modelValue", "onUpdate:modelValue", "options"])
                              ]),
                              _: 2
                            }, 1032, ["modelValue", "onUpdate:modelValue"]),
                            _createVNode(_unref(ErrorMessage), {
                              name: "city",
                              class: "error-feedback"
                            })
                          ]))
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  (
                    !_unref(profileFields).find((el) => el.name === 'type')?.disabled
                  )
                    ? (_openBlock(), _createElementBlock("div", _hoisted_47, [
                        _cache[23] || (_cache[23] = _createElementVNode("label", { for: "type" }, [
                          _createTextVNode("Type of Firm"),
                          _createElementVNode("span", { class: "super font-color-red" }, "*")
                        ], -1)),
                        _createVNode(_unref(Field), {
                          modelValue: values.type,
                          "onUpdate:modelValue": ($event: any) => ((values.type) = $event),
                          name: "type",
                          type: "text",
                          class: "dropdown"
                        }, {
                          default: _withCtx(({ field }) => [
                            _createVNode(_unref(Multiselect), _mergeProps(field, {
                              modelValue: values.type,
                              "onUpdate:modelValue": ($event: any) => ((values.type) = $event),
                              options: _unref(typeOfFirmOptions),
                              mode: "single",
                              searchable: true
                            }), null, 16, ["modelValue", "onUpdate:modelValue", "options"])
                          ]),
                          _: 2
                        }, 1032, ["modelValue", "onUpdate:modelValue"]),
                        (values.type === 'Others')
                          ? (_openBlock(), _createElementBlock("div", _hoisted_48, [
                              _createVNode(_unref(Field), {
                                name: "type_text",
                                type: "text",
                                placeholder: "Please Specify",
                                class: "form-control bg-others"
                              })
                            ]))
                          : _createCommentVNode("", true),
                        _createVNode(_unref(ErrorMessage), {
                          name: "type",
                          class: "error-feedback"
                        })
                      ]))
                    : _createCommentVNode("", true),
                  (
                    !_unref(profileFields).find((el) => el.name === 'size')?.disabled
                  )
                    ? (_openBlock(), _createElementBlock("div", _hoisted_49, [
                        _createElementVNode("label", _hoisted_50, [
                          _createTextVNode("Size of " + _toDisplayString(values.nameOrg), 1),
                          _cache[24] || (_cache[24] = _createElementVNode("span", { class: "super font-color-red" }, "*", -1))
                        ]),
                        _createVNode(_unref(Field), {
                          modelValue: values.size,
                          "onUpdate:modelValue": ($event: any) => ((values.size) = $event),
                          name: "size",
                          type: "text",
                          class: "dropdown"
                        }, {
                          default: _withCtx(({ field }) => [
                            _createVNode(_unref(Multiselect), _mergeProps(field, {
                              modelValue: values.size,
                              "onUpdate:modelValue": ($event: any) => ((values.size) = $event),
                              options: _unref(companySizeOptions),
                              mode: "single",
                              searchable: true
                            }), null, 16, ["modelValue", "onUpdate:modelValue", "options"])
                          ]),
                          _: 2
                        }, 1032, ["modelValue", "onUpdate:modelValue"]),
                        _createVNode(_unref(ErrorMessage), {
                          name: "size",
                          class: "error-feedback"
                        })
                      ]))
                    : _createCommentVNode("", true),
                  (
                    !_unref(profileFields).find((el) => el.name === 'genderDiv')
                      ?.disabled
                  )
                    ? (_openBlock(), _createElementBlock("div", _hoisted_51, [
                        _createElementVNode("label", _hoisted_52, [
                          _createTextVNode("Gender Diversity of " + _toDisplayString(values.nameOrg) + " ", 1),
                          _cache[25] || (_cache[25] = _createElementVNode("span", { class: "super font-color-red" }, "*", -1))
                        ]),
                        _createVNode(_unref(Field), {
                          modelValue: values.genderDiv,
                          "onUpdate:modelValue": ($event: any) => ((values.genderDiv) = $event),
                          name: "genderDiv",
                          type: "text",
                          class: "dropdown"
                        }, {
                          default: _withCtx(({ field }) => [
                            _createVNode(_unref(Multiselect), _mergeProps(field, {
                              modelValue: values.genderDiv,
                              "onUpdate:modelValue": ($event: any) => ((values.genderDiv) = $event),
                              options: _unref(diversityOptions),
                              mode: "single",
                              searchable: true
                            }), null, 16, ["modelValue", "onUpdate:modelValue", "options"])
                          ]),
                          _: 2
                        }, 1032, ["modelValue", "onUpdate:modelValue"]),
                        _createVNode(_unref(ErrorMessage), {
                          name: "genderDiv",
                          class: "error-feedback"
                        })
                      ]))
                    : _createCommentVNode("", true),
                  (
                    !_unref(profileFields).find((el) => el.name === 'ethnicDiv')
                      ?.disabled
                  )
                    ? (_openBlock(), _createElementBlock("div", _hoisted_53, [
                        _createElementVNode("label", _hoisted_54, [
                          _createTextVNode("Racial Diversity of " + _toDisplayString(values.nameOrg) + " ", 1),
                          _cache[26] || (_cache[26] = _createElementVNode("span", { class: "super font-color-red" }, "*", -1))
                        ]),
                        _createVNode(_unref(Field), {
                          modelValue: values.ethnicDiv,
                          "onUpdate:modelValue": ($event: any) => ((values.ethnicDiv) = $event),
                          name: "ethnicDiv",
                          type: "text",
                          class: "dropdown"
                        }, {
                          default: _withCtx(({ field }) => [
                            _createVNode(_unref(Multiselect), _mergeProps(field, {
                              modelValue: values.ethnicDiv,
                              "onUpdate:modelValue": ($event: any) => ((values.ethnicDiv) = $event),
                              options: _unref(diversityOptions),
                              mode: "single",
                              searchable: true
                            }), null, 16, ["modelValue", "onUpdate:modelValue", "options"])
                          ]),
                          _: 2
                        }, 1032, ["modelValue", "onUpdate:modelValue"]),
                        _createVNode(_unref(ErrorMessage), {
                          name: "ethnicDiv",
                          class: "error-feedback"
                        })
                      ]))
                    : _createCommentVNode("", true),
                  (
                    !_unref(profileFields).find((el) => el.name === 'website')?.disabled
                  )
                    ? (_openBlock(), _createElementBlock("div", _hoisted_55, [
                        _createElementVNode("label", _hoisted_56, [
                          _createTextVNode("Website of " + _toDisplayString(values.nameOrg), 1),
                          _cache[27] || (_cache[27] = _createElementVNode("span", { class: "super font-color-red" }, "*", -1))
                        ]),
                        _createVNode(_unref(Field), {
                          name: "website",
                          type: "text",
                          class: "form-control"
                        }),
                        _createVNode(_unref(ErrorMessage), {
                          name: "website",
                          class: "error-feedback"
                        })
                      ]))
                    : _createCommentVNode("", true),
                  (
                    !_unref(profileFields).find((el) => el.name === 'typeTechnology')
                      ?.disabled
                  )
                    ? (_openBlock(), _createElementBlock("div", _hoisted_57, [
                        _cache[28] || (_cache[28] = _createElementVNode("label", { for: "typeTechnology" }, [
                          _createTextVNode("Types of Technologies Adopted by Your Organization "),
                          _createElementVNode("span", { class: "super font-color-red" }, "*")
                        ], -1)),
                        _createVNode(_unref(Field), {
                          modelValue: values.typeTechnology,
                          "onUpdate:modelValue": ($event: any) => ((values.typeTechnology) = $event),
                          name: "typeTechnology",
                          type: "text",
                          class: "dropdown"
                        }, {
                          default: _withCtx(({ field }) => [
                            _createVNode(_unref(Multiselect), _mergeProps(field, {
                              modelValue: values.typeTechnology,
                              "onUpdate:modelValue": ($event: any) => ((values.typeTechnology) = $event),
                              options: typeTechnologyOptionsAdded,
                              mode: "tags",
                              multipleLabel: _unref(displayLabels),
                              searchable: true,
                              hideSelected: false,
                              closeOnSelect: false,
                              closeOnDeselect: false
                            }), {
                              option: _withCtx(({ option }) => [
                                _createElementVNode("input", {
                                  type: "checkbox",
                                  class: "input-pointer",
                                  checked: 
                            values.typeTechnology.includes(option.value)
                          
                                }, null, 8, _hoisted_58),
                                _createTextVNode("   " + _toDisplayString(option.label), 1)
                              ]),
                              _: 2
                            }, 1040, ["modelValue", "onUpdate:modelValue", "multipleLabel"])
                          ]),
                          _: 2
                        }, 1032, ["modelValue", "onUpdate:modelValue"]),
                        (values.typeTechnology.includes('Others'))
                          ? (_openBlock(), _createElementBlock("div", _hoisted_59, [
                              _createVNode(_unref(Field), {
                                name: "typeTechnology_text",
                                type: "text",
                                placeholder: "Please Specify",
                                class: "form-control bg-others"
                              })
                            ]))
                          : _createCommentVNode("", true)
                      ]))
                    : _createCommentVNode("", true),
                  (
                    !_unref(profileFields).find((el) => el.name === 'organPolicy')
                      ?.disabled
                  )
                    ? (_openBlock(), _createElementBlock("div", _hoisted_60, [
                        _createElementVNode("label", _hoisted_61, [
                          _cache[30] || (_cache[30] = _createTextVNode("What Your Company do to Support Student Development ")),
                          _cache[31] || (_cache[31] = _createElementVNode("span", { class: "super font-color-red" }, "*", -1)),
                          _cache[32] || (_cache[32] = _createTextVNode("   ")),
                          _createElementVNode("span", _hoisted_62, [
                            _createVNode(_component_font_awesome_icon, {
                              icon: "fa-solid fa-circle-question",
                              onClick: showTooltip
                            }),
                            _withDirectives(_createElementVNode("span", _hoisted_63, [
                              _createElementVNode("button", _hoisted_64, [
                                _createVNode(_component_font_awesome_icon, {
                                  icon: "fa-solid fa-xmark",
                                  class: "close-btn-icon",
                                  onClick: hideTooltip
                                })
                              ]),
                              _cache[29] || (_cache[29] = _createElementVNode("span", { style: {"margin-bottom":"0"} }, " Please indicate (if any) policies, practices, support or resources your organization have toward supporting workforce development collaboration with academia. It can be as simple as for example, in the case of site visit, do you offer PPE to students or they are to come with it? ", -1))
                            ], 512), [
                              [_vShow, elementVisible.value]
                            ])
                          ])
                        ]),
                        _createVNode(_unref(Field), {
                          name: "organPolicy",
                          type: "text",
                          class: "form-control"
                        }),
                        _createVNode(_unref(ErrorMessage), {
                          name: "organPolicy",
                          class: "error-feedback"
                        })
                      ]))
                    : _createCommentVNode("", true)
                ])
              ]),
              _cache[36] || (_cache[36] = _createElementVNode("br", null, null, -1)),
              _createElementVNode("div", _hoisted_65, [
                _createElementVNode("button", {
                  class: "btn btn-primary btn-block",
                  disabled: _unref(loading)
                }, [
                  _withDirectives(_createElementVNode("span", _hoisted_67, null, 512), [
                    [_vShow, _unref(loading)]
                  ]),
                  _cache[35] || (_cache[35] = _createElementVNode("span", null, "Save Profile", -1))
                ], 8, _hoisted_66)
              ])
            ]),
            _: 1
          }, 8, ["validation-schema"])
        ]),
        (_unref(message))
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(_unref(successful) ? 'alert-success' : 'alert-danger')
            }, _toDisplayString(_unref(message)), 3))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}
}

})