import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "grid-container" }
const _hoisted_2 = { class: "min-height" }

import { ref } from "vue";
import AdminService from "@/services/admin.service.js";
import AppSidebar from "@/components/user/AppSidebar.vue";
import BarChart from "@/components/admin/BarChart.vue";
import { groupBy } from "@/utils";


export default /*@__PURE__*/_defineComponent({
  __name: 'SiteStatisticsView',
  setup(__props) {

let loading = ref(false);
let successful = ref(false);
let message = ref("");
let items = ref([]);
let userGender = [],
  userEthnicity = [],
  userType = [];
const formatData = (data, outputObj) => {
  for (const key in data) {
    let label = "";
    if (key == "null") label = "Not Set";
    else label = key;
    outputObj.push({ name: label, amount: data[key].length });
  }
  console.log(outputObj);
};
const formatRoleData = (data, outputObj) => {
  for (const key in data) {
    let label = "";
    if (key == "1") label = "Instructor";
    else if (key == "2") label = "Practitioner";
    outputObj.push({ name: label, amount: data[key].length });
  }
  console.log(outputObj);
};

const getUsers = () => {
  loading.value = true;
  AdminService.getUsers().then(
    (response) => {
      items.value = response.data;
      console.log(response.data);
      formatRoleData(groupBy(items.value, "roleId"), userType);
      formatData(groupBy(items.value, "gender"), userGender);
      formatData(groupBy(items.value, "ethnicity"), userEthnicity);
      loading.value = false;
      successful.value = true;
    },
    (error) => {
      loading.value = false;
      message.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};

getUsers();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(AppSidebar),
    _createElementVNode("div", _hoisted_2, [
      (_unref(successful))
        ? (_openBlock(), _createBlock(BarChart, {
            key: 0,
            class: "mt-3",
            title: "User Type",
            xKey: "name",
            yKey: "amount",
            data: _unref(userType)
          }, null, 8, ["data"]))
        : _createCommentVNode("", true),
      (_unref(successful))
        ? (_openBlock(), _createBlock(BarChart, {
            key: 1,
            class: "mt-3",
            title: "User Gender Distribution",
            xKey: "name",
            yKey: "amount",
            data: _unref(userGender)
          }, null, 8, ["data"]))
        : _createCommentVNode("", true),
      (_unref(successful))
        ? (_openBlock(), _createBlock(BarChart, {
            key: 2,
            class: "mt-3",
            title: "User Ethnicity Distribution",
            xKey: "name",
            yKey: "amount",
            data: _unref(userEthnicity)
          }, null, 8, ["data"]))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})