import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, isRef as _isRef, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@/assets/images/info.png'


const _hoisted_1 = { class: "grid-container" }
const _hoisted_2 = { class: "content min-height m-3" }
const _hoisted_3 = { class: "back-grid" }
const _hoisted_4 = { class: "flex flex-column lg:flex-row align-items-center" }
const _hoisted_5 = { class: "m-3 ml-0 mt-5" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { class: "flex-grow-1" }
const _hoisted_9 = { key: 1 }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { key: 1 }
const _hoisted_12 = { class: "table" }
const _hoisted_13 = { key: 0 }
const _hoisted_14 = { class: "flex flex-row align-content-center justify-content-between gap-2" }

import { ref } from "vue";
import InstructorSidebar from "@/components/instructor/InstructorSidebar.vue";
import BackButton from "@/components/user/BackButton.vue";
import ClassForm from "@/components/instructor/ClassForm.vue";
import Dialog from "primevue/dialog";
import { useCourseStore } from "@/stores/CourseStore";
import { useRouter } from "vue-router";
import InstructorService from "@/services/instructor.service.js";
import {
  showToast,
  groupBy,
  convertCourseToCourseRequest,
  showErrorToast,
} from "@/utils";
import { Course } from "@/types";


export default /*@__PURE__*/_defineComponent({
  __name: 'MyClassesView',
  setup(__props) {

const CourseStore = useCourseStore();
const router = useRouter();
let myClasses = ref([] as Course[]);
let classesGrouped = ref([]);
let message = ref("");
let loading = ref(false);
let isVisible = ref(false);
let isViewClassVisible = ref(false);
let isEditClassVisible = ref(false);
let course = {} as Course;

const saveCourseSuccess = (values) => {
  showToast("Class has been saved.");
  getCourse();
  loading.value = false;
  isVisible.value = false;
};

const updateCourseSuccess = (values) => {
  showToast("Class has been updated.");
  getCourse();
  loading.value = false;
  isEditClassVisible.value = false;
  isViewClassVisible.value = false;
};

const getCourse = (pageNumber = 1) => {
  InstructorService.getCourse(pageNumber).then(
    (response) => {
      myClasses.value = response.data;
      myClasses.value.forEach((el) => {
        el.semYear = el.semester + "|" + el.year;
      });
      classesGrouped.value = groupBy(myClasses.value, "semYear");
    },
    (error) => {
      message.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};
getCourse();

const getCourseRequest = (course: Course) => {
  console.log(course.courseCode);
  router.push({
    name: "class-course-request",
    params: { courseCode: course.courseCode },
  });
};

const createCourseRequest = (course: Course | Course) => {
  CourseStore.removeCourse();
  let courseRequest = convertCourseToCourseRequest(course);
  CourseStore.addCourse(courseRequest);
  router.push({ name: "create-class-course-request" });
};

return (_ctx: any,_cache: any) => {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_va_collapse = _resolveComponent("va-collapse")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(InstructorSidebar),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(BackButton),
          _createElementVNode("div", _hoisted_4, [
            _cache[7] || (_cache[7] = _createElementVNode("h2", { style: {"text-align":"center","font-weight":"bold"} }, "My Classes", -1)),
            _createElementVNode("div", {
              class: "py-1 px-2 card cursor-pointer inline-block flex-shrink-1 flex-grow-0 m-0 mt-1 lg:mt-0 lg:p-3",
              style: {"width":"max-content"},
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_isRef(isVisible) //@ts-ignore
 ? isVisible.value = true : isVisible = true))
            }, [
              _cache[6] || (_cache[6] = _createElementVNode("b", null, " Add Class ", -1)),
              _createVNode(_component_font_awesome_icon, {
                class: "ml-2",
                title: "Add Class",
                icon: "fa-solid fa-plus"
              })
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          (_unref(myClasses).length > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(classesGrouped), (classes, semYear) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "m-0 mt-2 cursor-pointer",
                    key: semYear
                  }, [
                    _createVNode(_component_va_collapse, {
                      class: "w-96 mt-2 pb-2",
                      color: "#fff",
                      header: semYear.split('|')[0] + ' ' + semYear.split('|')[1]
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(classes, (myClass) => {
                          return (_openBlock(), _createElementBlock("div", {
                            key: myClass.courseCode
                          }, [
                            _createElementVNode("div", {
                              class: "m-1 p-3 pl-4 card flex flex-row",
                              onClick: ($event: any) => ((_isRef(isViewClassVisible) //@ts-ignore
 ? isViewClassVisible.value = true : isViewClassVisible = true), (_isRef(course) //@ts-ignore
 ? course.value = myClass : course = myClass))
                            }, [
                              _createElementVNode("div", _hoisted_8, [
                                _createElementVNode("b", null, _toDisplayString(myClass.courseCode), 1),
                                _cache[8] || (_cache[8] = _createTextVNode()),
                                _cache[9] || (_cache[9] = _createElementVNode("br", null, null, -1)),
                                _createTextVNode(" " + _toDisplayString(myClass.courseTitle), 1)
                              ]),
                              _cache[10] || (_cache[10] = _createElementVNode("div", { class: "flex-grow-0 flex-shrink-1 mr-3" }, [
                                _createElementVNode("button", {
                                  class: "btn btn-primary bg-green-400 border-none",
                                  title: "View Details"
                                }, [
                                  _createElementVNode("img", {
                                    src: _imports_0,
                                    class: "sidebar-image"
                                  })
                                ])
                              ], -1))
                            ], 8, _hoisted_7)
                          ]))
                        }), 128))
                      ]),
                      _: 2
                    }, 1032, ["header"])
                  ]))
                }), 128))
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_9, "You don't have any saved classes."))
        ])
      ])
    ]),
    _createVNode(_unref(Dialog), {
      visible: _unref(isVisible),
      "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => (_isRef(isVisible) ? (isVisible).value = $event : isVisible = $event)),
      modal: "",
      header: "Add Class",
      style: { width: '50vw' },
      breakpoints: { '768px': '90vw' }
    }, {
      default: _withCtx(() => [
        _createVNode(ClassForm, {
          successAction: saveCourseSuccess,
          action: "create",
          course: _unref(course)
        }, null, 8, ["course"])
      ]),
      _: 1
    }, 8, ["visible"]),
    _createVNode(_unref(Dialog), {
      visible: _unref(isViewClassVisible),
      "onUpdate:visible": _cache[5] || (_cache[5] = ($event: any) => (_isRef(isViewClassVisible) ? (isViewClassVisible).value = $event : isViewClassVisible = $event)),
      modal: "",
      header: "View Class Details",
      style: { width: '50vw' },
      breakpoints: { '768px': '90vw' }
    }, {
      default: _withCtx(() => [
        (_unref(isEditClassVisible))
          ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
              _createVNode(ClassForm, {
                "success-action": updateCourseSuccess,
                action: "update",
                course: _unref(course)
              }, null, 8, ["course"])
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_11, [
              _createElementVNode("table", _hoisted_12, [
                _createElementVNode("tr", null, [
                  _cache[11] || (_cache[11] = _createElementVNode("td", null, "Semester & Year", -1)),
                  _createElementVNode("td", null, _toDisplayString(_unref(course).semester + " " + _unref(course).year), 1)
                ]),
                _createElementVNode("tr", null, [
                  _cache[12] || (_cache[12] = _createElementVNode("td", null, "Course Code", -1)),
                  _createElementVNode("td", null, _toDisplayString(_unref(course).courseCode), 1)
                ]),
                _createElementVNode("tr", null, [
                  _cache[13] || (_cache[13] = _createElementVNode("td", null, "Course Title", -1)),
                  _createElementVNode("td", null, _toDisplayString(_unref(course).courseTitle), 1)
                ]),
                (_unref(course).additionalInformation?.length > 0)
                  ? (_openBlock(), _createElementBlock("tr", _hoisted_13, [
                      _cache[14] || (_cache[14] = _createElementVNode("td", null, "Link to Additional Course Information", -1)),
                      _createElementVNode("td", null, _toDisplayString(_unref(course).additionalInformation), 1)
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("tr", null, [
                  _cache[15] || (_cache[15] = _createElementVNode("td", null, "Location of Classroom", -1)),
                  _createElementVNode("td", null, _toDisplayString(_unref(course).location), 1)
                ]),
                _createElementVNode("tr", null, [
                  _cache[16] || (_cache[16] = _createElementVNode("td", null, "Class Size", -1)),
                  _createElementVNode("td", null, _toDisplayString(_unref(course).classSize), 1)
                ]),
                _createElementVNode("tr", null, [
                  _cache[17] || (_cache[17] = _createElementVNode("td", null, "Students' Academic Level", -1)),
                  _createElementVNode("td", null, _toDisplayString(JSON.parse(_unref(course).academicLevel).join(", ")), 1)
                ]),
                _createElementVNode("tr", null, [
                  _cache[18] || (_cache[18] = _createElementVNode("td", null, "Students' Department or Program of Study", -1)),
                  _createElementVNode("td", null, _toDisplayString(JSON.parse(_unref(course).studentDept).join(", ")), 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_14, [
                _createElementVNode("button", {
                  class: "btn btn-primary bg-cyan-600 border-none",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_isRef(isEditClassVisible) //@ts-ignore
 ? isEditClassVisible.value = true : isEditClassVisible = true))
                }, [
                  _createVNode(_component_font_awesome_icon, {
                    icon: "fa-solid fa-pencil",
                    style: {"display":"inline-block","align-self":"center"}
                  }),
                  _cache[19] || (_cache[19] = _createTextVNode(" Edit Class "))
                ]),
                _createElementVNode("button", {
                  class: "btn btn-secondary",
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (getCourseRequest(_unref(course))))
                }, " View Course-Support Request "),
                _createElementVNode("button", {
                  class: "btn btn-primary",
                  onClick: _cache[4] || (_cache[4] = ($event: any) => (createCourseRequest(_unref(course))))
                }, " Request Course-Support ")
              ])
            ]))
      ]),
      _: 1
    }, 8, ["visible"])
  ], 64))
}
}

})