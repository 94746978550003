import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createVNode as _createVNode, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "form-group" }
const _hoisted_2 = { class: "form-group" }
const _hoisted_3 = { class: "form-group" }
const _hoisted_4 = { class: "form-group text-align-center" }
const _hoisted_5 = ["disabled"]
const _hoisted_6 = { class: "spinner-border spinner-border-sm" }

import { ref } from "vue";
import * as yup from "yup";
import { Form, Field, ErrorMessage } from "vee-validate";
import UserService from "@/services/user.service.js";
import { showErrorToast, showToast } from "@/utils";
import Rating from "primevue/rating";

// eslint-disable-next-line no-undef

export default /*@__PURE__*/_defineComponent({
  __name: 'FeedbackForm',
  props: {
    currCourseId: {}
  },
  emits: ["fetchCourse"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
// eslint-disable-next-line no-undef
const emits = __emit;

let loading = ref(false);
let message = ref("");

const courseFeedbackSchema = yup.object().shape({
  message: yup.string().required("Comments about the session is required!"),
  rating: yup.string().required("Rating is required!"),
  concern: yup.string().optional().nullable(),
});

const saveCourseFeedback = (data) => {
  loading.value = true;
  data.courseRequestId = props.currCourseId;
  UserService.saveCourseFeedback(data).then(
    (response) => {
      if (response.status == "200") emits("fetchCourse");
      loading.value = false;
    },
    (error) => {
      loading.value = false;
      message.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      showErrorToast(message.value);
    }
  );
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Form), {
    onSubmit: saveCourseFeedback,
    "validation-schema": _unref(courseFeedbackSchema)
  }, {
    default: _withCtx(({ values, meta }) => [
      _createElementVNode("div", _hoisted_1, [
        _cache[0] || (_cache[0] = _createElementVNode("label", { for: "message" }, [
          _createTextVNode("Comments about the session "),
          _createElementVNode("span", { class: "super font-color-red" }, "*")
        ], -1)),
        _createVNode(_unref(Field), {
          name: "message",
          type: "text",
          class: "form-control"
        }),
        _createVNode(_unref(ErrorMessage), {
          name: "message",
          class: "error-feedback"
        })
      ]),
      _createElementVNode("div", _hoisted_2, [
        _cache[1] || (_cache[1] = _createElementVNode("label", { for: "rating" }, [
          _createTextVNode("Rating of the Practitioner"),
          _createElementVNode("span", { class: "super font-color-red" }, "*")
        ], -1)),
        _createVNode(_unref(Field), {
          name: "rating",
          type: "text",
          class: "form-control"
        }, {
          default: _withCtx(() => [
            _createVNode(_unref(Rating), {
              modelValue: values.rating,
              "onUpdate:modelValue": ($event: any) => ((values.rating) = $event),
              cancel: false
            }, null, 8, ["modelValue", "onUpdate:modelValue"])
          ]),
          _: 2
        }, 1024),
        _createVNode(_unref(ErrorMessage), {
          name: "rating",
          class: "error-feedback"
        })
      ]),
      _createElementVNode("div", _hoisted_3, [
        _cache[2] || (_cache[2] = _createElementVNode("label", { for: "concern" }, "Any feedback, comments, or concern to Admin.", -1)),
        _createVNode(_unref(Field), {
          name: "concern",
          type: "text",
          class: "form-control"
        }),
        _createVNode(_unref(ErrorMessage), {
          name: "concern",
          class: "error-feedback"
        })
      ]),
      _cache[4] || (_cache[4] = _createElementVNode("br", null, null, -1)),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("button", {
          class: "btn btn-primary btn-block",
          disabled: _unref(loading) || !meta.valid
        }, [
          _withDirectives(_createElementVNode("span", _hoisted_6, null, 512), [
            [_vShow, _unref(loading)]
          ]),
          _cache[3] || (_cache[3] = _createElementVNode("span", null, "Save Feedback", -1))
        ], 8, _hoisted_5)
      ])
    ]),
    _: 1
  }, 8, ["validation-schema"]))
}
}

})