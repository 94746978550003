import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withModifiers as _withModifiers } from "vue"
import _imports_0 from '@/assets/images/logo_cropped.png'


const _hoisted_1 = {
  key: 0,
  class: "fixed left-0 right-0 top-0 p-1 header"
}
const _hoisted_2 = { class: "flex flex-direction-row align-center list-style-type-none" }
const _hoisted_3 = {
  key: 0,
  class: "lg:ml-3 mt-auto mb-auto"
}
const _hoisted_4 = {
  key: 1,
  class: "lg:ml-3 mt-auto mb-auto"
}
const _hoisted_5 = {
  key: 2,
  class: "lg:ml-3 my-auto"
}
const _hoisted_6 = {
  key: 3,
  class: "ml-1"
}
const _hoisted_7 = { key: 4 }
const _hoisted_8 = { class: "list-style-type-none ml-auto" }
const _hoisted_9 = {
  key: 1,
  class: "fixed left-0 right-0 top-0 p-1 header"
}
const _hoisted_10 = { class: "flex flex-direction-row align-center list-style-type-none" }
const _hoisted_11 = { class: "lg:ml-3 mt-auto mb-auto" }

import { useConfigStore } from "@/stores/ConfigStore";
import { useAuthStore } from "@/stores/AuthStore";
import { useRouter } from "vue-router";
import HeaderSearchBar from "@/components/user/HeaderSearchBar.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'AppHeader',
  setup(__props) {

const ConfigStore = useConfigStore();
const AuthStore = useAuthStore();
const router = useRouter();

const logOut = () => {
  AuthStore.logout();
  router.push("/");
};

ConfigStore.getConfig();

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_unref(AuthStore).status)
    ? (_openBlock(), _createElementBlock("header", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_unref(AuthStore).role == 'INSTRUCTOR')
            ? (_openBlock(), _createElementBlock("li", _hoisted_3, [
                _createVNode(_component_router_link, {
                  to: { name: 'instructor-feed' },
                  class: "inline-block pl-2 pr-2"
                }, {
                  default: _withCtx(() => _cache[0] || (_cache[0] = [
                    _createElementVNode("img", {
                      style: {"width":"3em","height":"3em"},
                      title: "Home",
                      src: _imports_0
                    }, null, -1)
                  ])),
                  _: 1
                })
              ]))
            : (_unref(AuthStore).role == 'INDUSTRYPRACTITIONER')
              ? (_openBlock(), _createElementBlock("li", _hoisted_4, [
                  _createVNode(_component_router_link, {
                    to: { name: 'industry-practitioner-feed' },
                    class: "inline-block pl-2 pr-2"
                  }, {
                    default: _withCtx(() => _cache[1] || (_cache[1] = [
                      _createElementVNode("img", {
                        style: {"width":"3em","height":"3em"},
                        title: "Home",
                        src: _imports_0
                      }, null, -1)
                    ])),
                    _: 1
                  })
                ]))
              : (_unref(AuthStore).role == 'ADMIN')
                ? (_openBlock(), _createElementBlock("li", _hoisted_5, [
                    _createVNode(_component_router_link, {
                      to: { name: 'admin-feed' },
                      class: "inline-block pl-2 pr-2"
                    }, {
                      default: _withCtx(() => _cache[2] || (_cache[2] = [
                        _createElementVNode("img", {
                          style: {"width":"3em","height":"3em"},
                          title: "Home",
                          src: _imports_0
                        }, null, -1)
                      ])),
                      _: 1
                    })
                  ]))
                : _createCommentVNode("", true),
          (
          _unref(AuthStore).role == 'INDUSTRYPRACTITIONER' ||
          _unref(AuthStore).role == 'INSTRUCTOR'
        )
            ? (_openBlock(), _createElementBlock("li", _hoisted_6, [
                _createVNode(_component_router_link, {
                  to: { name: 'view-uploads' },
                  class: "nav-item nav-link rounded-2"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_font_awesome_icon, {
                      icon: "fa-solid fa-upload",
                      class: "header-link",
                      title: "My Uploads"
                    }),
                    _cache[3] || (_cache[3] = _createElementVNode("span", { class: "header-text" }, "My Uploads", -1))
                  ]),
                  _: 1
                })
              ]))
            : (_openBlock(), _createElementBlock("li", _hoisted_7)),
          _cache[4] || (_cache[4] = _createElementVNode("li", { class: "ml-1" }, null, -1))
        ]),
        (_unref(AuthStore).status)
          ? (_openBlock(), _createBlock(HeaderSearchBar, { key: 0 }))
          : _createCommentVNode("", true),
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("li", {
              class: "nav-item cursor-pointer lg:mr-2 rounded-2",
              onClick: _withModifiers(logOut, ["prevent"])
            }, [
              _createVNode(_component_font_awesome_icon, {
                icon: "fa-solid fa-right-from-bracket",
                class: "header-link",
                title: "Log Out"
              }),
              _cache[5] || (_cache[5] = _createElementVNode("span", { class: "header-text" }, "Log Out", -1))
            ])
          ])
        ])
      ]))
    : (_openBlock(), _createElementBlock("header", _hoisted_9, [
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("li", _hoisted_11, [
            _createVNode(_component_router_link, {
              to: { name: 'instructor-feed' },
              class: "inline-block pl-2 pr-2"
            }, {
              default: _withCtx(() => _cache[6] || (_cache[6] = [
                _createElementVNode("img", {
                  style: {"width":"3em","height":"3em"},
                  title: "Home",
                  src: _imports_0
                }, null, -1)
              ])),
              _: 1
            })
          ])
        ])
      ]))
}
}

})