<template>
  <span v-for="index in badgeLevel" :key="index">
    <img src="@/assets/images/badge.png" class="badge-img-card" />
  </span>
</template>

<script setup lang="ts">
import { ref } from "vue";
import PractitionerService from "@/services/practitioner.service.js";

let badgeLevel = ref(0);

const getUserBadge = () => {
  PractitionerService.getUserBadge().then(
    (response) => {
      badgeLevel.value = response.data.badge;
    },
    (error) => {
      let res =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(res);
    }
  );
};

getUserBadge();
</script>

<style scoped>
.badge-img-card {
  width: 2rem;
  height: 2rem;
  margin: 0.5rem 0.1rem;
  display: inline-block;
}

@media only screen and (max-width: 768px) {
  .badge-img-card {
    width: 1.25rem;
    height: 1.25rem;
  }
}
</style>
