import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, isRef as _isRef, withCtx as _withCtx, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = { class: "grid-container" }
const _hoisted_2 = { class: "mt-3 min-height" }
const _hoisted_3 = { class: "back-grid" }
const _hoisted_4 = {
  key: 0,
  class: "lg:mx-8"
}
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { style: {"color":"darkgrey","font-size":"small"} }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { class: "min-h" }
const _hoisted_9 = { class: "px-3" }
const _hoisted_10 = { class: "text-align-center mt-3" }

import { ref } from "vue";
import AppSidebar from "@/components/user/AppSidebar.vue";
import BackButton from "@/components/user/BackButton.vue";
import ResourcesGalleryItem from "@/components/ResourcesGalleryItem.vue";
import Dialog from "primevue/dialog";
import { useModal } from "vuestic-ui";
import { Notification, ResourceRequest } from "@/types";
import { useRouter } from "vue-router";
import { useAuthStore } from "@/stores/AuthStore";
import { useNotificationStore } from "@/stores/NotificationsStore";
import { useCourseStore } from "@/stores/CourseStore";
import InstructorService from "@/services/instructor.service.js";


export default /*@__PURE__*/_defineComponent({
  __name: 'NotificationsView',
  setup(__props) {

const { confirm } = useModal();
const AuthStore = useAuthStore();
const NotificationStore = useNotificationStore();
const CourseStore = useCourseStore();
const router = useRouter();

const currentUser = AuthStore.auth;
let currResourceRequest = ref({} as ResourceRequest);
let currNotification = ref({} as Notification);
let visible = ref(false);
let showDeleteCourseModal = ref(false);

const getNotificationText = (notification: Notification) => {
  let startText = "",
    endText = "";
  if (notification.action === "11") {
    startText = `Your request for ${notification.resource_request.type} (${notification.resource_request.description}) has received a response.`;
  } else {
    if (notification.user.phone === "True") {
      startText = `${notification.user.title} ${notification.user.firstName} ${notification.user.lastName} (${notification.user.email}/${notification.user.phoneNumber}) at ${notification.user.nameOrg}`;
    } else {
      startText = `${notification.user.title} ${notification.user.firstName} ${notification.user.lastName} (${notification.user.email}) at ${notification.user.nameOrg}`;
    }
    if (notification.action === "01") {
      endText = ` is requesting that you provide a ${notification.course_request.courseRequestType} session`;
    } else if (notification.action === "02") {
      endText = ` wants to provide course-support for ${notification.course_request.courseRequestType}`;
    } else if (notification.action === "03") {
      endText = ` has accepted your request to provide course-support for ${notification.course_request.courseRequestType}`;
    } else if (notification.action === "04") {
      endText = ` has declined your request provide course-support for ${notification.course_request.courseRequestType}`;
    } else if (notification.action === "09") {
      endText = ` has deleted the course-support request (${notification.course_request.courseRequestType}) which you intended to provide`;
    } else if (notification.action === "29") {
      endText = ` reported an upload.`;
    }
  }
  return startText + endText;
};

const viewCourseRequest = (notification) => {
  currNotification.value = notification;
  if (notification.status === "01") {
    NotificationStore.markAsRead(notification.id);
  }
  if (notification.action === "11") {
    InstructorService.getResourcesRequest(notification.linkedResourceId).then(
      (response) => {
        console.log(response.data);
        currResourceRequest.value = response.data;
        visible.value = true;
      },
      (error) => {
        let res =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        console.log(res);
      }
    );
  } else if (notification.action === "09") {
    showDeleteCourseModal.value = true;
  } else if (notification.action === "29") {
    router.push({
      name: "database-table",
      params: { name: "Uploads", sort: "true" },
    });
  } else {
    notification.course_request.user = notification.user;
    CourseStore.addCourse(notification.course_request);
    if (currentUser.roles === "INDUSTRYPRACTITIONER")
      router.push({ name: "view-course-request-prac" });
    else if (currentUser.roles === "INSTRUCTOR") {
      CourseStore.setPractitioner(notification.user.id);
      router.push({ name: "view-industry-practitioner-ins" });
    }
  }
};

return (_ctx: any,_cache: any) => {
  const _component_va_button = _resolveComponent("va-button")!
  const _component_va_modal = _resolveComponent("va-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(AppSidebar),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(BackButton),
          _cache[3] || (_cache[3] = _createElementVNode("h2", { style: {"text-align":"center","font-weight":"bold"} }, "Notifications", -1))
        ]),
        (_unref(NotificationStore).notifications.length > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(NotificationStore).notifications, (notification) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: notification.id
                }, [
                  _createElementVNode("div", {
                    class: _normalizeClass(["notification-content", { 'unread-notification': notification.status === '01' }]),
                    onClick: ($event: any) => (viewCourseRequest(notification))
                  }, [
                    _createTextVNode(_toDisplayString(getNotificationText(notification)) + " ", 1),
                    _createElementVNode("div", _hoisted_6, _toDisplayString(new Date(notification.createdAt).toDateString() +
                " " +
                new Date(notification.createdAt).toLocaleTimeString()), 1)
                  ], 10, _hoisted_5)
                ]))
              }), 128))
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_7, "No Notifications."))
      ])
    ]),
    _createVNode(_unref(Dialog), {
      visible: _unref(visible),
      "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => (_isRef(visible) ? (visible).value = $event : visible = $event)),
      modal: "",
      header: _unref(currResourceRequest).type,
      style: { width: '70vw' },
      breakpoints: { '768px': '90vw' }
    }, {
      default: _withCtx(() => [
        _createElementVNode("i", null, _toDisplayString(_unref(currResourceRequest).description), 1),
        _createElementVNode("div", _hoisted_8, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(currResourceRequest).resource_responses, (item) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "responsive",
              key: item.id
            }, [
              _createVNode(ResourcesGalleryItem, { item: item }, null, 8, ["item"])
            ]))
          }), 128))
        ]),
        _cache[4] || (_cache[4] = _createElementVNode("div", { class: "clearfix" }, null, -1))
      ]),
      _: 1
    }, 8, ["visible", "header"]),
    _createVNode(_component_va_modal, {
      modelValue: _unref(showDeleteCourseModal),
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_isRef(showDeleteCourseModal) ? (showDeleteCourseModal).value = $event : showDeleteCourseModal = $event)),
      "hide-default-actions": ""
    }, {
      default: _withCtx(() => [
        _cache[11] || (_cache[11] = _createElementVNode("h3", { class: "va-h3" }, null, -1)),
        _createElementVNode("div", _hoisted_9, [
          _cache[8] || (_cache[8] = _createElementVNode("div", null, " The following course-support request was deleted by the instructor. ", -1)),
          _createElementVNode("div", null, [
            _cache[5] || (_cache[5] = _createElementVNode("b", null, "Type of course-support:", -1)),
            _createTextVNode(" " + _toDisplayString(_unref(currNotification).course_request.courseRequestType) + " ", 1),
            _cache[6] || (_cache[6] = _createElementVNode("br", null, null, -1)),
            _cache[7] || (_cache[7] = _createElementVNode("b", null, "Date and Time:", -1)),
            _createTextVNode(" " + _toDisplayString(new Date(
            (_unref(currNotification)).course_request.course_request_x_practitioners[0].selectedDate
          ).toLocaleString()), 1)
          ]),
          _cache[9] || (_cache[9] = _createElementVNode("div", null, "Please take this schedule off your calendar.", -1))
        ]),
        _createElementVNode("div", _hoisted_10, [
          _createVNode(_component_va_button, {
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_isRef(showDeleteCourseModal) //@ts-ignore
 ? showDeleteCourseModal.value = false : showDeleteCourseModal = false))
          }, {
            default: _withCtx(() => _cache[10] || (_cache[10] = [
              _createTextVNode(" Confirm ")
            ])),
            _: 1
          })
        ])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}
}

})