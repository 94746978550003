import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, isRef as _isRef, mergeProps as _mergeProps, withCtx as _withCtx, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = { class: "grid-container" }
const _hoisted_2 = { class: "m-3 min-height" }
const _hoisted_3 = { class: "back-grid" }
const _hoisted_4 = { class: "mt-3" }
const _hoisted_5 = { class: "flex flex-direction-row gap-4" }
const _hoisted_6 = {
  class: "w-100 mt-3 text-align-center font-x-large font-bold",
  style: {"text-transform":"capitalize"}
}
const _hoisted_7 = { class: "mt-2" }
const _hoisted_8 = { class: "w-50" }
const _hoisted_9 = { class: "w-50" }

import { ref } from "vue";
import { Field } from "vee-validate";
import Multiselect from "@vueform/multiselect";
import UserService from "@/services/user.service.js";
import AppSidebar from "@/components/user/AppSidebar.vue";
import BackButton from "@/components/user/BackButton.vue";
import { useConfigStore } from "@/stores/ConfigStore";
import { showToast } from "@/utils";


export default /*@__PURE__*/_defineComponent({
  __name: 'FieldSelectionView',
  setup(__props) {

let selectedTable = ref("");
let loading = ref(false);
let msg = ref("");

const ConfigStore = useConfigStore();
const config = ref(ConfigStore.config);
const tableOptions = [
  { label: "Class", value: "course" },
  { label: "Course Request", value: "course_request" },
  { label: "Resource Request", value: "resource_request" },
  { label: "Upload", value: "upload" },
  { label: "Instructor Profile", value: "instructor_profile" },
  { label: "Practitioner Profile", value: "practitioner_profile" },
  { label: "Availability", value: "availability" },
  { label: "Site Visit", value: "site_visit" },
  { label: "Resource Response", value: "resource_response" },
  { label: "Invite Other Users", value: "invite_users" },
  { label: "Feedback", value: "feedback" },
  { label: "Settings", value: "settings" },
];

config.value.forEach((el) => (el.enabled = !el.disabled));

const updateConfig = (item) => {
  item.disabled = !item.enabled;
  loading.value = true;
  UserService.updateConfig(item.id, item.disabled).then(
    (data) => {
      showToast("Configuration updated successfully.");
      ConfigStore.getConfig();
      loading.value = false;
    },
    (error) => {
      msg.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      loading.value = false;
    }
  );
};

return (_ctx: any,_cache: any) => {
  const _component_VaSwitch = _resolveComponent("VaSwitch")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(AppSidebar),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(BackButton),
        _cache[1] || (_cache[1] = _createElementVNode("h2", { class: "text-align-center bold-text" }, "Field Selection", -1))
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _cache[2] || (_cache[2] = _createElementVNode("h3", { class: "flex-shrink-1 flex-grow-0" }, "Table", -1)),
          _createElementVNode("div", null, [
            _createVNode(_unref(Field), {
              name: "selectedTable",
              type: "text",
              class: "dropdown",
              autofocus: ""
            }, {
              default: _withCtx(({ field }) => [
                _createVNode(_unref(Multiselect), _mergeProps(field, {
                  modelValue: _unref(selectedTable),
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(selectedTable) ? (selectedTable).value = $event : selectedTable = $event)),
                  options: tableOptions,
                  mode: "single",
                  searchable: true
                }), null, 16, ["modelValue"])
              ]),
              _: 1
            })
          ])
        ]),
        _createElementVNode("div", _hoisted_6, _toDisplayString(tableOptions.find((el) => el.value === _unref(selectedTable))?.label), 1),
        _createElementVNode("div", _hoisted_7, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(config.value.filter((el) => el.table === _unref(selectedTable)), (item) => {
            return (_openBlock(), _createElementBlock("div", {
              key: item.id,
              class: "p-2 w-100 bg-gray-200 my-1 flex flex-basis-0"
            }, [
              _createElementVNode("div", _hoisted_8, _toDisplayString(item.label), 1),
              _createElementVNode("div", _hoisted_9, [
                _createVNode(_component_VaSwitch, {
                  modelValue: item.enabled,
                  "onUpdate:modelValue": [($event: any) => ((item.enabled) = $event), ($event: any) => (updateConfig(item))],
                  "true-label": "Enabled",
                  "false-label": "Disabled",
                  size: "small"
                }, null, 8, ["modelValue", "onUpdate:modelValue"])
              ])
            ]))
          }), 128))
        ])
      ])
    ])
  ]))
}
}

})