<template>
  <Form
    @submit="saveCourseFeedback"
    :validation-schema="courseFeedbackSchema"
    v-slot="{ values, meta }"
  >
    <div class="form-group">
      <label for="message"
        >Comments about the session
        <span class="super font-color-red">*</span></label
      >
      <Field name="message" type="text" class="form-control" />
      <ErrorMessage name="message" class="error-feedback" />
    </div>
    <div class="form-group">
      <label for="rating"
        >Rating of the Practitioner<span class="super font-color-red"
          >*</span
        ></label
      >
      <Field name="rating" type="text" class="form-control">
        <Rating v-model="values.rating" :cancel="false" />
      </Field>
      <ErrorMessage name="rating" class="error-feedback" />
    </div>
    <div class="form-group">
      <label for="concern">Any feedback, comments, or concern to Admin.</label>
      <Field name="concern" type="text" class="form-control" />
      <ErrorMessage name="concern" class="error-feedback" />
    </div>

    <br />
    <div class="form-group text-align-center">
      <button
        class="btn btn-primary btn-block"
        :disabled="loading || !meta.valid"
      >
        <span v-show="loading" class="spinner-border spinner-border-sm"></span>
        <span>Save Feedback</span>
      </button>
    </div>
  </Form>
</template>
<script setup lang="ts">
import { ref } from "vue";
import * as yup from "yup";
import { Form, Field, ErrorMessage } from "vee-validate";
import UserService from "@/services/user.service.js";
import { showErrorToast, showToast } from "@/utils";
import Rating from "primevue/rating";

// eslint-disable-next-line no-undef
const props = defineProps<{
  currCourseId: number;
}>();
// eslint-disable-next-line no-undef
const emits = defineEmits(["fetchCourse"]);

let loading = ref(false);
let message = ref("");

const courseFeedbackSchema = yup.object().shape({
  message: yup.string().required("Comments about the session is required!"),
  rating: yup.string().required("Rating is required!"),
  concern: yup.string().optional().nullable(),
});

const saveCourseFeedback = (data) => {
  loading.value = true;
  data.courseRequestId = props.currCourseId;
  UserService.saveCourseFeedback(data).then(
    (response) => {
      if (response.status == "200") emits("fetchCourse");
      loading.value = false;
    },
    (error) => {
      loading.value = false;
      message.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      showErrorToast(message.value);
    }
  );
};
</script>
<style scoped></style>
