import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, isRef as _isRef, withCtx as _withCtx, mergeProps as _mergeProps, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "grid-container" }
const _hoisted_2 = { class: "m-3 min-height" }
const _hoisted_3 = { class: "back-grid" }
const _hoisted_4 = { class: "mt-8" }
const _hoisted_5 = {
  key: 0,
  class: "course-item mt-5"
}
const _hoisted_6 = { class: "flex flex-direction-column text-center lg:text-left lg:flex-row justify-content-between align-content-center flex-wrap" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { class: "flex flex-grow-0 text-center align-content-center sm:mt-2 lg:mr-3 lg:flex-wrap" }
const _hoisted_9 = {
  key: 0,
  class: "mr-3 align-self-center font-bold"
}
const _hoisted_10 = { key: 1 }
const _hoisted_11 = ["onClick"]
const _hoisted_12 = { key: 0 }
const _hoisted_13 = { key: 1 }
const _hoisted_14 = { class: "paginator" }
const _hoisted_15 = {
  key: 1,
  class: "text-align-center"
}
const _hoisted_16 = {
  key: 0,
  class: "form-group flex justify-space-between flex-direction-column mt-3 lg:flex-row"
}
const _hoisted_17 = { class: "ml-3" }
const _hoisted_18 = {
  key: 0,
  class: "font-color-banner"
}
const _hoisted_19 = {
  key: 1,
  class: "font-color-banner"
}
const _hoisted_20 = {
  key: 2,
  class: "font-color-banner"
}
const _hoisted_21 = { key: 3 }
const _hoisted_22 = {
  key: 1,
  class: "form-group bold-text ml-5"
}
const _hoisted_23 = {
  key: 2,
  class: "form-group flex justify-space-between flex-direction-column lg:flex-row"
}
const _hoisted_24 = { class: "ml-3" }
const _hoisted_25 = {
  key: 3,
  class: "form-group"
}
const _hoisted_26 = {
  key: 4,
  class: "form-group"
}
const _hoisted_27 = { class: "form-group text-align-center mt-3" }
const _hoisted_28 = ["disabled"]
const _hoisted_29 = { class: "spinner-border spinner-border-sm" }

import { ref } from "vue";
import * as yup from "yup";
import { Form, Field, ErrorMessage } from "vee-validate";
import PractitionerSidebar from "@/components/PractitionerSidebar.vue";
import BackButton from "@/components/user/BackButton.vue";
import Dialog from "primevue/dialog";
import { checkIfFilesAreTooBig, showErrorToast, showToast } from "@/utils";
import UserService from "@/services/user.service.js";
import PractitionerService from "@/services/practitioner.service.js";
import { ResourceRequest } from "@/types";
import heic2any from "heic2any";
import { useConfigStore } from "@/stores/ConfigStore";


export default /*@__PURE__*/_defineComponent({
  __name: 'ResourcesFeed',
  setup(__props) {

const ConfigStore = useConfigStore();
const resourceResponseFields = ConfigStore.config.filter(
  (el) => el.table === "resource_response"
);

let resourceRequests = ref([] as ResourceRequest[]);
let currResourceRequest = ref({} as ResourceRequest);
let files = ref([] as File[]);
let convertedFiles = ref([] as File[]);
let allowedFileType = ref("");
let message = ref("");
let visible = ref(false);
let loading = ref(false);
const currentPage = ref(1);
const totalPages = ref(1);

const viewResourceRequest = (pageNumber = 1) => {
  UserService.getResourcesRequest(pageNumber).then(
    (response) => {
      console.log(response.data);
      resourceRequests.value = response.data.data;
      totalPages.value = response.data.totalPages;
      currentPage.value = response.data.currentPage;
    },
    (error) => {
      let res =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(res);
    }
  );
};

let initialState = {
  linkResources: "",
  rightToUpload: "",
  personalInfo: "",
};

const schema = yup.object().shape({
  fields: yup.array().default(resourceResponseFields),
  linkResources: yup.string().when("personalInfo", {
    is: () => files && files.value.length > 0,
    then: (schema) => schema.optional().nullable(),
    otherwise: (schema) =>
      schema
        .required("Link to Resources or uploaded file is required!")
        .url("Link to Resources should be a valid hyperlink!"),
  }),
  file: yup.mixed().when("linkResources", {
    is: (val) => val && val.length > 0,
    then: (schema) => schema.optional(),
    otherwise: (schema) =>
      schema.required().test(
        "is-file-too-large",
        () => `File size exceeds the maximum allowable of 25MB!`,
        (value, testContext) =>
          checkIfFilesAreTooBig(value, testContext, files.value)
      ),
  }),
  rightToUpload: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "rightToUpload")?.disabled,
    then: (schema) =>
      schema
        .required("This is required to proceed!")
        .oneOf(["True"], "This is required to proceed!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  personalInfo: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "personalInfo")?.disabled,
    then: (schema) =>
      schema
        .required("This is required to proceed!")
        .oneOf(["True"], "This is required to proceed!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
});

const fileType = (resourceRequest) => {
  currResourceRequest.value = resourceRequest;
  if (currResourceRequest.value.type === "Video")
    allowedFileType.value =
      ".ogm, .wmv, .mpg, .webm, .ogv, .mov, .asx, .mpeg, .mp4, .m4v, .avi";
  else if (currResourceRequest.value.type === "Project Pictures")
    allowedFileType.value =
      ".xbm, .tif, .pjp, .apng, .jpg, .jpeg, .ico, .tiff, .gif, .svg, .jfif, .webp, .png, .bmp, .avif, .heic";
  else if (currResourceRequest.value.type === "Document")
    allowedFileType.value =
      ".pdf,.doc,.docx,application/msword,.txt,.ppt,.pptx,application/vnd.ms-powerpoint";
  files.value.length = 0;
  visible.value = true;
};

const convertImg = async (blob) => {
  return (async () => {
    const conversionResult = await heic2any({ blob });
    convertedFiles.value.push(
      new File(
        [conversionResult],
        blob.name.split(".").slice(0, -1).join(".") + "-conv.png"
      )
    );
  })();
};

const convertImages = async (files) => {
  for (let index = 0; index < files.length; index++) {
    if (!files[index].type.startsWith("image")) await convertImg(files[index]);
  }
  await console.log("done convertImages");
};

const uploadData = async (data, { resetForm }) => {
  data["files"] = files.value;
  data["convertedFiles"] = convertedFiles.value;
  data["resourceRequestId"] = currResourceRequest.value.id;
  PractitionerService.uploadResourceRequestResponse(data).then(
    () => {
      showToast("Content Uploaded Successfully!");
      loading.value = false;
      resetForm();
      viewResourceRequest();
      visible.value = false;
    },
    (error) => {
      console.log(error);
      message.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      loading.value = false;
      visible.value = false;
      showErrorToast("File size limit has been reached");
    }
  );
};

const onValidSubmit = async (data, { resetForm }) => {
  if (files && files.value.length > 0 && data["linkResources"].length > 0) {
    showErrorToast(
      "Please provide only one of the following : File or Hyperlink to resource."
    );
  } else {
    loading.value = true;
    if (currResourceRequest.value.type === "Project Pictures") {
      await convertImages(files.value);
    }
    await uploadData(data, { resetForm });
  }
};

const onInvalidSubmit = ({ errors }) => {
  Object.values(errors).forEach((error) => showErrorToast(error));
};

const onClick = (val) => {
  viewResourceRequest(currentPage.value);
};

viewResourceRequest();

console.log("Anubhav");

return (_ctx: any,_cache: any) => {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_va_pagination = _resolveComponent("va-pagination")!
  const _component_va_file_upload = _resolveComponent("va-file-upload")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(PractitionerSidebar),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(BackButton),
          _cache[3] || (_cache[3] = _createElementVNode("h2", { style: {"text-align":"center","font-weight":"bold"} }, " Request(s) For Resources ", -1))
        ]),
        _createElementVNode("div", _hoisted_4, [
          (_unref(resourceRequests).length > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(resourceRequests), (resourceRequest) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "m-1 card",
                    key: resourceRequest.id
                  }, [
                    _createElementVNode("div", _hoisted_6, [
                      _createElementVNode("div", null, [
                        _createElementVNode("b", null, _toDisplayString(resourceRequest.type), 1),
                        (resourceRequest.resource_responses.length > 0)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_7, [
                              _createVNode(_component_font_awesome_icon, {
                                icon: "fa-solid fa-check",
                                style: {"color":"#0bda51","font-size":"x-large"}
                              }),
                              _cache[4] || (_cache[4] = _createElementVNode("span", { style: {"font-size":"smaller"} }, "You have successfully responded to this request!", -1))
                            ]))
                          : _createCommentVNode("", true),
                        _cache[5] || (_cache[5] = _createElementVNode("br", null, null, -1)),
                        _createTextVNode(" " + _toDisplayString(resourceRequest.description), 1)
                      ]),
                      _createElementVNode("div", _hoisted_8, [
                        (resourceRequest.status === '90')
                          ? (_openBlock(), _createElementBlock("span", _hoisted_9, " Closed "))
                          : (_openBlock(), _createElementBlock("span", _hoisted_10, [
                              _createElementVNode("button", {
                                onClick: ($event: any) => (fileType(resourceRequest)),
                                class: "btn btn-primary mr-3"
                              }, [
                                (resourceRequest.resource_responses.length > 0)
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_12, "Respond Again"))
                                  : (_openBlock(), _createElementBlock("span", _hoisted_13, "Respond"))
                              ], 8, _hoisted_11)
                            ]))
                      ])
                    ])
                  ]))
                }), 128)),
                _createElementVNode("div", _hoisted_14, [
                  _createVNode(_component_va_pagination, {
                    modelValue: currentPage.value,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((currentPage).value = $event)),
                    pages: totalPages.value,
                    "visible-pages": 3,
                    hideOnSinglePage: true,
                    onClick: onClick
                  }, null, 8, ["modelValue", "pages"])
                ])
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_15, " No request for resources from instructors at the moment "))
        ])
      ])
    ]),
    _createVNode(_unref(Dialog), {
      visible: _unref(visible),
      "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => (_isRef(visible) ? (visible).value = $event : visible = $event)),
      modal: "",
      header: _unref(currResourceRequest).type,
      style: { width: '75vw' }
    }, {
      default: _withCtx(() => [
        _createElementVNode("i", null, _toDisplayString(_unref(currResourceRequest).description), 1),
        _createVNode(_unref(Form), {
          onSubmit: onValidSubmit,
          onInvalidSubmit: onInvalidSubmit,
          "validation-schema": _unref(schema),
          "initial-values": _unref(initialState),
          enctype: "multipart/form-data"
        }, {
          default: _withCtx(() => [
            (
          !_unref(resourceResponseFields).find((el) => el.name === 'files')?.disabled
        )
              ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                  _cache[8] || (_cache[8] = _createElementVNode("div", { class: "flex-grow-0 flex-shrink-1" }, [
                    _createElementVNode("label", { style: {"margin-top":"0"} }, "Upload File(s)")
                  ], -1)),
                  _createElementVNode("div", _hoisted_17, [
                    _createVNode(_unref(Field), { name: "file" }, {
                      default: _withCtx(({ handleChange, handleBlur }) => [
                        _createVNode(_component_va_file_upload, {
                          modelValue: _unref(files),
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_isRef(files) ? (files).value = $event : files = $event)),
                          dropzone: "",
                          onChange: handleChange,
                          onBlur: handleBlur,
                          uploadButtonText: "Choose File",
                          "file-types": _unref(allowedFileType)
                        }, null, 8, ["modelValue", "onChange", "onBlur", "file-types"])
                      ]),
                      _: 1
                    }),
                    _cache[7] || (_cache[7] = _createElementVNode("div", { class: "font-color-banner mt-0" }, " Maximum allowable file size is 25MB ", -1)),
                    (_unref(currResourceRequest).type === 'Project Pictures')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_18, " Allowed File Type: .xbm, .tif, .pjp, .apng, .jpg, .jpeg, .ico, .tiff, .gif, .svg, .jfif, .webp, .png, .bmp, .avif, .heic "))
                      : (_unref(currResourceRequest).type === 'Video')
                        ? (_openBlock(), _createElementBlock("div", _hoisted_19, " Allowed File Type: .ogm, .wmv, .mpg, .webm, .ogv, .mov, .asx, .mpeg, .mp4, .m4v, .avi "))
                        : (_unref(currResourceRequest).type === 'Document')
                          ? (_openBlock(), _createElementBlock("div", _hoisted_20, " Allowed File Type: .pdf, .doc, .docx, .txt, .ppt, .pptx "))
                          : (_openBlock(), _createElementBlock("div", _hoisted_21, _cache[6] || (_cache[6] = [
                              _createElementVNode("br", null, null, -1)
                            ])))
                  ])
                ]))
              : _createCommentVNode("", true),
            (
          !_unref(resourceResponseFields).find((el) => el.name === 'files')?.disabled &&
          !_unref(resourceResponseFields).find((el) => el.name === 'linkResources')
            ?.disabled
        )
              ? (_openBlock(), _createElementBlock("div", _hoisted_22, " OR "))
              : _createCommentVNode("", true),
            (
          !_unref(resourceResponseFields).find((el) => el.name === 'linkResources')
            ?.disabled
        )
              ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                  _cache[9] || (_cache[9] = _createElementVNode("div", { class: "flex-grow-0 flex-shrink-1" }, [
                    _createElementVNode("label", { for: "linkResources" }, "Provide Link")
                  ], -1)),
                  _createElementVNode("div", _hoisted_24, [
                    _createVNode(_unref(Field), { name: "linkResources" }, {
                      default: _withCtx(({ field }) => [
                        _createElementVNode("input", _mergeProps(field, {
                          class: "form-control",
                          type: "text",
                          placeholder: "https://"
                        }), null, 16),
                        _createVNode(_unref(ErrorMessage), {
                          name: "linkResources",
                          class: "error-feedback"
                        })
                      ]),
                      _: 1
                    })
                  ])
                ]))
              : _createCommentVNode("", true),
            (
          !_unref(resourceResponseFields).find((el) => el.name === 'rightToUpload')
            ?.disabled
        )
              ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
                  _createVNode(_unref(Field), {
                    name: "rightToUpload",
                    value: "True",
                    type: "checkbox"
                  }),
                  _cache[10] || (_cache[10] = _createElementVNode("span", { class: "super font-color-red" }, "*", -1)),
                  _cache[11] || (_cache[11] = _createTextVNode(" I confirm that I have the right to upload this content and share it with instructors to enhance their instructional materials. ")),
                  _cache[12] || (_cache[12] = _createElementVNode("br", null, null, -1)),
                  _createVNode(_unref(ErrorMessage), {
                    name: "rightToUpload",
                    class: "error-feedback"
                  })
                ]))
              : _createCommentVNode("", true),
            (
          !_unref(resourceResponseFields).find((el) => el.name === 'personalInfo')
            ?.disabled
        )
              ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
                  _createVNode(_unref(Field), {
                    name: "personalInfo",
                    value: "True",
                    type: "checkbox"
                  }),
                  _cache[13] || (_cache[13] = _createElementVNode("span", { class: "super font-color-red" }, "*", -1)),
                  _cache[14] || (_cache[14] = _createTextVNode(" I confirm that this material is void of any personal identifiable information or privacy data. ")),
                  _cache[15] || (_cache[15] = _createElementVNode("br", null, null, -1)),
                  _createVNode(_unref(ErrorMessage), {
                    name: "personalInfo",
                    class: "error-feedback"
                  })
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_27, [
              _createElementVNode("button", {
                type: "submit",
                class: "btn btn-primary btn-block",
                disabled: _unref(loading)
              }, [
                _withDirectives(_createElementVNode("span", _hoisted_29, null, 512), [
                  [_vShow, _unref(loading)]
                ]),
                _cache[16] || (_cache[16] = _createElementVNode("span", null, "Upload", -1))
              ], 8, _hoisted_28)
            ])
          ]),
          _: 1
        }, 8, ["validation-schema", "initial-values"])
      ]),
      _: 1
    }, 8, ["visible", "header"])
  ], 64))
}
}

})