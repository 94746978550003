import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "p-2 cursor-pointer" }
const _hoisted_2 = { style: {"display":"grid","grid-template-columns":"14fr 1fr"} }
const _hoisted_3 = { class: "mt-2" }
const _hoisted_4 = ["src"]
const _hoisted_5 = {
  key: 1,
  id: "profile-img",
  src: "//ssl.gstatic.com/accounts/ui/avatar_2x.png",
  class: "recommendation-img-card"
}
const _hoisted_6 = { class: "text-align-center" }
const _hoisted_7 = { class: "bold-text" }
const _hoisted_8 = { class: "bold-text" }

import { websiteURL } from "@/utils";
import { useRecommendationsStore } from "@/stores/RecommendationsStore";
import { User } from "@/types";


export default /*@__PURE__*/_defineComponent({
  __name: 'RecommendationGalleryItem',
  props: {
    selectedPractitioner: {},
    title: {}
  },
  setup(__props: any) {

const RecommendationsStore = useRecommendationsStore();
// eslint-disable-next-line no-undef
const props = __props;

console.log(props.selectedPractitioner);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, [
      _createElementVNode("i", null, _toDisplayString(props.title), 1),
      _createElementVNode("span", {
        class: "list-item-remove list-item-remove-icon",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (
          _unref(RecommendationsStore).removeSelectedPractitioner(
            _ctx.selectedPractitioner.id
          )
        ))
      })
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.selectedPractitioner.fileName)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            id: "profile-img",
            src: _unref(websiteURL) + 'profile_picture/' + _ctx.selectedPractitioner.fileName,
            class: "recommendation-img-card"
          }, null, 8, _hoisted_4))
        : (_openBlock(), _createElementBlock("img", _hoisted_5))
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.selectedPractitioner.title +
          " " +
          _ctx.selectedPractitioner.firstName +
          " " +
          _ctx.selectedPractitioner.lastName), 1),
      _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.selectedPractitioner.position + " at " + _ctx.selectedPractitioner.nameOrg), 1)
    ])
  ]))
}
}

})