<template>
  <div class="grid-container">
    <app-sidebar />
    <div class="m-3 min-height">
      <div class="back-grid">
        <back-button></back-button>
        <h2 class="text-align-center bold-text">Field Selection</h2>
      </div>
      <div class="mt-3">
        <div class="flex flex-direction-row gap-4">
          <h3 class="flex-shrink-1 flex-grow-0">Table</h3>
          <div>
            <Field
              name="selectedTable"
              type="text"
              class="dropdown"
              v-slot="{ field }"
              autofocus
            >
              <Multiselect
                v-bind="field"
                v-model="selectedTable"
                :options="tableOptions"
                mode="single"
                :searchable="true"
              />
            </Field>
          </div>
        </div>
        <div
          class="w-100 mt-3 text-align-center font-x-large font-bold"
          style="text-transform: capitalize"
        >
          {{ tableOptions.find((el) => el.value === selectedTable)?.label }}
        </div>
        <div class="mt-2">
          <div
            v-for="item in config.filter((el) => el.table === selectedTable)"
            v-bind:key="item.id"
            class="p-2 w-100 bg-gray-200 my-1 flex flex-basis-0"
          >
            <div class="w-50">
              {{ item.label }}
            </div>
            <div class="w-50">
              <VaSwitch
                v-model="item.enabled"
                true-label="Enabled"
                false-label="Disabled"
                size="small"
                @update:modelValue="updateConfig(item)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { Field } from "vee-validate";
import Multiselect from "@vueform/multiselect";
import UserService from "@/services/user.service.js";
import AppSidebar from "@/components/user/AppSidebar.vue";
import BackButton from "@/components/user/BackButton.vue";
import { useConfigStore } from "@/stores/ConfigStore";
import { showToast } from "@/utils";

let selectedTable = ref("");
let loading = ref(false);
let msg = ref("");

const ConfigStore = useConfigStore();
const config = ref(ConfigStore.config);
const tableOptions = [
  { label: "Class", value: "course" },
  { label: "Course Request", value: "course_request" },
  { label: "Resource Request", value: "resource_request" },
  { label: "Upload", value: "upload" },
  { label: "Instructor Profile", value: "instructor_profile" },
  { label: "Practitioner Profile", value: "practitioner_profile" },
  { label: "Availability", value: "availability" },
  { label: "Site Visit", value: "site_visit" },
  { label: "Resource Response", value: "resource_response" },
  { label: "Invite Other Users", value: "invite_users" },
  { label: "Feedback", value: "feedback" },
  { label: "Settings", value: "settings" },
];

config.value.forEach((el) => (el.enabled = !el.disabled));

const updateConfig = (item) => {
  item.disabled = !item.enabled;
  loading.value = true;
  UserService.updateConfig(item.id, item.disabled).then(
    (data) => {
      showToast("Configuration updated successfully.");
      ConfigStore.getConfig();
      loading.value = false;
    },
    (error) => {
      msg.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      loading.value = false;
    }
  );
};
</script>

<style scoped></style>
