import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "min-height header-space" }
const _hoisted_2 = { class: "card card-container" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "form-group" }
const _hoisted_5 = { class: "form-group" }
const _hoisted_6 = { class: "form-group" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { class: "form-group" }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { class: "form-group" }
const _hoisted_11 = { class: "form-group" }
const _hoisted_12 = {
  class: "form-group text-align-center",
  style: {"width":"100%"}
}
const _hoisted_13 = ["disabled"]
const _hoisted_14 = { class: "spinner-border spinner-border-sm" }

import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import AdminService from "@/services/admin.service.js";
import { useRoute, useRouter } from "vue-router";


export default /*@__PURE__*/_defineComponent({
  __name: 'AddAdminView',
  setup(__props) {

const route = useRoute();
const router = useRouter();

let loading = false;
let successful = false;
let message = "";

const schema = yup.object().shape({
  ethnicity: yup.string().required("Racial Identity is required!"),
  gender: yup.string().required("Gender Identity is required!"),
  firstName: yup.string().required("First Name is required!"),
  lastName: yup.string().required("Last Name is required!"),
  password: yup
    .string()
    .required("Password is required!")
    .min(6, "Must be at least 6 characters!")
    .max(40, "Must be maximum 40 characters!")
    .matches(
      /^.*(?=.{8,})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      "Password must contain at least 8 characters, one uppercase, and one number"
    ),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password")], "Passwords must match"),
});

const handleSetAdmin = (data) => {
  loading = true;
  data.token = route.params.token;
  if (data.gender === "Others") data.gender = data["gender-text"];
  if (data.ethnicity === "Others") data.ethnicity = data["ethnicity-text"];
  AdminService.setAdminDetails(data).then(
    () => {
      router.push("/Home");
    },
    (error) => {
      loading = false;
      message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (!_unref(successful))
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_unref(Form), {
              onSubmit: handleSetAdmin,
              "validation-schema": _unref(schema)
            }, {
              default: _withCtx(({ values }) => [
                _createElementVNode("div", _hoisted_4, [
                  _cache[0] || (_cache[0] = _createElementVNode("label", { for: "firstName" }, "First Name", -1)),
                  _createVNode(_unref(Field), {
                    name: "firstName",
                    type: "text",
                    class: "form-control"
                  }),
                  _createVNode(_unref(ErrorMessage), {
                    name: "firstName",
                    class: "error-feedback"
                  })
                ]),
                _createElementVNode("div", _hoisted_5, [
                  _cache[1] || (_cache[1] = _createElementVNode("label", { for: "lastName" }, "Last Name", -1)),
                  _createVNode(_unref(Field), {
                    name: "lastName",
                    type: "text",
                    class: "form-control"
                  }),
                  _createVNode(_unref(ErrorMessage), {
                    name: "lastName",
                    class: "error-feedback"
                  })
                ]),
                _createElementVNode("div", _hoisted_6, [
                  _cache[3] || (_cache[3] = _createElementVNode("label", { for: "gender" }, "Gender Identity", -1)),
                  _createVNode(_unref(Field), {
                    name: "gender",
                    type: "select",
                    class: "dropdown",
                    as: "select"
                  }, {
                    default: _withCtx(() => _cache[2] || (_cache[2] = [
                      _createElementVNode("option", { value: "Male" }, "Male", -1),
                      _createElementVNode("option", { value: "Female" }, "Female", -1),
                      _createElementVNode("option", { value: "Non-binary" }, "Non-binary", -1),
                      _createElementVNode("option", { value: "Others" }, "Others, please specify", -1)
                    ])),
                    _: 1
                  }),
                  (values.gender === 'Others')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                        _createVNode(_unref(Field), {
                          name: "gender-text",
                          type: "text",
                          placeholder: "",
                          class: "form-control"
                        })
                      ]))
                    : _createCommentVNode("", true),
                  _createVNode(_unref(ErrorMessage), {
                    name: "gender",
                    class: "error-feedback"
                  })
                ]),
                _createElementVNode("div", _hoisted_8, [
                  _cache[5] || (_cache[5] = _createElementVNode("label", { for: "ethnicity" }, "Racial Identity", -1)),
                  _createVNode(_unref(Field), {
                    name: "ethnicity",
                    class: "dropdown",
                    as: "select"
                  }, {
                    default: _withCtx(() => _cache[4] || (_cache[4] = [
                      _createElementVNode("option", { value: "American Indian or Alaska Native" }, " American Indian or Alaska Native ", -1),
                      _createElementVNode("option", { value: "Asian" }, "Asian", -1),
                      _createElementVNode("option", { value: "Black or African American" }, " Black or African American ", -1),
                      _createElementVNode("option", { value: "Hispanic or Latinx" }, "Hispanic or Latinx", -1),
                      _createElementVNode("option", { value: "Native Hawaiian or other Pacific Islander" }, " Native Hawaiian or other Pacific Islander ", -1),
                      _createElementVNode("option", { value: "White/Caucasian" }, "White/Caucasian", -1),
                      _createElementVNode("option", { value: "Others" }, "Others, please specify", -1)
                    ])),
                    _: 1
                  }),
                  (values.ethnicity === 'Others')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                        _createVNode(_unref(Field), {
                          name: "ethnicity-text",
                          type: "text",
                          placeholder: "",
                          class: "form-control"
                        })
                      ]))
                    : _createCommentVNode("", true),
                  _createVNode(_unref(ErrorMessage), {
                    name: "ethnicity",
                    class: "error-feedback"
                  })
                ]),
                _createElementVNode("div", _hoisted_10, [
                  _cache[6] || (_cache[6] = _createElementVNode("label", { for: "password" }, "Password", -1)),
                  _createVNode(_unref(Field), {
                    name: "password",
                    type: "password",
                    class: "form-control"
                  }),
                  _createVNode(_unref(ErrorMessage), {
                    name: "password",
                    class: "error-feedback"
                  })
                ]),
                _createElementVNode("div", _hoisted_11, [
                  _cache[7] || (_cache[7] = _createElementVNode("label", { for: "confirmPassword" }, "Confirm Password", -1)),
                  _createVNode(_unref(Field), {
                    name: "confirmPassword",
                    type: "password",
                    class: "form-control"
                  }),
                  _createVNode(_unref(ErrorMessage), {
                    name: "confirmPassword",
                    class: "error-feedback"
                  })
                ]),
                _cache[9] || (_cache[9] = _createElementVNode("br", null, null, -1)),
                _createElementVNode("div", _hoisted_12, [
                  _createElementVNode("button", {
                    class: "btn btn-primary btn-block",
                    disabled: _unref(loading)
                  }, [
                    _withDirectives(_createElementVNode("span", _hoisted_14, null, 512), [
                      [_vShow, _unref(loading)]
                    ]),
                    _cache[8] || (_cache[8] = _createElementVNode("span", null, "Set Password", -1))
                  ], 8, _hoisted_13)
                ])
              ]),
              _: 1
            }, 8, ["validation-schema"])
          ]))
        : _createCommentVNode("", true),
      (_unref(message))
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: _normalizeClass(_unref(successful) ? 'alert-success' : 'error-feedback')
          }, _toDisplayString(_unref(message)), 3))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})