import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "table" }

import { ContactUs } from "@/types";

// eslint-disable-next-line no-undef

export default /*@__PURE__*/_defineComponent({
  __name: 'ContactUsTable',
  props: {
    contactUs: {}
  },
  setup(__props: any) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("table", _hoisted_1, [
    _createElementVNode("tr", null, [
      _cache[0] || (_cache[0] = _createElementVNode("td", null, "First Name", -1)),
      _createElementVNode("td", null, _toDisplayString(_ctx.contactUs.firstName), 1)
    ]),
    _createElementVNode("tr", null, [
      _cache[1] || (_cache[1] = _createElementVNode("td", null, "Last Name", -1)),
      _createElementVNode("td", null, _toDisplayString(_ctx.contactUs.lastName), 1)
    ]),
    _createElementVNode("tr", null, [
      _cache[2] || (_cache[2] = _createElementVNode("td", null, "Email", -1)),
      _createElementVNode("td", null, _toDisplayString(_ctx.contactUs.email), 1)
    ]),
    _createElementVNode("tr", null, [
      _cache[3] || (_cache[3] = _createElementVNode("td", null, "Name of Institution/Organization", -1)),
      _createElementVNode("td", null, _toDisplayString(_ctx.contactUs.nameOrg), 1)
    ]),
    _createElementVNode("tr", null, [
      _cache[4] || (_cache[4] = _createElementVNode("td", null, "Message", -1)),
      _createElementVNode("td", null, _toDisplayString(_ctx.contactUs.message), 1)
    ])
  ]))
}
}

})