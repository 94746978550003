import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "grid-container" }
const _hoisted_2 = { class: "m-3 min-height" }
const _hoisted_3 = { class: "back-grid" }
const _hoisted_4 = { class: "text-align-center bold-text" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }

import { ref, watch } from "vue";
import { useRoute } from "vue-router";
import AppSidebar from "@/components/user/AppSidebar.vue";
import BackButton from "@/components/user/BackButton.vue";
import AssignCategories from "@/components/admin/AssignCategories.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'CategorizeTopics',
  setup(__props) {

const route = useRoute();
let tableName = ref("");

const fetchData = (type) => {
  console.log(type);
  tableName.value = type;
};

watch(
  () => route.params.name,
  (tableName) => {
    fetchData(tableName as string);
  },
  { immediate: true }
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(AppSidebar),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(BackButton),
        _createElementVNode("h2", _hoisted_4, [
          (_unref(tableName) === 'CourseRequestTopic')
            ? (_openBlock(), _createElementBlock("span", _hoisted_5, " Subject Areas "))
            : (_openBlock(), _createElementBlock("span", _hoisted_6, "Topics of Interest "))
        ])
      ]),
      _createVNode(AssignCategories, { tableName: _unref(tableName) }, null, 8, ["tableName"])
    ])
  ]))
}
}

})