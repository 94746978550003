<template>
  <div class="grid-container">
    <app-sidebar />
    <div class="container min-height">
      <div>
        <h4>General Control Panel</h4>
        <button class="admin-button" @click="showPopup">Add an Admin</button
        ><br />
        <router-link class="admin-button" :to="{ name: 'site-statistics' }"
          >Site Statistics</router-link
        ><br />
        <router-link :to="{ name: 'field-selection' }"
          >Field Selection</router-link
        ><br />
        <router-link :to="{ name: 'database-table', params: { name: 'Users' } }"
          >Users</router-link
        ><br />
        <router-link
          :to="{ name: 'database-table', params: { name: 'Feedback' } }"
          >Feedback</router-link
        ><br />
        <router-link
          :to="{ name: 'database-table', params: { name: 'ContactUs' } }"
          >Contact Us</router-link
        ><br />
        <router-link :to="{ name: 'database-table', params: { name: 'FAQ' } }"
          >FAQ</router-link
        ><br />
      </div>
      <div>
        <h4>Instructor Control Panel</h4>
        <div class="button-container">
          <router-link
            :to="{ name: 'database-table', params: { name: 'Instructors' } }"
          >
            Instructors</router-link
          ><br />
          <router-link
            :to="{ name: 'database-table', params: { name: 'Classes' } }"
          >
            Classes</router-link
          ><br />
          <router-link
            :to="{ name: 'database-table', params: { name: 'CourseRequests' } }"
          >
            Course Requests</router-link
          ><br />
          <router-link
            :to="{
              name: 'database-table',
              params: { name: 'ResourceRequests' },
            }"
          >
            Resource Requests</router-link
          ><br />
          <router-link
            :to="{
              name: 'categorize-topics',
              params: { name: 'CourseRequestTopic' },
            }"
            >Subject Areas</router-link
          ><br />
        </div>
      </div>
      <div>
        <h4>Practitioner Control Panel</h4>
        <router-link
          :to="{ name: 'database-table', params: { name: 'Practitioners' } }"
        >
          Industry Practitioners</router-link
        ><br />
        <router-link
          :to="{ name: 'database-table', params: { name: 'Availability' } }"
        >
          Availability</router-link
        ><br />
        <router-link
          :to="{ name: 'database-table', params: { name: 'Uploads' } }"
        >
          Uploads</router-link
        ><br />
        <router-link
          :to="{ name: 'database-table', params: { name: 'Organization' } }"
          >Organization</router-link
        ><br />
        <router-link
          :to="{
            name: 'categorize-topics',
            params: { name: 'SubjectWillingToSpeakOn' },
          }"
          >Topics of Interest</router-link
        ><br />
      </div>
      <div>
        <h4>Dashboard Control Panel</h4>
        <router-link :to="{ name: 'dashboard' }"> Dashboard </router-link><br />
        <router-link
          :to="{
            name: 'database-table',
            params: { name: 'DashboardAccessRequest' },
          }"
        >
          Access Control
        </router-link>
        <span style="position: relative">
          <span class="counter"> {{ dashboardAccessCounter }} </span>
        </span>
        <br />
        <router-link
          :to="{
            name: 'database-table',
            params: { name: 'DashboardFeedback' },
          }"
        >
          Feedback </router-link
        ><br />
      </div>
    </div>
  </div>

  <PopupModal :visible="isVisible" @close="hidePopup">
    <template v-slot:title> Add an Admin</template>
    <template v-slot:content>
      <Form @submit="handleSubmit" :validation-schema="schema">
        <div class="form-group">
          <label for="email">Email</label>
          <Field name="email" type="email" class="form-control" />
          <ErrorMessage name="email" class="error-feedback" />
        </div>
        <br />

        <div class="form-group text-align-center">
          <button class="btn btn-primary btn-block" :disabled="loading">
            <span
              v-show="loading"
              class="spinner-border spinner-border-sm"
            ></span>
            Add Admin
          </button>
        </div>
      </Form>
    </template>
  </PopupModal>
</template>

<script setup lang="ts">
import { ref } from "vue";
import * as yup from "yup";
import { Form, Field, ErrorMessage } from "vee-validate";
import AppSidebar from "@/components/user/AppSidebar.vue";
import PopupModal from "../../components/user/PopupModal.vue";
import { useToast } from "vuestic-ui";
import { useAuthStore } from "@/stores/AuthStore";
import AdminService from "@/services/admin.service.js";
import { DashboardAccessRequestFields, showToast } from "@/utils";
const AuthStore = useAuthStore();

const currentUser = AuthStore.auth;
const { init, close, closeAll } = useToast();

const schema = yup.object().shape({
  email: yup
    .string()
    .required("Email is required!")
    .email("Email is invalid!")
    .max(50, "Must be maximum 50 characters!"),
});

let isVisible = ref(false);
let loading = ref(false);
let successful = ref(false);
let message = ref("");
let dashboardAccessCounter = ref(0);

const showPopup = () => {
  isVisible.value = true;
};

const hidePopup = () => {
  isVisible.value = false;
};

const handleSubmit = (user, { resetForm }) => {
  loading.value = true;
  AdminService.addAdmin(user).then(
    (res) => {
      console.log(res.data.message);
      message.value = res.data.message;
      showToast(
        "An Email has been sent to provided email address detailing further actions."
      );
      successful.value = true;
      loading.value = false;
      isVisible.value = false;
      resetForm();
    },
    (error) => {
      message.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      successful.value = false;
      loading.value = false;
    }
  );
};

const getDashboardAccessRequests = () => {
  loading.value = true;
  AdminService.getDashboardAccessRequests().then(
    (response) => {
      dashboardAccessCounter.value = response.data.filter(
        (el) => el.status === "01"
      ).length;
      loading.value = false;
    },
    (error) => {
      loading.value = false;
      message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};

getDashboardAccessRequests();
</script>

<style scoped>
.grid-container {
  display: grid;
  grid-template-columns: var(--sidebar-width) 1fr;
  column-gap: 1rem;
  position: relative;
  top: 4em;
}

.container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 0.25rem;
  text-align: center;
}

a,
.admin-button {
  display: inline-block;
  background: #ffffff;
  width: fit-content;
  padding: 0.75em;
  margin-top: 0.25rem;
  text-decoration: none;
  text-align: center;
  border-radius: 4px;
  color: black;
  line-height: 25px;
  width: 100%;
  border: none;
}

a:hover,
.admin-button:hover {
  text-decoration: none;
  background: rgba(15, 20, 25, 0.1);
  cursor: pointer;
}

.counter {
  top: -20px;
  right: -6px;
  position: absolute;
  font-size: 11px;
  line-height: 12px;
  background-color: #e41e3f;
  border: 1px solid rgb(255, 255, 255);
  border-radius: 50%;
  min-width: 16px;
  height: 16px;
  box-sizing: content-box;
  justify-content: center;
  align-items: center;
  display: flex;
  color: #fff;
}
</style>
