<script setup lang="ts">
import CourseRequestForm from "@/components/instructor/CourseRequestForm.vue";
import InstructorSidebar from "@/components/instructor/InstructorSidebar.vue";
import BackButton from "@/components/user/BackButton.vue";
import { deptOptions } from "@/utils";

import { useRouter } from "vue-router";
import { useCourseStore } from "@/stores/CourseStore";
import { useAuthStore } from "@/stores/AuthStore";

const router = useRouter();
const CourseStore = useCourseStore();
const AuthStore = useAuthStore();
const currentUser = AuthStore.auth;

let initialState = {
  academicLevel: [],
  classSize: "",
  studentDept: [] as string[],
  studentDept_text: "",
  courseCode: "",
  courseTitle: "",
  location: "",
  additionalInformation: "",
  semester: "",
  year: "",
  phone: "",
  phoneNumber: "",
  gender: "No preference",
  ageRange: "No preference",
  ethnicity: "No preference",
  yearsOfExp: "No preference",
  areaOfExpertise: "No preference",
  levelEducation: "No preference",
  typeFirm: "No preference",
  sizeFirm: "No preference",
  learning: [],
  date: [],
  time: [],
};

if (CourseStore.status) {
  initialState.academicLevel = JSON.parse(
    CourseStore?.course?.academicLevel || '[""]'
  );
  initialState.classSize = CourseStore.course.classSize;
  initialState.studentDept = JSON.parse(
    CourseStore?.course?.studentDept || '[""]'
  );
  if (
    !deptOptions.some((el) => el.value === initialState.studentDept.at(-1)!)
  ) {
    initialState.studentDept_text = initialState.studentDept.at(-1)!;
    initialState.studentDept.pop();
  }
  initialState.phone = currentUser.phone;
  initialState.phoneNumber = currentUser.phoneNumber;
  initialState.courseCode = CourseStore.course.courseCode;
  initialState.courseTitle = CourseStore.course.courseTitle;
  initialState.location = CourseStore.course.location;
  initialState.additionalInformation = CourseStore.course.additionalInformation;
  initialState.semester = CourseStore.course.semester;
  initialState.year = CourseStore.course.year;
}

const formValues = {
  academicLevel: initialState.academicLevel,
  ageRange: initialState.ageRange,
  areaOfExpertise: initialState.areaOfExpertise,
  classSize: initialState.classSize,
  date: initialState.date,
  ethnicity: initialState.ethnicity,
  gender: initialState.gender,
  learning: initialState.learning,
  levelEducation: initialState.levelEducation,
  studentDept: initialState.studentDept,
  studentDept_text: initialState.studentDept_text,
  time: initialState.time,
  yearsOfExp: initialState.yearsOfExp,
  Email: "True",
  phone: initialState.phone,
  phoneNumber: initialState.phoneNumber,
  courseCode: initialState.courseCode,
  courseTitle: initialState.courseTitle,
  location: initialState.location,
  additionalInformation: initialState.additionalInformation,
  semester: initialState.semester,
  year: initialState.year,
  typeFirm: initialState.typeFirm,
  sizeFirm: initialState.sizeFirm,
};
</script>

<style scoped></style>

<template>
  <div class="grid-container">
    <instructor-sidebar></instructor-sidebar>
    <div class="m-3 min-height">
      <div class="back-grid">
        <back-button></back-button>
        <h2 style="text-align: center; font-weight: bold">
          Course-Support Request Information
        </h2>
      </div>
      <course-request-form
        :formValues="formValues"
        action="resubmit"
      ></course-request-form>
    </div>
  </div>
</template>
