import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "grid-container" }
const _hoisted_2 = { class: "content min-height m-3" }
const _hoisted_3 = { class: "back-grid" }
const _hoisted_4 = { class: "flex flex-column lg:flex-row xl:align-items-center" }
const _hoisted_5 = { class: "flex-shrink-1 flex-grow-0 mr-3 layout-button" }
const _hoisted_6 = { class: "padding-10 mt-4" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { class: "flex flex-column xl:flex-row xl:align-items-center p-4 gap-4 flex-grow-1" }
const _hoisted_9 = ["onClick"]
const _hoisted_10 = { class: "priority" }
const _hoisted_11 = { key: 0 }
const _hoisted_12 = { key: 1 }
const _hoisted_13 = { key: 2 }
const _hoisted_14 = { key: 3 }
const _hoisted_15 = { class: "image-container" }
const _hoisted_16 = ["src"]
const _hoisted_17 = {
  key: 1,
  id: "profile-img",
  src: "//ssl.gstatic.com/accounts/ui/avatar_2x.png",
  class: "recommendation-img-card"
}
const _hoisted_18 = { class: "flex-grow-1" }
const _hoisted_19 = {
  key: 0,
  class: "flex-shrink-1 flex-grow-0 bold-text"
}
const _hoisted_20 = ["onClick"]
const _hoisted_21 = { class: "p-3 border-1 surface-border surface-card border-round cursor-pointer" }
const _hoisted_22 = ["src"]
const _hoisted_23 = {
  key: 1,
  id: "profile-img",
  src: "//ssl.gstatic.com/accounts/ui/avatar_2x.png",
  class: "recommendation-img-card"
}
const _hoisted_24 = { class: "text-align-center" }
const _hoisted_25 = {
  key: 0,
  class: "text-align-center bold-text"
}

import { ref } from "vue";
import { showToast, websiteURL } from "@/utils";
import InstructorSidebar from "@/components/instructor/InstructorSidebar.vue";
import BackButton from "@/components/user/BackButton.vue";
import RecommendationGalleryItem from "@/components/instructor/RecommendationGalleryItem.vue";
import DataView from "primevue/dataview";
import DataViewLayoutOptions from "primevue/dataviewlayoutoptions";
import { useCourseStore } from "@/stores/CourseStore";
import { useRecommendationsStore } from "@/stores/RecommendationsStore";
import { useAuthStore } from "@/stores/AuthStore";
import { useFeedStore } from "@/stores/FeedStore";
import { useRouter } from "vue-router";


export default /*@__PURE__*/_defineComponent({
  __name: 'SelectedRecommendationsView',
  setup(__props) {

const CourseStore = useCourseStore();
const RecommendationsStore = useRecommendationsStore();
const AuthStore = useAuthStore();
const FeedStore = useFeedStore();
const router = useRouter();

let loading = ref(false);
let message = ref("");
let successful = ref(false);
const currentUser = AuthStore.auth;

const course = CourseStore.course;

RecommendationsStore.setRecommendations(course);

const viewIndustryPractitioner = (course, practitioner) => {
  console.log("viewIndustryPractitioner");
  CourseStore.addCourse(course);
  RecommendationsStore.setPractitioner(practitioner.id);
  router.push({ name: "view-industry-practitioner-ins" });
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(InstructorSidebar),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(BackButton),
        _createElementVNode("div", _hoisted_4, [
          _cache[1] || (_cache[1] = _createElementVNode("div", null, [
            _createElementVNode("h2", {
              style: {"text-align":"center","font-weight":"bold"},
              class: "flex-grow-1"
            }, " Selected Practitioners ")
          ], -1)),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_unref(DataViewLayoutOptions), {
              modelValue: _unref(FeedStore).recommendationLayout,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(FeedStore).recommendationLayout) = $event))
            }, null, 8, ["modelValue"])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        (_unref(RecommendationsStore).recommendations.length > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createVNode(_unref(DataView), {
                value: _unref(RecommendationsStore).recommendations,
                layout: _unref(FeedStore).recommendationLayout
              }, {
                list: _withCtx((slotProps) => [
                  _createElementVNode("div", {
                    class: _normalizeClass(["col-12 recommendation-list", { disabled: slotProps.data.disabled }])
                  }, [
                    _createElementVNode("div", _hoisted_8, [
                      _createElementVNode("div", {
                        class: "flex flex-column xl:flex-row xl:align-items-center cursor-pointer",
                        onClick: ($event: any) => (viewIndustryPractitioner(_unref(course), slotProps.data))
                      }, [
                        _createElementVNode("div", _hoisted_10, [
                          (slotProps.index === 0)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_11, [
                                _createTextVNode(_toDisplayString(slotProps.index + 1), 1),
                                _cache[2] || (_cache[2] = _createElementVNode("sup", null, "st", -1))
                              ]))
                            : (slotProps.index === 1)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_12, [
                                  _createTextVNode(_toDisplayString(slotProps.index + 1), 1),
                                  _cache[3] || (_cache[3] = _createElementVNode("sup", null, "nd", -1))
                                ]))
                              : (slotProps.index === 2)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_13, [
                                    _createTextVNode(_toDisplayString(slotProps.index + 1), 1),
                                    _cache[4] || (_cache[4] = _createElementVNode("sup", null, "rd", -1))
                                  ]))
                                : (_openBlock(), _createElementBlock("span", _hoisted_14, [
                                    _createTextVNode(_toDisplayString(slotProps.index + 1), 1),
                                    _cache[5] || (_cache[5] = _createElementVNode("sup", null, "th", -1))
                                  ])),
                          _cache[6] || (_cache[6] = _createTextVNode(" Choice "))
                        ]),
                        _createElementVNode("div", _hoisted_15, [
                          (slotProps.data.fileName)
                            ? (_openBlock(), _createElementBlock("img", {
                                key: 0,
                                id: "profile-img",
                                src: 
                          _unref(websiteURL) +
                          'profile_picture/' +
                          slotProps.data.fileName
                        ,
                                class: "recommendation-img-card"
                              }, null, 8, _hoisted_16))
                            : (_openBlock(), _createElementBlock("img", _hoisted_17))
                        ]),
                        _createElementVNode("div", _hoisted_18, [
                          _createTextVNode(_toDisplayString(slotProps.data.title +
                        " " +
                        slotProps.data.firstName +
                        " " +
                        slotProps.data.lastName +
                        ", " +
                        slotProps.data.position +
                        " at " +
                        slotProps.data.nameOrg +
                        ", " +
                        slotProps.data.city +
                        " " +
                        slotProps.data.state) + " ", 1),
                          _cache[7] || (_cache[7] = _createElementVNode("br", null, null, -1)),
                          _createTextVNode(" Area of Specialization: " + _toDisplayString(JSON.parse(slotProps.data.expertise).join(", ")) + " ", 1),
                          _cache[8] || (_cache[8] = _createElementVNode("br", null, null, -1)),
                          _createTextVNode(" Years of Experience: " + _toDisplayString(slotProps.data.yearsOfExp) + " ", 1),
                          _cache[9] || (_cache[9] = _createElementVNode("br", null, null, -1)),
                          _createTextVNode(" Previous course-support provided: " + _toDisplayString(slotProps.data.pastExperience), 1)
                        ]),
                        (slotProps.data.status === '60')
                          ? (_openBlock(), _createElementBlock("div", _hoisted_19, " This Practitioner accepted the request. "))
                          : _createCommentVNode("", true)
                      ], 8, _hoisted_9)
                    ])
                  ], 2)
                ]),
                grid: _withCtx((slotProps) => [
                  _createElementVNode("div", {
                    class: _normalizeClass(["col-12 sm:col-6 lg:col-12 xl:col-4 p-2", { disabled: slotProps.data.disabled }]),
                    onClick: ($event: any) => (viewIndustryPractitioner(_unref(course), slotProps.data))
                  }, [
                    _createElementVNode("div", _hoisted_21, [
                      _createElementVNode("div", null, [
                        (slotProps.data.fileName)
                          ? (_openBlock(), _createElementBlock("img", {
                              key: 0,
                              id: "profile-img",
                              src: 
                        _unref(websiteURL) +
                        'profile_picture/' +
                        slotProps.data.fileName
                      ,
                              class: "recommendation-img-card"
                            }, null, 8, _hoisted_22))
                          : (_openBlock(), _createElementBlock("img", _hoisted_23))
                      ]),
                      _createElementVNode("div", _hoisted_24, [
                        _createElementVNode("div", null, _toDisplayString(slotProps.data.title +
                        " " +
                        slotProps.data.firstName +
                        " " +
                        slotProps.data.lastName), 1),
                        _createElementVNode("div", null, _toDisplayString(slotProps.data.position +
                        " at " +
                        slotProps.data.nameOrg +
                        ", " +
                        slotProps.data.city +
                        " " +
                        slotProps.data.state), 1),
                        _createElementVNode("div", null, [
                          _createTextVNode(" Area of specialization: " + _toDisplayString(JSON.parse(slotProps.data.expertise).join(", ")) + " ", 1),
                          _cache[10] || (_cache[10] = _createElementVNode("br", null, null, -1)),
                          _createTextVNode(" Years of experience: " + _toDisplayString(slotProps.data.yearsOfExp), 1)
                        ])
                      ]),
                      (slotProps.data.status === '60')
                        ? (_openBlock(), _createElementBlock("div", _hoisted_25, " This Practitioner accepted the request. "))
                        : _createCommentVNode("", true)
                    ])
                  ], 10, _hoisted_20)
                ]),
                _: 1
              }, 8, ["value", "layout"])
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}
}

})