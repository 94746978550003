import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "grid-container" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "container min-height" }
const _hoisted_5 = { class: "back-grid" }
const _hoisted_6 = { style: {"text-align":"center","font-weight":"bold"} }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { class: "col-12 sm:col-6 lg:col-3 xl:col-2 p-1" }
const _hoisted_10 = { class: "border-1 surface-border surface-card border-round cursor-pointer" }
const _hoisted_11 = ["src"]
const _hoisted_12 = {
  key: 1,
  id: "profile-img",
  src: "//ssl.gstatic.com/accounts/ui/avatar_2x.png",
  class: "recommendation-img-card"
}
const _hoisted_13 = { class: "flex flex-column sm:flex-row justify-content-between align-items-center flex-1" }
const _hoisted_14 = {
  class: "flex flex-column align-items-center gap-3 text-decoration-none",
  style: {"color":"black"}
}

import UserService from "@/services/user.service.js";
import { ref, watch } from "vue";
import { websiteURL } from "@/utils";
import { useRoute } from "vue-router";
import { useAuthStore } from "@/stores/AuthStore";
import PractitionerSidebar from "@/components/PractitionerSidebar.vue";
import InstructorSidebar from "@/components/instructor/InstructorSidebar.vue";
import BackButton from "@/components/user/BackButton.vue";
import DataView from "primevue/dataview";


export default /*@__PURE__*/_defineComponent({
  __name: 'UserListView',
  setup(__props) {

const AuthStore = useAuthStore();

const currentUser = AuthStore.auth;
const route = useRoute();
const userList = ref([]);

const getUserList = (role) => {
  UserService.getUsers(role).then(
    (response) => {
      userList.value = response.data;
    },
    (error) => {
      let res =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(res);
    }
  );
};

watch(
  () => route.params.role,
  (role) => {
    getUserList(role as string);
  },
  { immediate: true }
);

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_unref(currentUser).roles === 'INDUSTRYPRACTITIONER')
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(PractitionerSidebar)
        ]))
      : (_unref(currentUser).roles === 'INSTRUCTOR')
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(InstructorSidebar)
          ]))
        : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createVNode(BackButton),
        _createElementVNode("h2", _hoisted_6, [
          (_unref(route).params.role === 'industrypractitioner')
            ? (_openBlock(), _createElementBlock("span", _hoisted_7, " Industry Practitioners "))
            : (_openBlock(), _createElementBlock("span", _hoisted_8, "Instructors"))
        ])
      ]),
      _cache[0] || (_cache[0] = _createElementVNode("br", null, null, -1)),
      _createVNode(_unref(DataView), {
        value: userList.value,
        layout: "grid"
      }, {
        grid: _withCtx((slotProps) => [
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_router_link, {
              to: {
                name: 'user-profile',
                params: { userId: slotProps.data.id },
              }
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_10, [
                  (slotProps.data.fileName)
                    ? (_openBlock(), _createElementBlock("img", {
                        key: 0,
                        id: "profile-img",
                        src: 
                    _unref(websiteURL) + 'profile_picture/' + slotProps.data.fileName
                  ,
                        class: "recommendation-img-card"
                      }, null, 8, _hoisted_11))
                    : (_openBlock(), _createElementBlock("img", _hoisted_12)),
                  _createElementVNode("div", _hoisted_13, [
                    _createElementVNode("div", _hoisted_14, _toDisplayString(slotProps.data.firstName) + " " + _toDisplayString(slotProps.data.lastName), 1)
                  ])
                ])
              ]),
              _: 2
            }, 1032, ["to"])
          ])
        ]),
        _: 1
      }, 8, ["value"])
    ])
  ]))
}
}

})