<template>
  <Form
    @submit="saveCourse"
    :validation-schema="schema"
    v-slot="{ values, meta }"
    :initial-values="formValues"
  >
    <div class="form-column">
      <div
        class="form-group"
        v-if="!classFields.find((el) => el.name === 'courseCode')?.disabled"
      >
        <label for="courseCode"
          >Course Code <span class="super font-color-red">*</span></label
        >
        <Field name="courseCode" v-slot="{ field }">
          <input v-bind="field" class="form-control" type="text" />
          <ErrorMessage name="courseCode" class="error-feedback" />
        </Field>
      </div>
      <div
        class="form-group"
        v-if="!classFields.find((el) => el.name === 'courseTitle')?.disabled"
      >
        <label for="courseTitle"
          >Course Title <span class="super font-color-red">*</span></label
        >
        <Field name="courseTitle" v-slot="{ field }">
          <input v-bind="field" class="form-control" type="text" />
          <ErrorMessage name="courseTitle" class="error-feedback" />
        </Field>
      </div>
    </div>

    <div
      class="form-group"
      v-if="
        !classFields.find((el) => el.name === 'additionalInformation')?.disabled
      "
    >
      <label for="additionalInformation"
        >Link to Additional Course Information</label
      >
      <Field name="additionalInformation" v-slot="{ field }">
        <input
          v-bind="field"
          class="form-control"
          type="text"
          placeholder="https://"
        />
        <ErrorMessage name="additionalInformation" class="error-feedback" />
      </Field>
    </div>

    <div
      class="form-group"
      v-if="!classFields.find((el) => el.name === 'academicLevel')?.disabled"
    >
      <label for="academicLevel"
        >Students' Academic Level<span class="super font-color-red"
          >*</span
        ></label
      >
      <Field
        v-model="values.academicLevel"
        name="academicLevel"
        type="text"
        class="dropdown"
        v-slot="{ field }"
      >
        <multiselect
          v-bind="field"
          v-model="values.academicLevel"
          :options="academicLevelOptions"
          mode="tags"
          :searchable="true"
          :multipleLabel="displayLabels"
          :hideSelected="false"
          :closeOnSelect="false"
          :closeOnDeselect="false"
        />
      </Field>
      <ErrorMessage name="academicLevel" class="error-feedback" />
    </div>
    <div
      class="form-group"
      v-if="!classFields.find((el) => el.name === 'studentDept')?.disabled"
    >
      <label for="studentDept">
        Students' Department or Program of Study<span
          class="super font-color-red"
          >*</span
        ></label
      >
      <Field
        v-model="values.studentDept"
        name="studentDept"
        type="text"
        class="dropdown"
        v-slot="{ field }"
      >
        <multiselect
          v-bind="field"
          v-model="values.studentDept"
          :options="deptOptions"
          mode="tags"
          :searchable="true"
          :multipleLabel="displayLabels"
          :hideSelected="false"
          :closeOnSelect="false"
          :closeOnDeselect="false"
        />
      </Field>
      <div v-if="values.studentDept && values.studentDept.includes('Others')">
        <Field
          name="studentDept_text"
          type="text"
          placeholder="Please Specify"
          class="form-control bg-others"
        />
      </div>
      <ErrorMessage name="studentDept" class="error-feedback" />
    </div>

    <div
      class="form-group"
      v-if="!classFields.find((el) => el.name === 'location')?.disabled"
    >
      <label for="location"
        >Location of Classroom
        <span class="super font-color-red">*</span>
        <span class="font-color-yellow"
          >(Actual Address Including Room Number)</span
        ></label
      >
      <Field name="location" v-slot="{ field }">
        <input v-bind="field" class="form-control" type="text" />
        <ErrorMessage name="location" class="error-feedback" />
      </Field>
    </div>

    <div
      class="form-group"
      v-if="!classFields.find((el) => el.name === 'classSize')?.disabled"
    >
      <label for="classSize"
        >Class Size<span class="super font-color-red">*</span></label
      >
      <Field name="classSize" v-slot="{ field }">
        <input v-bind="field" class="form-control" type="text" />
        <ErrorMessage name="classSize" class="error-feedback" />
      </Field>
    </div>

    <div
      class="form-column"
      v-if="!classFields.find((el) => el.name === 'semester')?.disabled"
    >
      <div class="form-group">
        <label for="semester"
          >Semester <span class="super font-color-red">*</span></label
        >
        <Field name="semester" type="text" class="dropdown" v-slot="{ field }">
          <Multiselect
            v-bind="field"
            v-model="values.semester"
            :options="['Fall', 'Spring', 'Summer', 'Winter']"
            mode="single"
            :searchable="true"
          />
        </Field>
        <ErrorMessage name="semester" class="error-feedback" />
      </div>

      <div
        class="form-group"
        v-if="!classFields.find((el) => el.name === 'year')?.disabled"
      >
        <label for="year"
          >Year <span class="super font-color-red">*</span></label
        >
        <Field name="year" type="text" class="dropdown" v-slot="{ field }">
          <Multiselect
            v-bind="field"
            v-model="values.year"
            :options="years"
            mode="single"
            :searchable="true"
          />
        </Field>
        <ErrorMessage name="year" class="error-feedback" />
      </div>
    </div>

    <br />
    <div class="form-group text-align-center">
      <button
        class="btn btn-primary btn-block"
        :disabled="loading || !meta.valid"
      >
        <span v-show="loading" class="spinner-border spinner-border-sm"></span>
        <span v-if="props.action === 'update'">Update Class</span>
        <span v-else>Add Class</span>
      </button>
    </div>
  </Form>
</template>

<script setup lang="ts">
import { ref } from "vue";
import * as yup from "yup";
import { useConfigStore } from "@/stores/ConfigStore";
import InstructorService from "@/services/instructor.service.js";
import Multiselect from "@vueform/multiselect";
import { Form, Field, ErrorMessage } from "vee-validate";
import { deptOptions, academicLevelOptions, displayLabels } from "@/utils";
import { Course } from "@/types";

const ConfigStore = useConfigStore();

let message = ref("");
let loading = ref(false);
let years: number[] = [];
// eslint-disable-next-line no-undef
const props = defineProps<{
  successAction: any;
  action: string;
  course: Course;
}>();

const classFields = ConfigStore.config.filter((el) => el.table === "course");

let currentYear = new Date().getFullYear();
for (let i = 0; i < 16; i++) {
  years.push(currentYear + i);
}

let initialState = {
  courseCode: "",
  courseTitle: "",
  academicLevel: [] as string[],
  studentDept: [] as string[],
  studentDept_text: "",
  classSize: "",
  semester: "",
  year: "",
  additionalInformation: "",
  location: "",
};

if (props.action === "update") {
  initialState.academicLevel = JSON.parse(props.course.academicLevel);
  initialState.studentDept = JSON.parse(props.course.studentDept);
  if (
    !deptOptions.some((el) => el.value === initialState.studentDept.at(-1)!)
  ) {
    initialState.studentDept_text = initialState.studentDept.at(-1)!;
    initialState.studentDept.pop();
  }
}

const formValues = {
  courseCode: props.course?.courseCode,
  courseTitle: props.course?.courseTitle,
  academicLevel: initialState.academicLevel,
  studentDept: initialState.studentDept,
  classSize: props.course?.classSize,
  semester: props.course?.semester,
  year: props.course?.year,
  additionalInformation: props.course?.additionalInformation,
  location: props.course?.location,
};

const schema = yup.object().shape({
  fields: yup.array().default(classFields),
  courseCode: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "courseCode")?.disabled,
    then: (schema) =>
      schema
        .required("Course Code is required!")
        .max(10, "Course Code cannot be greater than 10 characters"),
    otherwise: (schema) => schema.optional().nullable().min(0),
  }),
  courseTitle: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "courseTitle")?.disabled,
    then: (schema) =>
      schema
        .required("Course Title is required!")
        .max(254, "Course Title cannot be greater than 255 characters"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  academicLevel: yup
    .array()
    .of(yup.string())
    .when("fields", {
      is: (val) => !val.find((el) => el.name === "academicLevel")?.disabled,
      then: (schema) =>
        schema
          .required("Students' academic level is required!")
          .min(1, "Students' academic level is required!"),
      otherwise: (schema) => schema.optional().min(0).nullable(),
    }),
  studentDept: yup
    .array()
    .of(yup.string())
    .when("fields", {
      is: (val) => !val.find((el) => el.name === "studentDept")?.disabled,
      then: (schema) =>
        schema
          .required("Students' department or Program of study is required!")
          .min(1, "Students' department or Program of study is required!"),
      otherwise: (schema) => schema.optional().min(0).nullable(),
    }),
  classSize: yup
    .number()
    .label("Class Size")
    .when("fields", {
      is: (val) => !val.find((el) => el.name === "classSize")?.disabled,
      then: (schema) =>
        schema
          .typeError("Class Size must be a number!")
          .positive()
          .integer()
          .required("Class Size is required!"),
      otherwise: (schema) => schema.optional().nullable(),
    }),
  semester: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "semester")?.disabled,
    then: (schema) => schema.required("Semester is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  year: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "year")?.disabled,
    then: (schema) => schema.required("Year is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  additionalInformation: yup.string().optional().nullable(),
  location: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "location")?.disabled,
    then: (schema) =>
      schema
        .required("Location of Classroom is required!")
        .max(
          100,
          "Location of Classroom cannot be greater than 100 characters"
        ),
    otherwise: (schema) => schema.optional().nullable(),
  }),
});

const saveCourse = (values) => {
  loading.value = true;
  if (values?.studentDept?.includes("Others"))
    values.studentDept.push(values["studentDept_text"]);
  if (props.action === "update") {
    values.id = props.course.id;
    console.log(values.id);
  }
  InstructorService.saveCourse(values, props.action).then(
    (response) => {
      console.log(response);
      props.successAction();
    },
    (error) => {
      loading.value = false;
      message.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};
</script>

<style scoped></style>
