import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "min-height header-space mx-3" }
const _hoisted_2 = { class: "m-3" }
const _hoisted_3 = { class: "back-grid" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = {
  key: 1,
  class: "my-5 text-align-center d-block"
}
const _hoisted_6 = ["disabled"]
const _hoisted_7 = {
  key: 0,
  class: "font-italic"
}

import { ref } from "vue";
import AppSidebar from "@/components/user/AppSidebar.vue";
import BackButton from "@/components/user/BackButton.vue";
import DashboardMap from "@/components/user/DashboardMap.vue";
import { useAuthStore } from "@/stores/AuthStore";
import UserService from "@/services/user.service.js";
import { showToast, showErrorToast } from "@/utils";


export default /*@__PURE__*/_defineComponent({
  __name: 'DashboardView',
  setup(__props) {

const AuthStore = useAuthStore();
const currentUser = AuthStore.auth;
let loading = ref(false);
let message = ref("");
let dashboardAccessRequestExists = ref(false);

const requestDashboardAccess = () => {
  loading.value = true;
  UserService.requestDashboardAccess().then(
    (response) => {
      showToast("Request sent to admin successfully!");
      dashboardAccessRequestExists.value = true;
      loading.value = false;
    },
    (error) => {
      loading.value = false;
      message.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      showErrorToast(message.value);
    }
  );
};

const checkDashboardAccessRequestExists = () => {
  UserService.checkDashboardAccessRequestExists(currentUser.id).then(
    (response) => {
      console.log(response);
      if (response.status == "200")
        dashboardAccessRequestExists.value =
          response.data.dashboardAccessRequestExists;
    },
    (error) => {
      message.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(message.value);
    }
  );
};
if (currentUser.roles !== "ADMIN") checkDashboardAccessRequestExists();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(BackButton),
        _cache[0] || (_cache[0] = _createElementVNode("h2", { class: "text-align-center bold-text" }, "ConPEC Dashboard", -1))
      ])
    ]),
    (_unref(currentUser).dashboardAccess)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(DashboardMap)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _cache[1] || (_cache[1] = _createElementVNode("h4", { style: {"overflow-wrap":"anywhere"} }, " Sorry, you do not have permission to access the dashboard. You can request access from the Admin. ", -1)),
          _createElementVNode("button", {
            class: "btn btn-primary mt-3",
            onClick: requestDashboardAccess,
            disabled: _unref(dashboardAccessRequestExists)
          }, " Request access from Admin ", 8, _hoisted_6),
          (_unref(dashboardAccessRequestExists))
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, " Request sent to Admin "))
            : _createCommentVNode("", true)
        ]))
  ]))
}
}

})