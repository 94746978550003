import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createVNode as _createVNode, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, vShow as _vShow, withDirectives as _withDirectives, resolveComponent as _resolveComponent, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from '@/assets/images/site/instructor.png'
import _imports_1 from '@/assets/images/site/practitioner.png'
import _imports_2 from '@/assets/images/site/mail-tick.png'


const _hoisted_1 = { class: "header-space min-height" }
const _hoisted_2 = {
  key: 0,
  class: "card card-container"
}
const _hoisted_3 = { class: "form-group" }
const _hoisted_4 = { class: "form-check-inline" }
const _hoisted_5 = { class: "form-check-inline lg:ml-3" }
const _hoisted_6 = { class: "form-group" }
const _hoisted_7 = {
  key: 0,
  class: "form-group"
}
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { key: 2 }
const _hoisted_10 = { class: "form-group" }
const _hoisted_11 = { class: "form-group" }
const _hoisted_12 = { class: "form-group" }
const _hoisted_13 = { for: "email" }
const _hoisted_14 = {
  key: 0,
  class: "font-color-blue"
}
const _hoisted_15 = {
  key: 1,
  class: "font-color-blue"
}
const _hoisted_16 = { class: "position-relative" }
const _hoisted_17 = { class: "registration-hint" }
const _hoisted_18 = { class: "form-group" }
const _hoisted_19 = { class: "position-relative" }
const _hoisted_20 = { class: "registration-hint" }
const _hoisted_21 = { class: "form-group" }
const _hoisted_22 = { style: {"font-size":"small","text-align":"center"} }
const _hoisted_23 = { class: "form-group text-align-center" }
const _hoisted_24 = ["disabled"]
const _hoisted_25 = { class: "spinner-border spinner-border-sm" }
const _hoisted_26 = {
  key: 0,
  class: "w-100 text-align-center"
}
const _hoisted_27 = {
  key: 1,
  class: "text-align-center m-3 min-height"
}
const _hoisted_28 = { class: "mt-5" }
const _hoisted_29 = {
  key: 0,
  class: "px-2 lg:px-8"
}
const _hoisted_30 = { key: 1 }

import { ref } from "vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import Multiselect from "@vueform/multiselect";
import Password from "primevue/password";
import * as yup from "yup";
import { useAuthStore } from "@/stores/AuthStore";
import { useRouter } from "vue-router";
import AuthService from "@/services/auth.service.js";
import {
  practitionerTitleOptions,
  instructorTitleOptions,
  showErrorToast,
  showToast,
} from "@/utils";
import { useConfigStore } from "@/stores/ConfigStore";


export default /*@__PURE__*/_defineComponent({
  __name: 'RegistrationView',
  setup(__props) {

const ConfigStore = useConfigStore();
const AuthStore = useAuthStore();
const router = useRouter();

const registrationFields = ConfigStore.config.filter(
  (el) => el.table === "registration"
);

let loading = ref(false);
let successful = ref(false);
let message = ref("");
let msgCode = ref("");
let passwordToolTipVisible = ref(false);
let emailToolTipVisible = ref(false);

const schema = yup.object().shape({
  fields: yup.array().default(registrationFields),
  email: yup
    .string()
    .required("Email is required!")
    .email("Email is invalid!")
    .max(50, "Must be maximum 50 characters!"),
  password: yup
    .string()
    .required("Password is required!")
    .min(8, "Must be at least 8 characters!")
    .max(40, "Must be maximum 40 characters!")
    .matches(
      /^.*(?=.{8,})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      "Password must contain at least 8 characters, one uppercase, and one number"
    ),
  // ethnicity: yup.string().required("Ethnicity is required!"),
  roles: yup.string().required("User Type is required!"),
  title: yup.string().required("Title is required!"),
  title_text: yup.string().when("title", {
    is: (val) => val === "Others",
    then: (schema) => schema.required("Title is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  firstName: yup.string().required("First Name is required!"),
  lastName: yup.string().required("Last Name is required!"),
  confirmPassword: yup
    .string()
    .required("Confirm your password!")
    .oneOf([yup.ref("password")], "Those passwords didn’t match. Try again."),
});

const resendVerification = () => {
  loading.value = true;
  AuthService.resendVerification(message.value).then(
    (res) => {
      showToast(
        "Verification Mail Sent successfully. Please check your inbox."
      );
      loading.value = false;
    },
    (error) => {
      message.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      showErrorToast(message.value);
      loading.value = false;
    }
  );
};

const handleRegister = (user) => {
  loading.value = true;
  if (user.title === "Others") user.title = user["title_text"];

  AuthService.register(user).then(
    (res) => {
      console.log(res);
      message.value = res.data.email;
      msgCode.value = res.data.code;
      successful.value = true;
      loading.value = false;
    },
    (error) => {
      message.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      successful.value = false;
      loading.value = false;
    }
  );
};

const inFocus = () => {
  emailToolTipVisible.value = true;
};

const outFocus = () => {
  emailToolTipVisible.value = false;
};

const inFocusPassword = () => {
  passwordToolTipVisible.value = true;
};

const outFocusPassword = () => {
  passwordToolTipVisible.value = false;
};

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_unref(successful))
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _cache[24] || (_cache[24] = _createElementVNode("h4", { class: "text-align-center bold-text" }, "User Registration", -1)),
          _cache[25] || (_cache[25] = _createElementVNode("br", null, null, -1)),
          _cache[26] || (_cache[26] = _createElementVNode("span", { class: "font-color-red" }, "* required field", -1)),
          _createVNode(_unref(Form), {
            onSubmit: handleRegister,
            "validation-schema": _unref(schema)
          }, {
            default: _withCtx(({ values }) => [
              _createElementVNode("div", null, [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("div", _hoisted_4, [
                    _cache[0] || (_cache[0] = _createElementVNode("label", { for: "roles" }, [
                      _createTextVNode("User Type"),
                      _createElementVNode("span", { class: "super font-color-red" }, "*")
                    ], -1)),
                    _createVNode(_unref(Field), {
                      name: "roles",
                      class: "form-check-input",
                      type: "radio",
                      value: "Instructor"
                    }),
                    _cache[1] || (_cache[1] = _createElementVNode("img", {
                      src: _imports_0,
                      class: "sidebar-image-xl ml-2 mr-1"
                    }, null, -1)),
                    _cache[2] || (_cache[2] = _createTextVNode("Instructor "))
                  ]),
                  _createElementVNode("div", _hoisted_5, [
                    _createVNode(_unref(Field), {
                      name: "roles",
                      class: "form-check-input",
                      type: "radio",
                      value: "IndustryPractitioner"
                    }),
                    _cache[3] || (_cache[3] = _createElementVNode("img", {
                      src: _imports_1,
                      class: "sidebar-image-xl ml-2 mr-1"
                    }, null, -1)),
                    _cache[4] || (_cache[4] = _createTextVNode("Industry Practitioner "))
                  ]),
                  _createVNode(_unref(ErrorMessage), {
                    name: "roles",
                    class: "error-feedback"
                  })
                ]),
                _createElementVNode("div", _hoisted_6, [
                  (values.roles === 'IndustryPractitioner')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                        _cache[5] || (_cache[5] = _createElementVNode("label", { for: "title" }, [
                          _createTextVNode("Title"),
                          _createElementVNode("span", { class: "super font-color-red" }, "*")
                        ], -1)),
                        _createVNode(_unref(Field), {
                          modelValue: values.title,
                          "onUpdate:modelValue": ($event: any) => ((values.title) = $event),
                          name: "title",
                          type: "text",
                          class: "dropdown"
                        }, {
                          default: _withCtx(({ field }) => [
                            _createVNode(_unref(Multiselect), _mergeProps(field, {
                              modelValue: values.title,
                              "onUpdate:modelValue": ($event: any) => ((values.title) = $event),
                              options: _unref(practitionerTitleOptions),
                              mode: "single",
                              searchable: true
                            }), null, 16, ["modelValue", "onUpdate:modelValue", "options"])
                          ]),
                          _: 2
                        }, 1032, ["modelValue", "onUpdate:modelValue"])
                      ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_8, [
                        _cache[6] || (_cache[6] = _createElementVNode("label", { for: "title" }, [
                          _createTextVNode("Title"),
                          _createElementVNode("span", { class: "super font-color-red" }, "*")
                        ], -1)),
                        _createVNode(_unref(Field), {
                          modelValue: values.title,
                          "onUpdate:modelValue": ($event: any) => ((values.title) = $event),
                          name: "title",
                          type: "text",
                          class: "dropdown"
                        }, {
                          default: _withCtx(({ field }) => [
                            _createVNode(_unref(Multiselect), _mergeProps(field, {
                              modelValue: values.title,
                              "onUpdate:modelValue": ($event: any) => ((values.title) = $event),
                              options: _unref(instructorTitleOptions),
                              mode: "single",
                              searchable: true
                            }), null, 16, ["modelValue", "onUpdate:modelValue", "options"])
                          ]),
                          _: 2
                        }, 1032, ["modelValue", "onUpdate:modelValue"])
                      ])),
                  (values.title === 'Others')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                        _createVNode(_unref(Field), {
                          name: "title_text",
                          type: "text",
                          placeholder: "Please Specify",
                          class: "form-control bg-others"
                        })
                      ]))
                    : _createCommentVNode("", true),
                  _createVNode(_unref(ErrorMessage), {
                    name: "title",
                    class: "error-feedback"
                  })
                ]),
                _createElementVNode("div", _hoisted_10, [
                  _cache[7] || (_cache[7] = _createElementVNode("label", { for: "firstName" }, [
                    _createTextVNode("First Name"),
                    _createElementVNode("span", { class: "super font-color-red" }, "*")
                  ], -1)),
                  _createVNode(_unref(Field), {
                    name: "firstName",
                    type: "text",
                    class: "form-control"
                  }),
                  _createVNode(_unref(ErrorMessage), {
                    name: "firstName",
                    class: "error-feedback"
                  })
                ]),
                _createElementVNode("div", _hoisted_11, [
                  _cache[8] || (_cache[8] = _createElementVNode("label", { for: "lastName" }, [
                    _createTextVNode("Last Name"),
                    _createElementVNode("span", { class: "super font-color-red" }, "*")
                  ], -1)),
                  _createVNode(_unref(Field), {
                    name: "lastName",
                    type: "text",
                    class: "form-control"
                  }),
                  _createVNode(_unref(ErrorMessage), {
                    name: "lastName",
                    class: "error-feedback"
                  })
                ]),
                _createElementVNode("div", _hoisted_12, [
                  _createElementVNode("label", _hoisted_13, [
                    _cache[9] || (_cache[9] = _createTextVNode("Email")),
                    _cache[10] || (_cache[10] = _createElementVNode("span", { class: "super font-color-red" }, "*", -1)),
                    (values.roles === 'IndustryPractitioner')
                      ? (_openBlock(), _createElementBlock("span", _hoisted_14, " (Please use your work/company email address) "))
                      : (values.roles === 'Instructor')
                        ? (_openBlock(), _createElementBlock("span", _hoisted_15, " (Please use your institutional email address) "))
                        : _createCommentVNode("", true)
                  ]),
                  _createVNode(_unref(Field), {
                    name: "email",
                    type: "email",
                    class: "form-control",
                    onFocusin: _withModifiers(inFocus, ["prevent"]),
                    onFocusout: _withModifiers(outFocus, ["prevent"])
                  }),
                  _createVNode(_unref(ErrorMessage), {
                    name: "email",
                    class: "error-feedback"
                  })
                ]),
                _createElementVNode("div", _hoisted_16, [
                  _withDirectives(_createElementVNode("div", _hoisted_17, _cache[11] || (_cache[11] = [
                    _createElementVNode("i", null, null, -1),
                    _createElementVNode("span", {
                      class: "speech_bubble tool_tip_text",
                      id: "tooltip",
                      style: {"display":"inline"}
                    }, [
                      _createElementVNode("p", { style: {"margin-bottom":"0px"} }, " You will be required to verify your email. ")
                    ], -1)
                  ]), 512), [
                    [_vShow, _unref(emailToolTipVisible)]
                  ])
                ]),
                _createElementVNode("div", _hoisted_18, [
                  _cache[12] || (_cache[12] = _createElementVNode("label", { for: "password" }, [
                    _createTextVNode("Password"),
                    _createElementVNode("span", { class: "super font-color-red" }, "*")
                  ], -1)),
                  _createVNode(_unref(Field), {
                    name: "password",
                    modelValue: values.password,
                    "onUpdate:modelValue": ($event: any) => ((values.password) = $event),
                    class: "form-control"
                  }, {
                    default: _withCtx(({ field }) => [
                      _createVNode(_unref(Password), _mergeProps({
                        modelValue: values.password,
                        "onUpdate:modelValue": ($event: any) => ((values.password) = $event)
                      }, field, {
                        toggleMask: "",
                        feedback: false,
                        onFocusin: _withModifiers(inFocusPassword, ["prevent"]),
                        onFocusout: _withModifiers(outFocusPassword, ["prevent"]),
                        class: "w-100"
                      }), null, 16, ["modelValue", "onUpdate:modelValue"])
                    ]),
                    _: 2
                  }, 1032, ["modelValue", "onUpdate:modelValue"]),
                  _createVNode(_unref(ErrorMessage), {
                    name: "password",
                    class: "error-feedback"
                  })
                ]),
                _createElementVNode("div", _hoisted_19, [
                  _withDirectives(_createElementVNode("div", _hoisted_20, _cache[13] || (_cache[13] = [
                    _createElementVNode("i", null, null, -1),
                    _createElementVNode("span", {
                      class: "speech_bubble tool_tip_text",
                      id: "tooltip",
                      style: {"display":"inline"}
                    }, [
                      _createElementVNode("div", { style: {"margin-bottom":"0px"} }, [
                        _createTextVNode(" Your password should be ATLEAST 8 characters long with a minimum of: "),
                        _createElementVNode("ul", null, [
                          _createElementVNode("li", null, "1 number"),
                          _createElementVNode("li", null, "1 uppercase letter"),
                          _createElementVNode("li", null, "1 lowercase letter")
                        ])
                      ])
                    ], -1)
                  ]), 512), [
                    [_vShow, _unref(passwordToolTipVisible)]
                  ])
                ]),
                _createElementVNode("div", _hoisted_21, [
                  _cache[14] || (_cache[14] = _createElementVNode("label", { for: "confirmPassword" }, [
                    _createTextVNode("Confirm Password"),
                    _createElementVNode("span", { class: "super font-color-red" }, "*")
                  ], -1)),
                  _createVNode(_unref(Field), {
                    name: "confirmPassword",
                    modelValue: values.confirmPassword,
                    "onUpdate:modelValue": ($event: any) => ((values.confirmPassword) = $event),
                    class: "form-control"
                  }, {
                    default: _withCtx(({ field }) => [
                      _createVNode(_unref(Password), _mergeProps({
                        modelValue: values.confirmPassword,
                        "onUpdate:modelValue": ($event: any) => ((values.confirmPassword) = $event)
                      }, field, {
                        toggleMask: "",
                        feedback: false,
                        class: "w-100"
                      }), null, 16, ["modelValue", "onUpdate:modelValue"])
                    ]),
                    _: 2
                  }, 1032, ["modelValue", "onUpdate:modelValue"]),
                  _createVNode(_unref(ErrorMessage), {
                    name: "confirmPassword",
                    class: "error-feedback"
                  })
                ]),
                _cache[21] || (_cache[21] = _createElementVNode("br", null, null, -1)),
                _createElementVNode("div", _hoisted_22, [
                  _cache[17] || (_cache[17] = _createTextVNode(" By clicking Agree & Join, you agree to the ConPEC ")),
                  _createVNode(_component_router_link, {
                    to: { name: 'user-agreement' },
                    target: "_blank"
                  }, {
                    default: _withCtx(() => _cache[15] || (_cache[15] = [
                      _createTextVNode("User Agreement")
                    ])),
                    _: 1
                  }),
                  _cache[18] || (_cache[18] = _createTextVNode(" and ")),
                  _createVNode(_component_router_link, {
                    to: { name: 'privacy-policy' },
                    target: "_blank"
                  }, {
                    default: _withCtx(() => _cache[16] || (_cache[16] = [
                      _createTextVNode("Privacy Policy")
                    ])),
                    _: 1
                  }),
                  _cache[19] || (_cache[19] = _createTextVNode(". "))
                ]),
                _cache[22] || (_cache[22] = _createElementVNode("br", null, null, -1)),
                _createElementVNode("div", _hoisted_23, [
                  _createElementVNode("button", {
                    class: "btn btn-primary btn-block",
                    disabled: _unref(loading)
                  }, [
                    _withDirectives(_createElementVNode("span", _hoisted_25, null, 512), [
                      [_vShow, _unref(loading)]
                    ]),
                    _cache[20] || (_cache[20] = _createTextVNode(" Agree & Join "))
                  ], 8, _hoisted_24)
                ])
              ])
            ]),
            _: 1
          }, 8, ["validation-schema"]),
          (_unref(message))
            ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
                _cache[23] || (_cache[23] = _createElementVNode("br", null, null, -1)),
                _createElementVNode("span", {
                  class: _normalizeClass(["alert", _unref(successful) ? 'alert-success' : 'alert-danger'])
                }, _toDisplayString(_unref(message)), 3)
              ]))
            : _createCommentVNode("", true)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_27, [
          _cache[39] || (_cache[39] = _createElementVNode("div", {
            class: "mt-3",
            style: {"font-size":"5rem"}
          }, [
            _createElementVNode("img", {
              src: _imports_2,
              class: "site-mail-verification-image"
            })
          ], -1)),
          _cache[40] || (_cache[40] = _createElementVNode("h2", {
            class: "mt-3",
            style: {"font-weight":"500"}
          }, " Verify your email to continue ", -1)),
          _createElementVNode("div", _hoisted_28, [
            _cache[29] || (_cache[29] = _createTextVNode(" Your security is our priority. ")),
            _cache[30] || (_cache[30] = _createElementVNode("br", null, null, -1)),
            (_unref(msgCode) === 'REACTIVATE')
              ? (_openBlock(), _createElementBlock("div", _hoisted_29, _cache[27] || (_cache[27] = [
                  _createTextVNode(" You had a previous account associated with this email ID."),
                  _createElementVNode("br", null, null, -1),
                  _createTextVNode(" To reactivate your previous account, an email has been sent to your email address."),
                  _createElementVNode("br", null, null, -1),
                  _createTextVNode(" Kindly click on the verification link in the email message to verify your email ID and reactivate your account. ")
                ])))
              : (_openBlock(), _createElementBlock("div", _hoisted_30, " Please check your inbox at " + _toDisplayString(_unref(message)) + " and verify your email to enjoy all the benefits of ConPec. ", 1)),
            _cache[31] || (_cache[31] = _createElementVNode("span", { class: "bold-text" }, " Please if you cannot find the verification email in your inbox, please check your junk or spam folder", -1)),
            _cache[32] || (_cache[32] = _createElementVNode("br", null, null, -1)),
            _cache[33] || (_cache[33] = _createElementVNode("br", null, null, -1)),
            _cache[34] || (_cache[34] = _createTextVNode(" Didn't receive a verification email? ")),
            _cache[35] || (_cache[35] = _createElementVNode("br", null, null, -1)),
            _createElementVNode("button", {
              class: "btn btn-outline-dark",
              onClick: resendVerification
            }, " Resend Verification Email "),
            _cache[36] || (_cache[36] = _createElementVNode("br", null, null, -1)),
            _cache[37] || (_cache[37] = _createElementVNode("br", null, null, -1)),
            _cache[38] || (_cache[38] = _createTextVNode(" Need Help? ")),
            _createVNode(_component_router_link, { to: { name: 'contact-us' } }, {
              default: _withCtx(() => _cache[28] || (_cache[28] = [
                _createTextVNode("Contact Support ")
              ])),
              _: 1
            })
          ])
        ]))
  ]))
}
}

})