import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 1 }

import { useRouter } from "vue-router";
import { useAuthStore } from "@/stores/AuthStore";


export default /*@__PURE__*/_defineComponent({
  __name: 'BackButton',
  setup(__props) {

const router = useRouter();
const AuthStore = useAuthStore();

const goBack = () => {
  router.go(-1);
};

return (_ctx: any,_cache: any) => {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("span", null, [
    (_unref(AuthStore).profile || _unref(AuthStore).role === 'ADMIN')
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          onClick: goBack
        }, [
          _createVNode(_component_font_awesome_icon, {
            class: "text-sm lg:text-4xl",
            icon: "fa-solid fa-chevron-left",
            title: "Go Back"
          }),
          _cache[0] || (_cache[0] = _createElementVNode("span", { class: "back-button-text" }, " Back ", -1))
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_1))
  ]))
}
}

})