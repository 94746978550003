import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, mergeProps as _mergeProps, vShow as _vShow, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createStaticVNode as _createStaticVNode } from "vue"
import _imports_0 from '@/assets/images/logo.png'
import _imports_1 from '@/assets/images/site/web-security.png'


const _hoisted_1 = { class: "header-space min-height" }
const _hoisted_2 = { class: "flex home-container" }
const _hoisted_3 = { class: "mt-3 logo-container" }
const _hoisted_4 = { class: "text-align-center" }
const _hoisted_5 = {
  class: "mt-3",
  style: {"font-size":"larger"}
}
const _hoisted_6 = { class: "mt-2 form-container" }
const _hoisted_7 = { class: "card card-container" }
const _hoisted_8 = { class: "form-group" }
const _hoisted_9 = { class: "form-group" }
const _hoisted_10 = { class: "form-group mt-2" }
const _hoisted_11 = { class: "form-group text-align-center" }
const _hoisted_12 = ["disabled"]
const _hoisted_13 = { class: "spinner-border spinner-border-sm" }
const _hoisted_14 = {
  key: 0,
  class: "mt-3"
}
const _hoisted_15 = {
  class: "alert alert-danger",
  role: "alert"
}
const _hoisted_16 = {
  class: "mt-3",
  style: {"color":"#1a73e8"}
}
const _hoisted_17 = { class: "mt-5 text-align-center fs-1-5" }
const _hoisted_18 = ["disabled"]
const _hoisted_19 = { class: "moving-text-container" }
const _hoisted_20 = {
  key: 0,
  class: "feedback-container"
}
const _hoisted_21 = { class: "flex justify-content-between" }
const _hoisted_22 = { class: "text-align-center feedback-text-container" }
const _hoisted_23 = { class: "row feedback-content" }
const _hoisted_24 = { class: "text-align-center" }
const _hoisted_25 = { class: "customer-name mt-3 mb-1" }

import { ref } from "vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import Password from "primevue/password";
import UserService from "@/services/user.service.js";
import { useAuthStore } from "@/stores/AuthStore";
import { useCourseStore } from "@/stores/CourseStore";
import { useRoute, useRouter } from "vue-router";


export default /*@__PURE__*/_defineComponent({
  __name: 'HomeView',
  setup(__props) {

const route = useRoute();
const router = useRouter();
const AuthStore = useAuthStore();
const CourseStore = useCourseStore();

const schema = yup.object().shape({
  username: yup
    .string()
    .email("Please Enter a valid email address")
    .required("Email is required!"),
  password: yup.string().required("Password is required!"),
});

let loading = ref(false);
let message = ref("");
let loginMessage = ref("");
let coeff = 1000 * 60 * 5;

const handleLogin = (user) => {
  loading.value = true;
  AuthStore.login(user).then(
    (res) => {
      console.log(res);
      if (route.query["redirect"]) {
        handleRedirect(route.query["redirect"]);
      } else if (AuthStore.role === "INSTRUCTOR")
        if (AuthStore.profile) router.push({ name: "instructor-feed" });
        else router.push({ name: "instructor-profile" });
      else if (AuthStore.role === "INDUSTRYPRACTITIONER")
        if (AuthStore.profile)
          router.push({ name: "industry-practitioner-feed" });
        else router.push({ name: "industry-practitioner-profile" });
      else if (AuthStore.role === "ADMIN") router.push({ name: "admin-feed" });
      else router.push("/home");
    },
    (error) => {
      console.log(error);
      let activationTime = error.response?.data?.activationTime;
      if (activationTime) {
        if (new Date(activationTime) > new Date(2029, 0, 1, 0, 0, 0)) {
          loginMessage.value =
            "Your account has been deactivated by the admin.";
        } else {
          let date = new Date(activationTime);
          let rounded = new Date(Math.ceil(date.getTime() / coeff) * coeff);
          loginMessage.value =
            "Your account has been deactivated by the admin. Your account will become active by " +
            rounded.toLocaleDateString() +
            " at " +
            new Date().toLocaleTimeString();
        }
      } else {
        loginMessage.value =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
      loading.value = false;
    }
  );
};

let currentIndex = ref(0);
let content = ref([]);
let instructorOrgList = ref([]);
let practitionerOrgList = ref([]);

const getFeedback = () => {
  UserService.getFeedback().then(
    (response) => {
      content.value = response.data;
    },
    (error) => {
      message.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};

const getOrgList = (roleType) => {
  UserService.getOrgList(roleType).then(
    (response) => {
      if (roleType == "1") instructorOrgList.value = response.data;
      else practitionerOrgList.value = response.data;
    },
    (error) => {
      message.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};

const handleRedirect = async (redirectURL) => {
  const notificationId = redirectURL.split("/").pop();
  console.log(notificationId);
  UserService.getNotification(notificationId).then(
    (response) => {
      const notification = response.data;
      notification.course_request.user = notification.user;
      CourseStore.addCourse(notification.course_request);
      if (AuthStore.role === "INDUSTRYPRACTITIONER")
        router.push({ name: "view-course-request-prac" });
      else if (AuthStore.role === "INSTRUCTOR") {
        CourseStore.setPractitioner(notification.user.id);
        router.push({ name: "view-industry-practitioner-ins" });
      } else router.push("/home");
    },
    (error) => {
      loading.value = false;
      router.push("/home");
    }
  );
};

getFeedback();
getOrgList("1");
getOrgList("2");

const moveToNext = () => {
  currentIndex.value = (currentIndex.value + 1) % content.value.length;
};

const moveToPrev = () => {
  if (currentIndex.value === 0) currentIndex.value = content.value.length - 1;
  else currentIndex.value = (currentIndex.value - 1) % content.value.length;
};

setInterval(() => {
  moveToNext();
}, 5000);

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_marquee = _resolveComponent("marquee")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _cache[14] || (_cache[14] = _createStaticVNode("<img id=\"profile-img\" style=\"width:50%;height:50%;\" class=\"m-auto\" src=\"" + _imports_0 + "\" data-v-72adcc76><p style=\"font-size:1.5rem;\" data-v-72adcc76> ...<span class=\"bold-text logo-color\" data-v-72adcc76>Con</span>necting <span class=\"bold-text logo-color\" data-v-72adcc76>P</span>rofessional and <span class=\"bold-text logo-color\" data-v-72adcc76>E</span>ducational <span class=\"bold-text logo-color\" data-v-72adcc76>C</span>ommunities for Future Workforce Development. </p>", 2)),
          _createElementVNode("div", _hoisted_5, [
            _cache[2] || (_cache[2] = _createTextVNode(" Are you a practitioner that wants to give back?")),
            _cache[3] || (_cache[3] = _createElementVNode("br", null, null, -1)),
            _cache[4] || (_cache[4] = _createTextVNode(" Do you want to contribute to future workforce development?")),
            _cache[5] || (_cache[5] = _createElementVNode("br", null, null, -1)),
            _cache[6] || (_cache[6] = _createTextVNode(" If yes, then join us today.")),
            _createVNode(_component_router_link, { to: "/register" }, {
              default: _withCtx(() => _cache[0] || (_cache[0] = [
                _createTextVNode(" Sign up")
              ])),
              _: 1
            }),
            _cache[7] || (_cache[7] = _createTextVNode(" to connect with instructors seeking practitioners to support their pedagogical efforts in preparing students for the industry.")),
            _cache[8] || (_cache[8] = _createElementVNode("br", null, null, -1)),
            _cache[9] || (_cache[9] = _createElementVNode("br", null, null, -1)),
            _cache[10] || (_cache[10] = _createTextVNode(" Are you an instructor with practical course-support needs that practitioners can meet?")),
            _cache[11] || (_cache[11] = _createElementVNode("br", null, null, -1)),
            _cache[12] || (_cache[12] = _createTextVNode(" If yes, then ")),
            _createVNode(_component_router_link, { to: "/register" }, {
              default: _withCtx(() => _cache[1] || (_cache[1] = [
                _createTextVNode("Sign up")
              ])),
              _: 1
            }),
            _cache[13] || (_cache[13] = _createTextVNode(" today to get connected to a diverse pool of practitioners willing to meet your practical course-support needs. "))
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_unref(Form), {
            onSubmit: handleLogin,
            "validation-schema": _unref(schema)
          }, {
            default: _withCtx(({ values }) => [
              _createElementVNode("div", _hoisted_8, [
                _cache[15] || (_cache[15] = _createElementVNode("label", { for: "username" }, "Email", -1)),
                _createVNode(_unref(Field), {
                  name: "username",
                  type: "text",
                  class: "form-control"
                }),
                _createVNode(_unref(ErrorMessage), {
                  name: "username",
                  class: "error-feedback"
                })
              ]),
              _createElementVNode("div", _hoisted_9, [
                _cache[16] || (_cache[16] = _createElementVNode("label", { for: "password" }, "Password", -1)),
                _createVNode(_unref(Field), {
                  name: "password",
                  modelValue: values.password,
                  "onUpdate:modelValue": ($event: any) => ((values.password) = $event),
                  class: "form-control"
                }, {
                  default: _withCtx(({ field }) => [
                    _createVNode(_unref(Password), _mergeProps({
                      modelValue: values.password,
                      "onUpdate:modelValue": ($event: any) => ((values.password) = $event)
                    }, field, {
                      toggleMask: "",
                      feedback: false,
                      class: "w-100"
                    }), null, 16, ["modelValue", "onUpdate:modelValue"])
                  ]),
                  _: 2
                }, 1032, ["modelValue", "onUpdate:modelValue"]),
                _createVNode(_unref(ErrorMessage), {
                  name: "password",
                  class: "error-feedback"
                })
              ]),
              _createElementVNode("div", _hoisted_10, [
                _createVNode(_unref(Field), {
                  value: "True",
                  "unchecked-value": "False",
                  name: "keepSignedIn",
                  type: "checkbox"
                }),
                _cache[17] || (_cache[17] = _createTextVNode(" Keep me Signed In ")),
                _cache[18] || (_cache[18] = _createElementVNode("br", null, null, -1))
              ]),
              _cache[21] || (_cache[21] = _createElementVNode("br", null, null, -1)),
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("button", {
                  class: "btn btn-primary btn-block",
                  disabled: _unref(loading)
                }, [
                  _withDirectives(_createElementVNode("span", _hoisted_13, null, 512), [
                    [_vShow, _unref(loading)]
                  ]),
                  _cache[19] || (_cache[19] = _createElementVNode("span", null, "Login", -1))
                ], 8, _hoisted_12),
                (_unref(loginMessage))
                  ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                      _createElementVNode("div", _hoisted_15, _toDisplayString(_unref(loginMessage)), 1)
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_16, [
                  _createVNode(_component_router_link, {
                    to: "/forgotPassword",
                    class: "nav-link"
                  }, {
                    default: _withCtx(() => _cache[20] || (_cache[20] = [
                      _createElementVNode("span", null, "Forgot Password?", -1)
                    ])),
                    _: 1
                  })
                ])
              ])
            ]),
            _: 1
          }, 8, ["validation-schema"]),
          _createElementVNode("div", _hoisted_17, [
            _cache[23] || (_cache[23] = _createTextVNode(" Are You New Here? ")),
            _cache[24] || (_cache[24] = _createElementVNode("br", null, null, -1)),
            _createElementVNode("button", {
              class: "btn btn-primary btn-block",
              style: {"margin":"10px 0px"},
              disabled: _unref(loading)
            }, [
              _createVNode(_component_router_link, {
                to: "/register",
                class: "nav-link"
              }, {
                default: _withCtx(() => _cache[22] || (_cache[22] = [
                  _createTextVNode(" Sign Up ")
                ])),
                _: 1
              })
            ], 8, _hoisted_18)
          ])
        ]),
        _cache[25] || (_cache[25] = _createElementVNode("img", {
          style: {"width":"20%","height":"20%"},
          class: "m-auto",
          src: _imports_1
        }, null, -1)),
        _cache[26] || (_cache[26] = _createElementVNode("div", { class: "text-align-center" }, [
          _createElementVNode("h6", { class: "mx-auto" }, "Secured web")
        ], -1))
      ])
    ]),
    _createElementVNode("div", _hoisted_19, [
      _createVNode(_component_marquee, { direction: "right" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(Object.values(_unref(practitionerOrgList).map((el) => el.nameOrg)).join(
            "            "
          )), 1)
        ]),
        _: 1
      })
    ]),
    (_unref(content).length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
          _cache[30] || (_cache[30] = _createElementVNode("p", { class: "text-align-center feedback-heading" }, " What users are saying about us. ", -1)),
          _createElementVNode("div", _hoisted_21, [
            _createElementVNode("div", {
              class: "flex flex-grow-0 flex-shrink-1 align-items-center",
              style: {"margin":"auto"}
            }, [
              _createElementVNode("button", {
                type: "button",
                class: "feedback-btn btn",
                onClick: moveToPrev
              }, _cache[27] || (_cache[27] = [
                _createElementVNode("svg", {
                  xmlns: "http://www.w3.org/2000/svg",
                  width: "48",
                  height: "48",
                  viewBox: "0 0 48 48",
                  fill: "none",
                  stroke: "#000",
                  "stroke-width": "3",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round",
                  class: "vertical-align-sub"
                }, [
                  _createElementVNode("polyline", { points: "27,36 9,18 27,0" })
                ], -1)
              ])),
              _cache[28] || (_cache[28] = _createTextVNode("  "))
            ]),
            _createElementVNode("div", _hoisted_22, [
              _createElementVNode("div", _hoisted_23, [
                _createElementVNode("p", null, _toDisplayString(_unref(content)[_unref(currentIndex)].message), 1)
              ])
            ]),
            _createElementVNode("div", {
              class: "flex flex-grow-0 flex-shrink-1 align-items-center",
              style: {"margin":"auto"}
            }, [
              _createElementVNode("button", {
                type: "button",
                class: "feedback-btn btn",
                onClick: moveToNext
              }, _cache[29] || (_cache[29] = [
                _createElementVNode("svg", {
                  xmlns: "http://www.w3.org/2000/svg",
                  width: "48",
                  height: "48",
                  viewBox: "0 -10 48 48",
                  fill: "none",
                  stroke: "#000",
                  "stroke-width": "3",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round",
                  class: "vertical-align-super"
                }, [
                  _createElementVNode("polyline", { points: "9,36 27,18 9,0" })
                ], -1)
              ]))
            ])
          ]),
          _createElementVNode("div", _hoisted_24, [
            _createElementVNode("p", _hoisted_25, _toDisplayString(_unref(content)[_unref(currentIndex)].user.firstName +
            " " +
            _unref(content)[_unref(currentIndex)].user.lastName), 1),
            _createElementVNode("p", null, [
              _createElementVNode("span", null, _toDisplayString(_unref(content)[_unref(currentIndex)].user.nameOrg), 1)
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})