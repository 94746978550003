import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, isRef as _isRef, vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow } from "vue"
import _imports_0 from '@/assets/images/uploads/general_resource.jpg'
import _imports_1 from '@/assets/images/uploads/pdf.png'
import _imports_2 from '@/assets/images/uploads/ppt.png'
import _imports_3 from '@/assets/images/uploads/docx.png'
import _imports_4 from '@/assets/images/uploads/txt.png'


const _hoisted_1 = { class: "flex flex-direction-column lg:flex-row" }
const _hoisted_2 = { class: "mr-6" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { class: "min-h" }
const _hoisted_7 = { class: "gallery" }
const _hoisted_8 = { class: "image-container" }
const _hoisted_9 = ["src", "alt", "onClick"]
const _hoisted_10 = ["href"]
const _hoisted_11 = ["alt"]
const _hoisted_12 = { class: "desc" }
const _hoisted_13 = ["href"]
const _hoisted_14 = ["onClick"]
const _hoisted_15 = ["onClick"]
const _hoisted_16 = { key: 1 }
const _hoisted_17 = { key: 0 }
const _hoisted_18 = { key: 1 }
const _hoisted_19 = { class: "gallery" }
const _hoisted_20 = {
  key: 0,
  controls: ""
}
const _hoisted_21 = ["src", "type"]
const _hoisted_22 = ["href"]
const _hoisted_23 = ["alt"]
const _hoisted_24 = { class: "desc" }
const _hoisted_25 = ["onClick"]
const _hoisted_26 = ["onClick"]
const _hoisted_27 = { key: 2 }
const _hoisted_28 = { key: 0 }
const _hoisted_29 = { key: 1 }
const _hoisted_30 = { class: "gallery" }
const _hoisted_31 = { class: "document-container" }
const _hoisted_32 = {
  key: 0,
  class: "doc-image-container",
  style: {"cursor":"default"}
}
const _hoisted_33 = {
  key: 0,
  src: _imports_1,
  class: "doc-image"
}
const _hoisted_34 = {
  key: 1,
  src: _imports_2,
  class: "doc-image"
}
const _hoisted_35 = {
  key: 2,
  src: _imports_3,
  class: "doc-image"
}
const _hoisted_36 = {
  key: 3,
  src: _imports_4,
  class: "doc-image"
}
const _hoisted_37 = {
  key: 1,
  class: "doc-image-container overflow-hidden"
}
const _hoisted_38 = ["href"]
const _hoisted_39 = ["alt"]
const _hoisted_40 = { class: "desc" }
const _hoisted_41 = ["href"]
const _hoisted_42 = ["onClick"]
const _hoisted_43 = ["onClick"]
const _hoisted_44 = { class: "paginator" }
const _hoisted_45 = { style: {"display":"grid","grid-template-columns":"3fr 1fr"} }
const _hoisted_46 = { style: {"text-align":"right"} }
const _hoisted_47 = ["href"]
const _hoisted_48 = {
  class: "flex justify-content-center",
  style: {"height":"70vh"}
}
const _hoisted_49 = ["src"]
const _hoisted_50 = { class: "form-group" }
const _hoisted_51 = { class: "form-group text-align-center" }
const _hoisted_52 = ["disabled"]
const _hoisted_53 = { class: "spinner-border spinner-border-sm" }
const _hoisted_54 = { class: "form-group" }
const _hoisted_55 = { class: "form-group text-align-center" }
const _hoisted_56 = ["disabled"]
const _hoisted_57 = { class: "spinner-border spinner-border-sm" }

import { ref } from "vue";
import { Upload } from "@/types";
import heic2any from "heic2any";
import UserService from "@/services/user.service.js";
import Dialog from "primevue/dialog";
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import { showToast, websiteURL } from "@/utils";
import { useAuthStore } from "@/stores/AuthStore";


export default /*@__PURE__*/_defineComponent({
  __name: 'ResourcesListTabs',
  props: {
    type: {}
  },
  setup(__props: any) {

const AuthStore = useAuthStore();

const showCustomContent = ref(false);
const downloadSrc = ref("");
const imgSrc = ref("");
const imgDesc = ref("");

const currentUser = AuthStore.auth;
const contentId = ref(-1);
const visible = ref(false);
const reportDialogVisible = ref(false);
let searchParameter = ref("");
let loading = ref(false);
let message = ref("");
let size = ref(10);
let currTabIndex = ref(0);

// eslint-disable-next-line no-undef
const props = __props;

const schema = yup.object().shape({
  reasonDelete: yup.string().required("Reason for deletion is required!"),
});

const reportDialogSchema = yup.object().shape({
  reasonReport: yup
    .string()
    .required("Reason for reporting content is required!"),
});

const tabs = ref([
  {
    title: "Pictures",
    icon: "fa-solid fa-image",
    value: "Project Pictures",
    currentPage: 1,
    totalPages: 1,
    data: [],
  },
  {
    title: "Videos",
    icon: "fa-solid fa-video",
    value: "Video",
    currentPage: 1,
    totalPages: 1,
    data: [],
  },
  {
    title: "Documents",
    icon: "fa-solid fa-file",
    value: "Document",
    currentPage: 1,
    totalPages: 1,
    data: [],
  },
]);

const handleSubmission = (data) => {
  loading.value = true;
  data.contentId = contentId.value;
  UserService.deleteContent(data).then(
    () => {
      window.location.reload();
      showToast("Content deleted successfully.");
      visible.value = false;
      loading.value = false;
    },
    (error) => {
      loading.value = false;
      message.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};

const handleReportDialogSubmission = (data) => {
  loading.value = true;
  data.contentId = contentId.value;
  UserService.reportContent(data).then(
    () => {
      showToast("Content Reported successfully.");
      getUploads();
      reportDialogVisible.value = false;
      loading.value = false;
    },
    (error) => {
      loading.value = false;
      message.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};

const getUploads = (index = currTabIndex.value) => {
  if (searchParameter.value.length > 0) {
    search(currTabIndex.value);
  } else {
    if (props.type === "private") {
      getMyUploads(index);
    } else {
      getPublicResources(index);
    }
  }
};

const getPublicResources = (index) => {
  UserService.getPublicResources(
    tabs.value[index].currentPage,
    tabs.value[index].value,
    size.value
  ).then(
    (response) => {
      tabs.value[index].data = response.data.uploads;
      tabs.value[index].currentPage = response.data.currentPage;
      tabs.value[index].totalPages = response.data.totalPages;
    },
    (error) => {
      message.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};

const getMyUploads = (index) => {
  UserService.getMyUploads(
    tabs.value[index].currentPage,
    tabs.value[index].value,
    size.value
  ).then(
    (response) => {
      tabs.value[index].data = response.data.uploads;
      tabs.value[index].currentPage = response.data.currentPage;
      tabs.value[index].totalPages = response.data.totalPages;
    },
    (error) => {
      message.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};

const search = (index) => {
  UserService.searchResources(
    searchParameter.value,
    props.type,
    tabs.value[index].currentPage,
    tabs.value[index].value,
    size.value
  ).then(
    (response) => {
      tabs.value[index].data = response.data.uploads;
      tabs.value[index].currentPage = response.data.currentPage;
      tabs.value[index].totalPages = response.data.totalPages;
    },
    (error) => {
      message.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};

const changePageSize = () => {
  tabs.value.forEach((el, index) => getUploads(index));
};

const showImage = (src, desc, downloadPath) => {
  imgSrc.value = src;
  imgDesc.value = desc;
  downloadSrc.value = downloadPath;
  showCustomContent.value = true;
};

const myFunc = (e) => {
  searchParameter.value = "";
  getUploads(e);
  console.log(e);
  console.log("Anubhav 2");
};

getUploads();
//tabs.value.forEach((el, index) => getUploads(index));

const onClick = () => {
  getUploads();
};

return (_ctx: any,_cache: any) => {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_va_tab = _resolveComponent("va-tab")!
  const _component_va_tabs = _resolveComponent("va-tabs")!
  const _component_va_pagination = _resolveComponent("va-pagination")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _withDirectives(_createElementVNode("input", {
          name: "searchParameter",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(searchParameter) ? (searchParameter).value = $event : searchParameter = $event)),
          type: "text",
          placeholder: "Search Resources",
          class: "search-bar"
        }, null, 512), [
          [_vModelText, _unref(searchParameter)]
        ]),
        _createElementVNode("button", {
          onClick: getUploads,
          class: "search-button button",
          title: "Search"
        }, [
          _createVNode(_component_font_awesome_icon, { icon: "fa-solid fa-magnifying-glass" })
        ])
      ]),
      _createElementVNode("div", null, [
        _createVNode(_unref(Field), {
          name: "size",
          as: "select",
          class: "dropdown w-auto ml-auto mr-3",
          modelValue: _unref(size),
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_isRef(size) ? (size).value = $event : size = $event)),
          onchange: changePageSize
        }, {
          default: _withCtx(() => _cache[7] || (_cache[7] = [
            _createElementVNode("option", { value: "10" }, "10", -1),
            _createElementVNode("option", { value: "20" }, "20", -1),
            _createElementVNode("option", { value: "30" }, "30", -1),
            _createElementVNode("option", { value: "40" }, "40", -1),
            _createElementVNode("option", { value: "50" }, "50", -1)
          ])),
          _: 1
        }, 8, ["modelValue"])
      ])
    ]),
    _createVNode(_component_va_tabs, {
      grow: "",
      modelValue: _unref(currTabIndex),
      "onUpdate:modelValue": [
        _cache[2] || (_cache[2] = ($event: any) => (_isRef(currTabIndex) ? (currTabIndex).value = $event : currTabIndex = $event)),
        myFunc
      ],
      class: "mb-3 mt-3"
    }, {
      tabs: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(tabs.value, (tab) => {
          return (_openBlock(), _createBlock(_component_va_tab, {
            key: tab.title
          }, {
            default: _withCtx(() => [
              _createVNode(_component_font_awesome_icon, {
                icon: tab.icon
              }, null, 8, ["icon"]),
              _createTextVNode("   " + _toDisplayString(tab.title), 1)
            ]),
            _: 2
          }, 1024))
        }), 128))
      ]),
      _: 1
    }, 8, ["modelValue"]),
    (_unref(currTabIndex) === 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (tabs.value[_unref(currTabIndex)].data.length === 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, _cache[8] || (_cache[8] = [
                _createElementVNode("h5", {
                  style: {"margin":"1em"},
                  class: "text-align-center"
                }, " No Pictures Uploaded Yet ", -1)
              ])))
            : (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(tabs.value[_unref(currTabIndex)].data, (item) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: "responsive",
                      key: item.id
                    }, [
                      _createElementVNode("div", _hoisted_7, [
                        _createElementVNode("div", _hoisted_8, [
                          (item.filePath)
                            ? (_openBlock(), _createElementBlock("img", {
                                key: 0,
                                src: _unref(websiteURL) + item.filePath.substring(9),
                                alt: item.file,
                                onClick: ($event: any) => (
                  showImage(
                    _unref(websiteURL) + item.filePath.substring(9),
                    item.description,
                    _unref(websiteURL) + item.origFilePath.substring(9)
                  )
                )
                              }, null, 8, _hoisted_9))
                            : (_openBlock(), _createElementBlock("a", {
                                key: 1,
                                href: item.linkResources,
                                target: "_blank"
                              }, [
                                _createElementVNode("img", {
                                  src: _imports_0,
                                  alt: item.linkResources
                                }, null, 8, _hoisted_11)
                              ], 8, _hoisted_10))
                        ]),
                        _createElementVNode("div", _hoisted_12, [
                          _createElementVNode("p", null, _toDisplayString(item.description.slice(0, 25) +
                  (item.description.length > 25 ? "..." : "")), 1),
                          (!JSON.parse(item.visibility).includes('All'))
                            ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                                key: 0,
                                icon: "fa-solid fa-lock",
                                class: "ml-1 mr-2",
                                title: "This content is restricted."
                              }))
                            : _createCommentVNode("", true),
                          (props.type === 'public' && item.filePath)
                            ? (_openBlock(), _createElementBlock("a", {
                                key: 1,
                                href: _unref(websiteURL) + item.origFilePath.substring(9),
                                download: "",
                                title: "Download",
                                style: {"color":"var(--bs-body-color)"}
                              }, [
                                _createVNode(_component_font_awesome_icon, { icon: "fa-solid fa-download" })
                              ], 8, _hoisted_13))
                            : _createCommentVNode("", true),
                          (props.type === 'private')
                            ? (_openBlock(), _createElementBlock("button", {
                                key: 2,
                                onClick: ($event: any) => ((visible.value = true), (contentId.value = item.id)),
                                style: {"color":"var(--bs-body-color)","border":"none"},
                                class: "bg-transparent"
                              }, [
                                _createVNode(_component_font_awesome_icon, { icon: "fa-solid fa-trash" })
                              ], 8, _hoisted_14))
                            : _createCommentVNode("", true),
                          (props.type === 'public')
                            ? (_openBlock(), _createElementBlock("button", {
                                key: 3,
                                onClick: ($event: any) => ((reportDialogVisible.value = true), (contentId.value = item.id)),
                                style: {"color":"var(--bs-body-color)","border":"none"},
                                class: "ml-2 bg-transparent",
                                title: "Flag as Inappropriate"
                              }, [
                                _createVNode(_component_font_awesome_icon, { icon: "fa-solid fa-flag" })
                              ], 8, _hoisted_15))
                            : _createCommentVNode("", true)
                        ])
                      ])
                    ]))
                  }), 128))
                ]),
                _cache[9] || (_cache[9] = _createElementVNode("div", { class: "clearfix" }, null, -1))
              ]))
        ]))
      : _createCommentVNode("", true),
    (_unref(currTabIndex) === 1)
      ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
          (tabs.value[_unref(currTabIndex)].data.length === 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_17, _cache[10] || (_cache[10] = [
                _createElementVNode("h5", {
                  style: {"margin":"1em"},
                  class: "text-align-center"
                }, " No Videos Uploaded Yet ", -1)
              ])))
            : (_openBlock(), _createElementBlock("div", _hoisted_18, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(tabs.value[_unref(currTabIndex)].data, (item) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "responsive",
                    key: item.id
                  }, [
                    _createElementVNode("div", _hoisted_19, [
                      (item.filePath)
                        ? (_openBlock(), _createElementBlock("video", _hoisted_20, [
                            _createElementVNode("source", {
                              src: _unref(websiteURL) + item.filePath.substring(9),
                              type: item['mimetype']
                            }, null, 8, _hoisted_21)
                          ]))
                        : (_openBlock(), _createElementBlock("a", {
                            key: 1,
                            href: item.linkResources,
                            target: "_blank"
                          }, [
                            _createElementVNode("img", {
                              src: _imports_0,
                              alt: item.linkResources
                            }, null, 8, _hoisted_23)
                          ], 8, _hoisted_22)),
                      _createElementVNode("div", _hoisted_24, [
                        _createElementVNode("p", null, _toDisplayString(item.description.slice(0, 25) +
                (item.description.length > 25 ? "..." : "")), 1),
                        (!JSON.parse(item.visibility).includes('All'))
                          ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                              key: 0,
                              icon: "fa-solid fa-lock",
                              class: "ml-1 mr-2",
                              title: "This content is restricted."
                            }))
                          : _createCommentVNode("", true),
                        (props.type === 'private')
                          ? (_openBlock(), _createElementBlock("button", {
                              key: 1,
                              onClick: ($event: any) => ((visible.value = true), (contentId.value = item.id)),
                              style: {"color":"var(--bs-body-color)","border":"none"}
                            }, [
                              _createVNode(_component_font_awesome_icon, { icon: "fa-solid fa-trash" })
                            ], 8, _hoisted_25))
                          : (_openBlock(), _createElementBlock("button", {
                              key: 2,
                              onClick: ($event: any) => ((reportDialogVisible.value = true), (contentId.value = item.id)),
                              style: {"color":"var(--bs-body-color)","border":"none"},
                              class: "bg-transparent",
                              title: "Flag as Inappropriate"
                            }, [
                              _createVNode(_component_font_awesome_icon, { icon: "fa-solid fa-flag" })
                            ], 8, _hoisted_26))
                      ])
                    ])
                  ]))
                }), 128)),
                _cache[11] || (_cache[11] = _createElementVNode("div", { class: "clearfix" }, null, -1))
              ]))
        ]))
      : _createCommentVNode("", true),
    (_unref(currTabIndex) === 2)
      ? (_openBlock(), _createElementBlock("div", _hoisted_27, [
          (tabs.value[_unref(currTabIndex)].data.length === 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_28, _cache[12] || (_cache[12] = [
                _createElementVNode("h5", {
                  style: {"margin":"1em"},
                  class: "text-align-center"
                }, " No Documents Uploaded Yet ", -1)
              ])))
            : (_openBlock(), _createElementBlock("div", _hoisted_29, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(tabs.value[_unref(currTabIndex)].data, (item) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "responsive",
                    key: item.id
                  }, [
                    _createElementVNode("div", _hoisted_30, [
                      _createElementVNode("div", _hoisted_31, [
                        (item.filePath)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_32, [
                              (item.mimetype === 'application/pdf')
                                ? (_openBlock(), _createElementBlock("img", _hoisted_33))
                                : (
                  item.mimetype ===
                    'application/vnd.openxmlformats-officedocument.presentationml.presentation' ||
                  item.mimetype === 'application/mspowerpoint'
                )
                                  ? (_openBlock(), _createElementBlock("img", _hoisted_34))
                                  : (
                  item.mimetype ===
                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
                  item.mimetype === 'application/msword'
                )
                                    ? (_openBlock(), _createElementBlock("img", _hoisted_35))
                                    : (_openBlock(), _createElementBlock("img", _hoisted_36))
                            ]))
                          : (_openBlock(), _createElementBlock("div", _hoisted_37, [
                              _createElementVNode("a", {
                                href: item.linkResources,
                                target: "_blank"
                              }, [
                                _createElementVNode("img", {
                                  src: _imports_0,
                                  alt: item.linkResources,
                                  style: {"width":"100%","height":"100%","overflow":"hidden"}
                                }, null, 8, _hoisted_39)
                              ], 8, _hoisted_38)
                            ])),
                        _createElementVNode("div", _hoisted_40, [
                          _createElementVNode("p", null, _toDisplayString(item.description.slice(0, 25) +
                  (item.description.length > 25 ? "..." : "")), 1),
                          (!JSON.parse(item.visibility).includes('All'))
                            ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                                key: 0,
                                icon: "fa-solid fa-lock",
                                class: "ml-1 mr-2",
                                title: "This content is restricted."
                              }))
                            : _createCommentVNode("", true),
                          (_unref(currentUser).roles === 'INSTRUCTOR' && item.filePath)
                            ? (_openBlock(), _createElementBlock("a", {
                                key: 1,
                                href: _unref(websiteURL) + item.filePath.substring(9),
                                download: "",
                                title: "Download",
                                style: {"color":"var(--bs-body-color)"}
                              }, [
                                _createVNode(_component_font_awesome_icon, { icon: "fa-solid fa-download" })
                              ], 8, _hoisted_41))
                            : _createCommentVNode("", true),
                          (_unref(currentUser).roles === 'INDUSTRYPRACTITIONER')
                            ? (_openBlock(), _createElementBlock("button", {
                                key: 2,
                                onClick: ($event: any) => ((visible.value = true), (contentId.value = item.id)),
                                style: {"color":"var(--bs-body-color)","border":"none"}
                              }, [
                                _cache[13] || (_cache[13] = _createTextVNode("   ")),
                                _createVNode(_component_font_awesome_icon, { icon: "fa-solid fa-trash" })
                              ], 8, _hoisted_42))
                            : _createCommentVNode("", true),
                          (props.type === 'public')
                            ? (_openBlock(), _createElementBlock("button", {
                                key: 3,
                                onClick: ($event: any) => ((reportDialogVisible.value = true), (contentId.value = item.id)),
                                style: {"color":"var(--bs-body-color)","border":"none"},
                                class: "ml-2 bg-transparent",
                                title: "Flag as Inappropriate"
                              }, [
                                _createVNode(_component_font_awesome_icon, { icon: "fa-solid fa-flag" })
                              ], 8, _hoisted_43))
                            : _createCommentVNode("", true)
                        ])
                      ])
                    ])
                  ]))
                }), 128)),
                _cache[14] || (_cache[14] = _createElementVNode("div", { class: "clearfix" }, null, -1))
              ]))
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_44, [
      _createVNode(_component_va_pagination, {
        modelValue: tabs.value[_unref(currTabIndex)].currentPage,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((tabs.value[_unref(currTabIndex)].currentPage) = $event)),
        pages: tabs.value[_unref(currTabIndex)].totalPages,
        "visible-pages": 3,
        hideOnSinglePage: true,
        onClick: onClick
      }, null, 8, ["modelValue", "pages"])
    ]),
    _createVNode(_unref(Dialog), {
      visible: showCustomContent.value,
      "onUpdate:visible": _cache[4] || (_cache[4] = ($event: any) => ((showCustomContent).value = $event)),
      modal: "",
      header: imgDesc.value,
      style: { width: '50vw' }
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_45, [
          _cache[15] || (_cache[15] = _createElementVNode("div", null, null, -1)),
          _createElementVNode("div", _hoisted_46, [
            (_unref(currentUser).roles === 'INSTRUCTOR')
              ? (_openBlock(), _createElementBlock("a", {
                  key: 0,
                  href: downloadSrc.value,
                  download: "",
                  title: "Download",
                  style: {"color":"var(--bs-body-color)"}
                }, [
                  _createVNode(_component_font_awesome_icon, { icon: "fa-solid fa-download" })
                ], 8, _hoisted_47))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("div", _hoisted_48, [
          _createElementVNode("img", {
            src: imgSrc.value,
            class: "model-img",
            autofocus: ""
          }, null, 8, _hoisted_49)
        ])
      ]),
      _: 1
    }, 8, ["visible", "header"]),
    _createVNode(_unref(Dialog), {
      visible: visible.value,
      "onUpdate:visible": _cache[5] || (_cache[5] = ($event: any) => ((visible).value = $event)),
      modal: "",
      header: " ",
      style: { width: '30vw' }
    }, {
      default: _withCtx(() => [
        _createVNode(_unref(Form), {
          onSubmit: handleSubmission,
          "validation-schema": _unref(schema)
        }, {
          default: _withCtx(({ meta }) => [
            _createElementVNode("div", _hoisted_50, [
              _cache[16] || (_cache[16] = _createElementVNode("label", { for: "reasonDelete" }, "Please provide a reason for deleting the uploaded content", -1)),
              _createVNode(_unref(Field), {
                name: "reasonDelete",
                type: "text",
                class: "form-control"
              }),
              _createVNode(_unref(ErrorMessage), {
                name: "reasonDelete",
                class: "error-feedback"
              })
            ]),
            _cache[18] || (_cache[18] = _createElementVNode("br", null, null, -1)),
            _createElementVNode("div", _hoisted_51, [
              _createElementVNode("button", {
                class: "btn btn-primary btn-block",
                disabled: _unref(loading) || !meta.valid
              }, [
                _withDirectives(_createElementVNode("span", _hoisted_53, null, 512), [
                  [_vShow, _unref(loading)]
                ]),
                _cache[17] || (_cache[17] = _createElementVNode("span", null, "Delete", -1))
              ], 8, _hoisted_52)
            ])
          ]),
          _: 1
        }, 8, ["validation-schema"])
      ]),
      _: 1
    }, 8, ["visible"]),
    _createVNode(_unref(Dialog), {
      visible: reportDialogVisible.value,
      "onUpdate:visible": _cache[6] || (_cache[6] = ($event: any) => ((reportDialogVisible).value = $event)),
      modal: "",
      header: " ",
      style: { width: '41vw' }
    }, {
      default: _withCtx(() => [
        _createVNode(_unref(Form), {
          onSubmit: handleReportDialogSubmission,
          "validation-schema": _unref(reportDialogSchema)
        }, {
          default: _withCtx(({ meta }) => [
            _createElementVNode("div", _hoisted_54, [
              _cache[19] || (_cache[19] = _createElementVNode("label", { for: "reasonDelete" }, "Please provide a reason for reporting the uploaded content", -1)),
              _createVNode(_unref(Field), {
                name: "reasonReport",
                type: "text",
                class: "form-control"
              }),
              _createVNode(_unref(ErrorMessage), {
                name: "reasonReport",
                class: "error-feedback"
              })
            ]),
            _cache[21] || (_cache[21] = _createElementVNode("br", null, null, -1)),
            _createElementVNode("div", _hoisted_55, [
              _createElementVNode("button", {
                class: "btn btn-primary btn-block",
                disabled: _unref(loading) || !meta.valid
              }, [
                _withDirectives(_createElementVNode("span", _hoisted_57, null, 512), [
                  [_vShow, _unref(loading)]
                ]),
                _cache[20] || (_cache[20] = _createElementVNode("span", null, "Report", -1))
              ], 8, _hoisted_56)
            ])
          ]),
          _: 1
        }, 8, ["validation-schema"])
      ]),
      _: 1
    }, 8, ["visible"])
  ], 64))
}
}

})