<template>
  <header v-if="AuthStore.status" class="fixed left-0 right-0 top-0 p-1 header">
    <div class="flex flex-direction-row align-center list-style-type-none">
      <li v-if="AuthStore.role == 'INSTRUCTOR'" class="lg:ml-3 mt-auto mb-auto">
        <router-link
          :to="{ name: 'instructor-feed' }"
          class="inline-block pl-2 pr-2"
        >
          <img
            style="width: 3em; height: 3em"
            title="Home"
            src="@/assets/images/logo_cropped.png"
          />
        </router-link>
      </li>
      <li
        v-else-if="AuthStore.role == 'INDUSTRYPRACTITIONER'"
        class="lg:ml-3 mt-auto mb-auto"
      >
        <router-link
          :to="{ name: 'industry-practitioner-feed' }"
          class="inline-block pl-2 pr-2"
        >
          <img
            style="width: 3em; height: 3em"
            title="Home"
            src="@/assets/images/logo_cropped.png"
          />
        </router-link>
      </li>
      <li v-else-if="AuthStore.role == 'ADMIN'" class="lg:ml-3 my-auto">
        <router-link
          :to="{ name: 'admin-feed' }"
          class="inline-block pl-2 pr-2"
        >
          <img
            style="width: 3em; height: 3em"
            title="Home"
            src="@/assets/images/logo_cropped.png"
          />
        </router-link>
      </li>
      <li
        v-if="
          AuthStore.role == 'INDUSTRYPRACTITIONER' ||
          AuthStore.role == 'INSTRUCTOR'
        "
        class="ml-1"
      >
        <router-link
          :to="{ name: 'view-uploads' }"
          class="nav-item nav-link rounded-2"
        >
          <font-awesome-icon
            icon="fa-solid fa-upload"
            class="header-link"
            title="My Uploads"
          />
          <span class="header-text">My Uploads</span>
        </router-link>
      </li>
      <li v-else></li>
      <li class="ml-1">
        <!--        <router-link-->
        <!--          :to="{ name: 'dashboard' }"-->
        <!--          class="nav-item nav-link rounded-2"-->
        <!--        >-->
        <!--          <font-awesome-icon-->
        <!--            icon="fa-solid fa-chart-line"-->
        <!--            class="header-link"-->
        <!--            title="Dashboard"-->
        <!--          />-->
        <!--          <span class="header-text">Dashboard</span>-->
        <!--        </router-link>-->
      </li>
    </div>
    <header-search-bar v-if="AuthStore.status"></header-search-bar>
    <div>
      <div class="list-style-type-none ml-auto">
        <li
          class="nav-item cursor-pointer lg:mr-2 rounded-2"
          @click.prevent="logOut"
        >
          <font-awesome-icon
            icon="fa-solid fa-right-from-bracket"
            class="header-link"
            title="Log Out"
          />
          <span class="header-text">Log Out</span>
        </li>
      </div>
    </div>
  </header>
  <header v-else class="fixed left-0 right-0 top-0 p-1 header">
    <div class="flex flex-direction-row align-center list-style-type-none">
      <li class="lg:ml-3 mt-auto mb-auto">
        <router-link
          :to="{ name: 'instructor-feed' }"
          class="inline-block pl-2 pr-2"
        >
          <img
            style="width: 3em; height: 3em"
            title="Home"
            src="@/assets/images/logo_cropped.png"
          />
        </router-link>
      </li>
    </div>
  </header>
</template>

<script setup lang="ts">
import { useConfigStore } from "@/stores/ConfigStore";
import { useAuthStore } from "@/stores/AuthStore";
import { useRouter } from "vue-router";
import HeaderSearchBar from "@/components/user/HeaderSearchBar.vue";

const ConfigStore = useConfigStore();
const AuthStore = useAuthStore();
const router = useRouter();

const logOut = () => {
  AuthStore.logout();
  router.push("/");
};

ConfigStore.getConfig();
</script>

<style scoped>
.grid-header {
  width: 100%;
  display: grid;
  grid-template-columns: 100px 1fr 100px;
}

.header-link {
  margin: 0.2em 0.2em 0.2em 0.6em;
  cursor: pointer;
}

.header-text {
  vertical-align: top;
  margin-left: 0.3em;
}

header {
  background: var(--secondary-background-color);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1em;
  height: 4em;
  overflow: hidden;
  box-shadow: 0px 0px 10px 4px rgb(0 0 0 / 30%);
  z-index: 9999;
}

.nav-item {
  padding: 1rem;
}

.nav-item:hover {
  background: var(--primary-background-color);
}

@media (max-width: 600px) {
  .header-text {
    display: none;
  }
}
</style>
