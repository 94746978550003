<template>
  <img
    v-if="currentUser.fileName"
    id="profile-img"
    :src="websiteURL + 'profile_picture/' + currentUser.fileName"
    class="profile-icon-card"
  />
  <img
    v-else
    id="profile-img"
    src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
    class="profile-icon-card"
  />
</template>

<script setup lang="ts">
import { websiteURL } from "@/utils";

import { useAuthStore } from "@/stores/AuthStore";
const AuthStore = useAuthStore();

let currentUser = AuthStore.auth;
</script>

<style scoped></style>
