<template>
  <div class="user-list">
    <ul>
      <li
        class="font-larger bold-text"
        v-if="practitionerList.length > 0 || instructorList.length > 0"
      >
        Other Users
      </li>
      <li style="font-weight: 600" v-if="practitionerList.length > 0">
        Industry Practitioners
      </li>
      <li v-for="user in practitionerList.slice(0, 5)" :key="user.fileName">
        <router-link
          :to="{
            name: 'user-profile',
            params: { userId: user.id },
          }"
        >
          <div class="name-card rounded-2">
            <img
              v-if="user.fileName"
              id="profile-img"
              :src="websiteURL + 'profile_picture/' + user.fileName"
              class="profile-icon-card"
            />
            <img
              v-else
              id="profile-img"
              src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
              class="profile-icon-card"
            />
            <div class="ml-2 flex-grow-1" style="margin: auto">
              {{ user.firstName + " " + user.lastName }}
            </div>
          </div>
        </router-link>
      </li>
      <li v-if="practitionerList.length > 5" class="view-more">
        <router-link
          :to="{ name: 'users', params: { role: 'industrypractitioner' } }"
        >
          View More...
        </router-link>
      </li>
      <li style="font-weight: 600" v-if="instructorList.length > 0">
        Instructors
      </li>
      <li v-for="user in instructorList.slice(0, 5)" :key="user.fileName">
        <router-link
          :to="{
            name: 'user-profile',
            params: { userId: user.id },
          }"
        >
          <div class="name-card rounded-2">
            <img
              v-if="user.fileName"
              id="profile-img"
              :src="websiteURL + 'profile_picture/' + user.fileName"
              class="profile-icon-card"
            />
            <img
              v-else
              id="profile-img"
              src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
              class="profile-icon-card"
            />
            <div class="ml-2 flex-grow-1" style="margin: auto">
              {{ user.firstName + " " + user.lastName }}
            </div>
          </div>
        </router-link>
      </li>
      <li v-if="instructorList.length > 5" class="view-more">
        <router-link :to="{ name: 'users', params: { role: 'instructor' } }">
          View More...
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { websiteURL } from "@/utils";
import UserService from "@/services/user.service.js";

const instructorList = ref([]);
const practitionerList = ref([]);

const getUserList = (role) => {
  UserService.getUsers(role).then(
    (response) => {
      if (role === "instructor") instructorList.value = response.data;
      else practitionerList.value = response.data;
    },
    (error) => {
      let res =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(res);
    }
  );
};
getUserList("instructor");
getUserList("industrypractitioner");
</script>

<style scoped>
.user-list {
  display: block;
  padding: 1em 0;
}

.view-more {
  text-align: right;
  cursor: pointer;
  margin-right: 1rem;
  color: #1a73e8;
  font-weight: bold;
}
@media only screen and (max-width: 768px) {
  .user-list {
    display: none;
  }
}
</style>
