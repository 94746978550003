import { defineStore, getActivePinia } from "pinia";
import AuthService from "@/services/auth.service.js";
import { Profile, User } from "@/types";

const AUTH_STORAGE_KEY = "User";

export const useAuthStore = defineStore("AuthStore", {
  state: () => {
    const initUser = {} as User;
    const userString = localStorage.getItem(AUTH_STORAGE_KEY);
    if (userString !== null) {
      const userFromStorage = JSON.parse(userString);
      Object.assign(initUser, userFromStorage);
    }
    return {
      auth: initUser,
    };
  },
  getters: {
    status(): boolean {
      return Object.keys(this.auth).length !== 0;
    },
    role(): string {
      return this.auth.roles;
    },
    profile(): boolean {
      return (
        Object.keys(this.auth).length !== 0 &&
        this.auth.profile !== null &&
        JSON.stringify(this.auth.profile) !== undefined
      );
    },
  },
  actions: {
    login(user) {
      return AuthService.login(user).then(
        (user) => {
          this.auth = user as User;
          localStorage.setItem(AUTH_STORAGE_KEY, JSON.stringify(this.auth));
          return Promise.resolve(user);
        },
        (error) => {
          console.log(error);
          this.auth = {} as User;
          localStorage.setItem(AUTH_STORAGE_KEY, JSON.stringify(this.auth));
          return Promise.reject(error);
        }
      );
    },
    logout() {
      this.auth = {} as User;
      sessionStorage.clear();
      localStorage.setItem(AUTH_STORAGE_KEY, JSON.stringify(this.auth));
      AuthService.logout();
    },
    saveProfile(profile) {
      if (this.role === "INDUSTRYPRACTITIONER") {
        return AuthService.saveIndProfile(profile).then(
          (profile) => {
            this.auth.profile = profile.data;
            localStorage.setItem(AUTH_STORAGE_KEY, JSON.stringify(this.auth));
            return Promise.resolve(profile.data);
          },
          (error) => {
            this.auth.profile = {} as Profile;
            return Promise.reject(error);
          }
        );
      } else {
        return AuthService.saveProfile(profile).then(
          (profile) => {
            this.auth.profile = profile.data;
            localStorage.removeItem(AUTH_STORAGE_KEY);
            localStorage.setItem(AUTH_STORAGE_KEY, JSON.stringify(this.auth));
            return Promise.resolve(profile.data);
          },
          (error) => {
            this.auth.profile = {} as Profile;
            return Promise.reject(error);
          }
        );
      }
    },
    getProfile() {
      return AuthService.getProfile(this.auth.id).then(
        (profile) => {
          console.log(profile.data);
          const tokenObj = {
            accessToken: this.auth.accessToken,
          };
          this.auth = { ...profile.data, ...tokenObj };
          console.log(this.auth);
          localStorage.setItem(AUTH_STORAGE_KEY, JSON.stringify(this.auth));
          return Promise.resolve(profile.data);
        },
        (error) => {
          this.auth.profile = {} as Profile;
          return Promise.reject(error);
        }
      );
    },
  },
});
