<template>
  <div>
    <div class="m-3 min-height header-space">
      <div class="back-grid">
        <back-button></back-button>
        <h3 class="text-align-center bold-text m-2 mb-5">
          {{ tableHeading.replace(/([A-Z]+)/g, " $1").trim() }}
        </h3>
      </div>
      <div class="flex flex-direction-row justify-space-between my-3">
        <va-input
          v-model="filterInput"
          class="flex flex-col mb-2 md6 xs12"
          placeholder="Filter..."
        />
        &nbsp;&nbsp;
        <button
          class="btn btn-primary btn-block flex-shrink-1 flex-grow-0"
          style="height: 2.3rem"
          :disabled="updating"
          v-if="tableHeading === 'Feedback'"
          @click="updateInDatabase"
        >
          <span
            v-show="updating"
            class="spinner-border spinner-border-sm"
          ></span>
          <span>Update</span>
        </button>
        <button
          class="btn btn-primary btn-block flex-shrink-1 flex-grow-0"
          style="height: 2.3rem"
          v-if="tableHeading === 'FAQ'"
          @click="(faq = emptyFaq), (isFAQFormVisible = true)"
        >
          <span
            v-show="updating"
            class="spinner-border spinner-border-sm"
          ></span>
          <span>Add FAQ</span>
        </button>
        <button
          class="btn btn-primary btn-block flex-shrink-1 flex-grow-0"
          style="height: 2.3rem"
          v-if="tableHeading === 'DashboardAccessRequest'"
          @click="isDashboardAccessFormVisible = true"
        >
          <span
            v-show="updating"
            class="spinner-border spinner-border-sm"
          ></span>
          <span>Add Access to User</span>
        </button>
      </div>
      <div>
        <va-data-table
          class="table-crud"
          :items="items"
          :columns="fields"
          :filter="filterInput"
          :per-page="perPage"
          :current-page="currentPage"
          sticky-header
          :loading="loading"
          v-model="selectedItems"
          :selectable="selectable"
          select-mode="multiple"
          selected-color="#888888"
          @filtered="(filtered = $event.items), (currentPage = 1)"
          @selectionChange="selectedItemsEmitted = $event.currentSelectedItems"
        >
          <template v-if="selectable" #headerAppend>
            <tr class="va-data-table__table-tr">
              <th class="va-data-table__table-th">Show on Home Screen</th>
            </tr>
          </template>
          <template #cell(rating)="{ value }">
            {{ +value + 1 }}
          </template>

          <template #cell(roleId)="{ value }">
            <va-chip size="small" v-if="value == 1"> Instructor </va-chip>
            <va-chip size="small" v-else-if="value == 2">
              Practitioner
            </va-chip>
            <va-chip size="small" v-else> Admin </va-chip>
          </template>
          <template #cell(academicLevel)="{ value }">
            <span v-if="isJSONObject(value)">
              {{ JSON.parse(value).join(", ") }}
            </span>
            <span v-else>
              {{ value }}
            </span>
          </template>
          <template #cell(studentDept)="{ value }">
            <span v-if="isJSONObject(value)">
              {{ JSON.parse(value).join(", ") }}
            </span>
            <span v-else>
              {{ value }}
            </span>
          </template>
          <template #cell(expertise)="{ value }">
            <span v-if="isJSONObject(value)">
              {{ JSON.parse(value).join(", ") }}
            </span>
            <span v-else>
              {{ value }}
            </span>
          </template>
          <template #cell(courseRequestType)="{ value }">
            <span v-if="isJSONObject(value)">
              {{ JSON.parse(value).join(", ") }}
            </span>
            <span v-else>
              {{ value }}
            </span>
          </template>
          <template #cell(insState)="{ value }">
            <span v-if="isJSONObject(value)">
              {{ JSON.parse(value).join(", ") }}
            </span>
            <span v-else>
              {{ value }}
            </span>
          </template>
          <template #cell(insName)="{ value }">
            <span v-if="isJSONObject(value)">
              {{ JSON.parse(value).join(", ") }}
            </span>
            <span v-else>
              {{ value }}
            </span>
          </template>
          <template #cell(date)="{ value }">
            {{ JSON.parse(value).join(", ") }}
          </template>
          <template #cell(time)="{ value }">
            {{ JSON.parse(value).join(", ") }}
          </template>
          <template #cell(message)="{ value }">
            <span v-if="value.length > 100">
              {{ value.substring(0, 100) }}...
            </span>
            <span v-else>
              {{ value }}
            </span>
          </template>
          <template #cell(status)="{ value }">
            <span v-if="tableHeading == 'CourseRequests'">
              <span v-if="value === '10' || value === '60'">Accepted</span>
              <span v-else-if="value === '20'"
                >Practitioner's Response Awaited</span
              >
              <span v-else-if="value === '40'">Deleted</span>
              <span v-else>Pending</span>
            </span>
            <span v-else-if="tableHeading === 'Users'">
              <span v-if="value == 'true'"> Active </span>
              <span v-else>Inactive</span>
            </span>
            <span v-else-if="tableHeading == 'Uploads'">
              <span v-if="value === '60'">Uploaded</span>
              <span v-else-if="value === '90'"
                >Reported <font-awesome-icon icon="fa-solid fa-flag"
              /></span>
              <span v-else-if="value === '40'">Deleted</span>
            </span>
            <span v-else> {{ value }} </span>
          </template>
          <template #cell(actions)="{ rowIndex }">
            <div v-if="tableHeading === 'DashboardAccessRequest'">
              <va-button
                preset="plain"
                title="Accept Access Request"
                icon="check"
                v-if="items[rowIndex].status === '01'"
                @click="updateDashboardAccessRequest(items[rowIndex], '60')"
              />
              <va-button
                v-else-if="items[rowIndex].status === '60'"
                preset="plain"
                color="danger"
                title="Remove Access"
                icon="block"
                @click="updateDashboardAccessRequest(items[rowIndex], '40')"
              />
              <va-button
                v-if="items[rowIndex].status === '01'"
                preset="plain"
                color="danger"
                title="Reject Access Request"
                icon="close"
                @click="updateDashboardAccessRequest(items[rowIndex], '90')"
                class="ml-2"
              />
            </div>
            <va-button
              v-else-if="
                tableHeading === 'FAQ' && tableHeading === 'Organization'
              "
              preset="plain"
              title="Edit"
              icon="edit"
              @click="rowAction(rowIndex)"
            />
            <va-button
              v-else
              preset="plain"
              title="View Details"
              icon="visibility"
              @click="rowAction(rowIndex)"
            />
            <span
              v-if="
                tableHeading == 'CourseRequests' &&
                (items[rowIndex].status == null ||
                  items[rowIndex].status == '20')
              "
            >
              &nbsp;
              <va-button
                preset="plain"
                icon="assignment_ind"
                title="Assign Practitioner"
                @click="assignPractitioner(rowIndex)"
              />
            </span>
            <span
              v-if="
                tableHeading == 'CourseRequests' &&
                items[rowIndex].status == null
              "
            >
              &nbsp;
              <va-button
                preset="plain"
                icon="recommend"
                title="Recommend Practitioner"
                @click="recommendPractitioner(rowIndex)"
              />
            </span>
            <span
              v-if="
                tableHeading == 'CourseRequests' &&
                (items[rowIndex].status == '60' ||
                  items[rowIndex].status == '10')
              "
            >
              &nbsp;
              <va-button
                preset="plain"
                icon="person_remove"
                title="Remove Practitioner"
                @click="removePractitioner(rowIndex)"
              />
            </span>
            <span v-else-if="tableHeading == 'Users'">
              &nbsp;
              <va-button
                preset="plain"
                icon="person_remove"
                title="Deactivate User Account"
                v-if="items[rowIndex].status === true"
                @click="disableUserStatus(rowIndex)"
              />
              <va-button
                v-else
                preset="plain"
                icon="person_add"
                title="Activate User Account"
                @click="changeUserStatus(rowIndex)"
              />
              &nbsp;
              <va-button
                preset="plain"
                icon="thumb_down"
                v-if="
                  items[rowIndex].roleId == 2 &&
                  !items[rowIndex].recommendationDisable
                "
                title="Disable as Recommendation"
                @click="disablePractitionerRecommendation(rowIndex)"
              />
              <va-button
                preset="plain"
                icon="thumb_up"
                v-else-if="
                  items[rowIndex].roleId == 2 &&
                  items[rowIndex].recommendationDisable
                "
                title="Enable as Recommendation"
                @click="enablePractitionerRecommendation(rowIndex)"
              />
            </span>
            <span
              v-else-if="
                tableHeading === 'Uploads' && items[rowIndex].status !== '40'
              "
            >
              &nbsp;
              <va-button
                preset="plain"
                title="Delete Uploaded Content"
                icon="delete"
                @click="deleteData(rowIndex)"
              />
            </span>
          </template>
          <template #bodyAppend>
            <tr>
              <td :colspan="fields.length">
                <div class="table--pagination">
                  <va-pagination
                    v-model="currentPage"
                    input
                    :pages="pages"
                    :hideOnSinglePage="true"
                    class="mx-auto my-2"
                  />
                </div>
              </td>
            </tr>
          </template>
        </va-data-table>
      </div>
    </div>
    <Dialog
      v-model:visible="isVisible"
      modal
      header="User Feedback"
      :style="{ width: '60vw' }"
      :breakpoints="{ '768px': '90vw' }"
    >
      <div class="flex flex-direction-row">
        <div class="flex-shrink-1 flex-grow-0">
          <profile-icon />
        </div>
        <span class="ml-3 mt-2 bold-text dark-text-color">
          {{ currFeedback.user.firstName }} {{ currFeedback.user.lastName }}
        </span>
      </div>
      <div class="mt-2" style="font-size: larger" autofocus>
        {{ currFeedback.message }}
      </div>
      <va-divider />
      <div
        v-for="feedbackComment in feedbackComments"
        v-bind:key="feedbackComment.id"
      >
        <div class="flex flex-direction-row ml-2">
          <div class="flex-shrink-1 flex-grow-0">
            <img
              v-if="feedbackComment.user.fileName"
              id="profile-img"
              :src="
                websiteURL + 'profile_picture/' + feedbackComment.user.fileName
              "
              class="profile-icon-card"
            />
            <img
              v-else
              id="profile-img"
              src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
              class="profile-icon-card"
            />
          </div>
          <div class="mb-2">
            <div class="ml-3 pl-3 mt-1 card comment-card">
              <div class="bold-text dark-text-color">
                {{ feedbackComment.user.firstName }}
                {{ feedbackComment.user.lastName }}
              </div>
              {{ feedbackComment.message }}
            </div>
            <span class="ml-3 pl-1 small">
              {{ getTimeDiff(feedbackComment.createdAt) }}
            </span>
          </div>
        </div>
      </div>
      <va-divider />
      <div class="flex flex-direction-row">
        <div class="flex-shrink-1 flex-grow-0">
          <profile-icon />
        </div>
        <div class="ml-3 mt-2" v-if="!editable" @click="editable = true">
          Add Comment
        </div>
        <div class="ml-3 mt-2" v-else>
          <Form @submit="postComment" :validation-schema="commentSchema">
            <div class="flex flex-direction-row">
              <Field name="message" v-slot="{ field }">
                <textarea
                  type="text"
                  v-bind="field"
                  class="form-control border-2"
                  placeholder="Add a comment..."
                />
                <ErrorMessage name="message" class="error-feedback" />
              </Field>
              <button class="btn btn-primary mx-4 my-3">Reply</button>
            </div>
          </Form>
        </div>
      </div>
    </Dialog>
    <Dialog
      v-model:visible="isViewClassVisible"
      modal
      :header="dialogHeading"
      :style="{ width: '60vw' }"
      :breakpoints="{ '768px': '95vw' }"
    >
      <div v-if="tableHeading === 'Users'">
        <user-table :user="user" />
      </div>
      <div v-else-if="tableHeading === 'Instructors'">
        <instructor-profile-table :user-profile="userProfile" />
      </div>
      <div v-else-if="tableHeading === 'Classes'">
        <table class="table">
          <tr>
            <td>Semester & Year</td>
            <td>{{ course.semester + " " + course.year }}</td>
          </tr>
          <tr>
            <td>Course Code</td>
            <td>{{ course.courseCode }}</td>
          </tr>
          <tr>
            <td>Course Title</td>
            <td>{{ course.courseTitle }}</td>
          </tr>
          <tr v-if="course.additionalInformation?.length > 0">
            <td>Link to Additional Course Information</td>
            <td>{{ course.additionalInformation }}</td>
          </tr>
          <tr>
            <td>Location of Classroom</td>
            <td>{{ course.location }}</td>
          </tr>
          <tr>
            <td>Class Size</td>
            <td>{{ course.classSize }}</td>
          </tr>
          <tr>
            <td>Students' Academic Level</td>
            <td>{{ JSON.parse(course.academicLevel).join(", ") }}</td>
          </tr>
          <tr>
            <td>Students' Department or Program of Study</td>
            <td>{{ JSON.parse(course.studentDept).join(", ") }}</td>
          </tr>
        </table>
      </div>
      <div v-else-if="tableHeading === 'CourseRequests'">
        <course-request-table :course="courseRequest" />
      </div>
      <div v-else-if="tableHeading === 'Practitioners'">
        <practitioner-profile-table :user-profile="userProfile" />
      </div>
      <div v-else-if="tableHeading === 'Availability'">
        <practitioner-availability-table :userAvailability="userAvailability" />
      </div>
      <div v-else-if="tableHeading === 'Uploads'">
        <resources-gallery-item :item="upload" />
      </div>
      <div v-else-if="tableHeading === 'ResourceRequests'">
        <div class="min-h">
          <div
            class="responsive"
            v-for="item in resourceRequest.resource_responses"
            :key="item.id"
          >
            <resources-gallery-item :item="item" />
          </div>
          <div
            v-if="resourceRequest.resource_responses?.length == 0"
            class="text-align-center"
          >
            No Responses!
          </div>
        </div>
        <div class="clearfix"></div>
      </div>
      <div v-else-if="tableHeading === 'ContactUs'">
        <contact-us-table :contactUs="contactUs" />
      </div>
    </Dialog>
    <Dialog
      v-model:visible="isFAQFormVisible"
      modal
      header="Edit FAQ"
      :style="{ width: '60vw' }"
      :breakpoints="{ '768px': '90vw' }"
    >
      <div class="mx-3 mt-2">
        <FAQForm :faq="faq" @saveFAQ="saveFAQ" />
      </div>
    </Dialog>
    <Dialog
      v-model:visible="isUserAccountDisableVisible"
      modal
      header="Disable User Account"
      :style="{ width: '50vw' }"
      :breakpoints="{ '768px': '90vw' }"
    >
      <div class="mx-3 mt-2">
        <Form
          @submit="disableUserAccount"
          :validation-schema="disableUserAccountSchema"
        >
          <div>
            <div class="form-column">
              <div class="flex-grow-1 flex-shrink-1">
                <Field
                  name="duration"
                  class="form-control"
                  type="text"
                  placeholder="Duration"
                />
                <ErrorMessage name="duration" class="error-feedback" />
              </div>
              <div class="flex-grow-1 flex-shrink-1">
                <Field name="unit" as="select" class="dropdown">
                  <option value="" selected hidden disabled>Unit</option>
                  <option value="hour(s)">Hour(s)</option>
                  <option value="day(s)">Day(s)</option>
                  <option value="month(s)">Month(s)</option>
                  <option value="Indefinite">Indefinite</option>
                  <ErrorMessage name="message" class="error-feedback" />
                </Field>
              </div>
            </div>
            <div class="text-align-center">
              <button
                class="btn btn-primary flex-grow-0 flex-shrink-1 w-fit mt-2"
              >
                Disable
              </button>
            </div>
          </div>
        </Form>
      </div>
    </Dialog>
    <Dialog
      v-model:visible="isAssignFormVisible"
      modal
      :header="assignFormHeader"
      :style="{ width: '40vw' }"
      :breakpoints="{ '768px': '75vw' }"
    >
      <Form
        @submit="handleSubmit"
        :validation-schema="schema"
        :initial-values="formValues"
      >
        <div class="form-group">
          <label for="email">Email</label>
          <Field name="email" type="email" class="form-control" />
          <ErrorMessage name="email" class="error-feedback" />
        </div>
        <br />

        <div class="form-group text-align-center">
          <button class="btn btn-primary btn-block" :disabled="loading">
            <span
              v-show="loading"
              class="spinner-border spinner-border-sm"
            ></span>
            <span v-if="userAction === 'assignPractitioner'"
              >Assign Practitioner</span
            >
            <span v-else>Recommend Practitioner</span>
          </button>
        </div>
      </Form>
    </Dialog>
    <Dialog
      v-model:visible="isOrganizationFormVisible"
      modal
      header="Edit Organization Name"
      :style="{ width: '60vw' }"
      :breakpoints="{ '768px': '90vw' }"
    >
      <div class="mx-3 mt-2">
        <OrganizationForm
          :organization="organization"
          @saveOrganization="saveOrganization"
        />
      </div>
    </Dialog>
    <Dialog
      v-model:visible="isDashboardAccessFormVisible"
      modal
      header="Grant Dashboard Access to User"
      :style="{ width: '60vw' }"
      :breakpoints="{ '768px': '90vw' }"
    >
      <div class="mx-3 mt-2">
        <Form
          @submit="addDashboardAccessRequest"
          :validation-schema="schema"
          :initial-values="formValues"
        >
          <div class="form-group">
            <label for="email">Email</label>
            <Field name="email" type="email" class="form-control" />
            <ErrorMessage name="email" class="error-feedback" />
          </div>
          <br />
          <div class="form-group text-align-center">
            <button class="btn btn-primary btn-block" :disabled="loading">
              <span
                v-show="loading"
                class="spinner-border spinner-border-sm"
              ></span>
              <span>Add Access to User</span>
            </button>
          </div>
        </Form>
      </div>
    </Dialog>
  </div>
</template>

<script setup lang="ts">
import { ref, watch, computed } from "vue";
import BackButton from "@/components/user/BackButton.vue";
import ProfileIcon from "@/components/user/ProfileIcon.vue";
import UserTable from "@/components/admin/UserTable.vue";
import CourseRequestTable from "@/components/admin/CourseRequestTable.vue";
import InstructorProfileTable from "@/components/admin/InstructorProfileTable.vue";
import PractitionerProfileTable from "@/components/admin/PractitionerProfileTable.vue";
import PractitionerAvailabilityTable from "@/components/admin/PractitionerAvailability.vue";
import ResourcesGalleryItem from "@/components/ResourcesGalleryItem.vue";
import FAQForm from "@/components/admin/FAQForm.vue";
import OrganizationForm from "@/components/admin/OrganizationForm.vue";
import { Field, ErrorMessage, Form } from "vee-validate";
import * as yup from "yup";
import Dialog from "primevue/dialog";
import { useModal } from "vuestic-ui";
import AdminService from "@/services/admin.service.js";
import { useRoute, useRouter } from "vue-router";
import {
  UserFields,
  InstructorFields,
  IndustryPractitionerFields,
  CourseRequestFields,
  AvailabilityFields,
  UploadFields,
  FeedbackFields,
  ClassFields,
  ResourceRequestFields,
  ContactUsFields,
  FAQFields,
  websiteURL,
  getTimeDiff,
  isJSONObject,
  showToast,
  valid,
  OrganizationFields,
  DashboardAccessRequestFields,
  DashboardFeedbackFields,
} from "@/utils";
import UserService from "@/services/user.service.js";
import {
  User,
  Feedback,
  FeedbackComment,
  Course,
  CourseRequest,
  Profile,
  Upload,
  Availability,
  FAQ,
  ResourceRequest,
  Organization,
  ContactUs,
} from "@/types";
import ContactUsTable from "@/components/admin/ContactUsTable.vue";

const route = useRoute();
const router = useRouter();
const { confirm } = useModal();

let loading = ref(false);
let successful = ref(false);
let message = "";
let items = ref([]);
let fields = ref([{}]);
let currentPage = ref(1);
let currFeedback = ref({} as Feedback);
let feedbackComments = ref([] as FeedbackComment[]);
let isVisible = ref(false);
let user = ref({} as User);
let course = ref({} as Course);
let courseRequest = ref({} as CourseRequest);
let userProfile = ref({} as Profile);
let userAvailability = ref({} as Availability);
let resourceRequest = ref({} as ResourceRequest);
let upload = ref({} as Upload);
let faq = ref({} as FAQ);
let contactUs = ref({} as ContactUs);
let organization = ref({} as Organization);
let emptyFaq = ref({} as FAQ);
let isViewClassVisible = ref(false);
let editable = ref(false);
let isUserAccountDisableVisible = ref(false);
let isFAQFormVisible = ref(false);
let isOrganizationFormVisible = ref(false);
let isDashboardAccessFormVisible = ref(false);
let isAssignFormVisible = ref(false);
let userAction = ref("");
let assignFormHeader = ref("");

const perPage = ref(10);
const tableHeading = ref("");
let dialogHeading = ref("");
const selectable = ref(false);
const selectedItems = ref([]);
const filtered = ref([]);

let formValues = {
  email: "",
};

const schema = yup.object().shape({
  email: yup
    .string()
    .required("Email is required!")
    .email("Email is invalid!")
    .max(50, "Must be maximum 50 characters!"),
});

const fetchTable = (tableName) => {
  switch (tableName) {
    case "Users":
      getUsers();
      selectable.value = false;
      tableHeading.value = tableName.toString();
      break;
    case "Instructors":
      getInstructors();
      selectable.value = false;
      tableHeading.value = tableName.toString();
      break;
    case "Classes":
      getClasses();
      selectable.value = false;
      tableHeading.value = tableName.toString();
      break;
    case "CourseRequests":
      getCourseRequests();
      selectable.value = false;
      tableHeading.value = tableName.toString();
      break;
    case "ResourceRequests":
      getResourceRequests();
      selectable.value = false;
      tableHeading.value = tableName.toString();
      break;
    case "Practitioners":
      getIndustryPractitioners();
      selectable.value = false;
      tableHeading.value = tableName.toString();
      break;
    case "Availability":
      getAvailability();
      selectable.value = false;
      tableHeading.value = tableName.toString();
      break;
    case "Uploads":
      getUploads();
      selectable.value = false;
      tableHeading.value = tableName.toString();
      break;
    case "Feedback":
      getFeedback();
      selectable.value = true;
      tableHeading.value = tableName.toString();
      break;
    case "ContactUs":
      getContactUs();
      selectable.value = false;
      tableHeading.value = tableName.toString();
      break;
    case "FAQ":
      getFAQs();
      selectable.value = false;
      tableHeading.value = tableName.toString();
      break;
    case "Organization":
      getOrganization();
      selectable.value = false;
      tableHeading.value = tableName.toString();
      break;
    case "DashboardAccessRequest":
      getDashboardAccessRequests();
      selectable.value = false;
      tableHeading.value = tableName.toString();
      break;
    case "DashboardFeedback":
      getDashboardFeedback();
      selectable.value = false;
      tableHeading.value = tableName.toString();
      break;
    default:
      router.push({ name: "admin-feed" });
  }
};

const getUsers = () => {
  loading.value = true;
  AdminService.getUsers().then(
    (response) => {
      items.value = response.data;
      fields.value = UserFields;
      loading.value = false;
    },
    (error) => {
      loading.value = false;
      message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};

const getInstructors = () => {
  loading.value = true;
  AdminService.getInstructors().then(
    (response) => {
      items.value = response.data;
      fields.value = InstructorFields;
      loading.value = false;
    },
    (error) => {
      loading.value = false;
      message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};

const getClasses = () => {
  loading.value = true;
  AdminService.getClasses().then(
    (response) => {
      items.value = response.data;
      fields.value = ClassFields;
      loading.value = false;
    },
    (error) => {
      loading.value = false;
      message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};

const getCourseRequests = () => {
  loading.value = true;
  AdminService.getCourseRequests().then(
    (response) => {
      console.log(response.data);
      items.value = response.data;
      fields.value = CourseRequestFields;
      loading.value = false;
    },
    (error) => {
      loading.value = false;
      message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};

const getResourceRequests = () => {
  loading.value = true;
  AdminService.getResourceRequests().then(
    (response) => {
      items.value = response.data;
      fields.value = ResourceRequestFields;
      loading.value = false;
    },
    (error) => {
      loading.value = false;
      message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};

const getIndustryPractitioners = () => {
  loading.value = true;
  AdminService.getIndustryPractitioners().then(
    (response) => {
      items.value = response.data;
      fields.value = IndustryPractitionerFields;
      loading.value = false;
    },
    (error) => {
      loading.value = false;
      message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};

const getAvailability = () => {
  loading.value = true;
  AdminService.getAvailability().then(
    (response) => {
      items.value = response.data;
      fields.value = AvailabilityFields;
      loading.value = false;
    },
    (error) => {
      loading.value = false;
      message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};

const getUploads = () => {
  loading.value = true;
  AdminService.getUploads().then(
    (response) => {
      items.value = response.data;
      fields.value = UploadFields;
      loading.value = false;
    },
    (error) => {
      loading.value = false;
      message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};

const getFeedback = () => {
  loading.value = true;
  AdminService.getFeedback().then(
    (response) => {
      items.value = response.data;
      fields.value = FeedbackFields;
      selectedItems.value = items.value.filter((it) => it["display"] === true);
      loading.value = false;
    },
    (error) => {
      loading.value = false;
      message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};

const getContactUs = () => {
  loading.value = true;
  AdminService.getContactUs().then(
    (response) => {
      items.value = response.data;
      fields.value = ContactUsFields;
      loading.value = false;
    },
    (error) => {
      loading.value = false;
      message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};

const getFAQs = () => {
  loading.value = true;
  AdminService.getFAQs().then(
    (response) => {
      items.value = response.data;
      fields.value = FAQFields;
      loading.value = false;
    },
    (error) => {
      loading.value = false;
      message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};

const getOrganization = () => {
  loading.value = true;
  AdminService.getOrganization().then(
    (response) => {
      items.value = response.data;
      fields.value = OrganizationFields;
      loading.value = false;
    },
    (error) => {
      loading.value = false;
      message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};

const getDashboardAccessRequests = () => {
  loading.value = true;
  AdminService.getDashboardAccessRequests().then(
    (response) => {
      items.value = response.data;
      console.log(response);
      fields.value = DashboardAccessRequestFields;
      loading.value = false;
    },
    (error) => {
      loading.value = false;
      message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};

const getDashboardFeedback = () => {
  loading.value = true;
  AdminService.getDashboardFeedback().then(
    (response) => {
      items.value = response.data;
      fields.value = DashboardFeedbackFields;
      loading.value = false;
    },
    (error) => {
      loading.value = false;
      message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};

const saveFAQ = () => {
  getFAQs();
  isFAQFormVisible.value = false;
};

const saveOrganization = () => {
  getOrganization();
  isOrganizationFormVisible.value = false;
};

const pages = computed({
  get() {
    return perPage.value && perPage.value !== 0
      ? Math.ceil(filtered.value.length / perPage.value)
      : filtered.value.length;
  },
  set(items) {
    console.log(items);
  },
});

const selectedItemsEmitted = ref([]);

let updating = ref(false);
let msg = ref("");

const updateInDatabase = () => {
  updating.value = true;

  console.log(selectedItemsEmitted.value.map((el) => el["id"]));
  let data = JSON.stringify(selectedItemsEmitted.value.map((el) => el["id"]));
  AdminService.updateFeedback(data).then(
    (response) => {
      updating.value = false;
      console.log(response.data);
      getFeedback();
    },
    (error) => {
      updating.value = false;
      msg.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};

const commentSchema = yup.object().shape({
  message: yup
    .string()
    .required("Message is required!")
    .max(5000, "Cannot be greater tha 5000 characters!"),
});

const disableUserAccountSchema = yup.object().shape({
  unit: yup.string().required("Unit is required!"),
  duration: yup.string().when("unit", {
    is: (val) => val !== "Indefinite",
    then: (schema) => schema.required("Duration is required!"),
    otherwise: (schema) => schema.optional().min(0),
  }),
});

const getFeedbackComments = (rowIndex) => {
  console.log(rowIndex);
  currFeedback.value = items.value[rowIndex];
  UserService.getFeedbackComments(currFeedback.value.id).then(
    (response) => {
      console.log(response);
      feedbackComments.value = response.data;
      isVisible.value = true;
    },
    (error) => {
      msg.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};

const rowAction = (rowIndex) => {
  switch (tableHeading.value) {
    case "Users":
      user.value = items.value[rowIndex];
      dialogHeading.value = "View User Details";
      isViewClassVisible.value = true;
      break;
    case "Instructors":
      userProfile.value = items.value[rowIndex];
      dialogHeading.value = "View Instructor Details";
      isViewClassVisible.value = true;
      break;
    case "Classes":
      course.value = items.value[rowIndex];
      dialogHeading.value = "View Class Details";
      isViewClassVisible.value = true;
      break;
    case "CourseRequests":
      courseRequest.value = items.value[rowIndex];
      dialogHeading.value = "View Course Request Details";
      isViewClassVisible.value = true;
      break;
    case "ResourceRequests":
      console.log("resource request table");
      resourceRequest.value = items.value[rowIndex];
      dialogHeading.value = "View Resource Request Details";
      isViewClassVisible.value = true;
      break;
    case "Practitioners":
      userProfile.value = items.value[rowIndex];
      dialogHeading.value = "View Practitioner Details";
      isViewClassVisible.value = true;
      break;
    case "Availability":
      console.log("availability table");
      userAvailability.value = items.value[rowIndex];
      dialogHeading.value = "View Practitioner Availability Details";
      isViewClassVisible.value = true;
      break;
    case "Uploads":
      upload.value = items.value[rowIndex];
      dialogHeading.value = "View Upload Details";
      isViewClassVisible.value = true;
      break;
    case "Feedback":
      console.log("feedback table");
      getFeedbackComments(rowIndex);
      break;
    case "FAQ":
      console.log("faq table");
      faq.value = items.value[rowIndex];
      isFAQFormVisible.value = true;
      break;
    case "ContactUs":
      console.log("ContactUs table");
      contactUs.value = items.value[rowIndex];
      dialogHeading.value = "View Contact Message Details";
      isViewClassVisible.value = true;
      break;
    case "Organization":
      console.log("organization table");
      organization.value = items.value[rowIndex];
      isOrganizationFormVisible.value = true;
      break;
    case "DashboardFeedback":
      console.log("dashboard feedback table");
      getFeedbackComments(rowIndex);
      break;
  }
};

const deleteData = async (rowIndex) => {
  const cancelDeleteContent = await confirm({
    message: "Are you sure you want to delete this uploaded content?",
    cancelText: "Yes",
    okText: "No",
  });
  if (!cancelDeleteContent) {
    loading.value = true;
    AdminService.deleteProjectDetail(items.value[rowIndex].id).then(
      (res) => {
        msg.value = res.data.message;
        showToast("Content deleted successfully.");
        getUploads();
        loading.value = false;
      },
      (error) => {
        msg.value =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        loading.value = false;
      }
    );
  }
};

const disableUserStatus = (rowIndex) => {
  user.value = items.value[rowIndex];
  isUserAccountDisableVisible.value = true;
};

const changeUserStatus = (rowIndex) => {
  console.log(rowIndex);
  loading.value = true;
  AdminService.changeUserStatus(items.value[rowIndex].id).then(
    (res) => {
      msg.value = res.data.message;
      showToast("User updated successfully.");
      getUsers();
      loading.value = false;
    },
    (error) => {
      msg.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      loading.value = false;
    }
  );
};

const assignPractitioner = (rowIndex) => {
  console.log(rowIndex);
  userAction.value = "assignPractitioner";
  assignFormHeader.value = "Assign Practitioner";
  courseRequest.value = items.value[rowIndex];
  isAssignFormVisible.value = true;
};

const recommendPractitioner = (rowIndex) => {
  console.log(rowIndex);
  userAction.value = "recommendPractitioner";
  assignFormHeader.value = "Recommend Practitioner";
  courseRequest.value = items.value[rowIndex];
  isAssignFormVisible.value = true;
};

const removePractitioner = async (rowIndex) => {
  console.log(rowIndex);
  const removePractitioner = await confirm({
    message:
      "Are you sure you want to remove this practitioner from course support request?",
    cancelText: "Yes",
    okText: "No",
  });
  if (!removePractitioner) {
    loading.value = true;
    AdminService.removePractitioner(items.value[rowIndex].id).then(
      (res) => {
        msg.value = res.data.message;
        showToast("Course Request updated successfully.");
        getCourseRequests();
        loading.value = false;
      },
      (error) => {
        msg.value =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        loading.value = false;
      }
    );
  }
};

const disablePractitionerRecommendation = async (rowIndex) => {
  console.log(rowIndex);
  const cancelDisablePractitioner = await confirm({
    message:
      "Are you sure you want to disable this practitioner from instructor recommendations?",
    cancelText: "Yes",
    okText: "No",
  });
  if (!cancelDisablePractitioner) {
    loading.value = true;
    AdminService.updatePractitionerRecommendationAvail(
      items.value[rowIndex].id,
      true
    ).then(
      (res) => {
        msg.value = res.data.message;
        showToast("User updated successfully.");
        getUsers();
        loading.value = false;
      },
      (error) => {
        msg.value =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        loading.value = false;
      }
    );
  }
};

const enablePractitionerRecommendation = async (rowIndex) => {
  console.log(rowIndex);
  loading.value = true;
  AdminService.updatePractitionerRecommendationAvail(
    items.value[rowIndex].id,
    false
  ).then(
    (res) => {
      msg.value = res.data.message;
      showToast("User updated successfully.");
      getUsers();
      loading.value = false;
    },
    (error) => {
      msg.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      loading.value = false;
    }
  );
};

const handleSubmit = (data) => {
  if (userAction.value === "assignPractitioner") {
    loading.value = true;
    AdminService.assignPractitioner(courseRequest.value.id, data).then(
      (res) => {
        msg.value = res.data.message;
        showToast("Course Request updated successfully.");
        getCourseRequests();
        isAssignFormVisible.value = false;
        loading.value = false;
      },
      (error) => {
        msg.value =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        loading.value = false;
      }
    );
  } else {
    loading.value = true;
    AdminService.assignRecommendation(courseRequest.value.id, data).then(
      (res) => {
        msg.value = res.data.message;
        showToast("Recommendations updated successfully.");
        getCourseRequests();
        isAssignFormVisible.value = false;
        loading.value = false;
      },
      (error) => {
        msg.value =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        loading.value = false;
      }
    );
  }
};

const postComment = (data) => {
  loading.value = true;
  console.log(tableHeading.value);
  if (tableHeading.value === "Feedback")
    data["linkedResourceType"] = "feedback";
  else data["linkedResourceType"] = "dashboardFeedback";
  UserService.postFeedbackComment(data, currFeedback.value.id).then(
    (res) => {
      msg.value = res.data.message;
      showToast("Comment posted successfully.");
      isVisible.value = false;
      loading.value = false;
    },
    (error) => {
      msg.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      successful.value = false;
      loading.value = false;
    }
  );
};

const disableUserAccount = (data) => {
  loading.value = true;
  AdminService.changeUserStatus(user.value.id, data).then(
    (res) => {
      msg.value = res.data.message;
      isUserAccountDisableVisible.value = false;
      showToast("User updated successfully.");
      getUsers();
      loading.value = false;
    },
    (error) => {
      msg.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      loading.value = false;
    }
  );
};

const updateDashboardAccessRequest = (item, status) => {
  loading.value = true;
  let data = { status: status };
  AdminService.updateDashboardAccessRequest(item.id, data).then(
    (res) => {
      msg.value = res.data.message;
      showToast(msg.value);
      getDashboardAccessRequests();
      loading.value = false;
    },
    (error) => {
      msg.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      loading.value = false;
    }
  );
};

const addDashboardAccessRequest = (data) => {
  loading.value = true;
  AdminService.addDashboardAccessRequest(data).then(
    (res) => {
      msg.value = res.data.message;
      showToast(msg.value);
      getDashboardAccessRequests();
      isDashboardAccessFormVisible.value = false;
      loading.value = false;
    },
    (error) => {
      msg.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      loading.value = false;
    }
  );
};

const filterInput = ref("");

watch(
  () => route.params.name,
  (newName) => {
    fetchTable(newName as string);
  },
  { immediate: true }
);
</script>

<style scoped>
.table--pagination {
  display: flex;
  justify-content: center;
}

.responsive {
  padding: 0 6px;
  margin: 4px 0;
  float: left;
}

.clearfix:after {
  content: "";
  display: table;
  clear: both;
}

.paginator {
  display: grid;
  justify-content: center;
  margin: 1rem 2rem;
}
</style>
