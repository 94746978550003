<template>
  <table class="table">
    <tr>
      <td>First Name</td>
      <td>{{ user.firstName }}</td>
    </tr>

    <tr>
      <td>Last Name</td>
      <td>{{ user.lastName }}</td>
    </tr>

    <tr>
      <td>Email</td>
      <td>{{ user.email }}</td>
    </tr>

    <tr>
      <td>Name of Organization</td>
      <td>{{ user.nameOrg }}</td>
    </tr>

    <tr>
      <td>Title</td>
      <td>{{ user.title }}</td>
    </tr>

    <tr>
      <td>Gender Identity</td>
      <td>{{ user.gender }}</td>
    </tr>
    <tr>
      <td>Ethnicity</td>
      <td>{{ user.ethnicity }}</td>
    </tr>

    <tr>
      <td>Phone</td>
      <td>{{ user.phone }}</td>
    </tr>

    <tr>
      <td>Phone Number</td>
      <td>{{ user.phoneNumber }}</td>
    </tr>

    <tr>
      <td>Account Creation Date</td>
      <td>{{ new Date(user.createdAt).toLocaleString() }}</td>
    </tr>

    <tr>
      <td>Account Active</td>
      <td>{{ user.status }}</td>
    </tr>

    <tr>
      <td>Reason Delete</td>
      <td>{{ user.reasonDelete }}</td>
    </tr>
  </table>
</template>

<script setup lang="ts">
import { User } from "@/types";

// eslint-disable-next-line no-undef
const props = defineProps<{
  user: User;
}>();

console.log(props.user);
</script>

<style scoped>
table {
  width: fit-content;
  box-shadow: 0px 0px 10px 4px rgb(0 0 0 / 30%);
  border-radius: 6px;
  background: #fafafa;
  padding: 1rem;
  margin-top: 1rem;
  margin-left: auto;
  margin-right: auto;
}

thead td {
  text-align: center;
}

td {
  border: 2px solid #f0f0f0;
  border-collapse: collapse;
  padding: 0.2em 2em;
  font-size: larger;
  overflow-wrap: break-word;
}
</style>
