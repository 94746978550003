import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "grid-container" }
const _hoisted_2 = { class: "m-3 min-height" }
const _hoisted_3 = { class: "back-grid" }

import CourseRequestForm from "@/components/instructor/CourseRequestForm.vue";
import InstructorSidebar from "@/components/instructor/InstructorSidebar.vue";
import BackButton from "@/components/user/BackButton.vue";
import { deptOptions } from "@/utils";

import { useRouter } from "vue-router";
import { useCourseStore } from "@/stores/CourseStore";
import { useAuthStore } from "@/stores/AuthStore";


export default /*@__PURE__*/_defineComponent({
  __name: 'CreateView',
  setup(__props) {

const router = useRouter();
const CourseStore = useCourseStore();
const AuthStore = useAuthStore();
const currentUser = AuthStore.auth;

let initialState = {
  academicLevel: [],
  classSize: "",
  studentDept: [] as string[],
  studentDept_text: "",
  courseCode: "",
  courseTitle: "",
  location: "",
  additionalInformation: "",
  semester: "",
  year: "",
  phone: "",
  phoneNumber: "",
  gender: "No preference",
  ageRange: "No preference",
  ethnicity: "No preference",
  yearsOfExp: "No preference",
  areaOfExpertise: "No preference",
  levelEducation: "No preference",
  typeFirm: "No preference",
  sizeFirm: "No preference",
  learning: [],
  date: [],
  time: [],
};

if (CourseStore.status) {
  initialState.academicLevel = JSON.parse(
    CourseStore?.course?.academicLevel || '[""]'
  );
  initialState.classSize = CourseStore.course.classSize;
  initialState.studentDept = JSON.parse(
    CourseStore?.course?.studentDept || '[""]'
  );
  if (
    !deptOptions.some((el) => el.value === initialState.studentDept.at(-1)!)
  ) {
    initialState.studentDept_text = initialState.studentDept.at(-1)!;
    initialState.studentDept.pop();
  }
  initialState.phone = currentUser.phone;
  initialState.phoneNumber = currentUser.phoneNumber;
  initialState.courseCode = CourseStore.course.courseCode;
  initialState.courseTitle = CourseStore.course.courseTitle;
  initialState.location = CourseStore.course.location;
  initialState.additionalInformation = CourseStore.course.additionalInformation;
  initialState.semester = CourseStore.course.semester;
  initialState.year = CourseStore.course.year;
}

const formValues = {
  academicLevel: initialState.academicLevel,
  ageRange: initialState.ageRange,
  areaOfExpertise: initialState.areaOfExpertise,
  classSize: initialState.classSize,
  date: initialState.date,
  ethnicity: initialState.ethnicity,
  gender: initialState.gender,
  learning: initialState.learning,
  levelEducation: initialState.levelEducation,
  studentDept: initialState.studentDept,
  studentDept_text: initialState.studentDept_text,
  time: initialState.time,
  yearsOfExp: initialState.yearsOfExp,
  Email: "True",
  phone: initialState.phone,
  phoneNumber: initialState.phoneNumber,
  courseCode: initialState.courseCode,
  courseTitle: initialState.courseTitle,
  location: initialState.location,
  additionalInformation: initialState.additionalInformation,
  semester: initialState.semester,
  year: initialState.year,
  typeFirm: initialState.typeFirm,
  sizeFirm: initialState.sizeFirm,
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(InstructorSidebar),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(BackButton),
        _cache[0] || (_cache[0] = _createElementVNode("h2", { style: {"text-align":"center","font-weight":"bold"} }, " Course-Support Request Information ", -1))
      ]),
      _createVNode(CourseRequestForm, {
        formValues: formValues,
        action: "resubmit"
      })
    ])
  ]))
}
}

})