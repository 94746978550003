import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/images/site/instructor.png'
import _imports_1 from '@/assets/images/site/practitioner.png'


const _hoisted_1 = {
  key: 0,
  class: "form-group"
}
const _hoisted_2 = { class: "form-check-inline" }
const _hoisted_3 = { class: "form-check-inline lg:ml-3" }
const _hoisted_4 = {
  key: 1,
  class: "form-group"
}
const _hoisted_5 = {
  key: 0,
  class: "form-group"
}
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { key: 2 }
const _hoisted_8 = {
  key: 2,
  class: "form-group"
}
const _hoisted_9 = {
  key: 3,
  class: "form-group"
}
const _hoisted_10 = {
  key: 4,
  class: "form-group"
}
const _hoisted_11 = { for: "email" }
const _hoisted_12 = {
  key: 0,
  class: "font-color-blue"
}
const _hoisted_13 = {
  key: 1,
  class: "font-color-blue"
}
const _hoisted_14 = { class: "form-group text-align-center" }
const _hoisted_15 = ["disabled"]
const _hoisted_16 = { class: "spinner-border spinner-border-sm" }

import { ref } from "vue";
import * as yup from "yup";
import { Form, Field, ErrorMessage } from "vee-validate";
import Multiselect from "@vueform/multiselect";
import UserService from "@/services/user.service.js";
import {
  practitionerTitleOptions,
  instructorTitleOptions,
  showErrorToast,
  showToast,
} from "@/utils";
import { useConfigStore } from "@/stores/ConfigStore";


export default /*@__PURE__*/_defineComponent({
  __name: 'InviteUsersForm',
  emits: ["hideDialog"],
  setup(__props, { emit: __emit }) {

const ConfigStore = useConfigStore();
const inviteUsersFields = ConfigStore.config.filter(
  (el) => el.table === "invite_users"
);
let loading = ref(false);
let message = ref("");

// eslint-disable-next-line no-undef
const emits = __emit;

const schema = yup.object().shape({
  fields: yup.array().default(inviteUsersFields),
  role: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "role")?.disabled,
    then: (schema) => schema.required("User Type is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  title: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "title")?.disabled,
    then: (schema) => schema.required("Title is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  title_text: yup.string().when("title", {
    is: (val) => val === "Others",
    then: (schema) => schema.required("Title is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  firstName: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "firstName")?.disabled,
    then: (schema) => schema.required("First Name is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  lastName: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "lastName")?.disabled,
    then: (schema) => schema.required("Last Name is required!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  email: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "email")?.disabled,
    then: (schema) =>
      schema
        .required("Email is required!")
        .email("Email is invalid!")
        .max(50, "Must be maximum 50 characters!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
});

const sendInvite = (data) => {
  loading.value = true;
  if (data.title === "Others") data.title = data["title_text"];

  UserService.sendInvite(data).then(
    (response) => {
      console.log(response.data);
      showToast("Invite sent successfully to user.");
      loading.value = false;
      emits("hideDialog");
    },
    (error) => {
      loading.value = false;
      console.log(error);
      message.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      showErrorToast(message.value);
      emits("hideDialog");
    }
  );
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Form), {
    onSubmit: sendInvite,
    "validation-schema": _unref(schema)
  }, {
    default: _withCtx(({ values, meta }) => [
      (!_unref(inviteUsersFields).find((el) => el.name === 'role')?.disabled)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _cache[0] || (_cache[0] = _createElementVNode("label", { for: "role" }, [
                _createTextVNode("User Type"),
                _createElementVNode("span", { class: "super font-color-red" }, "*")
              ], -1)),
              _createVNode(_unref(Field), {
                name: "role",
                class: "form-check-input",
                type: "radio",
                value: "Instructor"
              }),
              _cache[1] || (_cache[1] = _createElementVNode("img", {
                src: _imports_0,
                class: "sidebar-image-xl ml-2 mr-1"
              }, null, -1)),
              _cache[2] || (_cache[2] = _createTextVNode("Instructor "))
            ]),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_unref(Field), {
                name: "role",
                class: "form-check-input",
                type: "radio",
                value: "IndustryPractitioner"
              }),
              _cache[3] || (_cache[3] = _createElementVNode("img", {
                src: _imports_1,
                class: "sidebar-image-xl ml-2 mr-1"
              }, null, -1)),
              _cache[4] || (_cache[4] = _createTextVNode("Industry Practitioner "))
            ]),
            _createVNode(_unref(ErrorMessage), {
              name: "role",
              class: "error-feedback"
            })
          ]))
        : _createCommentVNode("", true),
      (!_unref(inviteUsersFields).find((el) => el.name === 'title')?.disabled)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            (values.role === 'IndustryPractitioner')
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _cache[5] || (_cache[5] = _createElementVNode("label", { for: "title" }, [
                    _createTextVNode("Title"),
                    _createElementVNode("span", { class: "super font-color-red" }, "*")
                  ], -1)),
                  _createVNode(_unref(Field), {
                    modelValue: values.title,
                    "onUpdate:modelValue": ($event: any) => ((values.title) = $event),
                    name: "title",
                    type: "text",
                    class: "dropdown"
                  }, {
                    default: _withCtx(({ field }) => [
                      _createVNode(_unref(Multiselect), _mergeProps(field, {
                        modelValue: values.title,
                        "onUpdate:modelValue": ($event: any) => ((values.title) = $event),
                        options: _unref(practitionerTitleOptions),
                        mode: "single",
                        searchable: true
                      }), null, 16, ["modelValue", "onUpdate:modelValue", "options"])
                    ]),
                    _: 2
                  }, 1032, ["modelValue", "onUpdate:modelValue"])
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _cache[6] || (_cache[6] = _createElementVNode("label", { for: "title" }, [
                    _createTextVNode("Title"),
                    _createElementVNode("span", { class: "super font-color-red" }, "*")
                  ], -1)),
                  _createVNode(_unref(Field), {
                    modelValue: values.title,
                    "onUpdate:modelValue": ($event: any) => ((values.title) = $event),
                    name: "title",
                    type: "text",
                    class: "dropdown"
                  }, {
                    default: _withCtx(({ field }) => [
                      _createVNode(_unref(Multiselect), _mergeProps(field, {
                        modelValue: values.title,
                        "onUpdate:modelValue": ($event: any) => ((values.title) = $event),
                        options: _unref(instructorTitleOptions),
                        mode: "single",
                        searchable: true
                      }), null, 16, ["modelValue", "onUpdate:modelValue", "options"])
                    ]),
                    _: 2
                  }, 1032, ["modelValue", "onUpdate:modelValue"])
                ])),
            (values.title === 'Others')
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  _createVNode(_unref(Field), {
                    name: "title-text",
                    type: "text",
                    placeholder: "Please Specify",
                    class: "form-control bg-others"
                  })
                ]))
              : _createCommentVNode("", true),
            _createVNode(_unref(ErrorMessage), {
              name: "title",
              class: "error-feedback"
            })
          ]))
        : _createCommentVNode("", true),
      (!_unref(inviteUsersFields).find((el) => el.name === 'firstName')?.disabled)
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
            _cache[7] || (_cache[7] = _createElementVNode("label", { for: "firstName" }, [
              _createTextVNode("First Name"),
              _createElementVNode("span", { class: "super font-color-red" }, "*")
            ], -1)),
            _createVNode(_unref(Field), {
              name: "firstName",
              type: "text",
              class: "form-control"
            }),
            _createVNode(_unref(ErrorMessage), {
              name: "firstName",
              class: "error-feedback"
            })
          ]))
        : _createCommentVNode("", true),
      (!_unref(inviteUsersFields).find((el) => el.name === 'firstName')?.disabled)
        ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
            _cache[8] || (_cache[8] = _createElementVNode("label", { for: "lastName" }, [
              _createTextVNode("Last Name"),
              _createElementVNode("span", { class: "super font-color-red" }, "*")
            ], -1)),
            _createVNode(_unref(Field), {
              name: "lastName",
              type: "text",
              class: "form-control"
            }),
            _createVNode(_unref(ErrorMessage), {
              name: "lastName",
              class: "error-feedback"
            })
          ]))
        : _createCommentVNode("", true),
      (!_unref(inviteUsersFields).find((el) => el.name === 'email')?.disabled)
        ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
            _createElementVNode("label", _hoisted_11, [
              _cache[9] || (_cache[9] = _createTextVNode("Email")),
              _cache[10] || (_cache[10] = _createElementVNode("span", { class: "super font-color-red" }, "*", -1)),
              (values.role === 'IndustryPractitioner')
                ? (_openBlock(), _createElementBlock("span", _hoisted_12, " (Please use your work/company email address) "))
                : (_openBlock(), _createElementBlock("span", _hoisted_13, " (Please use your institutional email address) "))
            ]),
            _createVNode(_unref(Field), {
              name: "email",
              type: "email",
              class: "form-control"
            }),
            _createVNode(_unref(ErrorMessage), {
              name: "email",
              class: "error-feedback"
            })
          ]))
        : _createCommentVNode("", true),
      _cache[12] || (_cache[12] = _createElementVNode("br", null, null, -1)),
      _createElementVNode("div", _hoisted_14, [
        _createElementVNode("button", {
          class: "btn btn-primary btn-block",
          disabled: _unref(loading) || !meta.valid
        }, [
          _withDirectives(_createElementVNode("span", _hoisted_16, null, 512), [
            [_vShow, _unref(loading)]
          ]),
          _cache[11] || (_cache[11] = _createElementVNode("span", null, "Invite", -1))
        ], 8, _hoisted_15)
      ])
    ]),
    _: 1
  }, 8, ["validation-schema"]))
}
}

})